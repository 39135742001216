import { getRoutePath } from "routes"
import { SegmentType } from "resources/segment/segment/segmentTypes"
import { View } from "./types"

export function getNewPathName(
  newView: View,
  type: SegmentType,
  isSmartSetup?: boolean,
): Parameters<typeof getRoutePath>[0] {
  if (isSmartSetup) {
    switch (newView) {
      case "builder":
        return "segments.smart.detail.builder"
      case "insights":
        return "segments.smart.detail.insights"
      case "exports":
        return "segments.smart.detail.exports"
      default:
        return "segments.smart.detail"
    }
  }

  switch (type) {
    case "smart": {
      switch (newView) {
        case "insights":
          return "segments.smart.detail.insights"
        case "exports":
          return "segments.smart.detail.exports"
        default:
          return "segments.smart.detail"
      }
    }

    case "featured": {
      switch (newView) {
        case "insights":
          return "segments.featured.detail.insights"
        case "exports":
          return "segments.featured.detail.exports"
        default:
          return "segments.featured.detail"
      }
    }

    case "lookalike": {
      switch (newView) {
        case "insights":
          return "segments.lookalike.detail.insights"
        case "exports":
          return "segments.lookalike.detail.exports"
        default:
          return "segments.lookalike.detail"
      }
    }

    default:
      switch (newView) {
        case "insights":
          return "segments.custom.detail.insights"
        case "exports":
          return "segments.custom.detail.exports"
        default:
          return "segments.custom.detail"
      }
  }
}

import React from "react"
import PropTypes from "prop-types"
import { Bar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { hexToRgba } from "helpers/chartjs.helper"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"

const staticOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: true,
    mode: "index",
    intersect: false,
    callbacks: {
      title: function (tooltipItems) {
        switch (tooltipItems?.[0]?.label) {
          case "12h":
            return "Between 0 – 12h"
          case "24h":
            return "Between 12 – 24h"
          case "48h":
            return "Between 1 – 2 days"
          case "96h":
            return "Between 2 – 4 days"
          case ">96h":
            return "Between 4 – 7 days"
          default:
            return ""
        }
      },
    },
  },
  hover: {
    mode: null,
  },
  layout: {
    padding: {
      top: 20,
      right: 10,
      bottom: 3,
      left: 3,
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          autoSkip: false,
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
          callback: function (value) {
            return `${value}%`
          },
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      color: function (context) {
        return context.dataset.borderColor
      },
      formatter: function (value) {
        if (value) {
          return `${Math.round(value * 100) / 100}%`
        }
      },
      font: {
        size: 12,
        weight: "bold",
      },
      anchor: "end",
      align: "end",
      offset: -3,
    },
  },
}

const CustomersUpdatedChart = ({ rawData, className, color }) => {
  const data = {
    labels: ["12h", "24h", "48h", "96h", ">96h"],
    datasets: [
      {
        data: [
          (rawData.h12 / rawData.total) * 100,
          (rawData.h24 / rawData.total) * 100,
          (rawData.h48 / rawData.total) * 100,
          (rawData.h96 / rawData.total) * 100,
          (rawData.h168 / rawData.total) * 100,
        ],
        minBarLength: 3,
        backgroundColor: hexToRgba(color, 0.5),
        borderColor: hexToRgba(color, 1),
        borderWidth: 1,
      },
    ],
  }

  const options = {
    ...staticOptions,
    tooltips: {
      ...staticOptions.tooltips,
      callbacks: {
        ...staticOptions.tooltips.callbacks,
        label: function (tooltipItem) {
          switch (tooltipItem.label) {
            case "12h":
              return getUserFriendlyValueFormat(rawData.h12, "int")
            case "24h":
              return getUserFriendlyValueFormat(rawData.h24, "int")
            case "48h":
              return getUserFriendlyValueFormat(rawData.h48, "int")
            case "96h":
              return getUserFriendlyValueFormat(rawData.h96, "int")
            case ">96h":
              return getUserFriendlyValueFormat(rawData.h168, "int")
            default:
              return ""
          }
        },
      },
    },
  }

  return (
    <div className={`customers-counts-chart ${className}`}>
      <Bar data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
  )
}

CustomersUpdatedChart.propTypes = {
  rawData: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
}

export default CustomersUpdatedChart

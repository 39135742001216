import React, { useEffect, useState } from "react"
import TextInput from "components/UI/elements/TextInput/TextInput"
import SelectField from "components/UI/elements/SelectField"
import { required, pythonVariable, min, max, requiredArray } from "helpers/validators.helper"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Button from "components/UI/elements/Button/Button"
import ConfirmModal from "components/UI/components/ConfirmModal"

import styles from "./EventForm.module.scss"
import { useDryRun, useFetchMEEndpointOptions } from "resources/event/eventQueries"
import { useFetchDataSourceOptions } from "resources/dataSource/dataSourceQueries"
import { useFieldArray, useForm, Controller, FormProvider, DeepPartial } from "react-hook-form"
import Paper from "components/UI/elements/Paper"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import ExpandableAceEditor from "components/UI/components/ExpandableAceEditor/ExpandableAceEditor"
import {
  AutoloadFormValue,
  DeleteTransformation,
  DeleteTransformationFormValue,
  Event,
  EventDryRunPayload,
  EventFull,
  EventPayload,
  Transformation,
  TransformationFormValue,
} from "resources/event/eventTypes"
import { pick, omit, always, dec, inc, isNil } from "ramda"
import TransformationsList from "../TransformationsList/TransformationsList"
import PathField from "components/UI/elements/PathField/PathField"
import StructuredEventPayload from "components/UI/elements/StructuredEventPayload/StructuredEventPayload"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFetchSystemInfo } from "resources/systemInfo/systemInfoQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SystemBadge from "components/UI/elements/SystemBadge/SystemBadge"
import TextArea from "components/UI/elements/TextArea/TextArea"
import { getDaysFromMs, pluralize } from "../uitls"

const TYPE_OPTIONS = [
  { value: "number", label: "number" },
  { value: "datetime", label: "datetime" },
  { value: "string", label: "string" },
]

const FORMAT_OPTIONS = [
  { value: "p", label: "text" },
  { value: "ahref", label: "link" },
  { value: "ul", label: "list" },
]

export type EventFormValues = Pick<
  Event,
  "name" | "description" | "source_id" | "type" | "version" | "priority"
> & {
  ttl: string
  schema: Omit<Event["schema"], "display"> & {
    display: (Omit<Event["schema"]["display"][number], "path" | "id"> & { path: string[] })[]
  }
  dryRunLimit: string
  auto_load?: AutoloadFormValue | null
}

type EventFormProps = {
  onSubmit: (values: EventPayload) => void
  isCreate?: boolean
  event?: EventFull
}

const isDeleteTransformation = (
  transformation: Transformation,
): transformation is DeleteTransformation => transformation.type === "Delete"

const transformationToFormValue = (transformation: Transformation): TransformationFormValue => {
  if (isDeleteTransformation(transformation))
    return { ...transformation, sources: transformation.sources.map(path => ({ path })) }

  return transformation
}

const eventToFormValues = (event: EventFull): EventFormValues => ({
  ...pick<EventFull, keyof EventFormValues>(
    ["name", "description", "type", "version", "auto_load", "priority"],
    event,
  ),
  source_id: event.source.id,
  ttl: event.ttl === 0 ? "" : getDaysFromMs(event.ttl).toString(),
  schema: {
    title: event.schema.title,
    display: event.schema.display.map(item => ({
      ...item,
      path: item.path.slice(1).split("."),
    })),
  },
  dryRunLimit: "10",
  auto_load: event.auto_load && {
    ...event.auto_load,
    transformations: event.auto_load.transformations.map(transformationToFormValue),
  },
})

const isDeleteTransformationFormValue = (
  transformation: TransformationFormValue,
): transformation is DeleteTransformationFormValue => transformation.type === "Delete"

const formValueToTransformation = (transformation: TransformationFormValue): Transformation => {
  if (isDeleteTransformationFormValue(transformation))
    return {
      ...transformation,
      sources: transformation.sources?.map(({ path }) => path) ?? undefined,
    }

  return transformation
}

const formValuesToEventPayload = (formValues: EventFormValues): EventPayload => ({
  ...omit(["dryRunLimit"], formValues),
  auto_load: formValues.auto_load && {
    ...formValues.auto_load,
    transformations:
      formValues.auto_load?.transformations
        .map(formValueToTransformation)
        .map(cleanUpTransformation) ?? [],
  },
  ttl: formValues.ttl ? parseInt(formValues.ttl) * 24 * 60 * 60 : 0,
  schema: {
    title: formValues.schema.title,
    display: formValues.schema.display.map(item => ({
      ...item,
      path: "." + item.path.join("."),
    })),
  },
})

// When we change the type of a transformation in the form, we need to remove the fields that don't
// belong in the new tranformation. We could use `shouldUnregister=true` on the field to remove the
// value when the input is unmounted, or `setValue` to manually set the value to undefined when the
// type changes, but both of those solutions create problems with the drag-n-drop. So we keep the
// fields in and use this function to strip the unwanted values during submit.
const tranformationKeys: Record<Transformation["type"], string[]> = {
  Move: ["source", "destination"],
  MapValues: ["source", "destination", "values_map"],
  NamePopUpBanner: ["source", "destination"],
  NameNativeBanner: ["source", "destination"],
  CzechVocative: ["source", "destination"],
  CzechGender: ["source", "destination"],
  ReplaceString: ["source", "destination", "old", "new"],
  StripString: ["source", "destination", "characters"],
  StripWhiteSpaces: ["source", "destination"],
  LowercaseString: ["source", "destination"],
  ValidateEmail: ["source", "destination"],
  Delete: ["sources"],
}
function cleanUpTransformation(transformation: Transformation): Transformation {
  return pick(["enabled", "type", ...tranformationKeys[transformation.type]], transformation)
}

export default function EventForm({ onSubmit, isCreate, event }: EventFormProps) {
  const { data: sourceOptions = [] } = useFetchDataSourceOptions({ showHidden: true })
  const { data: endpointOptions = [] } = useFetchMEEndpointOptions()

  const isEditable = !event?.is_system
  const isTTLEditable = event?.ttl_mutable !== 0

  const defaultValues: DeepPartial<EventFormValues> = event
    ? eventToFormValues(event)
    : {
        description: "",
        schema: {
          display: [{ name: "", type: "string" as const, format: "p" as const, path: [""] }],
        },
        auto_load: null,
        dryRunLimit: "10",
        priority: 0,
      }
  const methods = useForm<EventFormValues>({ defaultValues })
  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
    control,
    getValues,
    watch,
    setValue,
    clearErrors,
  } = methods
  const {
    fields: schemaFields,
    append: appendSchema,
    remove: removeSchema,
  } = useFieldArray({ name: "schema.display", control })

  const [isTTLModalOpen, setIsTTLModalOpen] = useState(false)

  const checkTTLConfirmation = () => {
    const formValues = getValues()
    if (dirtyFields.ttl && formValues.ttl) {
      setIsTTLModalOpen(true)
    } else {
      submitForm(formValues)
    }
  }
  const confirmTTLModal = () => {
    submitForm(getValues())
    setIsTTLModalOpen(false)
  }

  const submitForm = (formValues: EventFormValues) => {
    let data = formValuesToEventPayload(formValues)

    if (!isCreate) {
      data = omit(["source_id", "type", "version"], data)
    }

    if (!isEditable) {
      data = isTTLEditable ? pick(["ttl", "schema"], data) : pick(["schema"], data)
    }

    onSubmit(data)
  }

  const [isDeleteAutoloadModalOpen, setIsDeleteAutoloadModalOpen] = useState(false)

  const [submitType, setSubmitType] = useState<"modify" | "dryRun">("dryRun")
  const requiredIfModifying = submitType === "modify" ? required : always(undefined)
  const requiredArrayIfModifying = submitType === "modify" ? requiredArray : always(undefined)
  const validateDryRunLimit =
    submitType === "dryRun" ? { min: min(1), max: max(1000) } : always(undefined)

  // Dry run:
  const [showRaw, setShowRaw] = useState(true)
  const [page, setPage] = useState(1)
  const index = page - 1
  const values = getValues()
  watch("auto_load")
  const dryRunLimit = watch("dryRunLimit")
  const dryRunPayload: Partial<EventDryRunPayload> | null | undefined = values.auto_load && {
    ...omit(["enabled"], values.auto_load),
    transformations: values.auto_load.transformations
      .map(formValueToTransformation)
      .map(cleanUpTransformation),
    event_filter: values.auto_load.event_filter || undefined,
    limit: parseInt(values.dryRunLimit),
  }
  const {
    data: dryRunData,
    isFetching: isFetchingDryRun,
    refetch: dryRun,
    remove: removeDryRunData,
  } = useDryRun(dryRunPayload as EventDryRunPayload)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => removeDryRunData, [])
  useEffect(() => setPage(1), [isFetchingDryRun])

  const { data: systemInfoData, isLoading: isLoadingSystemInfo } = useFetchSystemInfo()

  return (
    <>
      <ConfirmModal
        open={isTTLModalOpen}
        handleClose={() => setIsTTLModalOpen(false)}
        handleConfirm={confirmTTLModal}
        title="Setting up event retention"
        text={`You are about to set event retention to ${pluralize(
          Number(getValues().ttl),
          "day",
        )}. If you update existing events, this may influence already stored events.`}
        type="success"
      />
      <ConfirmModal
        open={isDeleteAutoloadModalOpen}
        handleClose={() => setIsDeleteAutoloadModalOpen(false)}
        handleConfirm={() => {
          setIsDeleteAutoloadModalOpen(false)
          // Prevent a react-hook-form bug where `shouldUnregister` on some transformation
          // inputs crashes the app after settings the auto_load to null
          setValue("auto_load.transformations", [])
          setTimeout(() => {
            setValue("auto_load", null)
            clearErrors("auto_load")
          }, 0)
        }}
        type="delete"
        title="Are you sure?"
        text={`Do you really want to delete the autoload configuration?`}
      />
      <form
        className={styles.container}
        id="event-form"
        onSubmit={e => {
          e.preventDefault()
          setSubmitType("modify")
          setTimeout(() => handleSubmit(checkTTLConfirmation)(e), 0)
        }}
      >
        <Paper noPadding>
          <div data-testid="general-section" className={styles.generalSection}>
            <div className={styles.description}>
              <h2>General</h2>
              <div>{!!event?.is_system && <SystemBadge />}</div>
            </div>
            <div className={styles.content}>
              <TextInput
                {...register("name", { validate: requiredIfModifying })}
                placeholder="Event name"
                label="Event name"
                error={errors.name?.message}
                maxLength={60}
                disabled={!isEditable}
              />
              <Controller
                name="source_id"
                control={control}
                rules={{ validate: requiredIfModifying }}
                render={({ field }) => (
                  <SelectField
                    input={field}
                    placeholder="Source"
                    label="Source"
                    error={errors.source_id?.message}
                    options={sourceOptions}
                    disabled={!isCreate}
                    isSimpleValue
                  />
                )}
              />
              <TextInput
                {...register("type", { validate: { requiredIfModifying, pythonVariable } })}
                placeholder="Event type"
                label="Event type"
                disabled={!isCreate}
                error={errors.type?.message}
              />
              <TextInput
                {...register("version", { validate: requiredIfModifying })}
                placeholder="1-0-0"
                label="Event version"
                disabled={!isCreate}
                error={errors.version?.message}
              />
            </div>
          </div>
          <div className={styles.descriptionSection}>
            <div className={styles.description}>
              <h2>Description</h2>
            </div>
            <div className={styles.content}>
              <TextArea
                {...register("description")}
                readOnly={!isEditable}
                error={errors.description?.message}
                label="Description (optional)"
                placeholder="Description (optional)"
                rows={8}
                maxLength={255}
                className={styles.descriptionField}
              />
            </div>
          </div>
          <div className={styles.ttlSection}>
            <div className={styles.description}>
              <h2>Event retention</h2>
            </div>
            <div className={styles.content}>
              <span>Delete events after</span>
              <TextInput
                {...register("ttl", { validate: { min: min(1), max: max(5000) } })}
                type="number"
                min={1}
                max={5000}
                error={errors.ttl?.message}
                className={styles.ttlInput}
                disabled={!isTTLEditable}
              />
              <span>day(s)</span>
            </div>
          </div>
          <div data-testid="priority-section" className={styles.prioritySection}>
            <div className={styles.description}>
              <h2>Priority</h2>
              <p>If enabled, event will have priority in processing.</p>
            </div>
            <div className={styles.content}>
              <Controller
                control={control}
                name="priority"
                render={({ field: { value, onChange } }) => (
                  <ToggleButton
                    disabled={!isEditable}
                    value={value}
                    handleToggle={() => onChange(value === 0 ? 1 : 0)}
                  />
                )}
              />
            </div>
          </div>
          <div data-testid="schema-section" className={styles.schemaSection}>
            <div className={styles.schemaTopBar}>
              <div className={styles.description}>
                <h2>Schema</h2>
                <p className="docs">
                  To learn more:{" "}
                  <a
                    href="https://docs.meiro.io/books/meiro-business-explorer/page/set-events"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    User documentation
                  </a>
                </p>
              </div>
              <TextInput
                className={styles.schemaTitle}
                {...register("schema.title")}
                placeholder="Title"
                label="Title"
              />
            </div>
            <div className={styles.schemasWrapper}>
              {schemaFields.map((field, index) => (
                <div key={field.id} className={styles.schemaBox}>
                  <Controller
                    name={`schema.display.${index}.name`}
                    control={control}
                    rules={{ validate: requiredIfModifying }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        placeholder="Name"
                        label="Name"
                        error={errors.schema?.display?.[index]?.name?.message}
                        maxLength={60}
                      />
                    )}
                  />
                  <Controller
                    name={`schema.display.${index}.type`}
                    control={control}
                    render={({ field }) => (
                      <SelectField
                        input={field}
                        placeholder="Type"
                        label="Type"
                        options={TYPE_OPTIONS}
                        isSimpleValue
                      />
                    )}
                  />
                  <Controller
                    name={`schema.display.${index}.format`}
                    control={control}
                    render={({ field }) => (
                      <SelectField
                        input={field}
                        placeholder="Format"
                        label="Format"
                        options={FORMAT_OPTIONS}
                        isSimpleValue
                      />
                    )}
                  />
                  <Controller
                    name={`schema.display.${index}.path`}
                    control={control}
                    rules={{ validate: requiredArrayIfModifying }}
                    render={({ field }) => (
                      <PathField
                        {...field}
                        label="Path"
                        error={errors.schema?.display?.[index]?.path?.message}
                      />
                    )}
                  />
                  <IconButton
                    data-testid="delete-schema"
                    size="xs"
                    color="red"
                    onClick={() => removeSchema(index)}
                    icon="trash-alt"
                    tooltip="Delete"
                    variant="outlined"
                  />
                </div>
              ))}
              <Button
                size="sm"
                onClick={() =>
                  appendSchema({
                    name: "",
                    type: "string" as const,
                    format: "p" as const,
                    path: [""],
                  })
                }
                className={styles.addSchemaButton}
              >
                + Add schema
              </Button>
            </div>
          </div>
        </Paper>
        {isLoadingSystemInfo ? (
          <LoadingIndicator />
        ) : (
          <Paper noPadding>
            <div data-testid="autoload-section" className={styles.autoloadSection}>
              <div className={styles.description}>
                <h2>Autoload</h2>
              </div>
              {isNil(values.auto_load) ? (
                systemInfoData?.me_connection_url ? (
                  <Button
                    className={styles.addAutoloadButton}
                    onClick={() => {
                      setValue("auto_load", {
                        enabled: true,
                        transformations: [],
                        endpoint_name: "sdk",
                        event_filter: "payload ->> 'type' = 'page_view'",
                        event_time_extraction: "reception_time",
                        user_identifier_extraction: "payload ->> 'user_id'",
                      })
                    }}
                    icon={["far", "cogs"]}
                    disabled={!isEditable}
                  >
                    Configure
                  </Button>
                ) : (
                  <div className={styles.meConnMessage}>
                    Please configure connection to Meiro Events in the settings to enable autoload.
                  </div>
                )
              ) : (
                <div className={styles.content}>
                  {isEditable && (
                    <Button
                      onClick={() => setIsDeleteAutoloadModalOpen(true)}
                      color="red"
                      variant="outlined"
                      className={styles.removeAutoloadButton}
                      icon={["far", "trash-alt"]}
                    >
                      Remove
                    </Button>
                  )}
                  <div className={styles.autoloadEnable}>
                    <div className={styles.label}>Enable</div>
                    <Controller
                      name="auto_load.enabled"
                      control={control}
                      render={({ field }) => (
                        <ToggleButton
                          value={field.value}
                          handleToggle={() => field.onChange(!field.value)}
                          disabled={!isEditable}
                        />
                      )}
                    />
                  </div>
                  <Controller
                    name="auto_load.endpoint_name"
                    control={control}
                    rules={{ validate: required }}
                    render={({ field }) => (
                      <SelectField
                        input={field}
                        placeholder="Endpoint"
                        label="Endpoint"
                        options={endpointOptions}
                        className={styles.endpointSelect}
                        isSimpleValue
                        error={errors?.auto_load?.endpoint_name?.message}
                        disabled={!isEditable}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="auto_load.event_time_extraction"
                    rules={{ validate: required }}
                    render={({ field }) => (
                      <ExpandableAceEditor
                        className={styles.eventTimeInput}
                        input={field}
                        label="Event time extraction"
                        meta={{
                          touched: true,
                          error: errors?.auto_load?.event_time_extraction?.message,
                        }}
                        height="50px"
                        disabled={!isEditable}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="auto_load.user_identifier_extraction"
                    rules={{ validate: required }}
                    render={({ field }) => (
                      <ExpandableAceEditor
                        className={styles.userIdInput}
                        input={field}
                        label="User identifier extraction"
                        meta={{
                          touched: true,
                          error: errors?.auto_load?.user_identifier_extraction?.message,
                        }}
                        height="50px"
                        disabled={!isEditable}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="auto_load.event_filter"
                    rules={{ validate: requiredIfModifying }}
                    render={({ field }) => (
                      <ExpandableAceEditor
                        className={styles.eventFilterInput}
                        input={field}
                        label="Event filter"
                        meta={{ touched: true, error: errors?.auto_load?.event_filter?.message }}
                        height="50px"
                        disabled={!isEditable}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            {values.auto_load !== null && (
              <>
                <div data-testid="transformation-section" className={styles.transformationsSection}>
                  <div className={styles.description}>
                    <h2>Transformations</h2>
                  </div>
                  <div className={styles.content}>
                    <FormProvider {...methods}>
                      <TransformationsList isEditable={isEditable} />
                    </FormProvider>
                  </div>
                </div>
                {isEditable && (
                  <div className={styles.dryRunSection}>
                    <div className={styles.description}>
                      <h2>Test run</h2>
                    </div>
                    <div className={styles.content}>
                      <div className={styles.topBar}>
                        <TextInput
                          {...register("dryRunLimit", {
                            validate: validateDryRunLimit,
                            valueAsNumber: true,
                          })}
                          type="number"
                          min={1}
                          max={1000}
                          label="No. of events"
                          className={styles.limitInput}
                          error={errors.dryRunLimit?.message}
                        />
                        <Button
                          size="md"
                          onClick={_ => {
                            setSubmitType("dryRun")
                            setTimeout(
                              handleSubmit(_ => dryRun()),
                              0,
                            )
                          }}
                          loading={isFetchingDryRun}
                          className={styles.dryRunButton}
                        >
                          Fetch events
                        </Button>
                        {!isFetchingDryRun &&
                          dryRunData?.response &&
                          dryRunData.response.length < parseInt(dryRunLimit) && (
                            <div className={styles.limitInfo}>
                              <FontAwesomeIcon icon={["far", "info-circle"]} />
                              We've retrieved the last 1000 events for performance optimization, but
                              there aren't enough events of the specified type.
                            </div>
                          )}
                      </div>

                      {!isFetchingDryRun &&
                        dryRunData &&
                        (dryRunData.error ? (
                          <pre className={styles.error}>{dryRunData.error}</pre>
                        ) : dryRunData.response?.length === 0 ? (
                          <div className={styles.emptyMessage}>No events found.</div>
                        ) : (
                          <div className={styles.payload}>
                            <div className={styles.controls}>
                              <Button
                                size="xs"
                                variant="outlined"
                                color="grey"
                                onClick={() => setShowRaw(s => !s)}
                              >
                                {showRaw ? "Show structured data" : "Show raw payload"}
                              </Button>
                              <IconButton
                                size="sm"
                                variant="transparent"
                                onClick={_ => setPage(dec)}
                                disabled={page === 1}
                                icon={["fas", "chevron-left"]}
                                color="black"
                              />
                              <div className={styles.pageNumbers}>
                                {page} / {dryRunData.response.length}
                              </div>
                              <IconButton
                                size="sm"
                                variant="transparent"
                                onClick={_ => setPage(inc)}
                                disabled={page === dryRunData.response.length}
                                icon={["fas", "chevron-right"]}
                                color="black"
                              />
                            </div>
                            <div>
                              <div className={styles.label}>User ID:</div>{" "}
                              {dryRunData.response[index].user_identifier}
                            </div>
                            <div className={styles.eventTime}>
                              <div>
                                <div className={styles.label}>Event time:</div>{" "}
                                {dryRunData.response[index].event_time}
                              </div>
                              <div className={styles.eventTimeCheck}>
                                {dryRunData.event_time_error_statement === null ? (
                                  <>
                                    <FontAwesomeIcon
                                      icon={["fas", "check"]}
                                      className={styles.successIcon}
                                    />
                                    Valid timestamp
                                  </>
                                ) : (
                                  <>
                                    <FontAwesomeIcon
                                      icon={["fas", "times"]}
                                      className={styles.errorIcon}
                                    />
                                    Invalid timestamp
                                  </>
                                )}
                              </div>
                            </div>
                            <div>
                              <div className={styles.label}>Payload:</div>
                            </div>
                            {showRaw ? (
                              <div className={styles.rawPayload}>
                                <pre>
                                  {JSON.stringify(dryRunData.response[index].payload, null, 2)}
                                </pre>
                              </div>
                            ) : (
                              <StructuredEventPayload
                                customerEvent={dryRunData.response[index]}
                                schema={formValuesToEventPayload(values).schema}
                              />
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </Paper>
        )}
      </form>
    </>
  )
}

export type Unit = "minutes" | "hours" | "days"

export type Duration = {
  count: number
  unit: Unit
}

export function secondsToDuration(seconds: number): Duration {
  if (seconds % (24 * 60 * 60) === 0) {
    return { count: seconds / (24 * 60 * 60), unit: "days" }
  }
  if (seconds % (60 * 60) === 0) {
    return { count: seconds / (60 * 60), unit: "hours" }
  }
  return { count: seconds / 60, unit: "minutes" }
}

export function durationToSeconds({ unit, count }: Duration): number {
  switch (unit) {
    case "days":
      return Math.round(count * 24 * 60 * 60)
    case "hours":
      return Math.round(count * 60 * 60)
    case "minutes":
      return Math.round(count * 60)
  }
}

import React, { PureComponent } from "react"

// ui components
import Paper from "components/UI/elements/Paper"
import Tag from "components/UI/elements/Tag"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import TagForm from "./TagForm"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { RowMessage } from "components/UI/elements/Table"
// constants, helpers
import { MODAL, DATE_FMT } from "sharedConstants"
import { getRoutePath } from "routes"

import "./Tags.scss"
import Username from "components/Username/Username"
import { useHasAccess } from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import { useStore as useSegmentsStore } from "pages/Segments/CustomSegments/CustomSegmentsList/segmentSelectionStore"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Table from "components/Table/Table"
import { Link } from "react-router-dom"
import create from "zustand"
import SearchField from "components/UI/elements/SearchField"
import { useCreateTag, useDeleteTag, useFetchAllTags, useModifyTag } from "resources/tag/tagQueries"
import { pick } from "ramda"
import { format } from "date-fns"
import { DBtimestampToDate } from "utilities/date"

class Tags extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tagForm: {
        show: false,
        tag: null,
        loading: false,
      },
      deleteModal: {
        open: false,
        tag: null,
      },
    }
  }

  listSegmentsByTag = tag => () => {
    const { history, filterByTag } = this.props
    filterByTag(tag.id)
    history.push(getRoutePath("segments"))
  }

  toggleTagForm = (tag = null) => {
    this.setState(prevState => ({
      tagForm: {
        show: !prevState.tagForm.show,
        tag,
        loading: false,
      },
    }))
  }

  submitTagForm = values => {
    const { tagForm } = this.state
    const { createTag, modifyTag } = this.props
    if (!tagForm.loading) {
      this.setState(prevState => ({
        tagForm: {
          ...prevState.tagForm,
          loading: true,
        },
      }))
      if (tagForm.tag) {
        // modify
        modifyTag({ id: tagForm.tag.id, data: values })
          .then(() => {
            this.toggleTagForm()
          })
          .catch(() => {
            this.setState(prevState => ({
              tagForm: {
                ...prevState.tagForm,
                loading: false,
              },
            }))
          })
      } else {
        // create
        createTag({ data: values })
          .then(() => {
            this.toggleTagForm()
          })
          .catch(() => {
            this.setState(prevState => ({
              tagForm: {
                ...prevState.tagForm,
                loading: false,
              },
            }))
          })
      }
    }
  }

  toggleDeleteModal = tag => {
    this.setState(prevState => ({
      deleteModal: {
        open: !prevState.deleteModal.open,
        tag: tag ?? prevState.deleteModal.tag,
      },
    }))
  }

  deleteTag = () => {
    const { deleteTag } = this.props
    const { deleteModal, tagForm } = this.state

    deleteTag({ id: deleteModal.tag.id })
      .then(() => {
        this.toggleDeleteModal()
        if (tagForm.show) {
          this.toggleTagForm()
        }
      })
      .catch(() => this.toggleDeleteModal())
  }

  render() {
    const { tagForm, deleteModal } = this.state
    const {
      areTagsFulfilled,
      tags,
      hasAccess,
      filterByTag,
      orderBy,
      orderDir,
      searchTerm,
      setSort,
      setSearchTerm,
    } = this.props
    const isAuthUserAdmin = hasAccess.segments.editTags

    const columns = [
      {
        id: "name",
        label: "Name",
        gridTemplate: "2fr",
        renderCell: tag => (
          <Link to={getRoutePath("segments.custom")} onClick={() => filterByTag(tag.id)}>
            <Tag color={tag.color ?? "primary"}>{tag.name}</Tag>
          </Link>
        ),
        onSort: () => setSort("name"),
      },
      {
        id: "segments_count",
        label: "In segments",
        gridTemplate: "1fr",
        renderCell: tag => tag.segments_count,
        onSort: () => setSort("segments_count"),
      },
      {
        id: "author",
        label: "Author",
        gridTemplate: "1fr",
        renderCell: tag => <Username userId={tag.user_id} />,
      },
      {
        id: "created",
        label: "Created",
        gridTemplate: "1fr",
        renderCell: tag => format(DBtimestampToDate(tag.created), DATE_FMT.DATE),
        onSort: () => setSort("created"),
      },
      {
        id: "actions",
        gridTemplate: "max-content",
        renderCell: tag => (
          <div className="actions-cell">
            <Link to={getRoutePath("segments.custom")} onClick={() => filterByTag(tag.id)}>
              Show segments
            </Link>
            <div className="buttons">
              <IconButton
                color="black"
                size="xs"
                className="edit-button"
                onClick={() => this.toggleTagForm(tag)}
                disabled={!isAuthUserAdmin}
                icon="pencil-alt"
                tooltip="Edit"
                variant="outlined"
              />
              <IconButton
                color="red"
                size="xs"
                onClick={() => this.toggleDeleteModal(tag)}
                disabled={!isAuthUserAdmin}
                icon="trash-alt"
                tooltip="Delete"
                variant="outlined"
              />
            </div>
          </div>
        ),
      },
    ]

    return (
      <Page
        className="data-tags"
        title="Tags"
        headerContent={
          <>
            {!tagForm.show && (
              <SearchField
                placeholder="Search for tags"
                wrapperClassName="tags-search"
                input={{
                  value: searchTerm,
                  onChange: setSearchTerm,
                }}
              />
            )}
            {!tagForm.show && (
              <Button disabled={!isAuthUserAdmin} onClick={() => this.toggleTagForm()}>
                + Create tag
              </Button>
            )}
            {tagForm.show && (
              <div className="tag-form-buttons">
                {tagForm.tag && (
                  <Button
                    color="red"
                    icon="trash-alt"
                    variant="outlined"
                    onClick={() => this.toggleDeleteModal(tagForm.tag)}
                  >
                    Delete
                  </Button>
                )}

                <Button color="grey" variant="outlined" onClick={() => this.toggleTagForm()}>
                  Cancel
                </Button>
                <Button type="submit" form="tagForm" loading={tagForm.loading}>
                  Save
                </Button>
              </div>
            )}
          </>
        }
      >
        {!areTagsFulfilled && <LoadingIndicator />}

        {areTagsFulfilled && !tagForm.show && (
          <Paper noPaddingTop>
            {tags.length === 0 && !searchTerm && (
              <RowMessage className="tags-info-message">
                {isAuthUserAdmin && (
                  <React.Fragment>Click on the "Create Tag" to get started.</React.Fragment>
                )}
                {!isAuthUserAdmin && (
                  <React.Fragment>No tags created. Contact administrator.</React.Fragment>
                )}
              </RowMessage>
            )}

            {tags.length === 0 && searchTerm && (
              <RowMessage className="tags-info-message">No tags found.</RowMessage>
            )}

            {tags.length > 0 && (
              <Table columns={columns} data={tags} sortBy={orderBy} sortDir={orderDir} />
            )}
          </Paper>
        )}
        {tagForm.show && (
          <Paper className="tag-form-wrapper">
            <TagForm
              onSubmit={this.submitTagForm}
              initialValues={tagForm.tag ? pick(["name", "color"], tagForm.tag) : undefined}
              isCreate={!tagForm.tag}
            />
          </Paper>
        )}
        <ConfirmModal
          open={deleteModal.open}
          type={MODAL.TYPE.DELETE}
          handleClose={() => this.toggleDeleteModal()}
          handleConfirm={this.deleteTag}
          title="Delete tag"
          action="delete"
          what="tag"
          item={deleteModal.tag?.name ?? ""}
        />
      </Page>
    )
  }
}

const useStore = create(set => ({
  orderBy: "name",
  orderDir: "ASC",
  searchTerm: "",
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
}))

export default props => {
  const hasAccess = useHasAccess()
  const { setSort: setSegmentsSort, setTag } = useSegmentsStore()
  const { orderBy, orderDir, searchTerm, setSort, setSearchTerm } = useStore()
  const { data: tags = [], isSuccess: areTagsFulfilled } = useFetchAllTags({
    orderBy,
    orderDir,
    searchTerm,
  })
  const createMutation = useCreateTag()
  const modifyMutation = useModifyTag()
  const deleteMutation = useDeleteTag()

  const filterByTag = tagId => {
    setSegmentsSort("last_export")
    setTag(tagId)
  }

  return (
    <Tags
      {...props}
      hasAccess={hasAccess}
      filterByTag={filterByTag}
      orderBy={orderBy}
      orderDir={orderDir}
      searchTerm={searchTerm}
      setSort={setSort}
      setSearchTerm={setSearchTerm}
      tags={tags}
      areTagsFulfilled={areTagsFulfilled}
      createTag={createMutation.mutateAsync}
      modifyTag={modifyMutation.mutateAsync}
      deleteTag={deleteMutation.mutateAsync}
    />
  )
}

import React, { lazy, PureComponent, Suspense } from "react"
import PropTypes from "prop-types"

// ui components
import ExpandedInsight from "components/UI/components/ExpandedInsight/ExpandedInsight"

import "./SegmentInsights.scss"
import { SocketContext } from "context/socket"
import { getCompoundAttributeSubAttribute } from "resources/attribute/compoundAttributeUtils"

import { reorderStickyInsights } from "helpers/insight.helper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import { useFetchCurrentUser, useHasAccess } from "resources/user/currentUserQueries"
import { useSegmentCountsStore } from "resources/segment/segment/segmentCounts"
import { useInsightsFiltersStore } from "../../SegmentDetail"
import { isObject } from "utilities/isObject"
import { differenceInDays } from "date-fns"
import { DBtimestampToDate } from "utilities/date"
import { useFetchAllAttributeAggregations } from "resources/attributeAggregation/attributeAggregationQueries"
const InsightTile = lazy(() => import("components/UI/elements/InsightTile"))

class SegmentInsights extends PureComponent {
  static contextType = SocketContext

  constructor(props) {
    super(props)
    this.state = {
      expandedInsight: {
        data: null,
        open: false,
      },
    }
  }

  openExpandedInsight = ({ attribute, insight, globalAudience, segmentedAudience, subAttribute }) =>
    this.setState({
      expandedInsight: {
        open: true,
        data: {
          attribute,
          insight,
          globalAudience,
          segmentedAudience,
          subAttribute,
        },
      },
    })

  closeExpandedInsight = () => {
    const { expandedInsight } = this.state

    if (expandedInsight.open)
      this.setState({
        expandedInsight: {
          ...expandedInsight,
          open: false,
        },
      })
  }

  render() {
    const {
      areAttributesFulfilled,
      attributesMapById,
      searchTerm,
      sourceId,
      stickyInsightIds,
      hasAccess,
      conditionsResultsCount,
      customersTotalCount,
      attributeAggregations,
      areAttributeAggregationsFulfilled,
      segmentAggregationValues,
      allAggregationValues,
    } = this.props
    const { expandedInsight } = this.state
    let resultAttributeAggregations = attributeAggregations
    if (searchTerm) {
      resultAttributeAggregations = attributeAggregations?.filter(item => {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase())
      })
    }

    if (sourceId) {
      resultAttributeAggregations = resultAttributeAggregations?.filter(item => {
        return attributesMapById[item.attribute_id]?.source.id === sourceId
      })
    }

    resultAttributeAggregations = reorderStickyInsights(
      resultAttributeAggregations,
      stickyInsightIds,
    )

    const dataSize = Array.isArray(attributeAggregations) ? attributeAggregations.length : 0

    return (
      <section className="segment-analytics">
        {(!areAttributesFulfilled || !areAttributeAggregationsFulfilled) && <LoadingIndicator />}
        {areAttributesFulfilled && dataSize > 0 && (
          <div className={`tiles-section`}>
            {resultAttributeAggregations.map(item => {
              const segmentAggregationValueObj = segmentAggregationValues?.[item.id.toString()]
              const aggregationValueObj = allAggregationValues?.[item.id.toString()]
              let segmentAggregationValue = null
              let percentage = null
              if (segmentAggregationValueObj) {
                if (isObject(segmentAggregationValueObj.result)) {
                  if ("value" in segmentAggregationValueObj.result) {
                    segmentAggregationValue = segmentAggregationValueObj.result.value
                  } else if ("count" in segmentAggregationValueObj.result) {
                    segmentAggregationValue = segmentAggregationValueObj.result.count
                  }
                  percentage = segmentAggregationValueObj.result.segment_percentage
                } else {
                  segmentAggregationValue = segmentAggregationValueObj.result
                }
              }
              if (Array.isArray(segmentAggregationValue) && segmentAggregationValue.length) {
                if (isObject(segmentAggregationValue[0])) {
                  const tmp = [...segmentAggregationValue]
                  percentage = []
                  segmentAggregationValue = []
                  tmp.forEach(obj => {
                    if ("value" in obj) {
                      segmentAggregationValue.push(obj.value)
                    } else if ("count" in obj) {
                      segmentAggregationValue.push(obj.count)
                    }
                    percentage.push(obj.segment_percentage)
                  })
                }
              }

              const attribute = attributesMapById[item.attribute_id]
              const subAttribute =
                attribute && item.sub_attribute_id
                  ? getCompoundAttributeSubAttribute(item.sub_attribute_id, attribute.data_type)
                  : null
              const compareValue =
                item.settings?.value_from && item.settings?.value_to
                  ? [item.settings.value_from, item.settings.value_to]
                  : item.settings?.value
              return (
                <Suspense key={item.id} fallback={<LoadingIndicator />}>
                  <InsightTile
                    id={item.id}
                    name={item.name}
                    value={segmentAggregationValue}
                    compareValue={compareValue}
                    attribute={attributesMapById[item.attribute_id]}
                    subAttribute={subAttribute}
                    percentage={percentage}
                    funcType={item.function}
                    outOf={conditionsResultsCount}
                    color={item.frontend_settings?.color}
                    attributePercentage={
                      segmentAggregationValueObj?.customers_with_attribute_percentage
                    }
                    showNewBadge={differenceInDays(new Date(), DBtimestampToDate(item.created)) < 8}
                    displayType={item.frontend_settings?.tile_type ?? "chart"}
                    onExpandClick={() =>
                      this.openExpandedInsight({
                        subAttribute,
                        attribute: attributesMapById[item.attribute_id],
                        insight: item,
                        globalAudience: {
                          isKnownPercentage:
                            aggregationValueObj?.customers_with_attribute_percentage,
                          result: aggregationValueObj?.result ?? 0,
                          total: customersTotalCount,
                        },
                        segmentedAudience: {
                          isKnownPercentage:
                            segmentAggregationValueObj?.customers_with_attribute_percentage,
                          result: segmentAggregationValueObj?.result ?? 0,
                          total: conditionsResultsCount,
                        },
                      })
                    }
                    isLoading={!segmentAggregationValueObj}
                    isSticky={stickyInsightIds.includes(item.id)}
                  />
                </Suspense>
              )
            })}
          </div>
        )}
        {areAttributesFulfilled && areAttributeAggregationsFulfilled && dataSize === 0 && (
          <div className="segment-analytics-no-content">
            {hasAccess.setup.insights && (
              <p className="info-message">
                Insights not set. Go to Setup / Insights tab to create insights.
              </p>
            )}
            {!hasAccess.setup.insights && (
              <p className="info-message">Insights not set. Contact admin to set insights.</p>
            )}
          </div>
        )}
        {dataSize > 0 && resultAttributeAggregations.length === 0 && (
          <div className="segment-analytics-no-content">
            <p className="info-message">Nothing found.</p>
          </div>
        )}
        {expandedInsight.data && expandedInsight.open && (
          <ExpandedInsight
            {...expandedInsight.data}
            favorite={stickyInsightIds.includes(expandedInsight.data.insight.id)}
            open={expandedInsight.open}
            onClose={this.closeExpandedInsight}
          />
        )}
      </section>
    )
  }
}

SegmentInsights.propTypes = {
  areAttributesFulfilled: PropTypes.bool.isRequired,
  attributesMapById: PropTypes.object.isRequired,
}

export default props => {
  const { data: attributesMap = {}, isSuccess } = useFetchAttributesMap()
  const { data: currentUser } = useFetchCurrentUser()
  const hasAccess = useHasAccess()
  const { searchTerm, sourceId } = useInsightsFiltersStore()
  const { conditionsResultsCount, customersTotalCount } = useSegmentCountsStore()

  const { data: attributeAggregations, isSuccess: areAttributeAggregationsFulfilled } =
    useFetchAllAttributeAggregations()

  return (
    <SegmentInsights
      {...props}
      attributesMapById={attributesMap}
      areAttributesFulfilled={isSuccess}
      stickyInsightIds={currentUser.frontend_settings?.stickyInsightsIds ?? []}
      hasAccess={hasAccess}
      conditionsResultsCount={conditionsResultsCount}
      customersTotalCount={customersTotalCount}
      searchTerm={searchTerm}
      sourceId={sourceId}
      attributeAggregations={attributeAggregations}
      areAttributeAggregationsFulfilled={areAttributeAggregationsFulfilled}
    />
  )
}

import requestFactory from "api/request"
import { Attribute } from "resources/attribute/attributeTypes"
import { OrderDir } from "types/util"

export type CustomerAttributeCount = {
  id: number
  attribute_id: Attribute["id"]
  value: any
  count: number
}

export type CustomerAttributeCountsResponse = {
  attribute_values_counts: CustomerAttributeCount[]
  selection_settings: {
    limit: number | null
    offset: number | null
    order_by: keyof CustomerAttributeCount | null
    order_dir: OrderDir | null
    value_start: string | null
  }
}
export const attributeValueCounts = (
  attributeId: Attribute["id"],
): Promise<CustomerAttributeCountsResponse> =>
  requestFactory(
    "get",
    `/customers/attributes/${attributeId}/values/counts`,
    {
      offset: 0,
      limit: 100,
      order_by: "count",
      order_dir: "DESC",
      value_contains: "",
    },
    true,
    true,
    "",
    false,
    true,
  )

import { useFetchEmailById } from "resources/email/emailQueries"
import { ActivateEmailNodeSettings } from "resources/journey/journeyTypes"

export function useGetDescription(settings?: ActivateEmailNodeSettings | null) {
  const { data: email } = useFetchEmailById(settings?.campaign_id)

  if (!settings || !email) {
    return ""
  }

  return email.name
}

import React from "react"
import UserSettings from "components/UserSettings"
import { useModifyUser } from "resources/user/userQueries"
import { User, UserModifyPayload } from "resources/user/userTypes"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"

export default function UserSettingsPage() {
  const { data: currentUser } = useFetchCurrentUser()
  const { mutateAsync } = useModifyUser()
  const modifyUser = (id: User["id"], data: UserModifyPayload) => mutateAsync({ id, data })

  return (
    <UserSettings
      user={currentUser}
      modifyUser={modifyUser}
      editablePassword={!currentUser!.sso_enabled}
    />
  )
}

import React from "react"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import { showToast } from "app/toast"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { TOAST } from "sharedConstants"
import { getRoutePath } from "routes"
import "./SourcesList.scss"
import Tippy from "@tippyjs/react"
import { refetchAttributes } from "resources/attribute/attributeQueries"
import { refetchEvents } from "resources/event/eventQueries"
import Page from "components/UI/Page/Page"
import { useFetchAllDataSources, useModifyDataSource } from "resources/dataSource/dataSourceQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import {
  useFetchGlobalSettings,
  useModifyGlobalSetting,
} from "resources/globalSettings/globalSettingsQueries"
import { without } from "ramda"
import SystemBadge from "components/UI/elements/SystemBadge/SystemBadge"
import { Link } from "react-router-dom"
import {
  Source,
  SourceFilterInitialState,
  SourceFilterState,
} from "resources/dataSource/dataSourceTypes"
import Table, { Column, Name } from "components/Table/Table"
import Datetime from "components/UI/elements/Datetime/Datetime"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"
import { useModifyUser } from "resources/user/userQueries"
import create from "zustand"

const filtersInitialState: SourceFilterInitialState = {
  orderBy: "name",
  orderDir: "ASC",
}

export const useFiltersStore = create<SourceFilterState>(set => ({
  ...filtersInitialState,
  reset: () => set(filtersInitialState),
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
}))

export default function Sources() {
  const { orderBy, orderDir, setSort } = useFiltersStore()

  const { data: sources = [], isSuccess: areSourcesFulfilled } = useFetchAllDataSources({
    orderBy,
    orderDir,
    showHidden: true,
  })
  const { data: globalSettings, isSuccess: areGlobalSettingsFulfilled } = useFetchGlobalSettings()
  const { mutateAsync: modifyGlobalSetting } = useModifyGlobalSetting()

  const { data: currentUser } = useFetchCurrentUser()
  const { id, frontend_settings } = currentUser!
  const showHidden = frontend_settings?.showHiddenSources ?? false

  const { mutateAsync: modifyUser, isLoading } = useModifyUser()
  const toggleShowHidden = () =>
    modifyUser({
      id: id,
      data: {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          showHiddenSources: !frontend_settings?.showHiddenSources ?? false,
        },
      },
    })

  function toggleSourceHiddenInDashboard(sourceId: Source["id"]) {
    const hiddenSourcesSetting = globalSettings?.["hidden_data_sources_from_diagnostic_dashboard"]
    if (!hiddenSourcesSetting) {
      return
    }
    const { id, value: hiddenSourceIds } = hiddenSourcesSetting
    const isRemoving = hiddenSourceIds.includes(sourceId)
    return modifyGlobalSetting(
      {
        id,
        data: {
          value: isRemoving
            ? without([sourceId], hiddenSourceIds)
            : hiddenSourceIds.concat(sourceId),
        },
      },
      {
        onSuccess() {
          showToast(
            `The data source will be ${
              isRemoving ? "shown" : "hidden"
            } from the Diagnostic Dashboard after the next data cache refresh. If you want to see the change now, refresh cache manually.`,
            TOAST.TYPE.INFO,
            getRoutePath("administration.settings"),
            true,
          )
        },
      },
    )
  }

  const hiddenDashboardSourceIds =
    globalSettings?.["hidden_data_sources_from_diagnostic_dashboard"]?.value ?? []
  const filteredSources = showHidden ? sources : sources.filter(({ is_hidden }) => is_hidden === 0)
  const isFiltering = !showHidden

  const columns: Column<Source>[] = [
    {
      id: "icon",
      gridTemplate: "max-content",
      renderCell: source => (
        <div className="icon-container">
          <SrcDstIcon source={source} />
        </div>
      ),
    },
    {
      id: "name",
      label: "Name",
      gridTemplate: "1fr",
      renderCell: source => (
        <div>
          <Name name={source.name} />
          {!!source.is_system && <SystemBadge />}
        </div>
      ),
      onSort: () => setSort("name"),
    },
    {
      id: "description",
      label: "Description",
      gridTemplate: "3fr",
      renderCell: destination => destination.description ?? "—",
    },
    {
      id: "in_dashboard",
      label: (
        <>
          Show in Diagnostic Dashboard{" "}
          <Tippy
            content="The change will be visible after data cache expiration in the Diagnostic
                Dashboard charts: NO. OF UNIQUE CUSTOMERS IDENTIFIED ACROSS X SOURCES, NO. OF
                CUSTOMERS IDENTIFIED PER SOURCE and CONNECTED SOURCES. To quicken the update,
                manually refresh the data cache in the Administration tab / Settings tab."
          >
            <span>
              <FontAwesomeIcon
                icon={["fas", "info-circle"]}
                data-tip
                data-for="show-in-diagnostic-dashboard-tooltip"
              />
            </span>
          </Tippy>
        </>
      ),
      gridTemplate: "175px",
      renderCell: source =>
        areGlobalSettingsFulfilled && (
          <ToggleButton
            value={!hiddenDashboardSourceIds.includes(source.id)}
            handleToggle={() => toggleSourceHiddenInDashboard(source.id)}
            disabled={source.is_hidden === 1}
          />
        ),
      onSort: () => setSort("in_dashboard"),
    },
    {
      id: "created",
      label: "Date added",
      gridTemplate: "max-content",
      renderCell: source => <Datetime datetime={source.created} />,
      onSort: () => setSort("created"),
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: source => (
        <div className="actions">
          {!!source.is_system ? (
            <Link to={getRoutePath("administration.sources.detail", { id: source.id })}>
              <IconButton
                color="black"
                size="xs"
                icon={["fas", "magnifying-glass"]}
                tooltip="View"
                variant="outlined"
              />
            </Link>
          ) : (
            <>
              <Link to={getRoutePath("administration.sources.detail", { id: source.id })}>
                <IconButton
                  data-testid="edit-button"
                  color="black"
                  size="xs"
                  icon="pencil-alt"
                  tooltip="Edit"
                  variant="outlined"
                />
              </Link>
              <HideButton source={source} />
            </>
          )}
        </div>
      ),
    },
  ]

  return (
    <Page
      className="setup-sources"
      title="Sources"
      headerContent={
        <Link to={getRoutePath("administration.sources.create")}>
          <Button>+ Create source</Button>
        </Link>
      }
    >
      {!areSourcesFulfilled && <LoadingIndicator />}

      {areSourcesFulfilled && (
        <Paper hasHeader noPaddingTop={filteredSources.length > 0}>
          <div className="top-bar">
            <div>Show hidden sources</div>
            <ToggleButton
              value={showHidden}
              handleToggle={toggleShowHidden}
              size="sm"
              isLoading={isLoading}
            />
          </div>
          <Table
            data={filteredSources}
            columns={columns}
            emptyMessage={
              isFiltering ? "Nothing found." : 'Click on "Create Source" to get started.'
            }
            getRowClassName={source => (source.is_hidden ? "hidden-source" : undefined)}
            getRowTestId={() => "source-row"}
            sortBy={orderBy}
            sortDir={orderDir}
          />
        </Paper>
      )}
    </Page>
  )
}

function HideButton({ source }: { source: Source }) {
  const modifyMutation = useModifyDataSource()

  function toggleHiddenAttribute(source: Source) {
    const changedIsHidden = source.is_hidden === 1 ? 0 : 1
    modifyMutation.mutate(
      { id: source.id, data: { is_hidden: changedIsHidden } },
      {
        onSuccess() {
          refetchAttributes()
          refetchEvents()
        },
      },
    )
  }

  return (
    <IconButton
      color="red"
      size="xs"
      onClick={() => toggleHiddenAttribute(source)}
      data-testid="hide-button"
      icon={["far", source.is_hidden ? "eye" : "eye-slash"]}
      tooltip={source.is_hidden ? "Show" : "Hide"}
      variant="outlined"
      loading={modifyMutation.isLoading}
    />
  )
}

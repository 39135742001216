import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import { api } from "api"
import { queryClient } from "app/queryClient"
import {
  equals,
  filter,
  groupBy,
  identity,
  map,
  path,
  pathOr,
  pipe,
  prop,
  sort,
  uniq,
  uniqBy,
  update,
} from "ramda"
import { Source } from "resources/dataSource/dataSourceTypes"
import { useFetchSystemInfo } from "resources/systemInfo/systemInfoQueries"
import { OrderDir, SelectOption } from "types/util"
import { ascend, descend } from "utilities/comparators"
import {
  Event,
  EventSort,
  EventCreatePayload,
  EventModifyPayload,
  EventFull,
  EventDryRunPayload,
} from "./eventTypes"
import { useCallback } from "react"

const EVENT_ALL_QK: QueryKey = ["event", "all"]

export const refetchEvents = () =>
  queryClient.invalidateQueries(
    { queryKey: EVENT_ALL_QK, refetchType: "all" },
    { throwOnError: false },
  )

function useEventsQuery<T>(config?: UseQueryOptions<EventFull[], unknown, T, QueryKey>) {
  return useQuery(EVENT_ALL_QK, api.event.listAll, {
    staleTime: 60 * 1000,
    ...config,
  })
}

type FetchAllEventsOpts = {
  includeHidden?: boolean
  orderBy?: EventSort
  orderDir?: OrderDir
  searchTerm?: string
  sourceId?: Source["id"]
}

export function useFetchAllEvents(
  { includeHidden, orderBy, orderDir, searchTerm, sourceId }: FetchAllEventsOpts = {},
  config: UseQueryOptions<EventFull[], unknown, EventFull[], QueryKey> = {},
) {
  const select = useCallback(
    (events: EventFull[]) => {
      let selectedEvents = events

      if (!includeHidden) {
        selectedEvents = selectedEvents.filter(({ is_hidden }) => !is_hidden)
      }

      if (sourceId) {
        selectedEvents = selectedEvents.filter(({ source }) => source.id === sourceId)
      }

      if (searchTerm) {
        selectedEvents = selectedEvents.filter(
          ({ id, name }) =>
            name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            id.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      }

      if (orderBy && orderDir) {
        const comparator = orderDir === "ASC" ? ascend : descend
        const compareBy =
          orderBy === "auto_load"
            ? pathOr(false, ["auto_load", "enabled"])
            : orderBy === "source"
            ? path(["source", "name"])
            : prop(orderBy)
        const sortToBottomWhen = orderBy === "ttl" ? (d: EventFull["ttl"]) => !d : undefined

        selectedEvents = sort(comparator<EventFull>(compareBy, sortToBottomWhen), selectedEvents)
      }

      return selectedEvents
    },
    [includeHidden, orderBy, orderDir, searchTerm, sourceId],
  )

  return useEventsQuery({ select, ...config })
}

type FetchEventsMapOpts = {
  includeHidden?: boolean
}

export function useFetchEventsMap({ includeHidden }: FetchEventsMapOpts = {}) {
  const select = (events: EventFull[]) => {
    const filteredEvents = includeHidden ? events : events.filter(({ is_hidden }) => !is_hidden)
    return Object.fromEntries(filteredEvents.map(event => [event.id, event]))
  }

  return useEventsQuery({ select })
}

export function useFetchEventOptions() {
  return useEventsQuery<SelectOption<Event["id"]>[]>({
    select(events) {
      return sort(
        ascend(path(["source", "name"])),
        events.map(event => ({ value: event.id, label: event.name, source: event.source })),
      )
    },
  })
}

export function useFetchEventById(
  id: Event["id"] | null,
  config: UseQueryOptions<EventFull[], unknown, EventFull | null, QueryKey> = {},
) {
  const select = (events: EventFull[]) => events.find(event => event.id === id) ?? null

  return useEventsQuery({ select, enabled: !!id, ...config })
}

const selectEventsBySourceId = pipe(
  sort<EventFull>(ascend(prop("name"))),
  groupBy(({ source }) => source.id),
)

export function useFetchEventsMapBySourceId() {
  return useEventsQuery({ select: selectEventsBySourceId })
}

const selectEventTypes = pipe(
  map<EventFull, EventFull["type"]>(prop("type")),
  uniq,
  sort(ascend(identity)),
)

export function useFetchEventTypes({ includeHidden }: { includeHidden?: boolean } = {}) {
  return useEventsQuery({
    select: pipe(
      includeHidden ? identity : filter<EventFull>(({ is_hidden }) => !is_hidden),
      selectEventTypes,
    ),
  })
}

const selectEventSourceIds = pipe(
  map<EventFull, EventFull["source"]>(prop("source")),
  uniqBy(prop("id")),
  map(prop("id")),
)

export function useFetchEventSourceIds() {
  return useEventsQuery({ select: selectEventSourceIds })
}

export function useCreateEvent() {
  const queryClient = useQueryClient()

  return useMutation(({ data }: { data: EventCreatePayload }) => api.event.create(data), {
    onSuccess: ({ event }) => {
      queryClient.setQueryData<EventFull[]>(EVENT_ALL_QK, data => {
        if (!data) return

        return data.concat(event)
      })
      showToast("Event created.")
    },
  })
}

export function useModifyEvent() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: Event["id"]; data: EventModifyPayload }) => api.event.modify(id, data),
    {
      onSuccess: ({ event }, { data }) => {
        queryClient.setQueryData<EventFull[]>(EVENT_ALL_QK, data => {
          if (!data) return

          const index = data.findIndex(({ id }) => id === event.id)

          return index === -1 ? data.concat(event) : update(index, event, data)
        })
        showToast(
          equals(data, { is_hidden: 1 })
            ? "Event hidden."
            : equals(data, { is_hidden: 0 })
            ? "Event visible."
            : "Event modified.",
        )
      },
    },
  )
}

export function useFetchMEEndpointOptions() {
  const { data } = useFetchSystemInfo()

  return useQuery(["MEEndpoints"], api.event.autoload.endpoints, {
    select: map(endpoint => ({ value: endpoint, label: endpoint })),
    enabled: !!data?.me_connection_url,
  })
}

export function useDryRun(payload: EventDryRunPayload) {
  return useQuery(["autoloadDryRun", payload], () => api.event.autoload.dryRun(payload), {
    enabled: false,
    retry: false,
    keepPreviousData: true,
  })
}

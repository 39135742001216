import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SelectField from "components/UI/elements/SelectField"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import { useFetchEventById, useFetchEventOptions } from "resources/event/eventQueries"
import styles from "./EventConditionPath.module.scss"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { integer, required } from "helpers/validators.helper"
import { EventConditionPathFormValues } from "../formValues"
import { Fragment } from "react"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import EventCondition from "pages/JourneyCanvas/JourneyDetail/components/EventCondition/EventCondition"
import Button from "components/UI/elements/Button/Button"

type EventConditionFormProps = {
  pathIndex: number
  isEditable: boolean
}

const getNewCondition = () => ({
  parameter_id: "",
  operation: "",
  value: "",
})

const MAX_CONDITIONS = 10

export default function EventConditionPath({ pathIndex, isEditable }: EventConditionFormProps) {
  const {
    control,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<{
    paths: EventConditionPathFormValues[]
  }>()
  const {
    fields: conditionFields,
    append: appendCondition,
    remove: removeCondition,
  } = useFieldArray({ control, name: `paths.${pathIndex}.conditions_operation` })

  const eventOptionsQuery = useFetchEventOptions()

  const eventId = watch(`paths.${pathIndex}.event_id`)
  const eventQuery = useFetchEventById(eventId)

  return (
    <>
      <Controller
        control={control}
        name={`paths.${pathIndex}.event_id`}
        rules={{ validate: { required } }}
        render={({ field }) =>
          eventOptionsQuery.isLoading ? (
            <LoadingIndicator />
          ) : (
            <div className={styles.inputRow}>
              <SelectField
                input={{
                  value: field.value,
                  onChange(value: string) {
                    setValue(`paths.${pathIndex}.conditions_operation`, [])
                    field.onChange(value)
                  },
                }}
                label="Event"
                options={eventOptionsQuery.data ?? []}
                isSimpleValue
                className={styles.eventSelect}
                error={errors.paths?.[pathIndex]?.event_id?.message}
              />
              <div className={styles.sinceInput}>
                <TextInput
                  {...register(`paths.${pathIndex}.since.count`, {
                    valueAsNumber: true,
                    validate: {
                      required,
                      min(value, values) {
                        if (values.paths[pathIndex].since.unit === "minutes" && value < 1) {
                          return "Minimum is 1 minute"
                        }

                        if (
                          (values.paths[pathIndex].since.unit === "days" && value > 30) ||
                          (values.paths[pathIndex].since.unit === "hours" && value > 24 * 30) ||
                          (values.paths[pathIndex].since.unit === "minutes" && value > 60 * 24 * 30)
                        ) {
                          return "Maximum is 30 days"
                        }
                      },
                      integer,
                    },
                  })}
                  error={errors.paths?.[pathIndex]?.since?.count?.message}
                  disabled={!isEditable}
                  label="In the last"
                  type="number"
                  className={styles.countInput}
                  min={1}
                />
                <Controller
                  control={control}
                  name={`paths.${pathIndex}.since.unit`}
                  rules={{ validate: { required } }}
                  render={({ field }) => (
                    <SelectField
                      input={field}
                      options={[
                        { value: "minutes", label: "Minutes" },
                        { value: "hours", label: "Hours" },
                        { value: "days", label: "Days" },
                      ]}
                      isSimpleValue
                      label=" "
                      error={errors.paths?.[pathIndex]?.since?.unit?.message}
                      className={styles.unitInput}
                      disabled={!isEditable}
                    />
                  )}
                />
              </div>
            </div>
          )
        }
      />

      {eventQuery.data && (
        <div>
          <div className={styles.label}>Conditions</div>

          <div className={styles.list}>
            {conditionFields.map((field, index) => (
              <Fragment key={field.id}>
                {index !== 0 && (
                  <Controller
                    name={`paths.${pathIndex}.operator`}
                    control={control}
                    render={({ field }) => (
                      <ToggleSwitch
                        leftValue={"and"}
                        rightValue={"or"}
                        checked={field.value}
                        handleToggle={() => field.onChange(field.value === "and" ? "or" : "and")}
                        disabled={!isEditable}
                        width="80px"
                        size="small"
                      />
                    )}
                  />
                )}

                <EventCondition<{ paths: EventConditionPathFormValues[] }>
                  event={eventQuery.data!}
                  isEditable={isEditable}
                  removeSelf={() => removeCondition(index)}
                  path={`paths.${pathIndex}.conditions_operation.${index}`}
                />
              </Fragment>
            ))}

            {conditionFields.length < MAX_CONDITIONS && isEditable && (
              <Button
                onClick={() => appendCondition(getNewCondition())}
                variant="outlined"
                color="grey"
                className={styles.addButton}
              >
                + Add condition
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  )
}

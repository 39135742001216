import { useMemo } from "react"
import { CarouselContent } from "./CarouselContent"
import styles from "./AuthCarousel.module.scss"
import img from "../../../images/login/Image.png"
import img1 from "../../../images/login/Image-1.png"
import img2 from "../../../images/login/Image-2.png"
import img3 from "../../../images/login/Image-3.png"
import img4 from "../../../images/login/Image-4.png"

const DATA = [
  {
    image: img,
    description: {
      title: "Product replenishment reminders",
      text: "Keep your customers loyal and make them come back for more with timely reminders to replenish their favorite products!",
      learnMoreHref: "https://www.meiro.io/use-case/product-replenishment/",
    },
  },
  {
    image: img1,
    description: {
      title: "Abandoned basket",
      text: "Don't let abandoned carts become missed opportunities – recover lost sales with personalized reminders!",
      learnMoreHref: "https://www.meiro.io/use-case/abandoned-basket/",
    },
  },
  {
    image: img3,
    description: {
      title: "Personalized exit-intent popups",
      text: "Most users leave without converting. Personalize an exit popup to nudge users towards a purchase of the items they viewed before they leave your site.",
      learnMoreHref: "https://www.meiro.io/use-case/personalized-exit-intent-pop-ups/",
    },
  },
  {
    image: img2,
    description: {
      title: "Expand reach with lookalike audiences",
      text: "Capture your best customers in segments and model lookalike audiences off them for more efficient lead-generation campaigns and ad performance.",
      learnMoreHref: "https://www.meiro.io/use-case/expand-reach-and-discover-more-leads/?tag=bfsi",
    },
  },
  {
    image: img4,
    description: {
      title: "Personalize CX to anonymous visitors",
      text: "Increase conversion rates of unauthenticated users with web personalization.",
      learnMoreHref:
        "https://www.meiro.io/use-case/personalize-cx-to-anonymous-visitors/?tag=e-commerce-and-retail",
    },
  },
]

const shuffleData = <T,>(array: T[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[randomIndex]] = [array[randomIndex], array[i]]
  }
  return array
}

export const AuthCarousel = () => {
  const shuffledData = useMemo(() => shuffleData(DATA), [])

  return (
    <div className={styles.carousel}>
      <CarouselContent data={shuffledData} />
    </div>
  )
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { format, isToday, isYesterday } from "date-fns"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import { DATE_FMT } from "sharedConstants"
import NotificationCard from "components/UI/components/Notification/Notification"
import styles from "./NotificationsDropdown.module.scss"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import useClickOutHandler from "hooks/useClickOutHandler"
import Tippy from "@tippyjs/react"
import { useFetchNotifications, useMarkAllAsRead } from "resources/notification/notificationQueries"
import { groupBy } from "ramda"

export default function NotificationsDropdown({ isAltPosition }: { isAltPosition?: boolean }) {
  const { hasNextPage = false, fetchNextPage, data: notifications = [] } = useFetchNotifications()
  const { mutate: markAllAsRead } = useMarkAllAsRead()
  const [showUnreadBadge, setShowUnreadBadge] = useState(false)

  useEffect(() => {
    if (!isDropdownOpen) {
      setShowUnreadBadge(notifications.some(n => !n.read))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications.length])

  const openCallback = useCallback(() => {
    setShowUnreadBadge(false)
  }, [])

  const closeCallback = useCallback(() => {
    if (notifications.some(n => !n.read)) {
      markAllAsRead()
    }
  }, [markAllAsRead, notifications])

  const {
    isOpen: isDropdownOpen,
    toggle: toggleDropdown,
    close: closeDropdown,
    ref: dropdownRef,
    buttonRef,
  } = useClickOutHandler({ openCallback, closeCallback })

  const notifsGroupedByDate = useMemo(
    () =>
      Object.entries(groupBy(n => n.timestamp.slice(0, 10), notifications)).sort(
        ([date1], [date2]) => new Date(date2).getTime() - new Date(date1).getTime(),
      ),
    [notifications],
  )

  return (
    <div className={classNames(styles.container, { [styles.altPosition]: isAltPosition })}>
      <Tippy content="Notifications" placement={isAltPosition ? "right" : "bottom"}>
        <button
          className={classNames(styles.button, { [styles.isOpen]: isDropdownOpen })}
          onClick={toggleDropdown}
          ref={buttonRef}
        >
          <FontAwesomeIcon
            className={styles.icon}
            icon={[isDropdownOpen ? "fas" : "far", "bell"]}
          />
        </button>
      </Tippy>
      {showUnreadBadge && <div className={styles.unreadBadge}></div>}
      {isDropdownOpen && (
        <div className={styles.dropdown} ref={dropdownRef}>
          <div className={styles.dropdownHeader}>
            <div className={styles.title}>notifications</div>
            <Link
              to={getRoutePath("home")}
              onClick={closeDropdown}
              className={styles.dashboardLink}
            >
              view on homepage <FontAwesomeIcon icon={["fas", "arrow-right"]} />
            </Link>
          </div>
          <div className={styles.dropdownBody} id="dropdownBody">
            {notifsGroupedByDate.length === 0 ? (
              <div className={styles.emptyMessage}>There are no notifications.</div>
            ) : (
              <InfiniteScroll
                dataLength={notifsGroupedByDate.length}
                next={fetchNextPage}
                hasMore={hasNextPage}
                scrollThreshold="100px"
                loader={
                  <div className={styles.loadingMore}>
                    <LoadingIndicator />
                  </div>
                }
                scrollableTarget="dropdownBody"
              >
                {notifsGroupedByDate.map(([date, notifs]) => {
                  const dateObject = new Date(date)
                  let formattedDate = format(dateObject, DATE_FMT.DATE)
                  if (isToday(dateObject)) formattedDate = "TODAY"
                  if (isYesterday(dateObject)) formattedDate = "YESTERDAY"

                  return (
                    <div key={date} className={styles.dateGroup}>
                      <div className={styles.dateGroupHeader}>{formattedDate}</div>
                      {notifs.map(notification => (
                        <NotificationCard
                          notification={notification}
                          key={notification.id}
                          isDropdown
                          onLinkClick={closeDropdown}
                        />
                      ))}
                    </div>
                  )
                })}
              </InfiniteScroll>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

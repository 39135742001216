import React, { useEffect, useState } from "react"
import classnames from "classnames"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import { Link, Redirect, useLocation } from "react-router-dom"
import { login, useAuthTokenStore } from "auth/auth"
import AuthFormLayout from "components/AuthFormLayout/AuthFormLayout"
import Button from "components/UI/elements/Button/Button"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { required, email } from "helpers/validators.helper"
import useLocalStorage from "hooks/useLocalStorage"
import { getRoutePath } from "routes"
import { REDIRECT_AFTER_OKTA_LOGIN_LS_KEY } from "sharedConstants"
import styles from "./Login.module.scss"
import Tippy from "@tippyjs/react"

type FormValues = {
  email: string
  password: string
}

let credentialsLoginEnabled: number | string | undefined = 0,
  oktaLoginEnabled: number | string | undefined = 0,
  recaptchaSiteKey: string | null | undefined = null
if (process.env.NODE_ENV === "production") {
  credentialsLoginEnabled = "[[CREDENTIALS_LOGIN_ENABLED]]"
  oktaLoginEnabled = "[[OKTA_LOGIN_ENABLED]]"
  recaptchaSiteKey = "[[RECAPTCHA_SITE_KEY]]"
} else {
  credentialsLoginEnabled = process.env.REACT_APP_CREDENTIALS_LOGIN_ENABLED
  oktaLoginEnabled = process.env.REACT_APP_OKTA_LOGIN_ENABLED
  recaptchaSiteKey =
    process.env.REACT_APP_RECAPTCHA_SITE_KEY === '""'
      ? ""
      : process.env.REACT_APP_RECAPTCHA_SITE_KEY
}

export default function Login() {
  const [generalTermsCheckboxValue, setGeneralTermsCheckboxValue] = useState(false)
  const [showRecaptcha, setShowRecaptcha] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const location = useLocation()
  const [, setRedirectAfterSsoLogin] = useLocalStorage<string | null>(
    REDIRECT_AFTER_OKTA_LOGIN_LS_KEY,
    null,
  )
  const [cdpGeneralTermsAccepted, setCdpGeneralTermsAccepted] = useLocalStorage(
    "cdpGeneralTermsAccepted",
    false,
  )

  useEffect(() => {
    document.body.classList.add("toastify-on-top")

    return () => {
      document.body.classList.remove("toastify-on-top")
    }
  }, [])

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<FormValues>({
    defaultValues: { email: "", password: "" },
  })

  useEffect(() => {
    if (!loading && isSubmitSuccessful) reset()
  }, [loading, isSubmitSuccessful, reset])

  const { authToken } = useAuthTokenStore()

  const onSubmit = (values: FormValues) => {
    if (!loading) {
      const variables = recaptchaToken ? { ...values, recaptcha_token: recaptchaToken } : values

      setLoading(true)
      login(variables)
        .then(() => {
          if (!cdpGeneralTermsAccepted) setCdpGeneralTermsAccepted(true)
        })
        .catch((error: { response?: { data?: { show_recaptcha: boolean } } }) => {
          if (error.response?.data?.show_recaptcha) {
            setShowRecaptcha(true)
            setLoading(true)
          } else setLoading(false)
        })
    }
  }

  const loginWithSso = () => {
    try {
      const urlParams = new URLSearchParams(location.search)
      const redirect = urlParams.get("redirect")

      setRedirectAfterSsoLogin(redirect)
    } catch {
      // noop
    }

    window.open(`${window.location.origin}${getRoutePath("sso.login")}`, "_self")
  }

  const renderGeneralTermsCheckbox = () => (
    <div className={styles.generalTerms}>
      <Checkbox
        centered={false}
        checked={generalTermsCheckboxValue}
        label={
          <>
            I consent with{" "}
            <a href="https://meiro.io/general-terms/" target="_blank" rel="noopener noreferrer">
              Meiro General Terms
            </a>{" "}
            and{" "}
            <a
              href="https://meiro.io/user-security-guidelines/"
              target="_blank"
              rel="noopener noreferrer"
            >
              User Security Guidelines
            </a>
          </>
        }
        data-testid="agreement-checkbox"
        onChange={_ => setGeneralTermsCheckboxValue(prev => !prev)}
      />
    </div>
  )

  const showLoginForm = Number(credentialsLoginEnabled) === 1
  const showSsoButton = Number(oktaLoginEnabled) === 1
  const showGeneralTermsCheckbox = cdpGeneralTermsAccepted === false

  const renderSsoButton = () => (
    <div className={styles.ssoLoginContainer}>
      <Button
        fullWidth
        size="md"
        icon="lock-keyhole"
        onClick={loginWithSso}
        disabled={showGeneralTermsCheckbox && !generalTermsCheckboxValue}
        className={styles.ssoLogin}
      >
        Login with SSO
      </Button>
      {showLoginForm && (
        <div className={styles.ssoLoginSeparator}>
          <div className={styles.ssoSeperator}></div>
          <div className={styles.ssoSeperatorOr}>OR</div>
          <div className={styles.ssoSeperator}></div>
        </div>
      )}
    </div>
  )

  if (authToken) {
    return (
      <Redirect
        to={new URLSearchParams(window.location.search).get("redirect") ?? getRoutePath("home")}
      />
    )
  }

  return (
    <AuthFormLayout>
      <div className={styles.wrapper}>
        {showLoginForm && (
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.header}>
              <h1>
                Login into <br /> Business explorer
              </h1>
              <p>
                To learn more about cool things you can do with Meiro, visit our{" "}
                <a
                  className={styles.resourcesLink}
                  href="https://www.meiro.io/use-cases/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  resources
                </a>
                .
              </p>
            </div>

            {showSsoButton && renderSsoButton()}

            <div className="form-row">
              <TextInput
                autoFocus
                error={errors.email?.message}
                placeholder="Email"
                {...register("email", { validate: { required, email } })}
              />
            </div>
            <div className="form-row">
              <TextInput
                error={errors.password?.message}
                placeholder="Password"
                type="password"
                {...register("password", { validate: { required } })}
              />
            </div>
            {showRecaptcha && recaptchaSiteKey && (
              <div className={classnames("form-row", styles.recaptcha)}>
                <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={setRecaptchaToken} />
              </div>
            )}

            <div
              className={classnames(styles.actions, {
                [styles.withCheckbox]: showGeneralTermsCheckbox,
              })}
            >
              <Tippy
                content="You need to agree with General terms & User Security Guidelines"
                disabled={!showGeneralTermsCheckbox || generalTermsCheckboxValue}
              >
                <div>
                  <Button
                    data-testid="login-button"
                    size="md"
                    type="submit"
                    fullWidth
                    loading={loading}
                    disabled={
                      (showGeneralTermsCheckbox && !generalTermsCheckboxValue) ||
                      (showRecaptcha && !recaptchaToken)
                    }
                    className={classnames({ [styles.login]: showSsoButton })}
                  >
                    Login
                  </Button>
                </div>
              </Tippy>
              {showGeneralTermsCheckbox && renderGeneralTermsCheckbox()}
              <Link
                className={styles.underformLink}
                data-testid="forgot-password-link"
                to={getRoutePath("password.reset")}
              >
                Forgot password?
              </Link>
            </div>
          </form>
        )}
        {!showLoginForm && showSsoButton && (
          <>
            {renderSsoButton()}
            {showGeneralTermsCheckbox && renderGeneralTermsCheckbox()}
          </>
        )}
      </div>
    </AuthFormLayout>
  )
}

import React from "react"
import classNames from "classnames"
import { uniq } from "ramda"

import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import Paper from "components/UI/elements/Paper"
import { Source } from "resources/dataSource/dataSourceTypes"
import { ActiveEventIds, ActiveSourceIds } from "../types"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import { EventFull } from "resources/event/eventTypes"

import styles from "./SourceFilter.module.scss"

type SourceFilterProps = {
  activeEventIds: ActiveEventIds
  activeSourceIds: ActiveSourceIds
  sources: Array<Source>
  events: Array<EventFull>
  setActiveEventIds: (eventIds: ActiveEventIds) => void
  setActiveSourceIds: (sourceIds: ActiveSourceIds) => void
  layoutPicker?: React.ReactNode
}

export default function SourceFilter({
  activeEventIds,
  activeSourceIds,
  setActiveEventIds,
  setActiveSourceIds,
  sources,
  events,
  layoutPicker,
}: SourceFilterProps) {
  const onSourceToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [, sourceId] = event.currentTarget.name.split(".")

    const isActive = activeSourceIds.includes(sourceId)
    const newFilter = isActive
      ? activeSourceIds.filter(id => id !== sourceId)
      : activeSourceIds.concat(sourceId)

    setActiveSourceIds(newFilter)

    const sourceEvents = events.filter(({ source }) => source.id === sourceId)
    const sourceEventIds = sourceEvents.map(({ id }) => id)

    if (isActive) setActiveEventIds(activeEventIds.filter(id => !sourceEventIds.includes(id)))
    else setActiveEventIds(uniq(activeEventIds.concat(sourceEventIds)))
  }

  return (
    <div className={styles.container}>
      <Paper hasHeader={true} className={styles.paper}>
        <h3>Sources</h3>
        <div className={styles.sourcesWrapper}>
          <div className={styles.sources}>
            {sources.map(source => {
              const isActive = activeSourceIds.includes(source.id)

              const color = source.frontend_settings?.color

              return (
                <div key={source.id} className={styles.source}>
                  <Checkbox
                    checked={isActive}
                    id={`sources.${source.id}`}
                    label={
                      <>
                        <div
                          className={classNames(styles.sourceIcon, styles[color], {
                            [styles.checked]: isActive,
                            [styles.disabled]: !isActive,
                          })}
                        >
                          <SrcDstIcon source={source} white />
                        </div>
                        {source.name}
                      </>
                    }
                    name={`sources.${source.id}`}
                    variant="secondary"
                    onChange={onSourceToggle}
                    className={styles.checkbox}
                  />
                </div>
              )
            })}
          </div>
          {layoutPicker}
        </div>
      </Paper>
    </div>
  )
}

import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tippy from "@tippyjs/react"

import "./ColorRadioGroup.scss"
import classNames from "classnames"

class ColorRadioGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isHovering: null,
    }
  }

  handleMouseHover = color => () => {
    if (this.props.disabled) {
      return
    }
    this.setState({
      isHovering: color,
    })
  }

  render() {
    const {
      input,
      meta: { touched, error } = {},
      colors,
      label = "",
      alreadyUsedColors = [],
      disabled,
    } = this.props
    const { isHovering } = this.state
    const errorMessage = touched ? error : ""
    return (
      <div className="colors-wrapper">
        {label && <label>{label}</label>}
        <div
          className={classNames("colors-options-group", {
            active: isHovering || input.value,
            disabled,
          })}
        >
          {colors.map(color => {
            const inputContent = (
              <React.Fragment key={color}>
                <input
                  {...input}
                  type="radio"
                  id={color}
                  value={color}
                  className="color-opt"
                  checked={color === input.value}
                  disabled={disabled}
                />
                <label
                  htmlFor={color}
                  onMouseEnter={this.handleMouseHover(color)}
                  onMouseLeave={this.handleMouseHover()}
                  className={isHovering === color || input.value === color ? "marked" : ""}
                >
                  <span className={color}>
                    {input.value === color && (
                      <FontAwesomeIcon icon={["fas", "check"]} className="check-icon" />
                    )}
                  </span>
                </label>
              </React.Fragment>
            )

            if (alreadyUsedColors.includes(color) && color !== input.value) {
              return (
                <Tippy key={color} content="The color is already in use.">
                  <span className="already-used">{inputContent}</span>
                </Tippy>
              )
            } else {
              return inputContent
            }
          })}
        </div>
        {errorMessage && <p className="error-message">Pick one of the colors please.</p>}
      </div>
    )
  }
}

ColorRadioGroup.propTypes = {
  colors: PropTypes.array.isRequired,
  label: PropTypes.string,
}

export default ColorRadioGroup

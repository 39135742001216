import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import LookalikeSegmentDetail from "./LookalikeSegmentDetail/LookalikeSegmentDetail"
import LookalikeSegmentsList from "./LookalikeSegmentsList/LookalikeSegmentsList"
import Trash from "pages/Trash/Trash"
import {
  useFetchLookalikeSegmentTrashItems,
  useRestoreLookalikeSegment,
} from "resources/segment/segment/segmentQueries"

export default function LookalikeSegments() {
  return (
    <Switch>
      <Route path={getRoutePath("segments.lookalike")} exact component={LookalikeSegmentsList} />
      <Route
        path={getRoutePath("segments.lookalike.trash")}
        exact
        render={() => (
          <Trash
            backRoute={getRoutePath("segments.lookalike")}
            itemType="lookalike_segments"
            fetchQuery={useFetchLookalikeSegmentTrashItems}
            restoreMutation={useRestoreLookalikeSegment}
          />
        )}
      />
      <Route path={getRoutePath("segments.lookalike.detail")} component={LookalikeSegmentDetail} />
    </Switch>
  )
}

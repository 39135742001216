import requestFactory from "api/request"
import {
  MobilePushExport,
  MobilePushExports,
  MobilePushNotification,
} from "../mobilePushNotificationTypes"
import { Segment } from "resources/segment/segment/segmentTypes"

const exportEndpoints = {
  list({
    id,
    offset,
    limit = 20,
  }: {
    id: MobilePushNotification["id"]
    limit: MobilePushExports["selection_settings"]["limit"]
    offset: MobilePushExports["selection_settings"]["offset"]
  }): Promise<MobilePushExports> {
    return requestFactory("get", `/push_notifications/${id}/exports`, { limit, offset })
  },
  create(id: MobilePushNotification["id"], segmentIds: Array<Segment["id"]>): Promise<void> {
    return requestFactory("post", `/push_notifications/${id}/manual_activation`, {
      segment_ids: segmentIds,
    })
  },
  retrieve(
    pushNotificationId: MobilePushNotification["id"],
    exportId: MobilePushExport["id"],
  ): Promise<{ push_notification_export: MobilePushExport }> {
    return requestFactory("get", `/push_notifications/${pushNotificationId}/exports/${exportId}`)
  },
  delete(
    pushNotificationId: MobilePushNotification["id"],
    exportId: MobilePushExport["id"],
  ): Promise<{ push_notification_export: MobilePushExport }> {
    return requestFactory("delete", `/push_notifications/${pushNotificationId}/exports/${exportId}`)
  },
  test({
    mobilePushNotificationId,
    profileId,
    registrationTokens,
  }: {
    mobilePushNotificationId: MobilePushNotification["id"]
    profileId: string
    registrationTokens: Array<string>
  }): Promise<void> {
    return requestFactory("post", `/push_notifications/${mobilePushNotificationId}/activations`, {
      customer_id: profileId,
      registration_tokens: registrationTokens,
    })
  },
}

export default exportEndpoints

import { formatISO, isSameDay, isToday, parseISO, subDays } from "date-fns"
import { DateString } from "types/util"

export function getISODateDaysAgo(minusDays: number) {
  return formatISO(subDays(new Date(), minusDays), { representation: "date" })
}

export function getISODateToday() {
  return getISODateDaysAgo(0)
}

export function isLastDays(
  startDate: DateString | Date | null,
  endDate: DateString | Date | null,
  number: number,
): boolean {
  if (!startDate || !endDate) return false

  const parsedStartDate = typeof startDate === "string" ? parseISO(startDate) : startDate
  const parsedEndDate = typeof endDate === "string" ? parseISO(endDate) : endDate
  const today = new Date()

  return isToday(parsedEndDate) && isSameDay(parsedStartDate, subDays(today, number - 1))
}

import {
  QueryKey,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import { api } from "api"
import {
  ActivateEmailsChannelOptInPayload,
  EmailsChannel,
  EmailsChannelResponse,
  ListPushNotificationAppsResponse,
  FirebaseAppListResponse,
  ModifyEmailsChannelFrequencyCapPayload,
  ModifyEmailsChannelOptInPayload,
  ModifyEmailsChannelProviderConfigPayload,
  ModifyEmailsChannelStitchingCategoryPayload,
  ModifyEmailsChannelUnsubscribeBlock,
  ModifyPushNotificationsChannelCustomDataSchemaPayload,
  ModifyPushNotificationsChannelFrequencyCapPayload,
  ModifyPushNotificationsChannelStitchingCategoryPayload,
  PushNotificationApp,
  PushNotificationAppPayload,
  PushNotificationsChannel,
  PushNotificationsChannelReponse,
  FirebaseProject,
  FirebaseProjectListResponse,
} from "./channelTypes"
import { update, whereEq } from "ramda"

const CHANNEL = "channel" as const

const APPS = "apps" as const
const CHANNEL_PUSH_NOTIFICATIONS_QK: QueryKey = [CHANNEL, "pushNotifications"] as const
const CHANNEL_PUSH_NOTIFICATIONS_APPS_QK: QueryKey = [
  ...CHANNEL_PUSH_NOTIFICATIONS_QK,
  APPS,
] as const
const CHANNEL_PUSH_NOTIFICATIONS_FIREBASE_PROJECTS_QK: QueryKey = [
  ...CHANNEL_PUSH_NOTIFICATIONS_QK,
  "firebaseProjects",
]
const CHANNEL_EMAILS_QK: QueryKey = [CHANNEL, "emails"] as const

export function useFetchChannels() {
  return useQuery([CHANNEL, "all"], () => api.channel.list(), {
    select: ({ channels }) => channels,
  })
}

export function useFetchPushNotificationsChannel(
  config?: UseQueryOptions<
    PushNotificationsChannelReponse,
    unknown,
    PushNotificationsChannel,
    QueryKey
  >,
) {
  return useQuery(CHANNEL_PUSH_NOTIFICATIONS_QK, api.channel.pushNotifications.retrieve, {
    ...config,
    select: ({ push_notification_channel }) => push_notification_channel,
  })
}

export const useModifyPushNotificationsChannelFrequencyCap = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyPushNotificationsChannelFrequencyCapPayload }) =>
      api.channel.pushNotifications.modifyFrequencyCap(data),
    {
      onSuccess: ({ push_notification_channel }) => {
        queryClient.setQueryData(CHANNEL_PUSH_NOTIFICATIONS_QK, { push_notification_channel })
        showToast("Frequency cap modified.")
      },
    },
  )
}

export const useModifyPushNotificationsChannelStitchingCategory = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyPushNotificationsChannelStitchingCategoryPayload }) =>
      api.channel.pushNotifications.modifyStitchingCategory(data),
    {
      onSuccess: ({ push_notification_channel }) => {
        queryClient.setQueryData(CHANNEL_PUSH_NOTIFICATIONS_QK, { push_notification_channel })
        showToast("Stitching category modified.")
      },
    },
  )
}

export const useModifyPushNotificationsChannelCustomDataSchema = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyPushNotificationsChannelCustomDataSchemaPayload }) =>
      api.channel.pushNotifications.modifyCustomDataSchema(data),
    {
      onSuccess: ({ push_notification_channel }) => {
        queryClient.setQueryData(CHANNEL_PUSH_NOTIFICATIONS_QK, { push_notification_channel })
        showToast("Custom data schema modified.")
      },
    },
  )
}

export const useFetchFirebaseProjects = () =>
  useQuery(
    CHANNEL_PUSH_NOTIFICATIONS_FIREBASE_PROJECTS_QK,
    () => api.channel.pushNotifications.firebaseProjects.list(),
    { select: data => data.firebase_projects },
  )

export const useCreateFirebaseProject = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: FormData }) => api.channel.pushNotifications.firebaseProjects.create(data),
    {
      onSuccess: ({ firebase_project }) => {
        queryClient.setQueryData<FirebaseProjectListResponse>(
          CHANNEL_PUSH_NOTIFICATIONS_FIREBASE_PROJECTS_QK,
          data => {
            if (!data) return

            return { ...data, firebase_projects: data.firebase_projects.concat(firebase_project) }
          },
        )
        showToast("Firebase project created.")
      },
    },
  )
}

export const useModifyFirebaseProject = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: FirebaseProject["id"]; data: FormData }) =>
      api.channel.pushNotifications.firebaseProjects.modify(id, data),
    {
      onSuccess: ({ firebase_project }) => {
        queryClient.setQueryData<FirebaseProjectListResponse>(
          CHANNEL_PUSH_NOTIFICATIONS_FIREBASE_PROJECTS_QK,
          data => {
            if (!data) return

            const index = data.firebase_projects.findIndex(whereEq({ id: firebase_project.id }))

            const firebase_projects =
              index === -1
                ? data.firebase_projects.concat(firebase_project)
                : update(index, firebase_project, data.firebase_projects)

            return { ...data, firebase_projects }
          },
        )
        showToast("Firebase project modified.")
      },
    },
  )
}

export const useFetchPushNotificationApps = () =>
  useQuery(CHANNEL_PUSH_NOTIFICATIONS_APPS_QK, () => api.channel.pushNotifications.apps.list(), {
    select: data => data.push_notifications_apps,
  })

export const useFetchPushNotificationAppsByFirebaseProjectId = (id: FirebaseProject["id"]) =>
  useQuery(
    [...CHANNEL_PUSH_NOTIFICATIONS_FIREBASE_PROJECTS_QK, id, APPS],
    () => api.channel.pushNotifications.firebaseProjects.apps.list(id),
    {
      select: data => data.push_notifications_apps,
    },
  )

export const useCreatePushNotificationApp = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      firebaseProjectId,
      data,
    }: {
      firebaseProjectId: FirebaseProject["id"]
      data: PushNotificationAppPayload
    }) => api.channel.pushNotifications.firebaseProjects.apps.create(firebaseProjectId, data),
    {
      onSuccess: ({ push_notifications_app }, { firebaseProjectId }) => {
        queryClient.setQueryData<ListPushNotificationAppsResponse>(
          CHANNEL_PUSH_NOTIFICATIONS_APPS_QK,
          data => {
            if (!data) return

            return {
              ...data,
              push_notifications_apps: data.push_notifications_apps.concat(push_notifications_app),
            }
          },
        )

        queryClient.setQueryData<ListPushNotificationAppsResponse>(
          [...CHANNEL_PUSH_NOTIFICATIONS_FIREBASE_PROJECTS_QK, firebaseProjectId, APPS],
          data => {
            if (!data) return

            return {
              ...data,
              push_notifications_apps: data.push_notifications_apps.concat(push_notifications_app),
            }
          },
        )

        queryClient.invalidateQueries({
          queryKey: CHANNEL_PUSH_NOTIFICATIONS_FIREBASE_PROJECTS_QK,
          exact: true,
        })

        showToast("App created.")
      },
    },
  )
}

export const useModifyPushNotificationApp = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      firebaseProjectId,
      appId,
      data,
    }: {
      firebaseProjectId: FirebaseProject["id"]
      appId: PushNotificationApp["id"]
      data: PushNotificationAppPayload
    }) =>
      api.channel.pushNotifications.firebaseProjects.apps.modify({
        appId,
        data,
        firebaseProjectId,
      }),
    {
      onSuccess: ({ push_notifications_app }, { firebaseProjectId }) => {
        queryClient.setQueryData<ListPushNotificationAppsResponse>(
          CHANNEL_PUSH_NOTIFICATIONS_APPS_QK,
          data => {
            if (!data) return

            const index = data.push_notifications_apps.findIndex(
              ({ id }) => id === push_notifications_app.id,
            )

            const pushNotificationApps =
              index === -1
                ? data.push_notifications_apps.concat(push_notifications_app)
                : update(index, push_notifications_app, data.push_notifications_apps)

            return { ...data, push_notifications_apps: pushNotificationApps }
          },
        )

        queryClient.setQueryData<ListPushNotificationAppsResponse>(
          [...CHANNEL_PUSH_NOTIFICATIONS_FIREBASE_PROJECTS_QK, firebaseProjectId, APPS],
          data => {
            if (!data) return

            const index = data.push_notifications_apps.findIndex(
              ({ id }) => id === push_notifications_app.id,
            )

            const pushNotificationApps =
              index === -1
                ? data.push_notifications_apps.concat(push_notifications_app)
                : update(index, push_notifications_app, data.push_notifications_apps)

            return { ...data, push_notifications_apps: pushNotificationApps }
          },
        )

        queryClient.invalidateQueries({
          queryKey: CHANNEL_PUSH_NOTIFICATIONS_FIREBASE_PROJECTS_QK,
          exact: true,
        })

        showToast("App modified.")
      },
    },
  )
}

export const useDeletePushNotificationApp = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      firebaseProjectId,
      appId,
    }: {
      firebaseProjectId: FirebaseProject["id"]
      appId: PushNotificationApp["id"]
    }) => api.channel.pushNotifications.firebaseProjects.apps.delete(firebaseProjectId, appId),
    {
      onSuccess: (_, { appId, firebaseProjectId }) => {
        queryClient.setQueryData<ListPushNotificationAppsResponse>(
          CHANNEL_PUSH_NOTIFICATIONS_APPS_QK,
          data => {
            if (!data) return

            const pushNotificationApps = data.push_notifications_apps.filter(
              item => item.id !== appId,
            )
            return { ...data, push_notifications_apps: pushNotificationApps }
          },
        )
        queryClient.setQueryData<ListPushNotificationAppsResponse>(
          [...CHANNEL_PUSH_NOTIFICATIONS_FIREBASE_PROJECTS_QK, firebaseProjectId, APPS],
          data => {
            if (!data) return

            const apps = data.push_notifications_apps.filter(({ id }) => id !== appId)
            return { ...data, push_notifications_apps: apps }
          },
        )

        queryClient.invalidateQueries({
          queryKey: CHANNEL_PUSH_NOTIFICATIONS_FIREBASE_PROJECTS_QK,
          exact: true,
        })

        showToast("App deleted.")
      },
    },
  )
}

function useFetchPushNotificationFirebaseAppsQuery<T = FirebaseAppListResponse>(
  id: FirebaseProject["id"],
  config?: UseQueryOptions<FirebaseAppListResponse, unknown, T, QueryKey>,
) {
  return useQuery(
    [...CHANNEL_PUSH_NOTIFICATIONS_FIREBASE_PROJECTS_QK, id, "firebaseApps"] as QueryKey,
    () => api.channel.pushNotifications.firebaseProjects.firebase_apps.list(id),
    { ...config },
  )
}

export const useFetchPushNotificationAndroidOptions = (id: FirebaseProject["id"]) =>
  useFetchPushNotificationFirebaseAppsQuery(id, {
    select: data =>
      data.firebase_apps.android_apps.map(({ app_id, name }) => ({
        label: name,
        value: app_id,
      })),
  })

export const useFetchPushNotificationIosOptions = (id: FirebaseProject["id"]) =>
  useFetchPushNotificationFirebaseAppsQuery(id, {
    select: data =>
      data.firebase_apps.ios_apps.map(({ app_id, name }) => ({
        label: name,
        value: app_id,
      })),
  })

export function useFetchEmailChannel(
  config?: UseQueryOptions<EmailsChannelResponse, unknown, EmailsChannel, QueryKey>,
) {
  return useQuery(CHANNEL_EMAILS_QK, api.channel.emails.retrieve, {
    ...config,
    select: ({ email_channel }) => email_channel,
  })
}

export function useModifyEmailsChannelFrequencyCap() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyEmailsChannelFrequencyCapPayload }) =>
      api.channel.emails.modifyFrequencyCap(data),
    {
      onSuccess: ({ email_channel }) => {
        queryClient.setQueryData(CHANNEL_EMAILS_QK, { email_channel })
        showToast("Channel frequency cap modified.")
      },
    },
  )
}

export function useModifyEmailsChannelProviderConfig() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyEmailsChannelProviderConfigPayload }) =>
      api.channel.emails.modifyProviderConfig(data),
    {
      onSuccess: ({ email_channel }) => {
        queryClient.setQueryData(CHANNEL_EMAILS_QK, { email_channel })
        showToast("Channel provider config modified.")
      },
    },
  )
}

export function useModifyEmailsChannelStitchingCategory() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyEmailsChannelStitchingCategoryPayload }) =>
      api.channel.emails.modifyStitchingCategory(data),
    {
      onSuccess: ({ email_channel }) => {
        queryClient.setQueryData(CHANNEL_EMAILS_QK, { email_channel })
        showToast("Channel stitching category modified.")
      },
    },
  )
}

export const useModifyEmailsChannelOptIn = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyEmailsChannelOptInPayload }) => api.channel.emails.modifyOptIn(data),
    {
      onSuccess: ({ email_channel }) => {
        queryClient.setQueryData(CHANNEL_EMAILS_QK, { email_channel })
        showToast("Channel opt-in modified.")
      },
    },
  )
}

export const useActivateEmailsChannelOptIn = () => {
  return useMutation(
    ({ data }: { data: ActivateEmailsChannelOptInPayload }) =>
      api.channel.emails.activateOptIn(data),
    {
      onSuccess: () => {
        showToast("Test opt-in email sent.")
      },
    },
  )
}

export const useModifyEmailsChannelUnsubscribeBlock = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyEmailsChannelUnsubscribeBlock }) =>
      api.channel.emails.modifyUnsubscribeBlock(data),
    {
      onSuccess: ({ email_channel }) => {
        queryClient.setQueryData(CHANNEL_EMAILS_QK, { email_channel })
        showToast("Channel unsubscribe block modified.")
      },
    },
  )
}

import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"

import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { ToggleSwitchMultiple } from "components/UI/elements/ToggleSwitch"
import Page from "components/UI/Page/Page"
import DestinationParticipation from "./DestinationParticipation/DestinationParticipation"
import Header from "./Header/Header"
import HeaderActions from "./HeaderActions/HeaderActions"
import IdentityView from "./IdentityView/IdentityView"
import SegmentParticipation from "./SegmentParticipation/SegmentParticipation"
import { useFetchEventsMap } from "resources/event/eventQueries"
import { useFetchAttributesMapBySourceId } from "resources/attribute/attributeQueries"
import { AttributeFull } from "resources/attribute/attributeTypes"
import { useFetchCustomerAttributes } from "resources/customer/attribute/customerAttributeQueries"
import { useFetchCustomerDestinations } from "resources/customer/destination/customerDestinationQueries"
import { useFetchCustomerSegments } from "resources/customer/segment/customerSegmentQueries"
import { useFetchAllDataSources } from "resources/dataSource/dataSourceQueries"
import { Source } from "resources/dataSource/dataSourceTypes"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import { getRoutePath } from "routes"
import { ATTRIBUTE_VALUES_LIMIT, SUSPICIOUS_ENTITY_EVENT_COUNT_THRESHOLD } from "sharedConstants"
import { View } from "./types"
import { Label } from "resources/attributeLabel/attributeLabelTypes"

import styles from "./ProfileDetail.module.scss"
import { useFetchGlobalSettings } from "resources/globalSettings/globalSettingsQueries"
import { useProfileIteratorStore } from "resources/profile/profileIterator"
import AttributesView from "./AttributesView/AttributesView"
import TimelineView from "./TimelineView/TimelineView"

const filterAttributesByTagId = ({
  attributesMapBySourceId,
  hiddenChannelEngagementSourceIds,
  tagId,
  hideSourceEngagement = false,
}: {
  attributesMapBySourceId: Record<string, Array<AttributeFull>>
  hiddenChannelEngagementSourceIds?: Array<Source["id"]>
  hideSourceEngagement?: boolean
  tagId?: Label["id"]
}) => {
  let filteredAttributes: Array<AttributeFull> = []

  if (Object.keys(attributesMapBySourceId).length === 0 || !tagId) return filteredAttributes

  Object.values(attributesMapBySourceId).forEach(source => {
    source.forEach(attr => {
      if (attr.source.is_hidden) return

      if (Array.isArray(attr.tags) && attr.tags.some(tag => tag.id === tagId)) {
        if (
          hideSourceEngagement &&
          hiddenChannelEngagementSourceIds &&
          Array.isArray(hiddenChannelEngagementSourceIds)
        ) {
          if (!hiddenChannelEngagementSourceIds.includes(attr.source.id))
            filteredAttributes.push(attr)
        } else {
          filteredAttributes.push(attr)
        }
      }
    })
  })

  return filteredAttributes
}

export default function ProfileDetail() {
  const [showScreen, setShowScreen] = useState<View>("attributes")

  const scrollToSwitcher = useRef<HTMLDivElement>(null)

  const { id: profileId } = useParams<{ id: string }>()

  const { data: destinations = [], isLoading: isLoadingDestinations } = useFetchAllDestinations({
    orderBy: "name",
    orderDir: "ASC",
  })
  const { isLoading: isLoadingSources } = useFetchAllDataSources({ showHidden: true })
  const { data: attributesMapBySourceId = {}, isLoading: isLoadingAttributes } =
    useFetchAttributesMapBySourceId()
  const { isLoading: isLoadingEvents } = useFetchEventsMap({ includeHidden: true })

  const {
    data: customerSegments,
    fetchNextPage: fetchMoreCustomerSegments,
    hasNextPage: hasMoreCustomerSegments,
    isFetchingNextPage: isFetchingMoreCustomerSegments,
    isLoading: isLoadingCustomerSegments,
  } = useFetchCustomerSegments(profileId)
  const { data: customerDestinations, isLoading: isLoadingCustomerDestinations } =
    useFetchCustomerDestinations(profileId)
  const { data: customerAttributesResponse, isLoading: isLoadingCustomerAttributes } =
    useFetchCustomerAttributes({
      attribute_values_max_count: ATTRIBUTE_VALUES_LIMIT,
      customer_entity_id: profileId,
      load_full_structure: 0,
    })

  const { reset } = useProfileIteratorStore()

  useEffect(() => {
    return () => {
      reset()
    }
  }, [reset])

  const isLoading =
    isLoadingAttributes ||
    isLoadingDestinations ||
    isLoadingEvents ||
    isLoadingSources ||
    isLoadingCustomerAttributes ||
    isLoadingCustomerDestinations ||
    isLoadingCustomerSegments

  let isSuspiciouslyLarge = false
  if (
    customerAttributesResponse &&
    customerAttributesResponse.customer_events_count !== null &&
    customerAttributesResponse.customer_identifiers_count
  )
    isSuspiciouslyLarge =
      customerAttributesResponse?.customer_events_count >=
        SUSPICIOUS_ENTITY_EVENT_COUNT_THRESHOLD ||
      customerAttributesResponse?.customer_identifiers_count >=
        SUSPICIOUS_ENTITY_EVENT_COUNT_THRESHOLD

  const customerAttributes = customerAttributesResponse?.customer_attributes ?? []

  const { data: globalSettings, isSuccess: areGlobalSettingsFulfilled } = useFetchGlobalSettings()
  const channelEngagementTagId: Label["id"] | undefined =
    globalSettings?.["channel_engagement_tag_id"]?.value
  const contactInfoTagId: Label["id"] | undefined = globalSettings?.["contact_info_tag_id"]?.value
  const hiddenChannelEngagementSourceIds: Array<Source["id"]> =
    globalSettings?.["hidden_channel_engagement_source_ids"]?.value ?? []

  let contactInfoAttributes: Array<AttributeFull> = []
  if (areGlobalSettingsFulfilled)
    contactInfoAttributes = filterAttributesByTagId({
      attributesMapBySourceId,
      tagId: contactInfoTagId,
    })
  if (contactInfoAttributes.length > 0)
    contactInfoAttributes = contactInfoAttributes.sort((a, b) => {
      if (a.order_index < b.order_index) return -1
      else if (a.order_index > b.order_index) return 1
      else return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })

  let channelEngagementAttributes: Array<AttributeFull> = []
  if (areGlobalSettingsFulfilled)
    channelEngagementAttributes = filterAttributesByTagId({
      attributesMapBySourceId,
      hiddenChannelEngagementSourceIds,
      hideSourceEngagement: true,
      tagId: channelEngagementTagId,
    })

  return (
    <Page
      title="Profile detail"
      headerContent={<HeaderActions customerId={profileId} customerDataReady={!isLoading} />}
      backRouteFallback={getRoutePath("profiles")}
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <>
          <div>
            <Header
              customerId={profileId}
              customerAttributes={customerAttributes}
              contactInfoAttributes={contactInfoAttributes}
              channelEngagementAttributes={channelEngagementAttributes}
              isGlobalSettingsFulfilled={areGlobalSettingsFulfilled}
              showSystemMessage={isSuspiciouslyLarge}
            />
            {customerSegments.length > 0 && (
              <SegmentParticipation
                data={customerSegments}
                hasNextPage={hasMoreCustomerSegments}
                isFetchingNextPage={isFetchingMoreCustomerSegments}
                fetchNextPage={fetchMoreCustomerSegments}
              />
            )}
            {destinations.length > 0 && (
              <DestinationParticipation
                customerDestinations={customerDestinations}
                destinations={destinations}
              />
            )}
          </div>
          <div ref={scrollToSwitcher}>
            <div className={styles.switchRow}>
              <hr />
              <div className={styles.switchBackground}>
                <ToggleSwitchMultiple
                  width="280px"
                  name="view-switch"
                  buttons={[{ value: "attributes" }, { value: "timeline" }, { value: "identity" }]}
                  checked={showScreen}
                  handleToggle={setShowScreen}
                />
              </div>
            </div>
            <div className={styles.customerDetailContent}>
              {showScreen === "attributes" && <AttributesView />}
              {showScreen === "timeline" && <TimelineView />}
              {showScreen === "identity" && <IdentityView />}
            </div>
          </div>
        </>
      )}
    </Page>
  )
}

import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

import styles from "./Badge.module.scss"

type BadgeProps = {
  icon: IconProp
  text: string
  background?: "white" | "transparent"
}

export default function Badge({ icon, text, background = "transparent" }: BadgeProps) {
  return (
    <div className={classNames(styles.root, { [styles.white]: background === "white" })}>
      <FontAwesomeIcon icon={icon} className={styles.icon} />
      <span className={styles.text}>{text}</span>
    </div>
  )
}

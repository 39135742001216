import { OPERATION } from "./utilities/segmentOperationsConstants"

export type SegmentConditionOperation = typeof OPERATION[keyof typeof OPERATION]

type SegmentConditionBase = {
  attribute_id: string | null
  negation: boolean
}

type OperationValueObject = {
  operation: SegmentConditionOperation | null
  value?: any
}

// Simple condition

export type SimpleConditionObject = {
  condition: OperationValueObject
}

export type SimpleSegmentCondition = SegmentConditionBase & SimpleConditionObject

// Compound condition

type DimensionOperationObjectBase = {
  sub_attribute_id: string | null
}

export type DimensionConditionObject = {
  condition: DimensionOperationObjectBase & OperationValueObject
}

export type DimensionBranch = {
  operation: "and" | "or"
  operands: DimensionTree[]
}
export type DimensionTree = DimensionConditionObject | DimensionBranch

export type CompoundSegmentCondition = SegmentConditionBase & DimensionTree
export type LeafCompoundSegmentCondition = SegmentConditionBase & DimensionConditionObject
// export type BranchCompoundSegmentCondition = SegmentConditionBase & DimensionBranch

// Multi-value simple condition

export type MultiValueSimpleSegmentCondition = SegmentConditionBase & {
  operation: "or"
  operands: SimpleConditionObject[]
}

// Multi-value compound condition

export type MultiValueSubattributeBranch = {
  operation: "or"
  operands: DimensionConditionObject[]
}

export type MultiValueDimensionBranch = {
  operation: "and" | "or"
  operands: MultiValueDimensionTree[]
}
export type MultiValueDimensionTree =
  | MultiValueSubattributeBranch
  | MultiValueDimensionBranch
  | DimensionConditionObject

type MultiValueCompoundSegmentCondition = SegmentConditionBase & MultiValueDimensionTree

// All

export type SegmentCondition = SimpleSegmentCondition | CompoundSegmentCondition

export type MultiValueSegmentCondition =
  | MultiValueSimpleSegmentCondition
  | MultiValueCompoundSegmentCondition

// Errors

export enum ERROR {
  REQUIRED = "Cannot be empty",
  MAX_LENGTH = "Max length exceeded",
}

export type DimensionLeafError = {
  sub_attribute_id?: ERROR
  operation?: ERROR
  value?: ERROR
  min_value?: ERROR
  max_value?: ERROR
} | null
export type DimensionBranchError = { operands: DimensionTreeError[] }
export type DimensionTreeError = DimensionLeafError | DimensionBranchError

export type SegmentConditionError = {
  attribute_id?: ERROR
  operation?: ERROR
  value?: ERROR
  min_value?: ERROR
  max_value?: ERROR
  dimensions?: DimensionTreeError
}

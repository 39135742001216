import { ConditionNodeSettings } from "resources/journey/journeyTypes"
import { pick } from "ramda"
import { AttributeConditionPath, EventConditionPath } from "resources/journey/journeyTypes"
import { Duration, durationToSeconds, secondsToDuration } from "../../utils/duration"
import {
  eventConditionToFormValues,
  eventConditionToSettings,
} from "../../components/EventCondition/formValues"

export type EventConditionPathFormValues = Omit<EventConditionPath, "since"> & {
  since: Duration
}

export type AttributeConditionPathFormValues = AttributeConditionPath

export type ConditionNodeFormValues = {
  paths: Array<AttributeConditionPathFormValues | EventConditionPathFormValues>
}

export function settingsToFormValues(settings: ConditionNodeSettings): ConditionNodeFormValues {
  return {
    paths: Object.entries(settings.paths)
      .sort(([indexA], [indexB]) => +indexA - +indexB)
      .map(([_, path]) => {
        if (path.type === "attribute") {
          return path
        }

        return {
          ...path,
          since: secondsToDuration(path.since),
          conditions_operation: path.conditions_operation?.map(eventConditionToFormValues),
        }
      }),
  }
}

export function formValuesToSettings(values: ConditionNodeFormValues): ConditionNodeSettings {
  return {
    paths: Object.fromEntries(
      values.paths
        .map(path => {
          if (path.type === "attribute") {
            return pick(["type", "conditions_operation"], path)
          }

          return {
            ...pick(["type", "event_id", "operator", "since", "conditions_operation"], path),
            conditions_operation: path.conditions_operation?.map(eventConditionToSettings),
            since: path.since && durationToSeconds(path.since),
          }
        })
        // Paths specified in settings are 1-indexed, path 0 is the implicit default path
        .map((path, index) => [index + 1, path]),
    ),
  }
}

import React, { useEffect, useState } from "react"
import styles from "./SegmentCustomersPreview.module.scss"
import { useParams } from "react-router"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import Button from "components/UI/elements/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useHasAccess } from "resources/user/currentUserQueries"
import { useFetchSegmentCustomers } from "resources/segment/segment/segmentQueries"
import { useProfileIteratorStore } from "resources/profile/profileIterator"
import { SEGMENT } from "sharedConstants"

type SegmentCustomersPreviewProps = {
  hasUnsavedConditions: boolean
  isOpen: boolean
}

const MAX_OFFSET = 300

export default function SegmentCustomersPreview({
  hasUnsavedConditions,
  isOpen,
}: SegmentCustomersPreviewProps) {
  const [page, setPage] = useState(0)

  useEffect(() => {
    if (hasUnsavedConditions) setPage(0)
  }, [hasUnsavedConditions])

  const { id } = useParams<{ id: string }>()

  const hasAccess = useHasAccess()

  const canSeeCustomers = hasAccess.customers.detail
  const { error, data, isFetching, fetchNextPage, hasNextPage } = useFetchSegmentCustomers(+id, {
    enabled: canSeeCustomers,
  })

  const { setIndex, setSegmentId } = useProfileIteratorStore()

  const hasMaxOffset = (page + 1) * SEGMENT.CUSTOMER.ITEMS_PER_PAGE >= MAX_OFFSET

  if (!isOpen) return null

  return (
    <div className={styles.container}>
      {!hasAccess.customers.detail ? (
        <div className={styles.message}>You don't have permission to view customers.</div>
      ) : hasUnsavedConditions ? (
        <div className={styles.message}>Save segment conditions to see the customers preview.</div>
      ) : isFetching ? (
        <LoadingIndicator />
      ) : error ? (
        <div className={styles.message}>Error while loading.</div>
      ) : data?.pages.length === 0 ? (
        <div className={styles.message}>No profiles found.</div>
      ) : (
        <>
          {data?.pages[page].customer_entities.map((customer, index) => (
            <div data-testid="profile" className={styles.customerRow} key={customer.id}>
              <Link
                className={styles.customerLink}
                onClick={() => {
                  setIndex(index + page)
                  setSegmentId(+id)
                }}
                to={{
                  pathname: getRoutePath("profiles.detail", { id: customer.id }),
                  state: { goBack: true, prev: "segment", segmentId: id },
                }}
              >
                {customer.id}
              </Link>
            </div>
          ))}
          <div className={styles.customersListFooter}>
            <Button
              color="grey"
              disabled={page === 0}
              variant="outlined"
              onClick={() => {
                setPage(prev => prev - 1)
              }}
            >
              <FontAwesomeIcon icon={["fas", "chevron-left"]} />
            </Button>
            <div className={styles.pageNumber}>Page {page + 1}</div>
            <Button
              color="grey"
              disabled={!hasNextPage || hasMaxOffset}
              variant="outlined"
              onClick={() => {
                if (hasMaxOffset) return

                if (data && data.pages.length <= page + 1) fetchNextPage()
                setPage(prev => prev + 1)
              }}
            >
              <FontAwesomeIcon icon={["fas", "chevron-right"]} />
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

import requestFactory from "api/request"
import {
  Chart,
  ChartCreatePayload,
  ChartModifyPayload,
  ChartClonePayload,
  ChartMovePayload,
  ChartsListResponse,
} from "./funnelChartTypes"
import { FunnelGroup } from "resources/funnelGroup/funnelGroupTypes"

const funnelChart = {
  listAll(funnelGroupId: FunnelGroup["id"]): Promise<ChartsListResponse> {
    return requestFactory("get", `/funnel_groups/${funnelGroupId}/funnels`)
  },

  create(funnelGroupId: FunnelGroup["id"], data: ChartCreatePayload): Promise<{ chart: Chart }> {
    return requestFactory("post", `/funnel_groups/${funnelGroupId}/funnels`, data)
  },

  modify(funnelGroupId: FunnelGroup["id"], chartId: Chart["id"], data: ChartModifyPayload) {
    return requestFactory("patch", `/funnel_groups/${funnelGroupId}/funnels/${chartId}`, data)
  },

  delete(funnelGroupId: FunnelGroup["id"], chartId: Chart["id"]): Promise<void> {
    return requestFactory("delete", `/funnel_groups/${funnelGroupId}/funnels/${chartId}`)
  },

  clone(funnelGroupId: FunnelGroup["id"], chartId: Chart["id"], data: ChartClonePayload) {
    return requestFactory("post", `/funnel_groups/${funnelGroupId}/funnels/${chartId}/clone`, data)
  },

  move(funnelGroupId: FunnelGroup["id"], chartId: Chart["id"], data: ChartMovePayload) {
    return requestFactory("patch", `/funnel_groups/${funnelGroupId}/funnels/${chartId}/move`, data)
  },

  listDeleted(): Promise<{ trashed_charts: Chart[] }> {
    return requestFactory("get", "/funnel_groups/funnels/trash")
  },

  restoreDeleted(
    funnelGroupId: FunnelGroup["id"],
    chartId: Chart["id"],
  ): Promise<{ chart: Chart }> {
    return requestFactory("patch", `/funnel_groups/${funnelGroupId}/funnels/${chartId}/trash`)
  },
}

export default funnelChart

import React, { useState } from "react"
import Tippy from "@tippyjs/react"
import classNames from "classnames"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ExpandedInsightChartBar, {
  BAR_MAX_WIDTH,
} from "./ExpandedInsightChartBar/ExpandedInsightChartBar"
import ExpandedInsightChartMultiBar from "./ExpandedInsightChartMultiBar/ExpandedInsightChartMultiBar"
import { toStrPercentage } from "../formatters"
import { Audience, Color, InsightType, LabelType } from "../types"
import { abbreviateNumber } from "helpers/number.helper"

import styles from "./ExpandedInsightChart.module.scss"

type ExpandedInsightChartProps = {
  color: Color
  globalAudience: Audience
  type: InsightType
  hideTooltip: () => void
  segmentedAudience?: Audience
  first?: boolean
  firstTimeTooltip?: boolean
  label?: LabelType
}

const SEGMENTED_AUDIENCE_TOOLTIP =
  "The chart compares segmented and global audience insights by showing % of profiles with a specific attribute in the bar and % of profiles with a known attribute in a light-colored section."

const GLOBAL_AUDIENCE_TOOLTIP =
  "The chart displays global audience insights with the % of profiles possessing this attribute in the bar chart and the % of profiles with a known attribute in a light-colored section."

type Milliseconds = number
function debounce(f: () => unknown, delay: Milliseconds) {
  let timer: NodeJS.Timeout

  function ret() {
    clearTimeout(timer)
    timer = setTimeout(f, delay)
  }
  ret.cancel = function () {
    clearTimeout(timer)
  }
  return ret
}

export default function ExpandedInsightChart({
  color,
  globalAudience,
  label,
  segmentedAudience,
  type,
  hideTooltip,
  first = false,
  firstTimeTooltip = false,
}: ExpandedInsightChartProps) {
  const [hoverTooltipVisible, setHoverTooltipVisible] = useState(false)

  const debounceMouseEnter = debounce(() => setHoverTooltipVisible(true), 2000)
  const onMouseLeave = () => {
    if (hoverTooltipVisible) setHoverTooltipVisible(false)
    else debounceMouseEnter.cancel()
  }

  const tooltipContent =
    segmentedAudience !== undefined ? SEGMENTED_AUDIENCE_TOOLTIP : GLOBAL_AUDIENCE_TOOLTIP

  return (
    <div className={styles.root}>
      <span className={styles.label}>{label}</span>
      <div className={styles.chart}>
        <Tippy
          interactive={firstTimeTooltip}
          visible={
            hoverTooltipVisible ||
            (label !== undefined ? firstTimeTooltip && first : firstTimeTooltip)
          }
          content={
            <div className={styles.tooltipContent}>
              <p className={styles.paragraph}>{tooltipContent}</p>
              {firstTimeTooltip && (
                <IconButton
                  color="white"
                  icon="times"
                  variant="transparent"
                  onClick={_ => hideTooltip()}
                  className={styles.button}
                />
              )}
            </div>
          }
          placement="top-start"
        >
          <div className={styles.horizontalFlex}>
            <div
              onMouseEnter={() => {
                if (!firstTimeTooltip && !hoverTooltipVisible) debounceMouseEnter()
              }}
              onMouseLeave={onMouseLeave}
              className={classNames(styles.verticalFlex, { [styles.relative]: !segmentedAudience })}
            >
              {segmentedAudience && (
                <>
                  {!["IS_SET_COUNT", "IS_NOT_SET_COUNT"].includes(type) ? (
                    <ExpandedInsightChartMultiBar audience={segmentedAudience} color={color} />
                  ) : (
                    <ExpandedInsightChartBar
                      color={color}
                      percentage={(segmentedAudience.segmented / segmentedAudience.total) * 100}
                    />
                  )}
                </>
              )}
              <div
                className={classNames(styles.horizontalLine, {
                  [styles.hidden]: !segmentedAudience,
                })}
                style={{ width: `${BAR_MAX_WIDTH}px` }}
              />
              {!["IS_SET_COUNT", "IS_NOT_SET_COUNT"].includes(type) ? (
                <ExpandedInsightChartMultiBar
                  showLine={!segmentedAudience}
                  single={!segmentedAudience}
                  audience={globalAudience}
                  color={!segmentedAudience ? color : undefined}
                />
              ) : (
                <ExpandedInsightChartBar
                  showLine={!segmentedAudience}
                  single={!segmentedAudience}
                  color={!segmentedAudience ? color : undefined}
                  percentage={(globalAudience.segmented / globalAudience.total) * 100}
                />
              )}
            </div>

            <div className={styles.verticalLine} />
            <span className={styles.oneHundred}>
              <b>100%</b>
            </span>
          </div>
        </Tippy>
        <div className={styles.legend}>
          {segmentedAudience && (
            <span className={classNames(styles.primary, styles[color])}>
              <b>{`${abbreviateNumber(segmentedAudience.segmented)} (${toStrPercentage(
                (segmentedAudience.segmented / segmentedAudience.total) * 100,
              )} out of ${abbreviateNumber(segmentedAudience.total)})`}</b>
            </span>
          )}
          <span
            className={classNames({
              [styles[color]]: !segmentedAudience,
              [styles.primary]: !segmentedAudience,
              [styles.secondary]: !!segmentedAudience,
            })}
          >
            <b>{`${abbreviateNumber(globalAudience.segmented)} (${toStrPercentage(
              (globalAudience.segmented / globalAudience.total) * 100,
            )} out of ${abbreviateNumber(globalAudience.total)})`}</b>
          </span>
        </div>
      </div>
    </div>
  )
}

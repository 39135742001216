import { isNil } from "ramda"
import { WaitSlotNodeSettings } from "resources/journey/journeyTypes"
import { capitalize } from "utilities/capitalize"

export function getDescription(settings?: WaitSlotNodeSettings | null): string {
  if (isNil(settings)) {
    return ""
  }

  const { frequency, slots } = settings

  return `${capitalize(frequency)} (${slots.length} slot${slots.length > 1 ? "s" : ""})`
}

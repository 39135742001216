import React from "react"
import classNames from "classnames"
import styles from "./SearchWithSourceSelect.module.scss"
import { useFetchDataSourceOptions } from "resources/dataSource/dataSourceQueries"
import SelectField from "components/UI/elements/SelectField"
import SearchField from "components/UI/elements/SearchField"
import { Source } from "resources/dataSource/dataSourceTypes"

type SearchWithSourceSelectProps = {
  showHiddenSources?: boolean
  placeholder?: string
  selectValue: Source["id"] | null
  setSelectValue: (sourceId: Source["id"] | null) => void
  searchValue: string
  setSearchValue: (searchTerm: string) => void
  className?: string
}

export default function SearchWithSourceSelect({
  showHiddenSources,
  placeholder,
  selectValue,
  setSelectValue,
  searchValue,
  setSearchValue,
  className,
}: SearchWithSourceSelectProps) {
  const { data: sourceOptions = [] } = useFetchDataSourceOptions({ showHidden: showHiddenSources })

  return (
    <div className={classNames(styles.container, className)}>
      <SelectField
        input={{ value: selectValue, onChange: setSelectValue }}
        options={sourceOptions}
        className={styles.select}
        placeholder="All sources"
        isSearchable={true}
        isClearable={true}
        isSimpleValue
      />
      <SearchField
        input={{ value: searchValue, onChange: setSearchValue }}
        placeholder={placeholder}
        onClear={() => setSearchValue("")}
        wrapperClassName={styles.search}
      />
    </div>
  )
}

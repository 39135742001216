import TextInput from "components/UI/elements/TextInput/TextInput"
import { minLength, required } from "helpers/validators.helper"
import React from "react"
import { useForm } from "react-hook-form"
import "./PasswordChangeForm.scss"

export default function PasswordChangeForm({ onSubmit }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  return (
    <form
      id="passwordChangeForm"
      className="password-change-form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextInput
        {...register("password", { validate: { required, minLength: minLength(12) } })}
        label="New password"
        placeholder="New password"
        type="password"
        autoFocus
        error={errors.password?.message}
        className="field"
      />
      <TextInput
        {...register("repeatPassword", {
          validate: (value, { password }) =>
            password && value !== password ? "Password doesn't match" : undefined,
        })}
        label="Confirm password"
        placeholder="Confirm password"
        type="password"
        error={errors.repeatPassword?.message}
        className="field"
      />

      <button type="submit" className="hidden-submit">
        submit
      </button>
    </form>
  )
}

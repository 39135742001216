import { Suspense, lazy, useRef } from "react"
import classNames from "classnames"
import { isValid } from "date-fns"
import { format, utcToZonedTime } from "date-fns-tz"
import { last, path as _path } from "ramda"
import { Controller, FieldError, FieldErrors, useFormContext } from "react-hook-form"

import AttributePickerButton from "components/UI/components/AttributePickerButton/AttributePickerButton"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SelectField from "components/UI/elements/SelectField"
import TextInput from "components/UI/elements/TextInput/TextInput"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import {
  CUSTOM_DATA_DYNAMIC_FIELD_FORMAT,
  CUSTOM_DATA_DYNAMIC_FIELD_PATH_DELIMITER,
  CUSTOM_DATA_SINGLE_DYNAMIC_FIELD_PATH,
} from "sharedConstants"
import { JsonSchema } from "types/util"

import styles from "./DynamicField.module.scss"

const DatePicker = lazy(() => import("react-datepicker"))

const splitPath = (path: string) => path.split(CUSTOM_DATA_DYNAMIC_FIELD_PATH_DELIMITER)
const replaceUnderscore = (str: string) => str.split("_").join(" ")
const splitCamelCase = (str: string) => str.replace(/([a-z])([A-Z])/g, "$1 $2")
const capitalizeOnlyFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

const getFieldError = (errors: FieldErrors, path: string): FieldError | undefined =>
  path ? _path(splitPath(path), errors) : undefined
const getLabel = (schema: JsonSchema, path: string) =>
  schema.title ?? path ? capitalizeOnlyFirstLetter(splitCamelCase(last(splitPath(path))!)) : ""

type DynamicFieldProps = {
  schema: JsonSchema
  path?: string
}

export default function DynamicField({ path, schema }: DynamicFieldProps) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext()

  if (schema.type === "object")
    return (
      <div className={styles.object}>
        {schema.properties
          ? Object.entries(schema.properties).map(([key, field]) => {
              const fieldName = path ? `${path}.${key}` : key
              return <DynamicField key={fieldName} path={fieldName} schema={field} />
            })
          : null}
      </div>
    )

  if (!path) return null

  const fieldPath = path ?? CUSTOM_DATA_SINGLE_DYNAMIC_FIELD_PATH
  const label = getLabel(schema, fieldPath)
  const error = getFieldError(errors, fieldPath)

  switch (schema.type) {
    case "string":
      if (schema.enum)
        return (
          <Controller
            control={control}
            name={fieldPath}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <SelectField
                isSimpleValue
                error={error?.message}
                label={label}
                input={{ value, onBlur, onChange }}
                options={schema.enum!.map((value: string) => ({
                  value,
                  label: capitalizeOnlyFirstLetter(replaceUnderscore(value)),
                }))}
                className={styles.selectField}
              />
            )}
          />
        )

      if (
        schema.format &&
        [
          CUSTOM_DATA_DYNAMIC_FIELD_FORMAT.DATE,
          CUSTOM_DATA_DYNAMIC_FIELD_FORMAT.DATE_TIME,
        ].includes(schema.format)
      )
        return (
          <Controller
            control={control}
            name={fieldPath}
            render={({ field: { name, value, onBlur, onChange }, fieldState: { error } }) => (
              <div className="dynamic-field-datepicker">
                <label htmlFor={name} className={styles.label}>
                  {label}
                </label>
                <Suspense fallback={<LoadingIndicator />}>
                  <DatePicker
                    disabledKeyboardNavigation
                    inline
                    scrollableYearDropdown
                    showMonthDropdown
                    showYearDropdown
                    showTimeInput={schema.format === CUSTOM_DATA_DYNAMIC_FIELD_FORMAT.DATE_TIME}
                    timeInputLabel={
                      schema.format === CUSTOM_DATA_DYNAMIC_FIELD_FORMAT.DATE_TIME
                        ? "Time:"
                        : undefined
                    }
                    // to prevent custom data schema preview failing when user sets default to something else than datetime
                    selected={value && isValid(new Date(value)) ? new Date(value) : undefined}
                    onBlur={onBlur}
                    onChange={value => {
                      if (value && value instanceof Date)
                        onChange(
                          format(utcToZonedTime(value, "UTC"), "yyyy-MM-dd'T'HH:mm:ss.SSSSSSxx", {
                            timeZone: "UTC",
                          }),
                        )
                    }}
                    calendarClassName="calendar-dropdown"
                  />
                  {error && <p className={styles.errorMessage}>{error.message}</p>}
                </Suspense>
              </div>
            )}
          />
        )

      return <StringTextInput path={fieldPath} schema={schema} />
    case "boolean":
      return (
        <Controller
          control={control}
          name={fieldPath}
          render={({ field: { name, value, onChange } }) => (
            <div className={styles.toggleButton}>
              <ToggleButton size="xs" value={value} handleToggle={() => onChange(!value)} />
              <label htmlFor={name} className={styles.label}>
                {label}
              </label>
            </div>
          )}
        />
      )
    case "integer":
    case "number":
      return (
        <TextInput
          error={error?.message}
          label={label}
          max={schema.maximum}
          min={schema.minimum}
          type="number"
          {...register(fieldPath, { valueAsNumber: true })}
        />
      )
    default:
      return null
  }
}

const StringTextInput = ({ path, schema }: Required<DynamicFieldProps>) => {
  const textInputRef = useRef<HTMLInputElement | null>(null)

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const label = getLabel(schema, path)
  const error = getFieldError(errors, path)

  const dynamicFieldValue = watch(path)
  const insertAttributeIdToTextInput = (attributeId: string | null) => {
    if (!textInputRef.current || !attributeId) return

    const { selectionStart, selectionEnd } = textInputRef.current
    setValue(
      path,
      `${dynamicFieldValue.slice(
        0,
        selectionStart ?? 0,
      )}{{${attributeId}}}${dynamicFieldValue.slice(selectionEnd ?? 0)}`,
      { shouldDirty: true, shouldTouch: true, shouldValidate: true },
    )
  }

  const { ref: textFieldRef, ...textInputRegister } = register(path)

  return (
    <div className={styles.textInputWrapper}>
      <TextInput
        {...textInputRegister}
        error={error?.message}
        label={label}
        maxLength={schema.maxLength}
        minLength={schema.minLength}
        ref={(el: HTMLInputElement) => {
          textFieldRef(el)
          textInputRef.current = el
        }}
        className={classNames({
          [styles.noBorderRight]: schema.format !== CUSTOM_DATA_DYNAMIC_FIELD_FORMAT.EMAIL,
        })}
      />
      {schema.format !== CUSTOM_DATA_DYNAMIC_FIELD_FORMAT.EMAIL && (
        <AttributePickerButton
          autoFocus={false}
          isError={!!error}
          onChange={insertAttributeIdToTextInput}
        />
      )}
    </div>
  )
}

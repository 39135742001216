import { Fragment, useState } from "react"
import styles from "./EntryNodeForm.module.scss"
import { EntryNodeSettings, EntryNodeType, EntryType } from "resources/journey/journeyTypes"
import { Controller, FieldErrorsImpl, FormProvider, useFieldArray, useForm } from "react-hook-form"
import Button from "components/UI/elements/Button/Button"
import { required } from "helpers/validators.helper"
import SegmentPicker from "components/SegmentPicker/SegmentPicker"
import NodeFormLayout from "../../components/NodeFormLayout/NodeFormLayout"
import classNames from "classnames"
import eventImg from "./event.png"
import segmentImg from "./segment.png"
import eventActiveImg from "./event-active.png"
import segmentActiveImg from "./segment-active.png"
import SelectField from "components/UI/elements/SelectField"
import { useFetchEventById, useFetchEventOptions } from "resources/event/eventQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Schedule from "./Schedule/Schedule"
import {
  EntryNodeEventFormValues,
  EntryNodeFormValues,
  EntryNodeSegmentFormValues,
  formValuesToSettings,
  settingsToFormValues,
} from "./formValues"
import { getDescription } from "./getDescription"
import EntryNodeCard from "./EntryNodeCard"
import EventCondition from "../../components/EventCondition/EventCondition"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"

type EntryNodeFormProps = {
  node: EntryNodeType
  onSubmit: (data: EntryNodeSettings) => void
  onClose: () => void
  isSubmitting: boolean
  isEditable: boolean
}

const MAX_CONDITIONS = 10

const getNewCondition = () => ({
  parameter_id: "",
  operation: "",
  value: "",
})

const getNewSchedule = () =>
  ({
    days: { 1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: true },
    type: "fixed",
    hour: NaN,
    minute: NaN,
  } as const)

export default function EntryNodeForm({
  node,
  onClose,
  onSubmit,
  isSubmitting,
  isEditable,
}: EntryNodeFormProps) {
  const defaultValues = node.settings ? settingsToFormValues(node.settings) : {}

  const methods = useForm<EntryNodeFormValues>({ defaultValues })
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = methods
  const {
    fields: conditionFields,
    append: appendCondition,
    remove: removeCondition,
  } = useFieldArray({ control, name: "conditions_operation" })
  const {
    fields: scheduleFields,
    append: appendSchedule,
    remove: removeSchedule,
  } = useFieldArray({ control, name: "schedules" })

  const eventOptionsQuery = useFetchEventOptions()

  const values = watch()

  const eventId = watch("event_id")
  const eventQuery = useFetchEventById(eventId)

  const [selectedType, setSelectedType] = useState<EntryType | null>(node.settings?.type ?? null)
  const [page, setPage] = useState(node.settings ? 2 : 1)

  function goBack() {
    if (page === 2) {
      setPage(1)
    } else {
      onClose()
    }
  }

  function submit(data: EntryNodeFormValues) {
    onSubmit(formValuesToSettings(data))
  }

  return (
    <NodeFormLayout
      previewCard={<EntryNodeCard description={getDescription(formValuesToSettings(values))} />}
      onClose={onClose}
      onSubmit={handleSubmit(submit)}
      isSubmitting={isSubmitting}
      isEditable={isEditable && page === 2}
      onClickBack={goBack}
      width="lg"
    >
      {page === 1 && (
        <div className={styles.page1}>
          <div className={styles.intro}>Select the type of entry:</div>

          <div className={styles.selectButtons}>
            <button
              className={classNames(styles.selectButton, selectedType === "event" && styles.active)}
              onClick={() => {
                setSelectedType("event")
                reset({ type: "event", operator: "and", conditions_operation: [] })
              }}
              type="button"
            >
              <div className={styles.imgWrapper}>
                <img
                  src={selectedType === "event" ? eventActiveImg : eventImg}
                  alt="event"
                  className={styles.img}
                />
              </div>
              <div className={styles.text}>
                <h3>On event</h3>
                <p>Profiles will enter the journey when an event is received.</p>
              </div>
            </button>

            <button
              className={classNames(
                styles.selectButton,
                selectedType === "segment" && styles.active,
              )}
              onClick={() => {
                setSelectedType("segment")
                reset({
                  type: "segment",
                  schedules: [getNewSchedule()],
                })
              }}
              type="button"
            >
              <div className={styles.imgWrapper}>
                <img
                  src={selectedType === "segment" ? segmentActiveImg : segmentImg}
                  alt="segment"
                  className={styles.img}
                />
              </div>
              <div className={styles.text}>
                <h3>On segment</h3>
                <p>Profiles from a segment will enter the journey based on a schedule.</p>
              </div>
            </button>
          </div>

          <div className={styles.bottomButtons}>
            <Button
              onClick={() => {
                setValue("type", selectedType!)
                setPage(2)
              }}
              disabled={!selectedType}
            >
              Continue
            </Button>
          </div>
        </div>
      )}

      {page === 2 && (
        <div className={styles.page2}>
          {values.type === "event" && (
            <>
              <Controller
                control={control}
                name="event_id"
                render={({ field }) =>
                  eventOptionsQuery.isLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <SelectField
                      input={{
                        value: field.value,
                        onChange(value: string) {
                          setValue(`conditions_operation`, [])
                          field.onChange(value)
                        },
                      }}
                      label="Event"
                      options={eventOptionsQuery.data ?? []}
                      isSimpleValue
                    />
                  )
                }
              />

              {eventQuery.data && (
                <div>
                  <div className={styles.label}>Conditions</div>

                  <div className={styles.list}>
                    <FormProvider {...methods}>
                      {conditionFields.map((field, index) => (
                        <Fragment key={field.id}>
                          {index !== 0 && (
                            <Controller
                              name="operator"
                              control={control}
                              render={({ field }) => (
                                <ToggleSwitch
                                  leftValue={"and"}
                                  rightValue={"or"}
                                  checked={field.value}
                                  handleToggle={() =>
                                    field.onChange(field.value === "and" ? "or" : "and")
                                  }
                                  disabled={!isEditable}
                                  width="80px"
                                  size="small"
                                />
                              )}
                            />
                          )}

                          <EventCondition<EntryNodeEventFormValues>
                            event={eventQuery.data!}
                            isEditable={isEditable}
                            removeSelf={() => removeCondition(index)}
                            path={`conditions_operation.${index}`}
                          />
                        </Fragment>
                      ))}

                      {conditionFields.length < MAX_CONDITIONS && isEditable && (
                        <Button
                          onClick={() => appendCondition(getNewCondition())}
                          variant="outlined"
                          color="grey"
                          className={styles.addButton}
                        >
                          + Add condition
                        </Button>
                      )}
                    </FormProvider>
                  </div>
                </div>
              )}
            </>
          )}

          {values.type === "segment" && (
            <>
              <Controller
                control={control}
                name="segment_id"
                rules={{ validate: { required } }}
                render={({ field }) => (
                  <SegmentPicker
                    disabled={!isEditable}
                    value={field.value}
                    onChange={field.onChange}
                    errorMessage={
                      (errors as FieldErrorsImpl<EntryNodeSegmentFormValues>).segment_id?.message
                    }
                  />
                )}
              />

              <div>
                <div className={styles.label}>Schedules</div>

                <div className={styles.list}>
                  <FormProvider {...methods}>
                    {scheduleFields.map((field, index) => (
                      <Schedule
                        isEditable={isEditable}
                        key={field.id}
                        index={index}
                        removeSelf={
                          scheduleFields.length > 1 ? () => removeSchedule(index) : undefined
                        }
                      />
                    ))}
                  </FormProvider>

                  {isEditable && (
                    <Button
                      onClick={() => appendSchedule(getNewSchedule())}
                      color="grey"
                      variant="outlined"
                      className={styles.addButton}
                    >
                      + Add schedule
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </NodeFormLayout>
  )
}

import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import RolesList from "./RolesList/RolesList"
import RoleCreate from "./RoleCreate/RoleCreate"
import RoleDetail from "./RoleDetail/RoleDetail"
import Trash from "pages/Trash/Trash"
import { useFetchUserRoleTrashItems, useRestoreUserRole } from "resources/userRole/userRoleQueries"

export default function Roles() {
  return (
    <Switch>
      <Route path={getRoutePath("administration.roles")} exact component={RolesList} />
      <Route
        path={getRoutePath("administration.roles.trash")}
        render={() => (
          <Trash
            backRoute={getRoutePath("administration.roles")}
            itemType="user_roles"
            fetchQuery={useFetchUserRoleTrashItems}
            restoreMutation={useRestoreUserRole}
          />
        )}
      />
      <Route path={getRoutePath("administration.roles.create")} component={RoleCreate} />
      <Route path={getRoutePath("administration.roles.detail")} component={RoleDetail} />
    </Switch>
  )
}

import React, { useCallback, useState } from "react"
import styles from "./WebBannerCondition.module.scss"
import {
  browserOptions,
  countryOptions,
  dayOfWeekOptions,
  deviceOptions,
  languageOptions,
  osOptions,
} from "./dropdownOptions"
import SelectField from "components/UI/elements/SelectField"
import { format } from "date-fns"
import classNames from "classnames"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import {
  datetimeSubjects,
  EnumSubject,
  enumSubjects,
  LeafCondition,
  numberEnumSubjects,
  numberSubjects,
  RelativeDatetimeUnits,
  settableStringSubjects,
  stringSubjects,
} from "resources/webBanner/webBannerConditionTypes"
import TextInput from "components/UI/elements/TextInput/TextInput"
import {
  isArrayCondition,
  isEmptyCondition,
  isNoValueCondition,
  isRangeCondition,
  isSingleValueCondition,
  isTypedCondition,
} from "./utils"

type SelectOption = { value: any; label: string }

const LOCAL_ISO_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm"

const fromLocalToUTC = (localDatetime: string) => new Date(localDatetime).toISOString()
const fromUTCToLocal = (UTCDatetime: string) =>
  format(new Date(UTCDatetime), LOCAL_ISO_DATETIME_FORMAT)

const valuesToOptions = (values: any[] | null): SelectOption[] | null =>
  values && values.map(value => ({ value, label: value }))
const optionsToValues = (options: SelectOption[]) =>
  options.length === 0 ? null : options.map(({ value }) => value)

const DateTimeTooltip = (
  <div className={styles.tooltipWrapper}>
    <InfoTooltip placement="left" className={styles.info}>
      Enter datetime in your local timezone. It will be automatically converted to the user's
      timezone.
    </InfoTooltip>
  </div>
)

const RelativeDateTimeTooltip = (
  <div className={styles.tooltipWrapper}>
    <InfoTooltip placement="left" className={styles.info}>
      Time relative to the moment when the condition is being evaluated on the customer's side.
    </InfoTooltip>
  </div>
)

type WebBannerConditionValueProps = {
  condition: LeafCondition
  onChange: (condition: LeafCondition) => void
  isEditable?: boolean
  error: any
}

const relativeDatetimeUnitOptions = [
  { value: "minutes", label: "minutes" },
  { value: "hours", label: "hours" },
  { value: "days", label: "days" },
]

const relativeDatetimeDirectionOptions = [
  { value: "before", label: "before" },
  { value: "after", label: "after" },
]

const isNegative = (number: number) =>
  Object.is(Math.sign(number), -1) || Object.is(Math.sign(number), -0)

export default function WebBannerConditionValue({
  condition,
  onChange,
  isEditable,
  error,
}: WebBannerConditionValueProps) {
  const set = useCallback(
    (prop: "value" | "min_value" | "max_value" | "values", value: any) => {
      onChange({ ...condition, [prop]: value })
    },
    [condition, onChange],
  )

  const [relativeDatetimeDirectionState, setRelativeDatetimeDirectionState] = useState(() => {
    const state = {
      value: "before",
      min_value: "before",
      max_value: "before",
    }

    if (isTypedCondition(condition) && condition.type === "relative_datetime") {
      if (
        isSingleValueCondition(condition) &&
        condition.value?.count &&
        !isNegative(condition.value.count)
      ) {
        state.value = "after"
      }

      if (isRangeCondition(condition)) {
        if (condition.min_value?.count && !isNegative(condition.min_value.count)) {
          state.min_value = "after"
        }
        if (condition.max_value?.count && !isNegative(condition.max_value.count)) {
          state.max_value = "after"
        }
      }
    }

    return state
  })

  const setRelativeDatetimeCount = useCallback(
    (count: string, prop: "value" | "min_value" | "max_value") => {
      // @ts-ignore 😇
      const conditionValue = condition[prop]
      const parsedCount = parseFloat(count)
      const newAbsCount = isNaN(parsedCount) ? null : Math.abs(parsedCount)
      const newCount =
        newAbsCount === null || relativeDatetimeDirectionState[prop] === "after"
          ? newAbsCount
          : -newAbsCount
      const newValue = conditionValue
        ? { ...conditionValue, count: newCount }
        : { units: null, count: newCount }
      onChange({ ...condition, [prop]: newValue })
    },
    [condition, onChange, relativeDatetimeDirectionState],
  )

  const setRelativeDatetimeDirection = useCallback(
    (newDirection: "before" | "after", prop: "value" | "min_value" | "max_value") => {
      // @ts-ignore
      const conditionValue = condition[prop]
      setRelativeDatetimeDirectionState(s => ({ ...s, [prop]: newDirection }))
      if (conditionValue?.count) {
        // ^ also skip if it's 0 (not just undefined/null)
        if (
          (isNegative(conditionValue.count) && newDirection === "after") ||
          (!isNegative(conditionValue.count) && newDirection === "before")
        ) {
          onChange({
            ...condition,
            [prop]: { ...conditionValue, count: -conditionValue.count },
          })
        }
      }
    },
    [condition, onChange],
  )

  const { subject, operator } = condition

  if (subject === null || isEmptyCondition(condition) || isNoValueCondition(condition)) {
    return null
  }

  if (isTypedCondition(condition) && condition.type === "relative_datetime") {
    if (isSingleValueCondition(condition)) {
      const countValue =
        condition.value !== null && condition.value.count !== null
          ? Math.abs(condition.value.count)
          : ""
      const unitsValue =
        relativeDatetimeUnitOptions.find(o => o.value === condition.value?.units) ?? null
      const directionValue =
        relativeDatetimeDirectionOptions.find(
          o => o.value === relativeDatetimeDirectionState.value,
        ) ?? null

      return (
        <>
          <TextInput
            error={error?.relative_datetime_value?.count}
            label="Count"
            type="number"
            min="0"
            value={countValue}
            onChange={e => setRelativeDatetimeCount(e.target.value, "value")}
            disabled={!isEditable}
          />
          <SelectField
            meta={{ touched: true, error: error?.relative_datetime_value?.units }}
            label="Units"
            input={{
              value: unitsValue,
              onChange: ({ value: newUnits }: { value: RelativeDatetimeUnits }) => {
                onChange({
                  ...condition,
                  value: { count: condition.value ? condition.value.count : null, units: newUnits },
                })
              },
            }}
            placeholder="Select…"
            options={relativeDatetimeUnitOptions}
            className={classNames(styles.select, { [styles.readOnly]: !isEditable })}
            disabled={!isEditable}
          />
          <SelectField
            meta={{ touched: true, error: false }}
            label="Before/after"
            input={{
              value: directionValue,
              onChange: ({ value: newDirection }: { value: "before" | "after" }) =>
                setRelativeDatetimeDirection(newDirection, "value"),
            }}
            options={relativeDatetimeDirectionOptions}
            className={classNames(styles.select, { [styles.readOnly]: !isEditable })}
            disabled={!isEditable}
          />
          {RelativeDateTimeTooltip}
        </>
      )
    }

    if (isRangeCondition(condition)) {
      const minCountValue =
        condition.min_value !== null && condition.min_value.count !== null
          ? Math.abs(condition.min_value.count)
          : ""
      const minUnitsValue = relativeDatetimeUnitOptions.find(
        o => o.value === condition.min_value?.units,
      )
      const minDirectionValue = relativeDatetimeDirectionOptions.find(
        o => o.value === relativeDatetimeDirectionState.min_value,
      )

      const maxCountValue =
        condition.max_value !== null && condition.max_value.count !== null
          ? Math.abs(condition.max_value.count)
          : ""
      const maxUnitsValue = relativeDatetimeUnitOptions.find(
        o => o.value === condition.max_value?.units,
      )
      const maxDirectionValue = relativeDatetimeDirectionOptions.find(
        o => o.value === relativeDatetimeDirectionState.max_value,
      )

      return (
        <>
          <TextInput
            error={error?.relative_datetime_min_value?.count}
            label="Since: Count"
            type="number"
            min="0"
            value={minCountValue}
            onChange={e => setRelativeDatetimeCount(e.target.value, "min_value")}
            disabled={!isEditable}
          />
          <SelectField
            meta={{ touched: true, error: error?.relative_datetime_min_value?.units }}
            label="Units"
            input={{
              value: minUnitsValue,
              onChange: ({ value: newUnits }: { value: RelativeDatetimeUnits }) => {
                onChange({
                  ...condition,
                  min_value: {
                    count: condition.min_value ? condition.min_value.count : null,
                    units: newUnits,
                  },
                })
              },
            }}
            placeholder="Select…"
            options={relativeDatetimeUnitOptions}
            className={classNames(styles.select, { [styles.readOnly]: !isEditable })}
            disabled={!isEditable}
          />
          <SelectField
            meta={{ touched: true, error: false }}
            label="Before/after"
            input={{
              value: minDirectionValue,
              onChange: ({ value: newDirection }: { value: "before" | "after" }) =>
                setRelativeDatetimeDirection(newDirection, "min_value"),
            }}
            options={relativeDatetimeDirectionOptions}
            className={classNames(styles.select, { [styles.readOnly]: !isEditable })}
            disabled={!isEditable}
          />
          <div className={styles.spacer}></div>
          <TextInput
            error={error?.relative_datetime_max_value?.count}
            label="Until: Count"
            type="number"
            min="0"
            value={maxCountValue}
            onChange={e => setRelativeDatetimeCount(e.target.value, "max_value")}
            disabled={!isEditable}
          />
          <SelectField
            meta={{ touched: true, error: error?.relative_datetime_max_value?.units }}
            label="Units"
            input={{
              value: maxUnitsValue,
              onChange: ({ value: newUnits }: { value: RelativeDatetimeUnits }) => {
                onChange({
                  ...condition,
                  max_value: {
                    count: condition.max_value ? condition.max_value.count : null,
                    units: newUnits,
                  },
                })
              },
            }}
            placeholder="Select…"
            options={relativeDatetimeUnitOptions}
            className={classNames(styles.select, { [styles.readOnly]: !isEditable })}
            disabled={!isEditable}
          />
          <SelectField
            meta={{ touched: true, error: false }}
            label="Before/after"
            input={{
              value: maxDirectionValue,
              onChange: ({ value: newDirection }: { value: "before" | "after" }) =>
                setRelativeDatetimeDirection(newDirection, "max_value"),
            }}
            options={relativeDatetimeDirectionOptions}
            className={classNames(styles.select, { [styles.readOnly]: !isEditable })}
            disabled={!isEditable}
          />
          {RelativeDateTimeTooltip}
        </>
      )
    }
  }

  if (
    (stringSubjects as readonly string[]).includes(subject) ||
    (settableStringSubjects as readonly string[]).includes(subject) ||
    (isTypedCondition(condition) && condition.type === "string")
  ) {
    if (isSingleValueCondition(condition)) {
      return (
        <TextInput
          error={error?.value}
          label="Value"
          name="value"
          className={styles.fullWidth}
          value={condition.value ?? ""}
          onChange={e => set("value", e.target.value)}
          disabled={!isEditable}
        />
      )
    }

    if (isArrayCondition(condition)) {
      return (
        <SelectField
          meta={{ touched: true, error: error?.values }}
          label="Values"
          placeholder=""
          className={classNames(styles.fullWidth, styles.select, {
            [styles.readOnly]: !isEditable,
          })}
          input={{
            value: valuesToOptions(condition.values),
            onChange: (valueObjects: SelectOption[]) => {
              set("values", optionsToValues(valueObjects))
            },
          }}
          inputId="values-field"
          isCreatable
          isMulti
          allowCopy
          options={[]}
          disabled={!isEditable}
        />
      )
    }
  }

  if (
    (numberSubjects as readonly string[]).includes(subject) ||
    (isTypedCondition(condition) && condition.type === "number")
  ) {
    if (isSingleValueCondition(condition)) {
      return (
        <TextInput
          error={error?.value}
          label="Value"
          name="value"
          className={styles.fullWidth}
          type="number"
          min={subject === "current_hour" ? 0 : undefined}
          max={subject === "current_hour" ? 23 : undefined}
          value={condition.value ?? ""}
          onChange={e =>
            set("value", isNaN(parseFloat(e.target.value)) ? null : parseFloat(e.target.value))
          }
          disabled={!isEditable}
          placeholder={subject === "current_hour" ? "0–23" : undefined}
        />
      )
    }

    if (isRangeCondition(condition)) {
      return (
        <>
          <TextInput
            error={error?.min_value}
            label="Minimum value"
            className={styles.fullWidth}
            type="number"
            min={subject === "current_hour" ? 0 : undefined}
            max={subject === "current_hour" ? 23 : undefined}
            value={condition.min_value ?? ""}
            onChange={e =>
              set(
                "min_value",
                isNaN(parseFloat(e.target.value)) ? null : parseFloat(e.target.value),
              )
            }
            disabled={!isEditable}
            placeholder={subject === "current_hour" ? "0–23" : undefined}
          />
          <TextInput
            error={error?.max_value}
            label="Maximum value"
            className={styles.fullWidth}
            type="number"
            min={subject === "current_hour" ? 0 : undefined}
            max={subject === "current_hour" ? 23 : undefined}
            value={condition.max_value ?? ""}
            onChange={e =>
              set(
                "max_value",
                isNaN(parseFloat(e.target.value)) ? null : parseFloat(e.target.value),
              )
            }
            disabled={!isEditable}
            placeholder={subject === "current_hour" ? "0–23" : undefined}
          />
        </>
      )
    }
  }

  if (
    (datetimeSubjects as readonly string[]).includes(subject) ||
    (isTypedCondition(condition) && condition.type === "datetime")
  ) {
    if (isSingleValueCondition(condition)) {
      condition.value = condition.value as string
      return (
        <>
          <TextInput
            error={error?.value}
            label={operator === "greater" ? "Since" : "Until"}
            className={classNames(styles.datetime, styles.fullWidth)}
            type="datetime-local"
            value={condition.value ? fromUTCToLocal(condition.value) : ""}
            onChange={e => {
              try {
                set("value", fromLocalToUTC(e.target.value))
              } catch {}
            }}
            disabled={!isEditable}
          />
          {DateTimeTooltip}
        </>
      )
    }

    if (isRangeCondition(condition)) {
      const { min_value, max_value } = condition
      return (
        <>
          <TextInput
            error={error?.min_value}
            label="Since"
            className={classNames(styles.datetime, styles.fullWidth)}
            type="datetime-local"
            value={min_value ? fromUTCToLocal(min_value as string) : ""}
            onChange={e => {
              try {
                set("min_value", fromLocalToUTC(e.target.value))
              } catch {}
            }}
            disabled={!isEditable}
          />
          <TextInput
            error={error?.max_value}
            label="Until"
            className={classNames(styles.datetime, styles.fullWidth)}
            type="datetime-local"
            value={max_value ? fromUTCToLocal(max_value as string) : ""}
            onChange={e => {
              try {
                set("max_value", fromLocalToUTC(e.target.value))
              } catch {}
            }}
            disabled={!isEditable}
          />
          {DateTimeTooltip}
        </>
      )
    }
  }

  if ((enumSubjects as readonly string[]).includes(subject)) {
    const options = {
      device: deviceOptions,
      os: osOptions,
      browser: browserOptions,
      browser_language: languageOptions,
      current_day_of_week: dayOfWeekOptions,
      country_code: countryOptions,
    }
    const enumOptions: SelectOption[] = options[condition.subject as EnumSubject]

    const isNumberType = (numberEnumSubjects as readonly string[]).includes(subject)

    if (isSingleValueCondition(condition)) {
      const value = enumOptions.find(({ value }) => value === condition.value) ?? null

      return (
        <SelectField
          meta={{ touched: true, error: error?.value }}
          label="Value"
          className={classNames(styles.fullWidth, styles.select, {
            [styles.readOnly]: !isEditable,
          })}
          input={{
            value,
            onChange: ({ value }: SelectOption) =>
              set("value", (isNumberType ? parseFloat(value) : value) ?? null),
          }}
          options={enumOptions}
          disabled={!isEditable}
        />
      )
    }

    if (isArrayCondition(condition)) {
      const value =
        condition.values
          ?.map((value: string | number) => enumOptions.find(option => option.value === value))
          .filter(Boolean) ?? null

      return (
        <SelectField
          meta={{ touched: true, error: error?.values }}
          label="Values"
          className={classNames(styles.fullWidth, styles.select, {
            [styles.readOnly]: !isEditable,
          })}
          input={{
            value,
            onChange: (valueObjects: SelectOption[]) =>
              set(
                "values",
                optionsToValues(
                  valueObjects.map(({ label, value }) => ({
                    label,
                    value: (isNumberType ? parseFloat(value) : value) ?? null,
                  })),
                ),
              ),
          }}
          inputId="values-field"
          options={enumOptions}
          isMulti
          allowCopy
          disabled={!isEditable}
        />
      )
    }
  }

  return null
}

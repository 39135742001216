import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Paper from "components/UI/elements/Paper"
import styles from "./Events.module.scss"
import { useFetchAllEvents } from "resources/event/eventQueries"
import { useFetchCustomerEventsCounts } from "resources/customer/event/customerEventQueries"
import Page from "components/UI/Page/Page"
import Tippy from "@tippyjs/react"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import create from "zustand"
import SearchWithSourceSelect from "components/UI/components/SearchWithSourceSelect/SearchWithSourceSelect"
import Table, { Name } from "components/Table/Table"
import NewBadge from "components/UI/elements/NewBadge/NewBadge"
import { EventSort, EventFull } from "resources/event/eventTypes"
import { OrderDir } from "types/util"
import EventExamples from "./EventExamples/EventExamples"

type FilterState = {
  orderBy: EventSort
  orderDir: OrderDir
  searchTerm: string
  sourceId?: EventFull["source"]["id"]
  reset: () => void
  setSort: (orderBy: EventSort) => void
  setSearchTerm: (searchTerm: string) => void
  setSourceId: (sourceId: EventFull["source"]["id"] | undefined) => void
}

const filtersInitialState: Pick<FilterState, "orderBy" | "orderDir" | "searchTerm" | "sourceId"> = {
  orderBy: "name",
  orderDir: "ASC",
  searchTerm: "",
}

const useFiltersStore = create<FilterState>(set => ({
  ...filtersInitialState,
  reset: () => set(filtersInitialState),
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
  setSourceId: sourceId => set({ sourceId }),
}))

export default function Events() {
  const { orderBy, orderDir, searchTerm, sourceId, setSearchTerm, setSourceId, setSort, reset } =
    useFiltersStore()
  useEffect(() => reset, [reset])

  const { data: events = [], isSuccess: areEventsFulfilled } = useFetchAllEvents(
    {
      orderBy,
      orderDir,
      searchTerm: searchTerm.trim(),
      sourceId,
    },
    { refetchOnMount: "always" },
  )

  const { data: customerEventsCounts = [] } = useFetchCustomerEventsCounts()

  const columns = [
    {
      id: "name",
      label: "Name",
      gridTemplate: "1fr",
      onSort: () => setSort("name"),
      renderCell: (event: EventFull) => (
        <div>
          <Name name={event.name} /> <NewBadge created={event.created} />
        </div>
      ),
    },
    {
      id: "description",
      label: "Description",
      gridTemplate: "1fr",
      renderCell: (event: EventFull) => event.description,
    },
    {
      id: "source",
      label: "Data source",
      gridTemplate: "1fr",
      onSort: () => setSort("source"),
      renderCell: (event: EventFull) => {
        const eventCount = customerEventsCounts.find(ce => ce.event_id === event.id)
        return (
          <div className={styles.eventSourceFlexWrapper}>
            <div>
              <SrcDstIcon source={event.source} />
            </div>
            <div className={styles.sourceCount}>
              <div>{event.source.name}</div>
              {eventCount?.count && Number.isInteger(eventCount.count) && (
                <div className={styles.count}>{eventCount.count} filled</div>
              )}
            </div>
          </div>
        )
      },
    },
    {
      id: "examples",
      label: "Examples",
      gridTemplate: "3fr",
      renderCell: (event: EventFull) => <EventExamples event={event} />,
    },
  ]

  return (
    <Page
      className={styles.eventsContainer}
      title="Events"
      headerContent={
        <div className={styles.eventsHeaderContainer}>
          <Tippy
            placement="right"
            interactive
            content={
              <>
                <p>
                  Events are <strong>behaviours of a customer</strong>. They show information about
                  what actions customers have performed and when.{" "}
                  <strong>When a customer’s action happens</strong> (e.g. customer visits a web
                  page, makes a payment transaction, etc.){" "}
                  <strong>it is recorded as an event</strong>. Meiro is able to extract data from
                  the events and create attributes like "Date of the last visit to the web" or
                  "Total revenue from all transactions".
                </p>
                <p>
                  To learn more about Events tab, please refer to{" "}
                  <a
                    href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-data-library#bkmrk-events-tab"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    this article
                  </a>
                  .
                </p>
              </>
            }
          >
            <span className={styles.infoTooltipIcon}>
              <FontAwesomeIcon icon={["fas", "info-circle"]} />
            </span>
          </Tippy>
          <SearchWithSourceSelect
            searchValue={searchTerm}
            setSearchValue={setSearchTerm}
            selectValue={sourceId ?? null}
            setSelectValue={sourceId => setSourceId(sourceId ?? undefined)}
            placeholder="Search for events"
            className={styles.searchBox}
          />
        </div>
      }
    >
      {!areEventsFulfilled && <LoadingIndicator />}
      {areEventsFulfilled && (
        <Paper noPaddingTop>
          <Table
            data={events}
            columns={columns}
            sortBy={orderBy}
            sortDir={orderDir}
            getRowTestId={() => "events-row"}
          />
        </Paper>
      )}
    </Page>
  )
}

import { SplitNodeSettings } from "resources/journey/journeyTypes"

export type SplitNodeFormValues = {
  paths: { percentage: number }[]
}

export function settingsToFormValues(settings: SplitNodeSettings): SplitNodeFormValues {
  const paths: { percentage: number }[] = []
  Object.entries(settings.paths).forEach(([index, percentage]) => {
    paths[+index] = { percentage }
  })
  return { paths }
}

export function formValuesToSettings(values: SplitNodeFormValues): SplitNodeSettings {
  return {
    paths: Object.fromEntries(values.paths.map(({ percentage }, index) => [index, percentage])),
  }
}

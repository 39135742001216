import React, { useState } from "react"
import styles from "./AttributesList.module.scss"
import {
  useSensors,
  useSensor,
  PointerSensor,
  DndContext,
  rectIntersection,
  DragOverlay,
  DragStartEvent,
  DragOverEvent,
} from "@dnd-kit/core"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import SortableItem from "components/ConditionBuilder/components/SortableItem/SortableItem"
import { useFieldArray, useFormContext } from "react-hook-form"
import { FunnelChartFormValues } from "../FunnelChartForm/FunnelChartForm"
import AttributeField from "../AttributeField/AttributeField"
import Button from "components/UI/elements/Button/Button"
import Tippy from "@tippyjs/react"

type AttributesListProps = {
  isEditable: boolean
}

export default function AttributesList({ isEditable }: AttributesListProps) {
  const { control } = useFormContext<FunnelChartFormValues>()
  const { fields, append, remove, swap } = useFieldArray({
    name: "data.steps",
    control,
  })
  const [activeId, setActiveId] = useState<string | null>(null)
  const activeIndex = (activeId && fields.findIndex(({ id }) => id === activeId)) as number | null

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id)
  }

  const handleDragMove = ({ active, over }: DragOverEvent) => {
    const activeRect = active.rect.current.translated

    if (over === null || !activeRect) {
      return
    }
    const middleOfActive = activeRect.top + activeRect.height / 2 + window.scrollY
    const overIndex = fields.findIndex(({ id }) => id === over.id)

    if (
      (activeIndex! > overIndex && middleOfActive < over.rect.offsetTop + over.rect.height) ||
      (activeIndex! < overIndex && middleOfActive > over.rect.offsetTop)
    ) {
      swap(activeIndex!, overIndex)
    }
  }

  const handleDragEnd = () => {
    setActiveId(null)
  }

  const sensors = useSensors(useSensor(PointerSensor))
  return (
    <div className={styles.container}>
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={fields} strategy={verticalListSortingStrategy}>
          {fields.map((field, index, fields) => (
            <SortableItem
              key={field.id}
              id={field.id}
              render={({ listeners }) => (
                <AttributeField
                  index={index}
                  dragListeners={listeners}
                  removeSelf={fields.length > 2 ? () => remove(index) : undefined}
                  isEditable={isEditable}
                />
              )}
            />
          ))}
        </SortableContext>
        <DragOverlay dropAnimation={null}>
          {activeId ? (
            <AttributeField index={activeIndex!} removeSelf={() => {}} isEditable={isEditable} />
          ) : null}
        </DragOverlay>
      </DndContext>
      {isEditable && (
        <Tippy content="Funnels are limited to 10 attributes." disabled={fields.length < 10}>
          <div className={styles.addAttributeButton}>
            <Button
              size="sm"
              onClick={() =>
                append({
                  id: "",
                  data_dimension_id: "",
                  timestamp_dimension_id: "",
                  data_dimension_title: "",
                })
              }
              disabled={fields.length >= 10}
            >
              + Add attribute
            </Button>
          </div>
        </Tippy>
      )}
    </div>
  )
}

import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import FeaturedSegmentDetail from "./FeaturedSegmentDetail/FeaturedSegmentDetail"
import FeaturedSegmentsList from "./FeaturedSegmentsList/FeaturedSegmentsList"
import Trash from "pages/Trash/Trash"
import {
  useFetchFeaturedSegmentTrashItems,
  useRestoreFeaturedSegment,
} from "resources/segment/segment/segmentQueries"

export default function FeaturedSegments() {
  return (
    <Switch>
      <Route path={getRoutePath("segments.featured")} exact component={FeaturedSegmentsList} />
      <Route
        path={getRoutePath("segments.featured.trash")}
        exact
        render={() => (
          <Trash
            backRoute={getRoutePath("segments.featured")}
            itemType="featured_segments"
            fetchQuery={useFetchFeaturedSegmentTrashItems}
            restoreMutation={useRestoreFeaturedSegment}
          />
        )}
      />
      <Route path={getRoutePath("segments.featured.detail")} component={FeaturedSegmentDetail} />
    </Switch>
  )
}

import assert from "assert"
import { JourneyNode, MultiChildrenNode } from "./journeyTypes"

export type JourneyNodeTree = {
  node: JourneyNode | null
  width: number
  children: JourneyNodeTree[]
  pathIndex: number
}

export default function nodeListToTree(list: JourneyNode[]): JourneyNodeTree {
  let root: JourneyNode
  const childrenMap: Record<JourneyNode["id"], (JourneyNode | null)[]> = {}

  list.forEach(node => {
    childrenMap[node.id] = []

    if (isMultiChildrenNode(node) && node.settings) {
      Object.keys(node.settings.paths).forEach(path => {
        childrenMap[node.id][+path] = null
      })

      if (node.node_type === "flow_condition") {
        // Condition nodes have an implicit default branch 0
        childrenMap[node.id][0] = null
      }
    }
  })

  list.forEach(node => {
    if (node.previous_journey_node_id === node.id) {
      root = node
    } else {
      childrenMap[node.previous_journey_node_id][node.previous_journey_node_path] = node
    }
  })

  function buildTree(node: JourneyNode | null): Omit<JourneyNodeTree, "pathIndex"> {
    if (!node) {
      return { node: null, children: [], width: 1 }
    }

    if (!isMultiChildrenNode(node)) {
      const [child] = childrenMap[node.id]
      const childTree = buildTree(child ?? null)
      return {
        node,
        width: childTree?.width ?? 1,
        children: [childTree && { ...childTree, pathIndex: 0 }],
      }
    }

    const children = childrenMap[node.id]
    // Keep original index before reordering
    const childrenWithIndices = children.map((child, index) => [child, index] as const)

    let reorderedChildrenWithIndices = childrenWithIndices
    if (node.node_type === "flow_condition") {
      // In condition nodes, the default branch goes last
      const [defaultBranch, ...rest] = childrenWithIndices
      reorderedChildrenWithIndices = [...rest, defaultBranch]
    }

    const mappedChildren = reorderedChildrenWithIndices.map(([child, index]) => ({
      ...buildTree(child),
      pathIndex: index,
    }))

    return {
      node,
      width: mappedChildren.reduce((acc, el) => acc + (el?.width ?? 1), 0),
      children: mappedChildren,
    }
  }

  assert(root!, "Root node not found")
  return { ...buildTree(root)!, pathIndex: 0 }
}

export function isMultiChildrenNode(node: JourneyNode): node is MultiChildrenNode {
  return node.node_type === "flow_split" || node.node_type === "flow_condition"
}

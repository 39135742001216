import React from "react"
import { Route, Switch } from "react-router-dom"

import EmailsList from "./EmailsList/EmailsList"
import { getRoutePath } from "routes"
import EmailCreate from "./EmailCreate/EmailCreate"
import EmailDetail from "./EmailDetail/EmailDetail"
import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import { useHasAccess } from "resources/user/currentUserQueries"
import Trash from "pages/Trash/Trash"
import { useFetchEmailTrashItems, useRestoreEmail } from "resources/email/emailQueries"

export default function EmailsRoutes() {
  const hasAccess = useHasAccess()

  return (
    <Switch>
      <Route path={getRoutePath("channels.emails")} exact component={EmailsList} />
      <AuthorizedRoute
        hasAccess={hasAccess.emails.view}
        path={getRoutePath("channels.emails.trash")}
        exact
        render={() => (
          <Trash
            backRoute={getRoutePath("channels.emails")}
            itemType="emails"
            fetchQuery={useFetchEmailTrashItems}
            restoreMutation={useRestoreEmail}
          />
        )}
      />
      <Route path={getRoutePath("channels.emails.create")} component={EmailCreate} />
      <Route path={getRoutePath("channels.emails.detail")} exact component={EmailDetail} />
    </Switch>
  )
}

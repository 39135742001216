import React from "react"
import { Redirect, useHistory, useParams } from "react-router-dom"

import { getRoutePath } from "routes"
import MetaAttributesForm from "../components/MetaAttributesForm/MetaAttributesForm"
import {
  useFetchMetaAttribute,
  useModifyMetaAttribute,
} from "resources/metaAttributes/metaAttributesQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Page from "components/UI/Page/Page"
import Button from "components/UI/elements/Button/Button"

import styles from "./MetaAttributesDetail.module.scss"

export default function MetaAttributesDetail() {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const { data: metaAttribute, isError, isLoading } = useFetchMetaAttribute(id)
  const { mutateAsync, isLoading: isSubmitting } = useModifyMetaAttribute()

  if (metaAttribute === null) return <Redirect to={getRoutePath("not-found")} />
  if (isError) return null

  return (
    <Page
      title="Edit meta attribute"
      headerContent={
        !isLoading && (
          <div>
            <div className={styles.buttons}>
              <Button
                color="grey"
                variant="outlined"
                onClick={() => history.push(getRoutePath("administration.metaAttributes"))}
              >
                Cancel
              </Button>
              <Button form="metaAttributesForm" loading={isSubmitting} type="submit">
                Save
              </Button>
            </div>
          </div>
        )
      }
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <MetaAttributesForm
          metaAttribute={metaAttribute}
          onSubmit={({ id, ...rest }) => mutateAsync({ id, data: rest })}
        />
      )}
    </Page>
  )
}

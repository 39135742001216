import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { Prompt } from "react-router-dom"

import FileUploadDnD from "components/UI/components/FileUploadDnD/FileUploadDnD"
import FileField from "components/UI/elements/FileField/FileField"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { jsonFile, maxFileSize, maxLength, required } from "helpers/validators.helper"
import { FirebaseProject } from "resources/channel/channelTypes"

import styles from "./FirebaseProjectForm.module.scss"

const MAX_UPLOAD_BYTES = 2 * 1024 * 1024

export type FirebaseProjectFormValues = {
  firebaseFileList: FileList | null
  name: FirebaseProject["name"]
}

type FirebaseProjectFormProps = {
  formId: string
  onSubmit: (
    data: FirebaseProjectFormValues,
    onSubmitSuccess: (data: FirebaseProject) => void,
  ) => void
  hideLabel?: boolean
  hidePrompt?: boolean
  firebaseProject?: FirebaseProject
}

export default function FirebaseProjectForm({
  firebaseProject,
  formId,
  onSubmit,
  hideLabel = false,
  hidePrompt = false,
}: FirebaseProjectFormProps) {
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isDirty, isSubmitted, isSubmitting },
  } = useForm<FirebaseProjectFormValues>({
    defaultValues: { firebaseFileList: null, name: "" },
  })

  useEffect(() => {
    if (firebaseProject) reset({ firebaseFileList: null, name: firebaseProject.name })
  }, [firebaseProject, reset])

  const onFirebaseFileDrop = (files: FileList) => {
    if (files && files[0])
      setValue("firebaseFileList", files, { shouldDirty: true, shouldValidate: true })
  }

  const onClearFile = () => {
    setValue("firebaseFileList", null, {
      shouldDirty: true,
      shouldValidate: isSubmitted,
    })
  }

  return (
    <>
      <Prompt
        when={!hidePrompt && isDirty && !isSubmitting && !isSubmitted}
        message="Changes you made will not be saved."
      />
      <form
        id={formId}
        onSubmit={handleSubmit(formValues =>
          onSubmit(formValues, ({ name }) => {
            reset({ firebaseFileList: null, name })
          }),
        )}
        className={styles.root}
      >
        <div className={styles.nameWrapper}>
          <TextInput
            error={errors.name?.message}
            label={!hideLabel ? "Name" : undefined}
            placeholder="Name"
            maxLength={100}
            {...register("name", {
              validate: {
                required,
                maxLength: maxLength(100),
              },
            })}
            className={styles.name}
          />
          {firebaseProject && <span className={styles.configured}>Configured</span>}
        </div>
        <FileUploadDnD onFileDrop={onFirebaseFileDrop} label="Configure firebase">
          <Controller
            control={control}
            name="firebaseFileList"
            rules={{
              validate: firebaseProject
                ? {
                    jsonFile,
                    maxFileSize: maxFileSize(MAX_UPLOAD_BYTES),
                  }
                : {
                    required,
                    jsonFile,
                    maxFileSize: maxFileSize(MAX_UPLOAD_BYTES),
                  },
            }}
            render={({ field: { value, ref, onBlur, onChange } }) => (
              <div>
                <FileField
                  ref={ref}
                  error={errors.firebaseFileList?.message}
                  accept=".json"
                  value={value?.[0]?.name}
                  onBlur={onBlur}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const target = event.target as HTMLInputElement
                    const { files } = target
                    if (files && files[0]) onChange(files)
                  }}
                  onClearClick={onClearFile}
                  className={styles.fileUploadField}
                />
                <p className={styles.fileUploadDescription}>
                  Firebase configuration JSON file. Max size: 2MB
                </p>
              </div>
            )}
          />
        </FileUploadDnD>
      </form>
    </>
  )
}

import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import {
  UserRole,
  UserRoleCreatePayload,
  UserRoleListDeletedParams,
  UserRoleListDeletedResponse,
  UserRoleModifyPayload,
} from "./userRoleTypes"

const userRole = {
  listAll(): Promise<UserRole[]> {
    return fetchAll({
      fetchFn: (offset, limit) =>
        requestFactory("get", "/users/user_roles", {
          offset,
          limit,
          order_by: "name",
          order_dir: "ASC",
        }),
      key: "roles",
    })
  },
  create(data: UserRoleCreatePayload): Promise<UserRole> {
    return requestFactory("post", "/users/user_roles", data)
  },
  modify(roleId: UserRole["id"], data: UserRoleModifyPayload): Promise<UserRole> {
    return requestFactory("patch", `/users/user_roles/${roleId}`, data)
  },
  delete(roleId: UserRole["id"]): Promise<void> {
    return requestFactory("delete", `/users/user_roles/${roleId}`, {}, true)
  },
  listDeleted({
    offset = 0,
    limit = 20,
    searched_text = "",
  }: UserRoleListDeletedParams = {}): Promise<UserRoleListDeletedResponse> {
    return requestFactory("get", "/users/user_roles/trash", { offset, limit, searched_text })
  },
  restoreDeleted(id: UserRole["id"]): Promise<UserRole> {
    return requestFactory("patch", `/users/user_roles/trash/${id}`)
  },
}

export default userRole

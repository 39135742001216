import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tippy from "@tippyjs/react"
import classNames from "classnames"
import { differenceInDays, parseISO } from "date-fns"
import { descend, prop, sort, without } from "ramda"

import ExpandedInsightChart from "./ExpandedInsightChart/ExpandedInsightChart"
import ExpandedInsightRow from "./ExpandedInsightRow/ExpandedInsightRow"
import ExpandedInsightValue from "./ExpandedInsightValue/ExpandedInsightValue"
import { formatLabel, formatValue } from "./formatters"
import { Color, DataType, ConditionValueType, LabelType } from "./types"
import { getInsightType, getNumerator, isColor } from "./utils"
import { showToast } from "app/toast"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Modal from "components/UI/elements/Modal"
import { SEGMENT_ANALYTICS_FUNCTIONS } from "sharedConstants"
import { AttributeFull } from "resources/attribute/attributeTypes"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"
import { useModifyUser } from "resources/user/userQueries"

import styles from "./ExpandedInsight.module.scss"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"

type Audience = {
  isKnownPercentage: number
  result:
    | number
    | { count: number; segment_percentage: number }
    | Array<{ value: LabelType; segment_percentage: number }>
    | Array<string>
    | null
  total: number
}

type Insight = {
  attribute_id: string | null
  created: string
  description: string | null
  frontend_settings: Record<string, any> | null
  function: string
  id: number
  name: string
  settings: Record<string, any> | null
  sub_attribute_id: string | null
  user_id: number | null
}

export type ExpandedInsightProps = {
  attribute: AttributeFull
  globalAudience: Audience
  insight: Insight
  subAttribute: Pick<AttributeFull, "data_type" | "id" | "name"> | null
  onClose: () => void
  open?: boolean
  segmentedAudience?: Audience
}

export default function ExpandedInsight({
  attribute,
  globalAudience,
  insight,
  open,
  segmentedAudience,
  subAttribute,
  onClose,
}: ExpandedInsightProps) {
  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()

  const { hideInsightTooltip = false, stickyInsightsIds = [] } =
    currentUser?.frontend_settings ?? {}
  const isFavorite = stickyInsightsIds.includes(insight.id)

  const toggleFavorite = () => {
    if (currentUser)
      return modifyUser(
        {
          id: currentUser.id,
          data: {
            frontend_settings: {
              ...(currentUser.frontend_settings ?? {}),
              stickyInsightsIds: isFavorite
                ? without([insight.id], stickyInsightsIds)
                : stickyInsightsIds.concat(insight.id),
            },
          },
        },
        {
          onSuccess: () => {
            showToast(isFavorite ? "Removed from favorites." : "Added to favorites.")
          },
        },
      )
  }

  const hideTooltip = () => {
    if (currentUser && !hideInsightTooltip)
      modifyUser({
        id: currentUser.id,
        data: {
          frontend_settings: {
            ...(currentUser.frontend_settings ?? {}),
            hideInsightTooltip: true,
          },
        },
      })
  }

  const color: Color =
    insight.frontend_settings?.color && isColor(insight.frontend_settings?.color)
      ? insight.frontend_settings.color
      : attribute.source.frontend_settings?.color &&
        isColor(attribute.source.frontend_settings?.color)
      ? attribute.source.frontend_settings.color
      : "meiro"
  const dataType = (subAttribute ? subAttribute.data_type : attribute.data_type) as DataType
  const type = getInsightType(insight.function, dataType)
  const conditionValue: ConditionValueType =
    insight.settings?.value_from && insight.settings?.value_to
      ? [insight.settings.value_from, insight.settings.value_to]
      : insight.settings?.value

  const globalAudienceKnown = getNumerator(globalAudience.isKnownPercentage, globalAudience.total)
  const globalAudienceSegmented =
    globalAudience.result !== null
      ? Array.isArray(globalAudience.result)
        ? globalAudience.result.map(item => {
            if (typeof item === "string")
              return {
                segmented: 0,
                value: item,
              }

            const { segment_percentage, ...rest } = item
            return {
              ...rest,
              segmented: getNumerator(segment_percentage, globalAudience.total),
            }
          })
        : typeof globalAudience.result === "object"
        ? globalAudience.result.count
        : globalAudience.result
      : 0

  let segmentedAudienceKnown: number | undefined = undefined
  let segmentAudienceSegmented:
    | number
    | Array<{
        segmented: number
        value: LabelType
      }>
    | undefined = undefined

  if (segmentedAudience) {
    segmentedAudienceKnown = getNumerator(
      segmentedAudience.isKnownPercentage,
      segmentedAudience.total,
    )

    if (segmentedAudience.result !== null)
      segmentAudienceSegmented = Array.isArray(segmentedAudience.result)
        ? segmentedAudience.result.map(item => {
            if (typeof item === "string")
              return {
                segmented: 0,
                value: item,
              }

            const { segment_percentage, ...rest } = item
            return {
              ...rest,
              segmented: getNumerator(segment_percentage, segmentedAudience.total),
            }
          })
        : typeof segmentedAudience.result === "object"
        ? segmentedAudience.result.count
        : segmentedAudience.result
    else segmentAudienceSegmented = 0
  }

  return (
    <Modal
      open={open}
      customHeader={
        <header className={styles.header}>
          <div className={styles.titleWrapper}>
            <SrcDstIcon source={attribute.source} />
            <span className={classNames(styles.title, styles[color])}>{insight.name}</span>
            {insight.description && (
              <Tippy content={insight.description} placement="bottom">
                <span className={styles.tooltip}>
                  <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                </span>
              </Tippy>
            )}
            {differenceInDays(parseISO(insight.created), Date.now()) < 8 && (
              <span className={styles.new}>New</span>
            )}
          </div>
          <div className={styles.buttons}>
            <IconButton
              icon={isFavorite ? ["fas", "star"] : ["far", "star"]}
              variant="outlined"
              onClick={_ => toggleFavorite()}
              className={classNames(styles.iconButton, styles[color])}
            />
            <IconButton
              icon="times"
              variant="outlined"
              onClick={_ => onClose()}
              className={classNames(styles.iconButton, styles[color])}
            />
          </div>
        </header>
      }
      handleClose={(_: React.MouseEventHandler<HTMLButtonElement>) => onClose()}
      className={styles.root}
    >
      <div className={styles.legend}>
        {segmentedAudience && (
          <ExpandedInsightRow
            color={color}
            known={segmentedAudienceKnown!}
            name="Segmented profiles"
            percentage={segmentedAudience.isKnownPercentage}
            title={attribute.name}
            total={segmentedAudience.total}
          />
        )}
        <ExpandedInsightRow
          primary={!segmentedAudience}
          color={color}
          known={globalAudienceKnown}
          name="All profiles"
          percentage={globalAudience.isKnownPercentage}
          title={attribute.name}
          total={globalAudience.total}
        />
      </div>
      <div className={styles.content}>
        {globalAudienceKnown === 0 || segmentedAudienceKnown === 0 ? (
          <div className={styles.values}>
            <span className={styles.errorMessage}>
              There is insufficient data to view this insight.
            </span>
          </div>
        ) : (
          <>
            {type === "UNIQUE_VALUES" && Array.isArray(globalAudienceSegmented) && (
              <div className={classNames(styles.values, styles.column)}>
                <span className={styles.type}>
                  <b>{SEGMENT_ANALYTICS_FUNCTIONS[type].label} </b>
                </span>
                <span className={classNames(styles.value, styles[color])}>
                  <b>
                    {Array.isArray(segmentAudienceSegmented)
                      ? segmentAudienceSegmented.map(({ value }) => value).join(", ")
                      : globalAudienceSegmented.map(({ value }) => value).join(", ")}
                  </b>
                </span>
              </div>
            )}
            {["MOST_COMMON", "LEAST_COMMON"].includes(type) && (
              <div className={styles.charts}>
                <span className={styles.label}>
                  <b>{SEGMENT_ANALYTICS_FUNCTIONS[type].label}</b>
                </span>
                <div className={styles.flexBox}>
                  {segmentedAudience &&
                    Array.isArray(segmentAudienceSegmented) &&
                    Array.isArray(globalAudienceSegmented) &&
                    sort(descend(prop("segmented")), segmentAudienceSegmented).map(
                      (item, index) => (
                        <ExpandedInsightChart
                          key={item.value}
                          first={index === 0}
                          firstTimeTooltip={!hideInsightTooltip}
                          color={color}
                          globalAudience={{
                            known: globalAudienceKnown,
                            segmented:
                              globalAudienceSegmented.find(g => g.value === item.value)
                                ?.segmented ?? 0,
                            total: globalAudience.total,
                          }}
                          label={formatLabel(item.value, dataType)}
                          segmentedAudience={{
                            known: segmentedAudienceKnown!,
                            segmented: item.segmented,
                            total: segmentedAudience.total,
                          }}
                          type={type}
                          hideTooltip={hideTooltip}
                        />
                      ),
                    )}
                  {!segmentedAudience &&
                    Array.isArray(globalAudienceSegmented) &&
                    sort(descend(prop("segmented")), globalAudienceSegmented).map((item, index) => (
                      <ExpandedInsightChart
                        key={item.value}
                        first={index === 0}
                        firstTimeTooltip={!hideInsightTooltip}
                        color={color}
                        globalAudience={{
                          known: globalAudienceKnown,
                          segmented:
                            globalAudienceSegmented.find(g => g.value === item.value)?.segmented ??
                            0,
                          total: globalAudience.total,
                        }}
                        label={formatLabel(item.value, dataType)}
                        type={type}
                        hideTooltip={hideTooltip}
                      />
                    ))}
                </div>
              </div>
            )}
            {!["MOST_COMMON", "LEAST_COMMON"].includes(type) &&
              !Array.isArray(globalAudienceSegmented) &&
              !Array.isArray(segmentAudienceSegmented) && (
                <>
                  <div className={styles.values}>
                    {segmentAudienceSegmented !== undefined && (
                      <ExpandedInsightValue
                        color={color}
                        conditionValue={conditionValue}
                        dataType={dataType}
                        title="segmented profiles"
                        type={type}
                        value={formatValue({ dataType, type, value: segmentAudienceSegmented })}
                      />
                    )}
                    <ExpandedInsightValue
                      primary={!segmentedAudience}
                      color={color}
                      conditionValue={conditionValue}
                      dataType={dataType}
                      title="all profiles"
                      type={type}
                      value={formatValue({ dataType, type, value: globalAudienceSegmented })}
                    />
                  </div>
                  {!["AVG", "MAX", "MIN", "SUM", "EARLIEST", "LATEST", "UNIQUE_VALUES"].includes(
                    type,
                  ) && (
                    <div className={styles.chart}>
                      <ExpandedInsightChart
                        firstTimeTooltip={!hideInsightTooltip}
                        color={color}
                        globalAudience={{
                          known: globalAudienceKnown,
                          segmented: globalAudienceSegmented,
                          total: globalAudience.total,
                        }}
                        segmentedAudience={
                          segmentedAudience
                            ? {
                                known: segmentedAudienceKnown!,
                                segmented: segmentAudienceSegmented!,
                                total: segmentedAudience.total,
                              }
                            : undefined
                        }
                        type={type}
                        hideTooltip={hideTooltip}
                      />
                    </div>
                  )}
                </>
              )}
          </>
        )}
      </div>
    </Modal>
  )
}

import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { none } from "ramda"
import { useForm } from "react-hook-form"

import Button from "components/UI/elements/Button/Button"
import ConfirmModal from "components/UI/components/ConfirmModal"
import EmailChannelPaper from "../components/EmailChannelPaper/EmailChannelPaper"
import IconButton from "components/UI/elements/IconButton/IconButton"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { email, required } from "helpers/validators.helper"
import {
  useCreateEmailSender,
  useDeleteEmailSender,
  useFetchEmailSenders,
  useResendEmailSenderVerification,
  useSetDefaultEmailSender,
} from "resources/channel/emailSenders/emailSendersQueries"
import { Sender } from "resources/channel/emailSenders/emailSendersTypes"

import styles from "./VerifiedSenders.module.scss"

type VerifiedSendersProps = {
  isWizard?: boolean
  goBack?: () => void
  goForward?: () => void
}

export default function VerifiedSenders({
  goBack,
  goForward,
  isWizard = false,
}: VerifiedSendersProps) {
  const { data: senders = [], isRefetching, refetch } = useFetchEmailSenders()

  return (
    <EmailChannelPaper
      title="Verified senders"
      description="Set up your verified senders. Email addresses will need to be verified with a confirmation
    email."
      headerContent={
        isWizard && (
          <div className={styles.buttons}>
            <Button
              loading={isRefetching}
              color="grey"
              variant="outlined"
              onClick={() => refetch()}
            >
              Refresh
            </Button>
            <Button
              disabled={senders.length === 0 || none(s => s.verified, senders)}
              icon="arrow-right"
              iconPosition="end"
              onClick={goForward}
            >
              3<span className={styles.steps}>/5</span> Continue
            </Button>
          </div>
        )
      }
      goBack={goBack}
    >
      <div className={styles.row}>
        <div className={styles.sendersWrapper}>
          {senders.length > 0 && (
            <>
              <div className={styles.label}>Sender email</div>

              {senders.map(sender => (
                <SenderRow
                  sender={sender}
                  key={sender.email}
                  deleteDisabled={senders.length === 1}
                />
              ))}
            </>
          )}

          <AddSender />
        </div>
      </div>
    </EmailChannelPaper>
  )
}

type SenderRowProps = {
  sender: Sender
  deleteDisabled?: boolean
}

function SenderRow({ sender, deleteDisabled }: SenderRowProps) {
  const setDefaultMutation = useSetDefaultEmailSender()
  const resendVerificationMutation = useResendEmailSenderVerification()
  const deleteMutation = useDeleteEmailSender()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  return (
    <div className={styles.sender}>
      <ConfirmModal
        title="Are you sure?"
        open={isDeleteModalOpen}
        type="delete"
        action="delete"
        what="sender"
        item={sender.email}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={() => deleteMutation.mutate({ email: sender.email })}
        isLoading={deleteMutation.isLoading}
      />

      <div className={styles.senderEmail}>{sender.email}</div>
      {sender.default && (
        <div data-testid="default-sender" className={styles.default}>
          <FontAwesomeIcon icon={["fas", "check"]} />
          Default sender
        </div>
      )}
      {sender.verified ? (
        <>
          <Button
            data-testid="set-default-sender"
            className={styles.defaultButton}
            variant="link"
            color="grey"
            size="xs"
            onClick={() => setDefaultMutation.mutate({ email: sender.email })}
            loading={setDefaultMutation.isLoading}
          >
            Set as default
          </Button>
          <div className={styles.verified}>
            <FontAwesomeIcon icon={["fas", "circle-check"]} />
            Verified address
          </div>
        </>
      ) : (
        <Button
          className={styles.resendButton}
          size="xs"
          variant="link"
          onClick={() => resendVerificationMutation.mutate({ email: sender.email })}
          loading={resendVerificationMutation.isLoading}
        >
          <FontAwesomeIcon icon={["far", "paper-plane"]} />
          Resend verification
        </Button>
      )}
      <IconButton
        data-testid="delete-button"
        color="red"
        size="xs"
        icon="trash-alt"
        tooltip="Delete"
        variant="transparent"
        onClick={() => setIsDeleteModalOpen(true)}
        disabled={deleteDisabled}
      />
    </div>
  )
}

function AddSender() {
  const [isOpen, setIsOpen] = useState(false)
  const createMutation = useCreateEmailSender()
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm<{ email: string }>()
  const addSender = handleSubmit(({ email }) =>
    createMutation.mutate(
      { data: { email } },
      {
        onSuccess() {
          reset({ email: "" })
          setIsOpen(false)
        },
      },
    ),
  )

  if (!isOpen) {
    return (
      <Button
        className={styles.addSenderButton}
        size="sm"
        onClick={() => setIsOpen(true)}
        variant="link"
        type="button"
        icon={["far", "plus"]}
      >
        Add email address
      </Button>
    )
  }

  return (
    <div className={styles.addSender}>
      <div className={styles.addSenderEmail}>
        <TextInput
          error={errors?.email?.message}
          placeholder="Sender email"
          {...register("email", { validate: { required, email } })}
        />
      </div>

      <Button size="sm" onClick={addSender} loading={createMutation.isLoading}>
        Add address
      </Button>

      <IconButton
        icon={["fas", "times"]}
        variant="transparent"
        color="grey"
        size="xs"
        onClick={() => {
          reset({ email: "" })
          setIsOpen(false)
        }}
      />
    </div>
  )
}

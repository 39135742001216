import React, { useState, useCallback } from "react"
import styles from "./LookalikeSegmentsList.module.scss"
import Button from "components/UI/elements/Button/Button"
import Paper from "components/UI/elements/Paper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { PERMISSION, MODAL, DATE_FMT } from "sharedConstants"
import { getRoutePath } from "routes"
import { Segment, SegmentSelectionState } from "resources/segment/segment/segmentTypes"
import Tippy from "@tippyjs/react"
import TagBadge from "components/UI/elements/Tag"
import Username from "components/Username/Username"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import TagPicker from "components/UI/components/TagPicker"
// import { useHistory } from "react-router"
import { format } from "date-fns"
import create from "zustand"
import { uniq } from "ramda"
import {
  useDeleteSegment,
  useFetchLookalikeSegments,
} from "resources/segment/segment/segmentQueries"
import SearchField from "components/UI/elements/SearchField"
import { useHasAccess, useHasSegmentPermission } from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import { abbreviateNumber } from "helpers/number.helper"
import { Link } from "react-router-dom"
import Table, { Column } from "components/Table/Table"
import { useFetchAllTags } from "resources/tag/tagQueries"
import { DBtimestampToDate } from "utilities/date"
import CreateLookalikeSegmentModal from "pages/Segments/components/CreateLookalikeSegmentModal/CreateLookalikeSegmentModal"
import { useFetchSystemInfo } from "resources/systemInfo/systemInfoQueries"
import SegmentSchedulingIcon from "pages/Segments/components/SegmentSchedulingIcon/SegmentSchedulingIcon"

const useStore = create<SegmentSelectionState>(set => ({
  orderBy: "name",
  orderDir: "ASC",
  searchTerm: "",
  selectedTags: [],
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
  addTag: tag => set(state => ({ selectedTags: uniq(state.selectedTags.concat(tag)) })),
  removeTag: tag => set(state => ({ selectedTags: state.selectedTags.filter(t => t !== tag) })),
}))

export default function () {
  const hasAccess = useHasAccess()
  const hasSegmentPermission = useHasSegmentPermission()
  const { data: systemInfo } = useFetchSystemInfo()
  const isCreateDisabled = !systemInfo?.lookalike_segment_configured
  const { data: tags = [] } = useFetchAllTags()

  const [deleteModal, setDeleteModal] = useState<{
    open: boolean
    segment: Segment | null
  }>({ open: false, segment: null })

  const { orderBy, orderDir, searchTerm, selectedTags, addTag, removeTag, setSearchTerm, setSort } =
    useStore()

  const {
    data: lookalikeSegments,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useFetchLookalikeSegments({
    orderBy,
    orderDir,
    searchTerm: searchTerm.trim(),
    selectedTags,
    limit: 50,
  })

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const { mutate: deleteSegment, isLoading: isDeleting } = useDeleteSegment("lookalike")

  const openDeleteSegmentModal = (segment: Segment) => {
    setDeleteModal({
      open: true,
      segment,
    })
  }

  const closeDeleteSegmentModal = useCallback(() => {
    setDeleteModal({
      ...deleteModal,
      open: false,
    })
  }, [deleteModal])

  const onDeleteSegmentConfirm = () => {
    const { segment } = deleteModal

    deleteSegment(segment!.id)
    closeDeleteSegmentModal()
  }

  const columns: Column<Segment>[] = [
    {
      id: "name",
      label: "Name",
      gridTemplate: "2fr",
      onSort: () => setSort("name"),
      renderCell: segment => {
        const isForbidden = !hasSegmentPermission(segment.id, { segmentType: "lookalike" })

        return (
          <div className={styles.segmentName}>
            {!isForbidden && <SegmentSchedulingIcon schedules={segment.schedules} />}
            <Tippy content="You have no permission to access." disabled={!isForbidden}>
              <span className={styles.segmentNameText}>{segment.name}</span>
            </Tippy>
          </div>
        )
      },
    },
    {
      id: "tags",
      label: "Tags",
      gridTemplate: "1.5fr",
      renderCell: segment => (
        <div className={styles.tagsCell}>
          {segment.tag_ids.map(tagId => {
            const tag = tags.find(({ id }) => id === tagId)
            if (tag) {
              return (
                <TagBadge
                  key={tag.id}
                  color={tag.color ?? "primary"}
                  onClick={(evt: MouseEvent) => {
                    evt.preventDefault()
                    addTag(tag.id)
                  }}
                  className={styles.tag}
                >
                  {tag.name}
                </TagBadge>
              )
            }
            return null
          })}
        </div>
      ),
    },
    {
      id: "author_name",
      label: "Author",
      gridTemplate: "max-content",
      onSort: () => setSort("author_name"),
      renderCell: segment => <Username userId={segment.author_id} />,
    },
    {
      id: "segment_count",
      label: "Segmented",
      gridTemplate: "max-content",
      renderCell: segment =>
        typeof segment.customers_count === "number"
          ? `${abbreviateNumber(segment.customers_count)} profile${
              segment.customers_count !== 1 ? "s" : ""
            }`
          : null,
    },
    {
      id: "last_export",
      label: "Last export",
      gridTemplate: "max-content",
      onSort: () => setSort("last_export"),
      renderCell: segment =>
        segment.last_export === null
          ? "—"
          : format(DBtimestampToDate(segment.last_export), DATE_FMT.DATETIME),
    },
    {
      id: "created",
      label: "Modified",
      gridTemplate: "max-content",
      onSort: () => setSort("created"),
      renderCell: segment => (
        <div>
          <div>{format(DBtimestampToDate(segment.created), DATE_FMT.DATETIME)}</div>
          {segment.user_id && (
            <div className={styles.modifiedBy}>
              by <Username userId={segment.user_id} />
            </div>
          )}
        </div>
      ),
    },
    {
      id: "actions",
      gridTemplate: "min-content",
      renderCell: segment => (
        <IconButton
          color="red"
          size="xs"
          onClick={e => {
            e.preventDefault()
            openDeleteSegmentModal(segment)
          }}
          disabled={
            hasSegmentPermission(segment.id, {
              segmentType: "lookalike",
            }) !== PERMISSION.WRITE
          }
          data-testid="delete-segment"
          icon="trash-alt"
          tooltip="Delete"
          variant="outlined"
        />
      ),
    },
  ]

  return (
    <Page
      className={styles.lookalikeSegments}
      title="Lookalike segments"
      headerContent={
        <>
          <SearchField
            input={{ value: searchTerm, onChange: setSearchTerm }}
            placeholder="Search for name or tag"
            wrapperClassName={styles.searchBar}
            onClear={() => setSearchTerm("")}
          />
          <Tippy
            content={
              hasAccess.segments.create
                ? "To create lookalike segments, first configure lookalike settings under the Administration tab."
                : "You don't have the permission to create segments."
            }
            disabled={!isCreateDisabled}
          >
            <div>
              <Button
                onClick={_ => setIsCreateModalOpen(true)}
                disabled={!hasAccess.segments.create || isCreateDisabled}
              >
                + Create lookalike segment
              </Button>
            </div>
          </Tippy>
        </>
      }
    >
      <CreateLookalikeSegmentModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />

      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <Paper>
          <div className={styles.tagsRow}>
            <div className={styles.tagsFilter}>
              <span className={styles.label}>Filter by:</span>
              <span className={styles.tagsAndPicker}>
                {selectedTags.map(tagId => {
                  const tag = tags.find(({ id }) => id === tagId)

                  return (
                    <TagBadge
                      key={tagId}
                      clickable={true}
                      color={tag?.color ?? "primary"}
                      onClick={() => removeTag(tagId)}
                      className={styles.tag}
                    >
                      {tag?.name ?? "Deleted tag"}
                    </TagBadge>
                  )
                })}
                <TagPicker selectedTagIds={selectedTags} allTags={tags} onTagSelect={addTag} />
              </span>
            </div>
            <Link to={getRoutePath("segments.lookalike.trash")}>
              <Button icon={["far", "trash-alt"]} type="button" color="grey" variant="outlined">
                Trash
              </Button>
            </Link>
          </div>
          <Table
            columns={columns}
            data={lookalikeSegments}
            sortBy={orderBy}
            sortDir={orderDir}
            getRowLink={({ id }) =>
              hasSegmentPermission(id, { segmentType: "lookalike" })
                ? getRoutePath("segments.lookalike.detail", { id })
                : undefined
            }
            getRowClassName={({ id }) =>
              hasSegmentPermission(id, { segmentType: "lookalike" })
                ? undefined
                : styles.disabledSegment
            }
            emptyMessage="No segments found."
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </Paper>
      )}
      <ConfirmModal
        open={deleteModal.open}
        type={MODAL.TYPE.DELETE}
        handleClose={closeDeleteSegmentModal}
        handleConfirm={onDeleteSegmentConfirm}
        title="Delete lookalike segment"
        action="delete"
        what="lookalike segment"
        isLoading={isDeleting}
        item={deleteModal.segment?.name ?? ""}
      />
    </Page>
  )
}

import React, { useState } from "react"
import styles from "./RoleDetail.module.scss"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import {
  useDeleteUserRole,
  useFetchUserRoleById,
  useModifyUserRole,
} from "resources/userRole/userRoleQueries"
import { getRoutePath } from "routes"
import { Link, useHistory, useParams } from "react-router-dom"
import Button from "components/UI/elements/Button/Button"
import RoleForm from "../components/RoleForm/RoleForm"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import { allFeatures } from "resources/userRole/features"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import {
  ADMIN_ROLE_ID,
  EMAILS_CHANNEL_NAME,
  PUSH_NOTIFICATIONS_CHANNEL_NAME,
} from "sharedConstants"
import { Feature } from "resources/userRole/userRoleTypes"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { reject, startsWith, whereEq } from "ramda"
import { useFetchChannels } from "resources/channel/channelQueries"

export default function RoleDetail() {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { data: userRole, isLoading: isLoadingRole } = useFetchUserRoleById(+id)
  const { data: currentUser, isLoading: isLoadingCurrentUser } = useFetchCurrentUser()
  const { data: destinations = [], isLoading: isLoadingDestinations } = useFetchAllDestinations()
  const { data: channels = [], isLoading: isLoadingChannels } = useFetchChannels()

  const modifyMutation = useModifyUserRole()
  const deleteMutation = useDeleteUserRole()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const areEmailsActive = channels?.find(whereEq({ name: EMAILS_CHANNEL_NAME }))?.is_active
  const areMobilePushesActive = channels?.find(
    whereEq({ name: PUSH_NOTIFICATIONS_CHANNEL_NAME }),
  )?.is_active
  let filteredFeatures = allFeatures
  if (!areEmailsActive) {
    filteredFeatures = reject(startsWith("emails/"), filteredFeatures)
  }
  if (!areMobilePushesActive) {
    filteredFeatures = reject(startsWith("push_notifications/"), filteredFeatures)
  }

  const initialValues = userRole && {
    name: userRole.name,
    labelIds: userRole.invisible_attributes_tag_ids.map(labelId => ({ labelId })),
    features: Object.fromEntries(
      filteredFeatures.map(feature => [feature, userRole.features.includes(feature)]),
    ) as Record<Feature, boolean>,
  }

  if (initialValues?.features["segments/export"]) {
    destinations.forEach(({ id }) => {
      initialValues.features[`segments/export/${id}`] = true
    })
  }

  if (initialValues) {
    userRole.features.filter(startsWith("segments/export/")).forEach(feature => {
      initialValues.features[feature] = true
    })
  }

  const isLoading =
    isLoadingRole || isLoadingCurrentUser || isLoadingDestinations || isLoadingChannels
  const isAdmin = userRole?.id === ADMIN_ROLE_ID

  return (
    <Page
      title="Edit user role"
      headerContent={
        <div className={styles.buttons}>
          {!isAdmin && (
            <Button
              color="red"
              variant="outlined"
              onClick={() => setIsDeleteModalOpen(true)}
              icon={["far", "trash-alt"]}
            >
              Delete
            </Button>
          )}
          <Link to={getRoutePath("administration.roles")}>
            <Button variant="outlined" color="grey">
              Cancel
            </Button>
          </Link>
          {!isAdmin && (
            <Button type="submit" form="roleForm" loading={modifyMutation.isLoading}>
              Save
            </Button>
          )}
        </div>
      }
    >
      <ConfirmModal
        open={isDeleteModalOpen}
        type="delete"
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={() => {
          deleteMutation.mutate(
            { id: +id },
            {
              onSuccess() {
                history.push(getRoutePath("administration.roles"))
              },
            },
          )
        }}
        isLoading={deleteMutation.isLoading}
        title="Are you sure?"
        action="delete"
        item={userRole?.name ?? ""}
        what="role"
      />

      {isLoading && <LoadingIndicator />}

      {!isLoading && (
        <RoleForm
          initialValues={initialValues}
          onSubmit={data =>
            modifyMutation.mutate(
              { id: +id, data },
              {
                onSuccess() {
                  history.push(getRoutePath("administration.roles"))
                },
              },
            )
          }
          isAdmin={isAdmin}
          isOwn={currentUser?.role.id === +id}
        />
      )}
    </Page>
  )
}

import requestFactory from "api/request"
import {
  UsersActivityFilters,
  FetchUsersActivityResponse,
  FetchUsersActivityRequestParams,
} from "./usersActivityTypes"

export function usersActivity({
  since,
  until,
  filterType,
  filterValue,
}: UsersActivityFilters): Promise<FetchUsersActivityResponse> {
  const requestParams: FetchUsersActivityRequestParams =
    filterType === "all_users"
      ? { since, until }
      : { since, until, [filterType]: filterValue.join(",") }

  return requestFactory("get", "/users/user_activity", requestParams)
}

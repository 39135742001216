import { format } from "date-fns"
import { DATE_FMT } from "sharedConstants"
import { DBtimestampToDate } from "utilities/date"

export const timeResolutionRangeText = (startDate, endDate, timeResolution) => {
  switch (timeResolution) {
    case "hour": {
      return `${format(DBtimestampToDate(startDate), DATE_FMT.DATETIME)}‑${format(
        DBtimestampToDate(endDate),
        DATE_FMT.DATETIME,
      )}`
    }
    case "day": {
      return format(DBtimestampToDate(startDate), DATE_FMT.DATE)
    }
    case "week": {
      return `${format(DBtimestampToDate(startDate), "d.M.")}‑${format(
        DBtimestampToDate(endDate),
        "d.M.y",
      )}`
    }
    case "month": {
      return format(DBtimestampToDate(startDate), DATE_FMT.MONTH_YEAR)
    }
    case "year": {
      return format(DBtimestampToDate(startDate), "yyyy")
    }
    default: {
      return ""
    }
  }
}

export const durationStringFromSeconds = (seconds, precision = "seconds", shortFormat = false) => {
  seconds = Number(seconds)
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor((seconds % 3600) % 60)

  let dDisplay = "",
    hDisplay = "",
    mDisplay = "",
    sDisplay = ""
  if (shortFormat) {
    if (d > 0) dDisplay = `${d}d `
    if ((h > 0 && ["hours", "minutes", "seconds"].includes(precision)) || d === 0)
      hDisplay = `${h}h `
    if ((m > 0 && ["minutes", "seconds"].includes(precision)) || (d === 0 && h === 0))
      mDisplay = `${m}m `
    if ((s > 0 && precision === "seconds") || (d === 0 && h === 0 && s === 0)) sDisplay = `${s}s`
  } else {
    if (d > 0) dDisplay = d === 1 ? `${d} day ` : `${d} days `
    if ((h > 0 && ["hours", "minutes", "seconds"].includes(precision)) || d === 0)
      hDisplay = h === 1 ? `${h} hr ` : `${h} hrs `
    if ((m > 0 && ["minutes", "seconds"].includes(precision)) || (d === 0 && h === 0))
      mDisplay = m === 1 ? `${m} min ` : `${m} mins `
    if ((s > 0 && precision === "seconds") || (d === 0 && h === 0 && s === 0))
      sDisplay = s === 1 ? `${s} sec` : `${s} secs`
  }

  return dDisplay + hDisplay + mDisplay + sDisplay
}

import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import CustomSegmentDetail from "./CustomSegmentDetail/CustomSegmentDetail"
import CustomSegmentsList from "./CustomSegmentsList/CustomSegmentsList"
import Trash from "pages/Trash/Trash"
import {
  useFetchCustomSegmentTrashItems,
  useRestoreCustomSegment,
} from "resources/segment/segment/segmentQueries"

export default function CustomSegments() {
  return (
    <Switch>
      <Route path={getRoutePath("segments.custom")} exact component={CustomSegmentsList} />
      <Route
        path={getRoutePath("segments.custom.trash")}
        exact
        render={() => (
          <Trash
            backRoute={getRoutePath("segments.custom")}
            itemType="custom_segments"
            fetchQuery={useFetchCustomSegmentTrashItems}
            restoreMutation={useRestoreCustomSegment}
          />
        )}
      />
      <Route path={getRoutePath("segments.custom.detail")} component={CustomSegmentDetail} />
    </Switch>
  )
}

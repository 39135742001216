import { useQuery } from "@tanstack/react-query"
import requestFactory from "api/request"
import { differenceInSeconds } from "date-fns"
import { isEmpty } from "ramda"
import { Event } from "resources/event/eventTypes"
import { DBFormatDateTime } from "types/util"
import { DBtimestampToDate } from "utilities/date"

type EventTypesLastEventTimesResponse = {
  last_event_times: {
    event_id: Event["id"]
    last_event_time: DBFormatDateTime
  }[]
}

const eventTypesLastEventTimes = (): Promise<EventTypesLastEventTimesResponse> =>
  requestFactory("get", "/last_event_times")

export function useFetchEventTypesLastEventTimes() {
  return useQuery(["eventTypesLastEventTimes"], eventTypesLastEventTimes, {
    staleTime: 60 * 1000,
    select({ last_event_times }) {
      if (isEmpty(last_event_times)) {
        return null
      }

      return last_event_times.map(({ event_id, last_event_time }) => ({
        item_id: event_id,
        value: differenceInSeconds(new Date(), DBtimestampToDate(last_event_time)),
      }))
    },
  })
}

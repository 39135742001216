import React, { lazy, Suspense, useState } from "react"
import { Redirect, useHistory, useParams } from "react-router-dom"
import ConfirmModal from "components/UI/components/ConfirmModal"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { getRoutePath } from "routes"
import { useDeleteEmail, useFetchEmailById, useModifyEmail } from "resources/email/emailQueries"
import { ModifyEmailPayload } from "resources/email/emailTypes"
import styles from "./EmailDetail.module.scss"
import Page from "components/UI/Page/Page"
import { showToast } from "app/toast"
import ChatGPT from "components/ChatGPT/ChatGPT"
const EmailForm = lazy(() => import("../components/EmailForm/EmailForm"))

export default function EmailDetail() {
  const [modalOpen, setModalOpen] = useState(false)
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const { data: email, isLoading, isError } = useFetchEmailById(id)
  const modifyMutation = useModifyEmail()
  const deleteMutation = useDeleteEmail()

  const modifyEmail = (data: ModifyEmailPayload, onSettled: () => void) =>
    modifyMutation.mutateAsync(
      { id, data },
      {
        onSettled,
        onSuccess: () => {
          showToast("Email modified.")
        },
      },
    )

  if (email === null) {
    return <Redirect to={getRoutePath("not-found")} />
  }

  if (isError) {
    return null
  }

  return (
    <Page
      title="Edit email"
      contentClassName={styles.container}
      backRouteFallback={getRoutePath("channels.emails")}
      headerContent={<ChatGPT />}
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <Suspense fallback={<LoadingIndicator />}>
          <EmailForm email={email} onDelete={() => setModalOpen(true)} onSubmit={modifyEmail} />
        </Suspense>
      )}
      <ConfirmModal
        isLoading={deleteMutation.isLoading}
        open={modalOpen}
        action="delete"
        item={email?.name}
        title="Are you sure?"
        type="delete"
        what="email"
        handleClose={() => setModalOpen(false)}
        handleConfirm={() =>
          deleteMutation.mutate(
            { id },
            {
              onSuccess: () => {
                history.push(getRoutePath("channels.emails"))
              },
            },
          )
        }
      />
    </Page>
  )
}

import { ascend as _ascend, descend as _descend, flip } from "ramda"

const ascStringComparator = new Intl.Collator("en", { caseFirst: "upper" }).compare
const descStringComparator = flip(ascStringComparator)

/**
 * @param [sortToBottomWhen] - An optional function that determines when a value should be sorted to the bottom. Takes in item of the array as input. Not specifing the function results in no changes, and it ignores the bottom sort.
 */
export const ascend = <T>(f: (obj: T) => any, sortToBottomWhen?: (item: any) => boolean) => {
  const ascStandardComparator = _ascend(f)

  return (a: T, b: T) => {
    const fa = f(a)
    const fb = f(b)

    if (sortToBottomWhen) {
      const conditionA = sortToBottomWhen(fa)
      const conditionB = sortToBottomWhen(fb)

      if (conditionA && conditionB) return 0
      if (conditionA) return 1
      if (conditionB) return -1
    }

    return typeof fa === "string" && typeof fb === "string"
      ? ascStringComparator(fa, fb)
      : ascStandardComparator(a, b)
  }
}

/**
 * @param [sortToBottomWhen] - An optional function that determines when a value should be sorted to the bottom. Takes in item of the array as input. Not specifing the function results in no changes, and it ignores the bottom sort.
 */
export const descend = <T>(f: (obj: T) => any, sortToBottomWhen?: (item: any) => boolean) => {
  const descStandardComparator = _descend(f)

  return (a: T, b: T) => {
    const fa = f(a)
    const fb = f(b)

    if (sortToBottomWhen) {
      const conditionA = sortToBottomWhen(fa)
      const conditionB = sortToBottomWhen(fb)

      if (conditionA && conditionB) return 0
      if (conditionA) return 1
      if (conditionB) return -1
    }

    return typeof fa === "string" && typeof fb === "string"
      ? descStringComparator(fa, fb)
      : descStandardComparator(a, b)
  }
}

import requestFactory from "api/request"
import {
  User,
  UserCreatePayload,
  UserFull,
  UserInvitePayload,
  UserListDeletedParams,
  UserListDeletedResponse,
  UserListResponse,
  UserModifyPayload,
  UserReturnType,
} from "./userTypes"
import { Flag } from "types/util"
import fetchAll from "helpers/fetchAll.helper"
import { getAuthToken } from "auth/auth"

const user = {
  login(data: {
    email: string
    password: string
    recaptcha_token?: string
  }): Promise<{ token: string }> {
    return requestFactory("post", "/users/login", data, true, false)
  },
  logout(): Promise<void> {
    return requestFactory("delete", "/users/login")
  },
  oidcLogin(data: { code: string; state: string }): Promise<{ token: string }> {
    return requestFactory("post", "/users/oidc_login", data, true, false)
  },
  metabaseLogin(): Promise<{ token: string }> {
    return requestFactory("post", "/users/metabase_login")
  },
  refreshToken(): Promise<{ token: string }> {
    return requestFactory("patch", "/users/login", {
      token: getAuthToken(),
    })
  },
  me(): Promise<{ me: UserFull }> {
    return requestFactory("get", "/users/me")
  },
  resetPassword(data: { email: string }): Promise<void> {
    return requestFactory("post", "/users/password_reset", data, true)
  },
  setPassword(data: {
    email: string
    password: string
    password_reset_token: string
  }): Promise<void> {
    return requestFactory("patch", "/users/password_reset", data)
  },
  sendInvitation<T extends Flag = 1>(
    data: UserInvitePayload,
    load_full_structure: T = 1 as T,
  ): Promise<UserReturnType<T>> {
    return requestFactory(
      "post",
      `/users/invitation?load_full_structure=${load_full_structure}`,
      data,
    )
  },
  resendInvitation(email: string): Promise<{ user: User }> {
    return requestFactory("patch", "/users/invitation", { email })
  },
  getInvitationLink(userId: User["id"]): Promise<{ user_invitation_link: string }> {
    return requestFactory("get", `/users/${userId}/invitation_link`)
  },
  list<T extends Flag = 1>(offset = 0, limit = 50): Promise<UserListResponse<T>> {
    return requestFactory("get", "/users", {
      offset,
      limit,
    })
  },
  async listAll() {
    const activeUsersPromise = fetchAll({
      fetchFn: (offset, limit): Promise<UserListResponse<1>> =>
        requestFactory("get", "/users", { offset, limit }),
      key: "users",
    })
    const deletedUsersPromise = fetchAll({
      fetchFn: (offset, limit): Promise<UserListDeletedResponse> =>
        requestFactory("get", "/users/trash", { offset, limit }),
      key: "trashed_users",
    })

    return [...(await activeUsersPromise), ...(await deletedUsersPromise)]
  },
  modify<T extends Flag = 1>(id: User["id"], data: UserModifyPayload): Promise<UserReturnType<T>> {
    return requestFactory("patch", `/users/${id}`, data)
  },
  delete(id: User["id"]): Promise<void> {
    return requestFactory("delete", `/users/${id}`)
  },
  create<T extends Flag = 1>(data: UserCreatePayload): Promise<UserReturnType<T>> {
    return requestFactory("post", `/users/`, data)
  },
  listDeleted({
    offset = 0,
    limit = 20,
    searched_text = "",
  }: UserListDeletedParams = {}): Promise<UserListDeletedResponse> {
    return requestFactory("get", "/users/trash", { offset, limit, searched_text })
  },
  restoreDeleted(id: User["id"]): Promise<UserFull> {
    return requestFactory("patch", `/users/trash/${id}`)
  },
}

export default user

import { EmailContentType } from "types/util"

export const parseJsonContent = (contentJson: string) => {
  try {
    return JSON.parse(contentJson)
  } catch {
    return {}
  }
}

export const contentTypeOptions: Array<{ label: string; value: EmailContentType }> = [
  { label: "HTML", value: "html" },
  { label: "Visual Editor", value: "json" },
]

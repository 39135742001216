import React, { useContext, useState } from "react"
import { WaitDelayNodeType, WaitDelayNodeSettings, Journey } from "resources/journey/journeyTypes"
import { useDeleteJourneyNode, useModifyJourneyNode } from "resources/journey/journeyQueries"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ModalWrapper from "components/UI/elements/ModalWrapper/ModalWrapper"
import WaitDelayNodeForm from "../../nodes/WaitDelay/WaitDelayNodeForm"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { StatsContext } from "../../JourneyDetail"
import WaitDelayNodeCard from "./WaitDelayNodeCard"
import { getDescription } from "./getDescription"

type WaitDelayNodeProps = {
  journeyId: Journey["id"]
  node: WaitDelayNodeType
  isEditable: boolean
}

export default function WaitDelayNode({ journeyId, isEditable, node }: WaitDelayNodeProps) {
  const stats = useContext(StatsContext)?.[node.id]
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const modifyMutation = useModifyJourneyNode()
  function onSubmit(settings: WaitDelayNodeSettings) {
    modifyMutation.mutate(
      { journeyId, nodeId: node.id, data: { settings } },
      {
        onSuccess() {
          setIsModalOpen(false)
        },
      },
    )
  }

  const deleteMutation = useDeleteJourneyNode()
  function onDelete() {
    deleteMutation.mutate(
      { journeyId, nodeId: node.id },
      {
        onSuccess() {
          setIsDeleteModalOpen(false)
        },
      },
    )
  }

  return (
    <>
      <ConfirmModal
        title="Are you sure?"
        open={isDeleteModalOpen}
        type="delete"
        action="delete"
        what="node"
        item="Wait"
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={onDelete}
        isLoading={deleteMutation.isLoading}
      />
      <WaitDelayNodeCard
        description={getDescription(node.settings)}
        arrivedCount={stats?.arrived}
        actions={
          <>
            <IconButton
              onClick={() => {
                setIsModalOpen(true)
              }}
              icon={["far", isEditable ? "pencil-alt" : "eye"]}
              variant="transparent"
              color="grey"
              tooltip={isEditable ? "Edit" : "View"}
              size="xs"
            />
            {isEditable && (
              <IconButton
                onClick={() => setIsDeleteModalOpen(true)}
                icon={["far", "trash-alt"]}
                variant="transparent"
                color="red"
                tooltip="Remove"
                size="xs"
              />
            )}
          </>
        }
      />
      <ModalWrapper isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {isModalOpen && (
          <WaitDelayNodeForm
            node={node}
            onSubmit={onSubmit}
            onClose={() => setIsModalOpen(false)}
            isSubmitting={modifyMutation.isLoading}
            isEditable={isEditable}
          />
        )}
      </ModalWrapper>
    </>
  )
}

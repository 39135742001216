import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import SegmentDetail from "pages/Segments/components/SegmentDetail/SegmentDetail"
import SegmentExportLog from "pages/Segments/components/SegmentExportLog"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import { useHasAccess } from "resources/user/currentUserQueries"

export default function LookalikeSegmentDetail() {
  const hasAccess = useHasAccess()

  return (
    <Switch>
      <Route
        path={getRoutePath("segments.lookalike.detail")}
        exact
        render={() => <SegmentDetail view="builder" type="lookalike" />}
      />
      <Route
        path={getRoutePath("segments.lookalike.detail.insights")}
        render={() => <SegmentDetail view="insights" type="lookalike" />}
      />
      <Route
        path={getRoutePath("segments.lookalike.detail.exports")}
        exact
        render={() => <SegmentDetail view="exports" type="lookalike" />}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.segments.export}
        path={getRoutePath("segments.lookalike.export.detail")}
        component={SegmentExportLog}
      />
    </Switch>
  )
}

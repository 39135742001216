import React from "react"
import logo from "images/Meiro-white.svg"

import styles from "./AuthFormLayout.module.scss"
import { AuthCarousel } from "components/Login/AuthCarousel/AuthCarousel"

const AuthFormLayout: React.FC = ({ children }) => (
  <section className={styles.userFormScreens}>
    <div className={styles.formWrapper}>
      <img src={logo} className={styles.logo} alt="meiro cdp logo" />
      {children}
    </div>
    <header className={styles.caption}>
      <AuthCarousel />
    </header>
  </section>
)

export default AuthFormLayout

export function randomInt(upper: number): number {
  return Math.floor(Math.random() * upper)
}

export function randomElement<T>(array: T[]): T {
  return array[randomInt(array.length)]
}

const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

export const generateRandomString = (length: number) => {
  if (length < 1) length = 1
  else if (length > 30) length = 30

  let result = ""
  for (let i = 0; i < length; i++)
    result += characters.charAt(Math.floor(Math.random() * characters.length))

  return result
}

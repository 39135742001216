import { useEffect, useMemo, useState } from "react"
import Tippy from "@tippyjs/react"
import classNames from "classnames"
import { values, whereEq } from "ramda"
import { useParams } from "react-router-dom"

import { ActiveEventIds, ActiveSourceIds, Layout } from "../types"
import { ATTRIBUTE_VALUES_LIMIT } from "sharedConstants"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { ReactComponent as OneColIcon } from "images/one-col.svg"
import { ReactComponent as TwoColsIcon } from "images/two-col.svg"
import SourceBoxes from "../SourceBoxes/SourceBoxes"
import SourceFilter from "../SourceFilter/SourceFilter"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"
import { useModifyUser } from "resources/user/userQueries"
import { useFetchAllDataSources } from "resources/dataSource/dataSourceQueries"
import { useFetchAttributesMapBySourceId } from "resources/attribute/attributeQueries"
import { useFetchCustomerAttributes } from "resources/customer/attribute/customerAttributeQueries"
import { useFetchEventsMap } from "resources/event/eventQueries"

import styles from "./AttributesView.module.scss"

export default function AttributesView() {
  const [activeEventIds, setActiveEventIds] = useState<ActiveEventIds>([])
  const [activeSourceIds, setActiveSourceIds] = useState<ActiveSourceIds>([])

  const { id: profileId } = useParams<{ id: string }>()

  const { data: sources = [] } = useFetchAllDataSources({
    showHidden: true,
  })
  const { data: attributesMapBySourceId = {} } = useFetchAttributesMapBySourceId()
  const { data: eventsMap = {} } = useFetchEventsMap({ includeHidden: true })
  const { data: customerAttributesResponse } = useFetchCustomerAttributes({
    attribute_values_max_count: ATTRIBUTE_VALUES_LIMIT,
    customer_entity_id: profileId,
    load_full_structure: 0,
  })

  const customerSources = useMemo(
    () =>
      sources.filter(source => {
        const sourceAttributes = attributesMapBySourceId[source.id]
        if (Array.isArray(sourceAttributes) && customerAttributesResponse)
          return sourceAttributes.some(attr =>
            customerAttributesResponse.customer_attributes.find(whereEq({ attribute_id: attr.id })),
          )

        return false
      }),
    [attributesMapBySourceId, customerAttributesResponse, sources],
  )

  useEffect(() => {
    if (customerSources.length > 0) setActiveSourceIds(customerSources.map(({ id }) => id))
  }, [customerSources])

  useEffect(() => {
    if (values(eventsMap).length > 0 && customerSources.length > 0)
      setActiveEventIds(
        values(eventsMap)
          .filter(({ source }) => customerSources.some(whereEq({ id: source.id })))
          .map(({ id }) => id),
      )
  }, [eventsMap, customerSources])

  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()

  const layout = currentUser?.frontend_settings?.customerDetailLayout ?? "two-cols"

  const setLayout = (newLayout: Layout) => {
    const { id, frontend_settings } = currentUser!
    const { customerDetailLayout = "two-cols" } = frontend_settings ?? {}

    if (customerDetailLayout !== newLayout) {
      modifyUser({
        id,
        data: {
          frontend_settings: {
            ...(frontend_settings ?? {}),
            customerDetailLayout: newLayout,
          },
        },
      })
    }
  }

  const layoutPicker = (
    <div className={styles.layoutPicker}>
      <Tippy content="Two columns" placement="bottom">
        <div>
          <IconButton
            color="grey"
            customIcon={<TwoColsIcon />}
            variant="transparent"
            onClick={() => setLayout("two-cols")}
            className={classNames(styles.layoutButton, {
              [styles.active]: layout === "two-cols",
            })}
            data-testid="two-cols-button"
          />
        </div>
      </Tippy>
      <Tippy content="Single column" placement="bottom">
        <div>
          <IconButton
            color="grey"
            customIcon={<OneColIcon />}
            variant="transparent"
            onClick={() => setLayout("one-col")}
            className={classNames(styles.layoutButton, {
              [styles.active]: layout === "one-col",
            })}
            data-testid="one-col-button"
          />
        </div>
      </Tippy>
    </div>
  )

  return (
    <>
      <SourceFilter
        activeEventIds={activeEventIds}
        activeSourceIds={activeSourceIds}
        setActiveEventIds={setActiveEventIds}
        setActiveSourceIds={setActiveSourceIds}
        sources={customerSources}
        events={values(eventsMap).filter(({ source }) =>
          customerSources.some(whereEq({ id: source.id })),
        )}
        layoutPicker={layoutPicker}
      />
      <div data-testid="attributes-list" className={styles.customerAttributesBoxes}>
        <SourceBoxes
          sources={customerSources.filter(s => activeSourceIds.some(id => id === s.id))}
          layout={layout}
        />
      </div>
    </>
  )
}

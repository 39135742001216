import React, { useState, useCallback, useEffect } from "react"
import Button from "components/UI/elements/Button/Button"
import CreatePromoCodesListModal from "../components/CreatePromoCodesListModal/CreatePromoCodesListModal"
import ConfirmModal from "components/UI/components/ConfirmModal"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Image from "./promo-codes.svg"
import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"
import Paper from "components/UI/elements/Paper"
import { PromoCodesList } from "resources/promoCodesList/promoCodesListTypes"
import { showToast } from "app/toast"
import UpdatePromoCodesListModal from "../components/UpdatePromoCodesListModal/UpdatePromoCodesListModal"
import { copyStringToClipboard } from "helpers/string.helper"
import styles from "./PromoCodesLists.module.scss"
import Username from "components/Username/Username"
import { useHasAccess } from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import {
  useCreatePromoCodesList,
  useDeletePromoCodesList,
  useFetchAllPromoCodesLists,
  useRenamePromoCodesList,
  useUploadFileToPromoCodesList,
} from "resources/promoCodesList/promoCodesListQueries"
import create from "zustand"
import SearchField from "components/UI/elements/SearchField"
import Table, { Column, Name } from "components/Table/Table"
import { prop } from "ramda"
import Datetime from "components/UI/elements/Datetime/Datetime"

export const MAX_UPLOADED_FILE_SIZE = 2097152

const pageTitle = "Promo codes"

const useStore = create<{ searchTerm: string; setSearchTerm: (s: string) => void }>(set => ({
  searchTerm: "",
  setSearchTerm: searchTerm => set({ searchTerm }),
}))

export default function PromoCodesLists() {
  const hasAccess = useHasAccess()
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false)
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
  const [updateModalItem, setUpdateModalItem] = useState<PromoCodesList | null>(null)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [deleteModalItem, setDeleteModalItem] = useState<PromoCodesList | null>(null)

  const { searchTerm, setSearchTerm } = useStore()
  const { data: promoCodesLists, isLoading } = useFetchAllPromoCodesLists({ searchTerm })
  const createMutation = useCreatePromoCodesList()
  const renameMutation = useRenamePromoCodesList()
  const uploadMutation = useUploadFileToPromoCodesList()
  const deleteMutation = useDeletePromoCodesList()

  const copyListIdToClipboard = useCallback(
    id => () => {
      copyStringToClipboard(id)
      showToast("ID copied to clipboard.")
    },
    [],
  )

  useEffect(() => () => setSearchTerm(""), [setSearchTerm])

  if (!hasAccess.webBanners.view)
    return (
      <Page title={pageTitle}>
        <MarketingContent img={{ alt: "Promo codes", src: Image }}>
          <h1>Promo codes</h1>
          <strong>
            It seems like you don't have access to the Promo codes tab. If you want to know more
            about your access settings, please contact your administrator.
          </strong>
          <p>
            The Personalization tab offers pop-up web banners, embedded web banners and promo codes
            for your orchestrated omnichannel personalization strategy.
          </p>
          <p>
            In the Promo codes tab, you can upload a list of promo codes and display them in web
            banners to the audience of your choice.
          </p>
          <p>
            Learn more about{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-personalization-promo-codes"
              target="_blank"
              rel="noreferrer"
            >
              promo codes in detail
            </a>
            {" & "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-how-to-set-promo-codes-in-web-banners"
              target="_blank"
              rel="noreferrer"
            >
              how to set them for web banners
            </a>
            .
          </p>
        </MarketingContent>
      </Page>
    )

  if (isLoading) {
    return (
      <Page title={pageTitle}>
        <LoadingIndicator />
      </Page>
    )
  }

  const columns: Column<PromoCodesList>[] = [
    {
      id: "id",
      label: "ID",
      gridTemplate: "max-content",
      renderCell: ({ id }) => (
        <IconButton
          icon={["fas", "clone"]}
          color="grey"
          className={styles.copyButton}
          tooltip="Copy id to a clipboard."
          onClick={copyListIdToClipboard(id)}
          variant="transparent"
        />
      ),
    },
    {
      id: "name",
      label: "Name",
      gridTemplate: "1fr",
      renderCell: ({ name }) => <Name name={name} />,
    },
    {
      id: "count",
      label: "Available promo codes",
      gridTemplate: "max-content",
      renderCell: prop("promo_codes_count"),
    },
    {
      id: "createdBy",
      label: "Created by",
      gridTemplate: "max-content",
      renderCell: ({ author_id }) => <Username userId={author_id} />,
    },
    {
      id: "createdAt",
      label: "Date added",
      gridTemplate: "max-content",
      renderCell: ({ created_at }) => <Datetime datetime={created_at} />,
    },
    {
      id: "modified",
      label: "Date modified",
      gridTemplate: "max-content",
      renderCell: ({ last_uploaded_at }) => <Datetime datetime={last_uploaded_at} />,
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: promoCodesList => (
        <div className={styles.actions}>
          <IconButton
            color="black"
            size="xs"
            onClick={() => {
              setUpdateModalIsOpen(true)
              setUpdateModalItem(promoCodesList)
            }}
            variant="outlined"
            icon="pencil-alt"
            tooltip="Edit"
            disabled={!hasAccess.webBanners.edit}
          />
          <IconButton
            color="red"
            size="xs"
            onClick={() => {
              setDeleteModalIsOpen(true)
              setDeleteModalItem(promoCodesList)
            }}
            variant="outlined"
            icon="trash-alt"
            tooltip="Delete"
            disabled={!hasAccess.webBanners.edit}
          />
        </div>
      ),
    },
  ]

  return (
    <Page
      title={pageTitle}
      headerContent={
        <>
          <SearchField
            input={{ value: searchTerm, onChange: setSearchTerm }}
            onClear={() => setSearchTerm("")}
            placeholder="Search for ID or name"
            wrapperClassName={styles.searchBox}
          />
          <Button onClick={() => setCreateModalIsOpen(true)} disabled={!hasAccess.webBanners.edit}>
            + Create list
          </Button>
        </>
      }
    >
      <Paper className={styles.content}>
        {promoCodesLists && (
          <Table
            emptyMessage="No promo codes lists found."
            columns={columns}
            data={promoCodesLists}
          />
        )}
      </Paper>
      {promoCodesLists?.length === 0 && (
        <MarketingContent img={{ alt: "Promo codes", src: Image }}>
          <h1>Promo codes</h1>
          <p>
            Promo codes are the perfect tool to use in your campaigns to reward your customers with
            specific deals or encourage hesitant visitors to become first time shoppers. Sometimes
            little discount is all it takes!
          </p>
          <p>
            Upload a list of promo codes and display them to the audience of your choice. Learn more
            about{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-personalization-promo-codes"
              target="_blank"
              rel="noreferrer"
            >
              Promo codes in detail
            </a>
            {" & "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-how-to-set-promo-codes-in-web-banners"
              target="_blank"
              rel="noreferrer"
            >
              how to set them for web banners
            </a>
            .
          </p>
        </MarketingContent>
      )}
      <CreatePromoCodesListModal
        open={createModalIsOpen}
        handleClose={() => setCreateModalIsOpen(false)}
        onSubmit={values =>
          createMutation.mutateAsync(values, {
            onSuccess() {
              setCreateModalIsOpen(false)
            },
          })
        }
      />
      <UpdatePromoCodesListModal
        open={updateModalIsOpen}
        promoCodesList={updateModalItem}
        handleClose={() => {
          setUpdateModalIsOpen(false)
        }}
        onFileUpload={(id, file) =>
          uploadMutation.mutateAsync(
            { id, file },
            {
              onSuccess() {
                setUpdateModalIsOpen(false)
              },
            },
          )
        }
        onNameChange={(id, name) =>
          renameMutation.mutateAsync(
            { id, name },
            {
              onSuccess() {
                setUpdateModalIsOpen(false)
              },
            },
          )
        }
      />
      <ConfirmModal
        title="Are you sure?"
        open={deleteModalIsOpen}
        type="delete"
        text={`Do you really want to delete promo codes list: ${deleteModalItem?.name}?`}
        handleClose={() => setDeleteModalIsOpen(false)}
        handleConfirm={() =>
          deleteMutation.mutateAsync(
            { id: deleteModalItem!.id },
            {
              onSuccess() {
                setDeleteModalIsOpen(false)
              },
            },
          )
        }
        isLoading={deleteMutation.isLoading}
      />
    </Page>
  )
}

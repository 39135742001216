import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"

import { CustomerDestinations } from "resources/customer/destination/customerDestinationTypes"
import { SegmentExportDestination } from "resources/exportDestination/exportDestinationTypes"

import styles from "./DestinationParticipation.module.scss"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import { inc, partition, prop, whereEq } from "ramda"
import Button from "components/UI/elements/Button/Button"

type DestinationParticipationProps = {
  destinations: Array<SegmentExportDestination>
  customerDestinations: CustomerDestinations["customer_destinations"]
}

const PAGE_SIZE = 10

export default function DestinationParticipation({
  destinations,
  customerDestinations,
}: DestinationParticipationProps) {
  let mappedDestinations = destinations.map(destination => ({
    ...destination,
    isEligible: customerDestinations.some(whereEq({ destination_id: destination.id })),
  }))

  // Let's put eligible destinations first for user convenience
  const [eligible, nonEligible] = partition(prop("isEligible"), mappedDestinations)

  mappedDestinations = eligible.concat(nonEligible)

  const [page, setPage] = useState(1)
  const displayedCount = page * PAGE_SIZE
  const paginatedDestinations = mappedDestinations.slice(0, displayedCount)
  const hasNextPage = mappedDestinations.length > displayedCount

  return (
    <div data-testid="eligible-activations" className={styles.root}>
      <span className={styles.title}>
        <b>Eligible activations</b>
      </span>
      <div className={styles.items}>
        {paginatedDestinations.map(({ id, icon, name, isEligible }) => {
          return (
            <div key={id} className={classNames(styles.item, { [styles.disabled]: !isEligible })}>
              {isEligible && (
                <FontAwesomeIcon icon={["fas", "circle-check"]} className={styles.icon} />
              )}
              <SrcDstIcon iconName={icon} />
              <span className={styles.name}>
                <b>{name}</b>
              </span>
            </div>
          )
        })}
        {hasNextPage && (
          <Button
            color="black"
            variant="link"
            onClick={_ => setPage(inc)}
            className={styles.showMore}
          >
            + Show more
          </Button>
        )}
      </div>
    </div>
  )
}

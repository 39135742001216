import requestFactory from "api/request"
import { Segment } from "resources/segment/segment/segmentTypes"
import { SegmentNote, SegmentNotePayload } from "./segmentNoteTypes"
import fetchAll from "helpers/fetchAll.helper"

const note = {
  listAll(segmentId: Segment["id"]): Promise<SegmentNote[]> {
    return fetchAll({
      fetchFn: (offset, limit) =>
        requestFactory("get", `/segments/${segmentId}/notes`, {
          offset,
          limit,
          order_by: "modified",
          order_dir: "DESC",
          load_full_structure: 0,
        }),
      key: "segment_notes",
      limit: 50,
    })
  },
  create(
    segmentId: Segment["id"],
    data: SegmentNotePayload,
  ): Promise<{ segment_note: SegmentNote }> {
    return requestFactory("post", `/segments/${segmentId}/notes?load_full_structure=0`, data)
  },
  modify(
    segmentId: Segment["id"],
    noteId: SegmentNote["id"],
    data: SegmentNotePayload,
  ): Promise<{ segment_note: SegmentNote }> {
    return requestFactory(
      "patch",
      `/segments/${segmentId}/notes/${noteId}?load_full_structure=0`,
      data,
    )
  },
  delete(segmentId: Segment["id"], noteId: SegmentNote["id"]): Promise<void> {
    return requestFactory("delete", `/segments/${segmentId}/notes/${noteId}`)
  },
}

export default note

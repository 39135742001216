import React from "react"
import { useHistory } from "react-router-dom"

import { getRoutePath } from "routes"
import MetaAttributesForm from "../components/MetaAttributesForm/MetaAttributesForm"
import { useCreateMetaAttribute } from "resources/metaAttributes/metaAttributesQueries"
import Button from "components/UI/elements/Button/Button"
import Page from "components/UI/Page/Page"

import styles from "./MetaAttributesCreate.module.scss"

export default function MetaAttributesCreate() {
  const history = useHistory()

  const { mutateAsync, isLoading } = useCreateMetaAttribute()

  return (
    <Page
      title="Create meta attribute"
      headerContent={
        <div>
          <div className={styles.buttons}>
            <Button
              color="grey"
              variant="outlined"
              onClick={() => history.push(getRoutePath("administration.metaAttributes"))}
            >
              Cancel
            </Button>
            <Button form="metaAttributesForm" loading={isLoading} type="submit">
              Save
            </Button>
          </div>
        </div>
      }
    >
      <MetaAttributesForm
        onSubmit={data =>
          mutateAsync(
            { data },
            {
              onSuccess: ({ meta_attribute: { id } }) =>
                history.push(getRoutePath("administration.metaAttributes.detail", { id })),
            },
          )
        }
      />
    </Page>
  )
}

import { Field, FieldErrors, FieldValues, ResolverOptions, get, set } from "react-hook-form"

export const TTL_4_WEEKS_IN_SEC = 4 * 7 * 24 * 60 * 60 // 2419200

export const ttlOptions = [
  {
    label: "4 weeks",
    value: TTL_4_WEEKS_IN_SEC,
  },
  {
    label: "3 weeks",
    value: 3 * 7 * 24 * 60 * 60, // 1814400
  },
  {
    label: "2 weeks",
    value: 2 * 7 * 24 * 60 * 60, // 1209600
  },
  {
    label: "1 week",
    value: 7 * 24 * 60 * 60, // 604800
  },
  {
    label: "3 days",
    value: 3 * 24 * 60 * 60, // 259200
  },
  {
    label: "2 days",
    value: 2 * 24 * 60 * 60, // 172800
  },
  {
    label: "1 day",
    value: 24 * 60 * 60, // 86400
  },
  {
    label: "4 hours",
    value: 4 * 60 * 60, // 14400
  },
  {
    label: "2 hours",
    value: 2 * 60 * 60, // 7200
  },
  {
    label: "1 hour",
    value: 60 * 60, // 3600
  },
  {
    label: "30 minutes",
    value: 30 * 60, // 1800
  },
  {
    label: "10 minutes",
    value: 10 * 60, // 600
  },
  {
    label: "5 minutes",
    value: 5 * 60, // 300
  },
  {
    label: "3 minutes",
    value: 3 * 60, // 180
  },
  {
    label: "2 minutes",
    value: 2 * 60, // 120
  },
  {
    label: "1 minute",
    value: 60,
  },
]

export const assignRef = <TFieldValues extends FieldValues>(
  errors: FieldErrors,
  options: ResolverOptions<TFieldValues>,
): FieldErrors<TFieldValues> => {
  const fieldErrors = {} as FieldErrors<TFieldValues>

  for (const path in errors) {
    const field = get(options.fields, path) as Field["_f"] | undefined
    const error = Object.assign(errors[path] || {}, { ref: field && field.ref })
    set(fieldErrors, path, error)
  }

  return fieldErrors
}

import requestFactory from "api/request"

const image = {
  emails: {
    post(data: FormData): Promise<{ image_url: string }> {
      return requestFactory("post", "/images/emails", data, false, true, "", false, false, {
        "Content-Type": "multipart/form-data",
      })
    },
  },
  pushNotifications: {
    post(data: FormData): Promise<{ image_url: string }> {
      return requestFactory("post", "/images/mobile_push", data, false, true, "", false, false, {
        "Content-Type": "multipart/form-data",
      })
    },
  },
}

export default image

import {
  AndCondition,
  AndOrCondition,
  ConditionPath,
  ConditionTree,
  OrCondition,
} from "../../types/conditionTree"

function isAndCondition<T>(conditionTree: ConditionTree<T>): conditionTree is AndCondition<T> {
  return conditionTree && (conditionTree as AndCondition<T>).operator === "and"
}

function isOrCondition<T>(conditionTree: ConditionTree<T>): conditionTree is OrCondition<T> {
  return conditionTree && (conditionTree as OrCondition<T>).operator === "or"
}

export function isAndOrCondition<T>(
  conditionTree: ConditionTree<T>,
): conditionTree is AndOrCondition<T> {
  return isAndCondition(conditionTree) || isOrCondition(conditionTree)
}

export function idToPath(id: string): ConditionPath {
  return id.split("").flatMap(i => ["operands", +i])
}

export function pathToId(path: ConditionPath): string {
  return path.filter(el => el !== "operands").join("")
}

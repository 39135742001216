import { useState } from "react"
import classNames from "classnames"

import ApplicationSettings from "../components/ApplicationSettings/ApplicationSettings"
import Button from "components/UI/elements/Button/Button"
import ChannelPaper from "../components/ChannelPaper/ChannelPaper"
import FirebaseProjectForm, {
  FirebaseProjectFormValues,
} from "../components/FirebaseProjectForm/FirebaseProjectForm"
import Modal from "components/UI/elements/Modal"
import {
  useCreateFirebaseProject,
  useFetchFirebaseProjects,
  useModifyFirebaseProject,
} from "resources/channel/channelQueries"
import { FirebaseProject } from "resources/channel/channelTypes"

import styles from "./FirebaseProjectConfiguration.module.scss"

export default function FirebaseProjectConfiguration() {
  const [modalOpen, setModalOpen] = useState(false)

  const { data: firebaseProjects = [] } = useFetchFirebaseProjects()
  const [selectedProject, setSelectedProject] = useState<FirebaseProject>(firebaseProjects[0])

  const { mutate, isLoading } = useModifyFirebaseProject()

  const onSubmit = (
    { firebaseFileList, name }: FirebaseProjectFormValues,
    resetForm: (data: FirebaseProject) => void,
  ) => {
    if (isLoading || !selectedProject) return

    const formData = new FormData()
    formData.append("name", name)
    if (firebaseFileList) formData.append("firebase_account_data", firebaseFileList[0])

    mutate(
      { id: selectedProject.id, data: formData },
      {
        onSuccess: ({ firebase_project }) => resetForm(firebase_project),
      },
    )
  }

  const formId = "modifyProjectForm"

  return (
    <>
      <ChannelPaper
        isSubmitting={isLoading}
        isWizard={false}
        formId={formId}
        title="Firebase project"
        headerContent={
          <div className={styles.headerContent}>
            {firebaseProjects.map(project => (
              <Button
                key={project.id}
                icon={project.apps_available ? "circle-check" : "circle-xmark"}
                onClick={() => setSelectedProject(project)}
                className={classNames(styles.button, {
                  [styles.active]: selectedProject?.id === project.id,
                  [styles.checked]: project.apps_available,
                })}
              >
                {project.name}
              </Button>
            ))}
            <Button
              icon={["far", "plus"]}
              onClick={() => setModalOpen(true)}
              className={classNames(styles.button, styles.buttonAddNew)}
            >
              Add new project
            </Button>
          </div>
        }
      >
        <div className={styles.root}>
          <FirebaseProjectForm
            hideLabel
            formId={formId}
            firebaseProject={selectedProject}
            onSubmit={onSubmit}
          />
          {selectedProject && <ApplicationSettings firebaseProjectId={selectedProject.id} />}
        </div>
      </ChannelPaper>
      <AddProjectModal
        open={modalOpen}
        closeModal={() => setModalOpen(false)}
        onSubmitSuccess={(data: FirebaseProject) => setSelectedProject(data)}
      />
    </>
  )
}

type AddProjectModalProps = {
  open: boolean
  closeModal: () => void
  onSubmitSuccess: (data: FirebaseProject) => void
}

const AddProjectModal = ({ open, closeModal, onSubmitSuccess }: AddProjectModalProps) => {
  const { mutate, isLoading } = useCreateFirebaseProject()

  const onSubmit = (
    { firebaseFileList, name }: FirebaseProjectFormValues,
    resetForm: (data: FirebaseProject) => void,
  ) => {
    if (isLoading || !firebaseFileList) return

    const formData = new FormData()
    formData.append("name", name)
    formData.append("firebase_account_data", firebaseFileList[0])

    mutate(
      { data: formData },
      {
        onSuccess: ({ firebase_project }) => {
          resetForm(firebase_project)
          onSubmitSuccess(firebase_project)
          closeModal()
        },
      },
    )
  }

  const formId = "createProjectForm"

  return (
    <Modal open={open} title="Add project" handleClose={closeModal} className={styles.modal}>
      <FirebaseProjectForm hidePrompt formId={formId} onSubmit={onSubmit} />

      <div className={styles.buttons}>
        <Button color="grey" size="md" variant="text" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button loading={isLoading} form={formId} color="green" size="md" type="submit">
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import {
  AttributeCreatePayload,
  Attribute,
  AttributeModifyPayload,
  AttributeFull,
  AttributeTestPayload,
  AttributeTestResponse,
} from "./attributeTypes"

const attribute = {
  listAll(): Promise<AttributeFull[]> {
    return fetchAll({
      fetchFn: (offset, limit) =>
        requestFactory("get", "/attributes", {
          offset,
          limit,
        }),
      key: "attributes",
      limit: 500,
    })
  },
  create(data: AttributeCreatePayload): Promise<{ attribute: AttributeFull }> {
    return requestFactory("post", "/attributes?load_full_structure=1", data)
  },
  modify(id: Attribute["id"], data: AttributeModifyPayload): Promise<{ attribute: AttributeFull }> {
    return requestFactory("patch", `/attributes/${id}?load_full_structure=1`, data)
  },
  test(data: AttributeTestPayload): Promise<AttributeTestResponse> {
    return requestFactory("post", "/attributes_checker", data)
  },
}

export default attribute

export const isAttributeCompound = dataType => {
  if (dataType && dataType.startsWith("compound(")) {
    return true
  }
  return false
}

export const getCompoundAttributeSubAttributes = dataType => {
  const subAttributes = []
  if (isAttributeCompound(dataType)) {
    const trimmedDataType = dataType.trim()
    const subAttributesArrayString = trimmedDataType.substring(9, trimmedDataType.length - 1)
    const subAttributesArray = JSON.parse(subAttributesArrayString)
    if (Array.isArray(subAttributesArray)) {
      subAttributesArray.forEach(attr => {
        subAttributes.push({
          id: attr[0],
          name: attr[1],
          data_type: attr[2],
        })
      })
    }
  }
  return subAttributes
}

export const getCompoundAttributeSubAttribute = (id, attributeDataType) => {
  const subAttributes = getCompoundAttributeSubAttributes(attributeDataType)
  return subAttributes.find(subAttr => subAttr.id === id)
}

import React, { lazy, PureComponent, Suspense, useEffect } from "react"

// ui components
import Paper from "components/UI/elements/Paper"
import ExpandedInsight from "components/UI/components/ExpandedInsight/ExpandedInsight"
import SegmentationNumbers from "components/UI/components/SegmentationNumbers"

import "./Insights.scss"
import { SocketContext } from "context/socket"
import { getCompoundAttributeSubAttribute } from "resources/attribute/compoundAttributeUtils"
import { reorderStickyInsights } from "helpers/insight.helper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import { useFetchCurrentUser, useHasAccess } from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import { RowMessage } from "components/UI/elements/Table"
import create from "zustand"
import SearchWithSourceSelect from "components/UI/components/SearchWithSourceSelect/SearchWithSourceSelect"
import { isObject } from "utilities/isObject"
import { differenceInDays } from "date-fns"
import { DBtimestampToDate } from "utilities/date"
import { useFetchSegmentAggregations } from "resources/segment/segmentAggregation/segmentAggregationQueries"
import { useFetchAllAttributeAggregations } from "resources/attributeAggregation/attributeAggregationQueries"
import { useFetchCustomersCount } from "resources/stats/customersCount"
const InsightTile = lazy(() => import("components/UI/elements/InsightTile"))

class Insights extends PureComponent {
  static contextType = SocketContext

  constructor(props) {
    super(props)
    this.state = {
      expandedInsight: {
        data: null,
        open: false,
      },
    }
  }

  toggleInsightModal = ({ attribute, globalAudience, insight, subAttribute }) => {
    this.setState({
      expandedInsight: {
        open: true,
        data: {
          attribute,
          globalAudience,
          insight,
          subAttribute,
        },
      },
    })
  }

  closeExpandedInsight = () => {
    const { expandedInsight } = this.state

    if (expandedInsight.open)
      this.setState({
        expandedInsight: {
          ...expandedInsight,
          open: false,
        },
      })
  }

  render() {
    const {
      areAttributesFulfilled,
      attributesMapById,
      stickyInsightIds,
      hasAccess,
      filters,
      attributeAggregations,
      areAttributeAggregationsFulfilled,
      aggregationValues,
      customersCount,
    } = this.props
    const { expandedInsight } = this.state
    const { searchTerm, setSearchTerm, sourceId, setSourceId } = filters

    let resultAttributeAggregations = attributeAggregations
    if (searchTerm) {
      resultAttributeAggregations = resultAttributeAggregations?.filter(item => {
        return item.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
      })
    }

    if (sourceId) {
      resultAttributeAggregations = resultAttributeAggregations?.filter(item => {
        return attributesMapById[item.attribute_id]?.source.id === sourceId
      })
    }

    resultAttributeAggregations = reorderStickyInsights(
      resultAttributeAggregations,
      stickyInsightIds,
    )

    const dataSize = Array.isArray(attributeAggregations) ? attributeAggregations.length : 0
    return (
      <Page
        title="Insights"
        className="insights"
        headerContent={
          <>
            {areAttributeAggregationsFulfilled && areAttributesFulfilled && (
              <SearchWithSourceSelect
                searchValue={searchTerm}
                setSearchValue={setSearchTerm}
                selectValue={sourceId}
                setSelectValue={setSourceId}
                placeholder="Search for insights"
              />
            )}
            <div className="layout-customers-count-wrapper">
              {typeof customersCount?.customer_entities_count === "number" && (
                <SegmentationNumbers
                  totalNumber={customersCount.customer_entities_count}
                  segmentedNumber={customersCount.customer_entities_count}
                />
              )}
            </div>
          </>
        }
      >
        {!areAttributesFulfilled && <LoadingIndicator />}
        {areAttributesFulfilled && dataSize > 0 && (
          <div className="tiles-section">
            {resultAttributeAggregations.map(item => {
              const aggregationValueObj = aggregationValues?.[item.id.toString()]
              let aggregationValue = null
              let percentage = null
              if (aggregationValueObj) {
                if (isObject(aggregationValueObj.result)) {
                  if ("value" in aggregationValueObj.result) {
                    aggregationValue = aggregationValueObj.result.value
                  } else if ("count" in aggregationValueObj.result) {
                    aggregationValue = aggregationValueObj.result.count
                  }
                  percentage = aggregationValueObj.result.segment_percentage
                } else {
                  aggregationValue = aggregationValueObj.result
                }
              }
              if (Array.isArray(aggregationValue) && aggregationValue.length) {
                if (isObject(aggregationValue[0])) {
                  const tmp = [...aggregationValue]
                  percentage = []
                  aggregationValue = []
                  tmp.forEach(obj => {
                    if ("value" in obj) {
                      aggregationValue.push(obj.value)
                    } else if ("count" in obj) {
                      aggregationValue.push(obj.count)
                    }
                    percentage.push(obj.segment_percentage)
                  })
                }
              }
              const attribute = attributesMapById[item.attribute_id]
              const subAttribute =
                attribute && item.sub_attribute_id
                  ? getCompoundAttributeSubAttribute(item.sub_attribute_id, attribute.data_type)
                  : null
              const compareValue =
                item.settings?.value_from && item.settings?.value_to
                  ? [item.settings.value_from, item.settings.value_to]
                  : item.settings?.value
              return (
                <Suspense key={item.id} fallback={<LoadingIndicator />}>
                  <InsightTile
                    id={item.id}
                    name={item.name}
                    value={aggregationValue}
                    compareValue={compareValue}
                    attribute={attribute}
                    subAttribute={subAttribute}
                    percentage={percentage}
                    funcType={item.function}
                    outOf={customersCount?.customer_entities_count}
                    className="insights-tile"
                    color={item.frontend_settings?.color}
                    attributePercentage={aggregationValueObj?.customers_with_attribute_percentage}
                    showNewBadge={differenceInDays(new Date(), DBtimestampToDate(item.created)) < 8}
                    displayType={item.frontend_settings?.tile_type ?? "chart"}
                    onExpandClick={() =>
                      this.toggleInsightModal({
                        subAttribute,
                        attribute: attributesMapById[item.attribute_id],
                        insight: item,
                        globalAudience: {
                          isKnownPercentage:
                            aggregationValueObj?.customers_with_attribute_percentage,
                          result: aggregationValueObj?.result,
                          total: customersCount?.customer_entities_count,
                        },
                      })
                    }
                    isLoading={!aggregationValueObj}
                    isSticky={stickyInsightIds.includes(item.id)}
                  />
                </Suspense>
              )
            })}
          </div>
        )}
        {areAttributesFulfilled && areAttributeAggregationsFulfilled && dataSize === 0 && (
          <Paper className="insights-no-content">
            {hasAccess.setup.insights && (
              <RowMessage>
                Insights not set. Go to Administration / Insights tab to create insights.
              </RowMessage>
            )}
            {!hasAccess.setup.insights && (
              <RowMessage>Insights not set. Contact admin to set insights.</RowMessage>
            )}
          </Paper>
        )}
        {dataSize > 0 && resultAttributeAggregations.length === 0 && (
          <Paper className="insights-no-content">
            <RowMessage>Nothing found.</RowMessage>
          </Paper>
        )}

        {expandedInsight.data && expandedInsight.open && (
          <ExpandedInsight
            {...expandedInsight.data}
            favorite={stickyInsightIds.includes(expandedInsight.data.insight.id)}
            open={expandedInsight.open}
            onClose={this.closeExpandedInsight}
          />
        )}
      </Page>
    )
  }
}

const filtersInitialState = {
  searchTerm: "",
  sourceId: null,
}

const useFiltersStore = create(set => ({
  ...filtersInitialState,

  reset: () => set(filtersInitialState),

  setSearchTerm: searchTerm => set({ searchTerm }),
  setSourceId: sourceId => set({ sourceId }),
}))

export default props => {
  const { data: attributesMap = {}, isSuccess } = useFetchAttributesMap()
  const { data: currentUser } = useFetchCurrentUser()
  const hasAccess = useHasAccess()
  const filters = useFiltersStore()
  useEffect(() => filters.reset, [filters.reset])

  const { data: attributeAggregations, isSuccess: areAttributeAggregationsFulfilled } =
    useFetchAllAttributeAggregations()
  const { allAggregationValues } = useFetchSegmentAggregations()

  const { data: customersCount } = useFetchCustomersCount()

  return (
    <Insights
      {...props}
      attributesMapById={attributesMap}
      areAttributesFulfilled={isSuccess}
      stickyInsightIds={currentUser.frontend_settings?.stickyInsightsIds ?? []}
      hasAccess={hasAccess}
      filters={filters}
      attributeAggregations={attributeAggregations}
      areAttributeAggregationsFulfilled={areAttributeAggregationsFulfilled}
      aggregationValues={allAggregationValues}
      customersCount={customersCount}
    />
  )
}

import { showToast } from "app/toast"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Modal from "components/UI/elements/Modal"
import { copyStringToClipboard } from "helpers/string.helper"
import styles from "./IdentityStitchingForm.module.scss"

type Props = {
  open: boolean
  handleClose: () => void
}

type ExamplesType = {
  label: string
  description: string
  regexPattern: string
}

const copyToClipboad = (value: string) => {
  copyStringToClipboard(value)
  showToast("Copied to clipboard.")
}

const EXAMPLES: ExamplesType[] = [
  {
    label: "Email",
    description:
      "Basic email validation checking for a valid string of characters followed by '@' and another valid string of characters.",
    regexPattern: "^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$",
  },
  {
    label: "UUID",
    description: "Matches Meiro UUIDs formats like 123e4567-e89b-12d3-a456-426614174000:",
    regexPattern:
      "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$",
  },
  {
    label: "MD5 Hash",
    description: "MD5 hashes are 32 characters in length, consisting of hexadecimal digits:",
    regexPattern: "^[0-9a-fA-F]{32}$",
  },
  {
    label: "SHA256 Hash",
    description:
      "SHA256 hashes are 64 characters in length, also consisting of hexadecimal digits:",
    regexPattern: "^[0-9a-fA-F]{64}$",
  },
]

export const RegexExamplesModal = ({ open, handleClose }: Props) => {
  return (
    <Modal size="large" open={open} title="Regular expression examples" handleClose={handleClose}>
      {EXAMPLES.map(({ label, description, regexPattern }) => (
        <div key={label} className={styles.regexModalBoxContainer}>
          <h4>{label}</h4>
          <p>{description}</p>
          <div className={styles.regexModalInputBox}>
            {regexPattern}
            <IconButton
              icon={["fas", "clone"]}
              color="grey"
              tooltip="Copy id to a clipboard."
              onClick={() => copyToClipboad(regexPattern)}
              variant="transparent"
            />
          </div>
        </div>
      ))}
      <div className={styles.separator}></div>
      <div className={styles.modalFooter}>
        You can validate your regular expression in{" "}
        <a href="https://www.regextester.com/99203" target="_blank" rel="noreferrer">
          regex tester tool
        </a>
      </div>
    </Modal>
  )
}

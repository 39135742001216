import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tippy from "@tippyjs/react"
import classNames from "classnames"
import { isFuture } from "date-fns"
import { format, utcToZonedTime } from "date-fns-tz"
import { whereEq } from "ramda"

import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import { getScheduleText } from "resources/segment/segment/utilities/segmentSchedulesUtils"
import { SegmentSchedule } from "resources/segment/segment/segmentTypes"
import { useHasAccess } from "resources/user/currentUserQueries"
import { DATE_FMT } from "sharedConstants"

import styles from "./SegmentSchedulingIcon.module.scss"

type SegmentSchedulingIconProps = {
  schedules: Array<SegmentSchedule>
}

export default function SegmentSchedulingIcon({ schedules }: SegmentSchedulingIconProps) {
  const hasAccess = useHasAccess()
  const {
    fetchStatus,
    data: destinations = [],
    isLoading: isLoadingDestinations,
  } = useFetchAllDestinations(
    { orderBy: "name", orderDir: "ASC" },
    { enabled: hasAccess.segments.export },
  )

  // when enabled is false, isLoading is true - to prevent infinite spinner
  const isLoading = isLoadingDestinations && fetchStatus !== "idle"

  const activeSchedules = schedules.filter(
    schedule =>
      (schedule.schedule_type === "repetitive" && schedule.schedules.length > 0) ||
      (schedule.schedule_type === "static" && isFuture(new Date(schedule.schedule_time))),
  )
  const isActive = activeSchedules.length > 0

  const tooltipContent = !hasAccess.segments.export ? (
    "You don't have permission to view segment export schedule."
  ) : !isActive ? (
    "Not scheduled"
  ) : (
    <>
      {activeSchedules.map(schedule => {
        const { schedule_type, destination_id } = schedule

        const destination = destinations.find(whereEq({ id: destination_id }))
        if (!destination) return null

        const strSchedule =
          schedule_type === "static"
            ? `at ${format(utcToZonedTime(schedule.schedule_time, "UTC"), DATE_FMT.DATETIME, {
                timeZone: "UTC",
              })} UTC`
            : schedule.schedules.map(cron => getScheduleText(cron)).join("; ")

        return (
          <div
            key={destination.id}
            className={classNames(styles.schedule, styles[destination.frontend_settings?.color])}
          >
            <SrcDstIcon destination={destination} white />
            <p>
              {" "}
              {destination.name} – {strSchedule}{" "}
            </p>
          </div>
        )
      })}
    </>
  )

  return (
    <Tippy
      content={tooltipContent}
      disabled={isLoading}
      className={classNames({ [styles.tooltip]: isActive && hasAccess.segments.export })}
    >
      {isLoading ? (
        <LoadingIndicator fixedWidth size="xs" />
      ) : (
        <span
          className={classNames(styles.icon, {
            [styles.active]: isActive && hasAccess.segments.export,
          })}
          data-testid={isActive && hasAccess.segments.export ? "active-schedule" : undefined}
        >
          <FontAwesomeIcon icon={["far", "clock"]} />
        </span>
      )}
    </Tippy>
  )
}

import React from "react"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import SourceForm from "../components/SourceForm/SourceForm"
import "./SourceDetail.scss"
import { refetchAttributes } from "resources/attribute/attributeQueries"
import { refetchEvents } from "resources/event/eventQueries"
import Page from "components/UI/Page/Page"
import { useFetchAllDataSources, useModifyDataSource } from "resources/dataSource/dataSourceQueries"
import { useHistory, useParams } from "react-router-dom"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { SourceModifyPayload } from "resources/dataSource/dataSourceTypes"
import { pick } from "ramda"

export default function SourceDetail() {
  const { id } = useParams<{ id: string }>()
  const modifyMutation = useModifyDataSource()
  const toggleMutation = useModifyDataSource()
  const { data = [], isSuccess: areSourcesFulfilled } = useFetchAllDataSources({ showHidden: true })
  const source = data.find(source => source.id === id)
  const history = useHistory()

  function modifySource(data: SourceModifyPayload) {
    modifyMutation.mutate(
      { id, data },
      {
        onSuccess() {
          history.push(getRoutePath("administration.sources"))
        },
      },
    )
  }

  function toggleHiddenAttribute() {
    toggleMutation.mutate(
      { id, data: { is_hidden: source!.is_hidden === 1 ? 0 : 1 } },
      {
        onSuccess() {
          refetchAttributes()
          refetchEvents()
        },
      },
    )
  }

  function cloneSource() {
    history.push({
      pathname: getRoutePath("administration.sources.create"),
      state: { source: { ...source, is_system: 0 } },
    })
  }

  return (
    <Page
      className="detail-source"
      title="Edit source"
      headerContent={
        <div className="header-buttons">
          {source && (
            <>
              {!source.is_system && (
                <Button
                  color="red"
                  icon={["far", source.is_hidden === 1 ? "eye" : "eye-slash"]}
                  variant="outlined"
                  onClick={toggleHiddenAttribute}
                  loading={toggleMutation.isLoading}
                  disabled={modifyMutation.isLoading}
                >
                  {source.is_hidden === 1 ? "Show" : "Hide"}
                </Button>
              )}
              <Button color="grey" icon="clone" variant="outlined" onClick={cloneSource}>
                Copy
              </Button>
            </>
          )}
          {!source?.is_system && (
            <Button
              color="grey"
              variant="outlined"
              onClick={() => {
                history.push(getRoutePath("administration.sources"))
              }}
            >
              Cancel
            </Button>
          )}
          {!source?.is_system && (
            <Button type="submit" form="sourceForm" loading={modifyMutation.isLoading}>
              Save
            </Button>
          )}
        </div>
      }
    >
      {!areSourcesFulfilled && <LoadingIndicator />}

      {areSourcesFulfilled && (
        <Paper hasHeader className="detail-source-content">
          <SourceForm
            onSubmit={modifySource}
            initialValues={pick(["id", "name", "type", "description", "frontend_settings"], source)}
            isSystem={!!source?.is_system}
          />
        </Paper>
      )}
    </Page>
  )
}

import Page from "components/UI/Page/Page"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import marketingImg from "./marketingContent.jpg"
import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"

export default function WhatsApp() {
  return (
    <Switch>
      <Route path={getRoutePath("channels.whatsapp")} exact component={WhatsAppMarketingContent} />
    </Switch>
  )
}

function WhatsAppMarketingContent() {
  return (
    <Page title="WhatsApp campaigns">
      <MarketingContent img={{ alt: "WhatsApp", src: marketingImg }}>
        <h1>Coming Soon: WhatsApp Channel</h1>
        <p>
          Our team is hard at work crafting the WhatsApp Channel, aiming to enrich your engagement
          toolkit.
        </p>
        <p>Stay tuned for development updates!</p>
      </MarketingContent>{" "}
    </Page>
  )
}

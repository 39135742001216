import {
  InfiniteData,
  QueryKey,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import {
  Journey,
  JourneyActivatePayload,
  JourneyCreatePayload,
  JourneyModifyPayload,
  JourneyNode,
  JourneyNodeCreatePayload,
  JourneyNodeModifyPayload,
  JourneysListDeletedResponse,
  JourneysListResponse,
  JourneySort,
} from "./journeyTypes"
import { api } from "api"
import { showToast } from "app/toast"
import {
  allPass,
  always,
  append,
  assocPath,
  path,
  prop,
  reject,
  sort,
  update,
  whereEq,
} from "ramda"
import { TrashItem } from "types/trash"
import { getRoutePath } from "routes"
import { useHasAccess } from "resources/user/currentUserQueries"
import assert from "assert"
import { OrderDir } from "types/util"
import { ascend, descend } from "utilities/comparators"

const JOURNEY_ALL_QK: QueryKey = ["journey", "all"]
const JOURNEY_TRASH_QK: QueryKey = ["journey", "trash"]

function useJourneysQuery<T>(
  config?: UseQueryOptions<{ journeys: Journey[] }, unknown, T, QueryKey>,
) {
  const hasAccess = useHasAccess()

  return useQuery(JOURNEY_ALL_QK, api.journey.listAll, {
    staleTime: 10 * 1000,
    enabled: hasAccess.journeys.view,
    ...config,
  })
}

type FetchAllJourneysOpts = {
  orderBy: JourneySort
  orderDir: OrderDir
  searchTerm: string
}

export function useFetchAllJourneys({ searchTerm, orderBy, orderDir }: FetchAllJourneysOpts) {
  return useJourneysQuery({
    select: data => {
      let selectedJourneys = data.journeys

      if (searchTerm) {
        selectedJourneys = selectedJourneys.filter(
          ({ id, name }) =>
            name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            id.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      }

      if (orderBy && orderDir) {
        const comparator = orderDir === "ASC" ? ascend : descend
        let getSortingProperty = prop(orderBy)
        if (orderBy === "entered") {
          // @ts-ignore
          getSortingProperty = path(["lifetime_statistics", "customer_entities_entered"])
        }
        if (orderBy === "dropped") {
          // @ts-ignore
          getSortingProperty = path(["lifetime_statistics", "customer_entities_dropped"])
        }
        if (orderBy === "finished") {
          // @ts-ignore
          getSortingProperty = path(["lifetime_statistics", "customer_entities_finished"])
        }
        selectedJourneys = sort(comparator(getSortingProperty), selectedJourneys)
      }

      return selectedJourneys
    },
  })
}

export function useFetchJourneyById(id: Journey["id"]) {
  return useJourneysQuery({
    select: ({ journeys }) => journeys.find(journey => journey.id === id) ?? null,
  })
}

export function useCreateJourney() {
  const queryClient = useQueryClient()

  return useMutation(({ data }: { data: JourneyCreatePayload }) => api.journey.create(data), {
    onSuccess({ journey }) {
      queryClient.setQueryData<{ journeys: Journey[] }>(JOURNEY_ALL_QK, data => {
        if (!data) return

        return {
          journeys: [...data.journeys, journey],
        }
      })
      showToast("Journey created.")
    },
  })
}

export function useModifyJourney() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: Journey["id"]; data: JourneyModifyPayload }) =>
      api.journey.modify(id, data),
    {
      onSuccess({ journey }) {
        queryClient.setQueryData<{ journeys: Journey[] }>(JOURNEY_ALL_QK, data => {
          if (!data) return

          const index = data.journeys.findIndex(whereEq({ id: journey.id }))

          return {
            journeys:
              index === -1 ? data.journeys.concat(journey) : update(index, journey, data.journeys),
          }
        })
        showToast("Journey modified.")
      },
    },
  )
}

export function useDeleteJourney() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Journey["id"] }) => api.journey.delete(id), {
    onSuccess(_, { id }) {
      queryClient.setQueryData<{ journeys: Journey[] }>(JOURNEY_ALL_QK, data => {
        if (!data) return

        return {
          journeys: reject(whereEq({ id }), data.journeys),
        }
      })
      showToast("Journey deleted.")
    },
  })
}

export const useFetchJourneyTrashItems = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<
    { trashed_journeys: Journey[] },
    string,
    Omit<{ trashed_journeys: Journey[] }, "trashed_journeys"> & {
      trashed_journeys: Array<TrashItem>
    },
    QueryKey
  >(JOURNEY_TRASH_QK, () => api.journey.listDeleted(), {
    getNextPageParam: always(undefined),
    select: data => {
      return {
        ...data,
        pages: data.pages.map(p => ({
          ...p,
          trashed_journeys: p.trashed_journeys.map(({ id, name, modified, modified_by }) => {
            const trashItem: TrashItem = {
              id,
              name,
              deleted_at: modified,
              deleted_by: modified_by,
              type: "journeys",
            }

            return trashItem
          }),
        })),
      }
    },
  })

  return {
    ...rest,
    data: data
      ? data.pages
          .flatMap(m => m.trashed_journeys)
          .filter(({ name }) =>
            searchTerm ? name.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) : true,
          )
      : [],
  }
}

export function useRestoreJourney() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Journey["id"] }) => api.journey.restoreDeleted(id), {
    onSuccess: ({ journey }) => {
      queryClient.setQueryData<JourneysListResponse>(JOURNEY_ALL_QK, data => {
        if (!data) return

        return {
          journeys: [...data.journeys, journey],
        }
      })

      queryClient.setQueryData<InfiniteData<JourneysListDeletedResponse>>(
        JOURNEY_TRASH_QK,
        data => {
          if (!data) return

          return {
            ...data,
            pages: data.pages.map(({ trashed_journeys }) => ({
              trashed_journeys: reject(whereEq({ id: journey.id }), trashed_journeys),
            })),
          }
        },
      )

      showToast("Journey restored.", undefined, getRoutePath("journeys.detail", { id: journey.id }))
    },
  })
}

export function useCreateJourneyNode() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ journeyId, data }: { journeyId: Journey["id"]; data: JourneyNodeCreatePayload }) =>
      api.journey.node.create(journeyId, data),
    {
      onSuccess({ journey_node }, { journeyId }) {
        queryClient.setQueryData<{ journeys: Journey[] }>(JOURNEY_ALL_QK, data => {
          if (!data) return

          const journeyIndex = data.journeys.findIndex(whereEq({ id: journeyId }))
          assert(journeyIndex !== -1, "Journey not found")

          const { previous_journey_node_path, previous_journey_node_id } = journey_node

          let newNodes = data.journeys[journeyIndex].nodes

          const nextNodeIndex = newNodes.findIndex(
            allPass([
              whereEq({ previous_journey_node_path, previous_journey_node_id }),
              // Exclude entry node
              ({ previous_journey_node_id, id }) => previous_journey_node_id !== id,
            ]),
          )
          if (nextNodeIndex !== -1) {
            const nextNode = newNodes[nextNodeIndex]
            const updatedNextNode = {
              ...nextNode,
              previous_journey_node_id: journey_node.id,
              previous_journey_node_path: 0,
            }
            newNodes = update(nextNodeIndex, updatedNextNode, newNodes)
          }

          newNodes = append(journey_node, newNodes)

          return {
            journeys: assocPath([journeyIndex, "nodes"], newNodes, data.journeys),
          }
        })

        showToast("Node created.")
      },
    },
  )
}

export function useModifyJourneyNode() {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      journeyId,
      nodeId,
      data,
    }: {
      journeyId: Journey["id"]
      nodeId: JourneyNode["id"]
      data: JourneyNodeModifyPayload
    }) => api.journey.node.modify(journeyId, nodeId, data),
    {
      onSuccess({ journey_node }, { journeyId }) {
        queryClient.setQueryData<{ journeys: Journey[] }>(JOURNEY_ALL_QK, data => {
          if (!data) return

          const journeyIndex = data.journeys.findIndex(whereEq({ id: journeyId }))
          assert(journeyIndex !== -1, "Journey not found")

          const nodeIndex = data.journeys[journeyIndex].nodes.findIndex(
            whereEq({ id: journey_node.id }),
          )
          assert(nodeIndex !== -1, "Node not found")

          return {
            journeys: assocPath([journeyIndex, "nodes", nodeIndex], journey_node, data.journeys),
          }
        })

        showToast("Node modified.")
      },
    },
  )
}

export function useDeleteJourneyNode() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ journeyId, nodeId }: { journeyId: Journey["id"]; nodeId: JourneyNode["id"] }) =>
      api.journey.node.delete(journeyId, nodeId),
    {
      onSuccess(_, { journeyId, nodeId }) {
        queryClient.setQueryData<{ journeys: Journey[] }>(JOURNEY_ALL_QK, data => {
          if (!data) return

          const journeyIndex = data.journeys.findIndex(whereEq({ id: journeyId }))
          assert(journeyIndex !== -1, "Journey not found")
          const journey = data.journeys[journeyIndex]

          const node = journey.nodes.find(whereEq({ id: nodeId }))
          assert(node, "Node not found")
          const { previous_journey_node_path, previous_journey_node_id } = node

          let newNodes = reject(whereEq({ id: nodeId }), journey.nodes)

          const nextNodeIndex = newNodes.findIndex(whereEq({ previous_journey_node_id: nodeId }))
          if (nextNodeIndex !== -1) {
            const nextNode = newNodes[nextNodeIndex]
            const updatedNextNode = {
              ...nextNode,
              previous_journey_node_id,
              previous_journey_node_path,
            }
            newNodes = update(nextNodeIndex, updatedNextNode, newNodes)
          }

          return {
            journeys: assocPath([journeyIndex, "nodes"], newNodes, data.journeys),
          }
        })

        showToast("Node deleted.")
      },
    },
  )
}

export function useActivateJourney() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: Journey["id"]; data: JourneyActivatePayload }) =>
      api.journey.activate(id, data),
    {
      onSuccess({ journey }) {
        queryClient.setQueryData<{ journeys: Journey[] }>(JOURNEY_ALL_QK, data => {
          if (!data) return

          const index = data.journeys.findIndex(whereEq({ id: journey.id }))

          return {
            journeys:
              index === -1 ? data.journeys.concat(journey) : update(index, journey, data.journeys),
          }
        })

        queryClient.invalidateQueries(["journey", "nodeStatistics", journey.id])
        queryClient.invalidateQueries(["journey", "statistics", journey.id])

        showToast("Journey activated.")
      },
    },
  )
}

export function useDeactivateJourney() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Journey["id"] }) => api.journey.deactivate(id), {
    onSuccess({ journey }) {
      queryClient.setQueryData<{ journeys: Journey[] }>(JOURNEY_ALL_QK, data => {
        if (!data) return

        const index = data.journeys.findIndex(whereEq({ id: journey.id }))

        return {
          journeys:
            index === -1 ? data.journeys.concat(journey) : update(index, journey, data.journeys),
        }
      })

      showToast("Journey set to passive.")
    },
  })
}

export function useStopJourney() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Journey["id"] }) => api.journey.stop(id), {
    onSuccess({ journey }) {
      queryClient.setQueryData<{ journeys: Journey[] }>(JOURNEY_ALL_QK, data => {
        if (!data) return

        const index = data.journeys.findIndex(whereEq({ id: journey.id }))

        return {
          journeys:
            index === -1 ? data.journeys.concat(journey) : update(index, journey, data.journeys),
        }
      })

      showToast("Journey set to draft.")
    },
  })
}

export function useFetchJourneyNodeStatistics(id: Journey["id"]) {
  return useQuery(["journey", "nodeStatistics", id], () => api.journey.nodeStatistics(id), {
    select({ journey_nodes_statistics }) {
      return Object.fromEntries(
        journey_nodes_statistics.map(stats => [
          stats.journey_node_id,
          { arrived: stats.customer_entities_continued + stats.customer_entities_dropped },
        ]),
      )
    },
  })
}

export function useFetchJourneyStatistics(id: Journey["id"]) {
  return useQuery(["journey", "statistics", id], () => api.journey.statistics(id), {
    select({ journey_lifetime_statistics }) {
      return journey_lifetime_statistics
    },
  })
}

import { CSSProperties, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { dec, inc } from "ramda"

import Button from "components/UI/elements/Button/Button"
import StructuredEventPayload from "components/UI/elements/StructuredEventPayload/StructuredEventPayload"
import { getCustomerEventTitle } from "helpers/event.helper"
import { EventFull } from "resources/event/eventTypes"

import styles from "./EventExamples.module.scss"

type EventExamplesProps = {
  event: EventFull
}

export default function EventExamples({ event }: EventExamplesProps) {
  const [page, setPage] = useState(0)
  const [showRawPayload, setShowRawPayload] = useState(false)

  const examples: Array<{ type: EventFull["type"]; payload: Record<string, any> }> = []
  if (Array.isArray(event.examples)) {
    event.examples.forEach(example => {
      examples.push({
        type: event.type,
        payload: example,
      })
    })
  }

  const getExampleStyle = (idx: number) => {
    const left = (idx - page) * 100

    return {
      left: `${left}%`,
      position:
        left === 0
          ? ("relative" as CSSProperties["position"])
          : ("absolute" as CSSProperties["position"]),
    }
  }

  return (
    <div className={styles.carousel}>
      <div data-testid="example-content" className={styles.carouselContent}>
        {examples.map((example, index) => {
          const title = getCustomerEventTitle(example, event.schema, event.name)

          return (
            <div key={index} className={styles.eventExample} style={getExampleStyle(index)}>
              <div className={styles.titleWrapper}>
                <h4>{title}</h4>
                <Button
                  onClick={() => setShowRawPayload(prev => !prev)}
                  variant="outlined"
                  color="grey"
                  size="xs"
                  className={styles.showRawPayload}
                >
                  {showRawPayload ? "Show structured data" : "Show raw payload"}
                </Button>
              </div>
              <div className={styles.payload}>
                {showRawPayload ? (
                  <div data-testid="raw-payload" className={styles.rawPayload}>
                    <pre>{JSON.stringify(example.payload, null, 2)}</pre>
                  </div>
                ) : (
                  <StructuredEventPayload customerEvent={example} schema={event.schema} />
                )}
              </div>
            </div>
          )
        })}
      </div>
      {page > 0 && (
        <button
          className={classNames(styles.carouselButton, styles.carouselLeft)}
          onClick={() => setPage(dec)}
        >
          <FontAwesomeIcon icon={["far", "chevron-left"]} />
        </button>
      )}
      {page < examples.length - 1 && (
        <button
          className={classNames(styles.carouselButton, styles.carouselRight)}
          onClick={() => setPage(inc)}
        >
          <FontAwesomeIcon icon={["far", "chevron-right"]} />
        </button>
      )}
    </div>
  )
}

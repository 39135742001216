import React, { useContext, useEffect, useState } from "react"
import { equals } from "ramda"

import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import Scheduler from "pages/Segments/components/SegmentDetail/components/SegmentExports/Scheduler/Scheduler"
import SegmentationNumbers from "components/UI/components/SegmentationNumbers"
import SegmentPicker from "components/SegmentPicker/SegmentPicker"
import { SegmentOption } from "components/SegmentPicker/types"
import { SocketContext } from "context/socket"
import {
  ChannelSegmentCount,
  ChannelCountError,
  ChannelTotalCount,
} from "resources/channel/channelTypes"
import { useFetchSegmentsByIds } from "resources/segment/segment/segmentQueries"
import { Segment, SegmentScheduleCron } from "resources/segment/segment/segmentTypes"

import styles from "./ActivationBatch.module.scss"

type ActivationBatchProps = {
  activatedSegmentIds: Array<Segment["id"]>
  channelType: ChannelSegmentCount["channel_type"]
  repetitiveSchedules: Array<SegmentScheduleCron>
  segmentIds: Array<Segment["id"]>
  removeEnabled?: boolean
  staticSchedule?: string
  tooltip?: string | JSX.Element
  pushNotificationId?: ChannelSegmentCount["push_notification_id"]
  activate?: (segmentIds: Array<Segment["id"]>) => Promise<any> | undefined
  remove?: (segments: Array<Segment>) => void
  schedule?: (schedule: SegmentScheduleCron[] | string) => Promise<any> | undefined
  send?: (segments: Array<Segment>) => void
}

export const MISSING_SEGMENT_TOOLTIP_MESSAGE = "Select segment first."

export default function ActivationBatch({
  activatedSegmentIds,
  channelType,
  repetitiveSchedules,
  staticSchedule,
  segmentIds,
  tooltip,
  pushNotificationId,
  activate,
  remove,
  schedule,
  send,
  removeEnabled = true,
}: ActivationBatchProps) {
  const [initialLoading, setInitialLoading] = useState(false)
  const [totalCount, setTotalCount] = useState<ChannelTotalCount["count"]>()
  const [segmentedCount, setSegmentedCount] = useState<ChannelSegmentCount["count"]>()
  const [plusUpTo, setPlusUpTo] = useState<ChannelSegmentCount["plus_up_to"]>()
  const [error, setError] = useState<ChannelCountError>()

  const socket = useContext(SocketContext)

  const segmentsResults = useFetchSegmentsByIds(segmentIds)

  useEffect(() => {
    setInitialLoading(true)
    if (segmentIds.length > 0)
      socket.emit(
        "channel_segment_counts",
        {
          channel_type: channelType,
          segment_ids: segmentIds,
          push_notification_id: pushNotificationId,
        },
        () => setInitialLoading(false),
      )
  }, [channelType, pushNotificationId, segmentIds, socket])

  useEffect(() => {
    socket.on("channel_segment_counts_response", (msg: ChannelSegmentCount | ChannelTotalCount) => {
      if (msg.count_type === "channel_results_count") {
        if (
          msg.channel_type !== channelType ||
          !equals(Array.from(msg.segment_ids).sort(), Array.from(segmentIds).sort())
        )
          return

        if (msg.error) setError(msg.error)
        else {
          setSegmentedCount(msg.count)
          setPlusUpTo(msg.plus_up_to)
        }
      } else {
        if (msg.channel_type !== channelType) return

        if (msg.error) setError(msg.error)
        else setTotalCount(msg.count)
      }
    })

    return () => {
      socket.off("channel_segment_counts_response")
    }
  }, [channelType, segmentIds, socket])

  const segments = segmentsResults
    .filter(({ data }) => data !== undefined)
    .map(({ data }) => data!.segment)

  const empty = segmentIds.length === 0

  return (
    <div data-testid="sending-batch" className={styles.batch}>
      <div className={styles.firstRow}>
        <SegmentPicker
          disableActivated
          label="Select segment *"
          multiple
          activatedOptions={activatedSegmentIds}
          value={segmentIds}
          onChange={newValue => activate?.(newValue as Array<SegmentOption["value"]>)}
          className={styles.segmentPicker}
        />
        <div className={styles.segmented}>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>Segmented</span>
            {tooltip && (
              <InfoTooltip placement="top" interactive>
                {tooltip}
              </InfoTooltip>
            )}
          </div>
          <div className={styles.numbersWrapper}>
            <SegmentationNumbers
              isLoading={segmentIds.length > 0 && initialLoading}
              showLabel={false}
              errorMessage={error}
              segmentedNumber={segmentedCount}
              totalNumber={totalCount}
              plusUpTo={plusUpTo}
              showBar
              className={styles.segmentationNumbers}
            />
            <IconButton
              disabled={!removeEnabled}
              color="red"
              icon="trash-alt"
              size="xs"
              tooltip={removeEnabled ? "Remove sending batch" : MISSING_SEGMENT_TOOLTIP_MESSAGE}
              variant="outlined"
              onClick={() => remove?.(segments)}
            />
          </div>
        </div>
      </div>
      <div className={styles.secondRow}>
        <span className={styles.text}>Set schedule</span>

        <Scheduler
          permission="edit"
          id={segmentIds.length > 0 ? segmentIds[0] : undefined}
          repetitiveSchedules={repetitiveSchedules}
          staticSchedule={staticSchedule}
          tooltip={MISSING_SEGMENT_TOOLTIP_MESSAGE}
          tooltipDisabled={!empty}
          onSaveRepetitiveSchedules={newRepetitiveSchedules => schedule?.(newRepetitiveSchedules)}
          onSaveStaticSchedule={newStaticSchedule => schedule?.(newStaticSchedule ?? [])}
        />
        <span className={styles.text}>or</span>
        <Button
          data-testid="send"
          disabled={empty}
          icon={["far", "paper-plane"]}
          tooltip={empty ? MISSING_SEGMENT_TOOLTIP_MESSAGE : undefined}
          onClick={() => send?.(segments)}
        >
          Send now
        </Button>
      </div>
    </div>
  )
}

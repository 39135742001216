import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Prompt } from "react-router-dom"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import TextInput from "components/UI/elements/TextInput/TextInput"
import SelectField from "components/UI/elements/SelectField"
import ConfirmModal from "components/UI/components/ConfirmModal"
import Duration from "components/UI/elements/Duration"
import { MODAL } from "sharedConstants"
import { email as emailValidator, isEmptyField, required } from "helpers/validators.helper"
import "./Settings.scss"
import Page from "components/UI/Page/Page"
import { useFetchLabelOptions } from "resources/attributeLabel/attributeLabelQueries"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { equals, trim } from "ramda"
import { useFetchAllAttributes } from "resources/attribute/attributeQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { DBtimestampToDate } from "utilities/date"
import AttributePicker from "components/AttributePicker/AttributePicker"
import { useDeleteCache, useFetchCacheStatus } from "resources/stats/cacheStatus"
import { GlobalSettingsFormValues } from "resources/globalSettings/globalSettingsTypes"

type SettingsFormProps = {
  initialValues: GlobalSettingsFormValues
  onSubmit: (values: GlobalSettingsFormValues) => void
  handleCacheRenew: () => void
}

export default function SettingsForm({
  initialValues,
  onSubmit,
  handleCacheRenew,
}: SettingsFormProps) {
  const { data: labelOptions = [], isLoading: areAttributeLabelsLoading } = useFetchLabelOptions()

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isDirty, isSubmitting, isSubmitSuccessful },
  } = useForm<GlobalSettingsFormValues>({
    defaultValues: initialValues,
    reValidateMode: "onSubmit",
  })
  const {
    fields: maintenanceEmails,
    append: appendMaintenanceEmail,
    remove: removeMaintenanceEmail,
  } = useFieldArray({ control, name: "maintenance_notifications_emails.value" })

  const { isLoading: areAttributesLoading } = useFetchAllAttributes()

  const { data: cacheStatus, isLoading: isLoadingCacheStatus } = useFetchCacheStatus({
    refetchInterval: data => (data?.is_refresh_running || isInitiatingRefresh ? 5000 : false),
  })
  const deleteCacheMutation = useDeleteCache()

  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false)

  // The BE only switches to is_refresh_running: true after a few seconds, so in the meantime this
  // is to display a spinner and run the refetch interval
  const [isInitiatingRefresh, setIsInitiatingRefresh] = useState(false)

  function deleteCache() {
    deleteCacheMutation.mutate(undefined, {
      onSuccess() {
        setIsRefreshModalOpen(false)
        handleCacheRenew()
        setIsInitiatingRefresh(true)
      },
    })
  }

  useEffect(() => {
    if (cacheStatus?.is_refresh_running) {
      setIsInitiatingRefresh(false)
    }
  }, [cacheStatus?.is_refresh_running])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page
        title="Global settings"
        headerContent={
          <div>
            <Button
              color="grey"
              variant="outlined"
              onClick={_ => reset()}
              className="cancel-button"
            >
              Cancel
            </Button>
            <Button icon="save" loading={isSubmitting} type="submit">
              Save
            </Button>
          </div>
        }
        className="global-settings"
      >
        <Paper className="global-settings-content">
          <table className="global-settings-table">
            <tbody>
              <tr>
                <td className="row-title">
                  <h3>MI API connection</h3>
                  <p className="title-description">
                    Used when configuring export destinations. If your Meiro Integrations instance
                    is accessible on <span>https://meiro.acme.com</span>, the correct URL would be
                    <span> https://meiro.acme.com/api</span>. It is best to use credentials for an
                    account created just for this purpose.
                  </p>
                </td>
                <td className="values">
                  <div className="form-row">
                    <TextInput
                      {...register("mi_api.value.url", {
                        validate: (url, { mi_api }) =>
                          (mi_api.value.email || mi_api.value["#password"]) && isEmptyField(url)
                            ? "Please fill in the field"
                            : undefined,
                      })}
                      label="URL"
                      placeholder="URL"
                      className="settings-text-field floated meiro-api-url"
                      error={errors.mi_api?.value?.url?.message}
                    />
                  </div>
                  <div className="form-row">
                    <TextInput
                      {...register("mi_api.value.email", {
                        validate: (email, { mi_api }) =>
                          (mi_api.value.url || mi_api.value["#password"]) && isEmptyField(email)
                            ? "Please fill in the field"
                            : undefined,
                      })}
                      label="Email"
                      placeholder="Email"
                      className="settings-text-field floated"
                      error={errors.mi_api?.value?.email?.message}
                    />
                    <TextInput
                      {...register("mi_api.value.#password", {
                        validate: (password, { mi_api }) =>
                          (mi_api.value.email || mi_api.value.url) && isEmptyField(password)
                            ? "Please fill in the field"
                            : undefined,
                      })}
                      label="Password"
                      placeholder="Password"
                      className="settings-text-field floated margin-left"
                      type="password"
                      error={errors.mi_api?.value?.["#password"]?.message}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="row-title">
                  <h3>ME API connection</h3>
                  <p className="title-description">
                    Used when configuring web banners. If your Meiro Events instance is accessible
                    on <span>https://meiro.acme.com</span>, the correct URL would be
                    <span> https://meiro.acme.com/api</span>. It is best to use credentials for an
                    account created just for this purpose.
                  </p>
                </td>
                <td className="values">
                  <div className="form-row">
                    <TextInput
                      {...register("me_api.value.url", {
                        validate: (url, { me_api }) =>
                          (me_api.value.email || me_api.value["#password"]) && isEmptyField(url)
                            ? "Please fill in the field"
                            : undefined,
                      })}
                      label="URL"
                      placeholder="URL"
                      className="settings-text-field floated meiro-api-url"
                      error={errors.me_api?.value?.url?.message}
                    />
                  </div>
                  <div className="form-row">
                    <TextInput
                      {...register("me_api.value.email", {
                        validate: (email, { me_api }) =>
                          (me_api.value.url || me_api.value["#password"]) && isEmptyField(email)
                            ? "Please fill in the field"
                            : undefined,
                      })}
                      label="Email"
                      placeholder="Email"
                      className="settings-text-field floated"
                      error={errors.me_api?.value?.email?.message}
                    />
                    <TextInput
                      {...register("me_api.value.#password", {
                        validate: (password, { me_api }) =>
                          (me_api.value.email || me_api.value.url) && isEmptyField(password)
                            ? "Please fill in the field"
                            : undefined,
                      })}
                      label="Password"
                      placeholder="Password"
                      className="settings-text-field floated margin-left"
                      type="password"
                      error={errors.me_api?.value?.["#password"]?.message}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="row-title">
                  <h3>Profiles tab additional attribute</h3>
                  <p className="title-description">
                    To show additional attribute for profiles search results.
                  </p>
                </td>
                <td className="values">
                  {areAttributesLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <div className="form-row additional-attribute">
                      <div className="attribute-picker picker-field floated single">
                        <label>Attribute</label>
                        <Controller
                          control={control}
                          name="additional_search_result_attribute_id.value"
                          render={({ field }) => (
                            <AttributePicker
                              value={field.value}
                              onChange={field.onChange}
                              isClearable
                              size="lg"
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td className="row-title">
                  <h3>Profile Highlights label</h3>
                  <p className="title-description">
                    To identify the attributes for profile highlights.
                  </p>
                </td>
                <td className="values">
                  {areAttributeLabelsLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <div className="form-row contact-identifiers">
                      <Controller
                        control={control}
                        name="contact_info_tag_id.value"
                        render={({ field }) => (
                          <SelectField
                            input={{ value: field.value, onChange: field.onChange }}
                            options={labelOptions}
                            label="Label"
                            className="floated single"
                            isSearchable
                            isClearable
                            size="medium"
                            inputId="contact-info-tag-value"
                            placeholder="Select attribute label"
                            isSimpleValue
                          />
                        )}
                      />
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td className="row-title">
                  <h3>Channel Engagement label</h3>
                  <p className="title-description">
                    To identify the attributes for channel engagement.
                  </p>
                </td>
                <td className="values">
                  {areAttributeLabelsLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <div className="form-row channel-engagement-value">
                      <Controller
                        control={control}
                        name="channel_engagement_tag_id.value"
                        render={({ field }) => (
                          <SelectField
                            input={{ value: field.value, onChange: field.onChange }}
                            options={labelOptions}
                            label="Label"
                            className="floated single"
                            isSearchable
                            isClearable
                            size="medium"
                            inputId="channel-engagement-tag-value"
                            placeholder="Select attribute label"
                            isSimpleValue
                          />
                        )}
                      />
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td className="row-title">
                  <h3>Data cache expiration</h3>
                  <p className="title-description">
                    This schedule follows{" "}
                    <a href="https://crontab.guru/" target="_blank" rel="noopener noreferrer">
                      cron syntax
                    </a>{" "}
                    and sets the time (UTC) when the cache should be invalidated and generated. Set
                    it right after the process of ingesting new data is done.
                  </p>
                </td>
                <td className="values">
                  <div className="form-row cache-settings">
                    <label className="cache-settings-label">Settings</label>
                    <TextInput
                      {...register("cdp_cache_expiration_period.value.minute", {
                        validate: required,
                      })}
                      label="Minute"
                      className="expiration-schedule-field"
                      error={errors.cdp_cache_expiration_period?.value?.minute?.message}
                    />
                    <TextInput
                      {...register("cdp_cache_expiration_period.value.hour", {
                        validate: required,
                      })}
                      label="Hour"
                      className="expiration-schedule-field"
                      error={errors.cdp_cache_expiration_period?.value?.hour?.message}
                    />
                    <TextInput
                      {...register("cdp_cache_expiration_period.value.day", {
                        validate: required,
                      })}
                      label="Day"
                      className="expiration-schedule-field"
                      error={errors.cdp_cache_expiration_period?.value?.day?.message}
                    />
                    <TextInput
                      {...register("cdp_cache_expiration_period.value.month", {
                        validate: required,
                      })}
                      label="Month"
                      className="expiration-schedule-field"
                      error={errors.cdp_cache_expiration_period?.value?.month?.message}
                    />
                    <TextInput
                      {...register("cdp_cache_expiration_period.value.day_of_week", {
                        validate: required,
                      })}
                      label="Day&nbsp;of&nbsp;week"
                      className="expiration-schedule-field"
                      error={errors.cdp_cache_expiration_period?.value?.day_of_week?.message}
                    />
                    <Button
                      size="md"
                      loading={
                        isLoadingCacheStatus ||
                        isInitiatingRefresh ||
                        cacheStatus?.is_refresh_running
                      }
                      className="delete-cache"
                      onClick={() => {
                        if (!cacheStatus || isInitiatingRefresh || cacheStatus.is_refresh_running) {
                          return
                        }
                        setIsRefreshModalOpen(true)
                      }}
                    >
                      {cacheStatus &&
                        (cacheStatus.is_refresh_running ? (
                          <span className="small-text">
                            Running cache refresh (
                            <Duration
                              datetime={DBtimestampToDate(cacheStatus.refresh_init_time!)}
                            />
                            )
                          </span>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              className="trash-icon icon"
                              icon={["far", "history"]}
                            />{" "}
                            refresh cache
                          </>
                        ))}
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="row-title">
                  <h3>Maintenance notifications email(s)</h3>
                  <p className="title-description">
                    All important emails will be sent here, so make sure you read them!
                  </p>
                </td>
                <td className="values">
                  <div className="form-row maintenance-emails">
                    <div className="email-fields">
                      {maintenanceEmails.map(({ id }, index, fields) => (
                        <div className="notification-email-wrapper" key={id}>
                          <TextInput
                            {...register(`maintenance_notifications_emails.value.${index}.email`, {
                              setValueAs: trim,
                              validate: {
                                required,
                                email: emailValidator,
                                duplicate: (value, { maintenance_notifications_emails }) =>
                                  maintenance_notifications_emails.value.filter(
                                    ({ email: maintenanceEmail }) =>
                                      equals(value, maintenanceEmail),
                                  ).length > 1
                                    ? "Duplicate email"
                                    : undefined,
                              },
                            })}
                            label="Email"
                            placeholder="Email to notify"
                            className="settings-text-field"
                            error={errors.maintenance_notifications_emails?.value?.[index]?.message}
                          />
                          {fields.length > 1 && (
                            <IconButton
                              className="email-trash"
                              color="red"
                              onClick={() => removeMaintenanceEmail(index)}
                              icon="trash-alt"
                              tooltip="Delete"
                              variant="transparent"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                    <Button
                      size="md"
                      onClick={() => appendMaintenanceEmail({ email: "" })}
                      className="add-email-button"
                    >
                      Add email
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Paper>
        <ConfirmModal
          open={isRefreshModalOpen}
          type={MODAL.TYPE.DELETE}
          customButtonText="refresh"
          handleClose={() => setIsRefreshModalOpen(false)}
          handleConfirm={deleteCache}
          title="Refresh"
          text="Do you really want to refresh cache?"
          isLoading={deleteCacheMutation.isLoading}
        />
        <Prompt
          when={isDirty && !isSubmitSuccessful}
          message="Changes you made will not be saved."
        />
      </Page>
    </form>
  )
}

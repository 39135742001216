import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query"

import requestFactory from "api/request"
import { DBFormatDateTime } from "types/util"

type CustomerEventsCountPayload = {
  start_date: DBFormatDateTime
  end_date: DBFormatDateTime
}

type CustomerEventsCountResponse = {
  customer_events_count: number
  selection_settings: {
    start_date: DBFormatDateTime | null
    end_date: DBFormatDateTime | null
  }
}

const customerEventsCount = (
  data?: CustomerEventsCountPayload,
): Promise<CustomerEventsCountResponse> =>
  requestFactory("get", "/customer_events_count", {
    start_date: data?.start_date ?? null,
    end_date: data?.end_date ?? null,
  })

export const useFetchCustomerEventsCount = (
  config?: UseQueryOptions<
    CustomerEventsCountResponse,
    unknown,
    CustomerEventsCountResponse,
    QueryKey
  >,
  data?: CustomerEventsCountPayload,
) =>
  useQuery(["customerEventsCount"] as QueryKey, () => customerEventsCount(data), {
    ...config,
    staleTime: 60 * 1000,
  })

import { LinkExpired } from "./components/LinkExpired"

export const InviteExpired = () => {
  return (
    <LinkExpired
      title="Your invitation has expired"
      text="Ask your administrator for a new invitation. Invitation links last 5 days for security reasons."
      toastText="Invitation link has expired"
    />
  )
}

import { ActivatePushNodeSettings } from "resources/journey/journeyTypes"
import { useFetchMobilePushNotificationById } from "resources/mobilePushNotification/mobilePushNotificationQueries"

export function useGetDescription(settings?: ActivatePushNodeSettings | null) {
  const { data: mobilePush } = useFetchMobilePushNotificationById(settings?.campaign_id)

  if (!settings || !mobilePush) {
    return ""
  }

  return mobilePush.name
}

import React, { ReactNode } from "react"
import styles from "./NodeCard.module.scss"

export const NODE_CARD_WIDTH = 200

type NodeCardProps = {
  title: string
  icon: ReactNode
  description: string
  actions?: ReactNode
  arrivedCount?: number
}

const formatter = new Intl.NumberFormat("en-US")

export default function NodeCard({
  title,
  icon,
  description,
  actions,
  arrivedCount,
}: NodeCardProps) {
  return (
    <div className={styles.card} style={{ width: `${NODE_CARD_WIDTH}px` }}>
      <div className={styles.main}>
        <div className={styles.iconWrapper}>{icon}</div>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
        {actions && <div className={styles.actions}>{actions}</div>}
      </div>
      {arrivedCount !== undefined && (
        <div className={styles.counts}>
          <div className={styles.countsItem}>
            <div className={styles.label}>Arrived</div>
            <div className={styles.value}>{formatter.format(arrivedCount)}</div>
          </div>
        </div>
      )}
    </div>
  )
}

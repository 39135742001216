import PaperHeader from "components/UI/elements/PaperHeader"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { isEmptyField, url, required, imageFile, maxLength } from "helpers/validators.helper"
import { SubmitHandler, useForm, Controller, FieldError } from "react-hook-form"
import { PWBPosition, PopupWebBanner } from "resources/webBanner/popupWebBanner/popupWBTypes"
import styles from "./PopupWebBannersForm.module.scss"
import Button from "components/UI/elements/Button/Button"
import Paper from "components/UI/elements/Paper"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import React, { ChangeEvent, lazy, Suspense, useCallback, useEffect, useRef, useState } from "react"
import BannerPreview from "./BannerPreview/BannerPreview"
import SelectField from "components/UI/elements/SelectField"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Prompt, useHistory, useParams } from "react-router-dom"
import { getRoutePath } from "routes"
import classnames from "classnames"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import ConditionTreeOverview from "components/ConditionBuilder/components/ConditionTreeOverview/ConditionTreeOverview"
import { WBCondition, WBConditionError } from "resources/webBanner/webBannerConditionTypes"
import ConditionBuilder from "components/ConditionBuilder/ConditionBuilder"
import Condition, {
  getNewWebBannerCondition,
} from "../../../../../components/UI/components/WebBannerCondition/WebBannerCondition"
import { makeConditionTreeValidator } from "components/ConditionBuilder/validation"
import { validateWebBannerCondition } from "../../../../../components/UI/components/WebBannerCondition/validation"
import classNames from "classnames"
import FileField from "components/UI/elements/FileField/FileField"
import { showToast } from "app/toast"
import { TOAST } from "sharedConstants"
import Tag from "components/UI/elements/Tag"
import { shortenTextInMiddle } from "helpers/string.helper"
import { isEmpty, without } from "ramda"
import CloseButtonFields from "./CloseButtonFields/CloseButtonFields"
import Tippy from "@tippyjs/react"
import { useCopyPopupWB } from "resources/webBanner/popupWebBanner/popupWBQueries"
import TemplateGallery from "components/UI/components/TemplateGallery/TemplateGallery"
import templates from "./templates/templates"
import {
  Template,
  HighlightState,
} from "components/UI/components/TemplateGallery/TemplateGalleryItem/TemplateGalleryItem"
import { SelectOption } from "types/util"
import {
  PopupWebBannersFormValues,
  PopupWebBannersFormData,
  WebBannerType,
} from "pages/Channels/PopupWebBanners/popupWBComponentTypes"
import {
  PRIORITY_OPTIONS,
  DISPLAY_CONDITION_OPTIONS,
  ANIMATION_OPTIONS,
  FREQUENCY_CAP_TYPE_OPTIONS,
  POSITION_OPTIONS,
  HIDE_OPERATOR_OPTIONS,
} from "pages/Channels/PopupWebBanners/utilities/dropdownOptions"
import { useHasAccess } from "resources/user/currentUserQueries"
import { ConditionPath } from "types/conditionTree"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import WebBannerDisableDefaultTrackingModal from "components/UI/components/WebBannerDisableDefaultTrackingModal/WebBannerDisableDefaultTrackingModal"
import IgnoreFrequencyCapModal from "components/UI/components/IgnoreFrequencyCapModal/IgnoreFrequencyCapModal"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { popupWBToFormValues } from "../../utilities/popupWBToFormValues"
import Switch from "components/UI/elements/Switch/Switch"
const AceEditor = lazy(() => import("components/AceEditor/AceEditor"))

type PopupWebBannersFormProps = {
  initialValues?: PopupWebBannersFormValues
  submitting?: boolean
  onSubmit: (data: PopupWebBannersFormData) => Promise<{ web_banner: PopupWebBanner }>
  deleting?: boolean
  onDelete?: () => void
}
type WebBannerFormType = "create" | "edit"

const validateConditionTree = makeConditionTreeValidator(validateWebBannerCondition)

export default function PopupWebBannersForm({
  initialValues,
  onSubmit,
  onDelete,
  submitting = false,
  deleting = false,
}: PopupWebBannersFormProps) {
  const type: WebBannerFormType = initialValues ? "edit" : "create"
  const history = useHistory()
  const { id } = useParams<{ id: PopupWebBanner["id"] }>()
  const [minimizedBannerTab, setMinimizedBannerTab] = useState<"general" | "close" | "hide">(
    "general",
  )
  const [bannerTab, setBannerTab] = useState<"general" | "close">("general")
  const [minimizedBannerExpanded, setMinimizedBannerExpanded] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    control,
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm<PopupWebBannersFormValues>({
    defaultValues: initialValues ?? {
      disabled: true,
      priority: PRIORITY_OPTIONS.find(item => item.value === 0)!,
      condition: null,
      minimized: false,
      display_minimized_first: false,
      minimized_hide_values: [],
      margin_x: 20,
      margin_y: 20,
      minimized_margin_x: 20,
      minimized_margin_y: 20,
      close_button: {
        disabled: false,
        css: {
          color: "#222222",
          background_color: "#ffffff",
          border_color: "#cccccc",
        },
      },
      minimized_close_button: {
        disabled: false,
        css: {
          color: "#222222",
          background_color: "#ffffff",
          border_color: "#cccccc",
        },
      },
      display_on: {
        action: DISPLAY_CONDITION_OPTIONS.find(item => item.value === "page_load")!,
        value: null,
      },
      tracking_disabled: false,
      global_frequency_cap_ignored: false,
    },
  })
  const [hoveredPath, setHoveredPath] = useState<ConditionPath>()
  const [bannerType, setBannerType] = useState<WebBannerType>(
    !initialValues ||
      (initialValues.content_type &&
        ["local_image", "remote_image"].includes(initialValues.content_type))
      ? "image"
      : "html",
  )
  const [minimizedBannerType, setMinimizedBannerType] = useState<WebBannerType>(
    !initialValues ||
      (initialValues.minimized_content_type &&
        ["local_image", "remote_image"].includes(initialValues.minimized_content_type))
      ? "image"
      : "html",
  )
  const [base64BannerImage, setBase64BannerImage] = useState<string | null>(null)
  const [base64BannerMinimizedImage, setBase64BannerMinimizedImage] = useState<string | null>(null)
  const [htmlCodeHighlight, setHtmlCodeHighlight] = useState<HighlightState | null>(null)
  const [isHtmlTemplateHintShown, setIsHtmlTemplateHintShown] = useState(false)
  const [templateDocLink, setTemplateDocLink] = useState<string | null>(null)
  const [disableTrackingModalOpen, setDisableTrackingModalOpen] = useState(false)
  const [ignoreCapModalOpen, setIgnoreCapModalOpen] = useState(false)

  // @ts-ignore
  const position = watch("position")
  const minimized_position = watch("minimized_position")
  const imageUrl = watch("image_url")
  const minimizedImageUrl = watch("minimized_image_url")
  const html = watch("html")
  const minimized_html = watch("minimized_html")
  const width = watch("width")
  const minimized_width = watch("minimized_width")
  const minimized = watch("minimized")
  const closeButtonStyles = watch("close_button")
  const minimizedCloseButtonStyles = watch("minimized_close_button")
  const frequencyCapWindowType = watch("frequency_cap_window_type")
  const minimizedHideInputValue = watch("minimized_hide_input")
  const minimizedHideArrayValues = watch("minimized_hide_values")
  const customMarginX = watch("margin_x")
  const customMarginY = watch("margin_y")
  const customMinimizedMarginX = watch("minimized_margin_x")
  const customMinimizedMarginY = watch("minimized_margin_y")
  const animation = watch("animation")
  const minimizedAnimation = watch("minimized_animation")
  const displayOnAction = watch("display_on.action")
  const isEditable = useHasAccess().webBanners.edit

  useEffect(() => {
    if (frequencyCapWindowType?.value === "session") {
      setValue("frequency_cap_window_size", null)
      clearErrors("frequency_cap_window_size")
    }
  }, [frequencyCapWindowType, setValue, clearErrors])

  const validateFormValues = useCallback(
    (formValues: PopupWebBannersFormValues): string | undefined => {
      const {
        position,
        image_url,
        image,
        minimized_image_url,
        minimized_image,
        width,
        minimized_width,
        minimized,
        minimized_position,
        minimized_hide_values,
        minimized_hide_operator,
        minimized_html,
      } = formValues

      if (bannerType === "image" && (!image || !image[0]) && !image_url) {
        return "One of 'Image upload' or 'Image URL' must be filled."
      }

      if (!position) {
        return "Banner position needs to be set."
      }

      if (bannerType === "html" && !width) {
        return "Banner width needs to be filled."
      }

      if (isEmpty(minimized_hide_values) && minimized_hide_operator) {
        return "Hide minimized banner values needs to be filled if operator is selected."
      }

      if (!isEmpty(minimized_hide_values) && !minimized_hide_operator) {
        return "Hide minimized banner operator needs to be selected if values are filled."
      }

      if (!minimized) {
        return
      }

      if (
        minimizedBannerType === "image" &&
        (!minimized_image || !minimized_image["0"]) &&
        !minimized_image_url
      ) {
        return "One of 'Minimized image upload' or 'Minimized image URL' must be filled."
      }

      if (!minimized_position) {
        return "Minimized banner position needs to be set."
      }

      if (minimizedBannerType === "html" && !minimized_width) {
        return "Minimized banner width needs to be filled."
      }

      if (minimizedBannerType === "html" && !minimized_html) {
        return "Fill the minimized banner HTML."
      }
    },
    [bannerType, minimizedBannerType],
  )

  const formValuesToFormData = useCallback(
    ({
      name,
      condition,
      frequency_cap_window_type,
      frequency_cap_window_size,
      frequency_cap_window_max_count,
      frequency_cap_user_max_count,
      priority,
      position,
      html,
      minimized_html,
      image_url,
      image,
      minimized_image_url,
      minimized_image,
      destination_url,
      width,
      minimized_width,
      disabled,
      minimized,
      minimized_position,
      display_minimized_first,
      minimized_hide_values,
      minimized_hide_operator,
      margin_x,
      margin_y,
      minimized_margin_x,
      minimized_margin_y,
      animation,
      minimized_animation,
      close_button,
      minimized_close_button,
      display_on,
      tracking_disabled,
      global_frequency_cap_ignored,
    }: PopupWebBannersFormValues): PopupWebBannersFormData => {
      const frequencyCapWindow: PopupWebBanner["settings"]["frequency_cap"]["per_user"]["window"] =
        frequency_cap_window_type.value === "session"
          ? {
              type: frequency_cap_window_type.value,
              max_display_count: Number(frequency_cap_window_max_count),
            }
          : {
              type: frequency_cap_window_type.value,
              max_display_count: Number(frequency_cap_window_max_count),
              size: Number(frequency_cap_window_size),
            }
      const frequencyCap: PopupWebBanner["settings"]["frequency_cap"] = {
        per_user: {
          window: frequencyCapWindow,
        },
      }
      if (!isEmptyField(frequency_cap_user_max_count)) {
        frequencyCap.per_user.max_display_count = Number(frequency_cap_user_max_count)
      }

      const sendImagePayloadData =
        bannerType === "image" &&
        (dirtyFields["image"] || dirtyFields["image_url"] || type === "create")
      const sendMinimizedImagePayloadData =
        minimizedBannerType === "image" &&
        (dirtyFields["minimized_image"] || dirtyFields["minimized_image_url"] || type === "create")

      return {
        name,
        condition,
        tracking_disabled,
        global_frequency_cap_ignored,
        frequency_cap: frequencyCap,
        priority: priority.value,
        position: position.value,
        image_local:
          sendImagePayloadData && image && image[0] ? base64BannerImage!.split(",")[1] : null,
        image_name: sendImagePayloadData && image && image[0] ? image[0].name : null,
        image_remote: sendImagePayloadData && image_url ? image_url : null,
        minimized_image_local:
          sendMinimizedImagePayloadData && minimized_image && minimized_image["0"]
            ? base64BannerMinimizedImage!.split(",")[1]
            : null,
        minimized_image_name:
          sendMinimizedImagePayloadData && minimized_image && minimized_image["0"]
            ? minimized_image["0"].name
            : null,
        minimized_image_remote:
          sendMinimizedImagePayloadData && minimized_image_url ? minimized_image_url : null,
        html: bannerType === "html" ? html : null,
        minimized_html: minimizedBannerType === "html" ? minimized_html : null,
        destination_url:
          bannerType === "image" &&
          (dirtyFields["destination_url"] || sendImagePayloadData || type === "create")
            ? destination_url
            : null,
        width: width ? Math.round(Number(width)) : null,
        minimized_width: minimized_width ? Math.round(Number(minimized_width)) : null,
        disabled: Boolean(disabled),
        minimized: Boolean(minimized),
        minimized_position: minimized_position?.value,
        display_minimized_first,
        minimized_hide_values,
        minimized_hide_operator,
        margin_x: margin_x ?? 0,
        margin_y: margin_y ?? 0,
        minimized_margin_x: minimized_margin_x ?? 0,
        minimized_margin_y: minimized_margin_y ?? 0,
        animation: animation?.value,
        minimized_animation: minimized_animation?.value,
        close_button: {
          ...close_button,
          disabled: Boolean(close_button.disabled),
        },
        minimized_close_button: {
          ...minimized_close_button,
          disabled: Boolean(minimized_close_button.disabled),
        },
        display_on: {
          action: display_on.action.value,
          value: display_on.action.value === "scroll" ? display_on.value ?? 0 : null,
        },
      }
    },
    [
      bannerType,
      base64BannerImage,
      base64BannerMinimizedImage,
      dirtyFields,
      minimizedBannerType,
      type,
    ],
  )

  const submitForm: SubmitHandler<PopupWebBannersFormValues> = async formValues => {
    if (submitting) {
      return
    }

    const error = validateFormValues(formValues)

    if (error) {
      showToast(error, TOAST.TYPE.ERROR)
      return
    }

    try {
      const webBanner = (await onSubmit(formValuesToFormData(formValues))).web_banner
      setBase64BannerImage(null)
      setBase64BannerMinimizedImage(null)
      reset(popupWBToFormValues(webBanner))
    } catch (err: any) {
      // TODO: typescript here, validate everything??
      const errors = err.response?.data?.errors

      if (errors) {
        if (errors.name) {
          setError("name", { type: "manual", message: errors.name[0] })
        }
        if (errors.settings) {
          Object.entries<string[]>(errors.settings).forEach(([key, error]) => {
            setError(key as keyof PopupWebBannersFormValues, {
              type: "manual",
              message: error[0],
            })
          })
        }
      }
    }
  }

  const toggleBannerType = useCallback(() => {
    setBannerType(bannerType === "image" ? "html" : "image")
    setValue("close_button", { ...closeButtonStyles, disabled: false })
    clearErrors("width")
  }, [bannerType, clearErrors, setValue, closeButtonStyles])

  const toggleMinimizedBannerType = useCallback(() => {
    setMinimizedBannerType(minimizedBannerType === "image" ? "html" : "image")
    setValue("minimized_close_button", { ...minimizedCloseButtonStyles, disabled: false })
    clearErrors("minimized_width")
  }, [minimizedBannerType, clearErrors, setValue, minimizedCloseButtonStyles])

  const copyMutation = useCopyPopupWB()

  const copyBanner = () =>
    copyMutation.mutate(
      { id },
      {
        onSuccess: ({ web_banner }) => {
          history.push(getRoutePath("channels.popup-banners.detail", { id: web_banner.id }))
        },
      },
    )

  const encodeFileToBase64 = useCallback(
    (file: Blob) =>
      new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      }),
    [],
  )

  const verifyAndEncodeFile = useCallback(
    (field: "image" | "minimized_image") => async (evt: ChangeEvent<HTMLInputElement>) => {
      clearErrors(field)
      const { files } = evt.target
      if (files && files[0]) {
        try {
          const encodedFile = await encodeFileToBase64(files[0])
          if (typeof encodedFile === "string") {
            field === "image"
              ? setBase64BannerImage(encodedFile)
              : setBase64BannerMinimizedImage(encodedFile)
            setValue(field, files, { shouldDirty: true })
            setValue(field === "image" ? "image_url" : "minimized_image_url", "", {
              shouldDirty: true,
            })
          }
        } catch {}
      }
    },
    [encodeFileToBase64, setValue, clearErrors],
  )

  const clearImageSelection = useCallback(
    (field: "image" | "minimized_image") => () => {
      clearErrors(field)
      setValue(field, undefined)

      if (base64BannerImage && field === "image") setBase64BannerImage(null)
      if (base64BannerMinimizedImage && field === "minimized_image")
        setBase64BannerMinimizedImage(null)
    },
    [base64BannerImage, base64BannerMinimizedImage, setValue, clearErrors],
  )

  const periodValidation = useCallback(
    value => {
      if (!frequencyCapWindowType || frequencyCapWindowType?.value === "session") {
        return undefined
      } else {
        return required(value)
      }
    },
    [frequencyCapWindowType],
  )

  const addHideBannerValue = useCallback(() => {
    if (minimizedHideInputValue) {
      if (minimizedHideArrayValues.includes(minimizedHideInputValue)) {
        showToast("Value is already added", TOAST.TYPE.ERROR)
      } else {
        setValue("minimized_hide_values", [...minimizedHideArrayValues, minimizedHideInputValue])
        setValue("minimized_hide_input", "")
      }
    }
  }, [minimizedHideInputValue, minimizedHideArrayValues, setValue])

  const removeHideBannerValue = useCallback(
    value => {
      setValue("minimized_hide_values", without([value], minimizedHideArrayValues))
    },
    [setValue, minimizedHideArrayValues],
  )

  const renderPositionMarginFields = useCallback(
    (type: "large" | "minimized") => {
      const selectedPosition = type === "large" ? position : minimized_position
      if (selectedPosition && selectedPosition.value !== "middle") {
        let fields: { label: string; name: "margin_x" | "margin_y" }[]
        switch (selectedPosition.value) {
          case "top":
            fields = [{ label: "Margin from top", name: "margin_y" }]
            break
          case "top_right":
            fields = [
              { label: "Margin from top", name: "margin_y" },
              { label: "Margin from right", name: "margin_x" },
            ]
            break
          case "right":
            fields = [{ label: "Margin from right", name: "margin_x" }]
            break
          case "bottom_right":
            fields = [
              { label: "Margin from bottom", name: "margin_y" },
              { label: "Margin from right", name: "margin_x" },
            ]
            break
          case "bottom":
            fields = [{ label: "Margin from bottom", name: "margin_y" }]
            break
          case "bottom_left":
            fields = [
              { label: "Margin from bottom", name: "margin_y" },
              { label: "Margin from left", name: "margin_x" },
            ]
            break
          case "left":
            fields = [{ label: "Margin from left", name: "margin_x" }]
            break
          case "top_left":
            fields = [
              { label: "Margin from top", name: "margin_y" },
              { label: "Margin from left", name: "margin_x" },
            ]
            break
          default:
            fields = []
            break
        }
        if (fields.length) {
          return (
            <div className={styles.positionMarginsFields}>
              {fields.map(field => (
                <TextInput
                  key={field.name}
                  label={field.label}
                  disabled={!isEditable}
                  type="number"
                  min="0"
                  className={styles.positionMarginField}
                  {...register(`${type === "minimized" ? "minimized_" : ""}${field.name}`)}
                />
              ))}
            </div>
          )
        }
      }
      return null
    },
    [position, minimized_position, isEditable, register],
  )

  const onPositionChange = useCallback(
    (type: "large" | "minimized") => (option?: SelectOption<PWBPosition>) => {
      if (option?.value === "middle") {
        if (type === "large" && animation?.value === "slide_in") {
          setValue("animation", ANIMATION_OPTIONS[1])
        } else if (type === "minimized" && minimizedAnimation?.value === "slide_in") {
          setValue("minimized_animation", ANIMATION_OPTIONS[1])
        }
      }
    },
    [animation, minimizedAnimation, setValue],
  )

  const editorRef = useRef<any>(null)

  const insertHtmlTemplate = (template: Template) => {
    setBannerType("html")
    setValue("html", template.html)
    setValue("width", template.width)
    setHtmlCodeHighlight(template.highlight)
    setIsHtmlTemplateHintShown(true)
    setTemplateDocLink(template.docLink)

    // Needs timeout in case we are on image banner to postpone the scroll to after the editor is
    // rendered
    setTimeout(() => {
      if (template.highlight) {
        editorRef.current?.editor.scrollToRow(template.highlight.startRow - 1)
      }
    }, 0)
  }

  const hideHintAndHighlight = () => {
    setHtmlCodeHighlight(null)
    setIsHtmlTemplateHintShown(false)
  }

  return (
    <>
      <Prompt
        when={!isEmpty(dirtyFields) && !submitting && !deleting}
        message="Changes you made will not be saved."
      />
      <form onSubmit={handleSubmit(submitForm)}>
        <Paper hasHeader className={classnames(styles.content, styles.triggerSettings)}>
          <PaperHeader size="small" className={styles.header}>
            <TextInput
              label="Name"
              labelPosition="left"
              error={errors.name?.message}
              placeholder="Name"
              disabled={!isEditable}
              className={styles.name}
              data-testid="banner-name-field"
              {...register("name", { validate: { required, maxLength: maxLength(100) } })}
            />
            <div className={styles.buttons} data-testid="form-action-buttons">
              <Controller
                name="disabled"
                control={control}
                render={({ field }) => {
                  return (
                    <div className={styles.disabledButtonToggleWrapper}>
                      <label>Active</label>
                      <ToggleButton
                        value={!field.value}
                        handleToggle={() => {
                          field.onChange(!field.value)
                        }}
                      />
                    </div>
                  )
                }}
              />
              {type === "edit" && (
                <>
                  <Button
                    color="red"
                    icon="trash-alt"
                    variant="outlined"
                    onClick={onDelete}
                    disabled={!isEditable}
                    className={styles.buttonMargin}
                    data-testid="delete-button"
                  >
                    Delete
                  </Button>
                  <Button
                    color="grey"
                    icon="clone"
                    loading={copyMutation.isLoading}
                    variant="outlined"
                    onClick={copyBanner}
                    className={styles.buttonMargin}
                    disabled={!isEditable}
                  >
                    Copy
                  </Button>
                </>
              )}
              <Button type="submit" disabled={!isEditable} loading={submitting}>
                {type === "create" ? "Create" : "Save"}
              </Button>
            </div>
          </PaperHeader>
          <div className={styles.conditionBuilderWrapper}>
            <Controller
              name="condition"
              control={control}
              rules={{
                validate: tree => {
                  const result = validateConditionTree(tree)
                  // Validator returns a stringified tree of errors because react-hook-forms only
                  // works with string errors
                  return result === null ? true : JSON.stringify(result)
                },
              }}
              render={({ field }) => {
                const stringifiedError = (errors.condition as FieldError | undefined)?.message
                const conditionError = stringifiedError
                  ? (JSON.parse(stringifiedError) as WBConditionError)
                  : null

                return (
                  <>
                    <PaperHeader
                      size="small"
                      titleText="Conditions"
                      className={classNames(styles.conditionsHeader, {
                        [styles.empty]: !field.value,
                      })}
                    >
                      <ConditionTreeOverview<WBCondition>
                        conditionTree={field.value}
                        onChange={field.onChange}
                        highlightedPath={hoveredPath}
                        onHover={setHoveredPath}
                      />
                    </PaperHeader>
                    <ConditionBuilder<WBCondition, WBConditionError>
                      conditionTree={field.value}
                      onChange={field.onChange}
                      isEditable={isEditable}
                      error={conditionError}
                      conditionComponent={Condition}
                      getNewCondition={getNewWebBannerCondition}
                      highlightedPath={hoveredPath}
                      onHover={setHoveredPath}
                    />
                  </>
                )
              }}
            />
          </div>
          <div className={styles.freqPosPrioRow}>
            <div className={classnames(styles.frequencyCapPerUserBox, styles.greyBox)}>
              <div className={styles.titleWrapper}>
                <h4 className={classNames(styles.greyBoxTitle, styles.noBottomMargin)}>
                  Frequency cap per user
                </h4>
                <Controller
                  control={control}
                  name="global_frequency_cap_ignored"
                  render={({ field: { value, onBlur, onChange } }) => (
                    <div className={styles.tooltipWrapper}>
                      <Checkbox
                        checked={value}
                        label={<strong>Ignore global frequency cap</strong>}
                        onBlur={onBlur}
                        onChange={() => {
                          if (value) onChange(false)
                          else setIgnoreCapModalOpen(true)
                        }}
                      />
                      <InfoTooltip placement="bottom" className={styles.tooltip}>
                        This will ignore global frequency cap settings for all banners.
                      </InfoTooltip>
                    </div>
                  )}
                />
              </div>
              <div className={styles.frequencyCapFieldsRow}>
                <TextInput
                  type="number"
                  label="How many times *"
                  error={errors.frequency_cap_window_max_count?.message}
                  placeholder="1 to 50"
                  {...register("frequency_cap_window_max_count", { validate: required })}
                  min="1"
                  max="50"
                  step="1"
                  disabled={!isEditable}
                  className={styles.frequencyCapCountField}
                  data-testid="frequency-cap-window-max-count"
                />
                <span>during</span>
                <div className={styles.period}>
                  <label className={styles.label}>Period *</label>
                  <div className={styles.flexBox}>
                    <TextInput
                      type="number"
                      error={errors.frequency_cap_window_size?.message}
                      placeholder={
                        frequencyCapWindowType?.value === "hours"
                          ? "1 to 168"
                          : frequencyCapWindowType?.value === "days"
                          ? "1 to 7"
                          : ""
                      }
                      {...register("frequency_cap_window_size", {
                        validate: periodValidation,
                      })}
                      min="1"
                      max={frequencyCapWindowType?.value === "hours" ? 168 : 7}
                      step="1"
                      disabled={
                        !isEditable ||
                        !frequencyCapWindowType ||
                        frequencyCapWindowType?.value === "session"
                      }
                      className={styles.frequencyCapWindowSizeField}
                    />
                    <Controller
                      name="frequency_cap_window_type"
                      control={control}
                      rules={{ validate: required }}
                      render={({ field }) => (
                        <SelectField
                          input={field}
                          inputId={field.name}
                          meta={{
                            touched: true,
                            // @ts-ignore
                            error: errors.frequency_cap_window_type?.message,
                          }}
                          options={FREQUENCY_CAP_TYPE_OPTIONS}
                          className={styles.frequencyCapWindowTypeSelect}
                          disabled={!isEditable}
                        />
                      )}
                    />
                  </div>
                </div>
                <span>and</span>
                <div
                  className={classnames(
                    styles.fieldWithTooltip,
                    styles.frequencyCapUserCountFieldWrapper,
                  )}
                >
                  <TextInput
                    type="number"
                    label="Total"
                    error={errors.frequency_cap_user_max_count?.message}
                    placeholder="1 to 50"
                    {...register("frequency_cap_user_max_count")}
                    min="1"
                    max="50"
                    step="1"
                    disabled={!isEditable}
                  />
                  <InfoTooltip placement="top" className={styles.tooltip} interactive>
                    <div className={styles.tooltipTotalCap}>
                      <p>
                        Maximum number of impressions for 1 user over the lifetime of the banner.
                      </p>
                      <p>
                        <strong>Warning: </strong>the number of impressions is stored in the
                        browser's local storage. If the local storage is cleared, the user might see
                        the banner more times. More info{" "}
                        <a
                          href="https://www.cookiestatus.com/"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          here
                        </a>
                        .
                      </p>
                    </div>
                  </InfoTooltip>
                </div>
              </div>
            </div>

            <div className={classnames(styles.greyBox, styles.priorityBox)}>
              <h4 className={styles.greyBoxTitle}>Priority</h4>
              <div className={classnames(styles.fieldWithTooltip, styles.priorityFieldWrapper)}>
                <Controller
                  name="priority"
                  control={control}
                  rules={{ validate: required }}
                  render={({ field }) => (
                    <SelectField
                      input={field}
                      label="Priority *"
                      inputId={field.name}
                      meta={{
                        touched: true,
                        // @ts-ignore
                        error: errors.priority?.message,
                      }}
                      options={PRIORITY_OPTIONS}
                      className={styles.prioritySelect}
                      disabled={!isEditable}
                    />
                  )}
                />
                <InfoTooltip placement="top" className={styles.tooltip}>
                  Select priority from 0-10. Web banner with higher priority will appear first. 10
                  is the highest.
                </InfoTooltip>
              </div>
            </div>

            <div className={classnames(styles.greyBox, styles.displayConditionBox)}>
              <h4 className={styles.greyBoxTitle}>Display condition</h4>
              <div className={styles.displayConditionContent}>
                <Controller
                  name="display_on.action"
                  control={control}
                  rules={{ validate: required }}
                  render={({ field }) => (
                    <SelectField
                      input={field}
                      label="Show on"
                      meta={{
                        touched: true,
                        // @ts-ignore
                        error: errors.display_on?.action,
                      }}
                      options={DISPLAY_CONDITION_OPTIONS}
                      className={classnames(styles.displayConditionSelect, {
                        [styles.scrollValue]: displayOnAction?.value === "scroll",
                      })}
                      disabled={!isEditable}
                    />
                  )}
                />
                {displayOnAction?.value === "scroll" && (
                  <>
                    <span>greater or equal than</span>
                    <TextInput
                      type="number"
                      min="0"
                      max="100"
                      className={styles.scrollPercentageField}
                      error={errors.display_on?.value?.message}
                      disabled={!isEditable}
                      {...register("display_on.value", {
                        validate: required,
                      })}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Paper>
        <Paper className={classnames(styles.content, styles.nextBox, styles.bannerContent)}>
          <div data-testid="banner-section" className={styles.leftPart}>
            <div className={styles.paperInnerHeader}>
              <h3>Banner</h3>
              <div className={styles.bannerDesignControls}>
                <TemplateGallery
                  isTextAreaEmpty={!html}
                  insertTemplate={insertHtmlTemplate}
                  templates={templates}
                />
                <div className={styles.bannerToggleWrapper}>
                  <Switch
                    name="banner_type"
                    leftValue="image"
                    rightValue="html"
                    checked={bannerType}
                    onToggle={toggleBannerType}
                    disabled={!isEditable}
                    className={styles.bannerTypeSwitch}
                  />
                </div>
              </div>
            </div>

            <div
              className={classnames(styles.bannerContent, {
                [styles.htmlContent]: bannerType === "html",
                [styles.imageContent]: bannerType === "image",
              })}
            >
              {bannerType === "image" && (
                <>
                  <TextInput
                    label="Destination URL"
                    error={errors.destination_url?.message}
                    placeholder="https://www.example.com/promotion"
                    disabled={!isEditable}
                    {...register("destination_url", { validate: url })}
                  />
                  <div className={styles.imageUploadRow}>
                    <div className={styles.imageUploadRowContent}>
                      <Controller
                        control={control}
                        name="image"
                        rules={{ validate: { imageFile } }}
                        render={({ field: { value, ref, onBlur } }) => (
                          <FileField
                            ref={ref}
                            label="Image upload"
                            error={errors.image?.message}
                            disabled={!isEditable}
                            accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                            value={value?.[0]?.name}
                            onBlur={onBlur}
                            onChange={verifyAndEncodeFile("image")}
                            onClearClick={clearImageSelection("image")}
                            className={styles.fileInput}
                          />
                        )}
                      />
                      <p className={styles.imageUploadDescription}>
                        Image type: APNG, AVIF, GIF, JPG, PNG, SVG, WebP; Max size: 500 kB
                      </p>
                    </div>
                    <TextInput
                      label="Image URL"
                      error={errors.image_url?.message}
                      placeholder="https://www.example.com/image.jpg"
                      className={styles.imageUrlField}
                      disabled={!isEditable || base64BannerImage !== null}
                      {...register("image_url", { validate: url })}
                    />
                  </div>
                </>
              )}
              {bannerType === "html" && (
                <div>
                  <Controller
                    name="html"
                    control={control}
                    rules={{ validate: required }}
                    render={({ field }) => (
                      <div
                        className={classNames(
                          "ace-editor-wrapper",
                          errors.html?.message && "error",
                          styles.htmlCodeEditorWrapper,
                        )}
                        data-testid="html-ace-editor-wrapper"
                      >
                        <p className="label-like">Banner HTML *</p>
                        {isHtmlTemplateHintShown && (
                          <div className={styles.htmlTemplateHint}>
                            {htmlCodeHighlight
                              ? "Before proceeding, please change the variables on the highlighted rows. To learn more, visit the "
                              : "To learn more about how to use this template, visit the "}
                            <a href={templateDocLink!} target="_blank" rel="noreferrer">
                              user documentation
                            </a>
                            .
                            <button
                              type="button"
                              onClick={() => setIsHtmlTemplateHintShown(false)}
                              className={styles.hintCloseButton}
                            >
                              <FontAwesomeIcon icon={["fas", "times"]} />
                            </button>
                          </div>
                        )}
                        <Suspense fallback={<LoadingIndicator />}>
                          <AceEditor
                            mode="html"
                            theme="tomorrow"
                            onChange={(...args) => {
                              field.onChange(...args)
                              hideHintAndHighlight()
                            }}
                            ref={editorRef}
                            name="html"
                            width="808px"
                            height="398px"
                            editorProps={{ $blockScrolling: true }}
                            wrapEnabled={true}
                            className="ace-editor"
                            value={field.value ?? undefined}
                            setOptions={{
                              tabSize: 2,
                              showPrintMargin: false,
                            }}
                            markers={
                              htmlCodeHighlight
                                ? [
                                    {
                                      ...htmlCodeHighlight,
                                      startCol: 0,
                                      endCol: 0,
                                      className: styles.aceEditorHighlight,
                                      type: "fullLine",
                                    },
                                  ]
                                : []
                            }
                            errorMessage={errors.html?.message}
                          />
                        </Suspense>
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.rightPart}>
            <div className={styles.tabs}>
              <button
                className={classnames(styles.leftButton, {
                  [styles.active]: bannerTab === "general",
                })}
                onClick={() => {
                  setBannerTab("general")
                }}
                type="button"
              >
                General
              </button>
              <button
                className={classnames(styles.rightButton, {
                  [styles.active]: bannerTab === "close",
                })}
                onClick={() => {
                  setBannerTab("close")
                }}
                type="button"
              >
                Close button
              </button>
            </div>

            <div className={styles.bannerSettingsContent}>
              {bannerTab === "general" && (
                <>
                  <h4>General settings</h4>
                  <div className={styles.rightPartInnerBox}>
                    <Controller
                      name="position"
                      control={control}
                      rules={{ validate: required }}
                      render={({ field }) => (
                        <SelectField
                          input={field}
                          inputId={field.name}
                          label="Position *"
                          meta={{
                            touched: true,
                            // @ts-ignore
                            error: errors.position?.message,
                          }}
                          options={POSITION_OPTIONS}
                          className={styles.settingsBoxSelect}
                          disabled={!isEditable}
                          /*
                          // @ts-ignore */
                          onChange={onPositionChange("large")}
                        />
                      )}
                    />
                    {renderPositionMarginFields("large")}
                    <div className={classnames(styles.bannerWidthField, styles.fieldWithTooltip)}>
                      <TextInput
                        type="number"
                        label={`Banner width in pixels${bannerType === "html" ? " *" : ""}`}
                        error={errors.width?.message}
                        {...register("width", bannerType === "html" ? { validate: required } : {})}
                        min="1"
                        step="1"
                        disabled={!isEditable}
                      />
                      <InfoTooltip placement="top" className={styles.tooltip}>
                        If it does not fit into the viewport, it will be responsive: 100% viewport
                        width, 40 pixels of margins.
                      </InfoTooltip>
                    </div>
                    {position?.value && (
                      <Controller
                        name="animation"
                        control={control}
                        render={({ field }) => (
                          <SelectField
                            input={field}
                            label="Animation"
                            options={
                              position?.value === "middle"
                                ? ANIMATION_OPTIONS.filter(anim => anim.value !== "slide_in")
                                : ANIMATION_OPTIONS
                            }
                            className={classnames(styles.settingsBoxSelect, styles.animationSelect)}
                            disabled={!isEditable}
                          />
                        )}
                      />
                    )}
                    <div className={styles.disableDefaultTrackingField}>
                      <Controller
                        control={control}
                        name="tracking_disabled"
                        render={({ field: { value, onBlur, onChange } }) => (
                          <Checkbox
                            checked={value}
                            label={<b>Disable default tracking</b>}
                            onBlur={onBlur}
                            onChange={() => {
                              if (value) onChange(false)
                              else setDisableTrackingModalOpen(true)
                            }}
                          />
                        )}
                      />
                      <InfoTooltip placement="bottom" className={styles.tooltip}>
                        This will disable default tracking that Meiro has.
                      </InfoTooltip>
                    </div>
                  </div>
                </>
              )}
              {bannerTab === "close" && (
                <Controller
                  name="close_button"
                  control={control}
                  render={({ field }) => (
                    <CloseButtonFields
                      value={field.value}
                      onChange={field.onChange}
                      disabled={!isEditable}
                      name={field.name}
                      disabledEnableCloseButton={bannerType === "image"}
                    />
                  )}
                />
              )}
            </div>
          </div>
        </Paper>

        <Paper
          className={classnames(
            styles.content,
            styles.bannerContent,
            styles.nextBox,
            styles.minimizedBannerPaper,
          )}
        >
          <div data-testid="minimized-banner" className={styles.leftPart}>
            <div className={styles.paperInnerHeader}>
              <div className={styles.titleWithToggle}>
                <IconButton
                  disabled={!minimized}
                  color="grey"
                  icon={minimizedBannerExpanded ? "caret-up" : "caret-down"}
                  variant="transparent"
                  onClick={() => setMinimizedBannerExpanded(prevState => !prevState)}
                  className={styles.iconButton}
                />
                <h3>
                  Minimized banner{" "}
                  <InfoTooltip placement="top">
                    Banner will be minimized in the right bottom corner when closed.
                  </InfoTooltip>
                </h3>
                <Controller
                  name="minimized"
                  control={control}
                  render={({ field }) => {
                    return (
                      <div className={styles.minimizedToggleWrapper}>
                        <ToggleButton
                          value={field.value}
                          handleToggle={() => {
                            field.onChange(!field.value)
                            setMinimizedBannerExpanded(!field.value)
                          }}
                        />
                      </div>
                    )
                  }}
                />
              </div>
              <div className={styles.bannerToggleBox}>
                <ToggleSwitch
                  name="minimized_banner_type"
                  leftValue="image"
                  rightValue="html"
                  checked={minimizedBannerType}
                  handleToggle={toggleMinimizedBannerType}
                  width="120px"
                  disabled={!isEditable || !minimized}
                  className={styles.minimizedBannerTypeToggleBtn}
                />
              </div>
            </div>
            {minimizedBannerExpanded && (
              <div
                className={classnames(styles.bannerContent, {
                  [styles.htmlContent]: minimizedBannerType === "html",
                  [styles.imageContent]: minimizedBannerType === "image",
                })}
              >
                {minimizedBannerType === "image" && (
                  <>
                    <div
                      className={classnames(styles.imageUploadRow, styles.minimizedImageUploadRow)}
                    >
                      <div className={styles.imageUploadRowContent}>
                        <Controller
                          control={control}
                          name="minimized_image"
                          rules={{ validate: { imageFile } }}
                          render={({ field: { value, ref, onBlur } }) => (
                            <FileField
                              ref={ref}
                              label="Minimized image upload"
                              error={errors.minimized_image?.message}
                              disabled={!isEditable}
                              accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                              value={value?.[0]?.name}
                              onBlur={onBlur}
                              onChange={verifyAndEncodeFile("minimized_image")}
                              onClearClick={clearImageSelection("minimized_image")}
                              className={styles.fileInput}
                            />
                          )}
                        />
                        <p className={styles.imageUploadDescription}>
                          Image type: APNG, AVIF, GIF, JPG, PNG, SVG, WebP; Max size: 500 kB
                        </p>
                      </div>
                      <TextInput
                        label="Minimized image URL"
                        error={errors.minimized_image_url?.message}
                        placeholder="https://www.example.com/image.jpg"
                        className={styles.imageUrlField}
                        disabled={!isEditable || base64BannerMinimizedImage !== null}
                        {...register("minimized_image_url", { validate: url })}
                      />
                    </div>
                  </>
                )}
                {minimizedBannerType === "html" && (
                  <>
                    <div>
                      <Controller
                        name="minimized_html"
                        control={control}
                        rules={{ validate: required }}
                        render={({ field }) => (
                          <div
                            className={`ace-editor-wrapper ${styles.minimizedHtmlField} ${
                              errors.minimized_html?.message ? "error" : ""
                            }`}
                          >
                            <p className="label-like">Minimized banner HTML *</p>
                            <Suspense fallback={<LoadingIndicator />}>
                              <AceEditor
                                mode="html"
                                theme="tomorrow"
                                onChange={field.onChange}
                                name="minimized_html"
                                width="808px"
                                height="398px"
                                editorProps={{ $blockScrolling: true }}
                                wrapEnabled={true}
                                className="ace-editor"
                                defaultValue={field.value ?? undefined}
                                setOptions={{
                                  tabSize: 2,
                                  showPrintMargin: false,
                                }}
                                errorMessage={errors.minimized_html?.message}
                              />
                            </Suspense>
                          </div>
                        )}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div className={styles.rightPart}>
            <div
              className={classnames(styles.tabs, styles.minimizedTabs, {
                [styles.imageBannerTabs]: minimizedBannerType === "image",
              })}
            >
              <button
                className={classnames(styles.leftButton, {
                  [styles.active]: minimizedBannerTab === "general",
                })}
                onClick={() => {
                  setMinimizedBannerTab("general")
                }}
                disabled={!minimized}
                type="button"
              >
                General
              </button>
              <button
                className={classnames(styles.middleButton, {
                  [styles.active]: minimizedBannerTab === "close",
                  [styles.activeLeft]: minimizedBannerTab === "general",
                  [styles.activeRight]: minimizedBannerTab === "hide",
                })}
                onClick={() => {
                  setMinimizedBannerTab("close")
                }}
                disabled={!minimized}
                type="button"
              >
                Close button
              </button>
              <button
                className={classnames(styles.rightButton, {
                  [styles.active]: minimizedBannerTab === "hide",
                })}
                onClick={() => {
                  setMinimizedBannerTab("hide")
                }}
                disabled={!minimized}
                type="button"
              >
                Hide banner
              </button>
            </div>

            {minimizedBannerExpanded && (
              <div
                data-testid="minimized-general-settings"
                className={styles.minimizedSettingsContent}
              >
                {minimizedBannerTab === "general" && (
                  <>
                    <h4>General settings</h4>
                    <div className={styles.rightPartInnerBox}>
                      <Controller
                        name="display_minimized_first"
                        control={control}
                        render={({ field }) => {
                          return (
                            <div className={styles.minimizedFirstToggleWrapper}>
                              <label>Display minimized version first</label>
                              <ToggleButton
                                value={field.value || false}
                                handleToggle={() => {
                                  field.onChange(!field.value)
                                }}
                              />
                            </div>
                          )
                        }}
                      />
                      <Controller
                        name="minimized_position"
                        control={control}
                        render={({ field }) => (
                          <SelectField
                            input={field}
                            label="Position *"
                            meta={{
                              touched: true,
                              // @ts-ignore
                              error: errors.minimized_position?.message,
                            }}
                            options={POSITION_OPTIONS}
                            className={styles.settingsBoxSelect}
                            disabled={!isEditable}
                            /*
                            // @ts-ignore */
                            onChange={onPositionChange("minimized")}
                          />
                        )}
                      />
                      {renderPositionMarginFields("minimized")}
                      <div
                        className={classnames(
                          styles.bannerMinimizedWidthField,
                          styles.fieldWithTooltip,
                        )}
                      >
                        <TextInput
                          type="number"
                          label={`Minimized banner width in pixels${
                            minimizedBannerType === "html" ? " *" : ""
                          }`}
                          error={errors.minimized_width?.message}
                          {...register(
                            "minimized_width",
                            minimizedBannerType === "html" ? { validate: required } : {},
                          )}
                          min="1"
                          step="1"
                          disabled={!isEditable}
                        />
                        <InfoTooltip placement="top" className={styles.tooltip}>
                          If it does not fit into the viewport, it will be responsive: 100% viewport
                          width, 40 pixels of margins.
                        </InfoTooltip>
                      </div>
                      {minimized_position?.value && (
                        <Controller
                          name="minimized_animation"
                          control={control}
                          render={({ field }) => (
                            <SelectField
                              input={field}
                              label="Animation"
                              options={
                                minimized_position?.value === "middle"
                                  ? ANIMATION_OPTIONS.filter(anim => anim.value !== "slide_in")
                                  : ANIMATION_OPTIONS
                              }
                              className={classnames(
                                styles.settingsBoxSelect,
                                styles.animationSelect,
                              )}
                              disabled={!isEditable}
                            />
                          )}
                        />
                      )}
                    </div>
                  </>
                )}
                {minimizedBannerTab === "close" && (
                  <Controller
                    name="minimized_close_button"
                    control={control}
                    render={({ field }) => (
                      <CloseButtonFields
                        value={field.value}
                        onChange={field.onChange}
                        disabled={!isEditable}
                        name={field.name}
                        disabledEnableCloseButton={minimizedBannerType === "image"}
                      />
                    )}
                  />
                )}
                {minimizedBannerTab === "hide" && (
                  <>
                    <h4>Hide banner</h4>
                    <div className={styles.rightPartInnerBox}>
                      <Controller
                        name="minimized_hide_operator"
                        control={control}
                        render={({ field }) => (
                          <SelectField
                            input={field}
                            label="When URL"
                            meta={{
                              touched: true,
                              // @ts-ignore
                              error: errors.minimized_hide_operator?.message,
                            }}
                            options={HIDE_OPERATOR_OPTIONS}
                            disabled={!isEditable}
                            className={styles.settingsBoxSelect}
                            isClearable
                          />
                        )}
                      />
                      <div className={styles.minimizedHideValuesInputWrapper}>
                        <TextInput
                          label="Value(s)"
                          error={errors.minimized_hide_input?.message}
                          {...register("minimized_hide_input")}
                          disabled={!isEditable}
                          className={styles.minimizedHideValueInput}
                          onKeyPress={evt => {
                            if (evt.key === "Enter") {
                              evt.preventDefault()
                              addHideBannerValue()
                            }
                          }}
                          autoComplete="off"
                        />
                        <Button
                          className={styles.minimizedHideValueButtonAdd}
                          onClick={addHideBannerValue}
                        >
                          + add
                        </Button>
                        <div className={styles.minimizedHideValues}>
                          {minimizedHideArrayValues.map(value => (
                            <React.Fragment key={value}>
                              {value.length > 35 ? (
                                <Tippy content={value}>
                                  <div>
                                    <Tag
                                      color="primary"
                                      key={value}
                                      onClick={() => removeHideBannerValue(value)}
                                      clickable
                                    >
                                      {shortenTextInMiddle(value, 35)}
                                    </Tag>
                                  </div>
                                </Tippy>
                              ) : (
                                <Tag
                                  color="primary"
                                  key={value}
                                  onClick={() => removeHideBannerValue(value)}
                                  clickable
                                >
                                  {value}
                                </Tag>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </Paper>
        <BannerPreview
          type={bannerType}
          minimizedType={minimizedBannerType}
          html={html}
          minimizedHtml={minimized_html ?? null}
          imageUrl={imageUrl}
          minimizedImageUrl={minimizedImageUrl}
          base64Image={base64BannerImage}
          base64MinimizedImage={base64BannerMinimizedImage}
          width={width}
          minimizedWidth={minimized_width}
          position={position?.value}
          minimizedPosition={minimized_position?.value}
          margins={{
            x: customMarginX,
            y: customMarginY,
            minimizedX: customMinimizedMarginX,
            minimizedY: customMinimizedMarginY,
          }}
          minimizedBannerEnabled={minimized}
          animation={animation?.value}
          minimizedAnimation={minimizedAnimation?.value}
          closeButtonStyles={closeButtonStyles}
          minimizedCloseButtonStyles={minimizedCloseButtonStyles}
        />
        <WebBannerDisableDefaultTrackingModal
          open={disableTrackingModalOpen}
          handleClose={() => setDisableTrackingModalOpen(false)}
          handleConfirm={() => setValue("tracking_disabled", true)}
        />
        <IgnoreFrequencyCapModal
          entity="banner"
          open={ignoreCapModalOpen}
          onClose={() => setIgnoreCapModalOpen(false)}
          onConfirm={() => {
            setValue("global_frequency_cap_ignored", true)
            setIgnoreCapModalOpen(false)
          }}
        />
      </form>
    </>
  )
}

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { api } from "api"
import { queryClient } from "app/queryClient"
import { useCurrentUserId } from "auth/auth"
import { Segment } from "resources/segment/segment/segmentTypes"
import { User } from "resources/user/userTypes"
import { Permission } from "types/util"
import { UserPermission } from "./segmentPermissionTypes"
import { SEGMENT } from "resources/segment/segment/segmentQueries"

const SEGMENT_PERMISSION = "segmentPermission" as const

export function refetchSegmentPermissionsForCurrentUser() {
  queryClient.refetchQueries([SEGMENT_PERMISSION, "currentUser"])
}

export function useFetchSegmentPermissionsBySegmentId(segmentId: Segment["id"]) {
  return useQuery([SEGMENT_PERMISSION, SEGMENT, segmentId], () =>
    api.segmentPermission.listAllBySegmentId(segmentId),
  )
}

export function useFetchSegmentPermissionsForCurrentUser() {
  const currentUserId = useCurrentUserId()
  return useQuery([SEGMENT_PERMISSION, "currentUser"], () =>
    api.segmentPermission.listAllByUserId(currentUserId!),
  )
}

export function useCreateSegmentPermissionsByUserId() {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      userId,
      segmentPermissions,
    }: {
      userId: User["id"]
      segmentPermissions: Record<Segment["id"], Permission>
    }) => api.segmentPermission.createManyByUserId(userId, segmentPermissions),
    {
      onSuccess() {
        queryClient.invalidateQueries([SEGMENT_PERMISSION])
      },
    },
  )
}

export function useCreateSegmentPermissionsBySegmentId() {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      segmentId,
      segmentPermissions,
    }: {
      segmentId: Segment["id"]
      segmentPermissions: UserPermission[]
    }) => api.segmentPermission.createManyBySegmentId(segmentId, segmentPermissions),
    {
      onSuccess() {
        queryClient.invalidateQueries([SEGMENT_PERMISSION])
      },
    },
  )
}
export function useModifySegmentPermissionsBySegmentId() {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      segmentId,
      segmentPermissions,
    }: {
      segmentId: Segment["id"]
      segmentPermissions: UserPermission[]
    }) => api.segmentPermission.modifyManyBySegmentId(segmentId, segmentPermissions),
    {
      onSuccess() {
        queryClient.invalidateQueries([SEGMENT_PERMISSION])
      },
    },
  )
}
export function useDeleteSegmentPermissionsBySegmentId() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ segmentId, userIds }: { segmentId: Segment["id"]; userIds: User["id"][] }) =>
      api.segmentPermission.deleteManyBySegmentId(segmentId, userIds),
    {
      onSuccess() {
        queryClient.invalidateQueries([SEGMENT_PERMISSION])
      },
    },
  )
}

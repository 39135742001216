import React, { lazy, PureComponent, Suspense } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "../../elements/Button/Button"
import { DATE_FMT } from "sharedConstants"
import "./CustomDatePicker.scss"
import { sanitizeHour, sanitizeMinute } from "./timeSanitizers"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import Tippy from "@tippyjs/react"
import { api } from "api"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import classNames from "classnames"
import { addYears, endOfYear, format } from "date-fns"
import { DBtimestampToDate, toLocalDate } from "utilities/date"
import { formatInTimeZone } from "date-fns-tz"
const DatePicker = lazy(() => import("react-datepicker"))

const TOOLTIP_CONTENT_FETCHING_TIME = "(fetching…)"
const TOOLTIP_CONTENT_RESOLVED_VALUE_INVALID = "invalid"

function isValidDate(str) {
  return /^\d{4}-\d{2}-\d{2}([\sT]\d{2}:\d{2}:\d{2})?$/.test(str)
}

class CustomDatePicker extends PureComponent {
  constructor(props) {
    super(props)

    const { value } = props

    this.state = {
      type: isValidDate(value) || !value ? "calendar" : "input",
      displayValue: "",
      date: null,
      hours: "00",
      minutes: "00",
      resolvedNLValue: null,
      isFetchingResolvedNLValue: false,
    }
  }

  toggleDropdownType = type => () => {
    this.setState({
      type,
    })
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this._handleOutsideClick, false)
    window.removeEventListener("keyup", this._handleKeyUp, false)
  }

  _handleOutsideClick = evt => {
    const DROPDOWN_MONTH = "react-datepicker__month-option"
    const YEAR_DROPDOWN = "react-datepicker__year-option"

    if (this.datepicker !== null) {
      if (!this.datepicker.contains(evt.target)) {
        if (evt.target !== null && typeof evt.target.className === "string") {
          if (
            !evt.target.className.includes(DROPDOWN_MONTH) &&
            !evt.target.className.includes(YEAR_DROPDOWN)
          ) {
            this.props.toggle()
          }
        } else {
          this.props.toggle()
        }
      }
    }
  }

  onNaturalLanguageFieldChange = evt => {
    this.setState({
      displayValue: evt.currentTarget.value,
    })
  }

  _handleKeyUp = evt => {
    const keys = {
      13: () => {
        evt.preventDefault()
        this.applyChanges()
      },
    }
    if (keys[evt.keyCode]) {
      keys[evt.keyCode]()
    }
  }

  updateState() {
    // `value` is either a natural language string like "5 days ago", or date string like
    // "YYYY-MM-DD" if dataType === "date", or datetime string like "YYYY-MM-DD HH:MM:SS" if
    // dataType === "datetime"
    const { value, dataType } = this.props

    if (value && !isValidDate(value)) {
      this.fetchNLDateValue()
    }

    let displayValue = ""

    if (value) {
      displayValue = isValidDate(value)
        ? format(
            dataType === "datetime" ? DBtimestampToDate(value) : toLocalDate(value),
            dataType === "datetime" ? DATE_FMT.DATETIME : DATE_FMT.DATE,
          )
        : value
    }
    let date = null
    let hours = "00"
    let minutes = "00"

    if (value && isValidDate(value)) {
      if (dataType === "datetime") {
        date = DBtimestampToDate(value)
        hours = sanitizeHour(date.getHours().toString())
        minutes = sanitizeMinute(date.getMinutes().toString())
        date.setHours(0)
        date.setMinutes(0)
      } else {
        date = toLocalDate(value)
      }
    }

    this.setState({
      displayValue,
      date,
      hours,
      minutes,
      resolvedNLValue: null,
    })
  }

  componentDidMount() {
    this.updateState()
  }

  componentDidUpdate(prevProps) {
    const { open, value } = this.props
    if (open && !prevProps.open) {
      // datepicker opened
      setTimeout(() => document.addEventListener("mousedown", this._handleOutsideClick, false), 0)
      window.addEventListener("keyup", this._handleKeyUp, false)
    } else if (!open && prevProps.open) {
      // datepicker closed
      document.removeEventListener("mousedown", this._handleOutsideClick, false)
      window.removeEventListener("keyup", this._handleKeyUp, false)
    }

    if (value === prevProps.value) {
      return
    }

    this.updateState()
  }

  applyChanges = () => {
    const { date, hours, minutes, type, displayValue } = this.state
    const { dataType, onChange, toggle } = this.props

    if (type === "calendar") {
      if (dataType === "datetime") {
        const tempDate = new Date(date)
        tempDate.setHours(+hours)
        tempDate.setMinutes(+minutes)
        onChange(formatInTimeZone(tempDate, "UTC", DATE_FMT.DB_DATETIME))
      } else {
        onChange(format(date, DATE_FMT.DB_DATE))
      }
    } else {
      if (isValidDate(displayValue)) {
        onChange(
          formatInTimeZone(
            new Date(displayValue),
            "UTC",
            dataType === "datetime" ? DATE_FMT.DB_DATETIME : DATE_FMT.DB_DATE,
          ),
        )
      } else {
        onChange(displayValue)
      }
    }

    toggle()
  }

  fetchNLDateValue = async () => {
    const { value, dataType } = this.props
    this.setState({ isFetchingResolvedNLValue: true })
    const resolvedNLValue = (await api.tools.textToDatetime(value, dataType))[value]
    this.setState({ resolvedNLValue, isFetchingResolvedNLValue: false })
  }

  render() {
    const {
      value,
      isEditable,
      open,
      toggle,
      className = "",
      placeholder,
      dataType,
      withoutNL,
    } = this.props
    const { type, displayValue, date, hours, minutes, resolvedNLValue, isFetchingResolvedNLValue } =
      this.state

    const formatString = dataType === "datetime" ? DATE_FMT.DATETIME : DATE_FMT.DATE

    const showTimeTooltip = value && (!isValidDate(value) || dataType === "datetime")

    let tooltipContent = null

    if (showTimeTooltip) {
      if (isValidDate(value)) {
        tooltipContent = (
          <>
            <p>
              <strong>Local time:</strong> {format(DBtimestampToDate(value), formatString)}
            </p>
            <p>
              <strong>UTC time:</strong>{" "}
              {formatInTimeZone(DBtimestampToDate(value), "UTC", formatString)}
            </p>
          </>
        )
      } else if (isFetchingResolvedNLValue) {
        tooltipContent = TOOLTIP_CONTENT_FETCHING_TIME
      } else if (resolvedNLValue === TOOLTIP_CONTENT_RESOLVED_VALUE_INVALID) {
        tooltipContent = "Invalid datetime value"
      } else if (resolvedNLValue) {
        tooltipContent = (
          <>
            <p>
              Value if resolved at this moment:{" "}
              {dataType === "date" && format(toLocalDate(resolvedNLValue), formatString)}
            </p>
            {dataType === "datetime" && (
              <>
                {" "}
                <p>
                  <strong>Local time:</strong>{" "}
                  {format(DBtimestampToDate(resolvedNLValue), formatString)}
                </p>
                <p>
                  <strong>UTC time:</strong>{" "}
                  {formatInTimeZone(DBtimestampToDate(resolvedNLValue), "UTC", formatString)}
                </p>
              </>
            )}
          </>
        )
      }
    }

    return (
      <div className={classNames("datepicker-wrapper", "custom", { open }, className)}>
        <input
          type="text"
          value={displayValue}
          placeholder={placeholder ?? "Pick a date"}
          className="condition-input"
          onClick={toggle}
          readOnly
          disabled={!isEditable}
        />
        {showTimeTooltip && (
          <Tippy content={tooltipContent}>
            <div
              className={classNames("time-tooltip-wrapper", {
                "invalid-time": resolvedNLValue === TOOLTIP_CONTENT_RESOLVED_VALUE_INVALID,
                "valid-time":
                  tooltipContent !== null &&
                  tooltipContent !== TOOLTIP_CONTENT_FETCHING_TIME &&
                  resolvedNLValue !== TOOLTIP_CONTENT_RESOLVED_VALUE_INVALID,
              })}
            >
              <FontAwesomeIcon icon={["fas", "clock"]} />
            </div>
          </Tippy>
        )}
        {open && (
          <div
            className="react-datepicker-popper custom"
            data-placement="bottom-start"
            ref={node => (this.datepicker = node)}
          >
            {!withoutNL && (
              <div className="custom-header">
                <Button
                  color={type === "calendar" ? "primary" : "grey"}
                  variant={type === "calendar" ? "solid" : "outlined"}
                  onClick={this.toggleDropdownType("calendar")}
                >
                  Calendar
                </Button>
                <Button
                  color={type === "input" ? "primary" : "grey"}
                  variant={type === "input" ? "solid" : "outlined"}
                  onClick={this.toggleDropdownType("input")}
                >
                  Natural language
                </Button>
              </div>
            )}
            {type === "calendar" && (
              <div className="calendar-wrapper">
                {dataType === "datetime" && (
                  <div className="time-input-wrapper">
                    <div className="time-input-label">time:</div>
                    <input
                      value={hours}
                      onChange={e => {
                        const newValue = sanitizeHour(e.target.value)
                        if (newValue) {
                          this.setState({ hours: newValue })
                        }
                      }}
                      className="time-input"
                      onFocus={e => e.target.select()}
                    />
                    <span>&nbsp;:&nbsp;</span>
                    <input
                      value={minutes}
                      onChange={e => {
                        const newValue = sanitizeMinute(e.target.value)
                        if (newValue) {
                          this.setState({ minutes: newValue })
                        }
                      }}
                      className="time-input"
                      onFocus={e => e.target.select()}
                    />
                    <InfoTooltip className="time-input-info">
                      Enter values in your local timezone.
                    </InfoTooltip>
                  </div>
                )}
                <Suspense fallback={<LoadingIndicator />}>
                  <DatePicker
                    dateFormat={DATE_FMT.DATE}
                    selected={date}
                    onChange={value => this.setState({ date: value })}
                    className="condition-input"
                    calendarClassName="calendar-dropdown"
                    disabledKeyboardNavigation
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    maxDate={addYears(endOfYear(new Date()), 10)}
                    yearDropdownItemNumber={100}
                    disabled={!isEditable}
                    inline
                  />
                </Suspense>
                <div className="calendar-buttons-wrapper">
                  <Button color="grey" variant="outlined" onClick={toggle}>
                    close
                  </Button>
                  <Button onClick={this.applyChanges} disabled={!date}>
                    apply
                  </Button>
                </div>
              </div>
            )}
            {type === "input" && (
              <div className="natural-language">
                <div data-testid="nat-land-field" className="nat-lang-form">
                  <input
                    type="text"
                    value={displayValue}
                    placeholder="Write date in natural language"
                    onChange={this.onNaturalLanguageFieldChange}
                    autoFocus
                    autoComplete="off"
                  />
                  <Button
                    onClick={this.applyChanges}
                    disabled={!displayValue}
                    className="nat-lang-submit-button"
                  >
                    <FontAwesomeIcon icon={["fas", "check"]} />
                  </Button>
                </div>
                <div className="help-box">
                  <p className="help-message">
                    For example: today, yesterday, friday, -5 days, 20 days
                  </p>
                  <p className="help-message">
                    See{" "}
                    <a
                      href="https://docs.meiro.io/books/meiro-business-explorer/page/segment-builder-date-datetime-attributes#bkmrk-4.-how-natural-langu"
                      target="_blank"
                      rel="noreferrer"
                    >
                      documentation
                    </a>{" "}
                    for details.
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

CustomDatePicker.propTypes = {
  value: PropTypes.string,
  open: PropTypes.bool,
  isEditable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  dataType: PropTypes.string,
  withoutNL: PropTypes.bool,
}

export default CustomDatePicker

import { useMemo } from "react"
import { path as _path, assocPath } from "ramda"

import { isJSONString } from "helpers/validators.helper"
import {
  CUSTOM_DATA_DYNAMIC_FIELD_FORMAT,
  CUSTOM_DATA_DYNAMIC_FIELD_PATH_DELIMITER,
  CUSTOM_DATA_SINGLE_DYNAMIC_FIELD_PATH,
} from "sharedConstants"
import { JsonSchema } from "types/util"

const splitPath = (path: string) => path.split(CUSTOM_DATA_DYNAMIC_FIELD_PATH_DELIMITER)

export const useCustomDataDefaultValuesMemo = (schema: string | JsonSchema, data?: any) =>
  useMemo(() => {
    if (typeof schema === "string" && (!schema || !isJSONString(schema))) return {}

    let defaultValues: any = {}

    const traverseSchema = (schema: JsonSchema, path?: string) => {
      if (schema.type === "object")
        schema.properties &&
          Object.entries(schema.properties).forEach(([key, field]) =>
            traverseSchema(field, path ? `${path}.${key}` : key),
          )
      else {
        const fieldPath = path ?? CUSTOM_DATA_SINGLE_DYNAMIC_FIELD_PATH
        const arrFieldPath = splitPath(fieldPath)
        const dataValue = data ? _path(arrFieldPath, data) : null

        let defaultSchemaValue: any = null
        switch (schema.type) {
          case "string":
            defaultSchemaValue =
              schema.default ??
              ((schema.format &&
                [
                  CUSTOM_DATA_DYNAMIC_FIELD_FORMAT.DATE,
                  CUSTOM_DATA_DYNAMIC_FIELD_FORMAT.DATE_TIME,
                ].includes(schema.format)) ||
              schema.enum
                ? undefined
                : "")
            break
          case "boolean":
            defaultSchemaValue = schema.default ?? false
            break
          case "integer":
          case "number":
            defaultSchemaValue = schema.default ?? 0
            break
          default:
        }

        const defaultValue = dataValue ?? defaultSchemaValue
        defaultValues = assocPath(arrFieldPath, defaultValue, defaultValues)
      }
    }

    traverseSchema(typeof schema === "string" ? JSON.parse(schema) : schema)
    return defaultValues
  }, [schema, data])

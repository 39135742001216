import React, { PureComponent } from "react"
import { getRoutePath } from "routes"
import Paper from "components/UI/elements/Paper"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Select from "react-select"
import { DropdownIndicator } from "components/UI/elements/SelectField"
import { selectStyles } from "helpers/customSelectStyle.helper"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"
import DestinationAttributes from "pages/Data/Destinations/components/DestinationAttributes/DestinationAttributes"
import "./DestinationsList.scss"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import {
  useDeleteDestination,
  useFetchAllDestinations,
  useModifyDestination,
} from "resources/exportDestination/exportDestinationQueries"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import { Link } from "react-router-dom"
import Button from "components/UI/elements/Button/Button"
import { useFetchGlobalSettings } from "resources/globalSettings/globalSettingsQueries"
import { isNil } from "ramda"
import { useFetchWorkspaceOptions } from "resources/workspace/workspaceQueries"
import Table, { Name } from "components/Table/Table"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import create from "zustand"

class DestinationsList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      deleteModalState: {
        modalOpen: false,
        destination: null,
        isLoading: false,
      },
    }
  }

  goToDestinationDetail = destinationId => () => {
    this.props.history.push(
      getRoutePath("administration.destinations.detail", { id: destinationId }),
    )
  }

  changeDestinationWorkspace = destinationId => workspace => {
    this.props.modifyDestination({
      id: destinationId,
      data: {
        mi_workspace_id: workspace.value,
      },
    })
  }

  renderWorkspaceSelect = destination => {
    const { workspaces, isMiApiSet, areWorkspacesFulfilled } = this.props
    if (isMiApiSet && destination.id > 1000) {
      let selectedWorkspace = null
      if (destination.mi_workspace_id) {
        const foundWs = workspaces.find(ws => ws.value === destination.mi_workspace_id)
        selectedWorkspace = foundWs
          ? foundWs
          : { label: destination.mi_workspace_id, value: destination.mi_workspace_id }
      }
      return (
        <Select
          value={selectedWorkspace}
          onChange={this.changeDestinationWorkspace(destination.id)}
          options={workspaces}
          styles={selectStyles("tag")}
          simpleValue
          isSearchable={true}
          isLoading={!areWorkspacesFulfilled}
          className="select-input"
          components={{
            DropdownIndicator: DropdownIndicator,
          }}
          classNamePrefix="tag_select"
          noOptionsMessage={() => "Empty"}
        />
      )
    }
    return null
  }

  toggleDeleteModal =
    (newDestination = null) =>
    () => {
      if (this.state.deleteModalState.isLoading) return

      this.setState(({ deleteModalState: { isLoading, modalOpen, destination } }) => ({
        deleteModalState: {
          isLoading,
          modalOpen: !modalOpen,
          destination: newDestination ?? destination,
        },
      }))
    }

  deleteDestination = async () => {
    const { deleteModalState } = this.state
    const { deleteDestination } = this.props
    this.setState(prevState => ({
      deleteModalState: {
        ...prevState.deleteModalState,
        isLoading: true,
      },
    }))
    try {
      await deleteDestination({ id: deleteModalState.destination.id })
      this.setState(prevState => ({
        deleteModalState: {
          ...prevState.deleteModalState,
          isLoading: false,
          modalOpen: false,
        },
      }))
    } catch {
      this.setState(prevState => ({
        deleteModalState: {
          ...prevState.deleteModalState,
          isLoading: false,
        },
      }))
    }
  }

  render() {
    const { areDestinationsFulfilled, destinations, orderBy, orderDir, setSort } = this.props
    const { deleteModalState } = this.state

    const columns = [
      {
        id: "icon",
        gridTemplate: "min-content",
        renderCell: destination => (
          <div className="icon-container">
            <SrcDstIcon destination={destination} />
          </div>
        ),
      },
      {
        id: "id",
        label: "Id",
        gridTemplate: "min-content",
        renderCell: destination => <div>{destination.id}</div>,
      },
      {
        id: "name",
        label: "Name",
        gridTemplate: "1fr",
        renderCell: destination => <Name name={destination.name} />,
        onSort: () => setSort("name"),
      },
      {
        id: "exported",
        label: "Exported attributes",
        gridTemplate: "1fr",
        renderCell: destination => (
          <DestinationAttributes
            destination={destination}
            showAttributes="exported"
            showHiddenAttributes
          />
        ),
      },
      {
        id: "mandatory",
        label: "Mandatory attributes",
        gridTemplate: "1fr",
        renderCell: destination => (
          <DestinationAttributes
            destination={destination}
            showAttributes="mandatory"
            showHiddenAttributes
          />
        ),
      },
      {
        id: "workspace",
        label: (
          <>
            MI Workspace{" "}
            <InfoTooltip>
              Setting available only for certain destinations if MI connection is defined.
            </InfoTooltip>
          </>
        ),
        gridTemplate: "1fr",
        renderCell: this.renderWorkspaceSelect,
      },
      {
        id: "actions",
        gridTemplate: "max-content",
        renderCell: destination => (
          <div className="actions">
            <IconButton
              data-testid={`edit-button-${destination.id}`}
              color="black"
              size="xs"
              onClick={this.goToDestinationDetail(destination.id)}
              icon={["fas", "pencil-alt"]}
              tooltip="Edit"
              variant="outlined"
            />
            <IconButton
              data-testid={`delete-button-${destination.id}`}
              color="red"
              size="xs"
              onClick={this.toggleDeleteModal(destination)}
              className="left-margin"
              icon="trash-alt"
              tooltip="Delete"
              variant="outlined"
            />
          </div>
        ),
      },
    ]

    return (
      <Page className="setup-destinations" title="Destinations">
        {!areDestinationsFulfilled && <LoadingIndicator />}

        {areDestinationsFulfilled && (
          <Paper>
            <div className="trash-button-wrapper">
              <Link to={getRoutePath("administartion.destinations.trash")}>
                <Button type="button" color="grey" variant="outlined" icon={["far", "trash-alt"]}>
                  Trash
                </Button>
              </Link>
            </div>

            <Table data={destinations} columns={columns} sortBy={orderBy} sortDir={orderDir} />
          </Paper>
        )}
        <ConfirmModal
          open={deleteModalState.modalOpen}
          type={MODAL.TYPE.DELETE}
          handleClose={this.toggleDeleteModal()}
          handleConfirm={this.deleteDestination}
          title="Are you sure?"
          action="delete"
          what="destination"
          item={deleteModalState.destination?.name ?? ""}
          isLoading={deleteModalState.isLoading}
        />
      </Page>
    )
  }
}

const filtersInitialState = {
  orderBy: "name",
  orderDir: "ASC",
}

export const useFiltersStore = create(set => ({
  ...filtersInitialState,
  reset: () => set(filtersInitialState),
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
}))

export default props => {
  const { orderBy, orderDir, setSort } = useFiltersStore()

  const { data = [], isSuccess } = useFetchAllDestinations({ orderBy, orderDir })
  const { mutateAsync: modifyDestination } = useModifyDestination()
  const { mutateAsync: deleteDestination } = useDeleteDestination()
  const { data: globalSettings } = useFetchGlobalSettings()
  const isMiApiSet = !isNil(globalSettings?.["mi_api"]?.value)
  const { data: workspaces = [], isSuccess: areWorkspacesFulfilled } = useFetchWorkspaceOptions()

  return (
    <DestinationsList
      {...props}
      destinations={data}
      areDestinationsFulfilled={isSuccess}
      modifyDestination={modifyDestination}
      deleteDestination={deleteDestination}
      isMiApiSet={isMiApiSet}
      workspaces={workspaces}
      areWorkspacesFulfilled={areWorkspacesFulfilled}
      orderBy={orderBy}
      orderDir={orderDir}
      setSort={setSort}
    />
  )
}

import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SimpleBar from "simplebar-react"
import classNames from "classnames"
import { shortenMarkdownText } from "helpers/string.helper"
import { DATE_FMT } from "sharedConstants"
import IconButton from "components/UI/elements/IconButton/IconButton"
import SingleView from "./SingleView/SingleView"
import "./SegmentNotes.scss"
import Username from "components/Username/Username"
import { format } from "date-fns"
import { DBtimestampToDate } from "utilities/date"
import {
  useCreateSegmentNote,
  useDeleteSegmentNote,
  useFetchAllSegmentNotes,
  useModifySegmentNote,
} from "resources/segment/segmentNote/segmentNoteQueries"
import { SegmentNote as SegmentNoteType } from "resources/segment/segmentNote/segmentNoteTypes"
import { Segment } from "resources/segment/segment/segmentTypes"
import useKeyListener from "hooks/useKeyListener"
import { pick } from "ramda"

type SegmentNotesProps = {
  segmentId: Segment["id"]
  isEditable: boolean
  toggleNotes: () => void
  isOpen: boolean
}

export default function SegmentNotes({
  segmentId,
  isEditable,
  toggleNotes,
  isOpen,
}: SegmentNotesProps) {
  const [viewMode, setViewMode] = useState<"list" | "form">("list")
  const [selectedNote, setSelectedNote] = useState<SegmentNoteType>()

  useKeyListener("Escape", toggleNotes)

  const { data: segmentNotes = [], isLoading: isLoadingSegmentNotes } =
    useFetchAllSegmentNotes(segmentId)
  const { mutate: createNote, isLoading: isCreatingNote } = useCreateSegmentNote()
  const { mutate: modifyNote, isLoading: isModifyingNote } = useModifySegmentNote()

  const setListViewMode = () => {
    setViewMode("list")
    setSelectedNote(undefined)
  }

  return (
    <section className="notes">
      {!isLoadingSegmentNotes && typeof segmentNotes.length === "number" && (
        <div className="note-count-badge">{segmentNotes.length}</div>
      )}
      {isOpen && (
        <div>
          <div className={`notes-box ${viewMode === "form" ? "single-view" : ""}`}>
            {viewMode === "list" && (
              <div>
                <SimpleBar className="scroll">
                  {segmentNotes.map(note => (
                    <SegmentNote
                      {...note}
                      key={note.id}
                      excerpt={shortenMarkdownText(note.content, 80)}
                      isEditable={isEditable}
                      segmentId={segmentId}
                      selectNote={() => {
                        setSelectedNote(note)
                        setViewMode("form")
                      }}
                    />
                  ))}
                </SimpleBar>
                <button
                  className={`add-note ${segmentNotes.length === 0 ? "no-border-top" : ""}`}
                  onClick={() => setViewMode("form")}
                  disabled={!isEditable}
                >
                  Add note <FontAwesomeIcon icon={["fas", "plus-circle"]} />
                </button>
              </div>
            )}
            {viewMode === "form" && (
              <SingleView
                initialValues={selectedNote ? pick(["name", "content"], selectedNote) : undefined}
                onCancel={() => setListViewMode()}
                onSubmit={values => {
                  if (selectedNote)
                    modifyNote(
                      {
                        segmentId,
                        noteId: selectedNote.id,
                        data: values,
                      },
                      { onSuccess: () => setListViewMode() },
                    )
                  else
                    createNote(
                      {
                        segmentId,
                        data: values,
                      },
                      { onSuccess: () => setListViewMode() },
                    )
                }}
                isEditable={isEditable}
                isLoading={isCreatingNote || isModifyingNote}
              />
            )}
          </div>
        </div>
      )}
    </section>
  )
}

type SegmentNoteProps = SegmentNoteType & {
  excerpt: string
  isEditable: boolean
  segmentId: Segment["id"]
  selectNote: () => void
}

const SegmentNote = ({
  excerpt,
  id,
  modified,
  name,
  user_id,
  segmentId,
  isEditable,
  selectNote,
}: SegmentNoteProps) => {
  const { mutate, isLoading } = useDeleteSegmentNote()

  return (
    <div
      className={classNames("note-excerpt", { "is-deleting": isLoading })}
      key={id}
      onClick={() => {
        if (!isLoading) selectNote()
      }}
    >
      <h4>{name}</h4>
      {modified && (
        <p className="author-date">
          Last edit: <Username userId={user_id} />
          {", "}
          {format(DBtimestampToDate(modified), DATE_FMT.DATE)}
        </p>
      )}
      <p>{excerpt}</p>
      <div className="delete-column">
        <div className="center">
          <IconButton
            color="red"
            onClick={event => {
              event.stopPropagation()
              mutate({ segmentId, noteId: id })
            }}
            disabled={!isEditable || isLoading}
            icon="trash-alt"
            variant="transparent"
          />
        </div>
      </div>
    </div>
  )
}

import { pick } from "ramda"
import {
  EntryNodeEventSettings,
  EntryNodeSegmentSettings,
  EntryNodeSettings,
} from "resources/journey/journeyTypes"
import {
  eventConditionToFormValues,
  eventConditionToSettings,
} from "../../components/EventCondition/formValues"
import { cronToScheduleField, ScheduleField, scheduleFieldToCron } from "./Schedule/formValues"

export type EntryNodeFormValues = EntryNodeEventFormValues | EntryNodeSegmentFormValues

export type EntryNodeEventFormValues = EntryNodeEventSettings
export type EntryNodeSegmentFormValues = Omit<EntryNodeSegmentSettings, "schedules"> & {
  schedules: ScheduleField[]
}

export function settingsToFormValues(settings: EntryNodeSettings): EntryNodeFormValues {
  if (settings.type === "event") {
    return eventEntrySettingsToFormValues(settings)
  }
  return segmentEntrySettingsToFormValues(settings)
}

export function formValuesToSettings(values: EntryNodeFormValues): EntryNodeSettings {
  if (values.type === "event") {
    return eventEntryFormValuesToSettings(values)
  }
  return segmentEntryFormValuesToSettings(values)
}

function eventEntrySettingsToFormValues(
  settings: EntryNodeEventSettings,
): EntryNodeEventFormValues {
  return {
    ...settings,
    conditions_operation: settings.conditions_operation.map(eventConditionToFormValues),
  }
}

function eventEntryFormValuesToSettings(values: EntryNodeEventFormValues): EntryNodeEventSettings {
  return {
    ...pick(["type", "event_id", "operator"], values),
    conditions_operation: values.conditions_operation?.map(eventConditionToSettings),
  }
}

function segmentEntrySettingsToFormValues(
  settings: EntryNodeSegmentSettings,
): EntryNodeSegmentFormValues {
  return {
    ...settings,
    schedules: settings.schedules.map(cronToScheduleField),
  }
}

function segmentEntryFormValuesToSettings(
  values: EntryNodeSegmentFormValues,
): EntryNodeSegmentSettings {
  return {
    ...pick(["type", "segment_id"], values),
    schedules: values.schedules?.map(scheduleFieldToCron),
  }
}

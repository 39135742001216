import React from "react"
import styles from "./ConditionBranch.module.scss"
import {
  AndOrCondition,
  ConditionComponent as ConditionComponentType,
  ConditionPath,
  ConditionTree,
} from "../../../../types/conditionTree"
import { assocPath as set, equals, last, path as get } from "ramda"
import { isAndOrCondition, pathToId } from "../../utils"
import OperatorToggle from "../OperatorToggle/OperatorToggle"
import {
  canIndent,
  canUnindent,
  duplicateCondition,
  indent,
  isWithinPath,
  removeCondition,
  toggleOperator,
  unindent,
} from "../../treeMethods"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import SortableItem from "../SortableItem/SortableItem"
import { getSymbol } from "components/ConditionBuilder/treeSymbols"
import classNames from "classnames"

type ConditionBranchProps<T> = {
  conditionTree: ConditionTree<T>
  onChange: (conditionTree: ConditionTree<T> | null) => void
  path: ConditionPath
  conditionComponent: ConditionComponentType<T>
  isEditable: boolean
  error?: ConditionTree<any>
  insertPath: ConditionPath | null
  highlightedPath?: ConditionPath
  onHover?: (conditionPath?: ConditionPath) => void
}

export default function ConditionBranch<T>({
  conditionTree,
  onChange,
  path,
  conditionComponent: ConditionComponent,
  isEditable,
  error,
  insertPath,
  highlightedPath,
  onHover,
}: ConditionBranchProps<T>) {
  const { operands, operator } = get<AndOrCondition<T>>(path, conditionTree)!
  const itemPaths = operands.map<ConditionPath>((_, i) => [...path, "operands", i])
  const itemIds = itemPaths.map(pathToId)
  const isInsertingInThisArray = equals(insertPath?.slice(0, -2), path)
  const insertIndex = insertPath && (last(insertPath) as number)

  const showInsertBarBefore = (index: number) =>
    isInsertingInThisArray &&
    index === insertIndex &&
    (insertIndex === 0 || isAndOrCondition(operands[index - 1]))

  const showInsertBarAfter = (index: number) => isInsertingInThisArray && index + 1 === insertIndex

  return (
    <div className={classNames(styles.container, { [styles.isTopLevel]: path.length === 0 })}>
      <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
        {operands.map((operand, index) => {
          const itemPath = itemPaths[index]
          const itemId = itemIds[index]
          return (
            <React.Fragment key={`${JSON.stringify(operand)}_${index}`}>
              {isAndOrCondition(operand) ? (
                <>
                  <ConditionBranch<T>
                    conditionTree={conditionTree}
                    onChange={onChange}
                    path={itemPath}
                    conditionComponent={ConditionComponent}
                    isEditable={isEditable}
                    error={error}
                    insertPath={insertPath}
                    highlightedPath={highlightedPath}
                    onHover={onHover}
                  />
                </>
              ) : (
                <>
                  <div
                    className={classNames(styles.insertBar, styles.top, {
                      [styles.visible]: showInsertBarBefore(index),
                    })}
                  ></div>

                  <SortableItem
                    id={itemId}
                    render={({ listeners }) => (
                      <ConditionComponent
                        condition={operand}
                        onChange={condition => {
                          onChange(set(itemPath, condition, conditionTree))
                        }}
                        removeSelf={() => onChange(removeCondition(itemPath, conditionTree))}
                        duplicateSelf={() => onChange(duplicateCondition(itemPath, conditionTree))}
                        dragListeners={listeners}
                        symbol={getSymbol(itemPath, conditionTree)}
                        isEditable={isEditable}
                        error={get(itemPath, error)}
                        highlighted={isWithinPath(highlightedPath, itemPath)}
                        greyedOut={
                          highlightedPath ? !isWithinPath(highlightedPath, itemPath) : false
                        }
                        hoverSelf={hovered => (hovered ? onHover?.(itemPath) : onHover?.())}
                      />
                    )}
                  />

                  <div
                    className={classNames(styles.insertBar, styles.bottom, {
                      [styles.visible]: showInsertBarAfter(index),
                    })}
                  ></div>
                </>
              )}
              {index < operands.length - 1 && (
                <OperatorToggle
                  operator={operator}
                  onToggle={() => onChange(toggleOperator(itemPath, conditionTree))}
                  onIndent={() => onChange(indent(itemPath, conditionTree))}
                  onUnindent={() => onChange(unindent(itemPath, conditionTree))}
                  canIndent={canIndent(itemPath, conditionTree)}
                  canUnindent={canUnindent(itemPath)}
                  disabled={!isEditable}
                  greyedOut={highlightedPath ? !isWithinPath(highlightedPath, path) : false}
                />
              )}
            </React.Fragment>
          )
        })}
      </SortableContext>
    </div>
  )
}

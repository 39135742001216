import React, { useContext, useState } from "react"
import { EntryNodeSettings, EntryNodeType, Journey } from "resources/journey/journeyTypes"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ModalWrapper from "components/UI/elements/ModalWrapper/ModalWrapper"
import { useModifyJourneyNode } from "resources/journey/journeyQueries"
import EntryNodeForm from "./EntryNodeForm"
import { StatsContext } from "../../JourneyDetail"
import { getDescription } from "./getDescription"
import EntryNodeCard from "./EntryNodeCard"

type EntryNodeProps = {
  journeyId: Journey["id"]
  node: EntryNodeType
  isEditable: boolean
}

export default function EntryNode({ node, journeyId, isEditable }: EntryNodeProps) {
  const stats = useContext(StatsContext)?.[node.id]
  const [isModalOpen, setIsModalOpen] = useState(false)

  const modifyMutation = useModifyJourneyNode()
  function onSubmit(settings: EntryNodeSettings) {
    modifyMutation.mutate(
      { journeyId, nodeId: node.id, data: { settings } },
      {
        onSuccess() {
          setIsModalOpen(false)
        },
      },
    )
  }

  return (
    <>
      <EntryNodeCard
        description={getDescription(node.settings)}
        arrivedCount={stats?.arrived}
        actions={
          <IconButton
            onClick={() => {
              setIsModalOpen(true)
            }}
            icon={["far", isEditable ? "pencil-alt" : "eye"]}
            variant="transparent"
            color="grey"
            tooltip={isEditable ? "Edit" : "View"}
            size="xs"
          />
        }
      />
      <ModalWrapper isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {isModalOpen && (
          <EntryNodeForm
            isEditable={isEditable}
            isSubmitting={modifyMutation.isLoading}
            node={node}
            onClose={() => setIsModalOpen(false)}
            onSubmit={onSubmit}
          />
        )}
      </ModalWrapper>
    </>
  )
}

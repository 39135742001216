import React from "react"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import AttributeForm, { AttributeFormValues } from "../components/AttributeForm/AttributeForm"
import { getInitialAttributeFormValues } from "../components/AttributeForm/attributeInitialValues"
import "./AttributeCreate.scss"
import Page from "components/UI/Page/Page"
import { useHistory, useLocation } from "react-router-dom"
import { useCreateAttribute } from "resources/attribute/attributeQueries"
import { AttributeCreatePayload, AttributeFull } from "resources/attribute/attributeTypes"

export default function AttributeCreate() {
  const history = useHistory()
  const location = useLocation<{ attribute?: AttributeFull }>()
  const attribute = location.state?.attribute
  const createMutation = useCreateAttribute()
  const formInitValues = getInitialAttributeFormValues(attribute) as AttributeFormValues

  function createAttribute(data: AttributeCreatePayload) {
    createMutation.mutate(
      { data },
      {
        onSuccess() {
          history.push(getRoutePath("administration.attributes"))
        },
      },
    )
  }

  return (
    <Page
      className="create-attribute"
      title="Create attribute"
      headerContent={
        <div className="header-buttons">
          <Button
            color="grey"
            variant="outlined"
            onClick={() => {
              history.push(getRoutePath("administration.attributes"))
            }}
          >
            Cancel
          </Button>
          <Button loading={createMutation.isLoading} form="attributeForm" type="submit">
            Save
          </Button>
        </div>
      }
    >
      <AttributeForm onSubmit={createAttribute} initialValues={formInitValues} isCreate />
    </Page>
  )
}

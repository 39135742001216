import { isValid } from "date-fns"
import { path as _path, always, isEmpty, last } from "ramda"
import { FieldErrors, Validate, Message } from "react-hook-form"

import { ChannelFrequencyCapType, PushNotificationApp } from "./channelTypes"
import {
  email,
  max,
  min,
  maxLength,
  minLength,
  required,
  onlyValidAttributesUsed,
} from "helpers/validators.helper"
import { JsonSchema, SelectOption } from "types/util"
import { AttributeFull } from "resources/attribute/attributeTypes"
import {
  CUSTOM_DATA_DYNAMIC_FIELD_FORMAT,
  CUSTOM_DATA_DYNAMIC_FIELD_PATH_DELIMITER,
  CUSTOM_DATA_SINGLE_DYNAMIC_FIELD_PATH,
} from "sharedConstants"

const getFrequencyCapHourPeriodSize = (frequencyCap: ChannelFrequencyCapType) => {
  let periodSize = frequencyCap.period.size
  if (frequencyCap.period.type === "DAYS") periodSize *= 24
  return periodSize
}

const getFrequencyCapSize = (frequencyCap: ChannelFrequencyCapType) => {
  const periodSize = getFrequencyCapHourPeriodSize(frequencyCap)
  return frequencyCap.max_count / periodSize
}

export const isLocalFrequencyConflicting = (
  local: ChannelFrequencyCapType & { ignore_channel_frequency_cap: boolean },
  global: ChannelFrequencyCapType,
) => {
  if (local.ignore_channel_frequency_cap) return false
  return getFrequencyCapSize(local) > getFrequencyCapSize(global)
}

export const hasAndroidApp = (appIds: PushNotificationApp["app_ids"]) =>
  appIds.some(appId => appId.includes("android"))
export const hasIosApp = (appIds: PushNotificationApp["app_ids"]) =>
  appIds.some(appId => appId.includes("ios"))

const isOptionValid = (options: Array<SelectOption<string>>) => (value: string) =>
  options.find(option => option.value === value) ? undefined : "Is not a valid option"

const isTypeValid = (schema: JsonSchema) => (value: unknown) => {
  switch (schema.type) {
    case "string":
      if (
        schema.format &&
        [
          CUSTOM_DATA_DYNAMIC_FIELD_FORMAT.DATE,
          CUSTOM_DATA_DYNAMIC_FIELD_FORMAT.DATE_TIME,
        ].includes(schema.format)
      ) {
        if (typeof value !== "string") return "is not a date"

        return isValid(new Date(value)) ? undefined : "is not a date"
      }

      return typeof value === "string" ? undefined : "is not a string"
    case "number":
    case "integer":
      return typeof value === "number" ? undefined : "is not a number"
    case "boolean":
      return typeof value === "boolean" ? undefined : "is not a boolean"
    default:
  }
}

const isMessage = (value: unknown): value is Message => typeof value === "string"

export const jsonSchemaValidator = (
  schema: JsonSchema,
  data: any,
  attributesMap: Record<string, AttributeFull>,
) => {
  let errors: FieldErrors = {}

  const traverseSchema = ({
    path,
    schema,
    parentSchema,
  }: {
    schema: JsonSchema
    parentSchema?: JsonSchema
    path?: string
  }) => {
    if (schema.type === "object")
      schema.properties &&
        Object.entries(schema.properties).forEach(([key, field]) =>
          traverseSchema({
            schema: field,
            path: path ? `${path}.${key}` : key,
            parentSchema: schema,
          }),
        )
    else {
      const fieldPath = path ?? CUSTOM_DATA_SINGLE_DYNAMIC_FIELD_PATH
      const arrPath = fieldPath.split(CUSTOM_DATA_DYNAMIC_FIELD_PATH_DELIMITER)
      const name = last(arrPath)!
      const value = data ? _path(arrPath, data) : null

      let validate: Record<string, Validate<any, any>> = {
        required:
          Array.isArray(parentSchema?.required) && parentSchema?.required?.includes(name)
            ? required
            : always(undefined),
        typeValid: isTypeValid(schema),
      }

      switch (schema.type) {
        case "string":
          if (schema.enum)
            validate["isOption"] = isOptionValid(
              schema.enum!.map(val => ({ label: val, value: val })),
            )
          if (schema.maxLength) validate["maxLength"] = maxLength(schema.maxLength)
          if (schema.minLength) validate["minLength"] = minLength(schema.minLength)
          if (schema.format === "email") validate["email"] = email
          else
            validate["onlyValidAttributesUsed"] = (value: string) =>
              onlyValidAttributesUsed(value, attributesMap)

          break
        case "boolean":
          if (schema.maximum) validate["max"] = max(schema.maximum)
          if (schema.minimum) validate["min"] = min(schema.minimum)
          break
        default:
      }

      for (const key in validate) {
        const validationResult = validate[key](value, data)
        if (isMessage(validationResult))
          errors[fieldPath] = {
            type: key,
            message: validationResult,
          }

        if (validationResult) break
      }
    }
  }

  traverseSchema({ schema })
  return { errors, valid: isEmpty(errors) }
}

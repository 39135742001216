import React, { PropsWithChildren } from "react"
import { ReactSortable, SortableEvent } from "react-sortablejs"

type SortableGridProps = PropsWithChildren<{
  items: { id: string }[]
  onMove: (from: number, to: number) => void
  className?: string
}>

export default function SortableGrid({ children, items, onMove, className }: SortableGridProps) {
  function move(evt: SortableEvent) {
    const { oldIndex, newIndex } = evt

    if (oldIndex === newIndex || oldIndex === undefined || newIndex === undefined) {
      return
    }

    onMove(oldIndex, newIndex)
  }

  return (
    <ReactSortable
      list={items}
      setList={() => {}}
      animation={200}
      onUpdate={move}
      handle="[data-drag-handle]"
      className={className}
    >
      {children}
    </ReactSortable>
  )
}

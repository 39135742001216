import { QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { api } from "api"
import { assoc, update } from "ramda"
import { GlobalSetting, GlobalSettingModifyPayload, GlobalSettings } from "./globalSettingsTypes"

export const GLOBAL_SETTING_QK: QueryKey = ["global_settings"]

export function useFetchGlobalSettings() {
  return useQuery(GLOBAL_SETTING_QK, api.globalSettings.list, {
    select: ({ global_settings }) =>
      Object.fromEntries(
        global_settings.map(setting => [
          setting.key,
          {
            id: setting.id,
            value:
              // TODO: remove once BE fixes this
              setting.key === "maintenance_notifications_emails" &&
              typeof setting.value === "string"
                ? [setting.value]
                : setting.value,
          },
        ]),
      ) as GlobalSettings,
  })
}

export function useModifyGlobalSetting() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: GlobalSetting["id"]; data: GlobalSettingModifyPayload }) =>
      api.globalSettings.modify(id, data),
    {
      onSuccess({ global_setting }) {
        queryClient.setQueryData<{ global_settings: GlobalSetting[] }>(GLOBAL_SETTING_QK, data => {
          if (!data) {
            return
          }

          const index = data.global_settings.findIndex(({ id }) => id === global_setting.id)
          const newSettings =
            index === -1
              ? data.global_settings.concat(global_setting)
              : update(index, global_setting, data.global_settings)

          return assoc("global_settings", newSettings, data)
        })
      },
    },
  )
}

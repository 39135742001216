import React, { useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import SourceCreate from "./SourceCreate/SourceCreate"
import SourceDetail from "./SourceDetail/SourceDetail"
import SourcesList, { useFiltersStore } from "./SourcesList/SourcesList"

export default function SourcesSetup() {
  const { reset } = useFiltersStore()
  useEffect(() => reset, [reset])

  return (
    <Switch>
      <Route path={getRoutePath("administration.sources")} exact component={SourcesList} />
      <Route path={getRoutePath("administration.sources.create")} component={SourceCreate} />
      <Route path={getRoutePath("administration.sources.detail")} component={SourceDetail} />
    </Switch>
  )
}

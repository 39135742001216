import { useQuery } from "@tanstack/react-query"
import requestFactory from "api/request"
import { DateString } from "types/util"

type CustomersPerDateResponse = {
  customers_per_date: {
    date: DateString
    count: number
  }[]
  resolution: "hour" | "day" | "week" | "month" | "year"
  selection_settings: {
    start_date: DateString | null
    end_date: DateString | null
  }
}

const customersPerDate = (): Promise<CustomersPerDateResponse> =>
  requestFactory("get", "/customers_per_date")

export function useFetchCustomersPerDate() {
  return useQuery(["customersPerDate"], customersPerDate, {
    staleTime: 60 * 1000,
    select({ customers_per_date, resolution }) {
      return { data: customers_per_date.length ? customers_per_date : null, resolution }
    },
  })
}

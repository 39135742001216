import { useQuery } from "@tanstack/react-query"
import requestFactory from "api/request"

type UserCountsResponse = {
  users_stats: {
    total: number
    activated: number
    not_activated: number
    deleted: number
  }
}

const userCounts = (): Promise<UserCountsResponse> => requestFactory("get", "/users/users_stats")

export function useFetchUserCounts() {
  return useQuery(["userCounts"], userCounts, {
    staleTime: 60 * 1000,
    select({ users_stats }) {
      return users_stats
    },
  })
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ComponentPropsWithoutRef } from "react"
import NodeCard from "../../components/NodeCard/NodeCard"

const icon = <FontAwesomeIcon icon={["fas", "bell"]} color="#f9b549" />

export default function ActivatePushNodeCard(
  props: Omit<ComponentPropsWithoutRef<typeof NodeCard>, "title" | "icon">,
) {
  return <NodeCard title="Send mobile push" icon={icon} {...props} />
}

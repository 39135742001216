import { isMonday, formatISO, previousMonday, previousSunday } from "date-fns"
import { getISODateToday, getISODateDaysAgo } from "helpers/dateFormat.helper"
import { QuickOption } from "./FunnelsDataPicker.types"

export const DATE_OPTIONS: Array<QuickOption> = [
  {
    label: "Today",
    startDate: getISODateToday(),
    endDate: getISODateToday(),
  },
  {
    label: "Yesterday",
    startDate: getISODateDaysAgo(1),
    endDate: getISODateDaysAgo(1),
  },
  {
    label: "This week",
    startDate: isMonday(new Date())
      ? getISODateToday()
      : formatISO(previousMonday(new Date()), { representation: "date" }),
    endDate: getISODateToday(),
  },
  {
    label: "Last week",
    startDate: formatISO(previousMonday(previousSunday(new Date())), {
      representation: "date",
    }),
    endDate: formatISO(previousSunday(new Date()), { representation: "date" }),
  },
  {
    label: "Last 7 days",
    startDate: getISODateDaysAgo(6),
    endDate: getISODateToday(),
  },
  {
    label: "Last 28 days",
    startDate: getISODateDaysAgo(27),
    endDate: getISODateToday(),
  },
  {
    label: "Last 30 days",
    startDate: getISODateDaysAgo(29),
    endDate: getISODateToday(),
  },
  {
    label: "Last 90 days",
    startDate: getISODateDaysAgo(89),
    endDate: getISODateToday(),
  },
]

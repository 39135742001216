import React, { Component, useEffect, useState, useCallback } from "react"
import AuthenticatedRoutesSwitch from "../AuthenticatedRoutesSwitch/AuthenticatedRoutesSwitch"
import "./AuthenticatedLayout.scss"
import { socket, SocketContext } from "context/socket"
import { api } from "api"
import AppUpdateModal from "../AppUpdateModal/AppUpdateModal"
import NewReleaseModal from "../NewReleaseModal/NewReleaseModal"
import { Redirect, useLocation } from "react-router-dom"
import { getRoutePath } from "routes"
import {
  getCurrentUserId,
  refreshToken,
  refreshTokenTimestamp,
  useAuthTokenStore,
  verifyUser,
} from "auth/auth"
import { useFetchCurrentUser, useHasAccess } from "resources/user/currentUserQueries"
import { useAppVersionState } from "./appVersionState"
import NavBar from "../NavBar/NavBar"
import useClickOutHandler from "hooks/useClickOutHandler"
import classNames from "classnames"
import WholePageLoading from "pages/SsoCallback/WholePageLoading/WholePageLoading"
import { addNotification } from "resources/notification/notificationQueries"
import { prefetchAttributes } from "resources/attribute/attributeQueries"
import { NAV_BAR_COLLAPSE_BREAKPOINT } from "sharedConstants"

let JIRA_SD_DATA_KEY = ""
if (process.env.NODE_ENV === "production") {
  JIRA_SD_DATA_KEY = "[[JIRA_SD_DATA_KEY]]"
}

// 5 hours
const REFRESH_TOKEN_INTERVAL = 18000000

class AuthenticatedLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reportingEnabled: null,
      updateModalOpen: false,
    }
  }

  showUpdateModalIfOutdated = () => {
    api.systemInfo.get().then(response => {
      if (this.props.version !== response.system_info.version) {
        this.setState({
          updateModalOpen: true,
        })
      }
    })
  }

  componentDidMount() {
    const { setVersion, setBuildDate } = this.props

    verifyUser()
      .then(() => {
        api.systemInfo.get().then(response => {
          setVersion(response.system_info.version)
          setBuildDate(response.system_info.build_date)
          this.setState({
            reportingEnabled: response.system_info.reporting_enabled,
          })
        })

        let triedToRefreshTokenForWss = false
        socket.on("connect", () => {
          triedToRefreshTokenForWss = false
        })
        socket.on("connect_error", err => {
          if (err.message === "Connection rejected by server") {
            if (!triedToRefreshTokenForWss)
              refreshToken().then(() => {
                triedToRefreshTokenForWss = true
                socket.close()
                socket.open()
              })
          }
        })
        socket.on("notification", addNotification)
        socket.open()

        if (Date.now() - refreshTokenTimestamp >= REFRESH_TOKEN_INTERVAL) {
          refreshToken()
        }

        // check token every hour, check new version
        this.checkInterval = setInterval(() => {
          refreshToken()
          this.showUpdateModalIfOutdated()
        }, 3600000)

        // jira widget
        if (JIRA_SD_DATA_KEY) {
          const script = document.createElement("script")
          script.src = "https://jsd-widget.atlassian.com/assets/embed.js"
          script.async = true
          script.setAttribute("data-jsd-embedded", "")
          script.setAttribute("data-key", JIRA_SD_DATA_KEY)
          script.setAttribute("data-base-url", "https://jsd-widget.atlassian.com")
          document.body.appendChild(script)
          script.addEventListener("load", () => {
            window.document.dispatchEvent(
              new Event("DOMContentLoaded", {
                bubbles: true,
                cancelable: true,
              }),
            )
          })
        }
      })
      .catch(() => {})

    window.addEventListener("focus", verifyUser)
  }

  componentWillUnmount() {
    clearInterval(this.checkInterval)
    this.checkInterval = null
    if (socket && socket.connected) {
      socket.close()
    }
    window.removeEventListener("focus", verifyUser)
  }

  closeUpdateModal = () => {
    this.setState({
      updateModalOpen: false,
    })
  }

  render() {
    const { updateModalOpen, reportingEnabled } = this.state
    const { navProps, isLoadingCurrentUser } = this.props

    if (reportingEnabled === null || isLoadingCurrentUser) {
      return <WholePageLoading />
    }

    return (
      <div className="authenticated-layout">
        <SocketContext.Provider value={socket}>
          <NavBar reportingEnabled={reportingEnabled} navProps={navProps} />
          <div
            className={classNames("content-wrap", { navCollapsible: navProps.isNavCollapsible })}
          >
            <AuthenticatedRoutesSwitch />
          </div>
          <AppUpdateModal open={updateModalOpen} onClose={this.closeUpdateModal} />
          {!updateModalOpen && <NewReleaseModal />}
        </SocketContext.Provider>
      </div>
    )
  }
}

export default props => {
  const currentUserId = useAuthTokenStore(getCurrentUserId)
  const currentUser = useFetchCurrentUser()
  const hasAccess = useHasAccess()
  const { version, setVersion, setBuildDate } = useAppVersionState()
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    prefetchAttributes()
  }, [])

  // Navbar collapse/expand behavior
  //
  // Why is it up here?
  // - before the navbar redesign, the body was scrollable with overflow:auto
  // - the original implementation for the new navbar had the body not scrollable, the side navbar
  //   positioned normally, and the content scrollable with overflow:auto
  // - this broke the tooltips from cytoscape-popper used in the identity graph, because they assume
  //   the body is what is scrolled
  // - fixing the tooltips proved too hacky, so we chose the lesser hack: the body is going to be
  //   scrollable, the navbar is going to be position:fixed, and the content element gets a
  //   margin-left to clear the navbar
  // - the content element needs to know whether the navbar is collapsed or not to know how much
  //   margin-left to take, so the navbar collapse state has to live up here to be passed to the
  //   content element
  const isNarrowWindow = window.innerWidth < NAV_BAR_COLLAPSE_BREAKPOINT
  const [isNavCollapsible, setIsNavCollapsible] = useState(isNarrowWindow)
  const navProps = useClickOutHandler({
    initState: !isNarrowWindow,
    preventCloseIf: useCallback(() => !isNavCollapsible, [isNavCollapsible]),
  })
  const { close: collapseNav, open: expandNav } = navProps

  useEffect(() => {
    const listener = () => {
      if (window.innerWidth >= NAV_BAR_COLLAPSE_BREAKPOINT) {
        expandNav()
        setIsNavCollapsible(false)
      } else {
        collapseNav()
        setIsNavCollapsible(true)
      }
    }
    window.addEventListener("resize", listener)
    return () => window.removeEventListener("resize", listener)
  }, [collapseNav, expandNav])
  // END Navbar

  if (!currentUserId && window.location.pathname !== getRoutePath("sso.login")) {
    return (
      <Redirect
        to={{
          pathname: getRoutePath("login"),
          search: new URLSearchParams({ redirect: window.location.pathname }).toString(),
        }}
      />
    )
  }

  return (
    <AuthenticatedLayout
      {...props}
      currentUserId={currentUserId}
      hasAccess={hasAccess}
      version={version}
      setVersion={setVersion}
      setBuildDate={setBuildDate}
      navProps={{ ...navProps, isNavCollapsible }}
      isLoadingCurrentUser={currentUser.isLoading}
    />
  )
}

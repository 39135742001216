import create, { StateCreator } from "zustand"

import {
  ChannelCountError,
  ChannelSegmentCount,
  ChannelTotalCount,
  ChannelType,
} from "./channelTypes"
import { Segment } from "resources/segment/segment/segmentTypes"
import { socket } from "context/socket"
import { useEffect } from "react"
import { equals } from "ramda"

const channelStateCreator: StateCreator<{
  count: ChannelSegmentCount["count"]
  totalCount: ChannelTotalCount["count"]
  plusUpTo: ChannelSegmentCount["plus_up_to"]
  error?: ChannelCountError
  reset: () => void
  setCount: (count: ChannelSegmentCount["count"]) => void
  setTotalCount: (totalCount: ChannelTotalCount["count"]) => void
  setPlusUpTo: (plusUpTo: ChannelSegmentCount["plus_up_to"]) => void
  setError: (error: ChannelCountError) => void
}> = set => ({
  count: 0,
  totalCount: 0,
  plusUpTo: 0,
  reset: () =>
    set(() => ({
      count: 0,
      totalCount: 0,
    })),
  setCount: (count: ChannelSegmentCount["count"]) => set(() => ({ count })),
  setTotalCount: (totalCount: ChannelTotalCount["count"]) => set(() => ({ totalCount })),
  setPlusUpTo: (plusUpTo: ChannelSegmentCount["plus_up_to"]) => set(_ => ({ plusUpTo })),
  setError: (error: ChannelCountError) => set(() => ({ error })),
})

export const useEmailsChannelCountStore = create(channelStateCreator)
export const usePushNotificationsChannelCountStore = create(channelStateCreator)

export const emitSegmentChannelCounts = ({
  segmentIds,
  channelType,
  onAck,
}: {
  segmentIds: Array<Segment["id"]>
  channelType: ChannelType
  onAck?: () => void
}) => {
  if (segmentIds.length > 0)
    socket.emit(
      "channel_segment_counts",
      {
        channel_type: channelType,
        segment_ids: segmentIds,
      },
      () => {
        onAck?.()
      },
    )
}

export const useListenSegmentChannelCounts = (segmentIds: Array<Segment["id"]>) => {
  const {
    setCount: setEmailsChannelCustomersCount,
    setTotalCount: setEmailsChannelTotalCustomersCount,
    setPlusUpTo: setEmailsChannelPlusUpTo,
    setError: setEmailsChannelError,
  } = useEmailsChannelCountStore()

  const {
    setCount: setPushNotificationsChannelCustomersCount,
    setTotalCount: setPushNotificationsChannelTotalCustomersCount,
    setPlusUpTo: setPushNotificationsChannelPlusUpTo,
    setError: setPushNotificationsChannelError,
  } = usePushNotificationsChannelCountStore()

  useEffect(() => {
    socket.on("channel_segment_counts_response", (msg: ChannelSegmentCount | ChannelTotalCount) => {
      if (msg.count_type === "channel_results_count") {
        if (!equals(Array.from(msg.segment_ids).sort(), Array.from(segmentIds).sort())) return

        if (msg.error) {
          if (msg.channel_type === "emails") setEmailsChannelError(msg.error)
          else setPushNotificationsChannelError(msg.error)
        } else {
          if (msg.channel_type === "emails") {
            setEmailsChannelCustomersCount(msg.count)
            setEmailsChannelPlusUpTo(msg.plus_up_to)
          } else {
            setPushNotificationsChannelCustomersCount(msg.count)
            setPushNotificationsChannelPlusUpTo(msg.plus_up_to)
          }
        }
      } else {
        if (msg.error) {
          if (msg.channel_type === "emails") setEmailsChannelError(msg.error)
          else setPushNotificationsChannelError(msg.error)
        } else {
          if (msg.channel_type === "emails") setEmailsChannelTotalCustomersCount(msg.count)
          else setPushNotificationsChannelTotalCustomersCount(msg.count)
        }
      }
    })

    return () => {
      socket.off("channel_segment_counts_response")
    }
  }, [
    segmentIds,
    setEmailsChannelCustomersCount,
    setEmailsChannelError,
    setEmailsChannelPlusUpTo,
    setEmailsChannelTotalCustomersCount,
    setPushNotificationsChannelCustomersCount,
    setPushNotificationsChannelError,
    setPushNotificationsChannelPlusUpTo,
    setPushNotificationsChannelTotalCustomersCount,
  ])
}

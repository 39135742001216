import styles from "./FunnelGroupDetail.module.scss"
import ConfirmModal from "components/UI/components/ConfirmModal"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useState } from "react"
import { getRoutePath } from "routes"
import Page from "components/UI/Page/Page"
import {
  useDeleteFunnelGroup,
  useFetchFunnelGroup,
  useModifyFunnelGroup,
} from "resources/funnelGroup/funnelGroupQueries"
import { FunnelGroup } from "resources/funnelGroup/funnelGroupTypes"
import FunnelGroupForm, {
  FunnelGroupFormValues,
} from "../components/FunnelGroupForm/FunnelGroupForm"
import { Redirect, useHistory, useParams } from "react-router-dom"
import Button from "components/UI/elements/Button/Button"
import { equals, isEmpty, pick } from "ramda"
import { diffPermissionsLists } from "resources/funnelGroupPermission/diffPermissionsLists"
import {
  useFetchFunnelGroupPermissions,
  useModifyFunnelGroupPermissions,
} from "resources/funnelGroupPermission/funnelGroupPermissionQueries"
import { showToast } from "app/toast"

export default function () {
  const history = useHistory()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { id } = useParams<{ id: FunnelGroup["id"] }>()
  const funnelGroupQuery = useFetchFunnelGroup(id)
  const modifyMutation = useModifyFunnelGroup()
  const deleteMutation = useDeleteFunnelGroup()
  const permissionsQuery = useFetchFunnelGroupPermissions(id)
  const modifyPermissionsMutation = useModifyFunnelGroupPermissions()

  async function modifyFunnelGroup({ funnelGroup, acl }: FunnelGroupFormValues) {
    let modifyPromise
    if (!equals(pick(["name", "disabled"], funnelGroupQuery.data), funnelGroup)) {
      modifyPromise = modifyMutation.mutateAsync({ id, data: funnelGroup })
    }

    const permissionsList = Object.entries(acl).map(([user_role_id, permission]) => ({
      user_role_id: +user_role_id,
      permission,
    }))

    let modifyPermissionsPromise
    const diff = diffPermissionsLists(
      permissionsQuery.data?.funnel_group_user_roles!,
      permissionsList,
    )
    if (!isEmpty(diff.create) || !isEmpty(diff.modify) || !isEmpty(diff.delete)) {
      modifyPermissionsPromise = modifyPermissionsMutation.mutateAsync({ id, data: diff })
    }

    await Promise.all([modifyPromise, modifyPermissionsPromise])

    showToast("Funnel group modified.")
    history.push(getRoutePath("administration.funnel-groups"))
  }

  function deleteFunnelGroup() {
    deleteMutation.mutate(
      { id },
      {
        onSuccess() {
          history.push(getRoutePath("administration.funnel-groups"))
        },
      },
    )
  }

  if (funnelGroupQuery.isLoading || (permissionsQuery.isLoading && permissionsQuery.isFetching)) {
    return (
      <Page title="Edit funnel group">
        <LoadingIndicator />
      </Page>
    )
  }

  if (!funnelGroupQuery.data || !permissionsQuery.data) {
    return <Redirect to={getRoutePath("not-found")} />
  }

  return (
    <Page
      title="Edit funnel group"
      headerContent={
        <div className={styles.buttons}>
          <Button
            color="red"
            icon="trash-alt"
            variant="outlined"
            onClick={() => setDeleteModalOpen(true)}
          >
            Delete
          </Button>
          <Button
            color="grey"
            variant="outlined"
            onClick={() => history.push(getRoutePath("administration.funnel-groups"))}
          >
            Cancel
          </Button>
          <Button loading={modifyMutation.isLoading} form="funnelGroupForm" type="submit">
            Save
          </Button>
        </div>
      }
    >
      <FunnelGroupForm
        funnelGroup={funnelGroupQuery.data}
        acl={permissionsQuery.data.funnel_group_user_roles}
        onSubmit={modifyFunnelGroup}
      />
      <ConfirmModal
        title="Are you sure?"
        open={deleteModalOpen}
        type="delete"
        text={`Do you really want to delete funnel group?`}
        handleClose={() => setDeleteModalOpen(false)}
        handleConfirm={deleteFunnelGroup}
        isLoading={deleteMutation.isLoading}
      />
    </Page>
  )
}

import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Prompt, useHistory } from "react-router-dom"

import Button from "components/UI/elements/Button/Button"
import ChannelPaper from "../components/ChannelPaper/ChannelPaper"
import CreateStitchingCategoryModal from "components/CreateStitchingCategoryModal/CreateStitchingCategoryModal"
import SelectField from "components/UI/elements/SelectField"
import { required } from "helpers/validators.helper"
import { useModifyPushNotificationsChannelStitchingCategory } from "resources/channel/channelQueries"
import {
  ModifyPushNotificationsChannelStitchingCategoryPayload,
  PushNotificationsChannel,
} from "resources/channel/channelTypes"
import { useFetchAllStitchingCategories } from "resources/stitchingCategory/stitchingCategoryQueries"
import { getRoutePath } from "routes"

import styles from "./StitchingCategory.module.scss"

type StitchingCategoryFormValues = ModifyPushNotificationsChannelStitchingCategoryPayload

type StitchingCategoryProps = Pick<PushNotificationsChannel, "stitching_category_id"> & {
  isWizard?: boolean
}
export default function StitchingCategory({
  stitching_category_id,
  isWizard = false,
}: StitchingCategoryProps) {
  const [modalOpen, setModalOpen] = useState(false)

  const history = useHistory()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitted, isSubmitting },
  } = useForm<StitchingCategoryFormValues>({
    defaultValues: { stitching_category_id: stitching_category_id ?? "" },
  })

  const { data: stitchingCategories = [], isLoading: isLoadingStitchingCategories } =
    useFetchAllStitchingCategories()

  const { mutate, isLoading } = useModifyPushNotificationsChannelStitchingCategory()

  const onSubmit = (formValues: StitchingCategoryFormValues) => {
    if (isLoading) return

    mutate(
      { data: formValues },
      {
        onSuccess: () => {
          if (isWizard) history.push(getRoutePath("administration.channels"))
        },
      },
    )
  }

  const closeModal = () => setModalOpen(false)

  const stitchingCategoryAlreadySet = !!stitching_category_id
  const hasUnsavedChanges = isDirty && !isSubmitting && !isSubmitted

  return (
    <>
      <Prompt
        when={isDirty && !isSubmitting && !isSubmitted}
        message="Changes you made will not be saved."
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ChannelPaper
          title="FCM registration token stitching category"
          description="Once the FCM registration token stitching category is selected, it cannot be changed
          later on as the system stitching rules are created inside it."
          hasUnsavedChanges={hasUnsavedChanges}
          isSubmitting={isLoading}
          isWizard={isWizard}
          submitDisabled={stitchingCategoryAlreadySet}
        >
          <div className={styles.root}>
            <Controller
              control={control}
              name="stitching_category_id"
              rules={{ validate: { required } }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <div className={styles.selectFieldWrapper}>
                  <SelectField
                    isSimpleValue
                    disabled={stitchingCategoryAlreadySet}
                    isLoading={isLoadingStitchingCategories}
                    input={{ value, onChange }}
                    label="FCM registration token stitching category"
                    meta={{
                      touched: true,
                      error: error?.message,
                    }}
                    noOptionsMessage="No attribute matches the given criteria."
                    options={stitchingCategories.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    }))}
                    placeholder="Type to search..."
                  />
                </div>
              )}
            />
            <Button
              disabled={stitchingCategoryAlreadySet}
              icon="circle-plus"
              size="md"
              onClick={() => setModalOpen(true)}
            >
              Add stitching category
            </Button>
          </div>
        </ChannelPaper>
      </form>
      <CreateStitchingCategoryModal
        open={modalOpen}
        onClose={closeModal}
        onConfirm={({ id }) => {
          setValue("stitching_category_id", id, { shouldDirty: true })
          closeModal()
        }}
      />
    </>
  )
}

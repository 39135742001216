import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Attribute } from "resources/attribute/attributeTypes"
import { copyStringToClipboard } from "helpers/string.helper"
import { showToast } from "app/toast"
import Tippy from "@tippyjs/react"
import useHover from "hooks/useHover"

import styles from "./AttributeId.module.scss"

const AttributeId = ({ id }: { id: Attribute["id"] }) => {
  const [hoverRef, isHovered] = useHover<HTMLParagraphElement>()

  return (
    <Tippy content="Copy to clipboard" placement="bottom">
      <div
        ref={hoverRef}
        onClick={() => {
          copyStringToClipboard(id)
          showToast("Copied to clipboard", "TOAST_SUCCESS", "", true)
        }}
        className={styles.attributeId}
      >
        {isHovered ? (
          <FontAwesomeIcon icon={["fas", "copy"]} className={styles.idWidth} />
        ) : (
          <span className={styles.idWidth}>ID:</span>
        )}
        {id}
      </div>
    </Tippy>
  )
}

export default AttributeId

import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import { useState } from "react"
import TrashDataTable from "./components/TrashDataTable/TrashDataTable"
import { TrashItem, TrashItemType } from "../../types/trash"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"
import getReadableItemType from "./utilities/getReadableItemType"
import Page from "components/UI/Page/Page"
import styles from "./Trash.module.scss"
import useDebounce from "hooks/useDebounce"
import SearchField from "components/UI/elements/SearchField"
import { UseInfiniteQueryResult, UseMutationResult } from "@tanstack/react-query"
import Modal from "components/UI/elements/Modal"
import SelectField from "components/UI/elements/SelectField"
import { FunnelGroup } from "resources/funnelGroup/funnelGroupTypes"
import { useFetchAllFunnelGroups } from "resources/funnelGroup/funnelGroupQueries"
import Button from "components/UI/elements/Button/Button"

type RestorationModalState = {
  isOpen: boolean
  trashItem?: TrashItem
}

type TrashProps = {
  backRoute: string
  itemType: TrashItemType
  fetchQuery: (
    searchTerm: string,
    ...args: any[]
  ) => Omit<UseInfiniteQueryResult<any, string>, "data"> & { data: Array<TrashItem> }
  restoreMutation: () => UseMutationResult<
    any,
    unknown,
    { id: TrashItem["id"]; groupId?: string },
    unknown
  >
}

export default function Trash({ backRoute, itemType, fetchQuery, restoreMutation }: TrashProps) {
  const [restorationModal, setRestorationModal] = useState<RestorationModalState>({
    isOpen: false,
  })

  const openRestorationModal = (trashItem: TrashItem) => {
    setRestorationModal({
      isOpen: true,
      trashItem,
    })
  }

  const closeRestorationModal = () => {
    setRestorationModal(prevState => ({ ...prevState, isOpen: false }))
  }

  const { mutate, isLoading: isRestoring } = restoreMutation()

  const confirmRestorationModal = () => {
    if (!restorationModal.trashItem || isRestoring) return
    const { id } = restorationModal.trashItem

    mutate(
      { id, groupId: groupIdToRestore ?? undefined },
      {
        onSuccess: () => {
          closeRestorationModal()
        },
      },
    )
  }

  const [searchText, setSearchText] = useState("")
  const debouncedSearchTerm = useDebounce(searchText)

  const { data, hasNextPage, isFetchingNextPage, isLoading, fetchNextPage } =
    fetchQuery(debouncedSearchTerm)

  const [groupIdToRestore, setGroupIdToRestore] = useState<FunnelGroup["id"] | null>(null)
  const groupsQuery = useFetchAllFunnelGroups({ includeDisabled: false })

  return (
    <Page
      title={`Deleted ${getReadableItemType(itemType, { capitalize: false, plural: true })}`}
      headerContent={
        <SearchField
          input={{ value: searchText, onChange: setSearchText }}
          placeholder="Search for name"
          onClear={() => setSearchText("")}
          fullWidth={false}
          autoFocus
          wrapperClassName={styles.searchFieldWrapper}
        />
      }
      backRouteFallback={backRoute}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Paper>
          <TrashDataTable
            data={data}
            hasMoreDataToLoad={Boolean(hasNextPage)}
            itemType={itemType}
            onRestoreClick={openRestorationModal}
            onLoadMore={fetchNextPage}
            isLoadingMore={isFetchingNextPage}
          />
        </Paper>
      )}
      {itemType === "charts" ? (
        <Modal
          open={restorationModal.isOpen}
          handleClose={closeRestorationModal}
          title="Restore chart"
          contentClassName={styles.modalContent}
        >
          <div>Select the chart group where the copy will be restored:</div>
          <SelectField
            input={{ value: groupIdToRestore, onChange: setGroupIdToRestore }}
            options={groupsQuery.data?.map(group => ({ value: group.id, label: group.name })) ?? []}
            isSimpleValue
          />
          <div className={styles.modalButtons}>
            <Button variant="text" color="grey" onClick={closeRestorationModal}>
              Cancel
            </Button>
            <Button
              disabled={!groupIdToRestore || isRestoring}
              onClick={confirmRestorationModal}
              loading={isRestoring}
            >
              Restore
            </Button>
          </div>
        </Modal>
      ) : (
        <ConfirmModal
          open={restorationModal.isOpen}
          type={MODAL.TYPE.SUCCESS}
          handleClose={closeRestorationModal}
          handleConfirm={confirmRestorationModal}
          title="Restore item"
          action="restore"
          what={
            restorationModal.trashItem?.type
              ? getReadableItemType(restorationModal.trashItem.type, { capitalize: false })
              : ""
          }
          item={restorationModal.trashItem?.name}
          isLoading={isRestoring}
        />
      )}
    </Page>
  )
}

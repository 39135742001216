import React from "react"
import TagTextField from "components/UI/elements/TagTextField/TagTextField"
import ColorRadioGroup from "components/UI/components/ColorRadioGroup"
import { required } from "helpers/validators.helper"
import { OPTION_GROUP_COLORS } from "sharedConstants"
import { Controller, useForm } from "react-hook-form"

export default function TagForm({ onSubmit, initialValues, isCreate }) {
  const { control, handleSubmit, watch } = useForm({ defaultValues: initialValues })
  const selectedColor = watch("color")

  return (
    <form id="tagForm" className="tag-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className="custom-text-field">
        <Controller
          name="name"
          control={control}
          rules={{ validate: required }}
          render={({ field, fieldState: { error } }) => (
            <TagTextField
              input={field}
              meta={{ touched: true, error: error?.message }}
              placeholder="Tag name"
              label={isCreate ? "Create tag" : "Modify tag"}
              color={selectedColor ?? "white"}
              autoFocus
            />
          )}
        />
      </div>
      <div className="colors-buttons">
        <div className="colors-box">
          <p className="label-like">Pick color</p>
          <Controller
            name="color"
            control={control}
            rules={{ validate: required }}
            render={({ field, fieldState: { error } }) => (
              <ColorRadioGroup
                input={field}
                meta={{ touched: true, error: error?.message }}
                colors={OPTION_GROUP_COLORS}
              />
            )}
          />
        </div>
      </div>
    </form>
  )
}

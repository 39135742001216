import React, { useState } from "react"
import styles from "./ConditionNodeForm.module.scss"
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import NodeFormLayout from "../../components/NodeFormLayout/NodeFormLayout"
import { ConditionNodeType, ConditionNodeSettings } from "resources/journey/journeyTypes"
import { dec } from "ramda"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import EventConditionPath from "./EventConditionPath/EventConditionPath"
import ConditionBuilder from "components/ConditionBuilder/ConditionBuilder"
import SegmentConditionComponent from "pages/Segments/components/SegmentDetail/components/SegmentCondition/SegmentCondition"
import {
  SegmentCondition,
  SegmentConditionError,
} from "resources/segment/segment/segmentConditionsTypes"
import { ConditionTree } from "types/conditionTree"
import { validateSegmentCondition } from "pages/Segments/components/SegmentDetail/components/SegmentCondition/validation"
import { makeConditionTreeValidator } from "components/ConditionBuilder/validation"
import { required } from "helpers/validators.helper"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import {
  AttributeConditionPathFormValues,
  ConditionNodeFormValues,
  EventConditionPathFormValues,
  formValuesToSettings,
  settingsToFormValues,
} from "./formValues"
import { useGetDescription } from "./useGetDescription"
import ConditionNodeCard from "./ConditionNodeCard"

type ConditionNodeFormProps = {
  node?: ConditionNodeType
  onSubmit: (data: ConditionNodeSettings) => void
  onClose: () => void
  isSubmitting: boolean
  isEditable: boolean
}

const MAX_PATHS = 4

const validateConditionTree = makeConditionTreeValidator(validateSegmentCondition)

const getNewCondition = (): SegmentCondition => ({
  attribute_id: null,
  negation: false,
  condition: { operation: null },
})

const getNewEventConditionPath = (): EventConditionPathFormValues => ({
  type: "event",
  event_id: "",
  since: { unit: "minutes", count: NaN },
  operator: "and",
  conditions_operation: [],
})

const getNewAttributeConditionPath = (): AttributeConditionPathFormValues => ({
  type: "attribute",
  conditions_operation: getNewCondition(),
})

export default function ConditionNodeForm({
  node,
  onClose,
  onSubmit,
  isSubmitting,
  isEditable,
}: ConditionNodeFormProps) {
  const defaultValues = node?.settings
    ? settingsToFormValues(node.settings)
    : { paths: [getNewEventConditionPath()] }

  const methods = useForm<ConditionNodeFormValues>({ defaultValues })
  const {
    control,
    handleSubmit,
    resetField,
    watch,
    formState: { errors },
  } = methods
  const {
    fields: paths,
    append: appendPath,
    remove: removePath,
  } = useFieldArray({ control, name: "paths" })

  function submit(values: ConditionNodeFormValues) {
    onSubmit(formValuesToSettings(values))
  }

  const [selectedIndex, setSelectedIndex] = useState(0)

  const values = watch()

  // react-hook-form can only work with string errors
  let segmentConditionsError: ConditionTree<SegmentConditionError | null> = null
  try {
    const stringifiedSegmentConditionsError =
      errors?.paths?.[selectedIndex]?.conditions_operation?.message

    if (stringifiedSegmentConditionsError) {
      segmentConditionsError = JSON.parse(stringifiedSegmentConditionsError)
    }
  } catch {}

  return (
    <NodeFormLayout
      previewCard={
        <ConditionNodeCard description={useGetDescription(formValuesToSettings(values))} />
      }
      onClose={onClose}
      onSubmit={handleSubmit(submit)}
      isSubmitting={isSubmitting}
      isEditable={isEditable}
      onClickBack={onClose}
      width="lg"
    >
      <div className={styles.container}>
        <div className={styles.tabs}>
          {paths.map((_, index) => (
            <Button
              key={index}
              onClick={() => setSelectedIndex(index)}
              variant={selectedIndex === index ? "solid" : "outlined"}
              color={selectedIndex === index ? "primary" : "grey"}
              className={styles.tab}
            >
              Condition {index + 1}
              {paths.length > 1 && (
                <IconButton
                  variant="transparent"
                  color={selectedIndex === index ? "white" : "grey"}
                  onClick={e => {
                    e.stopPropagation()
                    removePath(index)
                    if (selectedIndex === paths.length - 1) {
                      setSelectedIndex(dec)
                    }
                  }}
                  icon={["fas", "times"]}
                  className={styles.removeButton}
                />
              )}
            </Button>
          ))}
          {isEditable && paths.length < MAX_PATHS && (
            <Button
              onClick={() => {
                appendPath(getNewEventConditionPath())
                setSelectedIndex(paths.length)
              }}
              variant="outlined"
              color="grey"
              className={styles.addTabButton}
            >
              <FontAwesomeIcon icon={["far", "plus"]} />
            </Button>
          )}
        </div>

        <div className={styles.tabContent}>
          <Controller
            control={control}
            name={`paths.${selectedIndex}.type`}
            rules={{ validate: { required } }}
            key={selectedIndex}
            render={({ field }) => (
              <>
                <ToggleSwitch
                  leftValue="event"
                  leftLabel="Event"
                  rightValue="attribute"
                  rightLabel="Attribute"
                  width="190px"
                  className={styles.typeSwitch}
                  disabled={!isEditable}
                  checked={field.value}
                  handleToggle={() => {
                    const value = field.value === "event" ? "attribute" : "event"

                    resetField(`paths.${selectedIndex}`, {
                      defaultValue:
                        value === "event"
                          ? getNewEventConditionPath()
                          : getNewAttributeConditionPath(),
                    })

                    field.onChange(value)
                  }}
                />

                {field.value === "event" && (
                  <FormProvider {...methods}>
                    <EventConditionPath pathIndex={selectedIndex} isEditable={isEditable} />
                  </FormProvider>
                )}

                {field.value === "attribute" && (
                  <Controller
                    control={control}
                    name={`paths.${selectedIndex}.conditions_operation`}
                    rules={{
                      validate: {
                        isValidTree(value) {
                          const result = validateConditionTree(
                            value as ConditionTree<SegmentCondition>,
                          )
                          return result ? JSON.stringify(result) : undefined
                        },
                        required: required,
                      },
                    }}
                    render={({ field }) => (
                      <ConditionBuilder<SegmentCondition, SegmentConditionError | null>
                        conditionComponent={props => (
                          <SegmentConditionComponent {...props} hideNumbers />
                        )}
                        conditionTree={field.value as ConditionTree<SegmentCondition>}
                        onChange={field.onChange}
                        isEditable={isEditable}
                        error={segmentConditionsError}
                        getNewCondition={getNewCondition}
                        minOneCondition
                      />
                    )}
                  />
                )}
              </>
            )}
          />
        </div>
      </div>
    </NodeFormLayout>
  )
}

import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import { api } from "api"
import { equals, move, update, whereEq } from "ramda"
import { Report, ReportCreatePayload, ReportModifyPayload } from "./reportTypes"

const REPORT_ALL_QK: QueryKey = ["report", "all"]

function useReportsQuery<T>(config?: UseQueryOptions<Report[], unknown, T, QueryKey>) {
  return useQuery(REPORT_ALL_QK, api.report.listAll, config)
}

export function useFetchAllReports() {
  return useReportsQuery<Report[]>()
}

export function useFetchAllEnabledReports({ enabled }: { enabled?: boolean } = {}) {
  return useReportsQuery({
    enabled,
    select: reports => reports.filter(({ disabled }) => !disabled),
  })
}

export function useFetchReportById(id: Report["id"]) {
  return useReportsQuery({ select: data => data.find(whereEq({ id })) ?? null })
}

export function useCreateReport() {
  const queryClient = useQueryClient()

  return useMutation(({ data }: { data: ReportCreatePayload }) => api.report.create(data), {
    onSuccess({ report }) {
      queryClient.setQueryData<Report[]>(REPORT_ALL_QK, data => {
        return data?.concat(report)
      })
      showToast("Report created.")
    },
  })
}

export function useModifyReport() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: Report["id"]; data: ReportModifyPayload }) => api.report.modify(id, data),
    {
      onSuccess({ report }, { data }) {
        queryClient.setQueryData<Report[]>(REPORT_ALL_QK, data => {
          if (!data) {
            return undefined
          }
          const index = data.findIndex(({ id }) => id === report.id)
          return index === -1 ? data.concat(report) : update(index, report, data)
        })

        let message = "Report modified."
        if (equals(data, { disabled: true })) {
          message = "Report disabled."
        }
        if (equals(data, { disabled: false })) {
          message = "Report enabled."
        }

        showToast(message)
      },
    },
  )
}

export function useDeleteReport() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Report["id"] }) => api.report.delete(id), {
    onSuccess(_, { id }) {
      queryClient.setQueryData<Report[]>(REPORT_ALL_QK, data => {
        return data?.filter(report => report.id !== id)
      })
      showToast("Report deleted.")
    },
  })
}

export function useMoveReport() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, toIndex }: { id: Report["id"]; fromIndex: number; toIndex: number }) =>
      api.report.move(id, { order_index: toIndex }),
    {
      // Optimistic UI update because there is no way to indicate a loading state in the current UI
      onMutate({ fromIndex, toIndex }) {
        queryClient.setQueryData<Report[]>(REPORT_ALL_QK, data => {
          if (!data) {
            return undefined
          }
          return move(fromIndex, toIndex, data)
        })
      },
      onSuccess() {
        showToast("Report order changed.")
      },
      onError(_, { fromIndex, toIndex }) {
        queryClient.setQueryData<Report[]>(REPORT_ALL_QK, data => {
          if (!data) {
            return undefined
          }
          return move(toIndex, fromIndex, data)
        })
      },
    },
  )
}

import React, { useEffect, useState } from "react"
import classNames from "classnames"
import styles from "./FunnelsList.module.scss"
import Page from "components/UI/Page/Page"
import { Link, Redirect, useHistory, useParams } from "react-router-dom"
import { useFetchAllFunnelGroups } from "resources/funnelGroup/funnelGroupQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { getRoutePath } from "routes"
import CreateChartModalButton from "../components/CreateChartModalButton/CreateChartModalButton"
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import { Segment } from "resources/segment/segment/segmentTypes"
import { DateString } from "types/util"
import { getISODateDaysAgo, getISODateToday } from "helpers/dateFormat.helper"
import {
  useFetchAllFunnelCharts,
  useFetchChartsData,
  useMoveFunnelChart,
} from "resources/funnelChart/funnelChartQueries"
import { useQueryClient } from "@tanstack/react-query"
import SegmentPicker from "components/SegmentPicker/SegmentPicker"
import Button from "components/UI/elements/Button/Button"
import { equals, prop } from "ramda"
import NewBadge from "components/UI/elements/NewBadge/NewBadge"
import Expandable from "../components/Expandable/Expandable"
import ChartCard from "../components/ChartCard/ChartCard"
import SortableGrid from "../components/SortableGrid/SortableGrid"
import { useHasAccess, useHasFunnelGroupEditPermission } from "resources/user/currentUserQueries"
import marketingImg from "./marketing-content.png"
import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"
import FunnelsDatePicker from "../components/FunnelsDatePicker/FunnelsDatePicker"
import create from "zustand"
import { QuickOption } from "../components/FunnelsDatePicker/FunnelsDataPicker.types"
import { DATE_OPTIONS } from "../components/FunnelsDatePicker/FunnelsDataPicker.config"

type FiltersStore = {
  startDate: DateString
  endDate: DateString
  segmentId: Segment["id"] | null
  quickOption?: QuickOption
  setStartDate: (startDate: DateString) => void
  setEndDate: (endDate: DateString) => void
  setSegmentId: (segmentId: Segment["id"] | null) => void
  setQuickOption: (quickOption?: QuickOption) => void
  reset: () => void
}

const initialState = {
  startDate: getISODateDaysAgo(27),
  endDate: getISODateToday(),
  segmentId: null,
  quickOption: DATE_OPTIONS[5],
}

export const useFiltersStore = create<FiltersStore>(set => ({
  ...initialState,
  setStartDate: (startDate: DateString) => set({ startDate }),
  setEndDate: (endDate: DateString) => set({ endDate }),
  setSegmentId: (segmentId: Segment["id"] | null) => set({ segmentId }),
  setQuickOption: (quickOption?: QuickOption) => set({ quickOption }),
  reset: () => set(initialState),
}))

export default function FunnelsList() {
  const history = useHistory()
  const { groupId } = useParams<{ groupId?: string }>()
  const funnelGroupsQuery = useFetchAllFunnelGroups({ includeDisabled: false })
  const hasEditPermission = useHasFunnelGroupEditPermission(groupId)

  const {
    startDate,
    endDate,
    segmentId,
    quickOption,
    setStartDate,
    setEndDate,
    setSegmentId,
    setQuickOption,
  } = useFiltersStore()

  const chartsQuery = useFetchAllFunnelCharts(groupId)

  const params = {
    groupId,
    chartIds: chartsQuery.data?.map(prop("id")) ?? [],
    startDate,
    endDate,
    segmentId,
  }
  const [lastSentParams, setLastSentParams] = useState(params)
  const chartsDataQuery = useFetchChartsData(params)

  const moveMutation = useMoveFunnelChart()
  function moveChart(fromIndex: number, toIndex: number) {
    const chartId = chartsQuery.data![fromIndex].id
    moveMutation.mutate({ funnelGroupId: groupId!, chartId, data: { fromIndex, toIndex } })
  }

  const queryClient = useQueryClient()

  function fetchData() {
    if (chartsQuery.data && chartsQuery.data.length) {
      setLastSentParams(params)
      queryClient.removeQueries(["funnelChartsData"])
      chartsDataQuery.refetch()
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartsDataQuery.refetch, chartsQuery.data])

  const hasAccess = useHasAccess()

  if (funnelGroupsQuery.isLoading) {
    return (
      <Page title="Funnels">
        <LoadingIndicator />
      </Page>
    )
  }

  if (funnelGroupsQuery.isError) {
    return <Page title="Funnels"> </Page>
  }

  if (!hasAccess.funnels.view) {
    return (
      <Page title="Funnels">
        <MarketingContent img={{ alt: "Funnels", src: marketingImg }}>
          <h1>It seems you don't have access to view any funnel groups at the moment.</h1>
          <p>
            If you believe this is an oversight or if you need access to specific funnel groups,
            please reach out to your administrator to request access.
          </p>
        </MarketingContent>
      </Page>
    )
  }

  if (!funnelGroupsQuery.data.length) {
    return (
      <Page title="Funnels">
        <MarketingContent img={{ alt: "Funnels", src: marketingImg }}>
          <h1>It looks like there are no active funnel groups at the moment.</h1>
          <p>
            Dive into your data with our easy-to-use funnel builder and uncover insights that can
            drive your business forward.
          </p>
          <p>
            Need help getting started? Check out our{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-analyticsfunnels"
              rel="noreferrer"
              target="_blank"
            >
              Docs Guide
            </a>
            .
          </p>
          <p>Let's turn your data into action.</p>
          <Button
            disabled={!hasAccess.setup.funnelGroups}
            icon="circle-plus"
            tooltip={
              hasAccess.setup.funnelGroups
                ? undefined
                : "You don't have permission to create funnel group."
            }
            onClick={() => history.push(getRoutePath("administration.funnel-groups.create"))}
            className={styles.marketingContentButton}
          >
            Create funnel group
          </Button>
        </MarketingContent>
      </Page>
    )
  }

  if (!groupId) {
    return (
      <Redirect
        to={getRoutePath("analytics.funnels.group", { groupId: funnelGroupsQuery.data[0].id })}
      />
    )
  }

  return (
    <>
      <Page
        title="Funnels"
        headerContent={<CreateChartModalButton isEditable={hasEditPermission} />}
        contentClassName={styles.container}
      >
        <div className={styles.top}>
          <PaperHeader size="small" className={styles.header}>
            <div className={styles.filters}>
              <SegmentPicker
                clearable
                value={segmentId}
                onChange={setSegmentId}
                className={styles.segmentPicker}
                enabledTypes={["custom", "featured", "smart"]}
              />
              <FunnelsDatePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setQuickOption={setQuickOption}
                quickOption={quickOption}
              />
              <Button
                size="md"
                onClick={fetchData}
                loading={chartsQuery.isLoading || chartsDataQuery.isFetching}
                disabled={
                  chartsQuery.isLoading ||
                  chartsDataQuery.isFetching ||
                  equals(params, lastSentParams)
                }
              >
                Update
              </Button>
            </div>
            <Link to={getRoutePath("analytics.funnels.trash")}>
              <Button variant="outlined" color="grey" icon={["far", "trash-alt"]}>
                Trash
              </Button>
            </Link>
          </PaperHeader>
          <Paper hasHeader className={styles.groupLinks}>
            {funnelGroupsQuery.data
              .filter(group => !group.disabled)
              .map(group => (
                <Link
                  key={group.id}
                  to={getRoutePath("analytics.funnels.group", { groupId: group.id })}
                >
                  <Button
                    className={classNames(styles.groupLink, {
                      [styles.active]: groupId === group.id,
                    })}
                    color="grey"
                    variant="outlined"
                  >
                    {group.name} <NewBadge created={group.created} />
                  </Button>
                </Link>
              ))}
          </Paper>
        </div>

        <Paper className={styles.main}>
          {chartsQuery.isLoading && <LoadingIndicator />}

          {chartsQuery.data && chartsQuery.data.length === 0 && (
            <div className={styles.emptyMessage}>No charts found.</div>
          )}

          {chartsQuery.data && chartsQuery.data.length > 0 && (
            <SortableGrid items={chartsQuery.data!} onMove={moveChart} className={styles.charts}>
              {chartsQuery.data!.map(chart => (
                <Expandable key={chart.id}>
                  {expandable => (
                    <ChartCard
                      {...expandable}
                      chart={chart}
                      data={chartsDataQuery.data?.[chart.id]}
                      isLoading={
                        chartsDataQuery.isLoading ||
                        chartsQuery.isLoading ||
                        chartsDataQuery.isFetching
                      }
                      isEditable={hasEditPermission}
                      funnelGroupId={groupId}
                    />
                  )}
                </Expandable>
              ))}
            </SortableGrid>
          )}
        </Paper>
      </Page>
    </>
  )
}

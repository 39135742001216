import requestFactory from "api/request"
import {
  Journey,
  JourneyCreatePayload,
  JourneyModifyPayload,
  JourneysListDeletedResponse,
  JourneysListResponse,
  JourneyNodeCreatePayload,
  JourneyNodeModifyPayload,
  JourneyNode,
  JourneyActivatePayload,
  JourneyNodesStatisticsResponse,
  JourneyStatisticsResponse,
} from "./journeyTypes"

const journey = {
  listAll(): Promise<JourneysListResponse> {
    return requestFactory("get", "/journeys")
  },

  create(data: JourneyCreatePayload): Promise<{ journey: Journey }> {
    return requestFactory("post", "/journeys", data)
  },

  modify(id: Journey["id"], data: JourneyModifyPayload): Promise<{ journey: Journey }> {
    return requestFactory("patch", `/journeys/${id}`, data)
  },

  delete(id: Journey["id"]): Promise<void> {
    return requestFactory("delete", `/journeys/${id}`)
  },

  listDeleted(): Promise<JourneysListDeletedResponse> {
    return requestFactory("get", "/journeys/trash")
  },

  restoreDeleted(id: Journey["id"]): Promise<{ journey: Journey }> {
    return requestFactory("patch", `/journeys/${id}/trash`)
  },

  activate(id: Journey["id"], data: JourneyActivatePayload): Promise<{ journey: Journey }> {
    return requestFactory("post", `/journeys/${id}/activate`, data)
  },

  deactivate(id: Journey["id"]): Promise<{ journey: Journey }> {
    return requestFactory("post", `/journeys/${id}/deactivate`)
  },

  stop(id: Journey["id"]): Promise<{ journey: Journey }> {
    return requestFactory("post", `/journeys/${id}/stop`)
  },

  statistics(id: Journey["id"]): Promise<JourneyStatisticsResponse> {
    return requestFactory("get", `/journeys/${id}/lifetime_statistics`)
  },

  nodeStatistics(id: Journey["id"]): Promise<JourneyNodesStatisticsResponse> {
    return requestFactory("get", `/journeys/${id}/nodes_statistics`)
  },

  node: {
    create(
      journeyId: Journey["id"],
      data: JourneyNodeCreatePayload,
    ): Promise<{ journey_node: JourneyNode }> {
      return requestFactory("post", `/journeys/${journeyId}/nodes`, data)
    },

    modify(
      journeyId: Journey["id"],
      nodeId: JourneyNode["id"],
      data: JourneyNodeModifyPayload,
    ): Promise<{ journey_node: JourneyNode }> {
      return requestFactory("patch", `/journeys/${journeyId}/nodes/${nodeId}`, data)
    },

    delete(journeyId: Journey["id"], nodeId: JourneyNode["id"]): Promise<void> {
      return requestFactory("delete", `/journeys/${journeyId}/nodes/${nodeId}`)
    },
  },
}

export default journey

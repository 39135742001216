import requestFactory from "api/request"
import { FunnelGroup } from "resources/funnelGroup/funnelGroupTypes"
import { UserRole } from "resources/userRole/userRoleTypes"
import {
  FunnelGroupPermission,
  FunnelGroupPermissionCreatePayload,
  FunnelGroupPermissionListResponse,
  FunnelGroupPermissionModifyPayload,
} from "./funnelGroupPermissionTypes"

const funnelGroupPermission = {
  list(id: FunnelGroup["id"]): Promise<FunnelGroupPermissionListResponse> {
    return requestFactory("get", `/acl/funnel_groups/${id}/user_roles`)
  },

  create(
    id: FunnelGroup["id"],
    data: FunnelGroupPermissionCreatePayload,
  ): Promise<{ funnel_group_user_role: FunnelGroupPermission }> {
    return requestFactory("post", `/acl/funnel_groups/${id}/user_roles`, data)
  },

  modify(
    id: FunnelGroup["id"],
    userRoleId: UserRole["id"],
    data: FunnelGroupPermissionModifyPayload,
  ): Promise<{ funnel_group_user_role: FunnelGroupPermission }> {
    return requestFactory("patch", `/acl/funnel_groups/${id}/user_roles/${userRoleId}`, data)
  },

  delete(id: FunnelGroup["id"], userRoleId: UserRole["id"]): Promise<void> {
    return requestFactory("delete", `/acl/funnel_groups/${id}/user_roles/${userRoleId}`)
  },
}

export default funnelGroupPermission

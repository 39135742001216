import React, { ComponentProps } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ErrorTippy from "../ErrorTippy/ErrorTippy"
import useHover from "hooks/useHover"

import styles from "./Checkbox.module.scss"

export type CheckboxProps = {
  label: string | JSX.Element
  centered?: boolean
  errorMessage?: string
  size?: "sm" | "lg"
  variant?: "primary" | "secondary"
} & Omit<ComponentProps<"input">, "size">

const Checkbox = ({
  checked,
  className,
  disabled,
  errorMessage,
  label,
  onChange,
  centered = true,
  size = "lg",
  variant = "primary",
  id,
  ...inputAttrs
}: CheckboxProps) => {
  const [hoverRef, isHovered] = useHover<HTMLLabelElement>()

  return (
    <ErrorTippy disabled={variant === "primary" || !errorMessage} content={errorMessage}>
      <label
        className={classNames(styles.label, styles[size], styles[variant], className, {
          [styles.checked]: checked,
          [styles.disabled]: disabled,
          [styles.error]: errorMessage,
        })}
        htmlFor={id}
        ref={hoverRef}
      >
        {label}
        <input
          {...inputAttrs}
          id={id}
          checked={checked}
          disabled={disabled}
          type="checkbox"
          onChange={onChange}
        />
        {variant === "primary" && (
          <ErrorTippy
            disabled={!errorMessage}
            visible={isHovered}
            content={errorMessage}
            offset={[0, 9]}
          >
            <div
              data-testid="checkbox-checkmark"
              className={classNames(styles.checkmark, styles[size], {
                [styles.centered]: centered,
                [styles.checked]: checked,
                [styles.disabled]: disabled,
                [styles.error]: errorMessage,
              })}
            >
              <FontAwesomeIcon icon={["fas", "check"]} />
            </div>
          </ErrorTippy>
        )}
      </label>
    </ErrorTippy>
  )
}

export default Checkbox

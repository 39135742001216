import {
  UseQueryOptions,
  QueryKey,
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import { api } from "api"
import { queryClient } from "app/queryClient"
import {
  assocPath,
  equals,
  filter,
  identity,
  map,
  path,
  pipe,
  prop,
  propEq,
  sort,
  uniq,
  update,
  whereEq,
} from "ramda"
import { SYSTEM_USER_ID } from "sharedConstants"
import { OrderDir } from "types/util"
import { ascend, descend } from "utilities/comparators"
import {
  User,
  UserCreatePayload,
  UserFull,
  UserInvitePayload,
  UserListDeletedResponse,
  UserModifyPayload,
} from "./userTypes"
import { getRoutePath } from "routes"
import { TrashItem } from "types/trash"
import { sortByDateProp } from "resources/utils"

const USER = "user" as const
export const USER_ALL_QK: QueryKey = [USER, "all"]
export const USER_TRASH_QK: QueryKey = [USER, "trash"]

export function refetchUsers() {
  queryClient.refetchQueries(USER_ALL_QK)
}

export function useUsersQuery<T>(config?: UseQueryOptions<UserFull[], unknown, T, QueryKey>) {
  return useQuery(USER_ALL_QK, () => api.user.listAll(), {
    staleTime: 60 * 1000,
    ...config,
  })
}

type FetchAllUsersOpts = {
  orderBy?: "name" | "email" | "last_login" | "role.name" | "disabled"
  orderDir?: OrderDir
  searchTerm?: string
  showAutomated?: boolean
}

export function useFetchActiveUsers({
  orderBy,
  orderDir,
  searchTerm,
  showAutomated,
}: FetchAllUsersOpts = {}) {
  const select = (users: UserFull[]) => {
    users = users
      .filter(({ deleted }) => deleted === false)
      .filter(({ id }) => id !== SYSTEM_USER_ID)

    if (!showAutomated) {
      users = users.filter(({ automated }) => !automated)
    }

    if (searchTerm) {
      users = users.filter(
        ({ name, email }) =>
          name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          email.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    }

    if (orderBy && orderDir) {
      if (orderBy === "last_login") {
        users = sortByDateProp(users, orderDir, "last_login")
      } else {
        const comparator = orderDir === "ASC" ? ascend : descend
        const getSortingProperty = orderBy === "role.name" ? path(["role", "name"]) : prop(orderBy)

        users = sort(comparator(getSortingProperty), users)
      }
    }

    return users
  }

  return useUsersQuery({ select, refetchOnMount: "always" })
}

export function useFetchAllUsers() {
  return useUsersQuery({ select: identity })
}

export function useFetchAllUsersMap() {
  return useUsersQuery({ select: users => Object.fromEntries(users.map(user => [user.id, user])) })
}

export function useFetchUserById(id: UserFull["id"]) {
  return useUsersQuery({ select: users => users.find(propEq(id, "id")) ?? null })
}

export function useFetchUserOptions() {
  return useUsersQuery({
    select: users =>
      users
        .filter(({ id }) => id !== SYSTEM_USER_ID)
        .map(user => ({ value: user.id, label: user.email }))
        .sort(ascend(prop("label"))),
  })
}

export function useFetchEmailDomainOptions() {
  return useUsersQuery({
    select: pipe(
      filter<UserFull>(({ id }) => id !== SYSTEM_USER_ID),
      map(({ email }) => `@${email.split("@")[1]}`),
      uniq,
      sort(ascend(identity)),
      map(domain => ({ value: domain, label: domain })),
    ),
  })
}

export function useCreateUser() {
  const queryClient = useQueryClient()

  return useMutation(({ data }: { data: UserCreatePayload }) => api.user.create(data), {
    onSuccess: (user, { data }) => {
      queryClient.setQueryData<UserFull[]>(USER_ALL_QK, data => data?.concat(user))
    },
    // No toast; toasts are shown in the component after the whole batch of create calls is sent off
  })
}

export function useInviteUser() {
  const queryClient = useQueryClient()

  return useMutation(({ data }: { data: UserInvitePayload }) => api.user.sendInvitation(data), {
    onSuccess: (user, { data }) => {
      queryClient.setQueryData<UserFull[]>(USER_ALL_QK, data => data?.concat(user))
    },
    // No toast; toasts are shown in the component after the whole batch of create calls is sent off
  })
}

export function useModifyUser() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: UserFull["id"]; data: UserModifyPayload }) => api.user.modify(id, data),
    {
      onSuccess: (user, { data }) => {
        queryClient.setQueryData<UserFull[]>(USER_ALL_QK, data => {
          if (!data) {
            return
          }

          const index = data.findIndex(({ id }) => id === user.id)

          return index === -1 ? data.concat(user) : update(index, user, data)
        })

        if (equals(Object.keys(data), ["frontend_settings"])) {
          // No toasts for UI state updates
          return
        }

        let toastMessage = "User modified."

        if (equals(data, { disabled: true })) {
          toastMessage = "User disabled."
        }

        if (equals(data, { disabled: false })) {
          toastMessage = "User enabled."
        }

        if (equals(data, { automated: true })) {
          toastMessage = "Automated status turned on."
        }

        if (equals(data, { automated: false })) {
          toastMessage = "Automated status turned off."
        }

        if (equals(data, { email_notifications_enabled: true })) {
          toastMessage = "Email notifications enabled."
        }

        if (equals(data, { email_notifications_enabled: false })) {
          toastMessage = "Email notifications disabled."
        }

        if (equals(Object.keys(data), ["role_id"])) {
          toastMessage = "User role changed."
        }

        if (equals(Object.keys(data), ["name"])) {
          toastMessage = "Name changed."
        }

        if (equals(Object.keys(data), ["email"])) {
          toastMessage = "Email changed."
        }

        if (equals(Object.keys(data), ["password"])) {
          toastMessage = "Password changed."
        }

        if (equals(Object.keys(data), ["cdp_settings"])) {
          return // Current user settings; toasts dispatched in currentUserQueries
        }

        showToast(toastMessage)
      },
    },
  )
}

export function useDeleteUser() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: UserFull["id"] }) => api.user.delete(id), {
    onSuccess: (_, { id }) => {
      queryClient.setQueryData<UserFull[]>(USER_ALL_QK, data => {
        if (!data) {
          return
        }

        const index = data.findIndex(whereEq({ id }))
        return index === -1 ? data : assocPath([index, "deleted"], true, data)
      })
      showToast("User deleted.")
    },
  })
}

export const useMetabaseLogin = () =>
  useMutation(() => api.user.metabaseLogin(), {
    onSuccess: () => {
      window.open(`${window.location.origin}/reports`, "_blank")
    },
  })

export const useFetchUserTrashItems = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<
    UserListDeletedResponse,
    string,
    Omit<UserListDeletedResponse, "trashed_users"> & { trashed_users: Array<TrashItem> },
    QueryKey
  >(
    [...USER_TRASH_QK, searchTerm],
    ({ pageParam }) =>
      api.user.listDeleted({
        offset: pageParam,
        limit: 20,
        searched_text: searchTerm,
      }),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.trashed_users.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      select: data => {
        return {
          ...data,
          pages: data.pages.map(p => ({
            ...p,
            trashed_users: p.trashed_users.map(({ id, name, modified, last_modified_by }) => {
              const trashItem: TrashItem = {
                id,
                name,
                deleted_at: modified,
                deleted_by: last_modified_by,
                type: "users",
              }

              return trashItem
            }),
          })),
        }
      },
    },
  )

  return {
    ...rest,
    data: data ? data.pages.flatMap(m => m.trashed_users) : [],
  }
}

export const useRestoreUser = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: User["id"] }) => api.user.restoreDeleted(id), {
    onSuccess: user => {
      queryClient.invalidateQueries(USER_TRASH_QK)
      queryClient.setQueryData<Array<UserFull>>(USER_ALL_QK, data => {
        if (!data) return

        return data.concat(user)
      })

      showToast(
        "User restored.",
        undefined,
        getRoutePath("administration.users.detail", { id: user.id }),
      )
    },
  })
}

import { WaitDelayNodeSettings } from "resources/journey/journeyTypes"
import { Duration, durationToSeconds, secondsToDuration } from "../../utils/duration"

export type WaitDelayNodeFormValues = Duration

export function settingsToFormValues({ delay }: WaitDelayNodeSettings): WaitDelayNodeFormValues {
  return secondsToDuration(delay)
}

export function formValuesToSettings(duration: WaitDelayNodeFormValues): WaitDelayNodeSettings {
  return { delay: durationToSeconds(duration) }
}

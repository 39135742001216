import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { useHasAccess } from "resources/user/currentUserQueries"
import { getRoutePath } from "routes"
import CustomSegments from "./CustomSegments/CustomSegmentsRoutes"
import FeaturedSegments from "./FeaturedSegments/FeaturedSegmentsRoutes"
import LookalikeSegments from "./LookalikeSegments/LookalikeSegmentsRoutes"
import SmartSegments from "./SmartSegments/SmartSegmentsRoutes"
import Tags from "./Tags/Tags"

export default function Segments() {
  const hasAccess = useHasAccess()

  return (
    <Switch>
      <Route
        path={getRoutePath("segments")}
        exact
        render={() => <Redirect to={getRoutePath("segments.custom")} />}
      />
      <Route path={getRoutePath("segments.custom")} component={CustomSegments} />
      <Route path={getRoutePath("segments.featured")} component={FeaturedSegments} />
      <Route path={getRoutePath("segments.smart")} component={SmartSegments} />
      <Route path={getRoutePath("segments.lookalike")} component={LookalikeSegments} />
      <AuthorizedRoute
        hasAccess={hasAccess.segments.viewTags}
        path={getRoutePath("segments.tags")}
        component={Tags}
      />

      {/* Deprecated segment routes need to be here rather than AuthenticatedRoutesSwitch because of the order of matching: */}
      <Route
        exact
        path={getRoutePath("DEPRECATED.segments.detail")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("segments.custom.detail", { id })} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.segments.detail.exports")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("segments.custom.detail.exports", { id })} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.segments.detail.insights")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("segments.custom.detail.insights", { id })} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.segments.export.detail")}
        render={({
          match: {
            params: { id, eid },
          },
        }) => <Redirect to={getRoutePath("segments.custom.detail", { id, eid })} />}
      />
    </Switch>
  )
}

import React, { PropsWithChildren } from "react"
import { CSSTransition } from "react-transition-group"
import styles from "./ModalWrapper.module.scss"
import useKeyListener from "hooks/useKeyListener"

type ModalProps = PropsWithChildren<{
  isOpen: boolean
  onClose: () => void
}>

export default function ModalWrapper({ isOpen, onClose, children }: ModalProps) {
  useKeyListener("Escape", () => {
    if (isOpen) onClose()
  })

  return (
    <CSSTransition in={isOpen} timeout={200} classNames="fade" unmountOnExit>
      <div className={styles.container}>
        <div className={styles.modalWrapper}>{children}</div>
        <div
          className={styles.overlay}
          onClick={() => {
            if (isOpen) onClose()
          }}
        />
      </div>
    </CSSTransition>
  )
}

import React, { useState } from "react"
import classNames from "classnames"
import styles from "./StatusSwitch.module.scss"
import { Journey, JourneyStatus } from "resources/journey/journeyTypes"
import Button from "components/UI/elements/Button/Button"
import useClickOutHandler from "hooks/useClickOutHandler"
import { useHasAccess } from "resources/user/currentUserQueries"
import Paper from "components/UI/elements/Paper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ActivateModal from "../ActivateModal/ActivateModal"
import { useDeactivateJourney, useStopJourney } from "resources/journey/journeyQueries"
import ConfirmModal from "components/UI/components/ConfirmModal"

type StatusSwitchProps = {
  status: JourneyStatus
  journeyId: Journey["id"]
}

export default function StatusSwitch({ status, journeyId }: StatusSwitchProps) {
  const {
    ref: dropdownRef,
    triggerRef,
    toggle: toggleDropdown,
    isOpen: isDropdownOpen,
    close: closeDropdown,
  } = useClickOutHandler()
  const hasAccess = useHasAccess()
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false)
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false)
  const [isStopModalOpen, setIsStopModalOpen] = useState(false)

  const stopMutation = useStopJourney()
  const deactivateMutation = useDeactivateJourney()

  function stop() {
    stopMutation.mutate(
      { id: journeyId },
      {
        onSuccess() {
          setIsStopModalOpen(false)
        },
      },
    )
  }

  function deactivate() {
    deactivateMutation.mutate(
      { id: journeyId },
      {
        onSuccess() {
          setIsDeactivateModalOpen(false)
        },
      },
    )
  }

  return (
    <>
      <ActivateModal
        isOpen={isActivateModalOpen}
        onClose={() => setIsActivateModalOpen(false)}
        journeyId={journeyId}
      />

      <ConfirmModal
        handleClose={() => setIsStopModalOpen(false)}
        handleConfirm={stop}
        isLoading={stopMutation.isLoading}
        title="Are you sure?"
        text="Do you really want to move this journey to draft state?"
        open={isStopModalOpen}
        type="success"
      />
      <ConfirmModal
        handleClose={() => setIsDeactivateModalOpen(false)}
        handleConfirm={deactivate}
        isLoading={deactivateMutation.isLoading}
        title="Are you sure?"
        text="Do you really want to move this journey to passive state?"
        open={isDeactivateModalOpen}
        type="success"
      />

      <div className={styles.container}>
        {hasAccess.journeys.status ? (
          <Button
            className={styles.button}
            variant="outlined"
            size="md"
            iconPosition="end"
            color={
              status === "active"
                ? "green"
                : status === "passive"
                ? "yellow"
                : status === "pending"
                ? "blue"
                : "grey"
            }
            ref={triggerRef}
            onClick={toggleDropdown}
          >
            {status}{" "}
            <FontAwesomeIcon
              icon={["fas", "caret-down"]}
              flip={isDropdownOpen ? "vertical" : undefined}
            />
          </Button>
        ) : (
          <div className={classNames(styles.readonlyStatus, styles[status])}>{status}</div>
        )}

        {isDropdownOpen && (
          <Paper noPadding ref={dropdownRef} className={styles.dropdown}>
            {status === "draft" && (
              <button
                className={styles.item}
                onClick={() => {
                  setIsActivateModalOpen(true)
                  closeDropdown()
                }}
              >
                <FontAwesomeIcon icon={["far", "play"]} />
                <div className={styles.title}>Activate</div>
                <div className={styles.description}>Start the journey.</div>
              </button>
            )}

            {(status === "pending" || status === "passive") && (
              <button
                className={styles.item}
                onClick={() => {
                  setIsStopModalOpen(true)
                  closeDropdown()
                }}
              >
                <FontAwesomeIcon icon={["far", "pencil-alt"]} />
                <div className={styles.title}>Set to draft</div>
                <div className={styles.description}>
                  Setting the journey to draft state will stop processing all entities in journey
                  and prevent new entities entering journey.
                </div>
              </button>
            )}

            {status === "active" && (
              <>
                <button
                  className={styles.item}
                  onClick={() => {
                    setIsDeactivateModalOpen(true)
                    closeDropdown()
                  }}
                >
                  <FontAwesomeIcon icon={["far", "right-long-to-line"]} />
                  <div className={styles.title}>Set to passive</div>
                  <div className={styles.description}>
                    Setting the journey to passive state will finish processing entities already in
                    the journey, but will prevent new entities from entering the journey.
                  </div>
                </button>
                <button
                  className={styles.item}
                  onClick={() => {
                    setIsStopModalOpen(true)
                    closeDropdown()
                  }}
                >
                  <FontAwesomeIcon icon={["far", "pencil-alt"]} />
                  <div className={styles.title}>Set to draft</div>
                  <div className={styles.description}>
                    Setting the journey to draft state will stop processing all entities in journey
                    and prevent new entities entering journey.
                  </div>
                </button>
              </>
            )}
          </Paper>
        )}
      </div>
    </>
  )
}

import React from "react"
import PropTypes from "prop-types"
import { differenceInMilliseconds } from "date-fns"
import humanizeDuration from "humanize-duration"

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
  delimiter: " ",
  spacer: "",
  maxDecimalPoints: 0,
})

class Duration extends React.PureComponent {
  intervalId = null

  constructor(props) {
    super(props)
    this.state = {
      duration: null,
    }
  }

  componentDidMount() {
    const { datetime, units } = this.props
    const params = {}
    if (Array.isArray(units) && units.length > 0) {
      params.units = units
    }
    this.setState({
      duration: shortEnglishHumanizer(differenceInMilliseconds(new Date(), datetime), params),
    })
    this.intervalId = setInterval(() => {
      this.setState({
        duration: shortEnglishHumanizer(differenceInMilliseconds(new Date(), datetime), params),
      })
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  render() {
    let duration = this.state.duration === "0m" ? "<1m" : this.state.duration
    return <span className="duration">{duration}</span>
  }
}

Duration.propTypes = {
  datetime: PropTypes.instanceOf(Date).isRequired,
  units: PropTypes.array,
}

export default Duration

import React, { useCallback, useState } from "react"
import styles from "./WebBannerCondition.module.scss"
import {
  WBCondition,
  CookieCondition,
  GTMCondition,
  HTTPCondition,
  LeafCondition,
  LSCondition,
  TypedCondition,
  WBConditionError,
  SegmentCondition,
} from "resources/webBanner/webBannerConditionTypes"
import SelectField from "components/UI/elements/SelectField"
import {
  getAttributeLocationOption,
  getOperatorOptions,
  getStorageTypeOption,
  getSubjectOption,
  AttributeLocationOption,
  attributeLocationOptions,
  OperatorDropdownOption,
  StorageTypeOption,
  storageTypeOptions,
  SubjectDropdownOption,
  subjectOptions,
} from "./dropdownOptions"
import ConditionValue from "./WebBannerConditionValue"
import {
  areOperatorsSameKind,
  getConditionWithNewSubject,
  getConditionWithDefaultValues,
  isCookieCondition,
  isGTMCondition,
  isHTTPCondition,
  isLSCondition,
  isOperatorAllowed,
  isSegmentCondition,
  isTypedCondition,
} from "./utils"
import IconButton from "components/UI/elements/IconButton/IconButton"
import classNames from "classnames"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { DraggableSyntheticListeners } from "@dnd-kit/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ConditionSymbol } from "types/conditionTree"
import { getSymbolStyle } from "components/ConditionBuilder/treeSymbols"
import DelayedTooltip from "components/UI/elements/DelayedTooltip/DelayedTooltip"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import SegmentPicker from "components/SegmentPicker/SegmentPicker"
import { useFetchAudienceApiStitchingCategories } from "resources/stitchingCategory/stitchingCategoryQueries"
import { StitchingCategory } from "resources/stitchingCategory/stitchingCategoryTypes"

type WebBannerConditionProps = {
  symbol: ConditionSymbol
  condition: WBCondition
  onChange: (condition: WBCondition) => void
  removeSelf?: () => void
  duplicateSelf?: () => void
  hoverSelf?: (hovered: boolean) => void
  isEditable?: boolean
  error?: WBConditionError
  dragListeners?: DraggableSyntheticListeners
  greyedOut?: boolean
  highlighted?: boolean
}

const touchedInitialState = {
  subject: true,
  operator: true,
  name: true,
  key: true,
  dl_name: true,
  dl_key: true,
  url_template: true,
  response_value_path: true,
  segment_id: true,
  attribute_id: true,
  attribute_location: true,
  attribute_location_key: true,
}

export function getNewWebBannerCondition(): WBCondition {
  return { subject: null, operator: null }
}

export default function WebBannerConditionField({
  symbol,
  condition,
  onChange,
  removeSelf,
  duplicateSelf,
  hoverSelf,
  isEditable,
  error,
  dragListeners,
  greyedOut = false,
  highlighted = false,
}: WebBannerConditionProps) {
  const { data: stitchingCategories = [], isLoading: isLoadingStitchingCategories } =
    useFetchAudienceApiStitchingCategories()

  const negation = condition.operator === "negation"
  const leafCondition = condition.operator === "negation" ? condition.operand : condition

  const onChangeWrappingNegation = useCallback(
    (leafCondition: LeafCondition) => {
      onChange(negation ? { operator: "negation", operand: leafCondition } : leafCondition)
    },
    [negation, onChange],
  )

  const setSubject = useCallback<(option: SubjectDropdownOption) => void>(
    ({ value: newSubject }) => {
      setTouched(s => ({ ...s, subject: true }))
      onChangeWrappingNegation(getConditionWithNewSubject(leafCondition, newSubject))
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setCookieName = useCallback(
    e => {
      setTouched(s => ({ ...s, name: true }))
      onChangeWrappingNegation({
        ...(leafCondition as CookieCondition),
        name: e.target.value || null,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setLSKey = useCallback(
    e => {
      setTouched(s => ({ ...s, key: true }))
      onChangeWrappingNegation({ ...(leafCondition as LSCondition), key: e.target.value || null })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setGTMName = useCallback(
    e => {
      setTouched(s => ({ ...s, dl_name: true }))
      onChangeWrappingNegation({
        ...(leafCondition as GTMCondition),
        dl_name: e.target.value || null,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setGTMKey = useCallback(
    e => {
      setTouched(s => ({ ...s, dl_key: true }))
      onChangeWrappingNegation({
        ...(leafCondition as GTMCondition),
        dl_key: e.target.value || null,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setHttpUrl = useCallback(
    e => {
      setTouched(s => ({ ...s, url_template: true }))
      onChangeWrappingNegation({
        ...(leafCondition as HTTPCondition),
        url_template: e.target.value || null,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setHttpPath = useCallback(
    e => {
      setTouched(s => ({ ...s, response_value_path: true }))
      onChangeWrappingNegation({
        ...(leafCondition as HTTPCondition),
        response_value_path: e.target.value || null,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setSegment = useCallback(
    newValue => {
      if (Array.isArray(newValue)) return

      setTouched(s => ({ ...s, segment_id: true }))
      onChangeWrappingNegation({
        ...(leafCondition as SegmentCondition),
        segment_id: newValue,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setStitchingCategory = useCallback<
    (stitchingCategory: StitchingCategory["id"] | null) => void
  >(
    stitchingCategory => {
      setTouched(s => ({ ...s, attribute_id: true }))
      onChangeWrappingNegation({
        ...(leafCondition as SegmentCondition),
        attribute_id: stitchingCategory,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setAttributeLocation = useCallback<(option: AttributeLocationOption) => void>(
    ({ value: newAttributeLocation }) => {
      setTouched(s => ({ ...s, attribute_location: true }))
      onChangeWrappingNegation({
        ...(leafCondition as SegmentCondition),
        attribute_location: newAttributeLocation,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setAttributeLocationKey = useCallback(
    e => {
      setTouched(s => ({ ...s, attribute_location_key: true }))
      onChangeWrappingNegation({
        ...(leafCondition as SegmentCondition),
        attribute_location_key: e.target.value || null,
      })
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setStorageType = useCallback<(option: StorageTypeOption | null) => void>(
    option => {
      const newType = option?.value ?? undefined
      const { subject, operator } = leafCondition as TypedCondition
      const newOperator = isOperatorAllowed(operator, subject, newType) ? operator : null
      const restObj = isCookieCondition(leafCondition)
        ? { name: leafCondition.name }
        : isLSCondition(leafCondition)
        ? { key: leafCondition.key }
        : isGTMCondition(leafCondition)
        ? { dl_name: leafCondition.dl_name, dl_key: leafCondition.dl_key }
        : isHTTPCondition(leafCondition)
        ? {
            url_template: leafCondition.url_template,
            response_value_path: leafCondition.response_value_path,
          }
        : {}

      return onChangeWrappingNegation(
        getConditionWithDefaultValues({
          subject,
          operator: newOperator,
          type: newType,
          ...restObj,
        } as LeafCondition),
      )
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const setOperator = useCallback<(option: OperatorDropdownOption) => void>(
    ({ value: newOperator, bool_value }) => {
      setTouched(s => ({ ...s, operator: true }))

      if (bool_value !== undefined) {
        return onChangeWrappingNegation({
          ...leafCondition,
          operator: "equals",
          value: bool_value,
        } as LeafCondition)
      }

      let newCondition = { ...leafCondition, operator: newOperator } as LeafCondition

      // remove values if needed
      if (!areOperatorsSameKind(leafCondition.operator, newOperator)) {
        newCondition = getConditionWithDefaultValues(newCondition)
      }

      return onChangeWrappingNegation(newCondition)
    },
    [leafCondition, onChangeWrappingNegation],
  )

  const subjectOption = getSubjectOption(leafCondition.subject)
  const type = isTypedCondition(leafCondition) ? leafCondition.type : undefined
  const operatorOptions = getOperatorOptions(leafCondition.subject, type)
  const operatorOption =
    operatorOptions.find(({ value, bool_value }) => {
      if (isTypedCondition(leafCondition) && leafCondition.type === "boolean") {
        if (bool_value === undefined) return false
        return bool_value === leafCondition.value
      }
      return value === leafCondition.operator
    }) ?? null

  const [touched, setTouched] = useState(touchedInitialState)

  const subjectSelect = (
    <SelectField
      meta={{ touched: touched.subject, error: error?.subject }}
      label="Condition"
      input={{ value: subjectOption, onChange: setSubject }}
      inputId="subject-field"
      placeholder="Type to search…"
      options={subjectOptions}
      className={classNames(styles.subjectSelect, styles.select, {
        [styles.readOnly]: !isEditable,
      })}
      disabled={!isEditable}
    />
  )

  const operatorSelect = (
    <SelectField
      meta={{ touched: touched.operator, error: error?.operator }}
      label="Operator"
      input={{ value: operatorOption, onChange: setOperator }}
      inputId="operator-field"
      placeholder="Select…"
      options={operatorOptions}
      disabled={subjectOption === null || !isEditable}
      className={classNames(styles.operatorSelect, styles.select, {
        [styles.readOnly]: !isEditable,
      })}
      // @ts-ignore
      isOptionSelected={() => false}
    />
  )

  const isSegmentCond = isSegmentCondition(leafCondition)

  return (
    <div
      className={classNames(styles.container, {
        [styles.hasError]: error,
        [styles.greyedOut]: greyedOut,
        [styles.highlighted]: highlighted,
      })}
    >
      {dragListeners ? (
        <DelayedTooltip content="Move condition">
          <div className={styles.dragHandle} {...dragListeners}>
            <FontAwesomeIcon icon={["fas", "grip-vertical"]} />
          </div>
        </DelayedTooltip>
      ) : (
        <div className={classNames(styles.dragHandle, styles.disabled)}>
          <FontAwesomeIcon icon={["fas", "grip-vertical"]} />
        </div>
      )}
      <div className={styles.symbol} style={getSymbolStyle(symbol.color)}>
        {symbol.text}
      </div>
      {isTypedCondition(leafCondition) || isSegmentCond ? (
        <div className={classNames(styles.typedCond, { [styles.typedCondDnD]: dragListeners })}>
          <div className={styles.typedCondRow}>
            {subjectSelect}
            {isCookieCondition(leafCondition) && (
              <TextInput
                error={(touched.name && error?.name) || undefined}
                label="Cookie name"
                value={leafCondition.name ?? ""}
                onChange={setCookieName}
                disabled={!isEditable}
                className={styles.fullWidth}
              />
            )}
            {isLSCondition(leafCondition) && (
              <TextInput
                error={(touched.key && error?.key) || undefined}
                label="Local storage key"
                value={leafCondition.key ?? ""}
                onChange={setLSKey}
                disabled={!isEditable}
                className={styles.fullWidth}
              />
            )}
            {isGTMCondition(leafCondition) && (
              <>
                <TextInput
                  error={(touched.dl_name && error?.dl_name) || undefined}
                  label="Name of GTM DL object"
                  name="dl-name"
                  value={leafCondition.dl_name ?? ""}
                  onChange={setGTMName}
                  disabled={!isEditable}
                  className={styles.fullWidth}
                />
                <TextInput
                  error={(touched.dl_key && error?.dl_key) || undefined}
                  label="GTM DL path"
                  name="dl-key"
                  value={leafCondition.dl_key ?? ""}
                  onChange={setGTMKey}
                  disabled={!isEditable}
                  className={styles.fullWidth}
                />
              </>
            )}
            {isHTTPCondition(leafCondition) && (
              <>
                <TextInput
                  error={(touched.url_template && error?.url_template) || undefined}
                  label="URL Template"
                  className={styles.fullWidth}
                  value={leafCondition.url_template || ""}
                  onChange={setHttpUrl}
                  disabled={!isEditable}
                />
                <div className={styles.tooltipWrapper}>
                  <InfoTooltip placement="left" className={styles.info}>
                    <p>
                      Use placeholder in the format <strong>{"{{cookie:some_cookie_name}}"}</strong>{" "}
                      or <strong>{"{{ls:some_storage_key}}"}</strong> to insert value from the
                      website's cookies or local storage in the URL before making the request.
                    </p>
                  </InfoTooltip>
                </div>
              </>
            )}
            {isSegmentCond && (
              <SegmentPicker
                className={classNames(styles.fullWidth, styles.select)}
                errorMessage={error?.segment_id}
                value={leafCondition.segment_id}
                onChange={setSegment}
                enabledTypes={["custom", "featured", "smart"]}
              />
            )}
            {!isSegmentCond && (
              <>
                <SelectField
                  label="Data type"
                  input={{
                    value: getStorageTypeOption((leafCondition as TypedCondition).type),
                    onChange: setStorageType,
                  }}
                  inputId="data-type-field"
                  placeholder="Select…"
                  options={storageTypeOptions}
                  disabled={!isEditable}
                  className={classNames(styles.typeSelect, styles.select, {
                    [styles.readOnly]: !isEditable,
                  })}
                  isClearable
                />
                {operatorSelect}
              </>
            )}
          </div>
          {leafCondition.operator && leafCondition.operator !== "is_ok" && !isSegmentCond && (
            <div className={styles.typedCondRow}>
              {isHTTPCondition(leafCondition) && (
                <TextInput
                  error={(touched.response_value_path && error?.response_value_path) || undefined}
                  label="Path in response body"
                  className={styles.fullWidth}
                  value={leafCondition.response_value_path || ""}
                  onChange={setHttpPath}
                  disabled={!isEditable}
                />
              )}
              <ConditionValue
                condition={leafCondition}
                onChange={onChangeWrappingNegation}
                isEditable={isEditable}
                error={error}
              />
            </div>
          )}
          {isSegmentCond && (
            <div className={styles.typedCondRow}>
              <div className={styles.fullWidth} data-testid="identifier-field-attribute-picker">
                <div className={styles.attributeLabel}>
                  <label className={styles.label}>Identifier stitching category</label>
                  <InfoTooltip interactive placement="right" className={styles.tooltip}>
                    <p>
                      View{" "}
                      <a
                        href="https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-displaying-and-testing-a-web-banner-to-a-segmented-audience"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        guide
                      </a>{" "}
                      about how to display web banners to segment audiences.
                    </p>
                  </InfoTooltip>
                </div>

                <SelectField
                  isSimpleValue
                  isLoading={isLoadingStitchingCategories}
                  input={{
                    value: leafCondition.attribute_id,
                    onChange: (value: StitchingCategory["id"]) => setStitchingCategory(value),
                  }}
                  options={stitchingCategories.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  }))}
                  meta={{
                    touched: true,
                    error: error?.attribute_id,
                  }}
                  noOptionsMessage="No enabled stitching categories in Audience API. Contact your administrator."
                />
              </div>
              {operatorSelect}
              <div className={classNames(styles.select, styles.attributeLocationField)}>
                <label className={styles.label}>Identifier stitching category location</label>
                <div className={styles.attributeLocation} data-testid="identifier-location">
                  <SelectField
                    input={{
                      onChange: setAttributeLocation,
                      value: getAttributeLocationOption(leafCondition.attribute_location),
                    }}
                    inputId="attribute-location-field"
                    meta={{
                      error: error?.attribute_location,
                      touched: touched.attribute_location,
                    }}
                    options={attributeLocationOptions}
                  />
                  <TextInput
                    className={classNames(styles.fullWidth, styles.locationKeyField)}
                    disabled={!isEditable}
                    error={
                      (touched.attribute_location_key && error?.attribute_location_key) || undefined
                    }
                    name="attribute-location-key"
                    onChange={setAttributeLocationKey}
                    placeholder="Location key"
                    value={leafCondition.attribute_location_key ?? ""}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={classNames(styles.simpleCondInputs, {
            [styles.simpleCondInputsDnD]: dragListeners,
          })}
        >
          {subjectSelect}
          {leafCondition.subject && operatorSelect}
          <ConditionValue
            condition={leafCondition}
            onChange={onChangeWrappingNegation}
            isEditable={isEditable}
            error={error}
          />
        </div>
      )}
      {isEditable && (
        <div className={styles.buttonsWrapper}>
          {removeSelf && (
            <IconButton
              onClick={removeSelf}
              icon="trash-alt"
              color="red"
              tooltip="Delete"
              size="xs"
              variant="outlined"
            />
          )}
          {duplicateSelf && hoverSelf && (
            <IconButton
              onClick={duplicateSelf}
              icon="copy"
              color="grey"
              tooltip="Duplicate"
              size="xs"
              variant="outlined"
              onMouseEnter={() => hoverSelf(true)}
              onMouseLeave={() => hoverSelf(false)}
            />
          )}
        </div>
      )}
    </div>
  )
}

import React, { useEffect } from "react"
import Paper from "components/UI/elements/Paper"
import "./Sources.scss"
import { useFetchAllDataSources } from "resources/dataSource/dataSourceQueries"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import Table, { Column, Name } from "components/Table/Table"
import {
  Source,
  SourceFilterInitialState,
  SourceFilterState,
} from "resources/dataSource/dataSourceTypes"
import NewBadge from "components/UI/elements/NewBadge/NewBadge"
import Datetime from "components/UI/elements/Datetime/Datetime"
import create from "zustand"

const filtersInitialState: SourceFilterInitialState = {
  orderBy: "name",
  orderDir: "ASC",
}

const useFiltersStore = create<SourceFilterState>(set => ({
  ...filtersInitialState,
  reset: () => set(filtersInitialState),
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
}))

export default function Sources() {
  const { orderBy, orderDir, reset, setSort } = useFiltersStore()
  useEffect(() => reset, [reset])

  const { data: sources = [], isSuccess: areSourcesFulfilled } = useFetchAllDataSources({
    orderBy,
    orderDir,
  })

  const columns: Column<Source>[] = [
    {
      id: "icon",
      gridTemplate: "max-content",
      renderCell: source => (
        <div className="icon">
          <SrcDstIcon source={source} />
        </div>
      ),
    },
    {
      id: "name",
      label: "Source",
      gridTemplate: "1fr",
      renderCell: source => (
        <div>
          <Name name={source.name} /> <NewBadge created={source.created} />
        </div>
      ),
      onSort: () => setSort("name"),
    },
    {
      id: "description",
      label: "Description",
      gridTemplate: "3fr",
      renderCell: ({ description }) => description ?? "—",
    },
    {
      id: "created",
      label: "Date added",
      gridTemplate: "max-content",
      renderCell: ({ created }) => <Datetime datetime={created} />,
      onSort: () => setSort("created"),
    },
  ]

  return (
    <Page className="sources-destinations" title="Sources">
      {!areSourcesFulfilled && <LoadingIndicator />}

      {areSourcesFulfilled && (
        <Paper noPaddingTop>
          <Table
            emptyMessage="No connected source found."
            columns={columns}
            data={sources}
            sortBy={orderBy}
            sortDir={orderDir}
          />
        </Paper>
      )}
    </Page>
  )
}

import { isAndOrCondition } from "components/ConditionBuilder/utils"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import { AttributeFull } from "resources/attribute/attributeTypes"
import { useFetchEventsMap } from "resources/event/eventQueries"
import { ConditionNodeSettings } from "resources/journey/journeyTypes"
import { SegmentCondition } from "resources/segment/segment/segmentConditionsTypes"
import { ConditionTree } from "types/conditionTree"
import { settingsToFormValues } from "./formValues"

export function useGetDescription(settings?: ConditionNodeSettings | null) {
  const { data: attributesMap } = useFetchAttributesMap()
  const { data: eventsMap } = useFetchEventsMap()

  if (!settings || !attributesMap || !eventsMap) {
    return ""
  }

  return settingsToFormValues(settings)
    .paths.map(path => {
      if (path.type === "attribute") {
        const attributeId = getFirstAttributeId(path.conditions_operation)

        if (!attributeId) {
          return ""
        }

        const attribute = attributesMap[attributeId]
        return attribute?.name ?? ""
      }

      const event = eventsMap[path.event_id]
      return event?.name ?? ""
    })
    .join("; ")
}

function getFirstAttributeId(tree: ConditionTree<SegmentCondition>): AttributeFull["id"] | null {
  if (tree === null) {
    return null
  }

  if (isAndOrCondition(tree)) {
    return getFirstAttributeId(tree.operands[0])
  }

  return tree.attribute_id
}

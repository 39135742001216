import React, { useCallback } from "react"
import Tippy from "@tippyjs/react"
import { format } from "date-fns"
import { Interweave } from "interweave"
import { pipe } from "ramda"
import { Link, useHistory } from "react-router-dom"
import Waypoint from "react-waypoint"

import Button from "components/UI/elements/Button/Button"
import { DATE_FMT } from "sharedConstants"
import { getRoutePath } from "routes"
import IconButton from "components/UI/elements/IconButton/IconButton"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import Table, {
  RowMessage,
  TbodySortable,
  Td,
  Th,
  Thead,
  TrSortable,
} from "components/UI/elements/Table"
import {
  useFetchMetaAttributes,
  useReorderMetaAttribute,
} from "resources/metaAttributes/metaAttributesQueries"

import styles from "./MetaAttributesList.module.scss"
import Page from "components/UI/Page/Page"
import { DragEndEvent } from "@dnd-kit/core"
import { MetaAttribute } from "resources/metaAttributes/metaAttributesTypes"
import { ISOwoTZtoDate } from "utilities/date"

const colorBySeparator = (definition: string, separator: string, color: `#${string}`) => {
  const splittedByQuotes = definition.split(separator)
  if (splittedByQuotes.length === 1) return definition

  let returnValue = ""
  splittedByQuotes.forEach((str, index) => {
    // SQL query can not start with single or double quotes - check of odd number
    if (index % 2)
      returnValue += `<span style="color: ${color};">${separator}${str}${separator}</span>`
    else returnValue += str
  })

  return returnValue
}

export default function MetaAttributesList() {
  const history = useHistory()

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useFetchMetaAttributes()
  const { mutate } = useReorderMetaAttribute()

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      if (!event.over?.data.current || !event.active.data.current) return
      const toIndex = event.over.data.current.sortable.index
      const fromIndex = event.active.data.current.sortable.index
      if (isNaN(fromIndex) || toIndex === fromIndex) return

      mutate({
        fromIndex,
        toIndex,
        id: event.active.id as MetaAttribute["id"],
      })
    },
    [mutate],
  )

  return (
    <Page
      title="Meta attributes"
      headerContent={
        <Button onClick={() => history.push(getRoutePath("administration.metaAttributes.create"))}>
          + Create Meta Attribute
        </Button>
      }
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <Paper>
          {data.length === 0 && <RowMessage>No meta attributes found</RowMessage>}
          {data.length > 0 && (
            <>
              <Table>
                <Thead stickyHeader>
                  <Th>#</Th>
                  <Th>ID</Th>
                  <Th>Description</Th>
                  <Th textAlignRight>Order</Th>
                  <Th textAlignRight>Modified at</Th>
                  <Th>&nbsp;</Th>
                </Thead>
                <TbodySortable items={data} onDragEnd={onDragEnd}>
                  {data.map(({ id, description, modified, order_index, definition }) => {
                    const coloredDefinition = pipe(
                      def => colorBySeparator(def, '"', "#4285F4"),
                      def => colorBySeparator(def, "'", "#ED382A"),
                    )(definition)

                    return (
                      <TrSortable key={id} id={id}>
                        <Td>{id}</Td>
                        <Td>{description}</Td>
                        <Td textAlignRight>{order_index}</Td>
                        <Td textAlignRight className={styles.modified}>
                          <span>{format(ISOwoTZtoDate(modified), DATE_FMT.DATETIME)}</span>
                        </Td>
                        <Td textAlignRight className={styles.action}>
                          <Tippy
                            allowHTML
                            interactive
                            content={
                              <Interweave
                                content={coloredDefinition}
                                className={styles.tooltipContent}
                              />
                            }
                            placement="bottom"
                            className={styles.tooltip}
                          >
                            <span>
                              <IconButton
                                color="black"
                                icon="comment-code"
                                size="xs"
                                variant="outlined"
                                className={styles.preview}
                              />
                            </span>
                          </Tippy>
                          <Link to={getRoutePath("administration.metaAttributes.detail", { id })}>
                            <IconButton
                              color="black"
                              icon="pencil-alt"
                              size="xs"
                              tooltip="Edit"
                              variant="outlined"
                              data-testid="edit-button"
                            />
                          </Link>
                        </Td>
                      </TrSortable>
                    )
                  })}
                </TbodySortable>
              </Table>
              {isFetchingNextPage && <LoadingIndicator />}
              {!isFetchingNextPage && hasNextPage && <Waypoint onEnter={() => fetchNextPage()} />}
            </>
          )}
        </Paper>
      )}
    </Page>
  )
}

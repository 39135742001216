import { showToast } from "app/toast"
import { useAuthTokenStore, getCurrentUserId } from "auth/auth"
import { last, prop, propEq, reject, startsWith, whereEq } from "ramda"
import { useCallback } from "react"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import { SegmentExportDestination } from "resources/exportDestination/exportDestinationTypes"
import { Segment, SegmentType } from "resources/segment/segment/segmentTypes"
import { useFetchSegmentPermissionsForCurrentUser } from "resources/segmentPermission/segmentPermissionQueries"
import { ADMIN_ROLE_ID, PERMISSION } from "sharedConstants"
import { Permission } from "types/util"
import { invalidateFavoriteCustomers } from "../customer/search/customerSearchQueries"
import { hasAccessSelector } from "./hasAccessSelector"
import { useModifyUser, useUsersQuery } from "./userQueries"
import { FunnelGroup } from "resources/funnelGroup/funnelGroupTypes"
import { useFetchFunnelGroupPermissions } from "resources/funnelGroupPermission/funnelGroupPermissionQueries"

export function useFetchCurrentUser() {
  const currentUserId = useAuthTokenStore(getCurrentUserId)

  return useUsersQuery({
    select: users => users.find(propEq(currentUserId, "id")) ?? null,
    enabled: currentUserId !== null,
  })
}

const defaultHasAccess = hasAccessSelector([])

export function useHasAccess() {
  const currentUserId = useAuthTokenStore(getCurrentUserId)

  const queryResult = useUsersQuery({
    select: users => {
      const currentUser = users.find(propEq(currentUserId, "id")) ?? null

      return hasAccessSelector(currentUser?.role.features)
    },
    enabled: currentUserId !== null,
  })

  return queryResult.data ?? defaultHasAccess
}

export function useHasSegmentPermission() {
  // TODO: Refactor segment queries, then fetch the segment by id to find out if it's featured
  // instead of passing that in

  const hasAccess = useHasAccess()
  const { data: segmentPermissions } = useFetchSegmentPermissionsForCurrentUser()

  // Needs a stable reference because it's used in useEffect in SegmentDetail
  return useCallback(
    (
      segmentId: Segment["id"],
      opts: { segmentType: SegmentType } = { segmentType: "custom" },
    ): Permission | false => {
      const permission = segmentPermissions?.find(
        ({ segment_id }) => segment_id === segmentId,
      )?.permission

      if (opts.segmentType === "featured") {
        if (hasAccess.segments.featured.edit) {
          return PERMISSION.WRITE
        }

        if (permission) {
          return permission
        }

        if (hasAccess.segments.featured.view) {
          return PERMISSION.READ
        }

        return false
      }

      if (opts.segmentType === "smart") {
        if (hasAccess.setup.smartSegments) {
          return PERMISSION.WRITE
        }

        if (permission) {
          return permission
        }

        return PERMISSION.READ
      }

      // Permissions for lookalike segments are the same as custom segments

      if (hasAccess.segments.editForeign) {
        return PERMISSION.WRITE
      }

      if (permission) {
        return permission
      }

      if (hasAccess.segments.viewForeign) {
        return PERMISSION.READ
      }

      return false
    },
    [
      hasAccess.segments.editForeign,
      hasAccess.segments.featured.edit,
      hasAccess.segments.featured.view,
      hasAccess.segments.viewForeign,
      hasAccess.setup.smartSegments,
      segmentPermissions,
    ],
  )
}

export function useAllowedDestinationsIds(): SegmentExportDestination["id"][] {
  const { data: currentUser } = useFetchCurrentUser()
  const { data: destinations } = useFetchAllDestinations()

  if (!currentUser || !destinations) return []

  const { features } = currentUser.role

  if (features.includes("segments/export") || currentUser.role.id === ADMIN_ROLE_ID) {
    return destinations.map(prop("id"))
  }

  return features
    .filter(startsWith("segments/export/"))
    .map(feature => parseInt(last(feature.split("/"))!))
}

export function useToggleFavoriteCustomer() {
  const { data: currentUser } = useFetchCurrentUser()
  const { mutateAsync: modifyUserAsync } = useModifyUser()

  return async (customerId: string) => {
    const { id, cdp_settings } = currentUser!
    const favoriteCustomers = cdp_settings?.favourite_customers ?? []
    const isFavorite = favoriteCustomers.some(propEq(customerId, "customer_entity_id"))
    return modifyUserAsync(
      {
        id,
        data: {
          cdp_settings: {
            ...(cdp_settings ?? {}),
            favourite_customers: isFavorite
              ? reject(propEq(customerId, "customer_entity_id"), favoriteCustomers)
              : favoriteCustomers.concat({ customer_entity_id: customerId }),
          },
        },
      },
      {
        onSuccess: () => {
          showToast(
            isFavorite ? "Customer removed from favorites." : "Customer added to favorites.",
          )
          if (isFavorite) {
            invalidateFavoriteCustomers()
          }
        },
      },
    )
  }
}

export function useHasFunnelGroupEditPermission(funnelGroupId?: FunnelGroup["id"]) {
  // If I have access to the funnel group id, that means that I have at least view access to the
  // funnel group, so the funnel group permissions query will always be enabled
  const { data: groupPermissions } = useFetchFunnelGroupPermissions(funnelGroupId)
  const { data: currentUser } = useFetchCurrentUser()
  const hasAccess = useHasAccess()

  if (!groupPermissions || !currentUser) return false

  return (
    hasAccess.funnels.edit ||
    groupPermissions.funnel_group_user_roles.some(
      whereEq({ user_role_id: currentUser.role.id, permission: PERMISSION.WRITE }),
    )
  )
}

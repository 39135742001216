import {
  MetaAttribute,
  MetaAttributesListResponse,
  MetaAttributeModifyPayload,
  MetaAttributeCreatePayload,
  MetaAttributeResponse,
  MetaAttributeReorderPayload,
} from "./metaAttributesTypes"
import requestFactory from "api/request"

export const metaAttributes = {
  list({
    limit = 100,
    offset = 0,
  }: Partial<
    MetaAttributesListResponse["selection_settings"]
  >): Promise<MetaAttributesListResponse> {
    return requestFactory("GET", "/meta_attributes", { offset, limit })
  },
  retrieve(id: MetaAttribute["id"]): Promise<MetaAttributeResponse> {
    return requestFactory("GET", `/meta_attributes/${id}`)
  },
  create(data: MetaAttributeCreatePayload): Promise<MetaAttributeResponse> {
    return requestFactory("POST", "/meta_attributes", data)
  },
  modify(
    id: MetaAttribute["id"],
    data: MetaAttributeModifyPayload,
  ): Promise<MetaAttributeResponse> {
    return requestFactory("PATCH", `/meta_attributes/${id}`, data)
  },
  reorder(id: MetaAttribute["id"], data: MetaAttributeReorderPayload) {
    return requestFactory("PATCH", `/meta_attributes/${id}/reorder`, data, true)
  },
}

import React, { ReactNode, useRef, useState } from "react"
import classNames from "classnames"
import styles from "./Expandable.module.scss"
import useKeyListener from "hooks/useKeyListener"
import {
  COLLAPSED_NAV_BAR_WIDTH,
  EXPANDED_NAV_BAR_WIDTH,
  NAV_BAR_COLLAPSE_BREAKPOINT,
} from "sharedConstants"

type ExpandState = "collapsed" | "expandStart" | "expanding" | "expanded" | "collapseStart"
type PositionStyle = { top: number; bottom: number; left: number; right: number }

export type ExpandableRenderArgs = {
  expandState: ExpandState
  containerStyle: PositionStyle | undefined
  containerClassName: string
  toggleExpand: () => void
}

type ExpandableProps = {
  children: (args: ExpandableRenderArgs) => ReactNode
}

export default function Expandable({ children }: ExpandableProps) {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [expandState, setExpandState] = useState<ExpandState>("collapsed")
  const [originalRect, setOriginalRect] = useState<PositionStyle>({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  })

  function toggleExpand() {
    if (expandState === "collapsed") {
      const wrapperRect = wrapperRef.current!.getBoundingClientRect()
      setOriginalRect({
        top: wrapperRect.top,
        bottom: window.innerHeight - wrapperRect.bottom,
        left: wrapperRect.left,
        right: window.innerWidth - wrapperRect.right,
      })
      setExpandState("expandStart")
      setTimeout(() => setExpandState("expanding"), 20)
      setTimeout(() => setExpandState("expanded"), 200)
    } else if (expandState === "expanded") {
      setExpandState("collapseStart")
      setTimeout(() => setExpandState("collapsed"), 200)
    }
  }

  const navBarWidth =
    window.innerWidth >= NAV_BAR_COLLAPSE_BREAKPOINT
      ? EXPANDED_NAV_BAR_WIDTH
      : COLLAPSED_NAV_BAR_WIDTH
  const expandedWidth = Math.min(1160, window.innerWidth - navBarWidth)
  const expandedPosition = {
    top: 100,
    bottom: 100,
    left: (window.innerWidth - expandedWidth + navBarWidth) / 2,
    right: (window.innerWidth - expandedWidth - navBarWidth) / 2,
  }

  const containerStyle: Record<ExpandState, PositionStyle | undefined> = {
    collapsed: undefined,
    expandStart: originalRect,
    expanding: expandedPosition,
    expanded: expandedPosition,
    collapseStart: originalRect,
  }

  useKeyListener("Escape", () => {
    if (expandState === "expanded") {
      toggleExpand()
    }
  })

  return (
    <div
      className={classNames(styles.expandWrapper, {
        [styles.collapsed]: expandState === "collapsed",
        [styles.expandStart]: expandState === "expandStart",
        [styles.expanding]: expandState === "expanding",
        [styles.expanded]: expandState === "expanded",
        [styles.collapseStart]: expandState === "collapseStart",
      })}
      ref={wrapperRef}
    >
      <div onClick={toggleExpand} className={styles.overlay}></div>

      {children({
        expandState,
        containerStyle: containerStyle[expandState],
        containerClassName: styles.container,
        toggleExpand,
      })}
    </div>
  )
}

import Tippy from "@tippyjs/react"
import IconButton from "components/UI/elements/IconButton/IconButton"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { abbreviateNumber } from "helpers/number.helper"
import { timeAgoFormatter } from "helpers/timeAgo.helper"
import React, { useEffect } from "react"
import ReactTimeago from "react-timeago"
import styles from "./SegmentNumbers.module.scss"
import { emitSegmentCounts, useSegmentCountsStore } from "resources/segment/segment/segmentCounts"
import { ISOwoTZtoDate } from "utilities/date"
import { Segment } from "resources/segment/segment/segmentTypes"
import { isNil } from "ramda"

function percentageToArcCoordinates(percentage: number) {
  const angle = percentage * (Math.PI / 100)
  const dX = (-Math.cos(angle) + 1) / 2
  const dY = Math.sin(angle)
  return { dX, dY }
}

type SegmentNumbersProps = {
  hasUnsavedConditions: boolean
  segmentId: Segment["id"]
  refetch: () => void
}

export default function SegmentNumbers({
  hasUnsavedConditions,
  segmentId,
  refetch,
}: SegmentNumbersProps) {
  useEffect(() => {
    emitSegmentCounts({ segmentId })
  }, [segmentId])

  const { conditionsResultsCount, customersTotalCount, dataLoadEndTime, dataLoadStartTime } =
    useSegmentCountsStore()

  const isLoading =
    isNil(conditionsResultsCount) ||
    isNil(customersTotalCount) ||
    isNil(dataLoadEndTime) ||
    isNil(dataLoadStartTime)

  const segmentedPercentage =
    conditionsResultsCount === undefined || customersTotalCount === undefined
      ? null
      : Math.round(100 * (conditionsResultsCount / customersTotalCount))

  const percentageDisplayString =
    conditionsResultsCount !== undefined &&
    customersTotalCount !== undefined &&
    segmentedPercentage !== null &&
    `${
      segmentedPercentage === 0 && conditionsResultsCount !== 0
        ? "<1"
        : segmentedPercentage === 100 && conditionsResultsCount < customersTotalCount
        ? ">99"
        : segmentedPercentage
    } %`

  // SVG for percentage arcs
  const { dX = 0, dY = 0 } =
    (segmentedPercentage && percentageToArcCoordinates(segmentedPercentage)) || {}

  const VIEWBOX_MARGIN = 15
  const ARC_HEIGHT = 140
  const ARC_WIDTH = 300

  const X1 = VIEWBOX_MARGIN
  const Y1 = VIEWBOX_MARGIN + ARC_HEIGHT
  const rX = ARC_WIDTH / 2
  const rY = ARC_HEIGHT
  const X2grey = VIEWBOX_MARGIN + ARC_WIDTH
  const Y2grey = VIEWBOX_MARGIN + ARC_HEIGHT
  const X2primary = VIEWBOX_MARGIN + ARC_WIDTH * dX
  const Y2primary = VIEWBOX_MARGIN + ARC_HEIGHT * (1 - dY)

  const greyArcPath = `M${X1} ${Y1} A${rX} ${rY} 0 0 1 ${X2grey} ${Y2grey}`
  const primaryArcPath = `M${X1} ${Y1} A${rX} ${rY} 0 0 1 ${X2primary} ${Y2primary}`
  const viewBox = `0 0 ${2 * VIEWBOX_MARGIN + ARC_WIDTH} ${2 * VIEWBOX_MARGIN + ARC_HEIGHT}`

  return (
    <div className={styles.container}>
      <IconButton
        icon={["far", "sync-alt"]}
        tooltip="Refresh segment numbers"
        variant="outlined"
        className={styles.refreshIcon}
        disabled={isLoading || hasUnsavedConditions}
        onClick={() => refetch()}
      />
      <div className={styles.segmentedNumberWrapper}>
        <div className={styles.segmentedNumberInlineWrapper}>
          <span className={styles.segmentedNumber}>
            {isLoading ? (
              <LoadingIndicator size="xs" fixedWidth />
            ) : (
              <>
                {hasUnsavedConditions ? (
                  <Tippy content="Save segment conditions to see segmentation numbers.">
                    <span>N/A</span>
                  </Tippy>
                ) : (
                  <>
                    {conditionsResultsCount === undefined ? (
                      <span>N/A</span>
                    ) : (
                      <span>{abbreviateNumber(conditionsResultsCount)}</span>
                    )}
                  </>
                )}
              </>
            )}
          </span>
          <span>out of </span>
          {isLoading ? (
            <LoadingIndicator size="xs" fixedWidth />
          ) : (
            <>
              {hasUnsavedConditions ? (
                <Tippy content="Save segment conditions to see segmentation numbers.">
                  <span>N/A</span>
                </Tippy>
              ) : (
                <>
                  {customersTotalCount === undefined ? (
                    <span>N/A</span>
                  ) : (
                    <span>{abbreviateNumber(customersTotalCount)}</span>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className={styles.dataRefreshed}>
          {dataLoadEndTime && (
            <>
              Data refreshed:&nbsp;
              <ReactTimeago
                key="finished"
                date={ISOwoTZtoDate(dataLoadEndTime)}
                formatter={timeAgoFormatter({ seconds: "moment" })}
              />
            </>
          )}
          {dataLoadEndTime === undefined && dataLoadStartTime && (
            <>
              Data refresh started:&nbsp;
              <ReactTimeago
                key="inProgress"
                date={ISOwoTZtoDate(dataLoadStartTime)}
                formatter={timeAgoFormatter({ seconds: "moment" })}
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.percentageWrapper}>
        <div className={styles.percentage}>
          <div className={styles.percentageArcWrapper}>
            <svg viewBox={viewBox}>
              <path
                d={greyArcPath}
                strokeLinecap="round"
                strokeWidth="30"
                fill="none"
                stroke="#dddddd"
              />
              {!hasUnsavedConditions && segmentedPercentage && (
                <path
                  d={primaryArcPath}
                  strokeLinecap="round"
                  strokeWidth="30"
                  fill="none"
                  stroke="#fe7f66"
                />
              )}
            </svg>
          </div>

          {isLoading ? (
            <LoadingIndicator size="xs" fixedWidth />
          ) : (
            <>
              {hasUnsavedConditions ? (
                <Tippy content="Save segment conditions to see segmentation numbers.">
                  <span>N/A %</span>
                </Tippy>
              ) : (
                <>
                  {percentageDisplayString ? (
                    <span>{percentageDisplayString}</span>
                  ) : (
                    <span>N/A %</span>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className={styles.percentageSubtitle}>of all customers</div>
      </div>
    </div>
  )
}

import { useState } from "react"
import classNames from "classnames"
import styles from "./EventCondition.module.scss"
import { EventCondition as EventConditionType } from "resources/journey/journeyTypes"
import {
  Controller,
  FieldPath,
  FieldPathByValue,
  FieldValues,
  PathValue,
  useFormContext,
} from "react-hook-form"
import IconButton from "components/UI/elements/IconButton/IconButton"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { required } from "helpers/validators.helper"
import SelectField from "components/UI/elements/SelectField"
import { whereEq } from "ramda"
import { EventFull } from "resources/event/eventTypes"
import CustomDatePicker from "components/UI/components/CustomDatePicker"

type EventConditionProps<TFieldValues extends FieldValues> = {
  event: EventFull
  isEditable: boolean
  removeSelf?: () => void
  path: FieldPathByValue<TFieldValues, EventConditionType>
}

export default function EventCondition<TFieldValues extends FieldValues>({
  event,
  isEditable,
  removeSelf,
  path,
}: EventConditionProps<TFieldValues>) {
  const { control, watch, setValue } = useFormContext<TFieldValues>()

  const selectedParameterId = watch(`${path}.parameter_id` as FieldPath<TFieldValues>)
  const selectedOperator = watch(`${path}.operation` as FieldPath<TFieldValues>)
  const selectedParameter = event.schema.display.find(whereEq({ id: selectedParameterId }))
  const selectedDataType = selectedParameter?.type
  const operationOptions = selectedDataType ? operators[selectedDataType] : []
  const parameterOptions = event.schema.display.map(entry => ({
    value: entry.id,
    label: entry.name,
  }))
  const selectedOperationOption = operationOptions.find(whereEq({ value: selectedOperator }))
  const selectedOperationType = selectedOperationOption?.type

  const [isDatepicker1Open, setIsDatepicker1Open] = useState(false)
  const [isDatepicker2Open, setIsDatepicker2Open] = useState(false)

  return (
    <div className={styles.condition}>
      <div className={styles.inputs}>
        <div className={styles.inputsRow}>
          <Controller
            control={control}
            name={`${path}.parameter_id` as FieldPath<TFieldValues>}
            rules={{ validate: { required } }}
            render={({ field, fieldState: { error } }) => (
              <SelectField
                input={{
                  value: field.value,
                  onChange(value: string) {
                    setValue(
                      path as FieldPathByValue<TFieldValues, EventConditionType>,
                      {
                        parameter_id: value,
                        operation: "",
                        value: "",
                        max_value: "",
                        min_value: "",
                        values: [],
                      } as PathValue<
                        TFieldValues,
                        FieldPathByValue<TFieldValues, EventConditionType>
                      >,
                    )
                  },
                }}
                label="Parameter"
                options={parameterOptions}
                isSimpleValue
                error={error?.message}
                disabled={!isEditable}
              />
            )}
          />
          <Controller
            control={control}
            name={`${path}.operation` as FieldPath<TFieldValues>}
            rules={{ validate: { required } }}
            render={({ field, fieldState: { error } }) => (
              <SelectField
                input={{
                  value: field.value,
                  onChange(value: string) {
                    setValue(
                      path as FieldPathByValue<TFieldValues, EventConditionType>,
                      {
                        parameter_id: selectedParameterId,
                        operation: value,
                        value: "",
                        max_value: "",
                        min_value: "",
                        values: [],
                      } as PathValue<
                        TFieldValues,
                        FieldPathByValue<TFieldValues, EventConditionType>
                      >,
                    )
                  },
                }}
                label="Operation"
                options={operationOptions}
                isSimpleValue
                error={error?.message}
                disabled={!isEditable || !selectedParameterId}
                isSearchable={false}
              />
            )}
          />
        </div>

        <div className={styles.inputsRow}>
          {selectedDataType === "string" && selectedOperationType === "unary" && (
            <Controller
              control={control}
              name={`${path}.value` as FieldPath<TFieldValues>}
              rules={{ validate: { required } }}
              render={({ field, fieldState: { error } }) => (
                <TextInput label="Value" {...field} error={error?.message} disabled={!isEditable} />
              )}
            />
          )}

          {selectedDataType === "string" && selectedOperationType === "array" && (
            <Controller
              control={control}
              name={`${path}.values` as FieldPath<TFieldValues>}
              render={({ field }) => (
                <SelectField
                  input={field}
                  isSimpleValue
                  isMulti
                  options={[]}
                  isCreatable
                  label="Values"
                  disabled={!isEditable}
                />
              )}
            />
          )}

          {selectedDataType === "number" && selectedOperationType === "unary" && (
            <Controller
              control={control}
              name={`${path}.value` as FieldPath<TFieldValues>}
              rules={{ validate: { required } }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  label="Value"
                  {...field}
                  onChange={e => field.onChange(Number(e.target.value))}
                  error={error?.message}
                  disabled={!isEditable}
                  type="number"
                />
              )}
            />
          )}

          {selectedDataType === "number" && selectedOperationType === "binary" && (
            <>
              <Controller
                control={control}
                name={`${path}.min_value` as FieldPath<TFieldValues>}
                rules={{ validate: { required } }}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    label="Min value"
                    {...field}
                    onChange={e => field.onChange(Number(e.target.value))}
                    error={error?.message}
                    disabled={!isEditable}
                    type="number"
                  />
                )}
              />
              <Controller
                control={control}
                name={`${path}.max_value` as FieldPath<TFieldValues>}
                rules={{ validate: { required } }}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    label="Value"
                    {...field}
                    onChange={e => field.onChange(Number(e.target.value))}
                    error={error?.message}
                    disabled={!isEditable}
                    type="number"
                  />
                )}
              />
            </>
          )}

          {selectedDataType === "number" && selectedOperationType === "array" && (
            <Controller
              control={control}
              name={`${path}.values` as FieldPath<TFieldValues>}
              render={({ field }) => (
                <SelectField
                  input={{
                    value: field.value,
                    onChange(values: string[]) {
                      field.onChange(values.map(value => Number(value)))
                    },
                  }}
                  isSimpleValue
                  isMulti
                  options={[]}
                  isCreatable
                  label="Values"
                  disabled={!isEditable}
                />
              )}
            />
          )}

          {selectedDataType === "datetime" && selectedOperationType === "unary" && (
            <Controller
              control={control}
              name={`${path}.value` as FieldPath<TFieldValues>}
              rules={{ validate: { required } }}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <div className={styles.label}>Date</div>
                  <div className="text-field">
                    <CustomDatePicker
                      value={field.value}
                      open={isDatepicker1Open}
                      isEditable={isEditable}
                      onChange={field.onChange}
                      toggle={() => setIsDatepicker1Open(s => !s)}
                      className={classNames(styles.datePicker, {
                        [styles.error]: error?.message,
                      })}
                      dataType="datetime"
                      placeholder="Pick date"
                    />
                  </div>
                </div>
              )}
            />
          )}

          {selectedDataType === "datetime" && selectedOperationType === "binary" && (
            <>
              <Controller
                control={control}
                name={`${path}.min_value` as FieldPath<TFieldValues>}
                rules={{ validate: { required } }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <div className={styles.label}>Since</div>
                    <div className="text-field">
                      <CustomDatePicker
                        value={field.value}
                        open={isDatepicker1Open}
                        isEditable={isEditable}
                        onChange={field.onChange}
                        toggle={() => setIsDatepicker1Open(s => !s)}
                        className={classNames(styles.datePicker, {
                          [styles.error]: error?.message,
                        })}
                        dataType="datetime"
                        placeholder="Pick minimum date"
                      />
                    </div>
                  </div>
                )}
              />
              <Controller
                control={control}
                name={`${path}.max_value` as FieldPath<TFieldValues>}
                rules={{ validate: { required } }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <div className={styles.label}>Until</div>
                    <div className="text-field">
                      <CustomDatePicker
                        value={field.value}
                        open={isDatepicker2Open}
                        isEditable={isEditable}
                        onChange={field.onChange}
                        toggle={() => setIsDatepicker2Open(s => !s)}
                        className={classNames(styles.datePicker, {
                          [styles.error]: error?.message,
                        })}
                        dataType="datetime"
                        placeholder="Pick maximum date"
                      />
                    </div>
                  </div>
                )}
              />
            </>
          )}

          {selectedDataType === "datetime" && selectedOperationType === "no_value" && <></>}
        </div>
      </div>

      {removeSelf && isEditable && (
        <IconButton
          onClick={removeSelf}
          icon={["fas", "trash-alt"]}
          variant="outlined"
          color="red"
          size="xs"
        />
      )}
    </div>
  )
}

const operators = {
  string: [
    { value: "equals", label: "Equals", type: "unary" },
    { value: "not_equal", label: "Not equal", type: "unary" },
    { value: "contains", label: "Contains", type: "unary" },
    { value: "not_contain", label: "Not contain", type: "unary" },
    { value: "equals_any_of", label: "Equals any of", type: "array" },
    { value: "not_equal_any_of", label: "Doesn't equal any of", type: "array" },
    { value: "contains_any_of", label: "Contains any of", type: "array" },
    { value: "not_contain_any_of", label: "Doesn't contain any of", type: "array" },
  ],
  number: [
    { value: "equals", label: "Equals", type: "unary" },
    { value: "not_equal", label: "Not equal", type: "unary" },
    { value: "lower_than", label: "Lower than", type: "unary" },
    { value: "greater_than", label: "Greater than", type: "unary" },
    { value: "between", label: "Between", type: "binary" },
    { value: "equals_any_of", label: "Equals any of", type: "array" },
    { value: "not_equal_any_of", label: "Doesn't equal any of", type: "array" },
  ],
  datetime: [
    { value: "exactly", label: "Exactly", type: "unary" },
    { value: "since", label: "Since", type: "unary" },
    { value: "until", label: "Until", type: "unary" },
    { value: "since_until", label: "Since/until", type: "binary" },
    { value: "match_current_day", label: "Matches current day", type: "no_value" },
    { value: "match_current_month", label: "Matches current month", type: "no_value" },
    { value: "match_current_year", label: "Matches current year", type: "no_value" },
  ],
}

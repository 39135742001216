import React, { useState } from "react"
import classNames from "classnames"
import styles from "./ChartCard.module.scss"
import { ExpandableRenderArgs } from "../Expandable/Expandable"
import Paper from "components/UI/elements/Paper"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { Chart, ChartsDataResponse } from "resources/funnelChart/funnelChartTypes"
import { getRoutePath } from "routes"
import { Link } from "react-router-dom"
import { useDeleteFunnelChart } from "resources/funnelChart/funnelChartQueries"
import ConfirmModal from "components/UI/components/ConfirmModal"
import LineChart from "../LineChart/LineChart"
import { COLOR } from "sharedConstants"
import FunnelChart from "../FunnelChart/FunnelChart"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"

type ChartCardProps = {
  chart: Chart
  data?: ChartsDataResponse
  isEditable: boolean
  funnelGroupId: string
  isLoading: boolean
} & ExpandableRenderArgs

export default function ChartCard({
  containerStyle,
  containerClassName,
  expandState,
  toggleExpand,
  chart,
  funnelGroupId,
  data,
  isEditable,
  isLoading,
}: ChartCardProps) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const deleteMutation = useDeleteFunnelChart()
  function deleteChart() {
    deleteMutation.mutate({ chartId: chart.id, funnelGroupId })
  }

  const hexColor = COLOR[(chart.frontend_settings.color ?? "primary") as keyof typeof COLOR]

  return (
    <Paper className={classNames(styles.container, containerClassName)} style={containerStyle}>
      <ConfirmModal
        title="Are you sure?"
        open={isDeleteModalOpen}
        type="delete"
        text={`Do you really want to delete chart ${chart.name}?`}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={deleteChart}
        isLoading={deleteMutation.isLoading}
      />

      <div className={styles.header}>
        <div
          className={classNames(styles.title, { [styles.expanded]: expandState === "expanded" })}
          style={{ color: hexColor }}
        >
          {chart.name}
        </div>

        <div className={styles.buttons}>
          {isEditable && expandState === "collapsed" && (
            <>
              <IconButton
                icon={["fas", "grip-vertical"]}
                color="grey"
                size="xs"
                variant="outlined"
                data-drag-handle
                className={styles.dragHandle}
                tooltip="Drag to reorder"
              />
              <Link
                to={getRoutePath("analytics.funnels.group.chart-detail", {
                  groupId: funnelGroupId,
                  id: chart.id,
                })}
              >
                <IconButton
                  icon={["far", "pencil"]}
                  tooltip="Edit"
                  color="grey"
                  size="xs"
                  variant="outlined"
                />
              </Link>
              <IconButton
                icon={["far", "trash-alt"]}
                color="grey"
                size="xs"
                variant="outlined"
                onClick={() => setIsDeleteModalOpen(true)}
                tooltip="Delete"
              />
            </>
          )}
          <IconButton
            onClick={toggleExpand}
            icon={["far", expandState === "collapsed" ? "expand-alt" : "times"]}
            tooltip={expandState === "collapsed" ? "Expand" : "Close"}
            color="grey"
            size="xs"
            variant="outlined"
          />
        </div>
      </div>
      <div className={styles.chartWrapper}>
        {isLoading && <LoadingIndicator className={styles.spinner} />}

        {data?.error && <h4 className={styles.errorMessage}>{data.error}</h4>}

        {!isLoading && !data?.error && !data?.data?.length && (
          <h4 className={styles.errorMessage}>There is insufficient data to view this chart.</h4>
        )}

        {!!data?.data?.length &&
          !isLoading &&
          (chart.type === "LINE" ? (
            <LineChart
              id={chart.id}
              data={data.data}
              interval={data.interval}
              color={hexColor}
              expanded={expandState === "expanded"}
            />
          ) : (
            <FunnelChart
              data={data.data.map(({ value }, index) => ({
                value,
                label: chart.definition.chart_attributes[index].data_dimension_title,
                id: chart.definition.chart_attributes[index].data_dimension.dimension_id,
              }))}
              interval={data.interval}
              color={hexColor}
              expanded={expandState === "expanded"}
            />
          ))}
      </div>
      {expandState === "expanded" && chart.description && (
        <Paper className={styles.description}>
          <h4>description:</h4>
          <p>{chart.description}</p>
        </Paper>
      )}
    </Paper>
  )
}

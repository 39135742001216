import React, { Fragment } from "react"
import styles from "./NodeTree.module.scss"
import { isMultiChildrenNode, JourneyNodeTree } from "resources/journey/nodeTree"
import EntryNode from "../../nodes/Entry/EntryNode"
import AddNodeButton from "../AddNodeButton/AddNodeButton"
import WaitDelayNode from "../../nodes/WaitDelay/WaitDelayNode"
import ActivateEmailNode from "../../nodes/ActivateEmail/ActivateEmailNode"
import SplitNode from "../../nodes/Split/SplitNode"
import { NODE_CARD_WIDTH } from "../NodeCard/NodeCard"
import WaitSlotNode from "../../nodes/WaitSlot/WaitSlotNode"
import ActivatePushNode from "../../nodes/ActivatePush/ActivatePushNode"
import ActivateWebhookNode from "../../nodes/ActivateWebhook/ActivateWebhookNode"
import ConditionNode from "../../nodes/Condition/ConditionNode"
import classNames from "classnames"

const BRANCH_GAP = 30

type NodeTreeProps = {
  tree: JourneyNodeTree
  journeyId: string
  isEditable: boolean
}

export default function NodeTree({
  tree: { node, children, width },
  journeyId,
  isEditable,
}: NodeTreeProps) {
  if (!node) {
    return null
  }

  return (
    <div className={styles.tree} style={{ minWidth: `${NODE_CARD_WIDTH}px` }}>
      {node.node_type === "entry" && (
        <EntryNode node={node} journeyId={journeyId} isEditable={isEditable} />
      )}

      {node.node_type === "flow_wait_delay" && (
        <WaitDelayNode node={node} journeyId={journeyId} isEditable={isEditable} />
      )}

      {node.node_type === "flow_wait_slot" && (
        <WaitSlotNode node={node} journeyId={journeyId} isEditable={isEditable} />
      )}

      {node.node_type === "activate_email" && (
        <ActivateEmailNode node={node} journeyId={journeyId} isEditable={isEditable} />
      )}

      {node.node_type === "activate_push" && (
        <ActivatePushNode node={node} journeyId={journeyId} isEditable={isEditable} />
      )}

      {node.node_type === "activate_webhook" && (
        <ActivateWebhookNode node={node} journeyId={journeyId} isEditable={isEditable} />
      )}

      {node.node_type === "flow_split" && (
        <SplitNode node={node} journeyId={journeyId} isEditable={isEditable} />
      )}

      {node.node_type === "flow_condition" && (
        <ConditionNode node={node} journeyId={journeyId} isEditable={isEditable} />
      )}

      {!isMultiChildrenNode(node) &&
        (isEditable ? (
          <>
            <VerticalLine />
            <AddNodeButton journeyId={journeyId} parentNodeId={node.id} index={0} />
            {children[0].node && (
              <>
                <VerticalLine />
                <NodeTree
                  key={children[0].node.id}
                  tree={children[0]}
                  journeyId={journeyId}
                  isEditable={isEditable}
                />
              </>
            )}
          </>
        ) : (
          children[0].node && (
            <>
              <VerticalLine length={40} />
              <NodeTree
                key={children[0].node.id}
                tree={children[0]}
                journeyId={journeyId}
                isEditable={isEditable}
              />
            </>
          )
        ))}

      {isMultiChildrenNode(node) && (
        <>
          <Lines totalWidth={width} widths={children.map(child => child?.width ?? 1)} />

          <div className={styles.children}>
            {children.map(child => (
              <div
                className={styles.child}
                style={{ minWidth: `${NODE_CARD_WIDTH}px` }}
                key={child.pathIndex}
              >
                {node.node_type === "flow_split" && (
                  <div className={styles.pathLabel}>{node.settings!.paths[child.pathIndex]} %</div>
                )}

                {node.node_type === "flow_condition" && (
                  <div
                    className={classNames(
                      styles.pathLabel,
                      child.pathIndex === 0 ? styles.default : styles.condition,
                    )}
                  >
                    {child.pathIndex === 0 ? "Not matching" : `Condition ${child.pathIndex}`}
                  </div>
                )}

                {isEditable ? (
                  <>
                    <VerticalLine />
                    <AddNodeButton
                      journeyId={journeyId}
                      parentNodeId={node.id}
                      index={child.pathIndex}
                    />
                    {child.node && (
                      <>
                        <VerticalLine />
                        <NodeTree
                          key={child.node.id}
                          tree={child}
                          journeyId={journeyId}
                          isEditable={isEditable}
                        />
                      </>
                    )}
                  </>
                ) : (
                  child.node && (
                    <>
                      <VerticalLine />
                      <NodeTree
                        key={child.node.id}
                        tree={child}
                        journeyId={journeyId}
                        isEditable={isEditable}
                      />
                    </>
                  )
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

function VerticalLine({ length = 8 }: { length?: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1" height={length} viewBox={`0 0 1 ${length}`}>
      <line x1="0" y1="0" x2="0" y2={length} stroke="#ccc" strokeWidth="1" />
    </svg>
  )
}

function Lines({ totalWidth, widths }: { totalWidth: number; widths: number[] }) {
  const svgWidth = totalWidth * NODE_CARD_WIDTH + (totalWidth - 1) * BRANCH_GAP
  const svgHeight = 35

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgWidth}
      height={svgHeight}
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
    >
      {widths.map((width, index) => {
        const startX = svgWidth / 2
        const targetX =
          widths.slice(0, index).reduce((acc, el) => acc + el, 0) * NODE_CARD_WIDTH +
          widths.slice(0, index).reduce((acc, el) => acc + (el - 1), 0) * BRANCH_GAP +
          BRANCH_GAP * index +
          (width * NODE_CARD_WIDTH + (width - 1) * BRANCH_GAP) / 2

        if (startX === targetX) {
          return (
            <line
              key={index}
              x1={startX}
              y1="0"
              x2={targetX}
              y2={svgHeight}
              stroke="#ccc"
              strokeWidth="1"
            />
          )
        }
        let arc1: string
        let horizontalLine: string
        let arc2: string
        if (targetX < startX) {
          arc1 = "a 10 10 0 0 1 -10 10"
          horizontalLine = `H ${targetX + 10}`
          arc2 = "a 10 10 0 0 0 -10 10"
        } else {
          arc1 = "a 10 10 0 0 0 10 10"
          horizontalLine = `H ${targetX - 10}`
          arc2 = "a 10 10 0 0 1 10 10"
        }

        return (
          <path
            key={index}
            d={`M ${startX} 0 V 5 ${arc1} ${horizontalLine} ${arc2} V ${svgHeight}`}
            stroke="#ccc"
            strokeWidth="1"
            fill="none"
          />
        )
      })}
    </svg>
  )
}

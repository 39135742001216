import React, { useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import DestinationDetail from "./DestinationDetail/DestinationDetail"
import DestinationsList, { useFiltersStore } from "./DestinationsList/DestinationsList"
import { useHasAccess } from "resources/user/currentUserQueries"
import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import Trash from "pages/Trash/Trash"
import {
  useFetchExportDestinationTrashItems,
  useRestoreExportDestination,
} from "resources/exportDestination/exportDestinationQueries"

export default function DestinationsSetup() {
  const hasAccess = useHasAccess()

  const { reset } = useFiltersStore() as { reset: () => void }
  useEffect(() => reset, [reset])

  return (
    <Switch>
      <Route
        path={getRoutePath("administration.destinations")}
        exact
        component={DestinationsList}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation}
        path={getRoutePath("administartion.destinations.trash")}
        exact
        render={() => (
          <Trash
            backRoute={getRoutePath("administration.destinations")}
            itemType="segment_export_destinations"
            fetchQuery={useFetchExportDestinationTrashItems}
            restoreMutation={useRestoreExportDestination}
          />
        )}
      />
      <Route
        path={getRoutePath("administration.destinations.detail")}
        component={DestinationDetail}
      />
    </Switch>
  )
}

import React from "react"
import classNames from "classnames"
import { update, whereEq } from "ramda"

import InfoTooltip from "components/UI/elements/InfoTooltip"
import Paper from "components/UI/elements/Paper"
import SearchField from "components/UI/elements/SearchField"
import { hexToRgba } from "helpers/chartjs.helper"
import { useFetchIdentityGraphStitchingCategories } from "resources/stitchingCategory/stitchingCategoryQueries"
import { StitchingCategory } from "resources/stitchingCategory/stitchingCategoryTypes"
import { ActiveCategories } from "../types"

import styles from "./IdentityGraphFilter.module.scss"

type IdentityGraphFilterProps = {
  activeCategories: ActiveCategories
  searchIdentifier: string
  setActiveCategories: (newValue: ActiveCategories) => void
  setSearchIdentifier: (searchIdentifier: string) => void
}

export default function IdentityGraphFilter({
  activeCategories,
  searchIdentifier,
  setActiveCategories,
  setSearchIdentifier,
}: IdentityGraphFilterProps) {
  const { data: stitchingCategories = [] } = useFetchIdentityGraphStitchingCategories()

  const toggleIdentifier = (categoryId: StitchingCategory["id"]) => {
    const identifierIndex = activeCategories.findIndex(whereEq({ id: categoryId }))
    const identifier = activeCategories[identifierIndex]

    const newValue = update(
      identifierIndex,
      { ...identifier, active: !identifier.active },
      activeCategories,
    )
    setActiveCategories(newValue)
  }

  return (
    <div className={styles.container}>
      <Paper hasHeader={true} className={styles.searchFieldPaper}>
        <h3>
          <span>
            Identity graph{" "}
            <InfoTooltip placement="right" className={styles.infoTooltip} interactive>
              Identity Graph shows how your customer entity has been stitched from various
              identifiers across data sources.{" "}
              <a
                href="https://docs.meiro.io/books/meiro-knowledgebase/page/identity-stitching-and-how-customer-identity-appears"
                target="_blank"
                rel="noreferrer noopener"
              >
                Learn more here
              </a>
              .
            </InfoTooltip>
          </span>
        </h3>

        <SearchField
          placeholder="Search for identifier value"
          type="text"
          input={{
            value: searchIdentifier,
            onChange: (value: string) => setSearchIdentifier(value),
          }}
          onClear={() => setSearchIdentifier("")}
          size="large"
          wrapperClassName={styles.searchFieldWrapper}
        />
      </Paper>

      {activeCategories.length > 0 && stitchingCategories.length > 0 && (
        <Paper className={styles.identifiersPaper}>
          <div data-testid="filter-identifier" className={styles.label}>
            Filter identifier:
          </div>
          <div className={styles.identifiers}>
            {stitchingCategories.map(category => {
              const graphIdentifier = activeCategories.find(whereEq({ id: category.id }))
              if (!graphIdentifier) return null

              return (
                <div key={category.id} className={styles.identifier}>
                  <div
                    className={classNames(styles.dot, {
                      [styles.active]: graphIdentifier.active,
                    })}
                    style={{
                      backgroundColor: hexToRgba(category.identity_graph_color, 0.5),
                      borderColor: hexToRgba(category.identity_graph_color, 1),
                    }}
                  />

                  <button
                    data-testid={`filter-category-${category.id}`}
                    className={classNames(styles.button, {
                      [styles.active]: graphIdentifier.active,
                    })}
                    style={{ color: hexToRgba(category.identity_graph_color, 1) }}
                    onClick={() => toggleIdentifier(category.id)}
                    type="button"
                  >
                    {category.name}
                  </button>
                </div>
              )
            })}
          </div>
        </Paper>
      )}
    </div>
  )
}

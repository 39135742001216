import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ComponentPropsWithoutRef } from "react"
import NodeCard from "../../components/NodeCard/NodeCard"

const icon = <FontAwesomeIcon icon={["fas", "webhook"]} color="#f9b549" />

export default function ActivateWebhookNodeCard(
  props: Omit<ComponentPropsWithoutRef<typeof NodeCard>, "title" | "icon">,
) {
  return <NodeCard title="Activate webhook" icon={icon} {...props} />
}

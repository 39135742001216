import { useQuery } from "@tanstack/react-query"
import { queryClient } from "app/queryClient"
import { Attribute } from "resources/attribute/attributeTypes"
import api from "resources/endpoints"
import { useHasAccess } from "resources/user/currentUserQueries"
import create from "zustand"

const initialState = { searchTerm: "", attributeId: null, isFavoritesList: true }

export const useCustomerSearchStore = create(set => ({
  ...initialState,

  setSearch: ({ searchTerm, attributeId }: { searchTerm?: string; attributeId: Attribute["id"] }) =>
    set({ searchTerm, attributeId, isFavoritesList: false }),

  reset: () => set(initialState),
}))

function useFetchCustomerFulltextSearch({
  searchTerm,
  enabled,
}: {
  searchTerm?: string
  enabled: boolean
}) {
  return useQuery(
    ["customerSearch", "fulltext", searchTerm],
    () => api.customers.searchFulltext({ searchTerm }),
    {
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    },
  )
}

function useFetchCustomerAttributeSearch({
  searchTerm,
  attributeId,
  enabled,
}: {
  searchTerm?: string
  attributeId: Attribute["id"]
  enabled: boolean
}) {
  return useQuery(
    ["customerSearch", "attribute", searchTerm, attributeId],
    () => api.customers.searchByAttribute({ searchTerm, attributeId }),
    {
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    },
  )
}

export function invalidateFavoriteCustomers() {
  queryClient.invalidateQueries(["customerSearch", "favorite"])
}

function useFetchFavoriteCustomers() {
  const hasAccess = useHasAccess()

  return useQuery(["customerSearch", "favorite"], () => api.customers.listFavorites(), {
    enabled: hasAccess.customers.search,
  })
}

export function useCustomerSearch({
  searchTerm,
  attributeId,
  isFavoritesList,
}: {
  searchTerm?: string
  attributeId: Attribute["id"] | null
  isFavoritesList: boolean
}) {
  const isAttributeSearch = !!attributeId
  // Favorites search is always enabled because it runs automatically on page load
  const { data: favoritesData, isLoading: isLoadingFavorites } = useFetchFavoriteCustomers()
  const {
    data: fulltextSearchData,
    isLoading: isLoadingFulltextSearch,
    isFetching: isFetchingFulltextSearch,
    error: errorFulltextSearch,
  } = useFetchCustomerFulltextSearch({
    searchTerm,
    enabled: !isFavoritesList && !isAttributeSearch,
  })
  const {
    data: attributeSearchData,
    isLoading: isLoadingAttributeSearch,
    isFetching: isFetchingAttributeSearch,

    error: errorAttributeSearch,
  } = useFetchCustomerAttributeSearch({
    attributeId: attributeId!,
    searchTerm,
    enabled: !isFavoritesList && isAttributeSearch,
  })

  const data = isFavoritesList
    ? favoritesData
    : isAttributeSearch
    ? attributeSearchData
    : fulltextSearchData
  const isLoading = isFavoritesList
    ? isLoadingFavorites
    : isAttributeSearch
    ? isLoadingAttributeSearch && isFetchingAttributeSearch
    : isLoadingFulltextSearch && isFetchingFulltextSearch
  const error = isAttributeSearch ? errorAttributeSearch : errorFulltextSearch

  return { data, isLoading, error }
}

import React from "react"
import styles from "./SystemBadge.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function SystemBadge() {
  return (
    <span className={styles.badge}>
      <FontAwesomeIcon icon={["fas", "computer"]} /> System
    </span>
  )
}

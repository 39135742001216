import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"
import Page from "components/UI/Page/Page"
import EmailsImg from "../EmailsImg.jpg"

import styles from "./EmailsReadyToUse.module.scss"

type EmailsReadyToUseProps = {
  pageTitle?: string
}

export default function EmailsReadyToUse({ pageTitle = "Emails" }: EmailsReadyToUseProps) {
  return (
    <Page title={pageTitle}>
      <MarketingContent img={{ alt: "Emails", src: EmailsImg }}>
        <h1>Unlock the power of the Meiro Email Channel!</h1>
        <strong>
          For activating the channel, reach our team at{" "}
          <a href="mailto:support@meiro.io" target="_blank" rel="noreferrer">
            support@meiro.io
          </a>{" "}
          or connect directly with your dedicated Meiro Project Manager.
        </strong>
        <p>
          Harness the capabilities of Meiro in our Email channel to craft{" "}
          <strong className={styles.primary}>personalized email campaigns</strong>. Orchestrate your
          emails in real-time, using distinctive customer attributes such as name, suggested
          products, items left in the basket, and much more. Use our intuitive drag-and-drop visual
          editor or custom HTML.
        </p>
        <p>
          <strong>Discover the Potential:</strong> Dive into a variety of examples and{" "}
          <a
            href="https://docs.meiro.io/books/meiro-business-explorer/page/emails-use-cases"
            target="_blank"
            rel="noreferrer"
          >
            use cases
          </a>{" "}
          to get inspired and elevate your email marketing strategy!
        </p>
      </MarketingContent>
    </Page>
  )
}

import React from "react"
import classNames from "classnames"
import Linkify from "react-linkify"
import AttributeOverview from "./AttributeOverview/AttributeOverview"
import AttributeTable from "./AttributeTable/AttributeTable"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Page, { PageProps } from "components/UI/Page/Page"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import StatusElement from "components/UI/elements/StatusElement"
import Username from "components/Username/Username"
import { DBFormatDateTime, ExportStatus } from "types/util"
import { User } from "resources/user/userTypes"
import { ConditionTree } from "types/conditionTree"
import { SegmentCondition } from "resources/segment/segment/segmentConditionsTypes"
import styles from "./ExportLog.module.scss"
import Table from "components/Table/Table"
import Datetime from "components/UI/elements/Datetime/Datetime"

type ExportLogProps = {
  conditionsOperation: ConditionTree<SegmentCondition> | null
  goBackRoute: PageProps["backRouteFallback"]
  isLoadingExport: boolean
  name: string
  stats: { statuses_history: { [K in ExportStatus]: DBFormatDateTime } } | null
  userId: User["id"]
  hasMoreLogs?: boolean
  isFetchingLogs?: boolean
  isLoadingLogs?: boolean
  logs?: Array<{
    id: number
    level: "info" | "error"
    text: string
    created: DBFormatDateTime
  }>
  loadMoreLogs?: () => void
}

export default function ExportLog({
  conditionsOperation,
  goBackRoute,
  isLoadingExport,
  name,
  stats,
  userId,
  loadMoreLogs,
  hasMoreLogs = false,
  isFetchingLogs = false,
  isLoadingLogs = false,
  logs,
}: ExportLogProps) {
  if (isLoadingExport) return <LoadingIndicator />

  return (
    <Page title={name} backRouteFallback={goBackRoute}>
      <div className={styles.details}>
        <div className={styles.timeline}>
          <PaperHeader size="small" titleText="Timeline"></PaperHeader>
          {stats != null && (
            <Paper hasHeader={true} className={styles.timelineContent} noPaddingTop>
              <Table
                data={Object.entries(stats.statuses_history).map(([status, timestamp]) => ({
                  id: timestamp,
                  status,
                  timestamp,
                }))}
                columns={[
                  {
                    id: "status",
                    label: "Status",
                    gridTemplate: "1fr",
                    renderCell: ({ status }) => <StatusElement status={status} />,
                  },
                  {
                    id: "timestamp",
                    label: "Date",
                    gridTemplate: "max-content",
                    renderCell: ({ timestamp }) => (
                      <Datetime datetime={timestamp} precision="second" />
                    ),
                  },
                  {
                    id: "user",
                    label: "User",
                    gridTemplate: "max-content",
                    renderCell: _ => <Username userId={userId} />,
                  },
                ]}
              />
            </Paper>
          )}
        </div>
        <div className={styles.attributes}>
          <PaperHeader size="small" titleText="Attributes" />
          <Paper hasHeader={true}>
            {conditionsOperation !== null ? (
              <>
                <AttributeOverview conditionTree={conditionsOperation} />
                <AttributeTable conditionTree={conditionsOperation} />
              </>
            ) : (
              <div className={styles.noConditions}>No conditions defined.</div>
            )}
          </Paper>
        </div>
      </div>
      {isLoadingLogs && <LoadingIndicator />}
      {!isLoadingLogs && logs && (
        <div className={styles.exportLogs}>
          <PaperHeader
            size="small"
            titleText="Logs"
            subTitleText="Segment export logs are archived after 30 days."
          />

          <Paper hasHeader={true} noPaddingTop>
            <Table
              data={logs}
              columns={[
                {
                  id: "level",
                  label: "Level",
                  gridTemplate: "max-content",
                  renderCell: ({ level }) => <div className={styles.logStatus}>{level}</div>,
                },
                {
                  id: "timestamp",
                  label: "Time",
                  gridTemplate: "max-content",
                  renderCell: ({ created }) => <Datetime datetime={created} precision="second" />,
                },
                {
                  id: "text",
                  label: "Text",
                  gridTemplate: "1fr",
                  renderCell: ({ text }) => (
                    <div className={styles.text}>
                      <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                          <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                          </a>
                        )}
                      >
                        {text}
                      </Linkify>
                    </div>
                  ),
                },
              ]}
              fetchNextPage={loadMoreLogs}
              isFetchingNextPage={isFetchingLogs}
              hasNextPage={hasMoreLogs}
              getRowClassName={({ level }) =>
                classNames(styles.row, { [styles.error]: level === "error" })
              }
            />
          </Paper>
        </div>
      )}
    </Page>
  )
}

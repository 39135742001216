import React, { useState } from "react"
import styles from "./TransformationsList.module.scss"
import { useFieldArray, useFormContext } from "react-hook-form"
import { EventFormValues } from "../EventForm/EventForm"
import {
  useSensors,
  useSensor,
  PointerSensor,
  DndContext,
  rectIntersection,
  DragOverlay,
  DragStartEvent,
  DragOverEvent,
} from "@dnd-kit/core"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import SortableItem from "components/ConditionBuilder/components/SortableItem/SortableItem"
import Transformation from "../Transformation/Transformation"
import Button from "components/UI/elements/Button/Button"

export default function TransformationsList({ isEditable = true }) {
  const { control } = useFormContext<EventFormValues>()
  const { fields, append, remove, swap } = useFieldArray({
    name: "auto_load.transformations",
    control,
  })
  const [activeId, setActiveId] = useState<string | null>(null)
  const activeIndex = (activeId && fields.findIndex(({ id }) => id === activeId)) as number | null

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id)
  }

  const handleDragMove = ({ active, over }: DragOverEvent) => {
    const activeRect = active.rect.current.translated

    if (over === null || !activeRect) {
      return
    }
    const middleOfActive = activeRect.top + activeRect.height / 2 + window.scrollY
    const overIndex = fields.findIndex(({ id }) => id === over.id)

    if (
      (activeIndex! > overIndex && middleOfActive < over.rect.offsetTop + over.rect.height) ||
      (activeIndex! < overIndex && middleOfActive > over.rect.offsetTop)
    ) {
      swap(activeIndex!, overIndex)
    }
  }

  const handleDragEnd = () => {
    setActiveId(null)
  }

  const sensors = useSensors(useSensor(PointerSensor))
  return (
    <div data-testid="transformation-content" className={styles.container}>
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={fields} strategy={verticalListSortingStrategy}>
          {fields.map((field, index) => (
            <SortableItem
              key={field.id}
              id={field.id}
              render={({ listeners }) => (
                <Transformation
                  index={index}
                  dragListeners={listeners}
                  removeSelf={() => remove(index)}
                  isEditable={isEditable}
                />
              )}
            />
          ))}
        </SortableContext>
        <DragOverlay dropAnimation={null}>
          {activeId ? <Transformation index={activeIndex!} /> : null}
        </DragOverlay>
      </DndContext>
      {isEditable && (
        <Button
          size="sm"
          onClick={() => append({ enabled: true, type: "Move", source: [""], destination: [""] })}
          className={styles.addTransformationButton}
        >
          + Add transformation
        </Button>
      )}
    </div>
  )
}

import React, { useCallback, useState } from "react"
import classNames from "classnames"
import styles from "./EditableTextArea.module.scss"
import { Validate, useForm } from "react-hook-form"
import useToggle from "hooks/useToggle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import TextArea from "components/UI/elements/TextArea/TextArea"

export type EditableTextAreaProps = {
  initValue: string
  onChange: (value: string) => any | Promise<any>
  validate?: Validate<string, unknown> | Record<string, Validate<string, unknown>> | undefined
  className?: string
  inputClassName?: string
  label?: string
  maxLength?: number
  minLength?: number
  rows?: number
}

export default function EditableTextArea({
  className,
  initValue,
  inputClassName,
  label,
  maxLength,
  minLength,
  onChange,
  validate,
  rows = 6,
}: EditableTextAreaProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" })
  const [isEditing, toggleIsEditing] = useToggle(false)
  const [isSaving, setIsSaving] = useState(false)

  const save = useCallback(
    async ({ value }) => {
      if (value !== initValue) {
        setIsSaving(true)
        await onChange(value)
        setIsSaving(false)
      }
      toggleIsEditing()
    },
    [initValue, onChange, toggleIsEditing],
  )

  const escListener = useCallback(
    e => {
      if (e.key === "Escape") toggleIsEditing()
    },
    [toggleIsEditing],
  )

  return isEditing ? (
    <form onSubmit={handleSubmit(save)}>
      <div className={styles.edit}>
        <TextArea
          defaultValue={initValue}
          error={errors.value?.message as string | undefined}
          className={classNames(styles.textArea, inputClassName)}
          onKeyUp={escListener}
          autoFocus
          maxLength={maxLength}
          minLength={minLength}
          label={label}
          data-testid="editable-value-input"
          {...register("value", { validate })}
          rows={rows}
        />
        <div className={styles.buttons}>
          <button
            className={styles.saveButton}
            type="submit"
            disabled={isSaving}
            data-testid="save-button"
          >
            {isSaving ? <LoadingIndicator /> : <FontAwesomeIcon icon={["fas", "check"]} />}
          </button>
          <button
            className={styles.button}
            type="button"
            disabled={isSaving}
            onClick={toggleIsEditing}
            data-testid="cancel-button"
          >
            <FontAwesomeIcon icon={["fas", "times"]} />
          </button>
        </div>
      </div>
    </form>
  ) : (
    <div className={styles.view}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.valueWrapper}>
        {Boolean(initValue) && (
          <div className={classNames(styles.value, className)} data-testid="editable-value">
            {initValue}
          </div>
        )}
        <button
          className={styles.button}
          type="button"
          onClick={toggleIsEditing}
          data-testid="edit-button"
        >
          <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
        </button>
      </div>
    </div>
  )
}

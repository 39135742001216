import { SegmentScheduleCron } from "resources/segment/segment/segmentTypes"

export type ScheduleField = FixedSchedule | IntervalSchedule

export type FixedSchedule = {
  days: Record<Day, boolean>
  type: "fixed"
  hour: number
  minute: number
}

export type IntervalSchedule = {
  days: Record<Day, boolean>
  type: "interval"
  every: Interval
  from: number
  to: number
}

export type Interval = { value: number; unit: "min" | "hr" }

const cronDays = ["1", "2", "3", "4", "5", "6", "7"] as const
export type Day = typeof cronDays[number]

export function cronToScheduleField(cron: SegmentScheduleCron): ScheduleField {
  const days = Object.fromEntries(
    cronDays.map(value => [value, cron.day_of_week.includes(value)]),
  ) as Record<Day, boolean>

  const [hour, hourInterval] = cron.hour.split("/")
  const [minute, minuteInterval] = cron.minute.split("/")

  if (hourInterval) {
    const [from, to] = hour.split("-")
    return {
      type: "interval",
      days: days,
      every: { unit: "hr", value: parseInt(hourInterval) },
      from: hour === "*" ? NaN : parseInt(from),
      to: hour === "*" ? NaN : parseInt(to),
    }
  }
  if (minuteInterval) {
    const [from, to] = hour.split("-")
    return {
      type: "interval",
      days: days,
      every: { unit: "min", value: parseInt(minuteInterval) },
      from: parseInt(from),
      to: parseInt(to),
    }
  }
  return {
    type: "fixed",
    days: days,
    hour: hour ? parseInt(hour) : NaN,
    minute: minute ? parseInt(minute) : NaN,
  }
}

export function scheduleFieldToCron(schedule: ScheduleField): SegmentScheduleCron {
  const days = cronDays.filter(value => schedule.days[value]).join(",")

  if (schedule.type === "interval") {
    let hour = ""
    if (schedule.every) {
      if (schedule.from && schedule.to) {
        hour = `${schedule.from}-${schedule.to}`
      } else {
        hour = "*"
      }
      if (schedule.every.unit === "hr") {
        hour += `/${schedule.every.value}`
      }
    }

    let minute = ""
    if (schedule.every) {
      if (schedule.every.unit === "min") {
        minute = `*/${schedule.every.value}`
      } else {
        minute = "0"
      }
    }

    return {
      month: "*",
      day: "*",
      day_of_week: days,
      hour,
      minute,
    }
  }
  return {
    month: "*",
    day: "*",
    day_of_week: days,
    hour: schedule.hour?.toString() ?? "0",
    minute: schedule.minute?.toString() ?? "0",
  }
}

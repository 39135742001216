import { useCallback, useState } from "react"
import { useHistory, useParams } from "react-router"
import { getRoutePath } from "routes"
import {
  EmbeddedWebBanner,
  EmbeddedWebBannerRequestPayload,
} from "resources/webBanner/embeddedWebBanner/embeddedWBTypes"
import ConfirmModal from "components/UI/components/ConfirmModal"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import {
  useFetchEmbeddedWBById,
  useDeleteEmbeddedWB,
  useModifyEmbeddedWB,
} from "resources/webBanner/embeddedWebBanner/embeddedWBQueries"
import EmbeddedWebBannersForm from "../components/EmbeddedWebBannersForm/EmbeddedWebBannersForm"
import { EmbeddedWebBannersFormData } from "../embeddedWBComponentTypes"
import { embeddedWBToFormValues } from "../utilities/embeddedWBToFormValues"
import Page from "components/UI/Page/Page"
import ChatGPT from "components/ChatGPT/ChatGPT"
import { isEmpty } from "ramda"

export default function EmbeddedWebBannersDetail() {
  const history = useHistory()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { id } = useParams<{ id: EmbeddedWebBanner["id"] }>()

  const { data: webBanner, isLoading, isError } = useFetchEmbeddedWBById(id)
  const deleteMutation = useDeleteEmbeddedWB()
  const modifyMutation = useModifyEmbeddedWB()

  const formDataToModifyPayload = useCallback(
    ({
      name,
      element_id,
      condition,
      priority,
      frequency_cap,
      image_local,
      image_name,
      image_remote,
      html,
      destination_url,
      width,
      preview_width,
      disabled,
      tracking_disabled,
    }: EmbeddedWebBannersFormData) => {
      // destination_url needs to be present in image contents, otherwise it will be nullified :(
      const content: EmbeddedWebBannerRequestPayload["settings"]["content"] = image_local
        ? {
            data: image_local,
            image_name: image_name!,
            type: "local_image",
            destination_url: destination_url === "" ? null : destination_url,
          }
        : image_remote
        ? {
            image_url: image_remote,
            type: "remote_image",
            destination_url: destination_url === "" ? null : destination_url,
          }
        : destination_url !== null
        ? {
            destination_url: destination_url === "" ? null : destination_url,
            type: webBanner?.settings.content.type,
          }
        : html
        ? { html, type: "html" }
        : {}
      const data: EmbeddedWebBannerRequestPayload = {
        name,
        element_id,
        settings: {
          condition,
          priority,
          frequency_cap,
          tracking_disabled,
          fe_settings: { width, preview_width },
        },
        disabled,
      }

      if (content && !isEmpty(content)) {
        // needs to be added optionally only when something changes, otherwise it removes
        // image from the storage ...
        data.settings.content = content
      }

      return data
    },
    [webBanner],
  )

  const deleteBanner = () =>
    deleteMutation.mutate(
      { id },
      {
        onSuccess: () => {
          history.push(getRoutePath("channels.native-banners"))
        },
      },
    )

  if (isError) {
    return null
  }

  return (
    <Page
      title={"Edit native banner"}
      backRouteFallback={getRoutePath("channels.native-banners")}
      headerContent={<ChatGPT />}
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <EmbeddedWebBannersForm
          key={id}
          submitting={modifyMutation.isLoading}
          deleting={deleteMutation.isLoading}
          initialValues={embeddedWBToFormValues(webBanner)}
          onDelete={() => setDeleteModalOpen(true)}
          onSubmit={formData =>
            modifyMutation.mutateAsync({ id, data: formDataToModifyPayload(formData) })
          }
        />
      )}
      <ConfirmModal
        title="Are you sure?"
        open={deleteModalOpen}
        type="delete"
        text={`Do you really want to delete web banner?`}
        handleClose={() => setDeleteModalOpen(false)}
        handleConfirm={deleteBanner}
        isLoading={deleteMutation.isLoading}
      />
    </Page>
  )
}

import React, { useState } from "react"
import styles from "./ActivationView.module.scss"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import { useModifyMobilePushNotification } from "resources/mobilePushNotification/mobilePushNotificationQueries"
import { MobilePushNotification } from "resources/mobilePushNotification/mobilePushNotificationTypes"
import { Segment, SegmentScheduleCron } from "resources/segment/segment/segmentTypes"
import Button from "components/UI/elements/Button/Button"
import ConfirmModal from "components/UI/components/ConfirmModal"
import ActivationHistory from "../ActivationHistory/ActivationHistory"
import { showToast } from "app/toast"
import { last } from "ramda"
import ActivationBatch, {
  MISSING_SEGMENT_TOOLTIP_MESSAGE,
} from "components/ActivationBatch/ActivationBatch"
import { useCreateMobilePushActivation } from "resources/mobilePushNotification/export/mobilePushNotificationExportQueries"
import { arrayEquals } from "utilities/common"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import classNames from "classnames"
import { max, min, required } from "helpers/validators.helper"
import { DropdownIndicator } from "components/UI/elements/SelectField"
import { selectStyles } from "helpers/customSelectStyle.helper"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import { FrequencyCapPeriodType } from "resources/channel/channelTypes"
import IgnoreFrequencyCapModal from "components/UI/components/IgnoreFrequencyCapModal/IgnoreFrequencyCapModal"

const periodTypeOptions: Array<{ label: string; value: FrequencyCapPeriodType }> = [
  { label: "Days", value: "DAYS" },
  { label: "Hours", value: "HOURS" },
]

type ActivationViewProps = {
  notification?: MobilePushNotification
}

type FormFields = MobilePushNotification["frequency_cap"]

export default function ActivationView({ notification }: ActivationViewProps) {
  const [activatingNewBatch, setActivatingNewBatch] = useState(false)
  const [sendToSegments, setSendToSegments] = useState<Array<Segment>>([])
  const [segmentsToRemove, setSegmentsToRemove] = useState<Array<Segment>>([])
  const [ignoreCapModalOpen, setIgnoreCapModalOpen] = useState(false)

  const sendingBatches = notification?.schedules ?? []
  const activatedSegmentIds = sendingBatches.map(({ segment_ids }) => segment_ids).flat()
  const [showEmptyBatch, setShowEmptyBatch] = useState(
    sendingBatches.length === 0 || last(sendingBatches)?.segment_ids.length === 0,
  )

  const sendMutation = useCreateMobilePushActivation()
  const modifyMutation = useModifyMobilePushNotification()

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<FormFields>()
  const periodType = watch("period.type", notification?.frequency_cap.period.type)

  const sendNotification = () => {
    if (!notification || sendToSegments.length === 0) return

    sendMutation.mutate(
      { id: notification.id, segmentIds: sendToSegments.map(({ id }) => id) },
      { onSuccess: () => setSendToSegments([]) },
    )
  }

  const modifyBatchSchedule = (
    segmentIds: Array<Segment["id"]>,
    newSchedule: SegmentScheduleCron[] | string,
  ) => {
    if (!notification || modifyMutation.isLoading) return

    return modifyMutation.mutateAsync(
      {
        id: notification.id,
        data: {
          schedules: notification.schedules.map(({ segment_ids, ...rest }) =>
            arrayEquals(segmentIds, segment_ids)
              ? Array.isArray(newSchedule)
                ? {
                    ...rest,
                    segment_ids,
                    schedule_type: "repetitive",
                    schedules: newSchedule,
                    schedule_time: undefined,
                  }
                : {
                    ...rest,
                    segment_ids,
                    schedule_type: "static",
                    schedules: undefined,
                    schedule_time: newSchedule,
                  }
              : { ...rest, segment_ids },
          ),
        },
      },
      {
        onSuccess: () => {
          showToast("Segment activation schedule modified.")
        },
      },
    )
  }

  const removeBatch = () => {
    if (!notification || modifyMutation.isLoading || !segmentsToRemove) return

    modifyMutation.mutate(
      {
        id: notification.id,
        data: {
          schedules: notification.schedules.filter(
            ({ segment_ids }) =>
              !arrayEquals(
                segment_ids,
                segmentsToRemove.map(({ id }) => id),
              ),
          ),
        },
      },
      {
        onSuccess: () => {
          setSegmentsToRemove([])
          showToast("Mobile push deactivated for segment.")
        },
      },
    )
  }

  const addBatch = async (segmentIds: Array<Segment["id"]>) => {
    if (!notification || modifyMutation.isLoading) return
    setActivatingNewBatch(true)

    await modifyMutation.mutateAsync(
      {
        id: notification.id,
        data: {
          schedules: [
            ...notification.schedules,
            {
              schedules: [],
              schedule_type: "repetitive",
              segment_ids: segmentIds,
            },
          ],
        },
      },
      {
        onSuccess: () => {
          showToast("Mobile push activated for segment.")
        },
        onSettled: () => {
          setActivatingNewBatch(false)
        },
      },
    )
  }

  const modifyBatchSegments = async (
    previousSegmentIds: Array<Segment["id"]>,
    newSegmentIds: Array<Segment["id"]>,
  ) => {
    if (!notification || modifyMutation.isLoading) return

    const newNotificationSchedules =
      newSegmentIds.length > 0
        ? notification.schedules.map(({ segment_ids, ...rest }) =>
            arrayEquals(previousSegmentIds, segment_ids)
              ? { ...rest, segment_ids: newSegmentIds }
              : { ...rest, segment_ids },
          )
        : notification.schedules.filter(
            ({ segment_ids }) => !arrayEquals(previousSegmentIds, segment_ids),
          )

    return modifyMutation.mutateAsync(
      {
        id: notification.id,
        data: {
          schedules: newNotificationSchedules,
        },
      },
      {
        onSuccess: () => {
          showToast("Mobile push activation modified.")
        },
      },
    )
  }

  const modifyFrequencyCap = (formValues: FormFields) => {
    if (!notification || modifyMutation.isLoading) return

    modifyMutation.mutate(
      {
        id: notification.id,
        data: {
          frequency_cap: formValues,
        },
      },
      {
        onSuccess: () => {
          showToast("Mobile push frequency cap modified.")
          reset(formValues)
        },
      },
    )
  }

  const tooltip = (
    <>
      Segmented profiles available for Mobile Push channel.{" "}
      <a
        href="https://docs.meiro.io/books/meiro-business-explorer/page/segmented-audience-in-activation-history"
        target="_blank"
        rel="noreferrer noopener"
      >
        Learn more
      </a>
      .
    </>
  )

  return (
    <>
      <ConfirmModal
        isLoading={sendMutation.isLoading}
        open={sendToSegments.length !== 0}
        customButtonText={isDirty ? "Confirm" : undefined}
        title="Are you sure?"
        action="send mobile push to"
        what="segment"
        item={sendToSegments.map(({ name }) => name).join(", ")}
        additional={isDirty && <p>There are some unsaved changes.</p>}
        type={isDirty ? "delete" : "success"}
        handleClose={() => setSendToSegments([])}
        handleConfirm={sendNotification}
      />
      <ConfirmModal
        isLoading={modifyMutation.isLoading}
        open={segmentsToRemove.length !== 0}
        title="Are you sure?"
        action="deactivate mobile push for"
        what="segment"
        item={segmentsToRemove.map(({ name }) => name).join(", ")}
        type="success"
        handleClose={() => setSegmentsToRemove([])}
        handleConfirm={removeBatch}
      />
      <IgnoreFrequencyCapModal
        entity={"mobile push campaign"}
        open={ignoreCapModalOpen}
        onClose={() => setIgnoreCapModalOpen(false)}
        onConfirm={() => {
          setValue("ignore_channel_frequency_cap", true, { shouldDirty: true })
          setIgnoreCapModalOpen(false)
        }}
      />

      <form onSubmit={handleSubmit(modifyFrequencyCap)}>
        <PaperHeader titleText={notification?.name} size="small" className={styles.paperHeader}>
          {notification && (
            <Button loading={modifyMutation.isLoading} type="submit">
              Save
            </Button>
          )}
        </PaperHeader>
        {notification && (
          <Paper className={styles.frequencyCap}>
            <div className={styles.fieldsWrapper}>
              <h3 className={styles.title}>Frequency cap per registration token</h3>
              <div className={styles.fields}>
                <div className={styles.maxCount}>
                  <TextInput
                    defaultValue={notification.frequency_cap.max_count}
                    error={errors.max_count?.message}
                    max={50}
                    min={1}
                    step={1}
                    type="number"
                    className={styles.textInput}
                    {...register("max_count", {
                      valueAsNumber: true,
                      validate: {
                        required,
                        max: max(50),
                        min: min(1),
                      },
                    })}
                  />
                  <div className={styles.times}>Times</div>
                </div>
                <span className={styles.text}>during period of</span>
                <div className={styles.period}>
                  <TextInput
                    defaultValue={notification.frequency_cap.period.size}
                    error={errors.period?.size?.message}
                    max={periodType === "DAYS" ? 7 : 168}
                    min={1}
                    step={1}
                    type="number"
                    className={styles.textInput}
                    {...register("period.size", {
                      valueAsNumber: true,
                      validate: {
                        required,
                        max: periodType === "DAYS" ? max(7) : max(168),
                        min: min(1),
                      },
                    })}
                  />
                  <Controller
                    control={control}
                    name="period.type"
                    defaultValue={notification.frequency_cap.period.type}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        simpleValue
                        components={{ DropdownIndicator }}
                        noOptionsMessage={() => "Empty"}
                        options={periodTypeOptions}
                        value={periodTypeOptions.find(o => o.value === value)}
                        onChange={o => onChange(o?.value)}
                        styles={selectStyles("medium", "no-left", "300px", "#FE7F66", "#F7F9FB")}
                        className={classNames("select-input", styles.periodType)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <Controller
              control={control}
              name="ignore_channel_frequency_cap"
              defaultValue={notification.frequency_cap.ignore_channel_frequency_cap}
              render={({ field: { value, onBlur, onChange } }) => (
                <div className={styles.tooltipWrapper}>
                  <Checkbox
                    checked={value}
                    label={<b>Ignore global frequency cap</b>}
                    onBlur={onBlur}
                    onChange={() => {
                      if (value) onChange(false)
                      else setIgnoreCapModalOpen(true)
                    }}
                  />
                  <InfoTooltip placement="bottom">
                    This will ignore global frequency cap settings for all mobile pushes.
                  </InfoTooltip>
                </div>
              )}
            />
          </Paper>
        )}
        <Paper className={classNames(styles.body, { [styles.missingEmail]: !notification })}>
          {!notification && (
            <div className={styles.emptyMessage}>
              Save the mobile push to enable activation setup.
            </div>
          )}

          {notification && (
            <>
              {sendingBatches.length > 0 &&
                sendingBatches.map((activatedSegment, index) => (
                  <ActivationBatch
                    key={index}
                    activatedSegmentIds={activatedSegmentIds}
                    channelType="push_notifications"
                    repetitiveSchedules={activatedSegment.schedules ?? []}
                    staticSchedule={activatedSegment.schedule_time}
                    segmentIds={activatedSegment.segment_ids}
                    tooltip={tooltip}
                    pushNotificationId={notification.id}
                    activate={newSegmentIds =>
                      modifyBatchSegments(activatedSegment.segment_ids, newSegmentIds)
                    }
                    remove={setSegmentsToRemove}
                    schedule={schedules =>
                      modifyBatchSchedule(activatedSegment.segment_ids, schedules)
                    }
                    send={setSendToSegments}
                  />
                ))}

              {showEmptyBatch && (
                <ActivationBatch
                  activatedSegmentIds={activatedSegmentIds}
                  channelType="push_notifications"
                  repetitiveSchedules={[]}
                  segmentIds={[]}
                  tooltip={tooltip}
                  activate={segmentIds => {
                    setShowEmptyBatch(false)
                    return addBatch(segmentIds)
                  }}
                  remove={() => setShowEmptyBatch(false)}
                  removeEnabled={sendingBatches.length > 0}
                />
              )}

              <div className={styles.addBatch}>
                <Button
                  disabled={showEmptyBatch || activatingNewBatch}
                  color="grey"
                  icon={["far", "plus"]}
                  tooltip={
                    showEmptyBatch || activatingNewBatch ? MISSING_SEGMENT_TOOLTIP_MESSAGE : ""
                  }
                  variant="outlined"
                  onClick={() => setShowEmptyBatch(true)}
                >
                  Add sending batch
                </Button>
              </div>
            </>
          )}
        </Paper>
      </form>

      {notification && (
        <div className={styles.historyWrapper}>
          <ActivationHistory notificationId={notification.id} />
        </div>
      )}
    </>
  )
}

import React, { ComponentPropsWithoutRef } from "react"
import classNames from "classnames"
import styles from "./SegmentGuide.module.scss"

type SegmentGuideProps = {
  step: number
  onClose: () => void
  onNext: () => void
  onPrev: () => void
} & ComponentPropsWithoutRef<"div">

const steps: Record<number, { title: string; text: JSX.Element }> = {
  1: {
    title: "Welcome to the new segment detail page!",
    text: <p>Check it out in a few steps.</p>,
  },
  2: {
    title: "Overview panel",
    text: <p>The important information about the segment is always available here.</p>,
  },
  3: {
    title: "New condition builder",
    text: (
      <p>
        More compact design, as well as a more intuitive nested layout and conditions color-coded by
        parenthesis level.
      </p>
    ),
  },
  4: {
    title: "Switch between different sections of the segment",
    text: <p>The segment page is split into sub-tabs for easier navigation.</p>,
  },
}

export default function SegmentGuide({
  step,
  onClose,
  onNext,
  onPrev,
  ...props
}: SegmentGuideProps) {
  return (
    <div data-testid="segment-guide" className={styles.container} {...props}>
      <div className={styles.title}>{steps[step].title}</div>
      {steps[step].text}
      <div className={styles.footer}>
        {step === 1 ? (
          <button className={styles.moveButton} onClick={onClose}>
            Skip guide
          </button>
        ) : (
          <button className={styles.moveButton} onClick={onPrev}>
            Back
          </button>
        )}

        <div className={styles.dotsWrapper}>
          <div className={classNames(styles.dot, { [styles.active]: step === 1 })} />
          <div className={classNames(styles.dot, { [styles.active]: step === 2 })} />
          <div className={classNames(styles.dot, { [styles.active]: step === 3 })} />
          <div className={classNames(styles.dot, { [styles.active]: step === 4 })} />
        </div>

        {step === 4 ? (
          <button className={styles.moveButton} onClick={onClose}>
            Finish
          </button>
        ) : (
          <button className={styles.moveButton} onClick={onNext}>
            Next
          </button>
        )}
      </div>
    </div>
  )
}

import ReactMarkdown from "react-markdown"
import TextArea from "components/UI/elements/TextArea/TextArea"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Button from "components/UI/elements/Button/Button"
import { required } from "helpers/validators.helper"
import "./SingleView.scss"
import { useForm } from "react-hook-form"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { SegmentNotePayload } from "resources/segment/segmentNote/segmentNoteTypes"
import { useState } from "react"

type FormValues = SegmentNotePayload

type SingleViewProps = {
  isEditable: boolean
  isLoading: boolean
  initialValues?: FormValues
  onCancel: () => void
  onSubmit: (values: FormValues) => void
}

export default function SingleView({
  initialValues,
  isEditable,
  isLoading,
  onCancel,
  onSubmit,
}: SingleViewProps) {
  const [isViewMode, setIsViewMode] = useState(true)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ defaultValues: initialValues ?? { content: "", name: "" } })

  return (
    <form className="note-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="note-form-header">
        <IconButton
          color="grey"
          className="back-button"
          onClick={onCancel}
          icon="chevron-left"
          variant="transparent"
        />
        <div className="note-name">
          <TextInput
            {...register("name", { validate: required })}
            label="Note name"
            disabled={!isEditable}
            error={errors.name?.message}
            autoFocus={!initialValues}
          />
        </div>
      </div>
      {isViewMode && (
        <section className="markdown-content-wrapper">
          <div
            onClick={() => {
              if (isEditable) setIsViewMode(false)
            }}
            className={`markdown-content ${isEditable ? "" : "disabled"}`}
          >
            {initialValues?.content && <ReactMarkdown source={initialValues.content} />}
            {!initialValues?.content && "Click to edit note's content."}
          </div>
        </section>
      )}
      {!isViewMode && (
        <TextArea
          {...register("content")}
          placeholder="Content"
          rows={11}
          className="note-markdown-textarea"
          autoFocus
        />
      )}
      <div className="tips">
        TIPS:
        <span className="headline"># Headline</span>
        <span className="bold">**bold**</span>
        <span className="italics">_italics_</span>
        <span className="strikethrough">~~strike~~</span>
      </div>
      <div className="note-actions">
        <Button
          color="grey"
          variant="link"
          onClick={() => {
            if (isViewMode) onCancel()
            else {
              // edit mode, cancel textarea editing
              if (!initialValues) onCancel()
              setIsViewMode(true)
            }
          }}
        >
          Cancel
        </Button>
        <Button icon="save" loading={isLoading} type="submit" disabled={!isEditable}>
          Save
        </Button>
      </div>
    </form>
  )
}

import loyalists from "images/smart-segments/loyalists.svg"
import regulars from "images/smart-segments/regulars.svg"
import newbies from "images/smart-segments/newbies.svg"
import top_spenders from "images/smart-segments/top_spenders.svg"
import mid_spenders from "images/smart-segments/mid_spenders.svg"
import low_spenders from "images/smart-segments/low_spenders.svg"
import frequent_spenders from "images/smart-segments/frequent_spenders.svg"
import active_spenders from "images/smart-segments/active_spenders.svg"
import drifting_spenders from "images/smart-segments/drifting_spenders.svg"
import most_recent_spenders from "images/smart-segments/most_recent_spenders.svg"
import medium_spenders from "images/smart-segments/medium_spenders.svg"
import least_recent_spenders from "images/smart-segments/least_recent_spenders.svg"
import subscribed from "images/smart-segments/subscribed.svg"
import not_subscribed from "images/smart-segments/not_subscribed.svg"
import unsubscribed from "images/smart-segments/unsubscribed.svg"
import recently_active from "images/smart-segments/recently_active.svg"
import medium_active from "images/smart-segments/medium_active.svg"
import drifting_active from "images/smart-segments/drifting_active.svg"
import web_super_engaged from "images/smart-segments/web_super_engaged.svg"
import web_medium_engaged from "images/smart-segments/web_medium_engaged.svg"
import web_fading_engagement from "images/smart-segments/web_fading_engagement.svg"
import edm_super_engaged from "images/smart-segments/edm_super_engaged.svg"
import edm_medium_engaged from "images/smart-segments/edm_medium_engaged.svg"
import edm_fading_engagement from "images/smart-segments/edm_fading_engagement.svg"
import mobile_super_engaged from "images/smart-segments/mobile_super_engaged.svg"
import mobile_medium_engaged from "images/smart-segments/mobile_medium_engaged.svg"
import mobile_fading_engagement from "images/smart-segments/mobile_fading_engagement.svg"
import source_google from "images/smart-segments/source_google.svg"
import source_facebook from "images/smart-segments/source_facebook.svg"
import source_instagram from "images/smart-segments/source_instagram.svg"
import social_preference from "images/smart-segments/social_preference.svg"
import email_preference from "images/smart-segments/email_preference.svg"
import search_preference from "images/smart-segments/search_preference.svg"
import silent_generation from "images/smart-segments/silent_generation.svg"
import baby_boomers from "images/smart-segments/baby_boomers.svg"
import generation_x from "images/smart-segments/generation_x.svg"
import xennials from "images/smart-segments/xennials.svg"
import millenials from "images/smart-segments/millenials.svg"
import generation_z from "images/smart-segments/generation_z.svg"
import desktop_users from "images/smart-segments/desktop_users.svg"
import mobile_users from "images/smart-segments/mobile_users.svg"
import tablet_users from "images/smart-segments/tablet_users.svg"
import big_city from "images/smart-segments/big_city.svg"
import city from "images/smart-segments/city.svg"
import village from "images/smart-segments/village.svg"
import contact_allowed from "images/smart-segments/contact_allowed.svg"
import contact_disallowed from "images/smart-segments/contact_disallowed.svg"
import contact_unknown from "images/smart-segments/contact_unknown.svg"
import default_icon from "images/smart-segments/default_icon.svg"

const iconsMapping = {
  loyalists,
  regulars,
  newbies,
  top_spenders,
  mid_spenders,
  low_spenders,
  frequent_spenders,
  active_spenders,
  drifting_spenders,
  most_recent_spenders,
  medium_spenders,
  least_recent_spenders,
  subscribed,
  not_subscribed,
  unsubscribed,
  recently_active,
  medium_active,
  drifting_active,
  web_super_engaged,
  web_medium_engaged,
  web_fading_engagement,
  edm_super_engaged,
  edm_medium_engaged,
  edm_fading_engagement,
  mobile_super_engaged,
  mobile_medium_engaged,
  mobile_fading_engagement,
  source_google,
  source_facebook,
  source_instagram,
  social_preference,
  email_preference,
  search_preference,
  silent_generation,
  baby_boomers,
  generation_x,
  xennials,
  millenials,
  generation_z,
  desktop_users,
  mobile_users,
  tablet_users,
  big_city,
  city,
  village,
  contact_allowed,
  contact_disallowed,
  contact_unknown,
  default_icon,
}

/**
 * Include all images in source code because we're using service worker api.
 */

export const getSegmentIconSrc = icon => {
  if (!icon || !iconsMapping[icon]) {
    return iconsMapping["default_icon"]
  } else {
    return iconsMapping[icon]
  }
}

export const smartSegmentGroups = [
  {
    id: "customer_lifetime",
    name: "Customer lifetime",
    color: "light-blue",
    icons: ["loyalists", "regulars", "newbies"],
  },
  {
    id: "total_spend",
    name: "Total spend",
    color: "medium-purple",
    icons: ["top_spenders", "mid_spenders", "low_spenders"],
  },
  {
    id: "spending_frequency",
    name: "Spending frequency",
    color: "medium-purple",
    icons: ["frequent_spenders", "active_spenders", "drifting_spenders"],
  },
  {
    id: "spending_recency",
    name: "Spending recency",
    color: "medium-purple",
    icons: ["most_recent_spenders", "medium_spenders", "least_recent_spenders"],
  },
  {
    id: "email_subscription",
    name: "Email subscription",
    color: "wasabi",
    icons: ["subscribed", "not_subscribed", "unsubscribed"],
  },
  {
    id: "customer_interaction",
    name: "Customer interaction",
    color: "wasabi",
    icons: ["recently_active", "medium_active", "drifting_active"],
  },
  {
    id: "web_engagement",
    name: "Web engagement",
    color: "wasabi",
    icons: ["web_super_engaged", "web_medium_engaged", "web_fading_engagement"],
  },
  {
    id: "edm_engagement",
    name: "EDM engagement",
    color: "wasabi",
    icons: ["edm_super_engaged", "edm_medium_engaged", "edm_fading_engagement"],
  },
  {
    id: "mobile_app_engagement",
    name: "Mobile app engagement",
    color: "wasabi",
    icons: ["mobile_super_engaged", "mobile_medium_engaged", "mobile_fading_engagement"],
  },
  {
    id: "source",
    name: "Source",
    color: "wasabi",
    icons: ["source_google", "source_facebook", "source_instagram"],
  },
  {
    id: "channel_preference",
    name: "Channel preference",
    color: "wasabi",
    icons: ["social_preference", "email_preference", "search_preference"],
  },
  {
    id: "age_group",
    name: "Age group",
    color: "cabaret",
    icons: [
      "silent_generation",
      "baby_boomers",
      "generation_x",
      "xennials",
      "millenials",
      "generation_z",
    ],
  },
  {
    id: "favourite_device",
    name: "Favourite device",
    color: "cabaret",
    icons: ["desktop_users", "mobile_users", "tablet_users"],
  },
  {
    id: "location",
    name: "Location",
    color: "cabaret",
    icons: ["big_city", "city", "village"],
  },
  {
    id: "permission_to_contact",
    name: "Permission to contact",
    color: "cabaret",
    icons: ["contact_allowed", "contact_disallowed", "contact_unknown"],
  },
]

export const getSmartSegmentsOptions = () => {
  return smartSegmentGroups.map(group => ({ value: group.id, label: group.name }))
}

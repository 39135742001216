import { useQuery } from "@tanstack/react-query"
import requestFactory from "api/request"
import { isEmpty } from "ramda"

type CustomersLastUpdateResponse = {
  customer_last_update: {
    h12: number
    h24: number
    h48: number
    h96: number
    h168: number
    total: number
  }
}

const customersLastUpdate = (): Promise<CustomersLastUpdateResponse> =>
  requestFactory("get", "/customer_last_update")

export function useFetchCustomersLastUpdate() {
  return useQuery(["customersLastUpdate"], customersLastUpdate, {
    staleTime: 60 * 1000,
    select({ customer_last_update }) {
      return !isEmpty(customer_last_update) ? customer_last_update : null
    },
  })
}

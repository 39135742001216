import { useQuery } from "@tanstack/react-query"
import requestFactory from "api/request"
import { Source } from "resources/dataSource/dataSourceTypes"

type CustomersPerSourcesCountResponse = {
  customers_per_sources_count: {
    source_count: number
    source_values: {
      source_ids: Source["id"][]
      customers_count: number
    }[]
  }[]
}

const customersPerSourcesCount = (): Promise<CustomersPerSourcesCountResponse> =>
  requestFactory("get", "/customers_per_sources_count")

export function useFetchCustomersPerSourcesCount() {
  return useQuery(["customersPerSourcesCount"], customersPerSourcesCount, {
    staleTime: 60 * 1000,
    select({ customers_per_sources_count }) {
      return customers_per_sources_count.length ? customers_per_sources_count : null
    },
  })
}

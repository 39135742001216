import requestFactory from "api/request"
import { Attribute } from "resources/attribute/attributeTypes"
import { CustomerSearchResponse } from "./customerSearchTypes"

const customers = {
  searchFulltext({ searchTerm }: { searchTerm?: string }): Promise<CustomerSearchResponse> {
    return requestFactory("get", "/customers/fulltext_search", {
      search_text: searchTerm || undefined,
    })
  },
  searchByAttribute({
    attributeId,
    searchTerm,
  }: {
    attributeId: Attribute["id"]
    searchTerm?: string
  }): Promise<CustomerSearchResponse> {
    return requestFactory("get", `/customers/attributes/${attributeId}/search`, {
      search_text: searchTerm || undefined,
    })
  },
  listFavorites(): Promise<CustomerSearchResponse> {
    return requestFactory("get", "/customers/favorite/search")
  },
}

export default customers

import requestFactory from "api/request"
import {
  ActivateEmailsChannelOptInPayload,
  ChannelListResponse,
  EmailsChannelResponse,
  ModifyEmailsChannelFrequencyCapPayload,
  ModifyEmailsChannelOptInPayload,
  ModifyEmailsChannelProviderConfigPayload,
  ModifyEmailsChannelStitchingCategoryPayload,
  ModifyEmailsChannelUnsubscribeBlock,
  PushNotificationApp,
  PushNotificationAppPayload,
  PushNotificationAppResponse,
  ListPushNotificationAppsResponse,
  PushNotificationsChannelReponse,
  FirebaseAppListResponse,
  ModifyPushNotificationsChannelStitchingCategoryPayload,
  ModifyPushNotificationsChannelFrequencyCapPayload,
  ModifyPushNotificationsChannelCustomDataSchemaPayload,
  FirebaseProjectListResponse,
  FirebaseProjectResponse,
  FirebaseProject,
} from "./channelTypes"
import senders from "./emailSenders/emailSendersEndpoints"

const channel = {
  list(): Promise<ChannelListResponse> {
    return requestFactory("get", "/channels")
  },
  pushNotifications: {
    retrieve(): Promise<PushNotificationsChannelReponse> {
      return requestFactory("get", "/channels/push_notifications")
    },
    modifyFrequencyCap(
      data: ModifyPushNotificationsChannelFrequencyCapPayload,
    ): Promise<PushNotificationsChannelReponse> {
      return requestFactory("post", "/channels/push_notifications/frequency_cap", data)
    },
    modifyStitchingCategory(
      data: ModifyPushNotificationsChannelStitchingCategoryPayload,
    ): Promise<PushNotificationsChannelReponse> {
      return requestFactory("post", "/channels/push_notifications/stitching_category", data)
    },
    modifyCustomDataSchema(
      data: ModifyPushNotificationsChannelCustomDataSchemaPayload,
    ): Promise<PushNotificationsChannelReponse> {
      return requestFactory("post", "/channels/push_notifications/custom_data_schema", data)
    },
    firebaseProjects: {
      list(): Promise<FirebaseProjectListResponse> {
        return requestFactory("get", "/channels/push_notifications/firebase_projects")
      },
      create(data: FormData): Promise<FirebaseProjectResponse> {
        return requestFactory(
          "post",
          "/channels/push_notifications/firebase_projects",
          data,
          false,
          true,
          "",
          false,
          false,
          { "Content-Type": "multipart/form-data" },
        )
      },
      modify(id: FirebaseProject["id"], data: FormData): Promise<FirebaseProjectResponse> {
        return requestFactory(
          "patch",
          `/channels/push_notifications/firebase_projects/${id}`,
          data,
          false,
          true,
          "",
          false,
          false,
          { "Content-Type": "multipart/form-data" },
        )
      },
      apps: {
        list(id: FirebaseProject["id"]): Promise<ListPushNotificationAppsResponse> {
          return requestFactory("get", `/channels/push_notifications/firebase_projects/${id}/apps`)
        },
        create(
          firebaseProjectId: FirebaseProject["id"],
          data: PushNotificationAppPayload,
        ): Promise<PushNotificationAppResponse> {
          return requestFactory(
            "post",
            `/channels/push_notifications/firebase_projects/${firebaseProjectId}/apps`,
            data,
          )
        },
        modify({
          firebaseProjectId,
          appId,
          data,
        }: {
          firebaseProjectId: FirebaseProject["id"]
          appId: PushNotificationApp["id"]
          data: PushNotificationAppPayload
        }): Promise<PushNotificationAppResponse> {
          return requestFactory(
            "patch",
            `/channels/push_notifications/firebase_projects/${firebaseProjectId}/apps/${appId}`,
            data,
          )
        },
        delete(
          firebaseProjectId: FirebaseProject["id"],
          appId: PushNotificationApp["id"],
        ): Promise<void> {
          return requestFactory(
            "delete",
            `/channels/push_notifications/firebase_projects/${firebaseProjectId}/apps/${appId}`,
          )
        },
      },
      firebase_apps: {
        list(id: FirebaseProject["id"]): Promise<FirebaseAppListResponse> {
          return requestFactory(
            "get",
            `/channels/push_notifications/firebase_projects/${id}/firebase_apps`,
          )
        },
      },
    },
    apps: {
      list(): Promise<ListPushNotificationAppsResponse> {
        return requestFactory("get", "/channels/push_notifications/apps")
      },
    },
  },
  emails: {
    retrieve(): Promise<EmailsChannelResponse> {
      return requestFactory("get", "/channels/emails")
    },
    modifyFrequencyCap(
      data: ModifyEmailsChannelFrequencyCapPayload,
    ): Promise<EmailsChannelResponse> {
      return requestFactory("post", "/channels/emails/frequency_cap", data)
    },
    modifyProviderConfig(
      data: ModifyEmailsChannelProviderConfigPayload,
    ): Promise<EmailsChannelResponse> {
      return requestFactory("post", "/channels/emails/provider_config", data)
    },
    modifyStitchingCategory(
      data: ModifyEmailsChannelStitchingCategoryPayload,
    ): Promise<EmailsChannelResponse> {
      return requestFactory("post", "/channels/emails/stitching_category", data)
    },
    modifyOptIn(data: ModifyEmailsChannelOptInPayload): Promise<EmailsChannelResponse> {
      return requestFactory("post", "/channels/emails/opt_in", data)
    },
    activateOptIn(data: ActivateEmailsChannelOptInPayload): Promise<void> {
      return requestFactory("post", "/channels/emails/opt_in_activation", data)
    },
    modifyUnsubscribeBlock(
      data: ModifyEmailsChannelUnsubscribeBlock,
    ): Promise<EmailsChannelResponse> {
      return requestFactory("post", "/channels/emails/unsubscribe_block", data)
    },
    senders,
  },
}

export default channel

import {
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import fetchAll from "helpers/fetchAll.helper"
import { equals, path, prop, sort } from "ramda"
import api from "resources/endpoints"
import { OrderDir } from "types/util"
import { ascend, descend } from "utilities/comparators"
import {
  EmbeddedWebBanner,
  EmbeddedWebBannerCreatePayload,
  EmbeddedWebBannerListDeletedResponse,
  EmbeddedWebBannerModifyPayload,
  EmbeddedWebBannerSort,
} from "./embeddedWBTypes"
import { getRoutePath } from "routes"
import { TrashItem } from "types/trash"

const EMBEDDED_WEB_BANNER = "embeddedWebBanner" as const
const EMBEDDED_WEB_BANNER_ALL_QK: QueryKey = [EMBEDDED_WEB_BANNER, "all"]
const EMBEDDED_WEB_BANNER_TRASH_QK: QueryKey = [EMBEDDED_WEB_BANNER, "trash"]

export function useFetchAllEmbeddedWB(
  opts: {
    orderBy: EmbeddedWebBannerSort
    orderDir: OrderDir
    searchTerm: string
  },
  config: UseQueryOptions<EmbeddedWebBanner[], unknown, EmbeddedWebBanner[], QueryKey> = {},
) {
  const queryClient = useQueryClient()
  return useQuery(
    EMBEDDED_WEB_BANNER_ALL_QK,
    () =>
      fetchAll({
        fetchFn: (offset, limit) => api.embeddedWebBanner.list(offset, limit),
        key: "web_banners",
      }),
    {
      ...config,
      onSuccess: webBanners => {
        webBanners.forEach(web_banner =>
          queryClient.setQueryData([EMBEDDED_WEB_BANNER, web_banner.id], { web_banner }),
        )
      },
      select: webBanners => {
        const filteredBanners = opts.searchTerm
          ? webBanners.filter(
              ({ name, element_id }) =>
                name.toLowerCase().includes(opts.searchTerm.toLowerCase()) ||
                element_id.toLowerCase().includes(opts.searchTerm.toLowerCase()),
            )
          : webBanners

        const getSortingProperty =
          opts.orderBy === "priority" ? path(["settings", "priority"]) : prop(opts.orderBy)
        const comparator = opts.orderDir === "ASC" ? ascend : descend

        return sort(comparator(getSortingProperty), filteredBanners)
      },
    },
  )
}

export function useFetchEmbeddedWBById(id: EmbeddedWebBanner["id"]) {
  return useQuery([EMBEDDED_WEB_BANNER, id], () => api.embeddedWebBanner.retrieve(id), {
    select: ({ web_banner }) => web_banner,
  })
}

export function useCreateEmbeddedWB() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: EmbeddedWebBannerCreatePayload }) => api.embeddedWebBanner.create(data),
    {
      onSuccess: ({ web_banner }) => {
        queryClient.setQueryData<EmbeddedWebBanner[]>(EMBEDDED_WEB_BANNER_ALL_QK, data => {
          if (!data) {
            return
          }

          data.push(web_banner)
          return data
        })
        queryClient.setQueryData([EMBEDDED_WEB_BANNER, web_banner.id], { web_banner })
        showToast("Web banner created.")
      },
    },
  )
}

export function useCopyEmbeddedWB() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: EmbeddedWebBanner["id"] }) => api.embeddedWebBanner.copy(id), {
    onSuccess: ({ web_banner }) => {
      queryClient.setQueryData<EmbeddedWebBanner[]>(EMBEDDED_WEB_BANNER_ALL_QK, data => {
        if (!data) {
          return
        }

        data.push(web_banner)
        return data
      })
      queryClient.setQueryData([EMBEDDED_WEB_BANNER, web_banner.id], { web_banner })
      showToast("Web banner copied.")
    },
  })
}

export function useModifyEmbeddedWB() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: EmbeddedWebBanner["id"]; data: EmbeddedWebBannerModifyPayload }) =>
      api.embeddedWebBanner.modify(id, data),
    {
      onSuccess: ({ web_banner }, { data }) => {
        queryClient.setQueryData<EmbeddedWebBanner[]>(EMBEDDED_WEB_BANNER_ALL_QK, data => {
          if (!data) {
            return
          }

          const index = data.findIndex(({ id }) => id === web_banner.id)
          if (index === -1) {
            data.push(web_banner)
          } else {
            data[index] = web_banner
          }
          return data
        })
        queryClient.setQueryData([EMBEDDED_WEB_BANNER, web_banner.id], { web_banner })
        showToast(
          equals(data, { disabled: true })
            ? "Web banner disabled."
            : equals(data, { disabled: false })
            ? "Web banner enabled."
            : "Web banner modified.",
        )
      },
    },
  )
}

export function useDeleteEmbeddedWB() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id }: { id: EmbeddedWebBanner["id"] }) => api.embeddedWebBanner.delete(id),
    {
      onSuccess: (_, { id }) => {
        queryClient.setQueryData<EmbeddedWebBanner[]>(EMBEDDED_WEB_BANNER_ALL_QK, data => {
          return data?.filter(el => el.id !== id)
        })
        showToast("Web banner deleted.")
      },
    },
  )
}

export const useFetchEmbeddedWebBannerTrashItems = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<
    EmbeddedWebBannerListDeletedResponse,
    string,
    Omit<EmbeddedWebBannerListDeletedResponse, "trashed_embedded_web_banners"> & {
      trashed_embedded_web_banners: Array<TrashItem>
    },
    QueryKey
  >(
    [...EMBEDDED_WEB_BANNER_TRASH_QK, searchTerm],
    ({ pageParam }) =>
      api.embeddedWebBanner.listDeleted({
        offset: pageParam,
        limit: 20,
        searched_text: searchTerm,
      }),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.trashed_embedded_web_banners.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      select: data => {
        return {
          ...data,
          pages: data.pages.map(p => ({
            ...p,
            trashed_embedded_web_banners: p.trashed_embedded_web_banners.map(
              ({ id, name, created, user_id }) => {
                const trashItem: TrashItem = {
                  id,
                  name,
                  deleted_at: created,
                  deleted_by: user_id,
                  type: "embedded_web_banners",
                }

                return trashItem
              },
            ),
          })),
        }
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.trashed_embedded_web_banners) : [] }
}

export const useRestoreEmbeddedWebBanner = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id }: { id: EmbeddedWebBanner["id"] }) => api.embeddedWebBanner.restoreDeleted(id),
    {
      onSuccess: ({ embedded_web_banner }) => {
        queryClient.invalidateQueries(EMBEDDED_WEB_BANNER_TRASH_QK)
        queryClient.setQueryData<Array<EmbeddedWebBanner>>(EMBEDDED_WEB_BANNER_ALL_QK, data => {
          if (!data) return

          data.push(embedded_web_banner)
          return data
        })
        showToast(
          "Native web banner restored.",
          undefined,
          getRoutePath("channels.native-banners.detail", { id: embedded_web_banner.id }),
        )
      },
    },
  )
}

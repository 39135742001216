import React from "react"
import styles from "./SegmentTagsPicker.module.scss"
import { Tag as TagType } from "resources/tag/tagTypes"
import Tag from "components/UI/elements/Tag"
import { useFetchAllTags } from "resources/tag/tagQueries"
import TagPicker from "components/UI/components/TagPicker"

type SegmentTagsPickerProps = {
  selectedIds: TagType["id"][]
  addTagId: (id: TagType["id"]) => void
  removeTagId: (id: TagType["id"]) => void
}

export default function SegmentTagsPicker({
  selectedIds,
  addTagId,
  removeTagId,
}: SegmentTagsPickerProps) {
  const { data: tags = [] } = useFetchAllTags()

  return (
    <div className={styles.container}>
      <div className={styles.label}>Filter by:</div>
      <div className={styles.tags}>
        {selectedIds.map(tagId => {
          const tag = tags.find(({ id }) => id === tagId)
          return (
            <Tag
              key={tagId}
              clickable
              color={tag?.color ?? "primary"}
              onClick={() => removeTagId(tagId)}
            >
              {tag?.name ?? "Deleted tag"}
            </Tag>
          )
        })}
        <TagPicker selectedTagIds={selectedIds} allTags={tags} onTagSelect={addTagId} />
      </div>
    </div>
  )
}

import { DateString, DBFormatDateTime, ISO8601DateTimeWithoutTZ } from "types/util"

export function ISOwoTZtoDate(dbTimestamp: ISO8601DateTimeWithoutTZ) {
  return new Date(`${dbTimestamp}Z`)
}

export function DBtimestampToDate(dbTimestamp: DBFormatDateTime) {
  return new Date(`${dbTimestamp.replace(" ", "T")}Z`)
}

// If a user is e.g. in UTC-2, doing `new Date("2022-02-28")` returns a Date object which is 28th
// February 00:00 in UTC, i.e. 27th February 22:00 in the user's local timezone. When formatting
// such a date back to YYYY-MM-DD, we get 2022-02-27. By adding `T00:00` we force the string to be
// interpreted as local timezone, giving us a Date object which is 28th February 00:00 in the user's
// local timezone, which then gets correctly converted back to a date string.
export function toLocalDate(dateString: DateString) {
  return new Date(`${dateString}T00:00`)
}

export function sanitizeDatetime(datetime: Date | string): Date {
  if (datetime instanceof Date) {
    return datetime
  } else if (/Z$/.test(datetime) || /[+-]\d{2}:\d{2}$/.test(datetime)) {
    return new Date(datetime)
  } else {
    return DBtimestampToDate(datetime)
  }
}

import React from "react"
import styles from "./StructuredEventPayload.module.scss"
import { Event, SchemaEntry } from "resources/event/eventTypes"
import { isEmpty } from "ramda"
import { errorMessage, getValue, isCorrectType } from "helpers/event.helper"
import { CustomerEvent } from "resources/customer/event/customerEventTypes"

type StructuredEventPayloadProps = {
  customerEvent: Pick<CustomerEvent, "payload">
  schema?: Omit<Event["schema"], "display"> & { display: Omit<SchemaEntry, "id">[] }
}

export default function StructuredEventPayload({
  customerEvent,
  schema,
}: StructuredEventPayloadProps) {
  if (!schema || !schema.display || isEmpty(schema.display)) {
    return null
  }

  return (
    <div className={styles.container}>
      {schema.display.map((item, index) => {
        switch (item.format) {
          case "ahref": {
            const value = getValue(customerEvent, item.type, item.path)
            return (
              <p key={index} className={styles.entry}>
                {`${item.name}: `}
                {isCorrectType(value, item.type) ? (
                  <a href={value} target="_blank" rel="noopener noreferrer">
                    link
                  </a>
                ) : (
                  errorMessage(value)
                )}
              </p>
            )
          }
          case "ul": {
            const value = getValue(customerEvent, item.type, item.path, true)
            return (
              <div key={index} className={styles.entry}>
                <h5>
                  {`${item.name}:`}
                  {Array.isArray(value) && value.length === 0 ? " —" : ""}
                </h5>
                {Array.isArray(value) ? (
                  <ul key={index}>
                    {value.map((value, idx) => (
                      <li key={idx}>
                        {isCorrectType(value, item.type) ? value : errorMessage(value)}
                      </li>
                    ))}
                  </ul>
                ) : (
                  errorMessage(value)
                )}
              </div>
            )
          }
          default: {
            const value = getValue(customerEvent, item.type, item.path)
            return (
              <p key={index} className={styles.entry}>
                {`${item.name}: ${isCorrectType(value) ? value : errorMessage(value)}`}
              </p>
            )
          }
        }
      })}
    </div>
  )
}

import { useContext, useEffect, useState } from "react"

import ApplicationSettings from "./components/ApplicationSettings/ApplicationSettings"
import { ChannelWizardContext } from "./components/channelWizardContext"
import ChannelPaper from "./components/ChannelPaper/ChannelPaper"
import CustomDataSchema from "./CustomDataSchema/CustomDataSchema"
import FirebaseProjectConfiguration from "./FirebaseProjectConfiguration/FirebaseProjectConfiguration"
import FirebaseProjectForm, {
  FirebaseProjectFormValues,
} from "./components/FirebaseProjectForm/FirebaseProjectForm"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Page from "components/UI/Page/Page"
import StitchingCategory from "./StitchingCategory/StitchingCategory"
import {
  useCreateFirebaseProject,
  useFetchPushNotificationApps,
  useFetchPushNotificationAppsByFirebaseProjectId,
  useFetchFirebaseProjects,
  useFetchPushNotificationsChannel,
} from "resources/channel/channelQueries"
import { getRoutePath } from "routes"

import styles from "./PushNotificationsChannel.module.scss"

export default function PushNotificationsChannel() {
  const { data: channel, isLoading: isLoadingChannel } = useFetchPushNotificationsChannel()
  const { isLoading: isLoadingFirebaseProjects } = useFetchFirebaseProjects()
  const { isLoading: isLoadingApplications } = useFetchPushNotificationApps()

  const isLoading = isLoadingChannel || isLoadingFirebaseProjects || isLoadingApplications

  return (
    <Page title="Mobile Push channel" backRouteFallback={getRoutePath("administration.channels")}>
      {isLoading && <LoadingIndicator />}
      {!isLoading && channel && (
        <>
          {channel.is_active ? (
            <div className={styles.page}>
              <FirebaseProjectConfiguration />
              <StitchingCategory stitching_category_id={channel.stitching_category_id} />
              <CustomDataSchema custom_data_schema={channel.custom_data_schema} />
            </div>
          ) : (
            <PushNotificationsChannelWizard />
          )}
        </>
      )}
    </Page>
  )
}

const PUSH_NOTIFICATIONS_CHANNEL_WIZARD_STEPS = 3

const PushNotificationsChannelWizard = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [expandedSection, setExpandedSection] = useState<string | null>(null)

  const { data: channel } = useFetchPushNotificationsChannel()
  const { data: firebaseProjects = [] } = useFetchFirebaseProjects()
  const { data: applications = [] } = useFetchPushNotificationApps()
  const { stitching_category_id } = channel!

  useEffect(() => {
    if (firebaseProjects.length === 0) setCurrentStep(1)
    else if (applications.length === 0) setCurrentStep(2)
    else if (!stitching_category_id) setCurrentStep(3)
  }, [stitching_category_id, applications.length, firebaseProjects.length])

  const increment = () => setCurrentStep(prev => prev + 1)
  const decrement = () => setCurrentStep(prev => prev - 1)

  const isFirstStep = currentStep === 1
  const isLastStep = currentStep === PUSH_NOTIFICATIONS_CHANNEL_WIZARD_STEPS

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <FirebaseProjectInitialConfiguration />
      case 2:
        return <InitialApplicationSettings />
      case 3:
        return <StitchingCategory isWizard stitching_category_id={stitching_category_id} />
      default:
        return null
    }
  }

  return (
    <ChannelWizardContext.Provider
      value={{
        expandedSection,
        setExpandedSection,
        decrement,
        increment,
        isFirstStep,
        isLastStep,
        currentStep,
        steps: PUSH_NOTIFICATIONS_CHANNEL_WIZARD_STEPS,
      }}
    >
      {renderStep()}
    </ChannelWizardContext.Provider>
  )
}

const FirebaseProjectInitialConfiguration = () => {
  const { data: firebaseProjects = [] } = useFetchFirebaseProjects()

  const { mutate, isLoading } = useCreateFirebaseProject()

  const { increment } = useContext(ChannelWizardContext)

  const onSubmit = ({ firebaseFileList, name }: FirebaseProjectFormValues) => {
    if (isLoading || !firebaseFileList) return

    const formData = new FormData()
    formData.append("name", name)
    formData.append("firebase_account_data", firebaseFileList[0])

    mutate({ data: formData }, { onSuccess: () => increment() })
  }

  const firebaseProjectConfigured = firebaseProjects.length > 0
  const formId = "createProjectForm"

  return (
    <ChannelPaper
      isWizard
      isSubmitting={isLoading}
      formId={formId}
      title="Firebase project"
      skipSubmit={firebaseProjectConfigured}
    >
      <div className={styles.firebaseProjectConfiguration}>
        <FirebaseProjectForm
          firebaseProject={firebaseProjectConfigured ? firebaseProjects[0] : undefined}
          formId={formId}
          onSubmit={onSubmit}
        />
      </div>
    </ChannelPaper>
  )
}

const InitialApplicationSettings = () => {
  const { data: firebaseProjects = [] } = useFetchFirebaseProjects()

  const firebaseProjectId = firebaseProjects[0].id
  const { data: applications = [], isLoading } =
    useFetchPushNotificationAppsByFirebaseProjectId(firebaseProjectId)

  if (isLoading) return <LoadingIndicator />

  return (
    <ChannelPaper
      isWizard
      skipSubmit
      title="Application settings"
      description="Application list is based on your Firebase configuration."
      submitDisabled={applications.length === 0}
    >
      <div className={styles.applicationSettings}>
        <ApplicationSettings firebaseProjectId={firebaseProjectId} />
      </div>
    </ChannelPaper>
  )
}

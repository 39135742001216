import React, { useCallback, useEffect, useMemo, useState } from "react"
// import classNames from "classnames"
import styles from "./CreateLookalikeSegmentModal.module.scss"
import Modal from "components/UI/elements/Modal"
import { Segment } from "resources/segment/segment/segmentTypes"
import SegmentPicker from "components/SegmentPicker/SegmentPicker"
import TextInput from "components/UI/elements/TextInput/TextInput"
import Button from "components/UI/elements/Button/Button"
import { useCreateSegment, useFetchSegmentById } from "resources/segment/segment/segmentQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { isNil } from "ramda"
import { useFetchCustomersCount } from "resources/stats/customersCount"
import { Controller, useForm } from "react-hook-form"
import { max, min, required } from "helpers/validators.helper"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import { useHistory } from "react-router-dom"
import { getRoutePath } from "routes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LookalikeSizeSlider from "../LookalikeSizeSlider/LookalikeSizeSlider"
import {
  emitSegmentCounts,
  useListenSegmentCounts,
  useSegmentCountsStore,
} from "resources/segment/segment/segmentCounts"
import { useHasAccess } from "resources/user/currentUserQueries"

type FormValues = {
  size: number
  includeBaseSegment: boolean
}

type CreateLookalikeSegmentModalProps = {
  isOpen?: boolean
  segmentId?: Segment["id"]
  onClose: () => void
}

export default function CreateLookalikeSegmentModal({
  isOpen = true,
  segmentId,
  onClose,
}: CreateLookalikeSegmentModalProps) {
  const history = useHistory()
  const [selectedSegmentId, setSelectedSegmentId] = useState(segmentId ?? null)
  const { data: segment } = useFetchSegmentById(selectedSegmentId)

  useListenSegmentCounts(selectedSegmentId)
  const { conditionsResultsCount: baseSegmentSize, clear: clearSegmentCouns } =
    useSegmentCountsStore()

  useEffect(() => {
    if (selectedSegmentId && !segmentId && isOpen) {
      clearSegmentCouns()
      emitSegmentCounts({
        segmentId: selectedSegmentId,
        refreshCache: false,
      })
    }
  }, [isOpen, selectedSegmentId, segmentId, clearSegmentCouns])

  useEffect(() => {
    if (!segmentId && !selectedSegmentId) clearSegmentCouns()
  }, [segmentId, selectedSegmentId, clearSegmentCouns])

  const { data: customersCount, isLoading: isLoadingCustomersCount } = useFetchCustomersCount()
  const totalCount = customersCount?.customer_entities_count

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<FormValues>({
    defaultValues: { includeBaseSegment: false, size: 1 },
  })

  useEffect(() => {
    setSelectedSegmentId(segmentId ?? null)
    reset()
  }, [segmentId, isOpen, reset])

  const createMutation = useCreateSegment("lookalike")

  function createSegment({ size, includeBaseSegment }: FormValues) {
    createMutation.mutate(
      {
        name: `Lookalike – '${segment!.name}'`,
        settings: {
          lookalike: { size, include_base_segment: includeBaseSegment },
          conditions_operation: segment!.settings?.conditions_operation,
        },
        segment_type: "lookalike",
      },
      {
        onSuccess({ segment: { id } }) {
          history.push(getRoutePath("segments.lookalike.detail", { id }))
        },
      },
    )
  }

  const includeBaseSegment = watch("includeBaseSegment")
  const size = watch("size")

  const maxSize = useMemo(() => (totalCount ? Math.floor(totalCount * 0.2) : 1), [totalCount])
  const minSize = useMemo(
    () =>
      includeBaseSegment && !isNil(baseSegmentSize) ? Math.min(baseSegmentSize + 1, maxSize) : 1,
    [baseSegmentSize, includeBaseSegment, maxSize],
  )

  useEffect(() => {
    if (isNil(baseSegmentSize)) {
      return
    }

    const prevSize = getValues().size

    if (includeBaseSegment) {
      setValue("size", Math.min(prevSize + baseSegmentSize, maxSize))
    } else {
      setValue("size", Math.max(prevSize - baseSegmentSize, minSize))
    }
  }, [baseSegmentSize, getValues, includeBaseSegment, maxSize, minSize, setValue])

  let warning = ""
  let disabled = false

  if (baseSegmentSize === 0) {
    disabled = true
    warning =
      "The size of the base segment is 0, so the resulting size of the lookalike segment will also be 0."
  } else if (includeBaseSegment) {
    if (!isNil(baseSegmentSize) && baseSegmentSize >= maxSize) {
      disabled = true
      warning =
        "The size of the base segment is greater than or equal to 20 % of the entire profile database, so it's not possible to find lookalike profiles that don't belong to this segment."
    }
  } else {
    if (!isNil(baseSegmentSize) && !isNil(totalCount) && baseSegmentSize >= totalCount) {
      disabled = true
      warning =
        "The base segment includes the whole profiles database, so the size of the lookalike segment will be 0."
    } else if (
      !isNil(baseSegmentSize) &&
      !isNil(totalCount) &&
      baseSegmentSize + size > totalCount
    ) {
      warning =
        "The base segment is too large, so it won't be possible to find the specified number of profiles."
    }
  }

  const onSliderChange = useCallback(
    (value: number) => {
      setValue("size", value)
    },
    [setValue],
  )

  const hasAccess = useHasAccess()

  return (
    <Modal size="large" title="Create lookalike segment" open={isOpen} handleClose={onClose}>
      <form className={styles.body}>
        <div className={styles.description}>
          Find new profiles with similar attributes. The lookalike segment size can reach a maximum
          of 20 % of the entire profile base.
        </div>
        <div className={styles.inputs}>
          <SegmentPicker
            label="Base segment"
            value={selectedSegmentId}
            onChange={setSelectedSegmentId}
            disabled={!isNil(segmentId)}
            enabledTypes={hasAccess.segments.featured.view ? ["custom", "featured"] : ["custom"]}
          />
          {isLoadingCustomersCount || (selectedSegmentId && isNil(baseSegmentSize)) ? (
            <LoadingIndicator className={styles.loadingIndicator} />
          ) : (
            <>
              <TextInput value={baseSegmentSize ?? ""} label="Selected segment size" disabled />

              <Controller
                control={control}
                rules={{
                  validate: {
                    required,
                    max: max(maxSize),
                    min: min(minSize),
                  },
                }}
                name="size"
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    type="number"
                    data-testid="ls-size-input"
                    label="Lookalike segment size"
                    disabled={isNil(baseSegmentSize)}
                    min={1}
                    max={maxSize}
                    error={errors.size?.message}
                    value={value}
                    onChange={e => {
                      onChange(parseInt(e.target.value))
                    }}
                  />
                )}
              />
            </>
          )}
          <Controller
            control={control}
            name="includeBaseSegment"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                checked={value}
                onChange={onChange}
                label="Include base segment"
                disabled={isNil(baseSegmentSize)}
                className={styles.checkbox}
              />
            )}
          />
        </div>

        <LookalikeSizeSlider
          disabled={isNil(baseSegmentSize)}
          maxSize={maxSize}
          baseSegmentSize={includeBaseSegment && baseSegmentSize ? baseSegmentSize : undefined}
          value={size}
          onChange={onSliderChange}
        />

        {warning && (
          <div data-testid="warning-message" className={styles.warning}>
            <FontAwesomeIcon icon={["far", "info-circle"]} /> {warning}
          </div>
        )}
        <div className={styles.buttons}>
          <Button variant="text" color="grey" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button
            loading={createMutation.isLoading}
            onClick={handleSubmit(createSegment)}
            disabled={disabled || isNil(selectedSegmentId)}
            type="submit"
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  )
}

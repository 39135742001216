import { useQuery } from "@tanstack/react-query"
import { api } from "api"
import { UsersActivityFilters } from "./usersActivityTypes"

export function useFetchUsersActivity({
  since,
  until,
  filterType,
  filterValue,
}: UsersActivityFilters) {
  return useQuery(
    ["usersActivity", since, until, filterType, filterValue],
    () => api.usersActivity({ since, until, filterType, filterValue }),
    { enabled: false, keepPreviousData: true },
  )
}

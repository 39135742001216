import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query"
import requestFactory from "api/request"

type CustomersCountResponse = {
  customer_entities_count: number
  customers_before_stitching_count: number
}

function customersCount(): Promise<CustomersCountResponse> {
  return requestFactory("get", "/customers_count")
}

export function useFetchCustomersCount(
  config?: UseQueryOptions<CustomersCountResponse, unknown, CustomersCountResponse, QueryKey>,
) {
  return useQuery(["customersCount"] as QueryKey, customersCount, {
    ...config,
    staleTime: 60 * 1000,
  })
}

import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import { api } from "api"
import { queryClient } from "app/queryClient"
import {
  equals,
  groupBy,
  isNil,
  length,
  mapObjIndexed,
  path,
  pipe,
  prop,
  sort,
  uniq,
  update,
} from "ramda"
import { useCallback } from "react"
import { Source } from "resources/dataSource/dataSourceTypes"
import { Label } from "../attributeLabel/attributeLabelTypes"
import { OrderDir } from "types/util"
import { ascend, descend } from "utilities/comparators"
import {
  Attribute,
  AttributeCreatePayload,
  AttributeFull,
  AttributeModifyPayload,
  AttributeSort,
  AttributeTestPayload,
} from "./attributeTypes"

const ATTRIBUTE_ALL_QK: QueryKey = ["attribute", "all"]

export const prefetchAttributes = () => {
  queryClient.prefetchQuery(ATTRIBUTE_ALL_QK, api.attribute.listAll)
}

export const refetchAttributes = () =>
  queryClient.invalidateQueries(
    { queryKey: ATTRIBUTE_ALL_QK, refetchType: "all" },
    { throwOnError: false },
  )

function useAttributesQuery<T>(config?: UseQueryOptions<AttributeFull[], unknown, T, QueryKey>) {
  return useQuery(ATTRIBUTE_ALL_QK, api.attribute.listAll, {
    staleTime: 60 * 1000,
    ...config,
  })
}

type FetchAllAttributesOpts = {
  includeHidden?: boolean
  orderBy?: AttributeSort
  orderDir?: OrderDir
  searchTerm?: string
  labelIds?: Label["id"][]
  sourceId?: Source["id"]
}

export function useFetchAllAttributes(
  { includeHidden, labelIds, orderBy, orderDir, searchTerm, sourceId }: FetchAllAttributesOpts = {},
  config: UseQueryOptions<AttributeFull[], unknown, AttributeFull[], QueryKey> = {},
) {
  const select = useCallback(
    (attributes: AttributeFull[]) => {
      let selectedAttributes = attributes

      if (!includeHidden) {
        selectedAttributes = selectedAttributes.filter(({ is_hidden }) => !is_hidden)
      }

      if (labelIds && labelIds.length > 0) {
        selectedAttributes = selectedAttributes.filter(({ tags }) =>
          tags?.some(label => labelIds.includes(label.id)),
        )
      }

      if (sourceId) {
        selectedAttributes = selectedAttributes.filter(({ source }) => source.id === sourceId)
      }

      if (searchTerm) {
        selectedAttributes = selectedAttributes.filter(
          ({ id, name }) =>
            name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            id.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      }

      if (orderBy && orderDir) {
        const comparator = orderDir === "ASC" ? ascend : descend
        const getSortingProperty =
          orderBy === "labels"
            ? ({ tags }: AttributeFull) =>
                (tags && sort(comparator(prop("name")), tags)[0]?.name) ?? "zzz"
            : orderBy === "source"
            ? path(["source", "name"])
            : prop("name")

        selectedAttributes = sort(comparator(getSortingProperty), selectedAttributes)
      }

      return selectedAttributes
    },
    [includeHidden, labelIds, orderBy, orderDir, searchTerm, sourceId],
  )

  return useAttributesQuery({ select, ...config })
}

type FetchAttributesMapOpts = {
  includeHidden?: boolean
  includeId?: AttributeFull["id"]
}

export function useFetchAttributesMap({ includeHidden, includeId }: FetchAttributesMapOpts = {}) {
  const select = useCallback(
    (attributes: AttributeFull[]) => {
      let filteredAttributes = attributes

      if (!includeHidden) {
        filteredAttributes = filteredAttributes.filter(
          ({ id, is_hidden }) => id === includeId || !is_hidden,
        )
      }

      return Object.fromEntries(filteredAttributes.map(attribute => [attribute.id, attribute]))
    },
    [includeHidden, includeId],
  )

  return useAttributesQuery({ select })
}

export function useFetchAttributeById(
  id?: Attribute["id"] | null,
  config: UseQueryOptions<AttributeFull[], unknown, AttributeFull | null, QueryKey> = {},
) {
  const select = useCallback(
    (attributes: AttributeFull[]) => attributes.find(attribute => attribute.id === id) ?? null,
    [id],
  )

  return useAttributesQuery({ select, enabled: !isNil(id), ...config })
}

const toMapBySourceId = pipe(
  sort<AttributeFull>(ascend(prop("name"))),
  sort(ascend(prop("order_index"))),
  groupBy(({ source }) => source.id),
)

export function useFetchAttributesMapBySourceId() {
  return useAttributesQuery({ select: toMapBySourceId })
}

export function useFetchAttributesCount() {
  return useAttributesQuery({ select: length })
}

const selectActiveLabels = (attributes: AttributeFull[]) =>
  sort(ascend(prop("name")), uniq(attributes.flatMap(({ tags }) => tags ?? [])))

export function useFetchActiveLabels() {
  return useAttributesQuery({ select: selectActiveLabels })
}

type PreviewExample =
  // Single-value non-compound
  | string
  // Multi-value non-compound
  | Array<string>
  // Single-value compound
  | Record<string, string>
  // Multi-value compound
  | Array<Record<string, string>>

function getAttributeExampleForPreview({ id, examples, is_unique }: AttributeFull): PreviewExample {
  if (Array.isArray(examples)) {
    if (examples.length === 0) {
      return `{{${id}}}`
    }
    return is_unique ? String(examples[0]) : examples.map(String)
  }

  if (is_unique) {
    return mapObjIndexed<string[], string>(
      (dimensionExamples, dimensionId) =>
        dimensionExamples.length === 0 ? `{{${id}.${dimensionId}}}` : String(dimensionExamples[0]),
      examples,
    )
  }

  const MAX_EXAMPLES = 3
  const examplesLength = Object.values(examples).reduce(
    (acc, { length }) => Math.min(acc, length),
    MAX_EXAMPLES,
  )

  if (examplesLength === 0) {
    return [
      mapObjIndexed<string[], string>((_, dimensionId) => `{{${id}.${dimensionId}}}`, examples),
    ]
  }

  return Array(examplesLength)
    .fill(undefined)
    .map((_, i) =>
      mapObjIndexed<string[], string>(
        (_, dimensionId) => String(examples[dimensionId][i]),
        examples,
      ),
    )
}

export function useFetchAttributeExamplesMap() {
  return useAttributesQuery({
    select: attributes =>
      Object.fromEntries(
        attributes.map(attribute => [attribute.id, getAttributeExampleForPreview(attribute)]),
      ),
  })
}

export function useCreateAttribute() {
  const queryClient = useQueryClient()

  return useMutation(({ data }: { data: AttributeCreatePayload }) => api.attribute.create(data), {
    onSuccess: ({ attribute }) => {
      queryClient.setQueryData<AttributeFull[]>(ATTRIBUTE_ALL_QK, data => {
        if (!data) {
          return
        }

        return data.concat(attribute)
      })
      showToast("Attribute created.")
    },
  })
}

export function useModifyAttribute() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: Attribute["id"]; data: AttributeModifyPayload }) =>
      api.attribute.modify(id, data),
    {
      onSuccess: ({ attribute }, { data }) => {
        queryClient.setQueryData<AttributeFull[]>(ATTRIBUTE_ALL_QK, data => {
          if (!data) {
            return
          }

          const index = data.findIndex(({ id }) => id === attribute.id)

          return index === -1 ? data.concat(attribute) : update(index, attribute, data)
        })
        showToast(
          equals(data, { is_hidden: 1 })
            ? "Attribute hidden."
            : equals(data, { is_hidden: 0 })
            ? "Attribute visible."
            : "Attribute modified.",
        )
      },
    },
  )
}

export function useTestAttribute() {
  return useMutation(({ data }: { data: AttributeTestPayload }) => api.attribute.test(data))
}

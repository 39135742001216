import Page from "components/UI/Page/Page"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import marketingImg from "./marketingContent.jpg"
import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"

export default function SMS() {
  return (
    <Switch>
      <Route path={getRoutePath("channels.sms")} exact component={SMSMarketingContent} />
    </Switch>
  )
}

function SMSMarketingContent() {
  return (
    <Page title="SMS campaigns">
      <MarketingContent img={{ alt: "SMS", src: marketingImg }}>
        <h1>Coming Soon: SMS Campaigns</h1>
        <p>
          Our new SMS Channel is on its way, simplifying your engagement efforts for better
          performance.
        </p>
        <p>Stay tuned, beta version coming soon!</p>
      </MarketingContent>{" "}
    </Page>
  )
}

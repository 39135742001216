import React from "react"
import classNames from "classnames"
import styles from "./CampaignSchedulingIcon.module.scss"
import { MobilePushNotificationSchedule } from "resources/mobilePushNotification/mobilePushNotificationTypes"
import { EmailSchedule } from "resources/email/emailTypes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tippy from "@tippyjs/react"
import { useFetchSegmentsByIds } from "resources/segment/segment/segmentQueries"
import { Segment } from "resources/segment/segment/segmentTypes"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { isFuture } from "date-fns"
import { whereEq } from "ramda"
import { DATE_FMT } from "sharedConstants"
import { getScheduleText } from "resources/segment/segment/utilities/segmentSchedulesUtils"
import { format, utcToZonedTime } from "date-fns-tz"

type CampaignSchedule = MobilePushNotificationSchedule | EmailSchedule

type CampaignSchedulingIconProps = {
  schedules: CampaignSchedule[]
}

export default function CampaignSchedulingIcon({ schedules }: CampaignSchedulingIconProps) {
  const activeSchedules = schedules.filter(
    schedule =>
      (schedule.schedule_type === "repetitive" && schedule.schedules.length > 0) ||
      (schedule.schedule_type === "static" && isFuture(new Date(schedule.schedule_time))),
  )
  const isActive = activeSchedules.length > 0
  const segmentQueries = useFetchSegmentsByIds(
    activeSchedules.flatMap(({ segment_ids }) => segment_ids),
  )
  const segments = segmentQueries.map(({ data }) => data?.segment).filter(Boolean) as Segment[]
  const isLoading = segmentQueries.some(({ isLoading }) => isLoading)

  const tooltipContent = !isActive ? (
    "No scheduled activations."
  ) : (
    <div className={styles.tooltip}>
      <h3>Scheduled activations</h3>
      <ul className={styles.activationsList}>
        {activeSchedules.map((schedule, index) => {
          const { schedule_type, segment_ids } = schedule

          const timeEl =
            schedule_type === "static" ? (
              <div>
                One-time activation at{" "}
                {format(utcToZonedTime(schedule.schedule_time, "UTC"), DATE_FMT.DATETIME, {
                  timeZone: "UTC",
                })}{" "}
                UTC
              </div>
            ) : (
              <div>
                <div>
                  Repeating activation{" "}
                  {schedule.schedules.length === 1 && getScheduleText(schedule.schedules[0])}
                </div>
                {schedule.schedules.length > 1 && (
                  <ul>
                    {schedule.schedules.map((cron, index) => (
                      <li key={index}>{getScheduleText(cron)}</li>
                    ))}
                  </ul>
                )}
              </div>
            )

          return (
            <li key={index} className={styles.activation}>
              {timeEl}
              <div>
                <div>Segments:</div>
                <ul>
                  {segment_ids.map(id => (
                    <li key={id}>
                      <div className={styles.segment}>
                        {segments.find(whereEq({ id }))?.name}{" "}
                        <span className={styles.segmentId}>ID: {id}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )

  return (
    <Tippy content={tooltipContent} placement="right" disabled={isLoading}>
      <div className={classNames(styles.container, { [styles.active]: isActive })}>
        {isLoading ? (
          <LoadingIndicator fixedWidth size="xs" />
        ) : (
          <FontAwesomeIcon icon={["far", "clock"]} />
        )}
      </div>
    </Tippy>
  )
}

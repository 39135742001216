import { prop, whereEq } from "ramda"
import { UserRole } from "resources/userRole/userRoleTypes"
import {
  FunnelGroupPermission,
  FunnelGroupPermissionCreatePayload,
  FunnelGroupPermissionModifyPayload,
} from "./funnelGroupPermissionTypes"

export type PermissionsDiff = {
  create: FunnelGroupPermissionCreatePayload[]
  modify: [UserRole["id"], FunnelGroupPermissionModifyPayload][]
  delete: UserRole["id"][]
}

export function diffPermissionsLists(
  prevPermissions: FunnelGroupPermission[],
  newPermissions: FunnelGroupPermissionCreatePayload[],
): PermissionsDiff {
  return {
    create: newPermissions.filter(
      ({ user_role_id }) => !prevPermissions.some(whereEq({ user_role_id })),
    ),
    modify: newPermissions
      .filter(({ user_role_id, permission }) => {
        const prevPermission = prevPermissions.find(whereEq({ user_role_id }))
        return prevPermission && prevPermission.permission !== permission
      })
      .map(({ user_role_id, permission }) => [user_role_id, { permission }]),
    delete: prevPermissions
      .filter(({ user_role_id }) => !newPermissions.some(whereEq({ user_role_id })))
      .map(prop("user_role_id")),
  }
}

import React, { useEffect, useState } from "react"
import styles from "./ActivateModal.module.scss"
import Modal from "components/UI/elements/Modal"
import { Controller, useForm } from "react-hook-form"
import CustomDatePicker from "components/UI/components/CustomDatePicker"
import Button from "components/UI/elements/Button/Button"
import { useActivateJourney } from "resources/journey/journeyQueries"
import { Journey } from "resources/journey/journeyTypes"
import classNames from "classnames"
import { required } from "helpers/validators.helper"
import { always } from "ramda"

type ActivateModalProps = {
  isOpen: boolean
  onClose: () => void
  journeyId: Journey["id"]
}

type ActivationFormValues = {
  startDate: string | null
  endDate: string | null
}

export default function ActivateModal({ isOpen, onClose, journeyId }: ActivateModalProps) {
  const { control, setValue, handleSubmit, reset } = useForm<ActivationFormValues>({
    defaultValues: { startDate: null, endDate: null },
  })
  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false)
  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(false)
  const [hasStartDate, setHasStartDate] = useState(true)
  const [hasEndDate, setHasEndDate] = useState(false)
  const activateMutation = useActivateJourney()

  function submit(data: ActivationFormValues) {
    activateMutation.mutate(
      {
        id: journeyId,
        data: {
          start_datetime: data.startDate ?? undefined,
          end_datetime: data.endDate ?? undefined,
          activate_now: !data.startDate,
        },
      },
      {
        onSuccess() {
          onClose()
        },
      },
    )
  }

  useEffect(() => {
    if (!isOpen) {
      reset()
      setHasStartDate(false)
      setHasEndDate(false)
    }
  }, [isOpen, reset])

  return (
    <Modal open={isOpen} title="Activate Journey" handleClose={onClose}>
      <form id="activateForm" onSubmit={handleSubmit(submit)} className={styles.content}>
        <div className={styles.group}>
          <div className={styles.radioGroup}>
            <label className={styles.radio}>
              <input
                type="radio"
                name="start"
                checked={!hasStartDate}
                onChange={e => {
                  setValue("startDate", null)
                  setHasStartDate(!e.target.checked)
                }}
              />
              Start now
            </label>
            <label className={styles.radio}>
              <input
                type="radio"
                name="start"
                checked={hasStartDate}
                onChange={e => setHasStartDate(e.target.checked)}
              />
              Start at
            </label>
          </div>

          <Controller
            name="startDate"
            control={control}
            rules={{ validate: { required: hasStartDate ? required : always(undefined) } }}
            render={({ field, fieldState: { error } }) => (
              <div className={classNames("text-field", { error })}>
                <CustomDatePicker
                  value={field.value}
                  onChange={field.onChange}
                  open={isStartDatePickerOpen}
                  toggle={() => setIsStartDatePickerOpen(prev => !prev)}
                  dataType="datetime"
                  isEditable={hasStartDate}
                  withoutNL
                  placeholder="Pick start date"
                  disabled={!hasStartDate}
                />
                {error && <div className="error-message">{error.message}</div>}
              </div>
            )}
          />
        </div>
        <div className={styles.group}>
          <div className={styles.radioGroup}>
            <label className={styles.radio}>
              <input
                type="radio"
                name="end"
                checked={!hasEndDate}
                onChange={e => {
                  setValue("endDate", null)
                  setHasEndDate(!e.target.checked)
                }}
              />
              Never end
            </label>
            <label className={styles.radio}>
              <input
                type="radio"
                name="end"
                checked={hasEndDate}
                onChange={e => setHasEndDate(e.target.checked)}
              />
              End at
            </label>
          </div>

          <Controller
            name="endDate"
            control={control}
            rules={{ validate: { required: hasEndDate ? required : always(undefined) } }}
            render={({ field, fieldState: { error } }) => (
              <div className={classNames("text-field", { error })}>
                <CustomDatePicker
                  value={field.value}
                  onChange={field.onChange}
                  open={isEndDatePickerOpen}
                  toggle={() => setIsEndDatePickerOpen(prev => !prev)}
                  dataType="datetime"
                  isEditable={hasEndDate}
                  withoutNL
                  placeholder="Pick end date"
                  disabled={!hasEndDate}
                />
                {error && <div className="error-message">{error.message}</div>}
              </div>
            )}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            loading={activateMutation.isLoading}
            disabled={activateMutation.isLoading}
            type="submit"
            form="activateForm"
          >
            Activate
          </Button>
        </div>
      </form>
    </Modal>
  )
}

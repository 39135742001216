import React from "react"
import Button from "components/UI/elements/Button/Button"
import SelectField from "components/UI/elements/SelectField"
import { required } from "helpers/validators.helper"
import "./ParametersController.scss"
import { Controller, useForm } from "react-hook-form"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { SelectOption } from "types/util"

type FieldValues = Array<SelectOption<string>>

const getParameterOptions = (values: FieldValues) =>
  Object.entries(values ?? {}).map(([id, label]) => ({ value: id, label }))

type FormValues = Record<string, string>

type ParametersFormProps = {
  isEditable: boolean
  fieldsConfig: Array<{
    input_mode: Array<"user_input" | "value_from_list">
    name: string
    required: boolean
    title: string
    values?: FieldValues
  }>
  initialValues: FormValues
  onSubmit: (values: FormValues) => void
}

export default function ParametersForm({
  onSubmit,
  fieldsConfig,
  isEditable,
  initialValues,
}: ParametersFormProps) {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: initialValues })

  if (fieldsConfig.length === 0) {
    // do not display parameters section if there aren't any fields defined by admin
    return null
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="body">
        {fieldsConfig.map(({ input_mode, name, required: isRequired, title, values }) => {
          const hasUserInput = Array.isArray(input_mode) && input_mode.includes("user_input")
          const isSelect = Array.isArray(input_mode) && input_mode.includes("value_from_list")
          const isCreatable = isSelect && hasUserInput

          if (isSelect && values)
            return (
              <div className="form-row" key={name}>
                <Controller
                  name={name}
                  control={control}
                  rules={isRequired ? { validate: required } : undefined}
                  render={({ field, fieldState: { error } }) => (
                    <SelectField
                      isSimpleValue
                      input={field}
                      meta={{ touched: true, error: error?.message }}
                      placeholder={title}
                      label={`${title}${isRequired ? " *" : ""}`}
                      disabled={!isEditable}
                      options={getParameterOptions(values)}
                      isClearable
                      isCreatable={isCreatable}
                    />
                  )}
                />
              </div>
            )
          else
            return (
              <div className="form-row" key={name}>
                <TextInput
                  {...register(name, isRequired ? { validate: required } : undefined)}
                  error={errors[name]?.message}
                  placeholder={title}
                  label={`${title}${isRequired ? " *" : ""}`}
                  disabled={!isEditable}
                />
              </div>
            )
        })}
      </div>
      <div className="action-area">
        <div className="right-part">
          <Button loading={isSubmitting} type="submit" disabled={!isEditable}>
            Save Parameters
          </Button>
        </div>
      </div>
    </form>
  )
}

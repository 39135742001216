import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { api } from "api"
import { showToast } from "app/toast"
import { always, prop, reject, sort, update, whereEq } from "ramda"
import { getRoutePath } from "routes"
import { TrashItem } from "types/trash"
import { ascend } from "utilities/comparators"
import {
  FunnelGroup,
  FunnelGroupCreatePayload,
  FunnelGroupListDeletedResponse,
  FunnelGroupListResponse,
  FunnelGroupModifyPayload,
} from "./funnelGroupTypes"

const FUNNEL_GROUP_ALL_QK: QueryKey = ["funnelGroup", "all"]
const FUNNEL_GROUP_TRASH_QK: QueryKey = ["funnelGroup", "trash"]
const FUNNEL_GROUP_ALL_QK_BY_USER: QueryKey = ["funnelGroupByUser", "all"]

function useFunnelGroupsQuery<T>(
  config?: UseQueryOptions<FunnelGroupListResponse, unknown, T, QueryKey>,
) {
  return useQuery(FUNNEL_GROUP_ALL_QK, api.funnelGroup.listAll, {
    staleTime: 10 * 1000,
    ...config,
  })
}

export function useFetchAllFunnelGroupsByUser(userId: number | null) {
  return useQuery(
    FUNNEL_GROUP_ALL_QK_BY_USER,
    () => api.funnelGroup.listAllFunnelGroupsByUser(userId),
    {
      select: ({ user_funnel_groups }) => {
        return user_funnel_groups.filter(group => group.permission === "write")
      },
      staleTime: 10 * 1000,
      enabled: !!userId,
    },
  )
}

export function useFetchAllFunnelGroups({
  includeDisabled = true,
}: { includeDisabled?: boolean } = {}) {
  return useFunnelGroupsQuery({
    select: ({ funnel_groups }) => {
      if (!includeDisabled) return funnel_groups.filter(group => !group.disabled)

      return funnel_groups
    },
  })
}

export function useFetchFunnelGroup(id: FunnelGroup["id"]) {
  return useFunnelGroupsQuery({
    select: ({ funnel_groups }) => funnel_groups.find(whereEq({ id })) ?? null,
  })
}

export function useCreateFunnelGroup() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: FunnelGroupCreatePayload }) => api.funnelGroup.create(data),
    {
      onSuccess({ funnel_group }) {
        queryClient.setQueryData<FunnelGroupListResponse>(FUNNEL_GROUP_ALL_QK, data => {
          if (!data) return

          return {
            funnel_groups: [...data.funnel_groups, funnel_group],
          }
        })
        // showToast("Funnel group created.")
      },
    },
  )
}

export function useModifyFunnelGroup() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: FunnelGroup["id"]; data: FunnelGroupModifyPayload }) =>
      api.funnelGroup.modify(id, data),
    {
      onSuccess({ funnel_group }) {
        queryClient.setQueryData<FunnelGroupListResponse>(FUNNEL_GROUP_ALL_QK, data => {
          if (!data) return

          const index = data.funnel_groups.findIndex(whereEq({ id: funnel_group.id }))

          return {
            funnel_groups:
              index === -1
                ? data.funnel_groups.concat(funnel_group)
                : update(index, funnel_group, data.funnel_groups),
          }
        })
        // showToast("Funnel group modified.")
      },
    },
  )
}

export function useDeleteFunnelGroup() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: FunnelGroup["id"] }) => api.funnelGroup.delete(id), {
    onSuccess: (_, { id }) => {
      queryClient.setQueryData<FunnelGroupListResponse>(FUNNEL_GROUP_ALL_QK, data => {
        if (!data) return

        return {
          funnel_groups: reject(whereEq({ id }), data.funnel_groups),
        }
      })
      showToast("Funnel group deleted.")
    },
  })
}

export function useMoveFunnelGroup() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: FunnelGroup["id"]; data: { from_index: number; to_index: number } }) =>
      api.funnelGroup.move(id, { order_index: data.to_index }),
    {
      onMutate({ id, data: { from_index, to_index } }) {
        const prevState = structuredClone(
          queryClient.getQueryData<FunnelGroupListResponse>(FUNNEL_GROUP_ALL_QK),
        )

        queryClient.setQueryData<FunnelGroupListResponse>(FUNNEL_GROUP_ALL_QK, data => {
          if (!data) return

          const index = data.funnel_groups.findIndex(whereEq({ id }))
          if (index === -1) return

          // The `0.1` is a little hack so that I don't have to change the order index of the
          // other items. Will invalidate the query after the mutation to get the correct order.
          data.funnel_groups[index].order_index = to_index + (to_index > from_index ? 0.1 : -0.1)

          return {
            funnel_groups: sort(ascend(prop("order_index")), data.funnel_groups),
          }
        })

        return prevState
      },
      onError(_, __, prevState) {
        queryClient.setQueryData(FUNNEL_GROUP_ALL_QK, prevState)
      },
      onSuccess() {
        queryClient.invalidateQueries(FUNNEL_GROUP_ALL_QK)
        showToast("Funnel group moved.")
      },
    },
  )
}

export const useFetchFunnelGroupTrashItems = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<
    FunnelGroupListDeletedResponse,
    string,
    Omit<FunnelGroupListDeletedResponse, "trashed_funnel_groups"> & {
      trashed_funnel_groups: Array<TrashItem>
    },
    QueryKey
  >(FUNNEL_GROUP_TRASH_QK, () => api.funnelGroup.listDeleted(), {
    getNextPageParam: always(undefined),
    select: data => {
      return {
        ...data,
        pages: data.pages.map(p => ({
          ...p,
          trashed_funnel_groups: p.trashed_funnel_groups.map(
            ({ id, name, modified, modified_by }) => {
              const trashItem: TrashItem = {
                id,
                name,
                deleted_at: modified,
                deleted_by: modified_by,
                type: "funnel_groups",
              }

              return trashItem
            },
          ),
        })),
      }
    },
  })

  return {
    ...rest,
    data: data
      ? data.pages
          .flatMap(m => m.trashed_funnel_groups)
          .filter(({ name }) =>
            searchTerm ? name.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) : true,
          )
      : [],
  }
}

export function useRestoreFunnelGroup() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: FunnelGroup["id"] }) => api.funnelGroup.restoreDeleted(id), {
    onSuccess: ({ funnel_group }) => {
      queryClient.setQueryData<FunnelGroupListResponse>(FUNNEL_GROUP_ALL_QK, data => {
        if (!data) return

        return {
          funnel_groups: [...data.funnel_groups, funnel_group],
        }
      })

      queryClient.setQueryData<InfiniteData<FunnelGroupListDeletedResponse>>(
        FUNNEL_GROUP_TRASH_QK,
        data => {
          if (!data) return

          return {
            ...data,
            pages: data.pages.map(({ trashed_funnel_groups }) => ({
              trashed_funnel_groups: reject(
                whereEq({ id: funnel_group.id }),
                trashed_funnel_groups,
              ),
            })),
          }
        },
      )

      showToast(
        "Funnel group restored.",
        undefined,
        getRoutePath("administration.funnel-groups.detail", { id: funnel_group.id }),
      )
    },
  })
}

import React, { PropsWithChildren } from "react"
import styles from "./NodeFormLayout.module.scss"
import Button from "components/UI/elements/Button/Button"
import classNames from "classnames"
import IconButton from "components/UI/elements/IconButton/IconButton"

type NodeFormLayoutProps = PropsWithChildren<{
  previewCard: React.ReactNode
  onSubmit: () => void
  onClose: () => void
  isSubmitting: boolean
  width?: "sm" | "md" | "lg"
  isEditable: boolean
  onClickBack?: () => void
}>

export default function NodeFormLayout({
  previewCard,
  onClose,
  onSubmit,
  isSubmitting,
  children,
  width = "md",
  isEditable,
  onClickBack,
}: NodeFormLayoutProps) {
  return (
    <div className={classNames(styles.container, styles[width])}>
      <div className={styles.header}>
        <IconButton
          icon={["fas", "chevron-left"]}
          onClick={onClickBack ?? onClose}
          variant="transparent"
          color="grey"
        />
        {previewCard}
        {isEditable && (
          <div className={styles.actions}>
            <Button onClick={onSubmit} loading={isSubmitting} disabled={isSubmitting}>
              Save
            </Button>
          </div>
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

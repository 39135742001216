import { QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { api } from "api"
import {
  AttributeAggregation,
  AttributeAggregationCreatePayload,
  AttributeAggregationModifyPayload,
  AttributeAggregationReorderPayload,
  AttributeAggregations,
} from "./attributeAggregationTypes"
import { showToast } from "app/toast"
import { move, update } from "ramda"

const ATTRIBUTE_AGGREGATION = "attributeAggregation" as const
const ATTRIBUTE_AGGREGATION_ALL_VALID_ONLY = [ATTRIBUTE_AGGREGATION, "all", "validOnly"] as QueryKey
const ATTRIBUTE_AGGREGATION_ALL_INCLUDING_INVALID = [
  ATTRIBUTE_AGGREGATION,
  "all",
  "includingInvalid",
] as QueryKey
const ATTRIBUTE_AGGREGATION_ALL_QUERY_KEYS = [
  ATTRIBUTE_AGGREGATION_ALL_VALID_ONLY,
  ATTRIBUTE_AGGREGATION_ALL_INCLUDING_INVALID,
]

export const useFetchAllAttributeAggregations = (onlyWithValidAttributes = 1) =>
  useQuery(
    onlyWithValidAttributes
      ? ATTRIBUTE_AGGREGATION_ALL_VALID_ONLY
      : ATTRIBUTE_AGGREGATION_ALL_INCLUDING_INVALID,
    () => api.attributeAggregation.listAll(onlyWithValidAttributes),
    { staleTime: 5 * 60 * 1000 },
  )

export const useCreateAttributeAggregation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: AttributeAggregationCreatePayload }) =>
      api.attributeAggregation.create(data),
    {
      onSuccess: ({ attribute_aggregation }) => {
        ATTRIBUTE_AGGREGATION_ALL_QUERY_KEYS.forEach(queryKey => {
          queryClient.setQueryData<AttributeAggregations>(queryKey, data => {
            if (!data) return
            return data.concat(attribute_aggregation)
          })
        })
        showToast("Insight created.")
      },
    },
  )
}

export const useModifyAttributeAggregation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: AttributeAggregation["id"]; data: AttributeAggregationModifyPayload }) =>
      api.attributeAggregation.modify(id, data),
    {
      onSuccess: ({ attribute_aggregation }) => {
        ATTRIBUTE_AGGREGATION_ALL_QUERY_KEYS.forEach(queryKey => {
          queryClient.setQueryData<AttributeAggregations>(queryKey, data => {
            if (!data) return

            const index = data.findIndex(({ id }) => id === attribute_aggregation.id)

            return index === -1
              ? data.concat(attribute_aggregation)
              : update(index, attribute_aggregation, data)
          })
        })
        showToast("Insight edited.")
      },
    },
  )
}

export const useDeleteAttributeAggregation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id }: { id: AttributeAggregation["id"] }) => api.attributeAggregation.delete(id),
    {
      onSuccess: (_, { id }) => {
        ATTRIBUTE_AGGREGATION_ALL_QUERY_KEYS.forEach(queryKey => {
          queryClient.setQueryData<AttributeAggregations>(queryKey, data => {
            if (!data) return

            return data.filter(item => item.id !== id)
          })
        })
        showToast("Insight deleted.")
      },
    },
  )
}

export const useReorderAttributeAggregations = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      id,
      toIndex,
    }: {
      id: AttributeAggregation["id"]
      fromIndex: AttributeAggregationReorderPayload["order_index"]
      toIndex: AttributeAggregationReorderPayload["order_index"]
    }) => api.attributeAggregation.reorder(id, { order_index: toIndex }),
    {
      onMutate({ fromIndex, toIndex }) {
        ATTRIBUTE_AGGREGATION_ALL_QUERY_KEYS.forEach(queryKey => {
          queryClient.setQueryData<AttributeAggregations>(queryKey, data => {
            if (!data) return

            // order_index starts from 1
            return move(fromIndex - 1, toIndex - 1, data).map((attributeAggregation, index) => ({
              ...attributeAggregation,
              order_index: index + 1,
            }))
          })
        })
      },
      onError(_, { fromIndex, toIndex }) {
        queryClient.setQueryData<AttributeAggregations>(
          ATTRIBUTE_AGGREGATION_ALL_INCLUDING_INVALID,
          data => {
            if (!data) return

            // order_index starts from 1
            return move(toIndex - 1, fromIndex - 1, data).map((attributeAggregation, index) => ({
              ...attributeAggregation,
              order_index: index + 1,
            }))
          },
        )
      },
    },
  )
}

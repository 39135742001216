import React from "react"
import styles from "./Schedule.module.scss"
import { SelectOption } from "types/util"
import Button from "components/UI/elements/Button/Button"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import IconButton from "components/UI/elements/IconButton/IconButton"
import TextInput from "components/UI/elements/TextInput/TextInput"
import SelectField from "components/UI/elements/SelectField"
import Tippy from "@tippyjs/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { integer, isNumber, max, min, required } from "helpers/validators.helper"
import { format } from "date-fns"
import { Controller, FieldErrorsImpl, useFormContext } from "react-hook-form"
import { Day, FixedSchedule, Interval, IntervalSchedule, ScheduleField } from "./formValues"
import { EntryNodeSegmentFormValues } from "../formValues"

type ScheduleProps = {
  isEditable: boolean
  index: number
  removeSelf?: () => void
}

export default function Schedule({ isEditable, index, removeSelf }: ScheduleProps) {
  const {
    watch,
    register,
    control,
    formState: { errors },
  } = useFormContext<EntryNodeSegmentFormValues>()
  const value = watch(`schedules.${index}`)
  const { serverTime, localTime } = getTimeDesc(value)

  const timeTooltip = (
    <div className={styles.timeTooltip}>
      <Tippy
        content={
          <>
            <p>
              <span>Server time (UTC):</span> <span className="value">{serverTime}</span>
            </p>
            <p>
              <span>Local time:</span> <span className="value">{localTime}</span>
            </p>
          </>
        }
      >
        <FontAwesomeIcon icon={["fas", "clock"]} />
      </Tippy>
    </div>
  )

  return (
    <div className={styles.container}>
      <div className={styles.days}>
        <div className={styles.label}>Days</div>
        <Controller
          control={control}
          name={`schedules.${index}.days`}
          rules={{
            validate: {
              anySelected(value) {
                return Object.values(value).some(Boolean) ? undefined : "Select at least one day"
              },
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <div className={styles.dayButtons}>
                {dayOptions.map(({ value: dayValue, label }) => (
                  <Button
                    color={value.days[dayValue] ? "primary" : "grey"}
                    variant={value.days[dayValue] ? "solid" : "outlined"}
                    key={dayValue}
                    onClick={() =>
                      field.onChange({
                        ...field.value,
                        [dayValue]: !field.value[dayValue],
                      })
                    }
                    disabled={!isEditable}
                    className={styles.dayButton}
                  >
                    {label}
                  </Button>
                ))}
              </div>
              {error && <div className={styles.error}>{error.message}</div>}
            </>
          )}
        />
      </div>
      <div className={styles.time}>
        <Controller
          control={control}
          name={`schedules.${index}`}
          render={({ field }) => (
            <ToggleSwitch
              leftValue="fixed"
              rightValue="interval"
              checked={field.value.type}
              leftLabel={"Fixed time"}
              rightLabel={"Interval"}
              disabled={!isEditable}
              handleToggle={() =>
                field.onChange(
                  field.value.type === "fixed"
                    ? {
                        days: value.days,
                        type: "interval",
                        interval: "",
                        from: NaN,
                        to: NaN,
                      }
                    : {
                        days: value.days,
                        type: "fixed",
                        hours: NaN,
                        minutes: NaN,
                      },
                )
              }
              width="160px"
              size="small"
              className={styles.toggle}
            />
          )}
        />

        {value.type === "fixed" && (
          <div className={styles.fixedTime}>
            <TextInput
              {...register(`schedules.${index}.hour`, {
                valueAsNumber: true,
                validate: {
                  required,
                  min: min(0),
                  max: max(23),
                  integer,
                },
              })}
              type="number"
              label="Hour"
              disabled={!isEditable}
              className={styles.numberInput}
              min={0}
              max={23}
              error={(errors.schedules?.[index] as FieldErrorsImpl<FixedSchedule>)?.hour?.message}
            />
            <TextInput
              {...register(`schedules.${index}.minute`, {
                valueAsNumber: true,
                validate: {
                  required,
                  min: min(0),
                  max: max(59),
                  integer,
                },
              })}
              type="number"
              label="Minute"
              disabled={!isEditable}
              className={styles.numberInput}
              min={0}
              max={59}
              error={(errors.schedules?.[index] as FieldErrorsImpl<FixedSchedule>)?.minute?.message}
            />
            {timeTooltip}
          </div>
        )}

        {value.type === "interval" && (
          <div className={styles.interval}>
            <Controller
              control={control}
              name={`schedules.${index}.every`}
              rules={{ validate: { required } }}
              render={({ field, fieldState: { error } }) => (
                <SelectField
                  input={field}
                  label="Every"
                  disabled={!isEditable}
                  isSimpleValue
                  options={intervalOptions}
                  className={styles.intervalSelect}
                  error={error?.message}
                />
              )}
            />
            <TextInput
              {...register(`schedules.${index}.from`, {
                valueAsNumber: true,
                validate: {
                  min: min(0),
                  max: max(23),
                  integer,
                },
              })}
              type="number"
              label="From hour"
              disabled={!isEditable}
              className={styles.numberInput}
              min={0}
              max={23}
              error={
                (errors.schedules?.[index] as FieldErrorsImpl<IntervalSchedule>)?.from?.message
              }
            />
            <TextInput
              {...register(`schedules.${index}.to`, {
                valueAsNumber: true,
                validate: {
                  min: min(0),
                  max: max(59),
                  integer,
                },
              })}
              type="number"
              label="To hour"
              disabled={!isEditable}
              className={styles.numberInput}
              min={0}
              max={23}
              error={(errors.schedules?.[index] as FieldErrorsImpl<IntervalSchedule>)?.to?.message}
            />
            {timeTooltip}
          </div>
        )}
      </div>

      {isEditable && removeSelf && (
        <IconButton
          color="red"
          variant="outlined"
          onClick={removeSelf}
          icon={["fas", "trash-alt"]}
          size="xs"
          className={styles.removeButton}
        />
      )}
    </div>
  )
}
function getTimeDesc(value: ScheduleField) {
  let serverTime = "not set"
  let localTime = "not set"

  if (value.type === "fixed") {
    if (value.hour && value.minute && isNumber(value.hour) && isNumber(value.minute)) {
      const time = new Date()
      time.setUTCHours(value.hour)
      time.setUTCMinutes(value.minute)
      const utcTime = getUtcDateTime(time)

      serverTime = format(utcTime, "H:mm")
      localTime = format(time, "H:mm")
    }
  } else {
    if (value.from && value.to && isNumber(value.from) && isNumber(value.to)) {
      const fromTime = new Date()
      fromTime.setUTCHours(value.from)
      fromTime.setUTCMinutes(0)
      const utcFromTime = getUtcDateTime(fromTime)

      const toTime = new Date()
      toTime.setUTCHours(value.to)
      toTime.setUTCMinutes(0)
      const utcToTime = getUtcDateTime(toTime)

      serverTime = `From ${format(utcFromTime, "H")} to ${format(utcToTime, "H")}`
      localTime = `From ${format(fromTime, "H")} to ${format(toTime, "H")}`
    }
  }
  return { serverTime, localTime }
}

const getUtcDateTime = (dateTime: Date) =>
  new Date(
    dateTime.getUTCFullYear(),
    dateTime.getUTCMonth(),
    dateTime.getUTCDate(),
    dateTime.getUTCHours(),
    dateTime.getUTCMinutes(),
    dateTime.getUTCSeconds(),
  )

const dayOptions: SelectOption<Day>[] = [
  { label: "Mon", value: "1" },
  { label: "Tue", value: "2" },
  { label: "Wed", value: "3" },
  { label: "Thu", value: "4" },
  { label: "Fri", value: "5" },
  { label: "Sat", value: "6" },
  { label: "Sun", value: "7" },
]

const intervalOptions: SelectOption<Interval>[] = [
  { value: { unit: "min", value: 1 }, label: "1 min" },
  { value: { unit: "min", value: 2 }, label: "2 min" },
  { value: { unit: "min", value: 3 }, label: "3 min" },
  { value: { unit: "min", value: 4 }, label: "4 min" },
  { value: { unit: "min", value: 5 }, label: "5 min" },
  { value: { unit: "min", value: 6 }, label: "6 min" },
  { value: { unit: "min", value: 10 }, label: "10 min" },
  { value: { unit: "min", value: 12 }, label: "12 min" },
  { value: { unit: "min", value: 15 }, label: "15 min" },
  { value: { unit: "min", value: 20 }, label: "20 min" },
  { value: { unit: "min", value: 30 }, label: "30 min" },
  { value: { unit: "hr", value: 1 }, label: "1 hr" },
  { value: { unit: "hr", value: 2 }, label: "2 hr" },
  { value: { unit: "hr", value: 3 }, label: "3 hr" },
  { value: { unit: "hr", value: 4 }, label: "4 hr" },
  { value: { unit: "hr", value: 6 }, label: "6 hr" },
  { value: { unit: "hr", value: 8 }, label: "8 hr" },
  { value: { unit: "hr", value: 12 }, label: "12 hr" },
]

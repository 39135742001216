import { useInfiniteQuery } from "@tanstack/react-query"
import { api } from "api"
import { CustomerDestinations } from "./customerDestinationTypes"

export const useFetchCustomerDestinations = (id: string) => {
  const { data, ...rest } = useInfiniteQuery<CustomerDestinations>(
    ["customer", id, "destinations"],
    ({ pageParam }) => api.customerDestination.list({ id, limit: 50, offset: pageParam ?? 0 }),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.customer_destinations.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.customer_destinations) : [] }
}

import React, { useState } from "react"
import styles from "./AddNodeButton.module.scss"
import { Journey, JourneyNode } from "resources/journey/journeyTypes"
import IconButton from "components/UI/elements/IconButton/IconButton"
import useClickOutHandler from "hooks/useClickOutHandler"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Paper from "components/UI/elements/Paper"
import { useCreateJourneyNode } from "resources/journey/journeyQueries"
import ModalWrapper from "components/UI/elements/ModalWrapper/ModalWrapper"
import WaitDelayNodeForm from "../../nodes/WaitDelay/WaitDelayNodeForm"
import ActivateEmailNodeForm from "../../nodes/ActivateEmail/ActivateEmailNodeForm"
import SplitNodeForm from "../../nodes/Split/SplitNodeForm"
import WaitSlotNodeForm from "../../nodes/WaitSlot/WaitSlotNodeForm"
import ActivatePushNodeForm from "../../nodes/ActivatePush/ActivatePushNodeForm"
import ActivateWebhookNodeForm from "../../nodes/ActivateWebhook/ActivateWebhookNodeForm"
import ConditionNodeForm from "../../nodes/Condition/ConditionNodeForm"

type AddNodeButtonProps = {
  journeyId: Journey["id"]
  parentNodeId: JourneyNode["id"]
  index: number
}

export default function AddNodeButton({ journeyId, parentNodeId, index }: AddNodeButtonProps) {
  const { toggle, ref, close, triggerRef, isOpen } = useClickOutHandler()
  const [selectedType, setSelectedType] = useState<JourneyNode["node_type"] | null>(null)

  const createMutation = useCreateJourneyNode()
  function onSubmit(settings: JourneyNode["settings"]) {
    createMutation.mutate(
      {
        journeyId,
        data: {
          node_type: selectedType!,
          settings,
          previous_journey_node_id: parentNodeId,
          previous_journey_node_path: index,
        },
      },
      {
        onSuccess() {
          setSelectedType(null)
        },
      },
    )
  }

  return (
    <div className={styles.container}>
      <IconButton
        color="grey"
        variant="outlined"
        fwdRef={triggerRef}
        onClick={toggle}
        customIcon={
          <FontAwesomeIcon
            icon={["far", "plus"]}
            transform={{ rotate: isOpen ? 45 : 0 }}
            className={styles.icon}
          />
        }
        size="xs"
      />

      {isOpen && (
        <Paper noPadding className={styles.dropdown} ref={ref}>
          <div className={styles.itemGroup}>
            <button
              className={styles.item}
              onClick={() => {
                setSelectedType("flow_wait_delay")
                close()
              }}
            >
              <FontAwesomeIcon icon={["far", "clock"]} size="sm" /> Wait
            </button>
            <button
              className={styles.item}
              onClick={() => {
                setSelectedType("flow_wait_slot")
                close()
              }}
            >
              <FontAwesomeIcon icon={["far", "calendar-clock"]} size="sm" /> Wait for time slot
            </button>
          </div>
          <div className={styles.separator} />
          <div className={styles.itemGroup}>
            <button
              className={styles.item}
              onClick={() => {
                setSelectedType("flow_condition")
                close()
              }}
            >
              <FontAwesomeIcon icon={["far", "code-merge"]} size="sm" /> Conditions
            </button>
            <button
              className={styles.item}
              onClick={() => {
                setSelectedType("flow_split")
                close()
              }}
            >
              <FontAwesomeIcon icon={["far", "vials"]} size="sm" /> A/B split
            </button>
          </div>
          <div className={styles.separator} />
          <div className={styles.itemGroup}>
            <button
              className={styles.item}
              onClick={() => {
                setSelectedType("activate_email")
                close()
              }}
            >
              <FontAwesomeIcon icon={["far", "envelope"]} size="sm" /> Email campaign
            </button>
            <button
              className={styles.item}
              onClick={() => {
                setSelectedType("activate_push")
                close()
              }}
            >
              <FontAwesomeIcon icon={["far", "bell"]} size="sm" /> Mobile push
            </button>
            <button
              className={styles.item}
              onClick={() => {
                setSelectedType("activate_webhook")
                close()
              }}
            >
              <FontAwesomeIcon icon={["far", "webhook"]} size="sm" /> Webhook
            </button>
          </div>
        </Paper>
      )}

      <ModalWrapper isOpen={selectedType !== null} onClose={() => setSelectedType(null)}>
        {selectedType === "flow_wait_delay" && (
          <WaitDelayNodeForm
            isEditable
            isSubmitting={createMutation.isLoading}
            onClose={() => setSelectedType(null)}
            onSubmit={onSubmit}
          />
        )}

        {selectedType === "flow_wait_slot" && (
          <WaitSlotNodeForm
            isEditable
            isSubmitting={createMutation.isLoading}
            onClose={() => setSelectedType(null)}
            onSubmit={onSubmit}
          />
        )}

        {selectedType === "activate_email" && (
          <ActivateEmailNodeForm
            isEditable
            isSubmitting={createMutation.isLoading}
            onClose={() => setSelectedType(null)}
            onSubmit={onSubmit}
          />
        )}

        {selectedType === "activate_push" && (
          <ActivatePushNodeForm
            isEditable
            isSubmitting={createMutation.isLoading}
            onClose={() => setSelectedType(null)}
            onSubmit={onSubmit}
          />
        )}

        {selectedType === "activate_webhook" && (
          <ActivateWebhookNodeForm
            isEditable
            isSubmitting={createMutation.isLoading}
            onClose={() => setSelectedType(null)}
            onSubmit={onSubmit}
          />
        )}

        {selectedType === "flow_split" && (
          <SplitNodeForm
            isEditable
            isSubmitting={createMutation.isLoading}
            onClose={() => setSelectedType(null)}
            onSubmit={onSubmit}
          />
        )}

        {selectedType === "flow_condition" && (
          <ConditionNodeForm
            isEditable
            isSubmitting={createMutation.isLoading}
            onClose={() => setSelectedType(null)}
            onSubmit={onSubmit}
          />
        )}
      </ModalWrapper>
    </div>
  )
}

import React from "react"
import SettingsForm from "./SettingsForm"
import "./Settings.scss"
import { refetchAttributes } from "resources/attribute/attributeQueries"
import { refetchEvents } from "resources/event/eventQueries"
import { useHasAccess } from "resources/user/currentUserQueries"
import { refetchDataSources } from "resources/dataSource/dataSourceQueries"
import {
  useFetchGlobalSettings,
  useModifyGlobalSetting,
} from "resources/globalSettings/globalSettingsQueries"
import { showToast } from "app/toast"
import { pick } from "ramda"
import {
  GlobalSetting,
  GlobalSettingsFormValues,
} from "resources/globalSettings/globalSettingsTypes"

export default function Settings() {
  const hasAccess = useHasAccess()
  const { data: globalSettings, isSuccess: areGlobalSettingsFulfilled } = useFetchGlobalSettings()
  const modifyMutation = useModifyGlobalSetting()
  const modifyGlobalSetting = ({ id, value }: Pick<GlobalSetting, "id" | "value">) =>
    modifyMutation.mutateAsync({ id, data: { value } })

  async function onSubmit({
    mi_api,
    me_api,
    additional_search_result_attribute_id,
    contact_info_tag_id,
    channel_engagement_tag_id,
    cdp_cache_expiration_period,
    maintenance_notifications_emails,
  }: GlobalSettingsFormValues) {
    try {
      await Promise.all([
        modifyGlobalSetting({ id: mi_api.id, value: mi_api?.value?.url ? mi_api.value : null }),
        modifyGlobalSetting({ id: me_api.id, value: me_api?.value?.url ? me_api.value : null }),
        modifyGlobalSetting(additional_search_result_attribute_id),
        modifyGlobalSetting(contact_info_tag_id),
        modifyGlobalSetting(channel_engagement_tag_id),
        modifyGlobalSetting(cdp_cache_expiration_period),
        modifyGlobalSetting({
          ...maintenance_notifications_emails,
          value: maintenance_notifications_emails.value.map(({ email }) => email),
        }),
      ])

      renewCache()
      // Toast here instead of in the mutation hook so that it doesn't show up a dozen times
      showToast("Global setting modified.")
    } catch {
      // noop
    }
  }

  function renewCache() {
    if (
      hasAccess.segments.insights ||
      hasAccess.data.dashboard ||
      hasAccess.data.insights ||
      hasAccess.data.events ||
      hasAccess.data.sourcesAndDestinations ||
      hasAccess.setup.implementation ||
      hasAccess.customers.detail
    ) {
      refetchDataSources()
    }
    refetchAttributes()
    refetchEvents()
  }

  if (!areGlobalSettingsFulfilled) {
    return null
  }

  return (
    <SettingsForm
      onSubmit={onSubmit}
      initialValues={{
        ...pick(
          [
            "mi_api",
            "me_api",
            "additional_search_result_attribute_id",
            "contact_info_tag_id",
            "channel_engagement_tag_id",
            "cdp_cache_expiration_period",
          ],
          globalSettings!,
        ),
        maintenance_notifications_emails: {
          ...globalSettings!.maintenance_notifications_emails,
          value: globalSettings!.maintenance_notifications_emails.value.map(v => ({ email: v })),
        },
      }}
      handleCacheRenew={renewCache}
    />
  )
}

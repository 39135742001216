import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import React, { useEffect } from "react"
import { Switch } from "react-router-dom"
import { useCustomerSearchStore } from "resources/customer/search/customerSearchQueries"
import { useHasAccess } from "resources/user/currentUserQueries"
import { getRoutePath } from "routes"
import ProfileDetail from "./ProfileDetail/ProfileDetail"
import ProfilesList, { useFiltersStore } from "./ProfilesList/ProfilesList"

export default function Profiles() {
  const hasAccess = useHasAccess()
  const { reset: resetFilter } = useFiltersStore() as { reset: () => void }
  const { reset: resetSearchQuery } = useCustomerSearchStore() as { reset: () => void }
  useEffect(() => {
    return () => {
      resetFilter()
      resetSearchQuery()
    }
  }, [resetFilter, resetSearchQuery])

  return (
    <Switch>
      <AuthorizedRoute
        hasAccess={hasAccess.customers.search}
        path={getRoutePath("profiles")}
        exact
        component={ProfilesList}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.customers.detail}
        path={getRoutePath("profiles.detail")}
        component={ProfileDetail}
      />
    </Switch>
  )
}

import { isObject } from "utilities/isObject"

export const REPEAT_OPTIONS = [
  { value: "1 m", label: "1min", langText: "minute" },
  { value: "2 m", label: "2min", langText: "2 minutes" },
  { value: "3 m", label: "3min", langText: "3 minutes" },
  { value: "4 m", label: "4min", langText: "4 minutes" },
  { value: "5 m", label: "5min", langText: "5 minutes" },
  { value: "6 m", label: "6min", langText: "6 minutes" },
  { value: "10 m", label: "10min", langText: "10 minutes" },
  { value: "12 m", label: "12min", langText: "12 minutes" },
  { value: "15 m", label: "15min", langText: "15 minutes" },
  { value: "20 m", label: "20min", langText: "20 minutes" },
  { value: "30 m", label: "30min", langText: "30 minutes" },
  { value: "1 h", label: "1hr", langText: "hour" },
  { value: "2 h", label: "2hr", langText: "2 hours" },
  { value: "3 h", label: "3hr", langText: "3 hours" },
  { value: "4 h", label: "4hr", langText: "4 hours" },
  { value: "6 h", label: "6hr", langText: "6 hours" },
  { value: "8 h", label: "8hr", langText: "8 hours" },
  { value: "12 h", label: "12hr", langText: "12 hours" },
]

export const SCHEDULE_TYPE = {
  REPEAT: "repeat",
  ONCE: "once",
}

export const DAY_MAPPINGS = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun",
}

export const getScheduleText = scheduleCron => {
  if (isObject(scheduleCron)) {
    const days = scheduleCron.day_of_week.split(",").map(dayNum => DAY_MAPPINGS[dayNum])
    if (scheduleCron.minute.includes("/") || scheduleCron.hour.includes("/")) {
      // repeat
      let repeatUnit, repeatValue, from, to
      if (scheduleCron.minute.includes("/")) {
        repeatUnit = "m"
        repeatValue = scheduleCron.minute.split("/")[1]
      } else {
        repeatValue = scheduleCron.hour.split("/")[1]
        repeatUnit = "h"
      }
      if (scheduleCron.hour.includes("-")) {
        ;[from, to] = scheduleCron.hour.split("/")[0].split("-")
      }
      const repeatSelectValue = REPEAT_OPTIONS.find(
        opt => opt.value === `${repeatValue} ${repeatUnit}`,
      )
      let repeatText = repeatSelectValue
        ? repeatSelectValue.langText
        : `${repeatValue} ${repeatUnit}`
      if (from && to) {
        repeatText = `${repeatText} from ${from} to ${to} hour UTC`
      }
      return `${
        days.length === 7 ? "every day" : `every ${days.join(",")}`
      } repeat every ${repeatText}`
    } else {
      // once
      return `${
        days.length === 7 ? "every day" : `every ${days.join(", ")}`
      } at ${scheduleCron.hour.padStart(2, "0")}:${scheduleCron.minute.padStart(2, "0")} UTC`
    }
  }
  return ""
}

export const schedulesApiToForm = schedules => {
  return schedules.map(schedule => {
    const days = schedule.day_of_week.split(",").map(Number)
    const type =
      schedule.minute.includes("/") || schedule.hour.includes("/")
        ? SCHEDULE_TYPE.REPEAT
        : SCHEDULE_TYPE.ONCE
    if (type === SCHEDULE_TYPE.ONCE) {
      return {
        days,
        type,
        hour: schedule.hour,
        minute: Math.round(Number(schedule.minute)) < 10 ? `0${schedule.minute}` : schedule.minute,
      }
    } else {
      const minuteRepeat = schedule.minute.includes("/") ? schedule.minute.split("/")[1] : null
      const hourRepeat = schedule.hour.includes("/") ? schedule.hour.split("/")[1] : null
      const fromToBase = schedule.hour.split("/")[0]
      let from = "",
        to = ""
      if (fromToBase !== "*") {
        ;[from, to] = fromToBase.split("-")
        from = `${Math.round(Number(from))}`
        to = `${Math.round(Number(to))}`
      }
      const every = minuteRepeat
        ? REPEAT_OPTIONS.find(v => v.value === `${minuteRepeat} m`)
        : REPEAT_OPTIONS.find(v => v.value === `${hourRepeat} h`)
      return {
        days,
        type,
        every: every,
        from,
        to,
      }
    }
  })
}

export const schedulesFormToApi = values => {
  return values.schedules.map(schedule => {
    const days = schedule.days.join(",")
    if (schedule.type === SCHEDULE_TYPE.ONCE) {
      // _toInteger converts 0x or 00 values to single integer value
      return {
        minute: schedule.minute ? `${Math.round(Number(schedule.minute))}` : "0",
        hour: schedule.hour ? `${Math.round(Number(schedule.hour))}` : "0",
        day: "*",
        month: "*",
        day_of_week: days,
      }
    } else {
      const every = schedule.every.value.split(" ")
      let hrRange = "*"
      if (schedule.from && schedule.to) {
        hrRange = `${Math.round(Number(schedule.from))}-${Math.round(Number(schedule.to))}`
      }
      return {
        minute: every[1] === "m" ? `*/${every[0]}` : "0",
        hour: every[1] === "m" ? `${hrRange}` : `${hrRange}/${every[0]}`,
        day: "*",
        month: "*",
        day_of_week: days,
      }
    }
  })
}

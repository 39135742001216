import PaperHeader from "components/UI/elements/PaperHeader"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { url, required, imageFile, isEmptyField, maxLength } from "helpers/validators.helper"
import { SubmitHandler, useForm, Controller, FieldError } from "react-hook-form"
import { EmbeddedWebBanner } from "resources/webBanner/embeddedWebBanner/embeddedWBTypes"
import styles from "./EmbeddedWebBannersForm.module.scss"
import Button from "components/UI/elements/Button/Button"
import Paper from "components/UI/elements/Paper"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import React, { lazy, Suspense, useCallback, useEffect, useState } from "react"
import { Prompt, useHistory, useParams } from "react-router-dom"
import { getRoutePath } from "routes"
import classnames from "classnames"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import ConditionTreeOverview from "components/ConditionBuilder/components/ConditionTreeOverview/ConditionTreeOverview"
import { WBCondition, WBConditionError } from "resources/webBanner/webBannerConditionTypes"
import ConditionBuilder from "components/ConditionBuilder/ConditionBuilder"
import Condition, {
  getNewWebBannerCondition,
} from "../../../../../components/UI/components/WebBannerCondition/WebBannerCondition"
import { makeConditionTreeValidator } from "components/ConditionBuilder/validation"
import { validateWebBannerCondition } from "../../../../../components/UI/components/WebBannerCondition/validation"
import classNames from "classnames"
import FileField from "components/UI/elements/FileField/FileField"
import { showToast } from "app/toast"
import { TOAST } from "sharedConstants"
import { isEmpty } from "ramda"
import SelectField from "components/UI/elements/SelectField"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import BannerPreview from "./BannerPreview/BannerPreview"
import {
  FREQUENCY_CAP_TYPE_OPTIONS,
  PRIORITY_OPTIONS,
} from "pages/Channels/EmbeddedWebBanners/utilities/dropdownOptions"
import {
  EmbeddedWebBannersFormValues,
  EmbeddedWebBannersFormData,
} from "pages/Channels/EmbeddedWebBanners/embeddedWBComponentTypes"
import { WebBannerType } from "pages/Channels/PopupWebBanners/popupWBComponentTypes"
import { useCopyEmbeddedWB } from "resources/webBanner/embeddedWebBanner/embeddedWBQueries"
import { useHasAccess } from "resources/user/currentUserQueries"
import { ConditionPath } from "types/conditionTree"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import WebBannerDisableDefaultTrackingModal from "components/UI/components/WebBannerDisableDefaultTrackingModal/WebBannerDisableDefaultTrackingModal"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import { embeddedWBToFormValues } from "../../utilities/embeddedWBToFormValues"
const AceEditor = lazy(() => import("components/AceEditor/AceEditor"))

type EmbeddedWebBannersFormProps = {
  initialValues?: EmbeddedWebBannersFormValues
  submitting?: boolean
  deleting?: boolean
  onSubmit: (data: EmbeddedWebBannersFormData) => Promise<{ web_banner: EmbeddedWebBanner }>
  onDelete?: () => void
}

type WebBannerFormType = "create" | "edit"

const validateConditionTree = makeConditionTreeValidator(validateWebBannerCondition)

export default function EmbeddedWebBannersForm({
  initialValues,
  onSubmit,
  onDelete,
  submitting = false,
  deleting = false,
}: EmbeddedWebBannersFormProps) {
  const type: WebBannerFormType = initialValues ? "edit" : "create"
  const history = useHistory()
  const { id } = useParams<{ id: EmbeddedWebBanner["id"] }>()

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    control,
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm<EmbeddedWebBannersFormValues>({
    defaultValues: initialValues ?? {
      disabled: true,
      priority: PRIORITY_OPTIONS.find(item => item.value === 0)!,
      condition: null,
      tracking_disabled: false,
    },
  })
  const [hoveredPath, setHoveredPath] = useState<ConditionPath>()
  const [bannerType, setBannerType] = useState<WebBannerType>(
    !initialValues ||
      (initialValues.content_type &&
        ["local_image", "remote_image"].includes(initialValues.content_type))
      ? "image"
      : "html",
  )
  const [base64BannerImage, setBase64BannerImage] = useState<string | null>(null)
  const [modalOpen, setModalOpen] = useState(false)

  const isEditable = useHasAccess().webBanners.edit

  // @ts-ignore
  const imageUrl = watch("image_url")
  const html = watch("html")
  const width = watch("width")
  const previewWidth = watch("preview_width")
  const frequencyCapWindowType = watch("frequency_cap_window_type")
  const windowCap = watch("frequency_cap_window_max_count")

  const validateFormValues = useCallback(
    (formValues: EmbeddedWebBannersFormValues): string | undefined => {
      const { image_url, image } = formValues

      if (bannerType === "image" && (!image || !image[0]) && !image_url) {
        return "One of 'Image upload' or 'Image URL' must be filled."
      }
    },
    [bannerType],
  )

  const formValuesToFormData = useCallback(
    ({
      name,
      condition,
      frequency_cap_window_type,
      frequency_cap_window_size,
      frequency_cap_window_max_count,
      frequency_cap_user_max_count,
      priority,
      element_id,
      html,
      image_url,
      image,
      destination_url,
      width,
      preview_width,
      disabled,
      tracking_disabled,
    }: EmbeddedWebBannersFormValues): EmbeddedWebBannersFormData => {
      const sendImagePayloadData =
        bannerType === "image" &&
        (dirtyFields["image"] || dirtyFields["image_url"] || type === "create")

      const perUserCap =
        !isEmptyField(frequency_cap_user_max_count) &&
        Math.round(Number(frequency_cap_user_max_count))
      const perWindowCap =
        !isEmptyField(frequency_cap_window_max_count) &&
        Math.round(Number(frequency_cap_window_max_count))

      const window =
        perWindowCap === false
          ? undefined
          : frequency_cap_window_type.value === "session"
          ? {
              type: frequency_cap_window_type.value,
              max_display_count: Math.round(Number(frequency_cap_window_max_count)),
            }
          : {
              type: frequency_cap_window_type.value,
              max_display_count: Math.round(Number(frequency_cap_window_max_count)),
              size: Math.round(Number(frequency_cap_window_size)),
            }

      const frequencyCap: EmbeddedWebBanner["settings"]["frequency_cap"] =
        perUserCap !== false || window
          ? {
              per_user: {
                max_display_count: perUserCap === false ? undefined : perUserCap,
                window,
              },
            }
          : undefined

      return {
        name,
        condition,
        tracking_disabled,
        frequency_cap: frequencyCap,
        priority: priority.value,
        image_local:
          sendImagePayloadData && image && image[0] ? base64BannerImage!.split(",")[1] : null,
        image_name: sendImagePayloadData && image && image[0] ? image[0].name : null,
        image_remote: sendImagePayloadData && image_url ? image_url : null,
        html: bannerType === "html" ? html : null,
        destination_url:
          bannerType === "image" &&
          (dirtyFields["destination_url"] || sendImagePayloadData || type === "create")
            ? destination_url
            : null,
        width: width ? (typeof width === "string" ? parseInt(width) : width) : null,
        preview_width: preview_width
          ? typeof preview_width === "string"
            ? parseInt(preview_width)
            : preview_width
          : null,
        disabled: Boolean(disabled),
        element_id: element_id.trim(),
      }
    },
    [bannerType, base64BannerImage, dirtyFields, type],
  )

  const submitForm: SubmitHandler<EmbeddedWebBannersFormValues> = async formValues => {
    if (submitting) {
      return
    }

    const error = validateFormValues(formValues)

    if (error) {
      showToast(error, TOAST.TYPE.ERROR)
      return
    }

    try {
      const webBanner = (await onSubmit(formValuesToFormData(formValues))).web_banner
      setBase64BannerImage(null)
      reset(embeddedWBToFormValues(webBanner))
    } catch (err: any) {
      // TODO: typescript here, validate everything??
      // @ts-ignore
      const errors = err.response?.data?.errors
      if (errors) {
        if (errors.name) {
          setError("name", { type: "manual", message: errors.name[0] })
        }
        if (errors.settings) {
          errors.settings.forEach((error: string[], key: string) => {
            setError(key as keyof EmbeddedWebBannersFormValues, {
              type: "manual",
              message: error[0],
            })
          })
        }
      }
    }
  }

  const toggleBannerType = useCallback(() => {
    setBannerType(bannerType === "image" ? "html" : "image")
  }, [bannerType])

  const copyMutation = useCopyEmbeddedWB()

  const copyBanner = () =>
    copyMutation.mutate(
      { id },
      {
        onSuccess: ({ web_banner }) => {
          history.push(getRoutePath("channels.native-banners.detail", { id: web_banner.id }))
        },
      },
    )

  const encodeFileToBase64 = useCallback(
    (file: Blob) =>
      new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      }),
    [],
  )

  const clearImageSelection = useCallback(
    (field: "image") => () => {
      clearErrors(field)
      if (base64BannerImage && field === "image") {
        setValue(field, undefined)
        setBase64BannerImage(null)
      }
    },
    [base64BannerImage, setValue, clearErrors],
  )

  const periodValidation = useCallback(
    value => {
      if (!windowCap || !frequencyCapWindowType || frequencyCapWindowType?.value === "session") {
        return undefined
      } else {
        return required(value)
      }
    },
    [frequencyCapWindowType, windowCap],
  )

  useEffect(() => {
    // using onChange on width and setValue was kinda bugged in a way that I was
    // not able make preview width field disabled or not
    if (width || width === 0) {
      setValue("preview_width", undefined)
    }
  }, [width, setValue])

  const windowTypeValidation = (value?: string) => (windowCap ? required(value) : undefined)
  return (
    <>
      <Prompt
        when={!isEmpty(dirtyFields) && !submitting && !deleting}
        message="Changes you made will not be saved."
      />
      <form onSubmit={handleSubmit(submitForm)}>
        <Paper hasHeader className={classnames(styles.content, styles.triggerSettings)}>
          <PaperHeader size="small" className={styles.header}>
            <TextInput
              label="Name"
              labelPosition="left"
              error={errors.name?.message}
              placeholder="Name"
              data-testid="banner-name-field"
              className={styles.name}
              disabled={!isEditable}
              {...register("name", { validate: { required, maxLength: maxLength(100) } })}
            />
            <div className={styles.buttons} data-testid="form-action-buttons">
              <Controller
                name="disabled"
                control={control}
                render={({ field }) => {
                  return (
                    <div className={styles.disabledButtonToggleWrapper}>
                      <label>Active</label>
                      <ToggleButton
                        value={!field.value}
                        handleToggle={() => {
                          field.onChange(!field.value)
                        }}
                      />
                    </div>
                  )
                }}
              />
              {type === "edit" && (
                <>
                  <Button
                    color="red"
                    disabled={!isEditable}
                    icon="trash-alt"
                    variant="outlined"
                    onClick={onDelete}
                    className={styles.buttonMargin}
                  >
                    Delete
                  </Button>
                  <Button
                    color="grey"
                    icon="clone"
                    loading={copyMutation.isLoading}
                    variant="outlined"
                    onClick={copyBanner}
                    className={styles.buttonMargin}
                    disabled={!isEditable}
                  >
                    Copy
                  </Button>
                </>
              )}
              <Button type="submit" disabled={!isEditable} loading={submitting}>
                {type === "create" ? "Create" : "Save"}
              </Button>
            </div>
          </PaperHeader>
          <div className={styles.conditionBuilderWrapper}>
            <Controller
              name="condition"
              control={control}
              rules={{
                validate: tree => {
                  const result = validateConditionTree(tree)
                  // Validator returns a stringified tree of errors because react-hook-forms only
                  // works with string errors
                  return result === null ? true : JSON.stringify(result)
                },
              }}
              render={({ field }) => {
                const stringifiedError = (errors.condition as FieldError | undefined)?.message
                const conditionError = stringifiedError
                  ? (JSON.parse(stringifiedError) as WBConditionError)
                  : null

                return (
                  <>
                    <PaperHeader
                      size="small"
                      titleText="Conditions"
                      className={classNames(styles.conditionsHeader, {
                        [styles.empty]: !field.value,
                      })}
                    >
                      <ConditionTreeOverview<WBCondition>
                        conditionTree={field.value}
                        onChange={field.onChange}
                        highlightedPath={hoveredPath}
                        onHover={setHoveredPath}
                      />
                    </PaperHeader>
                    <ConditionBuilder<WBCondition, WBConditionError>
                      conditionTree={field.value}
                      onChange={field.onChange}
                      isEditable={isEditable}
                      error={conditionError}
                      conditionComponent={Condition}
                      getNewCondition={getNewWebBannerCondition}
                      highlightedPath={hoveredPath}
                      onHover={setHoveredPath}
                    />
                  </>
                )
              }}
            />
          </div>
          <div className={styles.freqPosPrioRow}>
            <div className={classnames(styles.frequencyCapPerUserBox, styles.greyBox)}>
              <h4 className={styles.greyBoxTitle}>Frequency cap per user</h4>
              <div className={styles.frequencyCapFieldsRow}>
                <TextInput
                  type="number"
                  label="How many times"
                  error={errors.frequency_cap_window_max_count?.message}
                  placeholder="1 to 50"
                  {...register("frequency_cap_window_max_count")}
                  min="1"
                  max="50"
                  step="1"
                  disabled={!isEditable}
                  className={styles.frequencyCapCountField}
                  data-testid="frequency-cap-window-max-count"
                />
                <span>during</span>
                <TextInput
                  type="number"
                  label="Period"
                  error={errors.frequency_cap_window_size?.message}
                  placeholder={
                    frequencyCapWindowType?.value === "hours"
                      ? "1 to 168"
                      : frequencyCapWindowType?.value === "days"
                      ? "1 to 7"
                      : ""
                  }
                  {...register("frequency_cap_window_size", {
                    validate: periodValidation,
                  })}
                  min="1"
                  max={frequencyCapWindowType?.value === "hours" ? 168 : 7}
                  step="1"
                  disabled={
                    !isEditable ||
                    !frequencyCapWindowType ||
                    frequencyCapWindowType?.value === "session"
                  }
                  className={styles.frequencyCapWindowSizeField}
                />
                <Controller
                  name="frequency_cap_window_type"
                  control={control}
                  rules={{ validate: option => windowTypeValidation(option?.value) }}
                  render={({ field }) => (
                    <SelectField
                      input={field}
                      inputId={field.name}
                      label=""
                      meta={{
                        touched: true,
                        // @ts-ignore
                        error: errors.frequency_cap_window_type?.message,
                      }}
                      options={FREQUENCY_CAP_TYPE_OPTIONS}
                      className={styles.frequencyCapWindowTypeSelect}
                      disabled={!isEditable}
                    />
                  )}
                />
                <span>and</span>
                <div
                  className={classnames(
                    styles.fieldWithTooltip,
                    styles.frequencyCapUserCountFieldWrapper,
                  )}
                >
                  <TextInput
                    type="number"
                    label="Total"
                    error={errors.frequency_cap_user_max_count?.message}
                    placeholder="1 to 50"
                    {...register("frequency_cap_user_max_count")}
                    min="1"
                    max="50"
                    step="1"
                    disabled={!isEditable}
                  />
                  <InfoTooltip placement="top" className={styles.tooltip} interactive>
                    <div className={styles.tooltipTotalCap}>
                      <p>
                        Maximum number of impressions for 1 user over the lifetime of the banner.
                      </p>
                      <p>
                        <strong>Warning: </strong>the number of impressions is stored in the
                        browser's local storage. If the local storage is cleared, the user might see
                        the banner more times. More info{" "}
                        <a
                          href="https://www.cookiestatus.com/"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          here
                        </a>
                        .
                      </p>
                    </div>
                  </InfoTooltip>
                </div>
              </div>
            </div>

            <div className={classnames(styles.greyBox, styles.priorityBox)}>
              <h4 className={styles.greyBoxTitle}>Priority</h4>
              <div className={classnames(styles.fieldWithTooltip, styles.priorityFieldWrapper)}>
                <Controller
                  name="priority"
                  control={control}
                  rules={{ validate: required }}
                  render={({ field }) => (
                    <SelectField
                      input={field}
                      label="Priority *"
                      inputId={field.name}
                      meta={{
                        touched: true,
                        // @ts-ignore
                        error: errors.priority?.message,
                      }}
                      options={PRIORITY_OPTIONS}
                      className={styles.prioritySelect}
                      disabled={!isEditable}
                    />
                  )}
                />
                <InfoTooltip placement="top" className={styles.tooltip}>
                  Select priority from 0-10. Web banner with higher priority will appear first. 10
                  is the highest.
                </InfoTooltip>
              </div>
            </div>
          </div>
        </Paper>

        <Paper
          className={classnames(
            styles.content,
            styles.nextBox,
            styles.bannerBox,
            styles.bannerContent,
          )}
        >
          <div className={styles.leftPart}>
            <div className={styles.paperInnerHeader}>
              <h3>Banner</h3>

              <div className={styles.bannerToggleBox}>
                <ToggleSwitch
                  name="banner_type"
                  leftValue="image"
                  rightValue="html"
                  checked={bannerType}
                  handleToggle={toggleBannerType}
                  width="120px"
                  disabled={!isEditable}
                />
              </div>
            </div>

            <div
              className={classnames(styles.bannerContent, {
                [styles.htmlContent]: bannerType === "html",
                [styles.imageContent]: bannerType === "image",
              })}
            >
              {bannerType === "image" && (
                <>
                  <TextInput
                    label="Destination URL"
                    error={errors.destination_url?.message}
                    placeholder="https://www.example.com/promotion"
                    disabled={!isEditable}
                    {...register("destination_url", { validate: url })}
                  />
                  <div className={styles.imageUploadRow}>
                    <div className={styles.imageUploadRowContent}>
                      <Controller
                        control={control}
                        name="image"
                        rules={{ validate: { imageFile } }}
                        render={({ field: { value, ref, onBlur, onChange } }) => (
                          <FileField
                            ref={ref}
                            label="Image upload"
                            error={errors.image?.message}
                            disabled={!isEditable}
                            accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                            value={value?.[0]?.name}
                            onBlur={onBlur}
                            onChange={async event => {
                              clearErrors("image")
                              const { files } = event.target
                              if (files && files[0]) {
                                try {
                                  const encodedFile = await encodeFileToBase64(files[0])
                                  if (typeof encodedFile === "string") {
                                    setBase64BannerImage(encodedFile)
                                    onChange(files)
                                    setValue("image_url", "", { shouldDirty: true })
                                  }
                                } catch (err) {}
                              }
                            }}
                            onClearClick={clearImageSelection("image")}
                            className={styles.fileInput}
                          />
                        )}
                      />
                      <p className={styles.imageUploadDescription}>
                        Image type: APNG, AVIF, GIF, JPG, PNG, SVG, WebP; Max size: 500 kB
                      </p>
                    </div>
                    <TextInput
                      label="Image URL"
                      error={errors.image_url?.message}
                      placeholder="https://www.example.com/image.jpg"
                      className={styles.imageUrlField}
                      disabled={!isEditable || base64BannerImage !== null}
                      data-testid="image-url-field"
                      {...register("image_url", { validate: url })}
                    />
                  </div>
                </>
              )}
              {bannerType === "html" && (
                <div>
                  <Controller
                    name="html"
                    control={control}
                    rules={{ validate: required }}
                    render={({ field }) => (
                      <div className={`ace-editor-wrapper ${errors.html?.message ? "error" : ""}`}>
                        <p className="label-like">Banner HTML *</p>
                        <Suspense fallback={<LoadingIndicator />}>
                          <AceEditor
                            mode="html"
                            theme="tomorrow"
                            onChange={field.onChange}
                            name="html"
                            width="808px"
                            height="398px"
                            editorProps={{ $blockScrolling: true }}
                            wrapEnabled={true}
                            className="ace-editor"
                            value={field.value ?? undefined}
                            setOptions={{
                              tabSize: 2,
                              showPrintMargin: false,
                            }}
                            errorMessage={errors.html?.message}
                          />
                        </Suspense>
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.rightPart}>
            <div className={styles.tabs}>General settings</div>
            <p className={styles.labelLike}>General settings:</p>
            <div className={styles.tabContent}>
              <div className={classnames(styles.fieldWithTooltip)}>
                {/* hack to prevent showing 1Password (id: search) */}
                <TextInput
                  id="search"
                  label="Container id or selector *"
                  error={errors.element_id?.message}
                  {...register("element_id", {
                    validate: { required },
                  })}
                  disabled={!isEditable}
                  data-testid="element-id-field"
                  maxLength={100}
                  autoComplete="off"
                />
                <InfoTooltip placement="top" className={styles.tooltip} interactive>
                  The ID of the DOM element (or multiple elements) on the page in which the banner
                  will be placed. To learn more about how to set the ID on the element, see the{" "}
                  <a
                    href="https://docs.meiro.io/books/meiro-business-explorer/page/pop-up-and-native-banners-instructions-for-developers"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    developer documentation
                  </a>
                  .
                </InfoTooltip>
              </div>

              <div className={classnames(styles.fieldWithTooltip)}>
                <TextInput
                  label="Banner width in pixels"
                  {...register("width")}
                  min="1"
                  step="1"
                  disabled={!isEditable}
                />
                <InfoTooltip placement="top" className={styles.tooltip}>
                  If not set, banner width will be 100% of the container specified by container ID.
                </InfoTooltip>
              </div>
              <div className={styles.disableDefaultTrackingField}>
                <Controller
                  control={control}
                  name="tracking_disabled"
                  render={({ field: { value, onBlur, onChange } }) => (
                    <Checkbox
                      checked={value}
                      label={<b>Disable default tracking</b>}
                      onBlur={onBlur}
                      onChange={() => {
                        if (value) onChange(false)
                        else setModalOpen(true)
                      }}
                    />
                  )}
                />
                <InfoTooltip placement="bottom" className={styles.tooltip}>
                  This will disable default tracking that Meiro has.
                </InfoTooltip>
              </div>
            </div>
          </div>
        </Paper>

        <div className={styles.previewWidthContainer}>
          <div className={classnames(styles.previewWidthFieldWrapper)}>
            <TextInput
              label="Preview width in pixels"
              labelPosition="left"
              {...register("preview_width")}
              min="1"
              step="1"
              disabled={!isEditable || Boolean(width) || width === 0}
            />
          </div>
          <BannerPreview
            type={bannerType}
            html={html}
            imageUrl={imageUrl}
            base64Image={base64BannerImage}
            width={width || width === 0 ? width : previewWidth}
          />
        </div>
        <WebBannerDisableDefaultTrackingModal
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          handleConfirm={() => setValue("tracking_disabled", true)}
        />
      </form>
    </>
  )
}

import requestFactory from "api/request"
import {
  CreateStitchingCategoryPayload,
  ModifyStitchingCategoryPayload,
  StitchingCategory,
  StitchingCategoryListResponse,
  StitchingCategoryRule,
  StitchingCategoryRuleListResponse,
} from "./stitchingCategoryTypes"

const stitchingCategory = {
  list(): Promise<StitchingCategoryListResponse> {
    return requestFactory("get", "/stitching_categories")
  },
  retrieve(id: StitchingCategory["id"]): Promise<{ stitching_category: StitchingCategory }> {
    return requestFactory("get", `/stitching_categories/${id}`)
  },
  create(data: CreateStitchingCategoryPayload): Promise<{ stitching_category: StitchingCategory }> {
    return requestFactory("post", "/stitching_categories", data)
  },
  modify(
    id: StitchingCategory["id"],
    data: ModifyStitchingCategoryPayload,
  ): Promise<{ stitching_category: StitchingCategory }> {
    return requestFactory("patch", `/stitching_categories/${id}`, data)
  },
  delete(id: StitchingCategory["id"]): Promise<void> {
    return requestFactory("delete", `/stitching_categories/${id}`)
  },
  rule: {
    modify(
      categoryId: StitchingCategory["id"],
      rules: Array<StitchingCategoryRule>,
    ): Promise<StitchingCategoryRuleListResponse> {
      return requestFactory("patch", `/stitching_categories/${categoryId}/rules`, {
        stitching_rules: rules,
      })
    },
  },
}

export default stitchingCategory

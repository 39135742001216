import React from "react"
import { required } from "helpers/validators.helper"
import { Controller, useForm } from "react-hook-form"
import TagTextField from "components/UI/elements/TagTextField/TagTextField"

export default function LabelForm({ onSubmit, isCreate, initialValues }) {
  const { handleSubmit, control } = useForm({ defaultValues: initialValues })

  return (
    <form
      id="labelForm"
      className="label-form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="custom-text-field">
        <Controller
          name="name"
          control={control}
          rules={{ validate: required }}
          render={({ field, fieldState: { error } }) => (
            <TagTextField
              input={field}
              meta={{ touched: true, error: error?.message }}
              placeholder="Label name"
              label={isCreate ? "Create label" : "Modify label"}
              autoFocus
              maxLength={30}
            />
          )}
        />
      </div>
    </form>
  )
}

import { Id } from "react-toastify"
import { TOAST } from "sharedConstants"
import create from "zustand"

export type ToastOpts = {
  route?: string
  routes?: Array<{ title: string; path: string }>
  link?: { title: string; url: string }
}

export type ToastState = {
  message: string
  type: typeof TOAST.TYPE[keyof typeof TOAST.TYPE]
  hidePrevious?: boolean
  customId?: Id
  timestamp: number
} & ToastOpts

export const useToast = create<{ toast: ToastState | null }>(_ => ({
  toast: null,
}))

export const showToast = (
  message: string,
  type: typeof TOAST.TYPE[keyof typeof TOAST.TYPE] = TOAST.TYPE.SUCCESS,
  route = "",
  hidePrevious = false,
  link?: { title: string; url: string },
  routes?: Array<{ title: string; path: string }>,
  customId?: Id,
) =>
  useToast.setState({
    toast: { message, type, route, hidePrevious, link, routes, customId, timestamp: Date.now() },
  })

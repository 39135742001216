import React from "react"
import { IEmailTemplate } from "easy-email-editor"

import styles from "./SystemEmailTemplateItem.module.scss"

export type SystemEmailTemplate = {
  id: string
  name: string
  imgSrc: string
  contentJson: IEmailTemplate
  description?: string
}

export default function SystemEmailTemplateItem({
  imgSrc,
  name,
  description,
}: SystemEmailTemplate) {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.image} style={{ backgroundImage: `url(${imgSrc})` }} />
        <div className={styles.textWrapper}>
          <h3 className={styles.title}>{name}</h3>
          {description && <p className={styles.description}>{description}</p>}
        </div>
      </div>
      <p data-testid="create-button" className={styles.createCampaign}>
        + Create campaign
      </p>
    </div>
  )
}

import classNames from "classnames"
import { differenceInDays } from "date-fns"
import React from "react"
import { sanitizeDatetime } from "utilities/date"
import styles from "./NewBadge.module.scss"

export default function NewBadge({
  created,
  size = "md",
}: {
  created?: Date | string | null
  size?: "sm" | "md"
}) {
  return created && differenceInDays(new Date(), sanitizeDatetime(created)) > 7 ? null : (
    <span className={classNames(styles.badge, styles[size])}>New</span>
  )
}

import { pick } from "ramda"
import { FunnelChart, LineChart } from "resources/funnelChart/funnelChartTypes"
import { FunnelChartFormValues } from "./components/FunnelChartForm/FunnelChartForm"
import { LineChartFormValues } from "./components/LineChartForm/LineChartForm"
import { DeepPartial } from "react-hook-form"

export function getFunnelChartInitialValues(
  chart?: FunnelChart,
): DeepPartial<FunnelChartFormValues> {
  if (chart) {
    return {
      ...pick(["name", "description", "frontend_settings", "function", "type"], chart),
      data: {
        steps: chart.definition.chart_attributes.map(
          ({ attribute_id, data_dimension, timestamp_dimension, data_dimension_title }) => ({
            id: attribute_id,
            data_dimension_id: data_dimension.dimension_id,
            timestamp_dimension_id: timestamp_dimension.dimension_id,
            data_dimension_title,
          }),
        ),
      },
    }
  }

  return {
    name: "",
    description: "",
    frontend_settings: { color: undefined },
    function: undefined,
    type: "FUNNEL",
    data: {
      steps: [
        {
          id: undefined,
          data_dimension_id: undefined,
          timestamp_dimension_id: undefined,
          data_dimension_title: "",
        },
        {
          id: undefined,
          data_dimension_id: undefined,
          timestamp_dimension_id: undefined,
          data_dimension_title: "",
        },
      ],
    },
  }
}

export function getLineChartInitialValues(chart?: LineChart): DeepPartial<LineChartFormValues> {
  if (chart) {
    return {
      ...pick(["name", "description", "frontend_settings", "function", "type"], chart),
      data: {
        id: chart.definition.attribute_id,
        data_dimension_id: chart.definition.data_dimension.dimension_id,
        timestamp_dimension_id: chart.definition.timestamp_dimension.dimension_id,
      },
    }
  }

  return {
    name: "",
    description: "",
    frontend_settings: { color: undefined },
    function: undefined,
    type: "LINE",
    data: {
      id: undefined,
      data_dimension_id: undefined,
      timestamp_dimension_id: undefined,
    },
  }
}

import { useQuery } from "@tanstack/react-query"
import requestFactory from "api/request"
// import { prop, sum } from "ramda"
import { Source } from "resources/dataSource/dataSourceTypes"

type CustomersPerSourceResponse = {
  customers_per_source: {
    source_id: Source["id"]
    count: number
  }[]
}

const customersPerSource = (): Promise<CustomersPerSourceResponse> =>
  requestFactory("get", "/customers_per_source")

export function useFetchCustomersPerSource() {
  return useQuery(["customersPerSource"], customersPerSource, {
    staleTime: 60 * 1000,
    select({ customers_per_source }) {
      return customers_per_source.length ? customers_per_source : null
    },
  })
}

import { SystemEmailTemplate } from "../SystemEmailTemplateItem/SystemEmailTemplateItem"
import culinaryNewsletter from "./CulinaryNewsletter/culinaryNewsletter"
import culinaryExplorer from "./CulinaryExplorer/culinaryExplorer"
import seasonalSavingsSpotlight from "./SeasonalSavingsSpotlight/seasonalSavingsSpotlight"
import weekendCulinaryDelights from "./WeekendCulinaryDelights/weekendCulinaryDelights"
import realEstateInsider from "./RealEstateInsider/realEstateInsider"
import backToSchoolEcommerce from "./BackToSchoolEcommerce/backToSchoolEcommerce"

const templates: Array<SystemEmailTemplate> = [
  culinaryNewsletter,
  culinaryExplorer,
  seasonalSavingsSpotlight,
  weekendCulinaryDelights,
  realEstateInsider,
  backToSchoolEcommerce,
]

export default templates

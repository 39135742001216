import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import JourneysList, { useFiltersStore } from "./JourneysList/JourneysList"
import JourneyDetail from "./JourneyDetail/JourneyDetail"
import Page from "components/UI/Page/Page"
import marketingImg from "./marketingContent.png"
import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"
import { featureFlags } from "featureFlags"
import { useEffect } from "react"

export default function JourneyCanvas() {
  const { reset } = useFiltersStore()
  useEffect(() => reset, [reset])

  return featureFlags.JOURNEYS ? (
    <Switch>
      <Route path={getRoutePath("journeys")} exact component={JourneysList} />
      <Route path={getRoutePath("journeys.detail")} component={JourneyDetail} />
    </Switch>
  ) : (
    <Switch>
      <Route path={getRoutePath("journeys")} exact component={JourneyCanvasMarketingContent} />
    </Switch>
  )
}

function JourneyCanvasMarketingContent() {
  return (
    <Page title="Journey canvas">
      <MarketingContent img={{ alt: "Journey canvas", src: marketingImg }}>
        <h1>On the horizon: Journey canvas!</h1>
        <p>
          Get ready for our latest innovation designed for seamless cross-channel orchestration.
        </p>
        <p>Beta launching soon.</p>
      </MarketingContent>{" "}
    </Page>
  )
}

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { api } from "api"
import { SEGMENT } from "../segment/segmentQueries"
import { Segment } from "../segment/segmentTypes"
import { SegmentNote, SegmentNotePayload } from "./segmentNoteTypes"
import { showToast } from "app/toast"
import { prop, sort, update } from "ramda"
import { descend } from "utilities/comparators"

const SEGMENT_NOTES = "notes" as const

export const useFetchAllSegmentNotes = (segmentId: Segment["id"]) =>
  useQuery([SEGMENT, segmentId, SEGMENT_NOTES], () => api.segment.note.listAll(segmentId))

export const useCreateSegmentNote = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ segmentId, data }: { segmentId: Segment["id"]; data: SegmentNotePayload }) =>
      api.segment.note.create(segmentId, data),
    {
      onSuccess: ({ segment_note }, { segmentId }) => {
        queryClient.setQueryData<Array<SegmentNote>>([SEGMENT, segmentId, SEGMENT_NOTES], data => {
          if (!data) return

          return sort(descend(prop("modified")), data.concat(segment_note))
        })
        showToast("Note created.")
      },
    },
  )
}

export const useModifySegmentNote = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      segmentId,
      noteId,
      data,
    }: {
      segmentId: Segment["id"]
      noteId: SegmentNote["id"]
      data: SegmentNotePayload
    }) => api.segment.note.modify(segmentId, noteId, data),
    {
      onSuccess: ({ segment_note }, { segmentId }) => {
        queryClient.setQueryData<Array<SegmentNote>>([SEGMENT, segmentId, SEGMENT_NOTES], data => {
          if (!data) return

          const index = data.findIndex(({ id }) => id === segment_note.id)
          const segmentNotes =
            index === -1 ? data.concat(segment_note) : update(index, segment_note, data)

          return sort(descend(prop("modified")), segmentNotes)
        })
        showToast("Note edited.")
      },
    },
  )
}

export const useDeleteSegmentNote = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ segmentId, noteId }: { segmentId: Segment["id"]; noteId: SegmentNote["id"] }) =>
      api.segment.note.delete(segmentId, noteId),
    {
      onSuccess: (_, { segmentId, noteId }) => {
        queryClient.setQueryData<Array<SegmentNote>>([SEGMENT, segmentId, SEGMENT_NOTES], data => {
          if (!data) return

          return data.filter(note => note.id !== noteId)
        })
        showToast("Note deleted.")
      },
    },
  )
}

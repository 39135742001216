import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import styles from "./InfoMessage.module.scss"

type InfoMessageProps = {
  children: React.ReactNode
}

export default function InfoMessage({ children }: InfoMessageProps) {
  return (
    <div className={styles.root}>
      <FontAwesomeIcon className={styles.icon} icon={["far", "info-circle"]} />
      <p className={styles.text}>{children}</p>
    </div>
  )
}

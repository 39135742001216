import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import styles from "./CompoundAttributeValuesTable.module.scss"
import { dec, inc, isNil } from "ramda"
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator"
import IconButton from "../IconButton/IconButton"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import ToggleButton from "../ToggleButton/ToggleButton"
import { useHasAccess } from "resources/user/currentUserQueries"

export const BLOCKED_SUB_ATTRIBUTE = "blocked"

type SubAttribute = {
  data_type: string
  id: string
  name: string
}

type CompoundAttributeValuesTableProps = {
  subAttributes: SubAttribute[]
  values: string[][]
  // How to type this? Either all of the following should be present, or none of them
  page?: number
  lastPage?: number
  setPage?: Dispatch<SetStateAction<number>>
  isLoading?: boolean
  onValueChange?: (id: SubAttribute["id"], index: number, value: unknown) => void
}

export default function CompoundAttributeValuesTable({
  subAttributes,
  values,
  page,
  lastPage,
  setPage,
  isLoading,
  onValueChange,
}: CompoundAttributeValuesTableProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLDivElement>(null)
  const [offset, setOffset] = useState(0)
  const ERROR_MARGIN = 2
  const containerWidth = containerRef.current?.clientWidth ?? 0
  const tableWidth = tableRef.current?.clientWidth ?? 0
  const showLeftOverlay = offset > 0
  const showRightOverlay = tableWidth - containerWidth - offset > ERROR_MARGIN

  useEffect(() => {
    const ref = containerRef.current
    const listener = () => {
      if (ref) {
        setOffset(ref.scrollLeft)
      }
    }
    if (ref) {
      setOffset(ref.scrollLeft)
      ref.addEventListener("scroll", listener)
    }
    return () => ref?.removeEventListener("scroll", listener)
  }, [])

  useEffect(() => {
    // If loading, scroll to start AFTER new results come in
    if (!isLoading) {
      containerRef.current?.scrollTo(0, 0)
    }
  }, [isLoading, page])

  const hasAccess = useHasAccess()

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={classNames(styles.rightOverlay, { [styles.hidden]: !showRightOverlay })} />
        <div className={styles.scrollContainer} ref={containerRef}>
          <div
            className={styles.table}
            style={{ gridTemplateRows: `repeat(${subAttributes.length}, auto)` }}
            ref={tableRef}
          >
            <div className={styles.tableHeader}>
              <div className={styles.tableHeaderInner}>
                {subAttributes.map(({ id, name }) => (
                  // Wrapper is needed because when scrolling, the borders of the table body show up
                  // behind the rounded corners of the headerCells, so we need background
                  // under the corners
                  <div key={id} className={styles.headerCellWrapper}>
                    <div
                      className={classNames(styles.leftOverlay, {
                        [styles.hidden]: !showLeftOverlay,
                      })}
                    />
                    <div className={styles.headerCell}>
                      <div className={styles.inner}>{name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.tableBody}>
              {values.map((valueList, index) => (
                <div key={index} className={styles.column}>
                  {valueList.map((value, idx) => {
                    const subAttribute = subAttributes[idx]

                    if (!subAttribute) {
                      return null
                    }

                    const id = subAttributes[idx].id

                    return (
                      <div key={idx} className={styles.bodyCell}>
                        <div className={styles.inner}>
                          <Value
                            value={value}
                            dataType={subAttributes[idx].data_type}
                            id={id}
                            disabled={
                              id === BLOCKED_SUB_ATTRIBUTE && !hasAccess.emails.editBlocklist
                            }
                            onChange={
                              onValueChange ? val => onValueChange(id, index, val) : undefined
                            }
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {page !== undefined && (
        <div className={styles.pagination}>
          <IconButton
            variant="transparent"
            icon={["fas", "chevron-left"]}
            disabled={page === 1}
            onClick={_ => setPage!(dec)}
            color="grey"
            size="xs"
          />
          {isLoading ? (
            <LoadingIndicator fixedWidth size="xs" />
          ) : (
            <div className={styles.pageNumber}>{page}</div>
          )}
          <IconButton
            variant="transparent"
            icon={["fas", "chevron-right"]}
            disabled={page === lastPage}
            onClick={_ => setPage!(inc)}
            color="grey"
            size="xs"
          />
        </div>
      )}
    </div>
  )
}

type ValueProps = {
  value: unknown
  dataType?: string
  disabled?: boolean
  id?: string
  onChange?: (value: unknown) => void
}

function Value({ value, dataType, disabled, id, onChange }: ValueProps) {
  const imgPrefix = "img::"
  const linkPrefix = "link::"
  const isImg = (value as string)?.startsWith?.(imgPrefix)
  const isLink = (value as string)?.startsWith?.(linkPrefix)
  let content = getUserFriendlyValueFormat(value, dataType ?? "string")

  if (isNil(value) || value === "") {
    content = <span data-testid="empty-value">&nbsp;</span>
  }
  if (isImg) {
    content = <img src={(value as string).replace(new RegExp(`^${imgPrefix}`), "")} alt="" />
  }
  if (isLink) {
    const link = (value as string).replace(new RegExp(`^${linkPrefix}`), "")
    content = (
      <a href={link} target="_blank" rel="noreferrer">
        {link}
      </a>
    )
  }
  if (dataType === "bool" && id === BLOCKED_SUB_ATTRIBUTE && value && onChange) {
    content = (
      <div className={styles.toggleWrapper}>
        <span className={styles.value}>{getUserFriendlyValueFormat(value, "bool")}</span>
        <ToggleButton
          disabled={disabled}
          value={value === "1" || value === 1}
          size="xs"
          handleToggle={() => onChange(value)}
        />
      </div>
    )
  }
  return content
}

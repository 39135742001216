import React, { useEffect, useRef, useState } from "react"
import styles from "./BannerPreview.module.scss"
import { WebBannerType } from "../../../embeddedWBComponentTypes"
import classnames from "classnames"
import { useMutationObserver } from "hooks/useMutationObserver"
import Paper from "components/UI/elements/Paper"

const MAX_WIDTH = 1120

type WebBannersPreviewProps = {
  type: WebBannerType
  html: string | null
  imageUrl?: string
  base64Image: string | null
  width?: number | null
}

export default function BannerPreview({
  type,
  html,
  imageUrl,
  base64Image,
  width,
}: WebBannersPreviewProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const bannerRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  const updateIframeHeight = () => {
    if (iframeRef.current) {
      const height =
        iframeRef.current.contentWindow?.document.documentElement?.getBoundingClientRect().height
      setHeight(height ?? 0)
    }
  }

  useEffect(() => {
    updateIframeHeight()
  }, [width])

  useMutationObserver({
    target: iframeRef.current?.contentWindow?.document.body,
    options: {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    },
    callback: updateIframeHeight,
  })
  const isImage = type === "image" && (imageUrl || base64Image)
  const isHtml = type === "html" && html

  let previewWidth = width
  if (previewWidth && previewWidth > MAX_WIDTH) {
    previewWidth = MAX_WIDTH
  }

  let preview = <div className={styles.noContent}>Fill the form fields to see the preview</div>

  if (isImage) {
    preview = (
      <img
        style={{
          width: previewWidth || previewWidth === 0 ? `${previewWidth}px` : "auto",
          maxWidth: `${MAX_WIDTH}px`,
        }}
        src={imageUrl || base64Image!}
        alt=""
      />
    )
  }

  if (isHtml) {
    preview = (
      <iframe
        ref={iframeRef}
        width={previewWidth || previewWidth === 0 ? previewWidth : "100%"}
        height={height}
        title="preview"
        className={styles.bannerInnerHtml}
        srcDoc={html}
        onLoad={updateIframeHeight}
      />
    )
  }

  return (
    <Paper className={styles.paper}>
      <div className={styles.paperInnerHeader}>
        <h3>Preview</h3>
      </div>
      <div className={classnames(styles.preview, styles["middle"])}>
        <div
          className={classnames(styles.bannerCard, {
            [styles.imageBanner]: type === "image",
          })}
          ref={bannerRef}
        >
          <>{preview}</>
        </div>
      </div>
    </Paper>
  )
}

import { DATE_FMT } from "sharedConstants"
import { api } from "api"
import { DBtimestampToDate } from "utilities/date"
import { format, intlFormatDistance } from "date-fns"

export const getUserFriendlyValueFormat = (value, dataType, timeAgo = false) => {
  if (dataType === "float" && !isNaN(value)) {
    return Number(Math.round(value * 100) / 100).toLocaleString()
  } else if (dataType === "int" && !isNaN(value)) {
    return Number(Math.round(value)).toLocaleString()
  } else if (dataType === "datetime" && typeof value === "string") {
    const date = DBtimestampToDate(value)
    if (isNaN(date)) {
      return value
    }
    const formattedDate = format(date, DATE_FMT.DATETIME_PRECISE)
    return timeAgo ? `${intlFormatDistance(date, new Date())} (${formattedDate})` : formattedDate
  } else if (dataType === "date" && typeof value === "string") {
    const date = DBtimestampToDate(value)
    if (isNaN(date)) {
      return value
    }
    const formattedDate = format(date, DATE_FMT.DATE)
    return timeAgo ? `${intlFormatDistance(date, new Date())} (${formattedDate})` : formattedDate
  } else if (dataType === "bool") {
    if (value === "1" || value === 1) {
      return "Yes"
    } else if (value === "0" || value === 0) {
      return "No"
    }
  } else if (value === "* Protected Information *") {
    return "*\u00A0Protected\u00A0information\u00A0*"
  }
  return value
}

export const getBackendValueFromUserFriendly = (value, dataType) => {
  if (dataType === "bool") {
    if (value.toLowerCase() === "yes") {
      return "1"
    } else if (value.toLowerCase() === "no") {
      return "0"
    }
  }
  return value
}

export const fetchCustomerAttributeValues = async attributeId => {
  try {
    const data = await api.attributeValueCounts(attributeId)

    return (data?.attribute_values_counts ?? []).map((item, index) => {
      const additionalProps = {}
      if (index === 0 && data.attribute_values_counts.length > 2) {
        additionalProps.highest = true
      } else if (
        index === data.attribute_values_counts.length - 1 &&
        data.attribute_values_counts.length > 2
      ) {
        additionalProps.lowest = true
      }
      return {
        id: `${item.attribute_id}.${item.value}`,
        label: item.value,
        value: item.value,
        ...additionalProps,
      }
    })
  } catch (err) {
    if (err.response?.data.error_type === "pii_protected_attribute") {
      return [
        {
          label: "* Protected Information *",
          value: "",
          disabled: true,
          id: "protected_information",
        },
      ]
    }
    return []
  }
}

import { useQuery } from "@tanstack/react-query"
import requestFactory from "api/request"
import { Source } from "resources/dataSource/dataSourceTypes"
import { DateString } from "types/util"

type CustomerEventsPerDateResponse = {
  customer_events_per_date: {
    source_id: Source["id"]
    events_per_date: {
      date: DateString
      count: number
    }[]
  }[]
  resolution: "hour" | "day" | "week" | "month" | "year"
}

const customerEventsPerDate = (): Promise<CustomerEventsPerDateResponse> =>
  requestFactory("get", "/customer_events_per_date")

export function useFetchCustomerEventsPerDate() {
  return useQuery(["customerEventsPerDate"], customerEventsPerDate, {
    staleTime: 60 * 1000,
    select({ customer_events_per_date, resolution }) {
      return {
        data: customer_events_per_date.length ? customer_events_per_date : null,
        resolution,
        startDate: customer_events_per_date[0]?.events_per_date[0].date,
      }
    },
  })
}

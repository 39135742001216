import { Suspense, lazy, useEffect, useState } from "react"
import classNames from "classnames"
import { equals } from "ramda"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { Prompt } from "react-router-dom"

import ChannelPaper from "../components/ChannelPaper/ChannelPaper"
import CustomDataSchemaModal from "../components/CustomDataSchemaModal/CustomDataSchemaModal"
import DynamicField from "components/DynamicField/DynamicField"
import InfoMessage from "components/UI/components/InfoMessage/InfoMessage"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { isJSONString } from "helpers/validators.helper"
import { useCustomDataDefaultValuesMemo } from "hooks/useCustomDataDefaultValuesMemo"
import { PushNotificationsChannel } from "resources/channel/channelTypes"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import { useModifyPushNotificationsChannelCustomDataSchema } from "resources/channel/channelQueries"
import { jsonSchemaValidator } from "resources/channel/channelUtils"
import { useFetchAllMobilePushNotifications } from "resources/mobilePushNotification/mobilePushNotificationQueries"
import { MobilePushNotification } from "resources/mobilePushNotification/mobilePushNotificationTypes"
import { JsonSchema } from "types/util"

import styles from "./CustomDataSchema.module.scss"

const AceEditor = lazy(() => import("components/AceEditor/AceEditor"))

const getSchemaKeys = (schema: JsonSchema) => {
  const schemaKeys: Array<string> = []

  const traverseSchema = (schema: JsonSchema, path?: string) =>
    schema.type === "object"
      ? schema.properties &&
        Object.entries(schema.properties).forEach(([key, field]) => {
          traverseSchema(field, key)
        })
      : path && schemaKeys.push(path)

  traverseSchema(schema)
  return schemaKeys
}

const containsReservedKeyword = (schema: JsonSchema) => {
  const reservedWords = ["action", "url", "from", "notification", "message_id", "message_type"]
  const result = getSchemaKeys(schema).find(key =>
    reservedWords.some(keyWord => equals(keyWord, key.toLowerCase())),
  )

  return result ? `Cannot contain word: ${result}` : undefined
}

const startsWithReservedKeyword = (schema: JsonSchema) => {
  const reservedWords = ["google", "gcm"]
  const result = reservedWords.find(keyword =>
    getSchemaKeys(schema).some(key => key.toLowerCase().includes(keyword)),
  )

  return result ? `Key cannot start with: ${result}` : undefined
}

type CustomDataSchemaProps = Pick<PushNotificationsChannel, "custom_data_schema">

export default function CustomDataSchema({ custom_data_schema }: CustomDataSchemaProps) {
  const {
    control,
    getValues,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitted, isSubmitting },
  } = useForm({
    defaultValues: {
      custom_data_schema: custom_data_schema ? JSON.stringify(custom_data_schema, null, 2) : "",
    },
  })

  const strCustomDataSchema = watch("custom_data_schema")
  const isSchemaJson = isJSONString(strCustomDataSchema)
  const previewDefaultValues = useCustomDataDefaultValuesMemo(strCustomDataSchema)

  const previewFormMethods = useForm({ defaultValues: previewDefaultValues })
  const { reset } = previewFormMethods

  useEffect(() => {
    reset(previewDefaultValues)
  }, [previewDefaultValues, reset])

  const [affectedPushNotifications, setAffectedPushNotifications] = useState<
    Array<MobilePushNotification>
  >([])
  const { data: attributesMapById = {} } = useFetchAttributesMap({ includeHidden: true })
  const { data: { push_notifications } = { push_notifications: [] } } =
    useFetchAllMobilePushNotifications()

  const closeModal = () => setAffectedPushNotifications([])

  const { mutate, isLoading } = useModifyPushNotificationsChannelCustomDataSchema()
  const onSubmit = ({ custom_data_schema }: { custom_data_schema: string }) =>
    mutate(
      {
        data: {
          custom_data_schema: custom_data_schema ? JSON.parse(custom_data_schema) : null,
        },
      },
      {
        onSuccess: () => {
          if (affectedPushNotifications.length > 0) closeModal()
        },
      },
    )

  const hasUnsavedChanges = isDirty && !isSubmitting && !isSubmitted

  const formId = "customDataSchemaForm"

  return (
    <>
      <Prompt when={hasUnsavedChanges} message="Changes you made will not be saved." />
      <ChannelPaper
        hasUnsavedChanges={hasUnsavedChanges}
        isSubmitting={isLoading}
        isWizard={false}
        title="Custom data schema"
        formId={formId}
      >
        <div className={styles.content}>
          <InfoMessage>
            Make sure that you do not use any reserved words in your custom key-value pairs.
            Reserved words include <b>action</b>, <b>url</b>, <b>from</b>, <b>notification</b>,{" "}
            <b>message_id</b>, <b>message_type</b> or any word starting with <b>google</b> or{" "}
            <b>gcm</b>.
          </InfoMessage>
          <form
            id={formId}
            onSubmit={handleSubmit(({ custom_data_schema }) => {
              if (custom_data_schema && push_notifications.length > 0) {
                const parsedCustomDataSchema = JSON.parse(custom_data_schema) as JsonSchema

                const affectedPushNotificationsToBe = push_notifications.filter(
                  ({ custom_data }) =>
                    !jsonSchemaValidator(parsedCustomDataSchema, custom_data, attributesMapById)
                      .valid,
                )

                if (affectedPushNotificationsToBe.length > 0) {
                  setAffectedPushNotifications(affectedPushNotificationsToBe)
                  return
                }
              }

              onSubmit({ custom_data_schema })
            })}
          >
            <Suspense fallback={<LoadingIndicator />}>
              <Controller
                control={control}
                name="custom_data_schema"
                rules={{
                  validate: {
                    json: v => (!v || isJSONString(v) ? undefined : "Not a valid JSON."),
                    containsReservedKeyword: v =>
                      v ? containsReservedKeyword(JSON.parse(v)) : undefined,
                    startsWithReservedKeywords: v =>
                      v ? startsWithReservedKeyword(JSON.parse(v)) : undefined,
                  },
                }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <>
                    <label className={styles.label}>JSON</label>
                    <AceEditor
                      wrapEnabled
                      height="700px"
                      width="100%"
                      mode="json"
                      theme="tomorrow"
                      errorMessage={error?.message}
                      editorProps={{ $blockScrolling: true }}
                      setOptions={{ tabSize: 2, showPrintMargin: false }}
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      className={classNames(styles.aceEditor, { [styles.error]: error })}
                    />
                  </>
                )}
              />
            </Suspense>
          </form>
          <div
            className={classNames(styles.preview, {
              [styles.centered]: !strCustomDataSchema || !isSchemaJson,
            })}
          >
            {!strCustomDataSchema ? (
              <p>Fill the JSON to see the preview</p>
            ) : !isSchemaJson ? (
              <p>JSON is not valid</p>
            ) : (
              <form>
                <h3>Preview</h3>
                <FormProvider {...previewFormMethods}>
                  <DynamicField schema={JSON.parse(strCustomDataSchema)} />
                </FormProvider>
              </form>
            )}
          </div>
        </div>
      </ChannelPaper>

      <CustomDataSchemaModal
        open={affectedPushNotifications.length > 0}
        isLoading={isLoading}
        affectedPushNotifications={affectedPushNotifications.map(({ id, name }) => ({
          id,
          name,
        }))}
        onClose={closeModal}
        onSubmit={() => {
          onSubmit({ custom_data_schema: getValues("custom_data_schema") })
        }}
      />
    </>
  )
}

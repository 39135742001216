import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tippy from "@tippyjs/react"
import classNames from "classnames"
import { format } from "date-fns"
import { isEmpty } from "ramda"
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form"

import Button from "components/UI/elements/Button/Button"
import TextInput from "components/UI/elements/TextInput/TextInput"
import Modal from "components/UI/elements/Modal"
import SelectField from "components/UI/elements/SelectField"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import { isNumber, onlyNumber, required } from "helpers/validators.helper"
import {
  DAY_MAPPINGS,
  REPEAT_OPTIONS,
} from "resources/segment/segment/utilities/segmentSchedulesUtils"

import "./RepetitiveScheduleForm.scss"

type ScheduleType = "repeat" | "once"
type Schedule = {
  days: Array<number>
  type: ScheduleType
  hour?: string
  minute?: string
  every?: {
    label: string
    langText: string
    value: string
  }
  from?: string
  to?: string
}
type Schedules = Array<Schedule>

type FormFields = {
  schedules: Schedules
}

type RepetitiveScheduleFormProps = {
  isDeleting: boolean
  isEditable: boolean
  isSaving: boolean
  open: boolean
  schedules: Schedules
  onClose: () => void
  onSubmit: (values: { schedules: Array<any> }) => void
}

const MAX_ALLOWED_SCHEDULES = 8
const DAY_MAPPINGS_TS = DAY_MAPPINGS as Record<number, string>

const getDayNames = (days: Schedule["days"]) => {
  const daysText: Array<string> = []

  days.forEach(k => {
    daysText.push(DAY_MAPPINGS_TS[k])
  })

  return daysText.join(", ")
}

const getUtcDateTime = (dateTime: Date) =>
  new Date(
    dateTime.getUTCFullYear(),
    dateTime.getUTCMonth(),
    dateTime.getUTCDate(),
    dateTime.getUTCHours(),
    dateTime.getUTCMinutes(),
    dateTime.getUTCSeconds(),
  )

export default function RepetitiveScheduleForm({
  isDeleting,
  isEditable,
  isSaving,
  open,
  schedules: initialSchedules,
  onClose,
  onSubmit,
}: RepetitiveScheduleFormProps) {
  const [selectedScheduleIndex, setSelectedScheduleIndex] = useState(0)

  const {
    control,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    reset,
  } = useForm<FormFields>({ defaultValues: { schedules: initialSchedules } })
  const { fields, append, remove } = useFieldArray({
    control,
    name: "schedules",
  })

  const schedules = useWatch({
    control,
    name: "schedules",
    defaultValue: initialSchedules,
  })
  const schedule: Schedule | undefined = schedules[selectedScheduleIndex]

  const removeField = (index: number) => {
    const fieldsCount = fields.length

    if (fieldsCount > 1) {
      remove(index)
      if (selectedScheduleIndex === fieldsCount - 1)
        setSelectedScheduleIndex(selectedScheduleIndex - 1)
    }
  }

  let naturalLanguageMessage: string | JSX.Element = ""
  if (schedule) {
    naturalLanguageMessage =
      schedule.type === "once"
        ? "Select at least one day for exports at chosen time"
        : "Select at least one day for exports at chosen intervals of time"

    if (Array.isArray(schedule.days) && schedule.days.length > 0) {
      if (schedule.type === "once") {
        const days = getDayNames(schedule.days)
        naturalLanguageMessage = (
          <>
            Schedule is set for every <span>{days}</span> at{" "}
            <span>
              {schedule.hour ? schedule.hour : "0"}:{schedule.minute ? schedule.minute : "00"}
            </span>{" "}
            UTC
          </>
        )
      } else {
        if (!schedule.every) {
          naturalLanguageMessage = "Select repeat interval"
        } else {
          const days = getDayNames(schedule.days)
          const fromTo =
            schedule.from && schedule.to ? (
              <>
                from <span>{schedule.from}</span> to <span>{schedule.to} hour UTC</span>
              </>
            ) : null
          naturalLanguageMessage = (
            <>
              Schedule is set on <span>{days}</span> and repeats every{" "}
              <span>{schedule.every.langText}</span> {fromTo}
            </>
          )
        }
      }
    }
  }

  let serverTime = "not set"
  let localTime = "not set"
  if (schedule) {
    if (schedule.type === "once") {
      if (
        schedule.hour &&
        schedule.minute &&
        isNumber(schedule.hour) &&
        isNumber(schedule.minute)
      ) {
        const time = new Date()
        time.setUTCHours(parseInt(schedule.hour))
        time.setUTCMinutes(parseInt(schedule.minute))
        const utcTime = getUtcDateTime(time)

        serverTime = format(utcTime, "H:mm")
        localTime = format(time, "H:mm")
      }
    } else {
      if (schedule.from && schedule.to && isNumber(schedule.from) && isNumber(schedule.to)) {
        const fromTime = new Date()
        fromTime.setUTCHours(parseInt(schedule.from))
        fromTime.setUTCMinutes(0)
        const utcFromTime = getUtcDateTime(fromTime)

        const toTime = new Date()
        toTime.setUTCHours(parseInt(schedule.to))
        toTime.setUTCMinutes(0)
        const utcToTime = getUtcDateTime(toTime)

        serverTime = `From ${format(utcFromTime, "H")} to ${format(utcToTime, "H")}`
        localTime = `From ${format(fromTime, "H")} to ${format(toTime, "H")}`
      }
    }
  }

  return (
    <Modal open={open} title="Scheduler" className="scheduler-form" handleClose={onClose}>
      <form>
        <div className="tabs-n-buttons">
          <div className="tabs">
            {fields.map((_, index) => {
              let hasError = false
              if (isDirty && errors.schedules && !isEmpty(errors.schedules[index])) hasError = true

              return (
                <Button
                  key={`schedule-${index}`}
                  color={index === selectedScheduleIndex ? "primary" : "grey"}
                  size="md"
                  variant={index === selectedScheduleIndex ? "solid" : "outlined"}
                  onClick={() => setSelectedScheduleIndex(index)}
                  className={classNames({
                    "schedule-button": index === selectedScheduleIndex,
                    "error-schedule": hasError,
                  })}
                >
                  Schedule {index + 1}{" "}
                  {isEditable && (
                    <span
                      className="remove-schedule-button"
                      onClick={event => {
                        event.stopPropagation()
                        removeField(index)
                      }}
                    >
                      <FontAwesomeIcon icon={["far", "times"]} />
                    </span>
                  )}
                </Button>
              )
            })}
            {fields.length < MAX_ALLOWED_SCHEDULES && isEditable && (
              <Button
                key="schedule-add"
                color="grey"
                size="md"
                variant="outlined"
                className="add-schedule-button"
                onClick={_ => {
                  append({
                    days: [1, 2, 3, 4, 5, 6, 7],
                    type: "once",
                  })
                  setSelectedScheduleIndex(fields.length)
                }}
              >
                <FontAwesomeIcon icon={["far", "plus"]} />
              </Button>
            )}
          </div>
          {isEditable && (
            <div className="buttons">
              {isDirty && (
                <Button
                  color="grey"
                  size="md"
                  variant="text"
                  onClick={_ => {
                    setSelectedScheduleIndex(0)
                    reset()
                  }}
                >
                  Undo changes
                </Button>
              )}

              <Button
                color="red"
                loading={isDeleting}
                size="md"
                variant="outlined"
                onClick={() => {
                  onSubmit({ schedules: [] })
                }}
              >
                Delete all
              </Button>
              <Button
                loading={isSaving}
                size="md"
                // cannot use form onSubmit due to the nested form
                onClick={() => {
                  handleSubmit(onSubmit)()
                }}
              >
                Save
              </Button>
            </div>
          )}
        </div>
        {fields.map((field, index) => {
          return (
            <div key={field.id}>
              <div
                className={`form-inputs ${index === selectedScheduleIndex ? "visible" : "hidden"}`}
              >
                <span className="tiny-label">Select day(s) of the week:</span>
                <Controller
                  control={control}
                  defaultValue={field.days}
                  name={`schedules.${index}.days`}
                  rules={{
                    validate: {
                      required: value => (value.length > 0 ? undefined : "Select at least one day"),
                    },
                  }}
                  render={({ field: { name, value, onChange }, fieldState: { error } }) => {
                    return (
                      <div className="days-column-flexbox">
                        <div className="days-row-flexbox">
                          {Array.from({ length: 7 }, (_, i) => i + 1).map(dayOfWeek => {
                            return (
                              <div
                                key={dayOfWeek}
                                className={classNames("day-option", { disabled: !isEditable })}
                              >
                                <input
                                  checked={Array.isArray(value) && value.includes(dayOfWeek)}
                                  type="checkbox"
                                  id={`${name}.${dayOfWeek}`}
                                  className={error ? "error" : ""}
                                  disabled={!isEditable}
                                  onChange={() => {
                                    if (Array.isArray(value) && value.includes(dayOfWeek))
                                      onChange(value.filter(v => v !== dayOfWeek))
                                    else onChange([...value, dayOfWeek])
                                  }}
                                />
                                <label
                                  className={classNames({ disabled: !isEditable })}
                                  htmlFor={`${name}.${dayOfWeek}`}
                                >
                                  {DAY_MAPPINGS_TS[dayOfWeek]}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                        <div className="error">
                          {error && <p className="error-message">{error.message}</p>}
                        </div>
                      </div>
                    )
                  }}
                />
                <div className="type-toggle">
                  <Controller
                    control={control}
                    defaultValue={field.type}
                    name={`schedules.${index}.type`}
                    render={({ field: { name, value, onChange } }) => {
                      return (
                        <ToggleSwitch
                          name={name}
                          leftValue={"repeat"}
                          leftLabel="Interval"
                          rightValue={"once"}
                          rightLabel="Fixed time"
                          width="190px"
                          className="type-toggle-field"
                          disabled={!isEditable}
                          checked={value}
                          handleToggle={() => {
                            onChange(value === "once" ? "repeat" : "once")
                          }}
                        />
                      )
                    }}
                  />
                </div>
                {schedule?.type === "once" && (
                  <div className="once-fields">
                    <span className="field-lbl">Time</span>
                    <Controller
                      control={control}
                      defaultValue={field.hour}
                      name={`schedules.${index}.hour`}
                      rules={{
                        validate: {
                          required: value => {
                            if (getValues().schedules[index].type !== "once") return
                            return required(value)
                          },
                          onlyNumber: value => {
                            if (getValues().schedules[index].type !== "once") return
                            return onlyNumber(value)
                          },
                          min: value => {
                            if (getValues().schedules[index].type !== "once" || !value) return
                            return parseInt(value) >= 0 ? undefined : "Must be >= 0"
                          },
                          max: value => {
                            if (getValues().schedules[index].type !== "once" || !value) return
                            return parseInt(value) <= 23 ? undefined : "Must be <= 23"
                          },
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextInput
                          {...field}
                          disabled={!isEditable}
                          autoComplete="off"
                          maxLength={2}
                          placeholder="0"
                          className="once-hour"
                          error={error?.message}
                        />
                      )}
                    />
                    <span className="double-dot">:</span>
                    <Controller
                      control={control}
                      defaultValue={field.minute}
                      name={`schedules.${index}.minute`}
                      rules={{
                        validate: {
                          required: value => {
                            if (getValues().schedules[index].type !== "once") return
                            return required(value)
                          },
                          onlyNumber: value => {
                            if (getValues().schedules[index].type !== "once") return
                            return onlyNumber(value)
                          },
                          min: value => {
                            if (getValues().schedules[index].type !== "once" || !value) return
                            return parseInt(value) >= 0 ? undefined : "Must be >= 0"
                          },
                          max: value => {
                            if (getValues().schedules[index].type !== "once" || !value) return
                            return parseInt(value) <= 59 ? undefined : "Must be <= 59"
                          },
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextInput
                          {...field}
                          disabled={!isEditable}
                          autoComplete="off"
                          maxLength={2}
                          placeholder="00"
                          className="once-minute"
                          error={error?.message}
                        />
                      )}
                    />
                  </div>
                )}
                {schedule?.type === "repeat" && (
                  <div className="repeat-fields">
                    <span className="tiny-label">Select interval:</span>
                    <span className="tiny-label time-label">Fill time (24hr, UTC, optional):</span>
                    <span className="field-lbl">Every</span>
                    <Controller
                      control={control}
                      defaultValue={field.every}
                      name={`schedules.${index}.every`}
                      rules={{
                        validate: {
                          required: value => {
                            if (getValues().schedules[index].type !== "repeat") return
                            return required(value)
                          },
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <SelectField
                          isSearchable={false}
                          input={field}
                          inputId={`repeat-select-${index}`}
                          maxDropdownHeight="200px"
                          options={REPEAT_OPTIONS}
                          size="small"
                          className="repeat-select"
                          error={error?.message}
                        />
                      )}
                    />

                    <span className="field-lbl from">from</span>
                    <Controller
                      control={control}
                      defaultValue={field.from}
                      name={`schedules.${index}.from`}
                      rules={{
                        validate: {
                          both: from => {
                            if (getValues().schedules[index].type !== "repeat") return

                            const to = getValues().schedules[index].to
                            if (!from && to) return "Fill both fields"
                            else return undefined
                          },
                          onlyNumber: value => {
                            if (getValues().schedules[index].type !== "repeat" || !value) return
                            return onlyNumber(value)
                          },
                          min: value => {
                            if (getValues().schedules[index].type !== "repeat" || !value) return
                            return parseInt(value) >= 0 ? undefined : "Must be >= 0"
                          },
                          max: value => {
                            if (getValues().schedules[index].type !== "repeat" || !value) return
                            return parseInt(value) <= 23 ? undefined : "Must be <= 23"
                          },
                          lowerThanTimeTo: from => {
                            if (getValues().schedules[index].type !== "repeat" || !from) return
                            const to = getValues().schedules[index].to
                            if (!to) return undefined

                            const numFrom = parseInt(from)
                            const numTo = parseInt(to)
                            return numFrom < numTo
                              ? undefined
                              : numFrom === numTo
                              ? "Values can't be equal"
                              : "'from' can't be greater"
                          },
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextInput
                          {...field}
                          disabled={!isEditable}
                          autoComplete="off"
                          maxLength={2}
                          className="repeat-hrs from"
                          error={error?.message}
                        />
                      )}
                    />
                    <span className="field-lbl to">to</span>
                    <Controller
                      control={control}
                      defaultValue={field.to}
                      name={`schedules.${index}.to`}
                      rules={{
                        validate: {
                          both: to => {
                            if (getValues().schedules[index].type !== "repeat") return

                            const from = getValues().schedules[index].from
                            if (from && !to) return "Fill both fields"
                            else return undefined
                          },
                          onlyNumber: value => {
                            if (getValues().schedules[index].type !== "repeat" || !value) return
                            return onlyNumber(value)
                          },
                          min: value => {
                            if (getValues().schedules[index].type !== "repeat" || !value) return
                            return parseInt(value) >= 0 ? undefined : "Must be >= 0"
                          },
                          max: value => {
                            if (getValues().schedules[index].type !== "repeat" || !value) return
                            return parseInt(value) <= 23 ? undefined : "Must be <= 23"
                          },
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextInput
                          {...field}
                          disabled={!isEditable}
                          autoComplete="off"
                          maxLength={2}
                          className="repeat-hrs to"
                          error={error?.message}
                        />
                      )}
                    />
                  </div>
                )}
                <div className="utc-info">
                  <Tippy
                    content={
                      <>
                        <p>
                          <span>Server time (UTC):</span>{" "}
                          <span className="value">{serverTime}</span>
                        </p>
                        <p>
                          <span>Local time:</span> <span className="value">{localTime}</span>
                        </p>
                      </>
                    }
                  >
                    <FontAwesomeIcon icon={["fas", "clock"]} />
                  </Tippy>
                </div>
              </div>
              {naturalLanguageMessage && (
                <div
                  className={`scheduler-natural-language-message ${
                    index === selectedScheduleIndex ? "visible" : "hidden"
                  }`}
                >
                  {naturalLanguageMessage}
                </div>
              )}
            </div>
          )
        })}
      </form>
    </Modal>
  )
}

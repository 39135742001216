import {
  DimensionTree,
  DimensionTreeError,
  DimensionBranchError,
  SegmentCondition,
  SegmentConditionError,
  ERROR,
} from "resources/segment/segment/segmentConditionsTypes"
import {
  isCompoundSegmentCondition,
  isDimensionBranch,
} from "resources/segment/segment/utilities/segmentConditionTypeChecks"
import { OPERATION_HAS_VALUE } from "resources/segment/segment/utilities/segmentOperationsConstants"

const isEmpty = (value: any) =>
  value === null ||
  value === undefined ||
  value === "" ||
  (typeof value === "number" && isNaN(value)) ||
  (Array.isArray(value) && value.length === 0)

function validateDimensionsTree(tree: DimensionTree): DimensionTreeError | null {
  if (isDimensionBranch(tree)) {
    return { operands: tree.operands.map(validateDimensionsTree) }
  }

  const error: DimensionTreeError = {}

  const { sub_attribute_id, operation, value } = tree.condition

  if (!sub_attribute_id) {
    error.sub_attribute_id = ERROR.REQUIRED
  }

  if (!operation) {
    error.operation = ERROR.REQUIRED
  } else if (OPERATION_HAS_VALUE[operation] === 1 || OPERATION_HAS_VALUE[operation] === -1) {
    if (isEmpty(value)) error.value = ERROR.REQUIRED
    else if (
      [
        "contains_any_of",
        "not_contain_any_of",
        "number_in",
        "number_not_in",
        "in",
        "not_in",
      ].includes(operation) &&
      value.length > 100
    )
      error.value = ERROR.MAX_LENGTH
  } else if (OPERATION_HAS_VALUE[operation] === 2) {
    if (!Array.isArray(value)) {
      error.min_value = ERROR.REQUIRED
      error.max_value = ERROR.REQUIRED
    } else {
      if (isEmpty(value[0])) {
        error.min_value = ERROR.REQUIRED
      }
      if (isEmpty(value[1])) {
        error.max_value = ERROR.REQUIRED
      }
    }
  }

  return Object.keys(error).length > 0 ? error : null
}

function areAllLeafsNull(dimensionsTree: DimensionTreeError): boolean {
  return (dimensionsTree as DimensionBranchError)?.operands
    ? (dimensionsTree as DimensionBranchError).operands.every(areAllLeafsNull)
    : dimensionsTree === null
}

export function validateSegmentCondition(
  condition: SegmentCondition,
): SegmentConditionError | null {
  const error: SegmentConditionError = {}

  if (!condition.attribute_id) {
    error.attribute_id = ERROR.REQUIRED
  }

  if (isCompoundSegmentCondition(condition)) {
    const dimensions = validateDimensionsTree(condition)

    if (!areAllLeafsNull(dimensions)) {
      error.dimensions = dimensions
    }
  } else {
    const { operation, value } = condition.condition

    if (!operation) {
      error.operation = ERROR.REQUIRED
    } else if (OPERATION_HAS_VALUE[operation] === 1 || OPERATION_HAS_VALUE[operation] === -1) {
      if (isEmpty(value)) error.value = ERROR.REQUIRED
      else if (
        [
          "contains_any_of",
          "not_contain_any_of",
          "number_in",
          "number_not_in",
          "in",
          "not_in",
        ].includes(operation) &&
        value.length > 100
      )
        error.value = ERROR.MAX_LENGTH
    } else if (OPERATION_HAS_VALUE[operation] === 2) {
      if (!Array.isArray(value)) {
        error.min_value = ERROR.REQUIRED
        error.max_value = ERROR.REQUIRED
      } else {
        if (isEmpty(value[0])) {
          error.min_value = ERROR.REQUIRED
        }
        if (isEmpty(value[1])) {
          error.max_value = ERROR.REQUIRED
        }
      }
    }
  }

  return Object.keys(error).length > 0 ? error : null
}

import {
  InfiniteData,
  QueryKey,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { api } from "api"
import { FunnelGroup } from "resources/funnelGroup/funnelGroupTypes"
import {
  Chart,
  ChartClonePayload,
  ChartCreatePayload,
  ChartModifyPayload,
  ChartsDataRequest,
  ChartsDataResponse,
  ChartsListResponse,
} from "./funnelChartTypes"
import { showToast } from "app/toast"
import { always, move, reject, update, whereEq } from "ramda"
import { TrashItem } from "types/trash"
import { getRoutePath } from "routes"
import { DateString } from "types/util"
import { Segment } from "resources/segment/segment/segmentTypes"
import { socket } from "context/socket"

export default {}

const FUNNEL_CHART = "funnelChart"

function useFunnelChartsQuery<T>(
  funnelGroupId: FunnelGroup["id"],
  config?: UseQueryOptions<ChartsListResponse, unknown, T, QueryKey>,
) {
  return useQuery(
    [FUNNEL_CHART, funnelGroupId, "all"] as QueryKey,
    () => api.funnelChart.listAll(funnelGroupId),
    {
      staleTime: 10 * 1000,
      ...config,
    },
  )
}

export function useFetchAllFunnelCharts(funnelGroupId?: FunnelGroup["id"]) {
  return useFunnelChartsQuery(funnelGroupId!, {
    select: data => data.charts,
    enabled: !!funnelGroupId,
  })
}

export function useFetchFunnelChart(funnelGroupId: FunnelGroup["id"], chartId: Chart["id"]) {
  return useFunnelChartsQuery(funnelGroupId, {
    select: ({ charts }) => charts.find(whereEq({ id: chartId })) ?? null,
  })
}

export function useCreateFunnelChart() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ funnelGroupId, data }: { funnelGroupId: FunnelGroup["id"]; data: ChartCreatePayload }) =>
      api.funnelChart.create(funnelGroupId, data),
    {
      onSuccess({ chart }, { funnelGroupId }) {
        queryClient.setQueryData<{ charts: Chart[] }>(
          [FUNNEL_CHART, funnelGroupId, "all"],
          data => {
            if (!data) return

            return {
              charts: data.charts.concat(chart),
            }
          },
        )

        showToast("Chart created.")
      },
    },
  )
}

export function useModifyFunnelChart() {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      funnelGroupId,
      chartId,
      data,
    }: {
      funnelGroupId: FunnelGroup["id"]
      chartId: Chart["id"]
      data: ChartModifyPayload
    }) => api.funnelChart.modify(funnelGroupId, chartId, data),
    {
      onSuccess({ chart }, { funnelGroupId }) {
        queryClient.setQueryData<{ charts: Chart[] }>(
          [FUNNEL_CHART, funnelGroupId, "all"],
          data => {
            if (!data) return

            const index = data.charts.findIndex(whereEq({ id: chart.id }))

            return {
              charts: index === -1 ? data.charts.concat(chart) : update(index, chart, data.charts),
            }
          },
        )

        showToast("Chart modified.")
      },
    },
  )
}

export function useDeleteFunnelChart() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ funnelGroupId, chartId }: { funnelGroupId: FunnelGroup["id"]; chartId: Chart["id"] }) =>
      api.funnelChart.delete(funnelGroupId, chartId),
    {
      onSuccess: (_, { funnelGroupId, chartId }) => {
        queryClient.setQueryData<{ charts: Chart[] }>(
          [FUNNEL_CHART, funnelGroupId, "all"],
          data => {
            if (!data) return

            return {
              charts: reject(whereEq({ id: chartId }), data.charts),
            }
          },
        )

        queryClient.invalidateQueries([FUNNEL_CHART, funnelGroupId, "trash"])

        showToast("Chart deleted.")
      },
    },
  )
}

export function useCloneFunnelChart() {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      funnelGroupId,
      chartId,
      data,
    }: {
      funnelGroupId: FunnelGroup["id"]
      chartId: Chart["id"]
      data: ChartClonePayload
    }) => api.funnelChart.clone(funnelGroupId, chartId, data),
    {
      onSuccess({ chart }, { data: { funnel_group_id: groupIdClonedTo } }) {
        queryClient.setQueryData<{ charts: Chart[] }>(
          [FUNNEL_CHART, groupIdClonedTo, "all"],
          data => {
            if (!data) return

            return {
              charts: data.charts.concat(chart),
            }
          },
        )

        showToast("Chart copied.")
      },
    },
  )
}

export function useMoveFunnelChart() {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      funnelGroupId,
      chartId,
      data,
    }: {
      funnelGroupId: FunnelGroup["id"]
      chartId: Chart["id"]
      data: { fromIndex: number; toIndex: number }
    }) =>
      api.funnelChart.move(funnelGroupId, chartId, {
        order_index: data.toIndex + 1, // API uses 1-based index
      }),
    {
      onMutate({ funnelGroupId, data: { fromIndex, toIndex } }) {
        const prevState = structuredClone(
          queryClient.getQueryData<{ charts: Chart[] }>([FUNNEL_CHART, funnelGroupId, "all"]),
        )

        queryClient.setQueryData<{ charts: Chart[] }>(
          [FUNNEL_CHART, funnelGroupId, "all"],
          data => {
            if (!data) return

            return {
              charts: move(fromIndex, toIndex, data.charts),
            }
          },
        )

        return prevState
      },
      onError(_, { funnelGroupId }, prevState) {
        queryClient.setQueryData([FUNNEL_CHART, funnelGroupId, "all"], prevState)
      },
      onSuccess(_, { funnelGroupId }) {
        queryClient.invalidateQueries([FUNNEL_CHART, funnelGroupId, "all"])
        showToast("Chart moved.")
      },
    },
  )
}

export function useFetchFunnelChartTrashItems(searchTerm: string) {
  const { data, ...rest } = useInfiniteQuery<
    { trashed_charts: Chart[] },
    string,
    { trashed_charts: TrashItem[] },
    QueryKey
  >(
    [FUNNEL_CHART, "trash"],
    () => {
      return api.funnelChart.listDeleted()
    },
    {
      getNextPageParam: always(undefined),
      select: data => {
        return {
          ...data,
          pages: data.pages.map(p => ({
            ...p,
            trashed_charts: p.trashed_charts.map(({ id, name, modified, modified_by }) => {
              const trashItem: TrashItem = {
                id,
                name,
                deleted_at: modified,
                deleted_by: modified_by,
                type: "charts",
              }

              return trashItem
            }),
          })),
        }
      },
    },
  )

  return {
    ...rest,
    data: data
      ? data.pages
          .flatMap(m => m.trashed_charts)
          .filter(({ name }) =>
            searchTerm ? name.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) : true,
          )
      : [],
  }
}

export function useRestoreFunnelChart() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ groupId, id }: { groupId: FunnelGroup["id"]; id: Chart["id"] }) =>
      api.funnelChart.restoreDeleted(groupId, id),
    {
      onSuccess({ chart }, { groupId }) {
        queryClient.setQueryData<{ charts: Chart[] }>([FUNNEL_CHART, groupId, "all"], data => {
          if (!data) return

          return {
            charts: data.charts.concat(chart),
          }
        })

        queryClient.setQueryData<InfiniteData<{ trashed_charts: Chart[] }>>(
          [FUNNEL_CHART, "trash"],
          data => {
            if (!data) return

            return {
              ...data,
              pages: data.pages.map(({ trashed_charts }) => ({
                trashed_charts: reject(whereEq({ id: chart.id }), trashed_charts),
              })),
            }
          },
        )

        showToast(
          "Chart restored.",
          undefined,
          getRoutePath("analytics.funnels.group", { groupId }),
        )
      },
    },
  )
}

type FetchChartsOpts = {
  groupId?: FunnelGroup["id"]
  chartIds: Chart["id"][]
  startDate: DateString
  endDate: DateString
  segmentId: Segment["id"] | null
}

type SocketError = {
  chart_id?: string
  error: string
  error_type: "ATTRIBUTE_ ERROR" | string
}

export function useFetchChartsData({
  groupId,
  chartIds,
  startDate,
  endDate,
  segmentId,
}: FetchChartsOpts) {
  return useQuery(
    ["funnelChartsData", groupId, startDate, endDate, segmentId],
    () =>
      new Promise<Record<Chart["id"], ChartsDataResponse>>((resolve, reject) => {
        const data: Record<Chart["id"], ChartsDataResponse | null> = Object.fromEntries(
          chartIds.map(id => [id, null]),
        )

        socket.off("funnels_counts_response")
        socket.off("funnels_counts_error")

        socket.on("funnels_counts_error", (res: SocketError) => {
          if (res.error_type === "ATTRIBUTE_ERROR" && res.chart_id) {
            data[res.chart_id] = {
              chart_id: res.chart_id,
              error: res.error,
              data: null,
            }

            if (Object.values(data).every(data => !!data?.data || !!data?.error)) {
              socket.off("funnels_counts_response")
              socket.off("funnels_counts_error")
              resolve(data as Record<Chart["id"], ChartsDataResponse>)
            }
          } else {
            socket.off("funnels_counts_response")
            socket.off("funnels_counts_error")
            reject(res.error)
          }
        })

        socket.on("funnels_counts_response", (res: ChartsDataResponse) => {
          data[res.chart_id] = res

          if (Object.values(data).every(data => !!data?.data || !!data?.error)) {
            socket.off("funnels_counts_response")
            socket.off("funnels_counts_error")
            resolve(data as Record<Chart["id"], ChartsDataResponse>)
          }
        })

        const request: ChartsDataRequest = {
          funnel_group_id: groupId!, // We fetch manually; groupId will be defined by the time we fetch
          date_range: { start: `${startDate}T00:00:00Z`, end: `${endDate}T00:00:00Z` },
          segment_id: segmentId,
        }
        socket.emit("funnels_counts", request)
      }),
    { enabled: false, keepPreviousData: true },
  )
}

import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./SubAttributePicker.scss"
import { isNil } from "ramda"

class SubAttributePicker extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  _handleOutsideClick = evt => {
    if (!isNil(this.dropdown)) {
      if (!this.dropdown.contains(evt.target)) {
        this.dropdownToggle()
      }
    }
  }

  dropdownToggle = () => {
    if (this.state.open) {
      this.setState({ open: false })
      document.removeEventListener("click", this._handleOutsideClick, false)
    } else {
      this.setState({
        open: true,
      })
      setTimeout(() => document.addEventListener("click", this._handleOutsideClick, false), 0)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this._handleOutsideClick, false)
  }

  changeSubAttribute = subAttributeId => () => {
    this.props.handleValueChange(subAttributeId)
    this.dropdownToggle()
  }

  render() {
    const { selected, options, alreadySelectedIds, isEditable, isVisible, errorMarkup } = this.props
    const { open } = this.state

    const availableOptions = options.filter(option => !alreadySelectedIds.includes(option.id))

    return (
      <div className={`sub-attribute-picker-wrapper ${selected !== null ? "selected" : ""}`}>
        <button
          className={`sub-attribute-dropdown-button ${errorMarkup ? "error-shadow" : ""} ${
            isVisible ? "is-visible" : ""
          }`}
          onClick={this.dropdownToggle}
          disabled={!isEditable}
        >
          <span>
            <div className="text">
              {selected ? selected.name : <span className="placeholder">Select dimension</span>}
            </div>
            <div className="caret-wrapper">
              <FontAwesomeIcon icon={["fas", open ? "caret-up" : "caret-down"]} />
            </div>
          </span>
        </button>
        {open && (
          <div className="dropdown" ref={node => (this.dropdown = node)}>
            {availableOptions.length > 0 ? (
              <ul className="dropdown-content">
                {availableOptions.map(option => (
                  <li key={option.id} onClick={this.changeSubAttribute(option.id)}>
                    {option.name}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="empty-message">No more dimensions to select</div>
            )}
          </div>
        )}
      </div>
    )
  }
}

SubAttributePicker.defaultProps = {
  isEditable: true,
}

SubAttributePicker.propTypes = {
  selected: PropTypes.object,
  options: PropTypes.array,
  alreadySelectedIds: PropTypes.array,
  handleValueChange: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  errorMarkup: PropTypes.bool,
  isVisible: PropTypes.bool,
}

export default SubAttributePicker

import {
  LeafOperator,
  ConditionSubject,
  datetimeSubjects,
  DataType,
  AttributeLocation,
} from "resources/webBanner/webBannerConditionTypes"
import { isOperatorAllowed } from "./utils"

export type SubjectDropdownOption = { value: ConditionSubject; label: string }
export const subjectOptions: SubjectDropdownOption[] = [
  { label: "Browser storage: cookie", value: "cookie" },
  { label: "Browser storage: local storage", value: "local_storage" },
  { label: "Browser: language", value: "browser_language" },
  { label: "Browser: name", value: "browser" },
  { label: "Country", value: "country_code" },
  { label: "Device: IP address", value: "ip_address" },
  { label: "Device: operating system", value: "os" },
  { label: "Device: type", value: "device" },
  { label: "Google Tag Manager", value: "gtm" },
  { label: "Page: hostname", value: "hostname" },
  { label: "Page: pathname", value: "pathname" },
  { label: "Page: title", value: "page_title" },
  { label: "Page: URL", value: "url" },
  { label: "Server: HTTP request", value: "http_request" },
  { label: "Segment", value: "segment" },
  { label: "Time: datetime", value: "current_datetime" },
  { label: "Time: day of the week", value: "current_day_of_week" },
  { label: "Time: hour of the day", value: "current_hour" },
  { label: "Traffic source: referrer", value: "referrer" },
  { label: "Traffic source: UTM campaign", value: "utm_campaign" },
  { label: "Traffic source: UTM content", value: "utm_content" },
  { label: "Traffic source: UTM medium", value: "utm_medium" },
  { label: "Traffic source: UTM source", value: "utm_source" },
  { label: "Traffic source: UTM term", value: "utm_term" },
  { label: "Show after: pageviews", value: "page_views_count" },
]
export const getSubjectOption = (subject: ConditionSubject | null) =>
  subjectOptions.find(({ value }) => value === subject) ?? null

export type StorageTypeOption = { value: DataType; label: string }
export const storageTypeOptions: StorageTypeOption[] = [
  { value: "boolean", label: "boolean" },
  { value: "datetime", label: "datetime" },
  { value: "number", label: "number" },
  { value: "relative_datetime", label: "relative datetime" },
  { value: "string", label: "string" },
]
export const getStorageTypeOption = (storageType?: DataType) =>
  storageTypeOptions.find(({ value }) => value === storageType) ?? null

export type OperatorDropdownOption = { value: LeafOperator; label: string; bool_value?: boolean }
const operatorOptions: OperatorDropdownOption[] = [
  { value: "is_ok", label: "response is OK" },
  { value: "is_set", label: "is set" },
  { value: "is_not_set", label: "is not set" },
  { value: "contains", label: "contains" },
  { value: "not_contains", label: "doesn't contain" },
  { value: "lower", label: "is less than" },
  { value: "greater", label: "is greater than" },
  { value: "between", label: "is between" },
  { value: "equals", label: "equals" },
  { value: "not_equals", label: "doesn't equal" },
  { value: "in", label: "equals any of" },
  { value: "not_in", label: "doesn't equal any of" },
  { value: "contains_any", label: "contains any of" },
  { value: "not_contain_any", label: "doesn't contain any of" },
  { value: "in_segment", label: "is in segment" },
  { value: "not_in_segment", label: "is not in segment" },
]

export function getOperatorOptions(
  subject: ConditionSubject | null,
  type?: DataType,
): OperatorDropdownOption[] {
  // exceptions >:(
  if (subject === "current_hour") {
    return [
      { value: "equals", label: "equals" },
      { value: "not_equals", label: "doesn't equal" },
      { value: "greater", label: "since" },
      { value: "lower", label: "until" },
      { value: "between", label: "since / until" },
    ]
  }

  if (
    (subject && (datetimeSubjects as readonly string[]).includes(subject)) ||
    type === "datetime" ||
    type === "relative_datetime"
  ) {
    return [
      { value: "greater", label: "since" },
      { value: "lower", label: "until" },
      { value: "between", label: "since / until" },
    ]
  }

  if (type === "boolean") {
    return [
      { value: "equals", label: "yes", bool_value: true },
      { value: "equals", label: "no", bool_value: false },
    ]
  }
  // end exceptions
  return operatorOptions.filter(({ value }) => isOperatorAllowed(value, subject, type))
}

export const deviceOptions = [
  { value: "console", label: "Console" },
  { value: "desktop", label: "Desktop" },
  { value: "embedded", label: "Embedded" },
  { value: "mobile", label: "Mobile" },
  { value: "smarttv", label: "Smart TV" },
  { value: "tablet", label: "Tablet" },
  { value: "wearable", label: "Wearable" },
]

export const osOptions = [
  { value: "Android", label: "Android" },
  { value: "iOS", label: "iOS" },
  { value: "Mac OS", label: "Mac OS" },
  { value: "Windows", label: "Windows" },
  { value: "other", label: "other" },
]

export const browserOptions = [
  { value: "Chrome", label: "Chrome" },
  { value: "Edge", label: "Edge" },
  { value: "Firefox", label: "Firefox" },
  { value: "Opera", label: "Opera" },
  { value: "Safari", label: "Safari" },
  { value: "other", label: "other" },
]

export const dayOfWeekOptions = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 0, label: "Sunday" },
]

export const languageOptions = [
  { value: "ab", label: "Abkhazian (ab)" },
  { value: "aa", label: "Afar (aa)" },
  { value: "af", label: "Afrikaans (af)" },
  { value: "ak", label: "Akan (ak)" },
  { value: "sq", label: "Albanian (sq)" },
  { value: "am", label: "Amharic (am)" },
  { value: "ar", label: "Arabic (ar)" },
  { value: "an", label: "Aragonese (an)" },
  { value: "hy", label: "Armenian (hy)" },
  { value: "as", label: "Assamese (as)" },
  { value: "av", label: "Avaric (av)" },
  { value: "ae", label: "Avestan (ae)" },
  { value: "ay", label: "Aymara (ay)" },
  { value: "az", label: "Azerbaijani (az)" },
  { value: "bm", label: "Bambara (bm)" },
  { value: "ba", label: "Bashkir (ba)" },
  { value: "eu", label: "Basque (eu)" },
  { value: "be", label: "Belarusian (be)" },
  { value: "bn", label: "Bengali (Bangla) (bn)" },
  { value: "bh", label: "Bihari (bh)" },
  { value: "bi", label: "Bislama (bi)" },
  { value: "bs", label: "Bosnian (bs)" },
  { value: "br", label: "Breton (br)" },
  { value: "bg", label: "Bulgarian (bg)" },
  { value: "my", label: "Burmese (my)" },
  { value: "ca", label: "Catalan (ca)" },
  { value: "ch", label: "Chamorro (ch)" },
  { value: "ce", label: "Chechen (ce)" },
  { value: "ny", label: "Chichewa, Chewa, Nyanja (ny)" },
  { value: "zh", label: "Chinese (zh)" },
  { value: "cv", label: "Chuvash (cv)" },
  { value: "kw", label: "Cornish (kw)" },
  { value: "co", label: "Corsican (co)" },
  { value: "cr", label: "Cree (cr)" },
  { value: "hr", label: "Croatian (hr)" },
  { value: "cs", label: "Czech (cs)" },
  { value: "da", label: "Danish (da)" },
  { value: "dv", label: "Divehi, Dhivehi, Maldivian (dv)" },
  { value: "nl", label: "Dutch (nl)" },
  { value: "dz", label: "Dzongkha (dz)" },
  { value: "en", label: "English (en)" },
  { value: "eo", label: "Esperanto (eo)" },
  { value: "et", label: "Estonian (et)" },
  { value: "ee", label: "Ewe (ee)" },
  { value: "fo", label: "Faroese (fo)" },
  { value: "fj", label: "Fijian (fj)" },
  { value: "fi", label: "Finnish (fi)" },
  { value: "fr", label: "French (fr)" },
  { value: "ff", label: "Fula, Fulah, Pulaar, Pular (ff)" },
  { value: "gl", label: "Galician (gl)" },
  { value: "gd", label: "Gaelic (Scottish) (gd)" },
  { value: "gv", label: "Gaelic (Manx) (gv)" },
  { value: "ka", label: "Georgian (ka)" },
  { value: "de", label: "German (de)" },
  { value: "el", label: "Greek (el)" },
  { value: "kl", label: "Greenlandic (kl)" },
  { value: "gn", label: "Guarani (gn)" },
  { value: "gu", label: "Gujarati (gu)" },
  { value: "ht", label: "Haitian Creole (ht)" },
  { value: "ha", label: "Hausa (ha)" },
  { value: "he", label: "Hebrew (he)" },
  { value: "hz", label: "Herero (hz)" },
  { value: "hi", label: "Hindi (hi)" },
  { value: "ho", label: "Hiri Motu (ho)" },
  { value: "hu", label: "Hungarian (hu)" },
  { value: "is", label: "Icelandic (is)" },
  { value: "io", label: "Ido (io)" },
  { value: "ig", label: "Igbo (ig)" },
  { value: "id", label: "Indonesian (id)" },
  { value: "in", label: "Indonesian (in)" },
  { value: "ia", label: "Interlingua (ia)" },
  { value: "ie", label: "Interlingue (ie)" },
  { value: "iu", label: "Inuktitut (iu)" },
  { value: "ik", label: "Inupiak (ik)" },
  { value: "ga", label: "Irish (ga)" },
  { value: "it", label: "Italian (it)" },
  { value: "ja", label: "Japanese (ja)" },
  { value: "jv", label: "Javanese (jv)" },
  { value: "kl", label: "Kalaallisut, Greenlandic (kl)" },
  { value: "kn", label: "Kannada (kn)" },
  { value: "kr", label: "Kanuri (kr)" },
  { value: "ks", label: "Kashmiri (ks)" },
  { value: "kk", label: "Kazakh (kk)" },
  { value: "km", label: "Khmer (km)" },
  { value: "ki", label: "Kikuyu (ki)" },
  { value: "rw", label: "Kinyarwanda (Rwanda) (rw)" },
  { value: "rn", label: "Kirundi (rn)" },
  { value: "ky", label: "Kyrgyz (ky)" },
  { value: "kv", label: "Komi (kv)" },
  { value: "kg", label: "Kongo (kg)" },
  { value: "ko", label: "Korean (ko)" },
  { value: "ku", label: "Kurdish (ku)" },
  { value: "kj", label: "Kwanyama (kj)" },
  { value: "lo", label: "Lao (lo)" },
  { value: "la", label: "Latin (la)" },
  { value: "lv", label: "Latvian (Lettish) (lv)" },
  { value: "li", label: "Limburgish ( Limburger) (li)" },
  { value: "ln", label: "Lingala (ln)" },
  { value: "lt", label: "Lithuanian (lt)" },
  { value: "lu", label: "Luga-Katanga (lu)" },
  { value: "lg", label: "Luganda, Ganda (lg)" },
  { value: "lb", label: "Luxembourgish (lb)" },
  { value: "gv", label: "Manx (gv)" },
  { value: "mk", label: "Macedonian (mk)" },
  { value: "mg", label: "Malagasy (mg)" },
  { value: "ms", label: "Malay (ms)" },
  { value: "ml", label: "Malayalam (ml)" },
  { value: "mt", label: "Maltese (mt)" },
  { value: "mi", label: "Maori (mi)" },
  { value: "mr", label: "Marathi (mr)" },
  { value: "mh", label: "Marshallese (mh)" },
  { value: "mo", label: "Moldavian (mo)" },
  { value: "mn", label: "Mongolian (mn)" },
  { value: "na", label: "Nauru (na)" },
  { value: "nv", label: "Navajo (nv)" },
  { value: "ng", label: "Ndonga (ng)" },
  { value: "nd", label: "Northern Ndebele (nd)" },
  { value: "ne", label: "Nepali (ne)" },
  { value: "no", label: "Norwegian (no)" },
  { value: "nb", label: "Norwegian bokmål (nb)" },
  { value: "nn", label: "Norwegian nynorsk (nn)" },
  { value: "ii", label: "Nuosu (ii)" },
  { value: "oc", label: "Occitan (oc)" },
  { value: "oj", label: "Ojibwe (oj)" },
  { value: "cu", label: "Old Church Slavonic, Old Bulgarian (cu)" },
  { value: "or", label: "Oriya (or)" },
  { value: "om", label: "Oromo (Afaan Oromo) (om)" },
  { value: "os", label: "Ossetian (os)" },
  { value: "pi", label: "Pāli (pi)" },
  { value: "ps", label: "Pashto, Pushto (ps)" },
  { value: "fa", label: "Persian (Farsi) (fa)" },
  { value: "pl", label: "Polish (pl)" },
  { value: "pt", label: "Portuguese (pt)" },
  { value: "pa", label: "Punjabi (Eastern) (pa)" },
  { value: "qu", label: "Quechua (qu)" },
  { value: "rm", label: "Romansh (rm)" },
  { value: "ro", label: "Romanian (ro)" },
  { value: "ru", label: "Russian (ru)" },
  { value: "se", label: "Sami (se)" },
  { value: "sm", label: "Samoan (sm)" },
  { value: "sg", label: "Sango (sg)" },
  { value: "sa", label: "Sanskrit (sa)" },
  { value: "sr", label: "Serbian (sr)" },
  { value: "sh", label: "Serbo-Croatian (sh)" },
  { value: "st", label: "Sesotho (st)" },
  { value: "tn", label: "Setswana (tn)" },
  { value: "sn", label: "Shona (sn)" },
  { value: "ii", label: "Sichuan Yi (ii)" },
  { value: "sd", label: "Sindhi (sd)" },
  { value: "si", label: "Sinhalese (si)" },
  { value: "ss", label: "Siswati (ss)" },
  { value: "sk", label: "Slovak (sk)" },
  { value: "sl", label: "Slovenian (sl)" },
  { value: "so", label: "Somali (so)" },
  { value: "nr", label: "Southern Ndebele (nr)" },
  { value: "es", label: "Spanish (es)" },
  { value: "su", label: "Sundanese (su)" },
  { value: "sw", label: "Swahili (Kiswahili) (sw)" },
  { value: "ss", label: "Swati (ss)" },
  { value: "sv", label: "Swedish (sv)" },
  { value: "tl", label: "Tagalog (tl)" },
  { value: "ty", label: "Tahitian (ty)" },
  { value: "tg", label: "Tajik (tg)" },
  { value: "ta", label: "Tamil (ta)" },
  { value: "tt", label: "Tatar (tt)" },
  { value: "te", label: "Telugu (te)" },
  { value: "th", label: "Thai (th)" },
  { value: "bo", label: "Tibetan (bo)" },
  { value: "ti", label: "Tigrinya (ti)" },
  { value: "to", label: "Tonga (to)" },
  { value: "ts", label: "Tsonga (ts)" },
  { value: "tr", label: "Turkish (tr)" },
  { value: "tk", label: "Turkmen (tk)" },
  { value: "tw", label: "Twi (tw)" },
  { value: "ug", label: "Uyghur (ug)" },
  { value: "uk", label: "Ukrainian (uk)" },
  { value: "ur", label: "Urdu (ur)" },
  { value: "uz", label: "Uzbek (uz)" },
  { value: "ve", label: "Venda (ve)" },
  { value: "vi", label: "Vietnamese (vi)" },
  { value: "vo", label: "Volapük (vo)" },
  { value: "wa", label: "Wallon (wa)" },
  { value: "cy", label: "Welsh (cy)" },
  { value: "wo", label: "Wolof (wo)" },
  { value: "fy", label: "Western Frisian (fy)" },
  { value: "xh", label: "Xhosa (xh)" },
  { value: "yi", label: "Yiddish (yi)" },
  { value: "ji", label: "Yiddish (ji)" },
  { value: "yo", label: "Yoruba (yo)" },
  { value: "za", label: "Zhuang, Chuang (za)" },
  { value: "zu", label: "Zulu (zu)" },
]

export type AttributeLocationOption = { label: string; value: AttributeLocation }
export const attributeLocationOptions: Array<AttributeLocationOption> = [
  { label: "Cookie", value: "cookie" },
  { label: "Local storage", value: "ls" },
]

export const getAttributeLocationOption = (option: AttributeLocation | null) =>
  attributeLocationOptions.find(({ value }) => value === option) ?? null

// Country names array using ISO 3166 Alpha-2 country codes
// Sorted alphabetical by country name
export const countryOptions = [
  { value: "AF", label: "Afghanistan" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia (Plurinational State of)" },
  { value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet Island" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "CV", label: "Cabo Verde" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos (Keeling) Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CD", label: "Congo, Democratic Republic of the" },
  { value: "CG", label: "Congo" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CW", label: "Curaçao" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czechia" },
  { value: "CI", label: "Côte d'Ivoire" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "SZ", label: "Eswatini" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands (Malvinas)" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard Island and McDonald Islands" },
  { value: "VA", label: "Holy See" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran (Islamic Republic of)" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KP", label: "Korea (Democratic People's Republic of)" },
  { value: "KR", label: "Korea (Republic of)" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Lao People's Democratic Republic" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia (Federated States of)" },
  { value: "MD", label: "Moldova (Republic of)" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestine, State of" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "MK", label: "Republic of North Macedonia" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russian Federation" },
  { value: "RW", label: "Rwanda" },
  { value: "RE", label: "Réunion" },
  { value: "BL", label: "Saint Barthélemy" },
  { value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
  { value: "KN", label: "Saint Kitts and Nevis" },
  { value: "LC", label: "Saint Lucia" },
  { value: "MF", label: "Saint Martin (French part)" },
  { value: "PM", label: "Saint Pierre and Miquelon" },
  { value: "VC", label: "Saint Vincent and the Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tome and Principe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SX", label: "Sint Maarten (Dutch part)" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "GS", label: "South Georgia and the South Sandwich Islands" },
  { value: "SS", label: "South Sudan" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard and Jan Mayen" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syrian Arab Republic" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania, United Republic of" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks and Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom of Great Britain and Northern Ireland" },
  { value: "UM", label: "United States Minor Outlying Islands" },
  { value: "US", label: "United States of America" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela (Bolivarian Republic of)" },
  { value: "VN", label: "Viet Nam" },
  { value: "VG", label: "Virgin Islands (British)" },
  { value: "VI", label: "Virgin Islands (U.S.)" },
  { value: "WF", label: "Wallis and Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
  { value: "AX", label: "Åland Islands" },
]

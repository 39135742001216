import { useCallback, useContext, useEffect, useState } from "react"
import { SocketContext } from "context/socket"
import { has } from "ramda"

import { showToast } from "app/toast"
import { SegmentAggregation, SegmentAggregationResponse } from "./segmentAggregationTypes"
import { Segment } from "../segment/segmentTypes"
import { TOAST } from "sharedConstants"

type SegmentAggregationRecord = Record<
  SegmentAggregation["segment_aggregation"]["attribute_aggregation_id"],
  SegmentAggregation["segment_aggregation"]
>

export const useFetchSegmentAggregations = (segmentId?: Segment["id"]) => {
  const [allAggregationValues, setAllAggregationValues] = useState<SegmentAggregationRecord>({})
  const [segmentAggregationValues, setSegmentAggregationValues] =
    useState<SegmentAggregationRecord>({})

  const socket = useContext(SocketContext)

  const refetch = useCallback(() => {
    setAllAggregationValues({})
    setSegmentAggregationValues({})
    socket.emit("segment_aggregations", { segment_id: null })
    if (segmentId) socket.emit("segment_aggregations", { segment_id: segmentId })
  }, [segmentId, socket])

  useEffect(() => {
    socket.on("segment_aggregations_response", (msg: SegmentAggregationResponse) => {
      if (has("error", msg)) showToast(msg.error, TOAST.TYPE.ERROR)
      else {
        if (msg.segment_id === null)
          setAllAggregationValues(prev => ({
            ...prev,
            [msg.segment_aggregation.attribute_aggregation_id]: msg.segment_aggregation,
          }))
        else if (segmentId && msg.segment_id === segmentId) {
          setSegmentAggregationValues(prev => ({
            ...prev,
            [msg.segment_aggregation.attribute_aggregation_id]: msg.segment_aggregation,
          }))
        }
      }
    })

    refetch()

    return () => {
      socket.off("segment_aggregations_response")
    }
  }, [refetch, segmentId, socket])

  return { allAggregationValues, segmentAggregationValues, refetch }
}

import React, { useState } from "react"
import classNames from "classnames"
import styles from "./CreateChartModalButton.module.scss"
import Modal from "components/UI/elements/Modal"
import { useHistory, useParams } from "react-router-dom"
import { ChartType } from "resources/funnelChart/funnelChartTypes"
import funnelChartSVG from "./funnel-svg"
import lineChartSVG from "./line-svg"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"

export default function CreateChartModalButton({ isEditable }: { isEditable: boolean }) {
  const [isOpen, setIsOpen] = useState(false)
  const { groupId } = useParams<{ groupId: string }>()
  const history = useHistory()
  const [selectedType, setSelectedType] = useState<Lowercase<ChartType> | null>(null)

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        disabled={!isEditable}
        tooltip={
          isEditable ? undefined : "You don't have permission to create charts in this chart group."
        }
      >
        + Create chart
      </Button>

      <Modal
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        title="Create chart"
        className={styles.modal}
        contentClassName={styles.modalContent}
      >
        Select the type of chart:
        <div className={styles.chartCards}>
          <button
            className={classNames(styles.chartCard, { [styles.selected]: selectedType === "line" })}
            onClick={() => setSelectedType("line")}
          >
            {lineChartSVG}
            <div className={styles.text}>Line chart</div>
          </button>
          <button
            className={classNames(styles.chartCard, {
              [styles.selected]: selectedType === "funnel",
            })}
            onClick={() => setSelectedType("funnel")}
          >
            {funnelChartSVG}
            <div className={styles.text}>Funnel chart</div>
          </button>
        </div>
        <div className={styles.buttons}>
          <Button variant="text" color="grey" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            disabled={!selectedType}
            onClick={() => {
              history.push(
                `${getRoutePath("analytics.funnels.group.chart-create", {
                  groupId,
                })}?type=${selectedType}`,
              )
            }}
          >
            Create
          </Button>
        </div>
      </Modal>
    </>
  )
}

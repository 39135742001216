import { SystemEmailTemplate } from "../../SystemEmailTemplateItem/SystemEmailTemplateItem"
import imgSrc from "./CulinaryNewsletter.png"
import contentJson from "./culinaryNewsletter.json"

const template: SystemEmailTemplate = {
  contentJson,
  imgSrc,
  id: "culinaryNewsletter",
  name: "Welcome email template",
  description:
    "Welcome your subscribers or onboard new users with this clean and minimalist design template.",
}

export default template

import React from "react"
import styles from "./UserCounts.module.scss"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import { useFetchUserCounts } from "resources/stats/userCounts"
import { isNil } from "ramda"

export default function UserCounts() {
  const { data, isLoading } = useFetchUserCounts()

  return (
    <Paper className={styles.container}>
      <div className={styles.userCountItem}>
        <div className={styles.label}>Total number of users:</div>
        <div className={styles.value}>
          {isLoading ? <LoadingIndicator size="sm" /> : isNil(data) ? "N/A" : data.total}
        </div>
      </div>
      <div className={styles.userCountItem}>
        <div className={styles.label}>Active users:</div>
        <div className={styles.value}>
          {isLoading ? <LoadingIndicator size="sm" /> : isNil(data) ? "N/A" : data.activated}
        </div>
      </div>
      <div className={styles.userCountItem}>
        <div className={styles.label}>
          Never activated users:
          <InfoTooltip className={styles.info}>
            Users that have been invited but never accepted an invitation and haven't logged in
          </InfoTooltip>
        </div>
        <div className={styles.value}>
          {isLoading ? <LoadingIndicator size="sm" /> : isNil(data) ? "N/A" : data.not_activated}
        </div>
      </div>
      <div className={styles.userCountItem}>
        <div className={styles.label}>Deleted users:</div>
        <div className={styles.value}>
          {isLoading ? <LoadingIndicator size="sm" /> : isNil(data) ? "N/A" : data.deleted}
        </div>
      </div>
    </Paper>
  )
}

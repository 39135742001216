import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import Users from "./Users/UsersRoutes"
import Roles from "./Roles/RolesRoutes"
import Settings from "./Settings/Settings"
import UsersActivity from "./UsersActivity/UsersActivity"
import { useHasAccess } from "resources/user/currentUserQueries"
import AttributesSetup from "./Attributes/AttributesSetupRoutes"
import DestinationsSetup from "./Destinations/DestinationsSetupRoutes"
import EventsSetup from "./Events/EventsSetupRoutes"
import IdentityStitchingSetup from "./IdentityStitching/IdentityStitchingSetupRoutes"
import MetaAttributesSetup from "./MetaAttributes/MetaAttributesSetupRoutes"
import ReportsSetup from "./Reports/ReportsSetupRoutes"
import SourcesSetup from "./Sources/SourcesSetupRoutes"
import LabelsSetup from "./Labels/Labels"
import InsightsSetup from "./Insights/Insights"
import ChannelsSetup from "./Channels/ChannelsSetupRoutes"
import LookalikeSettings from "./LookalikeSettings/LookalikeSettings"
import FunnelGroups from "./FunnelGroups/FunnelGroupsRoutes"

export default function Administration() {
  const hasAccess = useHasAccess()

  function redirectToAllowedAdministrationTab() {
    if (hasAccess.setup.implementation) {
      return <Redirect to={getRoutePath("administration.sources")} />
    }
    if (hasAccess.setup.channels) {
      return <Redirect to={getRoutePath("administration.channels")} />
    }
    if (hasAccess.setup.reports) {
      return <Redirect to={getRoutePath("administration.reports")} />
    }
    if (hasAccess.setup.insights) {
      return <Redirect to={getRoutePath("administration.insights")} />
    }
    if (hasAccess.setup.lookalikeSettings) {
      return <Redirect to={getRoutePath("administration.lookalikeSettings")} />
    }
    if (hasAccess.setup.funnelGroups) {
      return <Redirect to={getRoutePath("administration.funnel-groups")} />
    }
    if (hasAccess.administration.globalSettings) {
      return <Redirect to={getRoutePath("administration.settings")} />
    }
    if (hasAccess.administration.usersActivity) {
      return <Redirect to={getRoutePath("administration.users-activity")} />
    }
    if (hasAccess.administration.users) {
      return <Redirect to={getRoutePath("administration.users")} />
    }
  }

  return (
    <Switch>
      <Route
        path={getRoutePath("administration")}
        exact
        render={redirectToAllowedAdministrationTab}
      />

      <AuthorizedRoute
        hasAccess={hasAccess.administration.usersActivity}
        path={getRoutePath("administration.users-activity")}
        component={UsersActivity}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.administration.users}
        path={getRoutePath("administration.users")}
        component={Users}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.administration.users}
        path={getRoutePath("administration.roles")}
        component={Roles}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.administration.globalSettings}
        path={getRoutePath("administration.settings")}
        component={Settings}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation}
        path={getRoutePath("administration.sources")}
        component={SourcesSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation}
        path={getRoutePath("administration.events")}
        component={EventsSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation}
        path={getRoutePath("administration.identity-stitching")}
        component={IdentityStitchingSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation}
        path={getRoutePath("administration.attributes")}
        component={AttributesSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation}
        path={getRoutePath("administration.metaAttributes")}
        component={MetaAttributesSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation}
        path={getRoutePath("administration.labels")}
        component={LabelsSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.insights}
        path={getRoutePath("administration.insights")}
        component={InsightsSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.reports}
        path={getRoutePath("administration.reports")}
        component={ReportsSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation}
        path={getRoutePath("administration.destinations")}
        component={DestinationsSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.channels}
        path={getRoutePath("administration.channels")}
        component={ChannelsSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.lookalikeSettings}
        path={getRoutePath("administration.lookalikeSettings")}
        component={LookalikeSettings}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.funnelGroups}
        path={getRoutePath("administration.funnel-groups")}
        component={FunnelGroups}
      />
    </Switch>
  )
}

import React, { Dispatch, lazy, SetStateAction, Suspense, useEffect, useState } from "react"
import TextArea from "components/UI/elements/TextArea/TextArea"
import { required, pythonVariable } from "helpers/validators.helper"
import SelectField from "components/UI/elements/SelectField"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Button from "components/UI/elements/Button/Button"
import Tag from "components/UI/elements/Tag"
import TagPicker from "components/UI/components/TagPicker"
import ExpandableAceEditor from "components/UI/components/ExpandableAceEditor/ExpandableAceEditor"
import classNames from "classnames"
import { identity, map, pipe, prop, sort, uniq, whereEq, omit, always, dec, inc } from "ramda"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import { ascend } from "utilities/comparators"
import { useFetchAllEvents, useFetchEventTypes } from "resources/event/eventQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useFetchDataSourceOptions } from "resources/dataSource/dataSourceQueries"
import { useFetchAllLabels } from "resources/attributeLabel/attributeLabelQueries"
import SystemBadge from "components/UI/elements/SystemBadge/SystemBadge"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import TextInput from "components/UI/elements/TextInput/TextInput"
import ToggleIconSwitchInput from "components/UI/elements/ToggleSwitch/ToggleIconSwitchInput"
import Paper from "components/UI/elements/Paper"
import {
  Attribute,
  AttributeCreatePayload,
  AttributeModifyPayload,
  AttributeTestPayload,
  AttributeTestResponse,
  CustomDefinition,
  Definition,
  PredefinedDefinition,
} from "resources/attribute/attributeTypes"
import { Label } from "resources/attributeLabel/attributeLabelTypes"
import { Source } from "resources/dataSource/dataSourceTypes"
import { Event, EventFull } from "resources/event/eventTypes"
import styles from "./AttributeForm.module.scss"
import { useTestAttribute } from "resources/attribute/attributeQueries"
import { showToast } from "app/toast"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AceEditor = lazy(() => import("components/AceEditor/AceEditor"))

const ATTRIBUTE_DATA_TYPES = [
  { value: "bool", label: "boolean" },
  { value: "compound", label: "compound" },
  { value: "date", label: "date" },
  { value: "datetime", label: "datetime" },
  { value: "float", label: "float" },
  { value: "int", label: "integer" },
  { value: "string", label: "string" },
]

const COMPOUND_ATTRIBUTE_DATA_TYPES = ATTRIBUTE_DATA_TYPES.filter(type => type.value !== "compound")

const DEFINITION_CALC_TYPE_OPTIONS = [
  { value: "avg", label: "Average" },
  { value: "first", label: "First" },
  { value: "last", label: "Last" },
  { value: "most_frequent", label: "Most frequent" },
  { value: "least_frequent", label: "Least frequent" },
  { value: "min", label: "Minimum" },
  { value: "max", label: "Maximum" },
  { value: "list", label: "List" },
  { value: "num_of_unique", label: "No. of unique" },
  { value: "sum", label: "Sum" },
  { value: "value", label: "Value" },
]

export type AttributeFormValues = Pick<
  Attribute,
  "id" | "name" | "source_id" | "description" | "data_type" | "is_unique" | "definition"
> & {
  // react-hook-form useFieldArray doesn't support an array of primitive values
  tag_ids: Array<{ id: Label["id"] }>

  _dimensions: Array<{ id: string; name: string; data_type: string | null }>
  _isDefinitionShown: boolean
  _definitionType: "predefined" | "custom"
  customerEntityId: string
}

type AttributeFormProps = {
  initialValues: AttributeFormValues
} & (
  | {
      isCreate: true
      isSystem?: false
      onSubmit: (values: AttributeCreatePayload) => void
    }
  | {
      isCreate?: undefined
      isSystem?: boolean
      onSubmit: (values: AttributeModifyPayload) => void
    }
)

export default function AttributeForm(props: AttributeFormProps) {
  const { isCreate, isSystem, initialValues } = props
  const { data: allEvents = [], isSuccess: areEventsFulfilled } = useFetchAllEvents()
  const { data: eventTypes = [] } = useFetchEventTypes()
  const { data: sourceOptions = [] } = useFetchDataSourceOptions({ showHidden: true })
  const { data: allLabels = [], isSuccess: areLabelsFulfilled } = useFetchAllLabels()
  const eventTypesForSelect = eventTypes.map(eventType => ({ label: eventType, value: eventType }))

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm<AttributeFormValues>({ defaultValues: initialValues })
  const {
    fields: labelFields,
    append: appendLabel,
    remove: removeLabel,
  } = useFieldArray({ name: "tag_ids", control })
  const {
    fields: dimensionFields,
    append: appendDimension,
    remove: removeDimension,
  } = useFieldArray({ name: "_dimensions", control })

  function submitForm(formValues: AttributeFormValues) {
    const { _dimensions, _isDefinitionShown, _definitionType, customerEntityId, ...values } =
      formValues
    // Accessing undestructured `props` object allows conditional type inferrence
    if (props.isSystem) {
      props.onSubmit({ tag_ids: values.tag_ids.map(({ id }) => id) })
      return
    }

    const definition = definitionFromFormValues(formValues)

    const tag_ids = values.tag_ids.map(({ id }) => id)

    if (props.isCreate) {
      let data_type = values.data_type
      if (data_type === "compound") {
        const dimensions = _dimensions!.map(({ id, name, data_type }) => [id, name, data_type])
        data_type = `compound(${JSON.stringify(dimensions)})`
      }

      const data: AttributeCreatePayload = {
        ...omit(["tag_ids", "definition", "data_type"], values),

        tag_ids,
        definition,
        data_type,
      }
      props.onSubmit(data)
      return
    }

    const data: AttributeModifyPayload = {
      ...omit(["id", "source_id", "is_unique", "data_type", "tag_ids", "definition"], values),

      tag_ids,
      definition,
    }
    props.onSubmit(data)
  }

  // Select only the event versions such that there are events with the selected sources and of the
  // selected types which have these versions
  function getAvailableEventVersions(
    allEvents: EventFull[],
    selectedSourceIds?: Source["id"][],
    selectedEventTypes?: Event["type"][],
  ) {
    let filteredEvents = allEvents

    if (selectedSourceIds && selectedSourceIds.length > 0) {
      filteredEvents = allEvents.filter(({ source: { id } }) => selectedSourceIds.includes(id))
    }
    if (selectedEventTypes && selectedEventTypes.length > 0) {
      filteredEvents = allEvents.filter(({ type }) => selectedEventTypes.includes(type))
    }

    return pipe(map(prop("version")), uniq, sort(ascend(identity)))(filteredEvents)
  }

  const tagIds = watch("tag_ids")
  const definition = watch("definition")

  // Keep selected versions in sync with available versions
  function updateVersionsSelection(
    selectedSourceIds: Source["id"][],
    selectedEventTypes: Event["type"][],
  ) {
    const availableEventVersions = getAvailableEventVersions(
      allEvents,
      selectedSourceIds,
      selectedEventTypes,
    )

    setValue(
      "definition.versions",
      (definition as PredefinedDefinition).versions?.filter(version =>
        availableEventVersions.includes(version),
      ),
    )
  }

  function updateVersionsAfterSourceChange(selectedSourceIds: Source["id"][]) {
    return updateVersionsSelection(selectedSourceIds, (definition as PredefinedDefinition).types)
  }

  function updateVersionsAfterEventTypeChange(selectedEventTypes: Event["type"][]) {
    return updateVersionsSelection((definition as PredefinedDefinition).sources, selectedEventTypes)
  }

  const [submitType, setSubmitType] = useState<"modify" | "test">("test")
  const requiredIfModifying = submitType === "modify" ? required : always(undefined)
  const requiredIfTestRun = submitType === "test" ? required : always(undefined)

  // Attribute test
  const [page, setPage] = useState(1)
  const isDefinitionShown = watch("_isDefinitionShown")
  const [testData, setTestData] = useState<AttributeTestResponse | null>()
  const hasError = !testData?.is_valid
  const testMutation = useTestAttribute()

  function testAttribute() {
    const formValues = getValues()
    const data = {
      customer_entity_id: formValues.customerEntityId,
      definition: omit(["id"], definitionFromFormValues(formValues)!),
      data_type: formValues.data_type === "compound" ? undefined : formValues.data_type,
      dimensions: formValues.data_type === "compound" ? formValues._dimensions : undefined,
      is_unique: Boolean(formValues.is_unique),
    } as AttributeTestPayload
    testMutation.mutate({ data }, { onSuccess: setTestData })
  }

  useEffect(() => setTestData(null), [])
  useEffect(() => setPage(1), [testMutation.isLoading])

  if (!areEventsFulfilled) return <LoadingIndicator />

  return (
    <form
      id="attributeForm"
      onSubmit={e => {
        e.preventDefault()
        setSubmitType("modify")
        setTimeout(() => handleSubmit(submitForm)(), 0)
      }}
      className={styles.container}
    >
      <Paper noPadding>
        <div data-testid="general-section" className={styles.generalSection}>
          <div className={styles.description}>
            <h2>General</h2>
            <div>{isSystem && <SystemBadge />}</div>
          </div>
          <div className={styles.content}>
            <TextInput
              {...register("id", { validate: { requiredIfModifying, pythonVariable } })}
              placeholder="Attribute ID"
              label="Attribute ID"
              maxLength={80}
              disabled={!isCreate || isSystem}
              error={errors.id?.message}
            />
            <TextInput
              {...register("name", { validate: requiredIfModifying })}
              placeholder="Name"
              label="Name"
              maxLength={80}
              disabled={isSystem}
              error={errors.name?.message}
            />
            <Controller
              name="source_id"
              control={control}
              rules={{ validate: requiredIfModifying }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <SelectField
                  input={{ value, onChange }}
                  placeholder="Source"
                  label="Source"
                  disabled={!isCreate || isSystem}
                  options={sourceOptions}
                  error={error?.message}
                  isSimpleValue
                />
              )}
            />
          </div>
        </div>
        <div className={styles.descriptionSection}>
          <div className={styles.description}>
            <h2>Description</h2>
          </div>
          <div className={styles.content}>
            <TextArea
              {...register("description")}
              placeholder="Description (optional)"
              label="Description (optional)"
              rows={8}
              disabled={isSystem}
            />
            <div>
              <p className={styles.label}>Labels (optional)</p>
              {areLabelsFulfilled && (
                <div className={styles.attributeLabelsList}>
                  {labelFields.map(({ id }, index) => {
                    const labelId = tagIds[index].id
                    const label = allLabels.find(whereEq({ id: labelId }))

                    return (
                      <Tag key={id} color="primary" clickable onClick={() => removeLabel(index)}>
                        {label?.name ?? labelId}
                      </Tag>
                    )
                  })}
                  <TagPicker
                    selectedTagIds={tagIds.map(({ id }) => id) ?? []}
                    allTags={allLabels}
                    onTagSelect={(id: Label["id"]) => appendLabel({ id })}
                    dropdownAlign="right"
                    type="label"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.dataTypeSection}>
          <div className={styles.description}>
            <div>
              <h2>Data settings</h2>
            </div>
            <p>
              To learn more:{" "}
              <a
                href="https://docs.meiro.io/books/meiro-business-explorer/page/set-attributes"
                target="_blank"
                rel="noreferrer noopener"
              >
                User documentation
              </a>
            </p>
          </div>
          <div data-testid="attribute-data-type" className={styles.content}>
            <Controller
              control={control}
              name="data_type"
              rules={{ validate: required }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <>
                  <div className={styles.topPart}>
                    <Controller
                      control={control}
                      name="is_unique"
                      render={({ field: { value, onChange } }) => (
                        <ToggleIconSwitchInput
                          value={value.toString()}
                          onChange={v => onChange(parseInt(v))}
                          name="is_unique"
                          leftValue="1"
                          rightValue="0"
                          width="82px"
                          leftLabel="Single"
                          rightLabel="Multiple"
                          leftIcon={["fas", "dot-circle"]}
                          rightIcon={["fas", "ball-pile"]}
                          disabled={!isCreate || isSystem}
                        />
                      )}
                    />
                    <SelectField
                      input={{
                        value,
                        onChange(newValue: string) {
                          if (value === "compound" && newValue !== "compound") {
                            setValue("_dimensions", [])
                          } else if (value !== "compound" && newValue === "compound") {
                            appendDimension({
                              id: "",
                              name: "",
                              data_type: null,
                            })
                          }

                          onChange(newValue)
                        },
                      }}
                      placeholder="Data type"
                      label="Data type"
                      disabled={!isCreate || isSystem}
                      options={ATTRIBUTE_DATA_TYPES}
                      isSimpleValue
                      error={error?.message}
                    />
                  </div>

                  {value === "compound" && (
                    <div className={styles.dimensionsList}>
                      {dimensionFields.map(({ id }, index) => (
                        <div key={id} className={styles.dimension}>
                          <TextInput
                            {...register(`_dimensions.${index}.id`, {
                              validate: { required, pythonVariable },
                            })}
                            placeholder="Dimension ID"
                            label="Dimension ID"
                            disabled={!isCreate || isSystem}
                            maxLength={80}
                            error={errors._dimensions?.[index]?.id?.message}
                          />
                          <TextInput
                            {...register(`_dimensions.${index}.name`, { validate: required })}
                            placeholder="Name"
                            label="Name"
                            disabled={!isCreate || isSystem}
                            maxLength={60}
                            error={errors._dimensions?.[index]?.name?.message}
                          />
                          <Controller
                            control={control}
                            name={`_dimensions.${index}.data_type`}
                            rules={{ validate: required }}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                              <SelectField
                                input={{ value, onChange }}
                                placeholder="Data type"
                                label="Data type"
                                options={COMPOUND_ATTRIBUTE_DATA_TYPES}
                                disabled={!isCreate || isSystem}
                                isSimpleValue
                                error={error?.message}
                              />
                            )}
                          />
                          {dimensionFields.length > 1 && isCreate && !isSystem && (
                            <IconButton
                              size="xs"
                              color="red"
                              onClick={() => removeDimension(index)}
                              icon="trash-alt"
                              tooltip="Delete"
                              variant="outlined"
                              className={styles.deleteButton}
                            />
                          )}
                        </div>
                      ))}
                      {isCreate && !isSystem && (
                        <Button
                          size="md"
                          onClick={() =>
                            appendDimension({
                              id: "",
                              name: "",
                              data_type: null,
                            })
                          }
                        >
                          + Add dimension
                        </Button>
                      )}
                    </div>
                  )}
                </>
              )}
            />
          </div>
        </div>
        <Controller
          control={control}
          name="_isDefinitionShown"
          render={({ field: { value: isDefinitionShown, onChange: setIsDefinitionShown } }) => (
            <div className={styles.definitionSection}>
              <div className={styles.description}>
                <div data-testid="definition" className={styles.titleWithToggle}>
                  <h2>Definition</h2>
                  <ToggleButton
                    value={isDefinitionShown}
                    handleToggle={() => setIsDefinitionShown(!isDefinitionShown)}
                    disabled={isSystem}
                  />
                </div>
                <p>
                  To learn more:{" "}
                  <a
                    href="https://docs.meiro.io/books/meiro-business-explorer/page/set-attributes"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    User documentation
                  </a>
                </p>
              </div>
              {isDefinitionShown && (
                <div className={styles.content}>
                  <Controller
                    control={control}
                    name="_definitionType"
                    render={({ field: { value: definitionType, onChange: setDefinitionType } }) => (
                      <>
                        <ToggleIconSwitchInput
                          value={definitionType}
                          onChange={type => {
                            if (type === "predefined") {
                              // @ts-ignore
                              setValue("definition.type", null)
                            }
                            setDefinitionType(type)
                          }}
                          name="definitionType"
                          leftValue="predefined"
                          rightValue="custom"
                          width="82px"
                          leftLabel="Predefined"
                          rightLabel="Custom"
                          leftIcon={["far", "list"]}
                          rightIcon={["fas", "pencil-alt"]}
                          className={styles.definitionTypeSwitch}
                          disabled={isSystem}
                        />

                        {definitionType === "custom" && (
                          <Controller
                            control={control}
                            name="definition.query"
                            rules={{ validate: required }}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                              <div
                                className={classNames("ace-editor-wrapper", styles.query, {
                                  error: error,
                                })}
                              >
                                <p className={styles.label}>SQL query</p>
                                <Suspense fallback={<LoadingIndicator />}>
                                  <AceEditor
                                    value={value}
                                    onChange={onChange}
                                    mode="pgsql"
                                    theme="github"
                                    editorProps={{ $blockScrolling: true }}
                                    width="100%"
                                    height="400px"
                                    wrapEnabled={true}
                                    className="ace-editor"
                                    errorMessage={error?.message}
                                    readOnly={isSystem}
                                  />
                                </Suspense>
                              </div>
                            )}
                          />
                        )}

                        {definitionType === "predefined" && (
                          <>
                            <Controller
                              control={control}
                              name="definition.sources"
                              render={({ field: { value, onChange } }) => (
                                <SelectField
                                  input={{ value, onChange }}
                                  placeholder="Select"
                                  label="Event source (optional)"
                                  className={styles.sources}
                                  options={sourceOptions}
                                  isMulti
                                  onChange={updateVersionsAfterSourceChange}
                                  disabled={isSystem}
                                  isSimpleValue
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="definition.types"
                              render={({ field: { value, onChange } }) => (
                                <SelectField
                                  input={{ value, onChange }}
                                  placeholder="Select"
                                  label="Event type (optional)"
                                  className={styles.types}
                                  options={eventTypesForSelect}
                                  isMulti
                                  onChange={updateVersionsAfterEventTypeChange}
                                  disabled={isSystem}
                                  isSimpleValue
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="definition.versions"
                              render={({ field: { value, onChange } }) => (
                                <SelectField
                                  input={{ value, onChange }}
                                  placeholder="Type"
                                  label="Event version (optional)"
                                  className={styles.versions}
                                  options={getAvailableEventVersions(
                                    allEvents,
                                    (definition as PredefinedDefinition).sources,
                                    (definition as PredefinedDefinition).types,
                                  ).map(version => ({ value: version, label: version }))}
                                  isMulti
                                  disabled={isSystem}
                                  isSimpleValue
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="definition.type"
                              rules={{ validate: required }}
                              render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <SelectField
                                  input={{ value, onChange }}
                                  placeholder="Select"
                                  label="Calculation type"
                                  className={styles.type}
                                  options={DEFINITION_CALC_TYPE_OPTIONS}
                                  disabled={isSystem}
                                  isSimpleValue
                                  error={error?.message}
                                />
                              )}
                            />
                            <TextInput
                              {...register("definition.weight")}
                              placeholder="Weight"
                              label="Weight (optional)"
                              className={styles.weight}
                              disabled={isSystem}
                            />
                            <Controller
                              control={control}
                              rules={{ validate: required }}
                              name="definition.value"
                              render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <ExpandableAceEditor
                                  input={{ value, onChange }}
                                  label="Value"
                                  className={styles.value}
                                  disabled={isSystem}
                                  meta={{ touched: true, error: error?.message }}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="definition.outer_value"
                              render={({ field: { value, onChange } }) => (
                                <ExpandableAceEditor
                                  input={{ value, onChange }}
                                  label="Outer value (optional)"
                                  className={styles.outerValue}
                                  disabled={isSystem}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="definition.filter"
                              render={({ field: { value, onChange } }) => (
                                <ExpandableAceEditor
                                  input={{ value, onChange }}
                                  label="Filter (optional)"
                                  className={styles.filter}
                                  disabled={isSystem}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="definition.outer_filter"
                              render={({ field: { value, onChange } }) => (
                                <ExpandableAceEditor
                                  input={{ value, onChange }}
                                  label="Outer filter (optional)"
                                  className={styles.outerFilter}
                                  disabled={isSystem}
                                />
                              )}
                            />
                          </>
                        )}
                      </>
                    )}
                  />
                </div>
              )}
            </div>
          )}
        />
      </Paper>
      <Paper noPadding>
        <div className={styles.testSection}>
          <div className={styles.description}>
            <h2>Attribute definition checker</h2>
          </div>
          <div className={styles.content}>
            <div className={styles.inputs}>
              <TextInput
                {...register("customerEntityId", { validate: { requiredIfTestRun } })}
                label="Customer entity ID"
                placeholder="Customer entity ID"
                error={errors.customerEntityId?.message}
              />
              <Button
                onClick={_ => {
                  if (isDefinitionShown) {
                    setSubmitType("test")
                    setTimeout(
                      handleSubmit(_ => testAttribute()),
                      0,
                    )
                  } else {
                    showToast(
                      "Definition must be enabled to run the attribute definition check.",
                      "TOAST_ERROR",
                    )
                  }
                }}
                loading={testMutation.isLoading}
                size="md"
                className={styles.testRunButton}
              >
                Check
              </Button>
            </div>

            {testData && (
              <div className={styles.globalResults}>
                <div className={styles.statusWrapper}>
                  <div className={styles.label}>Global status</div>
                  <div
                    className={classNames(styles.status, {
                      [styles.error]: hasError,
                    })}
                  >
                    <div className={classNames(styles.iconWrapper, { [styles.error]: hasError })}>
                      <FontAwesomeIcon icon={["fas", hasError ? "times" : "check"]} />
                    </div>
                    {testData.is_valid ? (
                      <div>Results are OK</div>
                    ) : testData.errors ? (
                      <div className={styles.errorsWrapper}>
                        <strong>Errors:</strong>
                        {testData.errors?.map(error => (
                          <div key={error}>{error}</div>
                        ))}
                      </div>
                    ) : (
                      <div>There are invalid values. See table below for details.</div>
                    )}
                  </div>
                </div>
                <div className={styles.execTimeWrapper}>
                  <div className={styles.label}>Execution time</div>
                  <div
                    className={classNames(styles.execTime, {
                      [styles.warning]: testData.execution_time_milli_seconds > 100,
                      [styles.error]: testData.execution_time_milli_seconds > 500,
                    })}
                  >
                    {testData.execution_time_milli_seconds} ms
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {(testData || testMutation.isLoading) && (
          <div className={styles.testResultsSection}>
            {testMutation.isLoading && <LoadingIndicator />}

            {!testMutation.isLoading && (
              <TestResults response={testData!} page={page} setPage={setPage} />
            )}
          </div>
        )}
      </Paper>
    </form>
  )
}

const RESULTS_PER_PAGE = 20

function TestResults({
  response,
  page,
  setPage,
}: {
  response: AttributeTestResponse
  page: number
  setPage: Dispatch<SetStateAction<number>>
}) {
  const [isFiltering, setIsFiltering] = useState(false)

  useEffect(() => {
    setPage(1)
  }, [setPage, isFiltering])

  if (!response.data) {
    return (
      <div className={styles.emptyMessage}>
        No attribute values for the profile entity ID selected.
      </div>
    )
  }

  const filteredData = isFiltering
    ? response.is_compound
      ? response.data.map(row => row.filter(prop("error"))).filter(row => row.length)
      : response.data.filter(prop("error"))
    : response.data

  const index = page - 1
  const maxPage = Math.ceil(filteredData.length / RESULTS_PER_PAGE)
  const dataPage = filteredData.slice(index * RESULTS_PER_PAGE, (index + 1) * RESULTS_PER_PAGE)

  if (response.data.length === 0) {
    return (
      <div className={styles.emptyMessage}>
        No attribute values for the profile entity ID selected.
      </div>
    )
  }

  return (
    <div
      className={classNames(styles.testResultsTable, {
        [styles.withDimensions]: response.is_compound,
      })}
    >
      <div className={styles.header}>
        {response.is_compound && <div>Dimension name</div>}
        <div>Cockroach DB value</div>
        <div>Opensearch value</div>
        <div>Status</div>
        <div className={classNames(styles.toggleWrapper, { [styles.filtering]: isFiltering })}>
          <div>Show only invalid</div>
          <ToggleButton
            value={isFiltering}
            handleToggle={() => setIsFiltering(s => !s)}
            size="xs"
          />
        </div>
      </div>
      {filteredData.length === 0 && <div className={styles.emptyMessage}>No invalid results.</div>}

      {dataPage.map((row, index) => (
        <div key={index} className={styles.row}>
          {Array.isArray(row) ? (
            row.map(subRow => (
              <div key={subRow.dimension.id} className={styles.subRow}>
                <div className={styles.dimensionName}>{subRow.dimension.name}</div>
                <div>{String(subRow.cockroach)}</div>
                <div>{String(subRow.opensearch)}</div>
                <Status error={subRow.error} />
              </div>
            ))
          ) : (
            <div className={styles.subRow}>
              <div>{String(row.cockroach)}</div>
              <div>{String(row.opensearch)}</div>
              <Status error={row.error} />
            </div>
          )}
        </div>
      ))}

      {filteredData.length > 0 && (
        <div className={styles.pagination}>
          <IconButton
            icon="chevron-left"
            variant="transparent"
            onClick={() => setPage(dec)}
            disabled={page === 1}
            color="grey"
          />
          <div>
            {page} / {maxPage}
          </div>
          <IconButton
            icon="chevron-right"
            variant="transparent"
            onClick={() => setPage(inc)}
            disabled={page === maxPage}
            color="grey"
          />
        </div>
      )}
    </div>
  )
}

function Status({ error }: { error: string | null }) {
  return (
    <div className={styles.status}>
      <div className={classNames(styles.iconWrapper, { [styles.error]: error })}>
        <FontAwesomeIcon icon={["fas", error ? "times" : "check"]} />
      </div>
      {error}
    </div>
  )
}

function definitionFromFormValues({
  _isDefinitionShown,
  _definitionType,
  ...values
}: AttributeFormValues): Definition | null {
  let definition: Definition | null = null

  if (_isDefinitionShown) {
    if (_definitionType === "custom") {
      definition = {
        id: values.id,
        type: "custom",
        query: (values.definition as CustomDefinition).query,
      }
    } else {
      definition = {
        id: values.id,
        ...omit(["query"], values.definition),
      } as PredefinedDefinition

      definition.outer_value = definition.outer_value || "value"
      definition.sources = definition.sources ?? []
      definition.types = definition.types ?? []
      definition.versions = definition.versions ?? []
    }
  }

  return definition
}

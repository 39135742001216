import { QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { showToast } from "app/toast"
import { api } from "api"
import { prop, sort, update } from "ramda"
import { useHasAccess } from "resources/user/currentUserQueries"
import { ascend } from "utilities/comparators"
import { PromoCodesList } from "./promoCodesListTypes"

const PROMO_CODES_LIST_ALL_QK: QueryKey = ["promo_codes_list", "all"]

export function useFetchAllPromoCodesLists({ searchTerm }: { searchTerm?: string }) {
  const hasAccess = useHasAccess()
  return useQuery(PROMO_CODES_LIST_ALL_QK, api.promoCodesList.listAll, {
    enabled: hasAccess.webBanners.view,
    select(data) {
      if (searchTerm) {
        data = data.filter(
          ({ id, name }) =>
            name.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
            id.toLowerCase().includes(searchTerm.trim().toLowerCase()),
        )
      }
      // We have to sort on the FE because the BE sort is case-sensitive
      return sort(ascend(prop("name")), data)
    },
  })
}

export function useCreatePromoCodesList() {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ name, file }: { name: string; file: FileList }) => {
      const res = await api.promoCodesList.create({ name })
      const formData = new FormData()
      formData.append("file", file[0])
      try {
        return await api.promoCodesList.uploadFile(res.promo_codes_list.id, formData)
      } catch {
        return res
      }
    },
    {
      onSuccess({ promo_codes_list }) {
        queryClient.setQueryData<PromoCodesList[]>(PROMO_CODES_LIST_ALL_QK, data => {
          return data?.concat(promo_codes_list)
        })
        showToast("Promo codes list created.")
      },
    },
  )
}

export function useUploadFileToPromoCodesList() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, file }: { id: PromoCodesList["id"]; file: FileList }) => {
      const formData = new FormData()
      formData.append("file", file[0])
      return api.promoCodesList.uploadFile(id, formData)
    },
    {
      onSuccess({ promo_codes_list }) {
        queryClient.setQueryData<PromoCodesList[]>(PROMO_CODES_LIST_ALL_QK, data => {
          if (!data) {
            return undefined
          }
          const index = data.findIndex(({ id }) => id === promo_codes_list.id)

          return index === -1
            ? data.concat(promo_codes_list)
            : update(index, promo_codes_list, data)
        })
        showToast("Promo codes added to the list.")
      },
    },
  )
}

export function useRenamePromoCodesList() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, name }: { id: PromoCodesList["id"]; name: string }) =>
      api.promoCodesList.modify(id, { name }),
    {
      onSuccess({ promo_codes_list }) {
        queryClient.setQueryData<PromoCodesList[]>(PROMO_CODES_LIST_ALL_QK, data => {
          if (!data) {
            return undefined
          }
          const index = data.findIndex(({ id }) => id === promo_codes_list.id)

          return index === -1
            ? data.concat(promo_codes_list)
            : update(index, promo_codes_list, data)
        })
        showToast("Promo codes list renamed.")
      },
    },
  )
}

export function useDeletePromoCodesList() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: PromoCodesList["id"] }) => api.promoCodesList.delete(id), {
    onSuccess(_, { id }) {
      queryClient.setQueryData<PromoCodesList[]>(PROMO_CODES_LIST_ALL_QK, data =>
        data?.filter(list => list.id !== id),
      )
      showToast("Promo codes list deleted.")
    },
  })
}

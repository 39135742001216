import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Canceler, Method } from "axios"
import { showToast, useToast } from "app/toast"
import { BASE_URL, TOAST } from "sharedConstants"
import { history } from "app/App"
import { getRoutePath } from "routes"
import { getAuthToken, logout } from "auth/auth"
import { displayErrorMessage, ErrorData } from "./utils"

export const BASE_API_URL = new URL("api", BASE_URL).toString()

const DISCONNECTED_MESSAGE =
  "There was a connection error. Either you are offline, or the request you are trying to perform is too large."
const RETRY_ATTEMPTS = 5

axios.defaults.baseURL = BASE_API_URL
axios.defaults.withCredentials = true
axios.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json",
}

const CancelToken = axios.CancelToken

type Cancelable = { cancel: Canceler; ref: number }
type CancelablesRecord = Record<string, Cancelable>
export const cancelable: CancelablesRecord = {}

const responseSuccessHandler = (response: AxiosResponse) => response.data || {}

const responseErrorHandler =
  (preventNotFoundRedirect: boolean, hideErrorNotification: boolean) =>
  (error: AxiosError & { response?: { data?: ErrorData } }) => {
    // don't throw error on cancel request, we don't want to show error messages in UX
    if (!axios.isCancel(error) && !hideErrorNotification) {
      const { response } = error
      if (response === undefined) {
        // network problem
        const previousMessage = useToast.getState().toast?.message
        if (previousMessage === DISCONNECTED_MESSAGE) {
          const previousTimestamp = useToast.getState().toast?.timestamp
          if (!previousTimestamp || Date.now() - previousTimestamp > 6000) {
            showToast(
              DISCONNECTED_MESSAGE,
              TOAST.TYPE.ERROR,
              undefined,
              false,
              undefined,
              undefined,
              "network_err",
            )
          }
        } else {
          showToast(
            DISCONNECTED_MESSAGE,
            TOAST.TYPE.ERROR,
            undefined,
            false,
            undefined,
            undefined,
            "network_err",
          )
        }
      }
      const status = response?.status
      if (status === 403) {
        history.push(getRoutePath("not-authorized"))
      } else if (status === 404 && !preventNotFoundRedirect) {
        history.push(getRoutePath("not-found"))
      } else {
        displayErrorMessage(error.response?.data)
        if (status === 401) {
          logout()
        }
      }
    }
    throw error
  }

export default function requestFactory(
  method: Method,
  url: string,
  body = {},
  preventNotFoundRedirect = false,
  useToken = true,
  ownToken = "",
  retryOnTimeout = false,
  hideErrorNotification = false,
  additionalHeaders: { [key: string]: any } | null = null,
) {
  const refCounter = (cancelableObj?: Cancelable) => {
    const ref = cancelableObj?.ref
    if (typeof ref === "number") {
      return (ref + 1) % 3
    }
    return 0
  }

  const apiCall = <T = any>(
    url: string,
    config: AxiosRequestConfig,
    ref = 0,
    count = 0,
  ): Promise<T> => {
    return axios(url, config)
      .then(async response => {
        if (config.method === "get") {
          if (cancelable[url]?.ref === ref) {
            delete cancelable[url]
          }
        }
        return responseSuccessHandler(response)
      })
      .catch(error => {
        // retry only network error
        const { response } = error
        if (response === undefined) {
          if (retryOnTimeout && count < RETRY_ATTEMPTS && !axios.isCancel(error)) {
            count++
            return apiCall(url, config, ref, count)
          }
        }
        if (config.method === "get") {
          if (cancelable[url]?.ref === ref) {
            delete cancelable[url]
          }
        }
        return responseErrorHandler(preventNotFoundRedirect, hideErrorNotification)(error)
      })
  }

  const config: AxiosRequestConfig = {
    headers: additionalHeaders ? additionalHeaders : {},
  }

  if (useToken) {
    config.headers["x-access-token"] = ownToken || getAuthToken()
  }

  config.method = method.toString().toLowerCase() as Method
  if (Object.keys(body).length > 0) {
    if (config.method === "get") {
      config.params = body
    }
  }
  if (["post", "patch", "put"].includes(config.method)) {
    config.data = body
  }
  const ref = refCounter(cancelable[url])
  if (config.method === "get") {
    config.cancelToken = new CancelToken(function executor(c) {
      // store some reference value, because another "same call" can be faster
      // than cancel + delete, so it can remove ongoing call cancel token
      cancelable[url] = {
        cancel: c,
        ref,
      }
    })
  }

  return apiCall(url, config, ref)
}

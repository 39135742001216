import React, { useState } from "react"
import styles from "./WaitSlotNodeForm.module.scss"
import { WaitSlotNodeType, WaitSlotNodeSettings } from "resources/journey/journeyTypes"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import NodeFormLayout from "../../components/NodeFormLayout/NodeFormLayout"
import SelectField from "components/UI/elements/SelectField"
import tzdata from "tzdata"
import { ascend, identity, sort } from "ramda"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { SelectOption } from "types/util"
import Button from "components/UI/elements/Button/Button"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import classNames from "classnames"
import { getDescription } from "./getDescription"
import { formValuesToSettings, WaitSlotNodeFormValues } from "./formValues"
import WaitSlotNodeCard from "./WaitSlotNodeCard"

type WaitSlotNodeFormProps = {
  node?: WaitSlotNodeType
  onSubmit: (data: WaitSlotNodeSettings) => void
  onClose: () => void
  isSubmitting: boolean
  isEditable: boolean
}

const getNewSlot = () => ({
  every: 1,
  from: "00:00:00",
  to: "00:00:00",
})

export default function WaitSlotNodeForm({
  node,
  onClose,
  onSubmit,
  isSubmitting,
  isEditable,
}: WaitSlotNodeFormProps) {
  const defaultValues = node?.settings ?? {
    frequency: "daily",
    timezone: currentTimezone,
    slots: [getNewSlot()],
  }
  const { handleSubmit, watch, control, setValue } = useForm<WaitSlotNodeFormValues>({
    defaultValues,
  })
  const { append, remove, fields } = useFieldArray({ control, name: "slots" })

  const [isCurrentTimezone, setIsCurrentTimezone] = useState(
    defaultValues.timezone === currentTimezone,
  )

  function submit(values: WaitSlotNodeFormValues) {
    onSubmit(formValuesToSettings(values))
  }

  const values = watch()

  return (
    <NodeFormLayout
      previewCard={<WaitSlotNodeCard description={getDescription(formValuesToSettings(values))} />}
      onClose={onClose}
      onSubmit={handleSubmit(submit)}
      isSubmitting={isSubmitting}
      isEditable={isEditable}
    >
      <div className={styles.content}>
        <Checkbox
          checked={isCurrentTimezone}
          onChange={e => {
            setIsCurrentTimezone(e.target.checked)
            if (e.target.checked) {
              setValue("timezone", currentTimezone)
            }
          }}
          label={`Use your local timezone (${currentTimezone})`}
          disabled={!isEditable}
          className={styles.timezoneCheckbox}
        />

        <div className={styles.inputRow}>
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => (
              <SelectField
                input={{
                  value: field.value,
                  onChange(value: string) {
                    field.onChange(value)
                    fields.forEach((_, index) => {
                      setValue(`slots.${index}.every`, 1)
                    })
                  },
                }}
                label="Frequency"
                options={[
                  { label: "Daily", value: "daily" },
                  { label: "Weekly", value: "weekly" },
                  { label: "Monthly", value: "monthly" },
                ]}
                disabled={!isEditable}
                isSimpleValue
              />
            )}
          />

          <Controller
            name="timezone"
            control={control}
            render={({ field }) => (
              <SelectField
                input={field}
                label="Timezone"
                options={timezoneOptions}
                disabled={isCurrentTimezone || !isEditable}
                isSimpleValue
                className={styles.timezoneSelect}
              />
            )}
          />
        </div>

        {fields.map((slot, index) => (
          <div key={slot.id} className={styles.slot}>
            <Controller
              name={`slots.${index}.from`}
              control={control}
              render={({ field }) => (
                <SelectField
                  input={field}
                  label="From"
                  disabled={!isEditable}
                  isSimpleValue
                  isSearchable={false}
                  options={timeslotOptions}
                />
              )}
            />

            <Controller
              name={`slots.${index}.to`}
              control={control}
              render={({ field }) => (
                <SelectField
                  input={field}
                  label="To"
                  disabled={!isEditable}
                  isSimpleValue
                  isSearchable={false}
                  options={timeslotOptions}
                />
              )}
            />

            {values.frequency === "weekly" && (
              <Controller
                name={`slots.${index}.every`}
                control={control}
                render={({ field }) => (
                  <SelectField
                    input={field}
                    label="Every"
                    disabled={!isEditable}
                    isSimpleValue
                    isSearchable={false}
                    options={dayOfWeekOptions}
                  />
                )}
              />
            )}

            {values.frequency === "monthly" && (
              <div>
                <div className={styles.label}>Day of month</div>
                <div className={styles.dayOfMonthSelectWrapper}>
                  <Controller
                    name={`slots.${index}.every`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <SelectField
                          input={{ ...field, value: field.value === -1 ? "" : field.value }}
                          options={dayOfMonthOptions}
                          isSimpleValue
                          isSearchable={false}
                          disabled={field.value === -1 || !isEditable}
                          className={styles.dayOfMonthSelect}
                        />
                        <div>or</div>
                        <ToggleButton
                          value={field.value === -1}
                          handleToggle={() => {
                            field.onChange(field.value === -1 ? 1 : -1)
                          }}
                          size="xs"
                        />
                        <div
                          className={classNames(styles.lastDayLabel, {
                            [styles.selected]: field.value === -1,
                          })}
                        >
                          last day of month
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            )}

            {isEditable && fields.length > 1 && (
              <IconButton
                icon={["fas", "trash-alt"]}
                onClick={() => remove(index)}
                variant="outlined"
                color="red"
                size="xs"
                className={styles.removeButton}
              />
            )}
          </div>
        ))}

        {isEditable && (
          <Button
            onClick={() => append(getNewSlot())}
            variant="outlined"
            color="grey"
            className={styles.addSlotButton}
            icon={["far", "plus"]}
          >
            Add slot
          </Button>
        )}
      </div>
    </NodeFormLayout>
  )
}

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const timezoneOptions = sort(ascend(identity), Object.keys(tzdata.zones)).map(zone => ({
  label: zone,
  value: zone,
}))

const timeslotOptions: SelectOption<string>[] = [
  { label: "00:00", value: "00:00:00" },
  { label: "00:30", value: "00:30:00" },
  { label: "01:00", value: "01:00:00" },
  { label: "01:30", value: "01:30:00" },
  { label: "02:00", value: "02:00:00" },
  { label: "02:30", value: "02:30:00" },
  { label: "03:00", value: "03:00:00" },
  { label: "03:30", value: "03:30:00" },
  { label: "04:00", value: "04:00:00" },
  { label: "04:30", value: "04:30:00" },
  { label: "05:00", value: "05:00:00" },
  { label: "05:30", value: "05:30:00" },
  { label: "06:00", value: "06:00:00" },
  { label: "06:30", value: "06:30:00" },
  { label: "07:00", value: "07:00:00" },
  { label: "07:30", value: "07:30:00" },
  { label: "08:00", value: "08:00:00" },
  { label: "08:30", value: "08:30:00" },
  { label: "09:00", value: "09:00:00" },
  { label: "09:30", value: "09:30:00" },
  { label: "10:00", value: "10:00:00" },
  { label: "10:30", value: "10:30:00" },
  { label: "11:00", value: "11:00:00" },
  { label: "11:30", value: "11:30:00" },
  { label: "12:00", value: "12:00:00" },
  { label: "12:30", value: "12:30:00" },
  { label: "13:00", value: "13:00:00" },
  { label: "13:30", value: "13:30:00" },
  { label: "14:00", value: "14:00:00" },
  { label: "14:30", value: "14:30:00" },
  { label: "15:00", value: "15:00:00" },
  { label: "15:30", value: "15:30:00" },
  { label: "16:00", value: "16:00:00" },
  { label: "16:30", value: "16:30:00" },
  { label: "17:00", value: "17:00:00" },
  { label: "17:30", value: "17:30:00" },
  { label: "18:00", value: "18:00:00" },
  { label: "18:30", value: "18:30:00" },
  { label: "19:00", value: "19:00:00" },
  { label: "19:30", value: "19:30:00" },
  { label: "20:00", value: "20:00:00" },
  { label: "20:30", value: "20:30:00" },
  { label: "21:00", value: "21:00:00" },
  { label: "21:30", value: "21:30:00" },
  { label: "22:00", value: "22:00:00" },
  { label: "22:30", value: "22:30:00" },
  { label: "23:00", value: "23:00:00" },
  { label: "23:30", value: "23:30:00" },
]

const dayOfWeekOptions: SelectOption<number>[] = [
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 7 },
]

const dayOfMonthOptions: SelectOption<number>[] = Array.from({ length: 31 }, (_, i) => i + 1).map(
  day => ({ label: day.toString(), value: day }),
)

import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { useHasAccess } from "resources/user/currentUserQueries"
import { getRoutePath } from "routes"
import Dashboard from "./Dashboard/Dashboard"
import Insights from "./Insights/Insights"
import ReportingPage from "./Reporting/Reporting"
import Funnels from "./Funnels/FunnelsRoutes"

export default function Analytics() {
  const hasAccess = useHasAccess()

  function redirectToAllowedAnalyticsTab() {
    if (hasAccess.data.dashboard) {
      return <Redirect to={getRoutePath("analytics.dashboard")} />
    }
    if (hasAccess.data.insights) {
      return <Redirect to={getRoutePath("analytics.insights")} />
    }
    if (hasAccess.reports) {
      return <Redirect to={getRoutePath("analytics.reporting")} />
    }
    return <Redirect to={getRoutePath("analytics.funnels")} />
  }

  return (
    <Switch>
      <Route path={getRoutePath("analytics")} exact render={redirectToAllowedAnalyticsTab} />
      <AuthorizedRoute
        hasAccess={hasAccess.data.dashboard}
        path={getRoutePath("analytics.dashboard")}
        component={Dashboard}
      />
      <Route path={getRoutePath("analytics.funnels")} component={Funnels} />
      <AuthorizedRoute
        hasAccess={hasAccess.data.insights}
        path={getRoutePath("analytics.insights")}
        component={Insights}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.reports}
        path={getRoutePath("analytics.reporting")}
        component={ReportingPage}
      />
    </Switch>
  )
}

import Paper from "components/UI/elements/Paper"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Table, { Thead, Th, Tbody, Td, Tr, RowMessage } from "components/UI/elements/Table"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import { format } from "date-fns"
import { DATE_FMT } from "sharedConstants"
import Username from "components/Username/Username"
import styles from "./ChannelsList.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  useFetchEmailChannel,
  useFetchPushNotificationApps,
  useFetchFirebaseProjects,
  useFetchPushNotificationsChannel,
} from "resources/channel/channelQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Page from "components/UI/Page/Page"
import { featureFlags } from "featureFlags"
import { ISOwoTZtoDate } from "utilities/date"
import Tippy from "@tippyjs/react"
import { useMemo } from "react"
import { useFetchEmailSenders } from "resources/channel/emailSenders/emailSendersQueries"
import { useFetchSystemInfo } from "resources/systemInfo/systemInfoQueries"

export default function ChannelsList() {
  const { data: pushNotificationsChannel, isLoading: isLoadingPushNotificationsChannel } =
    useFetchPushNotificationsChannel()
  const { data: firebaseProjects = [], isLoading: isLoadingFirebaseProjects } =
    useFetchFirebaseProjects()
  const { data: applications = [], isLoading: isLoadingApplications } =
    useFetchPushNotificationApps()

  const { data: emailsChannel, isLoading: isLoadingEmails } = useFetchEmailChannel()
  const { data: senders = [], isLoading: isLoadingSenders } = useFetchEmailSenders()
  const { data: systemInfo, isLoading: isLoadingSystemInfo } = useFetchSystemInfo()

  const pushNotificationsChannelIncompleteTooltip = useMemo(() => {
    if (!pushNotificationsChannel || pushNotificationsChannel.is_active) return ""

    const missing: Array<string> = []
    if (firebaseProjects.length === 0) missing.push("firebase project configuration")
    if (applications.length === 0) missing.push("application")
    if (!pushNotificationsChannel.stitching_category_id) missing.push("stitching category")

    return `Setup incomplete. Missing ${missing.join(", ")}.`
  }, [pushNotificationsChannel, firebaseProjects.length, applications.length])

  const emailsChannelIncompleteTooltip = useMemo(() => {
    if (!emailsChannel || emailsChannel.is_active) return ""

    const missing: Array<string> = []
    if (!emailsChannel.provider_config) missing.push("email provider config")
    if (!emailsChannel.emails_stitching_category_id) missing.push("email stitching category")
    if (senders.length === 0) missing.push("verified sender")
    if (!emailsChannel.opt_in_email_config) missing.push("opt-in")
    if (!emailsChannel.email_unsubscribe_block) missing.push("unsubscribe block")

    return `Setup incomplete. Missing ${missing.join(", ")}.`
  }, [emailsChannel, senders])

  const isLoading =
    isLoadingPushNotificationsChannel ||
    isLoadingFirebaseProjects ||
    isLoadingApplications ||
    isLoadingEmails ||
    isLoadingSenders ||
    isLoadingSystemInfo
  const meNotConfigured = !systemInfo?.me_connection_url

  return (
    <Page title="Channels">
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <Paper hasHeader noPaddingTop={!!pushNotificationsChannel || !!emailsChannel}>
          {!pushNotificationsChannel && !emailsChannel ? (
            <RowMessage>No channels found.</RowMessage>
          ) : (
            <Table>
              <Thead stickyHeader>
                <Th>Name</Th>
                <Th>Is active</Th>
                <Th>Last modified</Th>
                <Th> </Th>
              </Thead>
              <Tbody>
                {pushNotificationsChannel && (
                  <Tr>
                    <Td textBold textBigger textBlack>
                      Mobile Push
                    </Td>
                    <Td>
                      {pushNotificationsChannel.is_active ? (
                        <FontAwesomeIcon
                          className={styles.yesIcon}
                          icon={["fas", "circle-check"]}
                        />
                      ) : (
                        <Tippy
                          content={pushNotificationsChannelIncompleteTooltip}
                          placement="bottom"
                        >
                          <FontAwesomeIcon
                            className={styles.noIcon}
                            icon={["fas", "triangle-exclamation"]}
                          />
                        </Tippy>
                      )}
                    </Td>
                    <Td>
                      {pushNotificationsChannel.modified && (
                        <span className={styles.modifiedAt}>
                          {format(
                            ISOwoTZtoDate(pushNotificationsChannel.modified),
                            DATE_FMT.DATETIME,
                          )}
                        </span>
                      )}
                      {pushNotificationsChannel.modified_by && (
                        <span className={styles.modifiedBy}>
                          by <Username userId={pushNotificationsChannel.modified_by} />
                        </span>
                      )}
                    </Td>
                    <Td textAlignRight>
                      <Link to={getRoutePath("administration.channels.push-notifications")}>
                        <IconButton
                          data-testid="edit-mp-channel"
                          disabled={meNotConfigured}
                          color="black"
                          size="xs"
                          icon={["fas", "pencil-alt"]}
                          tooltip={meNotConfigured ? "ME is not configured." : "Edit"}
                          variant="outlined"
                        />
                      </Link>
                    </Td>
                  </Tr>
                )}
                {emailsChannel && featureFlags.EMAILS && (
                  <Tr>
                    <Td textBold textBigger textBlack>
                      Email
                    </Td>
                    <Td>
                      {emailsChannel.is_active ? (
                        <FontAwesomeIcon
                          className={styles.yesIcon}
                          icon={["fas", "circle-check"]}
                        />
                      ) : (
                        <Tippy content={emailsChannelIncompleteTooltip} placement="bottom">
                          <FontAwesomeIcon
                            className={styles.noIcon}
                            icon={["fas", "triangle-exclamation"]}
                          />
                        </Tippy>
                      )}
                    </Td>
                    <Td>
                      {emailsChannel.modified && (
                        <span className={styles.modifiedAt}>
                          {format(ISOwoTZtoDate(emailsChannel.modified), DATE_FMT.DATETIME)}
                        </span>
                      )}
                      {emailsChannel.modified_by && (
                        <span className={styles.modifiedBy}>
                          by <Username userId={emailsChannel.modified_by} />
                        </span>
                      )}
                    </Td>
                    <Td textAlignRight>
                      <Link to={getRoutePath("administration.channels.emails")}>
                        <IconButton
                          data-testid="edit-email-channel"
                          disabled={meNotConfigured}
                          color="black"
                          size="xs"
                          icon={["fas", "pencil-alt"]}
                          tooltip={meNotConfigured ? "ME is not configured." : "Edit"}
                          variant="outlined"
                        />
                      </Link>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          )}
        </Paper>
      )}
    </Page>
  )
}

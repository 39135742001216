export const categoryLabel = (categoryId: string) => categories[categoryId]?.label ?? categoryId

export const categoryColor = (categoryId: string) =>
  categories[categoryId]?.color ?? DEFAULT_BASE_COLOR

export const subcategoryLabel = (subcategoryId: string) => {
  const [categoryId, rest] = subcategoryId.split("/")
  return rest === "total"
    ? "All interactions"
    : categories[categoryId]?.subcategoryLabels[rest] ?? rest
}

type Category = {
  label: string
  color: string
  subcategoryLabels: Record<string, string>
}

const colors = {
  login: "#FE7F66",
  customers: "#FBB962",
  segments: "#739943",
  data: "#19309F",
  reports: "#B85888",
  personalization: "#4A6D7F",
}

const DEFAULT_BASE_COLOR = "#19309F"

const categories: Record<string, Category> = {
  login: {
    label: "Number of logins",
    color: colors.login,
    subcategoryLabels: {
      login: "All",
    },
  },
  customers: {
    label: "Profiles tab",
    color: colors.customers,
    subcategoryLabels: {
      view: "Viewed",
      search: "Searched",
    },
  },
  customers_profile: {
    label: "Customer profiles",
    color: colors.customers,
    subcategoryLabels: {
      attributes_view: "Viewed attributes subtab",
      timeline_view: "Viewed timeline subtab",
      identity_view: "Viewed identity subtab",
    },
  },
  custom_segments: {
    label: "Custom segments tab",
    color: colors.segments,
    subcategoryLabels: {
      view: "Viewed list tab",
      my_view: "Viewed 'my segments'",
      shared_view: "Viewed 'shared with me'",
      foreign_view: "Viewed 'others'",
      detail_view: "Viewed detail tab",
      restore: "Restored",
      create: "Created",
      copy: "Copied",
      delete: "Deleted",
      edit: "Edited",
      exports_view: "Viewed exports subtab",
      exports_create: "Exported",
    },
  },
  featured_segments: {
    label: "Featured segments tab",
    color: colors.segments,
    subcategoryLabels: {
      view: "Viewed list tab",
      detail_view: "Viewed detail tab",
      restore: "Restored",
      create: "Created",
      copy: "Copied",
      delete: "Deleted",
      edit: "Edited",
      exports_view: "Viewed exports subtab",
      exports_create: "Exported",
    },
  },
  smart_segments: {
    label: "Smart segments tab",
    color: colors.segments,
    subcategoryLabels: {
      view: "Viewed list tab",
      detail_view: "Viewed detail tab",
      restore: "Restored",
      create: "Created",
      copy: "Copied",
      delete: "Deleted",
      edit: "Edited",
      exports_view: "Viewed exports subtab",
      exports_create: "Exported",
    },
  },
  lookalike_segments: {
    label: "Lookalike segments tab",
    color: colors.segments,
    subcategoryLabels: {
      view: "Viewed list tab",
      detail_view: "Viewed detail tab",
      restore: "Restored",
      create: "Created",
      copy: "Copied",
      delete: "Deleted",
      edit: "Edited",
      exports_view: "Viewed exports subtab",
      exports_create: "Exported",
    },
  },
  diagnostic_dashboard: {
    label: "Data tab",
    color: colors.data,
    subcategoryLabels: {
      view: "Viewed diagnostic dashboard",
      insights_view: "Viewed insights",
      attributes_view: "Viewed attributes",
      events_view: "Viewed events",
      sources_destinations_view: "Viewed sources & destinations",
    },
  },
  reports: {
    label: "Reporting tab",
    color: colors.reports,
    subcategoryLabels: {
      view: "Viewed list tab",
      detail_view: "Viewed detail tab",
      create: "Created",
      edit: "Edited",
      delete: "Deleted",
    },
  },
  popup_web_banners: {
    label: "Pop-up banners tab",
    color: colors.personalization,
    subcategoryLabels: {
      view: "Viewed list tab",
      detail_view: "Viewed detail tab",
      create: "Created",
      edit: "Edited",
      copy: "Copied",
      delete: "Deleted",
    },
  },
  embedded_web_banners: {
    label: "Native banners tab",
    color: colors.personalization,
    subcategoryLabels: {
      view: "Viewed list tab",
      detail_view: "Viewed detail tab",
      create: "Created",
      edit: "Edited",
      copy: "Copied",
      delete: "Deleted",
    },
  },
  promo_codes: {
    label: "Promo codes tab",
    color: colors.personalization,
    subcategoryLabels: {
      view: "Viewed list tab",
      detail_view: "Viewed detail tab",
      create: "Created",
      edit: "Edited",
      delete: "Deleted",
    },
  },
}

import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import Trash from "pages/Trash/Trash"
import FunnelGroupsList from "./FunnelGroupsList/FunnelGroupsList"
import FunnelGroupCreate from "./FunnelGroupCreate/FunnelGroupCreate"
import FunnelGroupDetail from "./FunnelGroupDetail/FunnelGroupDetail"
import {
  useFetchFunnelGroupTrashItems,
  useRestoreFunnelGroup,
} from "resources/funnelGroup/funnelGroupQueries"

export default function FunnelGroups() {
  return (
    <Switch>
      <Route
        path={getRoutePath("administration.funnel-groups")}
        exact
        component={FunnelGroupsList}
      />
      <Route
        path={getRoutePath("administration.funnel-groups.trash")}
        render={() => (
          <Trash
            backRoute={getRoutePath("administration.funnel-groups")}
            itemType="funnel_groups"
            fetchQuery={useFetchFunnelGroupTrashItems}
            restoreMutation={useRestoreFunnelGroup}
          />
        )}
      />
      <Route
        path={getRoutePath("administration.funnel-groups.create")}
        component={FunnelGroupCreate}
      />
      <Route
        path={getRoutePath("administration.funnel-groups.detail")}
        component={FunnelGroupDetail}
      />
    </Switch>
  )
}

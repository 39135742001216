import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Select from "react-select"

import {
  ADMIN_ROLE_ID,
  EMAILS_CHANNEL_NAME,
  MODAL,
  PUSH_NOTIFICATIONS_CHANNEL_NAME,
  TOAST,
} from "sharedConstants"

import Avatar from "components/UI/elements/Avatar"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import PasswordChangeForm from "./PasswordChangeForm"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { selectStyles } from "helpers/customSelectStyle.helper"
import { DropdownIndicator } from "components/UI/elements/SelectField"

import "./UserSettings.scss"
import { getRoutePath } from "routes"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import { getCurrentUserId, useAuthTokenStore } from "auth/auth"
import Page from "components/UI/Page/Page"
import { allFeatures, featuresSections, getFeatureTagLabel } from "resources/userRole/features"
import { useFetchDestinationsMap } from "resources/exportDestination/exportDestinationQueries"
import { showToast } from "app/toast"
import EditableValue from "components/UI/components/EditableValue/EditableValue"
import { email, required } from "helpers/validators.helper"
import { take, takeLast, whereEq } from "ramda"
import { useFetchChannels } from "resources/channel/channelQueries"

class UserSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      passwordEditMode: false,
      deleteModalOpen: false,
      isSavingPassword: false,
    }
  }

  deleteUser = () => {
    const {
      deleteUser,
      history,
      match: {
        params: { id },
      },
    } = this.props
    deleteUser(id)
      .then(() => {
        history.push(getRoutePath("administration.users"))
      })
      .catch(() => {})
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModalOpen: !prevState.deleteModalOpen,
    }))
  }

  togglePasswordEditMode = () => {
    this.setState(prevState => ({
      passwordEditMode: !prevState.passwordEditMode,
    }))
  }

  savePassword = async values => {
    const { modifyUser, user } = this.props
    this.setState({ isSavingPassword: true })
    try {
      await modifyUser(user.id, { password: values.password })
      this.setState({ passwordEditMode: false })
    } catch {
    } finally {
      this.setState({ isSavingPassword: false })
    }
  }

  saveName = async value => {
    const { modifyUser, user } = this.props
    if (!value || (typeof value === "string" && !value.trim())) {
      showToast("Please fill in the name", TOAST.TYPE.ERROR)
      return false
    }
    try {
      await modifyUser(user.id, { name: value })
      return true
    } catch (err) {
      if (err?.response?.data?.name) {
        showToast(err.response.data.name.join("\r\n"), TOAST.TYPE.ERROR)
      }
      return false
    }
  }

  saveEmail = async value => {
    const { modifyUser, user } = this.props
    if (!value || (typeof value === "string" && !value.trim())) {
      showToast("Please fill in the email", TOAST.TYPE.ERROR)
      return false
    }
    try {
      await modifyUser(user.id, { email: value })
      return true
    } catch (err) {
      if (err?.response?.data?.email) {
        showToast(err.response.data.email.join("\r\n"), TOAST.TYPE.ERROR)
      }
      return false
    }
  }

  toggleEmailNotifications = async () => {
    const { modifyUser, user } = this.props
    const isEnabled = user.email_notifications_enabled
    try {
      await modifyUser(user.id, { email_notifications_enabled: !isEnabled })
    } catch {}
  }

  toggleAutomated = async () => {
    const { modifyUser, user } = this.props
    try {
      await modifyUser(user.id, { automated: !user.automated })
    } catch {}
  }

  renderRoleFeaturesSection = () => {
    const { user, destinationsMap, channels } = this.props
    const features = user.role.id === ADMIN_ROLE_ID ? allFeatures : user.role.features
    const areEmailsActive = channels?.find(whereEq({ name: EMAILS_CHANNEL_NAME }))?.is_active
    const areMobilePushesActive = channels?.find(
      whereEq({ name: PUSH_NOTIFICATIONS_CHANNEL_NAME }),
    )?.is_active

    let filteredFeaturesSections = featuresSections

    if (!areEmailsActive) {
      filteredFeaturesSections = filteredFeaturesSections.map(section => ({
        ...section,
        items: section.items.filter(({ feature }) => !feature.startsWith("emails/")),
      }))
    }

    if (!areMobilePushesActive) {
      filteredFeaturesSections = filteredFeaturesSections.map(section => ({
        ...section,
        items: section.items.filter(({ feature }) => !feature.startsWith("push_notifications/")),
      }))
    }

    const allowedFeatures = [],
      notAllowedFeatures = []

    const evalFeatures = items => {
      items.forEach(({ feature, tagLabel }) => {
        if (features.includes(feature)) {
          allowedFeatures.push(tagLabel)
        } else {
          notAllowedFeatures.push(tagLabel)
        }
      })
    }

    // Sorry, this is brittle. This is a case where immutable.js OrderedMap is actually kinda useful

    evalFeatures(filteredFeaturesSections[0].items) // Profiles
    evalFeatures(filteredFeaturesSections[1].items) // Segments

    features.forEach(feature => {
      if (feature.startsWith("segments/export/")) {
        allowedFeatures.push(getFeatureTagLabel(feature, destinationsMap))
      }
    })

    filteredFeaturesSections.slice(2).map(({ items }) => evalFeatures(items)) // Rest of the sections

    return (
      <div className="role-features-section">
        {allowedFeatures.length > 0 && (
          <div className="row allowed">
            <h4>Permissions:</h4>
            <div className="permissions">
              <div className="col">
                {take(Math.ceil(allowedFeatures.length / 2), allowedFeatures).map(val => (
                  <p key={val}>
                    <FontAwesomeIcon className="icon" icon={["fas", "check"]} /> {val}
                  </p>
                ))}
              </div>
              <div className="col">
                {takeLast(Math.floor(allowedFeatures.length / 2), allowedFeatures).map(val => (
                  <p key={val}>
                    <FontAwesomeIcon className="icon" icon={["fas", "check"]} /> {val}
                  </p>
                ))}
              </div>
            </div>
          </div>
        )}
        {allowedFeatures.length > 0 && notAllowedFeatures.length > 0 && (
          <div className="delimiter" />
        )}
        {notAllowedFeatures.length > 0 && (
          <div className="row">
            <h4>Without access:</h4>
            <div className="permissions">
              <div className="col">
                {take(Math.ceil(notAllowedFeatures.length / 2), notAllowedFeatures).map(val => (
                  <p key={val}>
                    <FontAwesomeIcon className="icon" icon={["fas", "times"]} /> {val}
                  </p>
                ))}
              </div>
              <div className="col">
                {takeLast(Math.floor(notAllowedFeatures.length / 2), notAllowedFeatures).map(
                  val => (
                    <p key={val}>
                      <FontAwesomeIcon className="icon" icon={["fas", "times"]} /> {val}
                    </p>
                  ),
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  changeUserRole = obj => {
    const { modifyUser, user } = this.props

    modifyUser(user.id, { role_id: obj.value }).catch(() => {})
  }

  render() {
    const { user, editablePassword, deletableUser, editableRole, roles, isMe, showBackButton } =
      this.props
    const { passwordEditMode, deleteModalOpen, isSavingPassword } = this.state

    const actionButtons = (
      <div className="action-buttons">
        {deletableUser && (
          <Button color="red" icon="trash-alt" variant="outlined" onClick={this.toggleDeleteModal}>
            Delete user
          </Button>
        )}
        {editablePassword && (
          <React.Fragment>
            {passwordEditMode && (
              <React.Fragment>
                <Button color="grey" variant="outlined" onClick={this.togglePasswordEditMode}>
                  Cancel
                </Button>
                <Button
                  icon="check"
                  type="submit"
                  form="passwordChangeForm"
                  loading={isSavingPassword}
                >
                  Confirm password
                </Button>
              </React.Fragment>
            )}
            {!passwordEditMode && (
              <Button icon="lock-alt" onClick={this.togglePasswordEditMode}>
                Change password
              </Button>
            )}
          </React.Fragment>
        )}
      </div>
    )

    return (
      <Page
        className="user-settings"
        title="User settings"
        headerContent={
          <div className="header-content">
            {isMe && (
              <>
                <div className="email-notif-toggle-label">Receive notifications by email</div>
                <ToggleButton
                  value={user.email_notifications_enabled}
                  handleToggle={this.toggleEmailNotifications}
                  size="sm"
                />
              </>
            )}
            {actionButtons}
          </div>
        }
        backRouteFallback={showBackButton ? getRoutePath("administration.users") : undefined}
      >
        <>
          <Paper className="user-settings-content">
            <div className="avatar">
              <Avatar
                className="gravatar-image"
                name={user.name}
                email={user.email}
                gravatarSize={200}
              />
            </div>
            {!passwordEditMode && (
              <div className="user-info">
                <div className="box">
                  <label className="field-label">Name</label>
                  <EditableValue
                    initValue={user.name}
                    onChange={this.saveName}
                    validate={required}
                  />
                </div>
                <div className="box">
                  <label className="field-label">Email</label>
                  <EditableValue
                    initValue={user.email}
                    onChange={this.saveEmail}
                    validate={{ email, required }}
                  />
                </div>
                <div className="box">
                  <label className="field-label">Role</label>
                  {!editableRole && <span className="role-name">{user.role.name}</span>}
                  {editableRole && (
                    <Select
                      value={roles ? roles.find(role => role.value === user.role.id) : null}
                      onChange={this.changeUserRole}
                      options={roles === null ? [] : roles}
                      styles={selectStyles()}
                      simpleValue
                      isSearchable={true}
                      isLoading={roles === null}
                      className="select-input"
                      components={{
                        DropdownIndicator: DropdownIndicator,
                      }}
                      classNamePrefix="role_select"
                      noOptionsMessage={() => "Empty"}
                    />
                  )}
                </div>
                <div className="box">
                  <label className="field-label">
                    System user{" "}
                    <InfoTooltip>
                      System users are not included in the Users Activity tab reports.
                    </InfoTooltip>
                  </label>
                  <div className="automated-toggle-wrapper">
                    <ToggleButton
                      value={user.automated}
                      handleToggle={this.toggleAutomated}
                      size="sm"
                    />
                  </div>
                </div>
              </div>
            )}
            {passwordEditMode && <PasswordChangeForm onSubmit={this.savePassword} />}
          </Paper>
          {this.renderRoleFeaturesSection()}
          <ConfirmModal
            open={deleteModalOpen}
            type={MODAL.TYPE.DELETE}
            handleClose={this.toggleDeleteModal}
            handleConfirm={this.deleteUser}
            title="Delete user"
            action="delete"
            what="user"
            item={user.name}
          />
        </>
      </Page>
    )
  }
}

UserSettings.propTypes = {
  user: PropTypes.object.isRequired,
  modifyUser: PropTypes.func.isRequired,
  editablePassword: PropTypes.bool,
  editableRole: PropTypes.bool,
  deletableUser: PropTypes.bool,
  deleteUser: PropTypes.func,
  backButton: PropTypes.bool,
  roles: PropTypes.array,
}

UserSettings = withRouter(UserSettings)

export default props => {
  const currentUserId = useAuthTokenStore(getCurrentUserId)
  const { data: destinationsMap = {} } = useFetchDestinationsMap()
  const { data: channels = [] } = useFetchChannels()

  return (
    <UserSettings
      {...props}
      isMe={props.user.id === currentUserId}
      destinationsMap={destinationsMap}
      channels={channels}
    />
  )
}

import React, { useCallback, useEffect, useRef } from "react"
import classNames from "classnames"
import styles from "./ToastCenter.module.scss"
import { CloseButtonProps, ToastContainer, TypeOptions, toast as _toast, Id } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { Link } from "react-router-dom"
import { TOAST } from "sharedConstants"
import { ToastOpts, ToastState, useToast } from "app/toast"

export function ToastCenter() {
  const { toast } = useToast()
  const prevToast = useRef<{ id: Id; message: string } | null>(null)

  useEffect(() => {
    if (toast) {
      if (
        prevToast.current &&
        prevToast.current.id !== toast.customId &&
        (toast.message === prevToast.current.message || toast.hidePrevious)
      ) {
        _toast.dismiss(prevToast.current.id)
      }
      prevToast.current = { id: notify(toast), message: toast.message }
    }
  }, [toast])

  const clearToasts = useCallback(() => _toast.dismiss(), [])

  useEffect(() => {
    window.addEventListener("blur", clearToasts, false)
    return () => window.removeEventListener("blur", clearToasts, false)
  }, [clearToasts])

  function notify({ message, type, hidePrevious, customId, ...opts }: ToastState) {
    switch (type) {
      case TOAST.TYPE.SUCCESS:
        return _toast.success(<ToastContent text={message} {...opts} />, {
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          autoClose: 5000,
          toastId: customId,
        })
      case TOAST.TYPE.ERROR:
        return _toast.error(<ToastContent text={message} {...opts} />, {
          autoClose: false,
          toastId: customId,
        })
      case TOAST.TYPE.INFO:
      default:
        return _toast.info(<ToastContent text={message} {...opts} />, {
          autoClose: false,
          toastId: customId,
        })
    }
  }

  return (
    <ToastContainer
      className={styles.toastContainer}
      toastClassName={ctx => {
        return classNames(styles.toast, {
          [styles.toastSuccess]: ctx?.type === "success",
          [styles.toastError]: ctx?.type === "error",
          [styles.toastInfo]: ctx?.type === "info",
        })
      }}
      bodyClassName={styles.toastBody}
      hideProgressBar={true}
      closeButton={CloseButton}
      position={_toast.POSITION.TOP_RIGHT}
      draggable={false}
      closeOnClick={false}
      icon={ToastIcon}
    />
  )
}

function CloseButton({ closeToast }: CloseButtonProps) {
  return (
    <div className={styles.toastClose} onClick={closeToast}>
      <FontAwesomeIcon icon={["fas", "times"]} />
    </div>
  )
}

type ToastContentProps = {
  text?: string
  closeToast?: () => void
} & ToastOpts

function ToastContent({ text = "", route, closeToast, link, routes }: ToastContentProps) {
  if (link) {
    return (
      <div className={styles.toastContent}>
        <div className={styles.toastText}>
          {text}{" "}
          <a href={link.url} className={styles.externalLink} target="_blank" rel="noreferrer">
            {link.title || "Learn more"}
          </a>
        </div>
      </div>
    )
  }

  if (routes) {
    return (
      <div className={styles.toastContent}>
        <div className={styles.toastText}>
          {text}{" "}
          {routes.map(({ path, title }) => (
            <Link to={path} onClick={closeToast}>
              <span className={styles.route}>{title}</span>
            </Link>
          ))}
        </div>
      </div>
    )
  }

  if (route) {
    return (
      <div className={styles.toastContent}>
        <span className={styles.toastText}>
          {text}
          <Link to={route} className={styles.link} onClick={closeToast}>
            {["/administration/settings"].includes(route) ? "Settings" : "View"}{" "}
            <FontAwesomeIcon icon={["far", "chevron-right"]} />
          </Link>
        </span>
      </div>
    )
  }

  return (
    <div className={styles.toastContent}>
      <div className={styles.toastText}>{text}</div>
    </div>
  )
}

function ToastIcon({ type }: { type: TypeOptions }) {
  let faIcon: IconName
  let testDataId
  if (type === "error") {
    faIcon = "exclamation-triangle"
    testDataId = "error-message-toast"
  } else if (type === "success") {
    faIcon = "check"
    testDataId = "success-message-toast"
  } else {
    faIcon = "cogs"
    testDataId = "info-message-toast"
  }
  return (
    <div data-testid={testDataId} className={styles.toastIcon}>
      <FontAwesomeIcon icon={["fas", faIcon]} />
    </div>
  )
}

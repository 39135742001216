import React from "react"
import styles from "./WaitDelayNodeForm.module.scss"
import { WaitDelayNodeType, WaitDelayNodeSettings } from "resources/journey/journeyTypes"
import { Controller, useForm } from "react-hook-form"
import NodeFormLayout from "../../components/NodeFormLayout/NodeFormLayout"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { integer, min, required } from "helpers/validators.helper"
import SelectField from "components/UI/elements/SelectField"
import { formValuesToSettings, settingsToFormValues, WaitDelayNodeFormValues } from "./formValues"
import WaitDelayNodeCard from "./WaitDelayNodeCard"
import { getDescription } from "./getDescription"
import { SelectOption } from "types/util"
import { Unit } from "../../utils/duration"

type WaitDelayNodeFormProps = {
  node?: WaitDelayNodeType
  onSubmit: (data: WaitDelayNodeSettings) => void
  onClose: () => void
  isSubmitting: boolean
  isEditable: boolean
}

export default function WaitDelayNodeForm({
  node,
  onClose,
  onSubmit,
  isSubmitting,
  isEditable,
}: WaitDelayNodeFormProps) {
  const defaultValues = node?.settings
    ? settingsToFormValues(node.settings)
    : { count: NaN, unit: "minutes" as const }

  const {
    handleSubmit,
    formState: { errors },
    watch,
    register,
    control,
  } = useForm<WaitDelayNodeFormValues>({ defaultValues })

  const values = watch()

  function submit(values: WaitDelayNodeFormValues) {
    onSubmit(formValuesToSettings(values))
  }

  return (
    <NodeFormLayout
      previewCard={<WaitDelayNodeCard description={getDescription(formValuesToSettings(values))} />}
      onClose={onClose}
      onSubmit={handleSubmit(submit)}
      isSubmitting={isSubmitting}
      isEditable={isEditable}
      width="sm"
    >
      <div className={styles.content}>
        <TextInput
          {...register("count", {
            validate: { required, min: min(1), integer },
            valueAsNumber: true,
          })}
          error={errors.count?.message}
          disabled={!isEditable}
          label="Delay"
          autoFocus
          type="number"
          className={styles.countInput}
          min={1}
        />
        <Controller
          control={control}
          name="unit"
          rules={{ validate: { required } }}
          render={({ field }) => (
            <SelectField
              input={field}
              options={unitOptions}
              isSimpleValue
              label=" "
              error={errors.unit?.message}
              className={styles.unitInput}
              disabled={!isEditable}
            />
          )}
        />
      </div>
    </NodeFormLayout>
  )
}

const unitOptions: SelectOption<Unit>[] = [
  { value: "minutes", label: "Minutes" },
  { value: "hours", label: "Hours" },
  { value: "days", label: "Days" },
]

import { useQuery } from "@tanstack/react-query"
import requestFactory from "api/request"
import { Source } from "resources/dataSource/dataSourceTypes"

type CustomerEventsPerEventTypeResponse = {
  customer_events_per_event_type: {
    source_id: Source["id"]
    event_type: Event["type"]
    count: number
  }[]
}

const customerEventsPerEventType = (): Promise<CustomerEventsPerEventTypeResponse> =>
  requestFactory("get", "/customer_events_per_event_type")

export function useFetchCustomerEventsPerEventType() {
  return useQuery(["customerEventsPerEventType"], customerEventsPerEventType, {
    staleTime: 60 * 1000,
    select({ customer_events_per_event_type }) {
      return customer_events_per_event_type.length
        ? customer_events_per_event_type.map(item => ({
            item_id: `${item.source_id}-${item.event_type}`,
            value: item.count,
            source_id: item.source_id,
          }))
        : null
    },
  })
}

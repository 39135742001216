import React from "react"
import styles from "./SplitNodeForm.module.scss"
import { useFieldArray, useForm } from "react-hook-form"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { max, min, required } from "helpers/validators.helper"
import NodeFormLayout from "../../components/NodeFormLayout/NodeFormLayout"
import { SplitNodeType, SplitNodeSettings } from "resources/journey/journeyTypes"
import SplitNodeCard from "./SplitNodeCard"
import { formValuesToSettings, settingsToFormValues } from "./formValues"
import { getDescription } from "./getDescription"

type SplitNodeFormProps = {
  node?: SplitNodeType
  onSubmit: (data: SplitNodeSettings) => void
  onClose: () => void
  isSubmitting: boolean
  isEditable: boolean
}

export type SplitNodeFormValues = {
  paths: { percentage: number }[]
}

const getNewPath = () => ({ percentage: NaN })

export default function SplitNodeForm({
  node,
  onClose,
  onSubmit,
  isSubmitting,
  isEditable,
}: SplitNodeFormProps) {
  const defaultValues = node?.settings
    ? settingsToFormValues(node.settings)
    : { paths: [getNewPath(), getNewPath()] }

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    register,
  } = useForm<SplitNodeFormValues>({ defaultValues })
  const { fields, append, remove } = useFieldArray({ control, name: "paths" })

  function submit(data: SplitNodeFormValues) {
    onSubmit(formValuesToSettings(data))
  }

  const paths = watch("paths")

  return (
    <NodeFormLayout
      previewCard={<SplitNodeCard description={getDescription(formValuesToSettings({ paths }))} />}
      onClose={onClose}
      onSubmit={handleSubmit(submit)}
      isSubmitting={isSubmitting}
      isEditable={isEditable}
      onClickBack={onClose}
      width="sm"
    >
      <div className={styles.paths}>
        {fields.map((field, index) => (
          <div className={styles.path} key={field.id}>
            <TextInput
              label={`% for path ${index + 1}`}
              {...register(`paths.${index}.percentage`, {
                validate: {
                  required,
                  min: min(0),
                  max: max(100),
                  addsUp(_, values) {
                    if (index !== values.paths.length - 1) {
                      return undefined
                    }

                    if (
                      values.paths
                        .map(({ percentage }) => percentage)
                        .reduce((acc, curr) => acc + curr, 0) !== 100
                    ) {
                      return "The sum of all paths should be 100%"
                    }

                    return undefined
                  },
                },
                valueAsNumber: true,
              })}
              error={errors.paths?.[index]?.percentage?.message}
              type="number"
              min={0}
              max={100}
            />

            <IconButton
              icon={["fas", "trash-alt"]}
              onClick={() => remove(index)}
              variant="outlined"
              color="red"
              size="xs"
            />
          </div>
        ))}

        {paths.length < 5 && (
          <Button variant="outlined" color="grey" onClick={() => append(getNewPath())}>
            + Add path
          </Button>
        )}
      </div>
    </NodeFormLayout>
  )
}

import { Dispatch, SetStateAction, createContext } from "react"

export const ChannelWizardContext = createContext({
  currentStep: 0 as number,
  steps: 0 as number,
  isFirstStep: false as boolean,
  isLastStep: false as boolean,
  expandedSection: null as string | null,
  setExpandedSection: (_ => {}) as Dispatch<SetStateAction<string | null>>,
  decrement: (() => {}) as () => void,
  increment: (() => {}) as () => void,
})

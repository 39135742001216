import React, { useCallback, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import styles from "./LookalikeSizeSlider.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { abbreviateNumber } from "helpers/number.helper"
import { isNil } from "ramda"

type LookalikeSizeSliderProps = {
  disabled?: boolean
  baseSegmentSize?: number
  maxSize: number
  value: number
  onChange: (value: number) => void
}

export default function LookalikeSizeSlider({
  disabled = false,
  value,
  onChange,
  maxSize,
  baseSegmentSize,
}: LookalikeSizeSliderProps) {
  const sliderRef = useRef<HTMLDivElement>(null)
  const handleRef = useRef<HTMLDivElement>(null)
  const width = sliderRef.current ? sliderRef.current.getBoundingClientRect().width : 0

  const valueToPosition = useCallback(
    (value: number): number => (value / maxSize) * width,
    [maxSize, width],
  )

  const positionToValue = useCallback(
    (x: number): number => Math.round((x / width) * maxSize),
    [maxSize, width],
  )

  const markerPosition = baseSegmentSize ? valueToPosition(baseSegmentSize) : 0
  const handlePosition = valueToPosition(value)

  const [isDragging, setIsDragging] = useState(false)

  // This state is here purely to force rerender on window resize
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setWinSize] = useState(0)
  useEffect(() => {
    function handleResize() {
      setWinSize(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    function mouseMoveListener(e: MouseEvent) {
      if (!isDragging) {
        return
      }

      const newValue = positionToValue(e.clientX - sliderRef.current!.getBoundingClientRect().left)

      if (
        newValue <= maxSize &&
        newValue >= 1 &&
        (isNil(baseSegmentSize) || newValue >= baseSegmentSize)
      ) {
        onChange(newValue)
      }
    }

    function mouseUpListener() {
      setIsDragging(false)
    }

    window.addEventListener("mousemove", mouseMoveListener)
    window.addEventListener("mouseup", mouseUpListener)

    return () => {
      window.removeEventListener("mousemove", mouseMoveListener)
      window.removeEventListener("mouseup", mouseUpListener)
    }
  }, [baseSegmentSize, isDragging, maxSize, onChange, positionToValue])

  return (
    <div
      className={classNames(styles.slider, {
        [styles.disabled]: disabled,
        [styles.noBaseSegment]: isNil(baseSegmentSize),
      })}
    >
      <div className={styles.sliderBackground} ref={sliderRef}>
        <div
          className={styles.sliderBackgroundFilled}
          style={{ left: markerPosition, right: width - handlePosition }}
        />
        <div
          data-testid="ls-slider"
          className={styles.sliderHandle}
          style={{ left: handlePosition }}
          ref={handleRef}
          onMouseDown={_ => {
            if (!disabled) {
              setIsDragging(true)
            }
          }}
        >
          <FontAwesomeIcon icon={["fas", "grip-lines-vertical"]} />
        </div>
        {!isNil(baseSegmentSize) && baseSegmentSize <= maxSize && (
          <div className={styles.baseSegmentMarker} style={{ left: markerPosition }}>
            <div className={styles.markerLabel}>{abbreviateNumber(baseSegmentSize)}</div>
          </div>
        )}
      </div>
      <div className={styles.labels}>
        <div className={styles.label}>
          <div>0</div>
          <div className={styles.percentage}>0 %</div>
        </div>
        <div className={styles.label}>
          <div>{abbreviateNumber(maxSize)}</div>
          <div className={styles.percentage}>20 %</div>
        </div>
      </div>
    </div>
  )
}

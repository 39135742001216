import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ComponentPropsWithoutRef } from "react"
import NodeCard from "../../components/NodeCard/NodeCard"

const icon = <FontAwesomeIcon icon={["fas", "clock"]} color="#b85888" />

export default function WaitDelayNodeCard(
  props: Omit<ComponentPropsWithoutRef<typeof NodeCard>, "title" | "icon">,
) {
  return <NodeCard title="Wait" icon={icon} {...props} />
}

import { useQuery } from "@tanstack/react-query"
import requestFactory from "api/request"
import { useHasAccess } from "resources/user/currentUserQueries"

type LicenceUsageResponse = {
  customer_events_hidden_included_count: number | null
  customer_events_hidden_included_last_30_days_count: number | null
}

const licenceUsage = (): Promise<LicenceUsageResponse> =>
  requestFactory("get", "/licence_usage_counts")

export function useFetchLicenceUsage() {
  const hasAccess = useHasAccess()

  return useQuery(["licenceUsage"], licenceUsage, {
    staleTime: 60 * 1000,
    enabled: hasAccess.data.licenceUsage,
  })
}

import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import requestFactory from "api/request"
import { showToast } from "app/toast"
import { DBFormatDateTime } from "types/util"

type CacheStatusResponse = {
  init_time: DBFormatDateTime
  refresh_init_time: DBFormatDateTime | null
  is_refresh_running: boolean
}

const cacheStatus = (): Promise<CacheStatusResponse> => requestFactory("get", "/cdp_cache_status")

export const useFetchCacheStatus = (
  config?: UseQueryOptions<CacheStatusResponse, unknown, CacheStatusResponse, QueryKey>,
) => useQuery(["cacheStatus"] as QueryKey, cacheStatus, { ...config, staleTime: 60 * 1000 })

const deleteCache = (): Promise<void> => requestFactory("delete", "/cache")

export function useDeleteCache() {
  const queryClient = useQueryClient()

  return useMutation(deleteCache, {
    onSuccess() {
      queryClient.invalidateQueries(["cacheStatus"])
      showToast("Cache is being refreshed.")
    },
  })
}

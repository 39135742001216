import React from "react"
import classNames from "classnames"
import { whereEq } from "ramda"
import { Controller, useForm } from "react-hook-form"
import { Prompt, useHistory } from "react-router-dom"

import Button from "components/UI/elements/Button/Button"
import EmailChannelPaper from "../components/EmailChannelPaper/EmailChannelPaper"
import SelectField from "components/UI/elements/SelectField"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { goBackInHistory } from "helpers/backButton.helper"
import { port, required, url } from "helpers/validators.helper"
import brevoLogo from "./images/brevo.svg"
import infobipLogo from "./images/infobip.svg"
import mailersendLogo from "./images/mailersend.svg"
import mailgunLogo from "./images/mailgun.svg"
import mailjetLogo from "./images/mailjet.svg"
import messagebirdLogo from "./images/messagebird.svg"
import omniveryLogo from "./images/omnivery.svg"
import sendgridLogo from "./images/sendgrid.svg"
import { useModifyEmailsChannelProviderConfig } from "resources/channel/channelQueries"
import {
  BrevoProviderConfig,
  EmailsChannel,
  InfobipProviderConfig,
  ModifyEmailsChannelProviderConfigPayload,
  ProviderConfig,
} from "resources/channel/channelTypes"
import { getRoutePath } from "routes"

import styles from "./Provider.module.scss"

type EmailsChannelProvider = ProviderConfig["provider"]

const providerOptions: Array<{
  label: string
  value: EmailsChannelProvider
}> = [
  {
    label: "Brevo",
    value: "brevo",
  },
  {
    label: "Infobip",
    value: "infobip",
  },
]

const getProviderOption = (value: EmailsChannelProvider) => providerOptions.find(whereEq({ value }))

// react-hook-form doesn't support discriminated unions
type FormValues = ProviderConfig & { provider_config: InfobipProviderConfig } & {
  provider_config: BrevoProviderConfig
}

const emptyFormValues: Omit<FormValues, "provider"> = {
  host: "",
  password: "",
  port: 0,
  username: "",
  provider_config: {
    api_key: "",
    account_key: "",
    base_url: "",
  },
  use_tls: true,
}

type SmtpAccountProps = {
  config: EmailsChannel["provider_config"]
  isWizard?: boolean
  goForward?: () => void
}

export default function SmtpAccount({ config, goForward, isWizard = false }: SmtpAccountProps) {
  const history = useHistory()

  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors, isDirty, isSubmitted, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: config ?? { ...emptyFormValues, provider: "brevo" },
  })

  const { mutate, isLoading } = useModifyEmailsChannelProviderConfig()

  const onSubmit = async ({
    provider_config: { account_key, api_key, base_url },
    provider,
    ...restFormValues
  }: FormValues) => {
    const data: ModifyEmailsChannelProviderConfigPayload =
      provider === "brevo"
        ? {
            ...restFormValues,
            provider: "brevo",
            provider_config: {
              api_key,
            },
          }
        : {
            ...restFormValues,
            provider: "infobip",
            provider_config: {
              account_key,
              api_key,
              base_url,
            },
          }

    mutate(
      { data },
      {
        onSuccess: () => goForward?.(),
      },
    )
  }

  const provider = watch("provider")

  const skipSubmit = isWizard && config && !isDirty

  return (
    <>
      <Prompt
        when={isDirty && !isSubmitting && !isSubmitted}
        message="Changes you made will not be saved."
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <EmailChannelPaper
          title="Email provider"
          description="Connection type will be STARTTLS by default."
          headerContent={
            <Button
              icon={isWizard ? "arrow-right" : undefined}
              iconPosition="end"
              loading={isLoading}
              type={skipSubmit ? "button" : "submit"}
              onClick={() => {
                if (skipSubmit) goForward?.()
              }}
            >
              {isWizard ? (
                <>
                  1<span className={styles.steps}>/5</span> Continue
                </>
              ) : (
                "Save"
              )}
            </Button>
          }
          goBack={
            isWizard ? goBackInHistory(history, getRoutePath("administration.channels")) : undefined
          }
        >
          <div className={classNames(styles.row, styles.provider)}>
            <Controller
              control={control}
              name="provider"
              rules={{ validate: { required } }}
              render={({ field: { value, onChange } }) => (
                <div className={styles.selectFieldWrapper}>
                  <SelectField
                    input={{
                      value: value ? getProviderOption(value) : undefined,
                      onChange: ({ value }: { value: EmailsChannelProvider }) => onChange(value),
                    }}
                    onChange={({ value }: { value: EmailsChannelProvider }) =>
                      reset(
                        { ...emptyFormValues, provider: value },
                        {
                          keepDirty: true,
                          keepIsSubmitted: true,
                          keepSubmitCount: true,
                          keepTouched: true,
                        },
                      )
                    }
                    label="Provider"
                    meta={{
                      touched: true,
                      error: errors?.provider?.message,
                    }}
                    noOptionsMessage="No provider matches the given criteria."
                    options={providerOptions}
                    placeholder="Type to search..."
                  />
                </div>
              )}
            />
            <div className={styles.smtpAccount}>
              <div className={styles.server}>
                <TextInput
                  error={errors?.host?.message}
                  label="Server name"
                  placeholder="Server name"
                  className={classNames(styles.serverName, {
                    [styles.error]: errors?.host || errors?.port,
                  })}
                  {...register("host", { validate: { required } })}
                />
                <TextInput
                  error={errors?.port?.message}
                  label="Port"
                  placeholder="Port"
                  className={classNames(styles.port, {
                    [styles.error]: errors?.host || errors?.port,
                  })}
                  {...register("port", {
                    validate: { required, port },
                  })}
                />
              </div>
              <div className={styles.credentials}>
                <TextInput
                  error={errors?.username?.message}
                  label="Username"
                  placeholder="Username"
                  {...register("username", { validate: { required } })}
                />
                <TextInput
                  error={errors?.password?.message}
                  label="Password"
                  placeholder="Password"
                  type="password"
                  {...register("password", { validate: { required } })}
                />
              </div>
            </div>
            {provider === "brevo" && (
              <TextInput
                error={errors.provider_config?.api_key?.message}
                label="API key"
                placeholder="API key"
                type="password"
                {...register("provider_config.api_key")}
              />
            )}
            {provider === "infobip" && (
              <div className={styles.infobipFields}>
                <TextInput
                  error={errors.provider_config?.base_url?.message}
                  label="Base URL"
                  placeholder="Base URL"
                  {...register("provider_config.base_url", { validate: { required, url } })}
                />
                <TextInput
                  error={errors.provider_config?.account_key?.message}
                  label="Account key"
                  placeholder="Account key"
                  type="password"
                  {...register("provider_config.account_key", { validate: { required } })}
                />
                <TextInput
                  error={errors.provider_config?.api_key?.message}
                  label="API key"
                  placeholder="API key"
                  type="password"
                  {...register("provider_config.api_key")}
                />
              </div>
            )}
          </div>
          <div className={styles.row}>
            <div className={styles.description}>
              <h3>Available SMTP integrations</h3>
            </div>
            <div className={styles.fields}>
              <div className={styles.smtpList}>
                <div className={styles.smtpItem}>
                  <div className={styles.logoWrapper}>
                    <img className={styles.logo} src={mailjetLogo} alt="" />
                  </div>
                  <div className={styles.name}>Mailjet</div>
                </div>
                <div className={styles.smtpItem}>
                  <div className={styles.logoWrapper}>
                    <img className={styles.logo} src={messagebirdLogo} alt="" />
                  </div>
                  <div className={styles.name}>MessageBird</div>
                </div>
                <div className={styles.smtpItem}>
                  <div className={styles.logoWrapper}>
                    <img className={styles.logo} src={brevoLogo} alt="" />
                  </div>
                  <div className={styles.name}>Brevo</div>
                </div>
                <div className={styles.smtpItem}>
                  <div className={styles.logoWrapper}>
                    <img className={styles.logo} src={mailersendLogo} alt="" />
                  </div>
                  <div className={styles.name}>MailerSend</div>
                </div>
                <div className={styles.smtpItem}>
                  <div className={styles.logoWrapper}>
                    <img className={styles.logo} src={infobipLogo} alt="" />
                  </div>
                  <div className={styles.name}>Infobip</div>
                </div>
                <div className={styles.smtpItem}>
                  <div className={styles.logoWrapper}>
                    <img className={styles.logo} src={mailgunLogo} alt="" />
                  </div>
                  <div className={styles.name}>Mailgun</div>
                </div>
                <div className={styles.smtpItem}>
                  <div className={styles.logoWrapper}>
                    <img className={styles.logo} src={sendgridLogo} alt="" />
                  </div>
                  <div className={styles.name}>SendGrid</div>
                </div>
                <div className={styles.smtpItem}>
                  <div className={styles.logoWrapper}>
                    <img className={styles.logo} src={omniveryLogo} alt="" />
                  </div>
                  <div className={styles.name}>Omnivery</div>
                </div>
              </div>
            </div>
          </div>
        </EmailChannelPaper>
      </form>
    </>
  )
}

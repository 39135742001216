import React, { useContext, useState } from "react"
import { ConditionNodeType, ConditionNodeSettings, Journey } from "resources/journey/journeyTypes"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ModalWrapper from "components/UI/elements/ModalWrapper/ModalWrapper"
import { useDeleteJourneyNode, useModifyJourneyNode } from "resources/journey/journeyQueries"
import ConditionNodeForm from "./ConditionNodeForm"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { StatsContext } from "../../JourneyDetail"
import { useGetDescription } from "./useGetDescription"
import ConditionNodeCard from "./ConditionNodeCard"

type ConditionNodeProps = {
  journeyId: Journey["id"]
  node: ConditionNodeType
  isEditable: boolean
}

export default function ConditionNode({ node, journeyId, isEditable }: ConditionNodeProps) {
  const stats = useContext(StatsContext)?.[node.id]
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const modifyMutation = useModifyJourneyNode()
  function onSubmit(settings: ConditionNodeSettings) {
    modifyMutation.mutate(
      { journeyId, nodeId: node.id, data: { settings } },
      {
        onSuccess() {
          setIsModalOpen(false)
        },
      },
    )
  }

  const deleteMutation = useDeleteJourneyNode()
  function onDelete() {
    deleteMutation.mutate(
      { journeyId, nodeId: node.id },
      {
        onSuccess() {
          setIsDeleteModalOpen(false)
        },
        onError(error) {
          if ((error as Error).message?.includes("400")) {
            // Node with more than 1 output path can't be deleted
            setIsDeleteModalOpen(false)
          }
        },
      },
    )
  }

  return (
    <>
      <ConfirmModal
        title="Are you sure?"
        open={isDeleteModalOpen}
        type="delete"
        action="delete"
        what="node"
        item="Condition"
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={onDelete}
        isLoading={deleteMutation.isLoading}
      />
      <ConditionNodeCard
        description={useGetDescription(node.settings)}
        arrivedCount={stats?.arrived}
        actions={
          <>
            <IconButton
              onClick={() => {
                setIsModalOpen(true)
              }}
              icon={["far", isEditable ? "pencil-alt" : "eye"]}
              variant="transparent"
              color="grey"
              tooltip={isEditable ? "Edit" : "View"}
              size="xs"
            />
            {isEditable && (
              <IconButton
                onClick={() => setIsDeleteModalOpen(true)}
                icon={["far", "trash-alt"]}
                variant="transparent"
                color="red"
                tooltip="Remove"
                size="xs"
              />
            )}
          </>
        }
      />
      <ModalWrapper isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {isModalOpen && (
          <ConditionNodeForm
            isEditable={isEditable}
            isSubmitting={modifyMutation.isLoading}
            node={node}
            onClose={() => setIsModalOpen(false)}
            onSubmit={onSubmit}
          />
        )}
      </ModalWrapper>
    </>
  )
}

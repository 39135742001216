import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import api from "resources/endpoints"
import { MobilePushExports, MobilePushNotification } from "../mobilePushNotificationTypes"
import { showToast } from "app/toast"
import { Segment } from "resources/segment/segment/segmentTypes"
import { MOBILE_PUSH_NOTIFICATION } from "../mobilePushNotificationQueries"

const EXPORT = "export" as const

export function useFetchMobilePushExports(
  id: MobilePushNotification["id"],
  config?: UseInfiniteQueryOptions<MobilePushExports, string>,
) {
  const { data, ...rest } = useInfiniteQuery<MobilePushExports, string>(
    [MOBILE_PUSH_NOTIFICATION, id, EXPORT],
    ({ pageParam }) => api.mobilePushNotification.export.list({ id, limit: 20, offset: pageParam }),
    {
      ...config,
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.push_notification_exports.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.push_notification_exports) : [] }
}

export function useCreateMobilePushActivation() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, segmentIds }: { id: MobilePushNotification["id"]; segmentIds: Array<Segment["id"]> }) =>
      api.mobilePushNotification.export.create(id, segmentIds),
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries([MOBILE_PUSH_NOTIFICATION, id, EXPORT])
        showToast("Mobile push sent.")
      },
    },
  )
}

export function useSendTestMobilePushNotification() {
  return useMutation(
    ({
      mobilePushNotificationId,
      profileId,
      registrationTokens,
    }: {
      profileId: string
      mobilePushNotificationId: MobilePushNotification["id"]
      registrationTokens: Array<string>
    }) =>
      api.mobilePushNotification.export.test({
        mobilePushNotificationId,
        profileId,
        registrationTokens,
      }),
    {
      onSuccess: () => {
        showToast("Test mobile push notification sent.")
      },
    },
  )
}

import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import SmartSegmentDetail from "./SmartSegmentDetail/SmartSegmentDetail"
import SmartSegmentsList from "./SmartSegmentsList/SmartSegmentsList"
import Trash from "pages/Trash/Trash"
import {
  useFetchSmartSegmentTrashItems,
  useRestoreSmartSegment,
} from "resources/segment/segment/segmentQueries"

export default function SmartSegments() {
  return (
    <Switch>
      <Route path={getRoutePath("segments.smart")} exact component={SmartSegmentsList} />
      <Route
        path={getRoutePath("segments.smart.trash")}
        exact
        render={() => (
          <Trash
            backRoute={getRoutePath("segments.smart")}
            itemType="smart_segments"
            fetchQuery={useFetchSmartSegmentTrashItems}
            restoreMutation={useRestoreSmartSegment}
          />
        )}
      />
      <Route path={getRoutePath("segments.smart.detail")} component={SmartSegmentDetail} />
    </Switch>
  )
}

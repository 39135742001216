import { QueryKey, useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query"
import { api } from "api"
import { showToast } from "app/toast"
import { useHasAccess } from "resources/user/currentUserQueries"
import { Segment } from "../segment/segmentTypes"
import { SegmentExport, SegmentExportRunPayload } from "./segmentExportTypes"

const SEGMENT = "segment" as const
const EXPORT = "export" as const

export function useFetchSegmentExports(segmentId: Segment["id"]) {
  const hasAccess = useHasAccess()
  const { data, ...rest } = useInfiniteQuery(
    [EXPORT, segmentId],
    ({ pageParam }) => api.segment.export.list(segmentId, pageParam),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.segment_exports.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      enabled: hasAccess.segments.export,
      refetchInterval: 5000,
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(p => p.segment_exports) : [] }
}

/*
 * BEWARE there can be problems in cache when we don't wanna include push notification exports
 * on segment detail page, especially when you will fetch segment export detail for push notification,
 * put it into the cache and then you'll go to segment detail ... the export can be then found in
 * table which is not desired behaviour
 */
export function useFetchSegmentExportById(segmentId: Segment["id"], exportId: SegmentExport["id"]) {
  return useQuery(
    [EXPORT, segmentId, exportId],
    () => api.segment.export.retrieve(segmentId, exportId),
    {
      select: ({ segment_export }) => segment_export,
    },
  )
}

export function useRunSegmentExport() {
  return useMutation(
    ({ id, data }: { id: Segment["id"]; data: SegmentExportRunPayload }) =>
      api.segment.export.run(id, data),
    {
      onSuccess() {
        showToast("Export initiated.")
      },
    },
  )
}

export const useCancelSegmentExport = () =>
  useMutation(
    ({ segmentId, exportId }: { segmentId: Segment["id"]; exportId: SegmentExport["id"] }) =>
      api.segment.export.cancel(segmentId, exportId),
    {
      onSuccess: () => showToast("Export will be canceled."),
    },
  )

export function useFetchSegmentExportLogs(segmentId: Segment["id"], exportId: SegmentExport["id"]) {
  const { data, ...rest } = useInfiniteQuery(
    [SEGMENT, segmentId, EXPORT, exportId, "logs"] as QueryKey,
    ({ pageParam }) =>
      api.segment.export.logs.list(segmentId, exportId, pageParam, 10, 0, "id", "DESC"),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.segment_export_logs.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(p => p.segment_export_logs) : [] }
}

import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import React, { createContext, useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  useDeleteJourney,
  useFetchJourneyById,
  useFetchJourneyNodeStatistics,
  useFetchJourneyStatistics,
  useModifyJourney,
} from "resources/journey/journeyQueries"
import {
  JourneyModifyPayload,
  JourneyNode,
  JourneyNodeUIStatistics,
} from "resources/journey/journeyTypes"
import { getRoutePath } from "routes"
import styles from "./JourneyDetail.module.scss"
import Button from "components/UI/elements/Button/Button"
import { useHasAccess } from "resources/user/currentUserQueries"
import ConfirmModal from "components/UI/components/ConfirmModal"
import NodeTree from "./components/NodeTree/NodeTree"
import nodeListToTree from "resources/journey/nodeTree"
import StatusSwitch from "./components/StatusSwitch/StatusSwitch"
import PaperHeader from "components/UI/elements/PaperHeader"
import EditableValue from "components/UI/components/EditableValue/EditableValue"
import EditableTextArea from "components/UI/components/EditableTextArea/EditableTextArea"

export const StatsContext = createContext<
  Record<JourneyNode["id"], JourneyNodeUIStatistics> | undefined
>(undefined)

const formatter = new Intl.NumberFormat("en-US")

export default function JourneyDetail() {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const hasAccess = useHasAccess()
  const journeyQuery = useFetchJourneyById(id)
  const statsQuery = useFetchJourneyStatistics(id)
  const nodeStatsQuery = useFetchJourneyNodeStatistics(id)
  const modifyMutation = useModifyJourney()
  const deleteMutation = useDeleteJourney()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  function modifyJourney(data: JourneyModifyPayload) {
    return modifyMutation.mutateAsync({ id, data })
  }

  function deleteJourney() {
    deleteMutation.mutate(
      { id },
      {
        onSuccess() {
          history.push(getRoutePath("journeys"))
        },
      },
    )
  }

  const canvasRef = useRef<HTMLDivElement>(null)
  const wasScrolled = useRef(false)
  useEffect(() => {
    // Canvas is rendered after the data is fetched; center it
    if (canvasRef.current && !wasScrolled.current && journeyQuery.data) {
      const canvas = canvasRef.current
      canvas.scrollLeft = (canvas.scrollWidth - canvas.clientWidth) / 2
      wasScrolled.current = true
    }
  }, [journeyQuery.data])

  if (journeyQuery.isLoading) {
    return (
      <Page title="Edit journey" backRouteFallback={getRoutePath("journeys")}>
        <LoadingIndicator />
      </Page>
    )
  }

  if (!journeyQuery.data) {
    return (
      <Page title="Edit journey" backRouteFallback={getRoutePath("journeys")}>
        {null}
      </Page>
    )
  }

  const showStatistics =
    journeyQuery.data.status === "active" || journeyQuery.data.status === "passive"

  return (
    <>
      <ConfirmModal
        title="Are you sure?"
        open={isDeleteModalOpen}
        action="delete"
        item={journeyQuery.data?.name}
        type="delete"
        what="journey"
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={deleteJourney}
        isLoading={deleteMutation.isLoading}
      />

      <Page
        title="Edit journey"
        backRouteFallback={getRoutePath("journeys")}
        headerContent={
          hasAccess.journeys.edit && (
            <Button
              color="red"
              icon="trash-alt"
              variant="outlined"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              Delete
            </Button>
          )
        }
        contentClassName={styles.content}
      >
        <PaperHeader className={styles.header} size="small">
          <EditableValue
            initValue={journeyQuery.data.name}
            onChange={name => modifyJourney({ name })}
          />
          <StatusSwitch status={journeyQuery.data.status} journeyId={id} />
        </PaperHeader>
        <Paper noPadding className={styles.canvasPaper}>
          <div className={styles.canvasWrapper} ref={canvasRef}>
            {showStatistics && statsQuery.data && (
              <div className={styles.stats}>
                <div className={styles.stat}>
                  <div className={styles.statLabel}>Arrived</div>
                  <div className={styles.statValue}>
                    {formatter.format(statsQuery.data.customer_entities_entered)}
                  </div>
                </div>
                <div className={styles.stat}>
                  <div className={styles.statLabel}>Dropped</div>
                  <div className={styles.statValue}>
                    {formatter.format(statsQuery.data.customer_entities_dropped)}
                  </div>
                </div>
                <div className={styles.stat}>
                  <div className={styles.statLabel}>Completed</div>
                  <div className={styles.statValue}>
                    {formatter.format(statsQuery.data.customer_entities_finished)}
                  </div>
                </div>
              </div>
            )}
            <StatsContext.Provider value={showStatistics ? nodeStatsQuery.data : undefined}>
              <NodeTree
                tree={nodeListToTree(journeyQuery.data.nodes)}
                journeyId={id}
                isEditable={hasAccess.journeys.edit && journeyQuery.data.status === "draft"}
              />
            </StatsContext.Provider>
          </div>
        </Paper>
        <Paper className={styles.descriptionWrapper}>
          <EditableTextArea
            label="Description"
            initValue={journeyQuery.data.description ?? ""}
            onChange={description => modifyJourney({ description })}
          />
        </Paper>
      </Page>
    </>
  )
}

export default (
  <svg width="194" height="90" viewBox="0 0 194 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 1H62C63.6569 1 65 2.34315 65 4V89H1V4C1 2.34315 2.34315 1 4 1Z"
      fill="url(#paint0_linear_25811_17350)"
      fillOpacity="0.09"
      stroke="url(#paint1_linear_25811_17350)"
      strokeWidth="2"
    />
    <path
      d="M64 22C64 19.7909 65.7909 18 68 18H126C128.209 18 130 19.7909 130 22V90H64V22Z"
      fill="url(#paint2_linear_25811_17350)"
      fillOpacity="0.06"
    />
    <path
      d="M68 19H126C127.657 19 129 20.3431 129 22V89H65V22C65 20.3431 66.3431 19 68 19Z"
      stroke="url(#paint3_linear_25811_17350)"
      strokeOpacity="0.66"
      strokeWidth="2"
    />
    <path
      d="M128 70C128 67.7909 129.791 66 132 66H190C192.209 66 194 67.7909 194 70V90H128V70Z"
      fill="url(#paint4_linear_25811_17350)"
      fillOpacity="0.03"
    />
    <path
      d="M132 67H190C191.657 67 193 68.3431 193 70V89H129V70C129 68.3431 130.343 67 132 67Z"
      stroke="url(#paint5_linear_25811_17350)"
      strokeOpacity="0.33"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_25811_17350"
        x1="33"
        y1="0"
        x2="33"
        y2="90"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" />
        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_25811_17350"
        x1="33"
        y1="0"
        x2="33"
        y2="90"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" />
        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_25811_17350"
        x1="97"
        y1="18"
        x2="97"
        y2="90"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" />
        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_25811_17350"
        x1="97"
        y1="18"
        x2="97"
        y2="90"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" />
        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_25811_17350"
        x1="161"
        y1="66"
        x2="161"
        y2="90"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" />
        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_25811_17350"
        x1="161"
        y1="66"
        x2="161"
        y2="90"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" />
        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

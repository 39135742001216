import React, { useEffect, useState } from "react"
import Paper from "components/UI/elements/Paper"
import Tag from "components/UI/elements/Tag"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import LabelForm from "./components/LabelForm"
import { MODAL } from "sharedConstants"
import "./Labels.scss"
import { refetchAttributes } from "resources/attribute/attributeQueries"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import {
  useCreateLabel,
  useDeleteLabel,
  useFetchAllLabels,
  useModifyLabel,
} from "resources/attributeLabel/attributeLabelQueries"
import { Label, LabelFilterState } from "resources/attributeLabel/attributeLabelTypes"
import { pick } from "ramda"
import Table, { Column } from "components/Table/Table"
import Datetime from "components/UI/elements/Datetime/Datetime"
import create from "zustand"

const filtersInitialState: Pick<LabelFilterState, "orderBy" | "orderDir"> = {
  orderBy: "name",
  orderDir: "ASC",
}

const useFiltersStore = create<LabelFilterState>(set => ({
  ...filtersInitialState,
  reset: () => set(filtersInitialState),
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
}))

export default function Labels() {
  const [view, setView] = useState<"list" | "form">("list")
  const [selectedLabel, setSelectedLabel] = useState<Label | null>(null)

  const { orderBy, orderDir, reset, setSort } = useFiltersStore()
  useEffect(() => reset, [reset])

  const { data: labels = [], isSuccess, isLoading } = useFetchAllLabels({ orderBy, orderDir })

  const createMutation = useCreateLabel()
  const modifyMutation = useModifyLabel()
  const deleteMutation = useDeleteLabel()
  const [labelIdToDelete, setLabelIdToDelete] = useState<Label["id"] | null>(null)
  function submit(values: Pick<Label, "name">) {
    if (selectedLabel) {
      modifyMutation.mutate(
        { id: selectedLabel.id, data: values },
        {
          onSuccess() {
            refetchAttributes()
            setView("list")
            setSelectedLabel(null)
          },
        },
      )
    } else {
      createMutation.mutate(
        { data: values },
        {
          onSuccess() {
            setView("list")
          },
        },
      )
    }
  }
  function deleteLabel() {
    deleteMutation.mutate(
      { id: labelIdToDelete! },
      {
        onSuccess() {
          setView("list")
          setLabelIdToDelete(null)
          setSelectedLabel(null)
        },
      },
    )
  }

  const columns: Column<Label>[] = [
    {
      id: "name",
      label: "Name",
      gridTemplate: "5fr",
      renderCell: label => <Tag color="primary">{label.name}</Tag>,
      onSort: () => setSort("name"),
    },
    {
      id: "created",
      label: "Created",
      gridTemplate: "1fr",
      renderCell: label => <Datetime datetime={label.created} />,
      onSort: () => setSort("created"),
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: label => (
        <div className="actions">
          <IconButton
            color="black"
            size="xs"
            onClick={() => {
              setView("form")
              setSelectedLabel(label)
            }}
            icon="pencil-alt"
            tooltip="Edit"
            variant="outlined"
          />
          <IconButton
            color="red"
            size="xs"
            onClick={() => setLabelIdToDelete(label.id)}
            icon="trash-alt"
            tooltip="Delete"
            variant="outlined"
          />
        </div>
      ),
    },
  ]

  return (
    <Page
      title="Attribute labels"
      className="setup-labels"
      headerContent={
        <>
          {view === "list" && <Button onClick={() => setView("form")}>+ Create label</Button>}
          {view === "form" && (
            <div className="buttons">
              {selectedLabel && (
                <Button
                  color="red"
                  icon="trash-alt"
                  variant="outlined"
                  onClick={() => setLabelIdToDelete(selectedLabel.id)}
                  loading={deleteMutation.isLoading}
                >
                  Delete
                </Button>
              )}

              <Button
                color="grey"
                variant="outlined"
                onClick={() => {
                  setView("list")
                  setSelectedLabel(null)
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                form="labelForm"
                loading={createMutation.isLoading || modifyMutation.isLoading}
              >
                Save
              </Button>
            </div>
          )}
        </>
      }
    >
      {isLoading && <LoadingIndicator />}

      {view === "list" && isSuccess && (
        <Paper>
          <Table
            columns={columns}
            data={labels}
            emptyMessage={'Click on "Create Label" to get started.'}
            sortBy={orderBy}
            sortDir={orderDir}
          />
        </Paper>
      )}

      {view === "form" && (
        <Paper className="label-form-wrapper">
          <LabelForm
            onSubmit={submit}
            initialValues={selectedLabel ? pick(["name"], selectedLabel) : undefined}
            isCreate={!selectedLabel}
          />
        </Paper>
      )}
      <ConfirmModal
        open={!!labelIdToDelete}
        type={MODAL.TYPE.DELETE}
        handleClose={() => setLabelIdToDelete(null)}
        handleConfirm={deleteLabel}
        title="Delete label"
        action="delete"
        what="label"
        item={labels?.find(({ id }) => id === labelIdToDelete)?.name}
        isLoading={deleteMutation.isLoading}
      />
    </Page>
  )
}

import { SplitNodeSettings } from "resources/journey/journeyTypes"

export function getDescription(settings?: SplitNodeSettings | null) {
  if (!settings) {
    return ""
  }

  return Object.values(settings.paths)
    .map(percentage => `${!percentage || isNaN(percentage) ? 0 : percentage}%`)
    .join(" – ")
}

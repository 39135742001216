import React from "react"
import TextArea from "components/UI/elements/TextArea/TextArea"
import IconsRadioGroup from "components/UI/components/IconsRadioGroup"
import ColorRadioGroup from "components/UI/components/ColorRadioGroup"
import { required, pythonVariable } from "helpers/validators.helper"
import { OPTION_GROUP_ICONS, OPTION_GROUP_COLORS } from "sharedConstants"
import "./SourceForm.scss"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import { useFetchAllDataSources } from "resources/dataSource/dataSourceQueries"
import SystemBadge from "components/UI/elements/SystemBadge/SystemBadge"
import { omit } from "ramda"
import { Controller, DeepPartial, useForm } from "react-hook-form"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { SourceCreatePayload, SourceModifyPayload } from "resources/dataSource/dataSourceTypes"

type SourceFormProps = {
  isSystem?: boolean
  initialValues?: DeepPartial<SourceCreatePayload>
} & (
  | {
      onSubmit: (data: SourceCreatePayload) => void
      isCreate: true
    }
  | {
      onSubmit: (data: SourceModifyPayload) => void
      isCreate?: false
    }
)

function SourceForm({ onSubmit, isSystem, isCreate, initialValues }: SourceFormProps) {
  const { data: sources = [] } = useFetchAllDataSources({ showHidden: true })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<SourceCreatePayload>({
    defaultValues: initialValues
      ? {
          ...initialValues,
          frontend_settings: {
            icon: initialValues.frontend_settings?.icon ?? initialValues.type,
            color: initialValues.frontend_settings?.color ?? "primary",
          },
        }
      : undefined,
  })

  function submitForm(values: SourceCreatePayload) {
    if (isCreate) {
      onSubmit(values)
    } else {
      // @ts-ignore I don't know, TS should be able to figure this out
      onSubmit(omit(["id"], values))
    }
  }

  const alreadyUsedColors = sources
    .map(({ frontend_settings }) => frontend_settings?.color)
    .filter(Boolean)

  return (
    <section className="source-form">
      <form id="sourceForm" onSubmit={handleSubmit(submitForm)}>
        <div className="form-row white">
          <div className="left-part">
            <h2>General</h2>
            <div>{isSystem && <SystemBadge />}</div>
          </div>
          <div className="right-part">
            <TextInput
              {...register("id", { validate: { required, pythonVariable } })}
              placeholder="Source ID"
              label="Source ID"
              maxLength={30}
              className="source-id"
              disabled={!isCreate || isSystem}
              error={errors.id?.message}
            />
            <TextInput
              {...register("name", { validate: required })}
              placeholder="Name"
              label="Name"
              maxLength={30}
              className="source-name"
              disabled={isSystem}
              error={errors.name?.message}
            />
            <TextInput
              {...register("type", { validate: { required, pythonVariable } })}
              placeholder="Source type"
              label="Source type"
              maxLength={30}
              className="source-type"
              disabled={isSystem}
              error={errors.type?.message}
            />{" "}
          </div>
        </div>
        <div className="form-row grey">
          <div className="left-part">
            <h2>Description</h2>
          </div>
          <div className="right-part">
            <TextArea
              {...register("description")}
              placeholder="Description (optional)"
              label="Description (optional)"
              className="source-description"
              disabled={isSystem}
              rows={8}
            />
          </div>
        </div>
        <div className="form-row last">
          <div className="left-part">
            <h2>Icon</h2>
          </div>
          <div className="right-part">
            <div>
              <Controller
                control={control}
                name="frontend_settings.icon"
                rules={{ validate: required }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <IconsRadioGroup
                    input={{ value, onChange }}
                    meta={{ touched: true, error: error?.message }}
                    icons={OPTION_GROUP_ICONS}
                    disabled={isSystem}
                  />
                )}
              />
              <div className="label-n-tooltip">
                <label>
                  Define source color{" "}
                  <InfoTooltip>
                    Color will be visible in: Diagnostic Dashboard, insights default settings,
                    customer profile, source logos.
                  </InfoTooltip>
                </label>
              </div>
              <Controller
                control={control}
                name="frontend_settings.color"
                rules={{ validate: required }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <ColorRadioGroup
                    input={{ value, onChange }}
                    meta={{ touched: true, error: error?.message }}
                    colors={OPTION_GROUP_COLORS}
                    alreadyUsedColors={alreadyUsedColors}
                    disabled={isSystem}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </section>
  )
}

export default SourceForm

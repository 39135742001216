import { SystemEmailTemplate } from "../../SystemEmailTemplateItem/SystemEmailTemplateItem"
import imgSrc from "./RealEstateInsider.png"
import contentJson from "./realEstateInsider.json"

const template: SystemEmailTemplate = {
  contentJson,
  imgSrc,
  id: "realEstateInsider",
  name: "Balanced product promotion template",
  description:
    "Showcase your content or product offering with this classic template fitting for every industry.",
}

export default template

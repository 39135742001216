import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"
import Page from "components/UI/Page/Page"
import EmailsImg from "../../../components/EmailsImg.jpg"

import styles from "./EmailsNoAccess.module.scss"

type EmailsReadyToUseProps = {
  pageTitle: string
}

export default function EmailsNoAccess({ pageTitle }: EmailsReadyToUseProps) {
  return (
    <Page title={pageTitle}>
      <MarketingContent img={{ alt: "Emails", src: EmailsImg }}>
        <h1>Emails</h1>
        <strong>
          It seems like you don't have access to the Emails channel. If you want to know more about
          your access settings, please contact your administrator.
        </strong>
        <p>
          In the Emails tab, you can create{" "}
          <strong className={styles.primary}>email campaigns</strong> and deliver them to desired
          audience groups with <strong className={styles.primary}>personalized</strong> content,
          such as names, recommended products, items left in the basket, searched products, and
          more.
        </p>
        <p>
          Check out possible{" "}
          <a
            href="https://docs.meiro.io/books/meiro-business-explorer/page/emails-use-cases"
            target="_blank"
            rel="noreferrer"
          >
            use cases
          </a>{" "}
          and access{" "}
          <a
            href="https://docs.meiro.io/books/meiro-business-explorer/chapter/email-campaigns"
            target="_blank"
            rel="noreferrer"
          >
            tutorials
          </a>{" "}
          for the Email channel.
        </p>
      </MarketingContent>
    </Page>
  )
}

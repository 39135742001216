import { isNil } from "ramda"
import { EventCondition } from "resources/journey/journeyTypes"

export function eventConditionToFormValues(condition: EventCondition): EventCondition {
  try {
    const parsed = JSON.parse(condition.value)

    if (Array.isArray(parsed)) {
      return { ...condition, values: parsed }
    }

    if (typeof parsed === "object") {
      return { ...condition, min_value: parsed.min_value, max_value: parsed.max_value }
    }

    return condition
  } catch {
    return condition
  }
}

export function eventConditionToSettings(condition: EventCondition): EventCondition {
  const { parameter_id, operation, value, values, min_value, max_value } = condition

  if (!isNil(value)) {
    return { parameter_id, operation, value: value.toString() }
  }
  if (!isNil(min_value) && !isNil(max_value)) {
    return { parameter_id, operation, value: JSON.stringify({ min_value, max_value }) }
  }
  if (Array.isArray(values)) {
    return {
      parameter_id,
      operation,
      value: JSON.stringify(values.filter(value => typeof value !== "number" || !isNaN(value))),
    }
  }
  return { parameter_id, operation, value: "" }
}

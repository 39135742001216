import {
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import { equals, prop, sort, update } from "ramda"
import api from "resources/endpoints"
import {
  DestinationFilterState,
  SegmentExportDestination,
  SegmentExportDestinationListDeletedResponse,
  SegmentExportDestinationModifyPayload,
} from "./exportDestinationTypes"
import { ascend, descend } from "utilities/comparators"
import { getRoutePath } from "routes"
import { TrashItem } from "types/trash"
import { useCallback } from "react"

const DESTINATION = "exportDestination" as const
export const DESTINATION_ALL_QK: QueryKey = [DESTINATION, "all"]
export const DESTINATION_TRASH_QK: QueryKey = [DESTINATION, "trash"]

function useDestinationsQuery<T>(
  config?: UseQueryOptions<SegmentExportDestination[], unknown, T, QueryKey>,
) {
  return useQuery(DESTINATION_ALL_QK, api.exportDestination.listAll, {
    staleTime: 60 * 1000,
    ...config,
  })
}

export function useFetchAllDestinations(
  { orderBy, orderDir }: Partial<Pick<DestinationFilterState, "orderBy" | "orderDir">> = {},
  config?: UseQueryOptions<
    SegmentExportDestination[],
    unknown,
    SegmentExportDestination[],
    QueryKey
  >,
) {
  const select = useCallback(
    (destinations: Array<SegmentExportDestination>) => {
      let selectedDestinations = destinations

      if (orderBy && orderDir) {
        const comparator = orderDir === "ASC" ? ascend : descend
        selectedDestinations = sort(comparator(prop(orderBy)), selectedDestinations)
      }

      return selectedDestinations
    },
    [orderBy, orderDir],
  )

  return useDestinationsQuery({ ...config, select })
}

export function useFetchDestinationsMap() {
  return useDestinationsQuery({
    select: destinations =>
      Object.fromEntries(destinations.map(destination => [destination.id, destination])),
  })
}

export function useFetchDestinationById(id: SegmentExportDestination["id"]) {
  return useDestinationsQuery({
    select: (destinations: SegmentExportDestination[]) =>
      destinations.find(destination => destination.id === id) ?? null,
  })
}

export function useDeleteDestination() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id }: { id: SegmentExportDestination["id"] }) => api.exportDestination.delete(id),
    {
      onSuccess: () => {
        // Cannot setQueryData because the API response doesn't return the id of the deleted entity
        queryClient.refetchQueries(DESTINATION_ALL_QK)
        showToast("Destination deleted.")
      },
    },
  )
}

export function useModifyDestination() {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      id,
      data,
    }: {
      id: SegmentExportDestination["id"]
      data: SegmentExportDestinationModifyPayload
    }) => api.exportDestination.modify(id, data),
    {
      onSuccess: ({ segment_export_destination }, { data }) => {
        queryClient.setQueryData<SegmentExportDestination[]>(DESTINATION_ALL_QK, data => {
          if (!data) return

          const index = data.findIndex(({ id }) => segment_export_destination.id === id)

          return index === -1
            ? data.concat(segment_export_destination)
            : update(index, segment_export_destination, data)
        })
        showToast(
          equals(Object.keys(data), ["mi_workspace_id"])
            ? "Workspace assigned."
            : "Destination modified.",
        )
      },
    },
  )
}

export const useFetchExportDestinationTrashItems = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<
    SegmentExportDestinationListDeletedResponse,
    string,
    Omit<SegmentExportDestinationListDeletedResponse, "trashed_segment_export_destinations"> & {
      trashed_segment_export_destinations: Array<TrashItem>
    },
    QueryKey
  >(
    [DESTINATION, "trash", searchTerm],
    ({ pageParam }) =>
      api.exportDestination.listDeleted({
        offset: pageParam,
        limit: 20,
        searched_text: searchTerm,
      }),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.trashed_segment_export_destinations.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      select: data => {
        return {
          ...data,
          pages: data.pages.map(p => ({
            ...p,
            trashed_segment_export_destinations: p.trashed_segment_export_destinations.map(
              ({ id, name, created, user_id }) => {
                const trashItem: TrashItem = {
                  id,
                  name,
                  deleted_at: created,
                  deleted_by: user_id,
                  type: "segment_export_destinations",
                }

                return trashItem
              },
            ),
          })),
        }
      },
    },
  )

  return {
    ...rest,
    data: data ? data.pages.flatMap(m => m.trashed_segment_export_destinations) : [],
  }
}

export const useRestoreExportDestination = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id }: { id: SegmentExportDestination["id"] }) => api.exportDestination.restoreDeleted(id),
    {
      onSuccess: ({ segment_export_destination }) => {
        queryClient.invalidateQueries(DESTINATION_TRASH_QK)
        queryClient.invalidateQueries(
          { queryKey: DESTINATION_ALL_QK, refetchType: "all" },
          { throwOnError: false },
        )

        showToast(
          "Destination restored.",
          undefined,
          getRoutePath("administration.destinations.detail", { id: segment_export_destination.id }),
        )
      },
    },
  )
}

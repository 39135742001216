import React, { PureComponent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TimeAgo from "react-timeago"
import { CSSTransition } from "react-transition-group"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Duration from "components/UI/elements/Duration"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import EventsOverTimeChart from "./charts/EventsOverTimeChart"
import CustomersOverTimeChart from "./charts/CustomersOverTimeChart"
import CustomersPerSourceChart from "./charts/CustomersPerSourceChart"
import CustomersAcrossXSourcesChart from "./charts/CustomersAcrossXSourcesChart"
import CustomersUpdatedChart from "./charts/CustomersUpdatedChart"
import VerticalChart from "./charts/VerticalChart"
import SuspiciousCustomerEntities from "./charts/SuspiciousCustomerEntities/SuspiciousCustomerEntities"
import { abbreviateNumber } from "helpers/number.helper"
import PendingPromise from "helpers/pendingPromise.helper"
import "./Dashboard.scss"
import Tippy from "@tippyjs/react"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { timeAgoFormatter } from "helpers/timeAgo.helper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { isEmpty, isNil } from "ramda"
import {
  useFetchAttributesCount,
  useFetchAttributesMap,
} from "resources/attribute/attributeQueries"
import { useHasAccess } from "resources/user/currentUserQueries"
import { useFetchAllEvents, useFetchEventsMap } from "resources/event/eventQueries"
import { useFetchAllDataSources } from "resources/dataSource/dataSourceQueries"
import Page from "components/UI/Page/Page"
import { useFetchGlobalSettings } from "resources/globalSettings/globalSettingsQueries"
import { DBtimestampToDate } from "utilities/date"
import { useFetchCustomerEventsCount } from "resources/stats/customerEventsCount"
import { useFetchCustomersCount } from "resources/stats/customersCount"
import { useFetchCacheStatus } from "resources/stats/cacheStatus"
import { useFetchCustomerEventsPerEventType } from "resources/stats/customerEventsPerEventType"
import { useFetchCustomerEventsPerDate } from "resources/stats/customerEventsPerDate"
import { useFetchCustomersLastUpdate } from "resources/stats/customersLastUpdate"
import { useFetchCustomersPerDate } from "resources/stats/customersPerDate"
import { useFetchCustomersPerAttribute } from "resources/stats/customersPerAttribute"
import { useFetchEventTypesLastEventTimes } from "resources/stats/eventTypesLastEventTimes"
import { useFetchCustomersPerSource } from "resources/stats/customersPerSource"
import { useFetchCustomersPerSourcesCount } from "resources/stats/customersPerSourcesCount"
import { useFetchLicenceUsage } from "resources/stats/licenceUsage"
import { useFetchSuspiciousCustomerEntities } from "resources/stats/suspiciousCustomerEntities"

class Dashboard extends PureComponent {
  cacheIntervalId = null

  constructor(props) {
    super(props)
    this.state = {
      orderDirs: {
        customerEventsPerEventType: "desc",
        customersPerAttributeCounts: "desc",
        eventTypesLastEventTimes: "desc",
        suspiciousCustomerEntities: "desc",
      },
      chartsExpanded: {},
      chartsProportions: {},
    }

    this.chartRefs = {}

    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    window.addEventListener("keyup", this.handleKeyUp, false)
  }

  toggleDataOrder = statePath => () => {
    this.setState(prevState => ({
      orderDirs: {
        ...prevState.orderDirs,
        [statePath]: prevState.orderDirs[statePath] === "asc" ? "desc" : "asc",
      },
    }))
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
  }

  handleDateValueChange = dateType => date => {
    this.setState({
      [dateType]: date,
    })
  }

  handleKeyUp = evt => {
    const keys = {
      27: () => {
        this.toggleChartsExpanded()()
      },
    }
    if (keys[evt.keyCode]) {
      keys[evt.keyCode]()
    }
  }

  toggleChartsExpanded = chart => () => {
    const { chartsProportions, chartsExpanded } = this.state
    if (!chart) {
      const [key] = Object.entries(chartsExpanded).find(([key, val]) => val === "expanded")
      chart = key
    }
    const chartElement = this.chartRefs[chart]
    if (chartElement) {
      const chartProportions = chartElement.getBoundingClientRect()

      if (chartsExpanded[chart] === "expanded") {
        this.setState(
          {
            chartsExpanded: { ...chartsExpanded, [chart]: "collapsed-start" },
          },
          () => {
            setTimeout(() => {
              this.setState({
                chartsExpanded: { ...chartsExpanded, [chart]: "collapsed-end" },
              })
              document.body.style["overflow"] = "auto"
              document.body.style["padding-right"] = "0px"
            }, 200)
          },
        )
      } else if (chartsExpanded[chart] === "collapsed-end" || !chartsExpanded[chart]) {
        const bodyWidth = document.body.clientWidth
        const windowWidth = window.innerWidth
        document.body.style["overflow"] = "hidden"
        document.body.style["padding-right"] = `${windowWidth - bodyWidth}px`

        this.setState(
          {
            chartsProportions: {
              ...chartsProportions,
              [chart]: {
                xLeft: chartProportions.left,
                xTop: chartProportions.top,
                xWidth: chartProportions.width,
                xHeight: chartProportions.height,
              },
            },
          },
          () => {
            setTimeout(() => {
              const windowWidth = window.innerWidth
              const windowHeight = window.innerHeight
              const expandedWidth = windowWidth * 0.9 > 1160 ? 1160 : windowWidth * 0.9
              const expandedHeight = windowHeight * 0.9

              this.setState(prevState => ({
                chartsExpanded: { ...prevState.chartsExpanded, [chart]: "expanded" },
                chartsProportions: {
                  ...prevState.chartsProportions,
                  [chart]: {
                    ...prevState.chartsProportions[chart],
                    lLeft: (windowWidth - expandedWidth) / 2,
                    lTop: (windowHeight - expandedHeight) / 2,
                    lWidth: expandedWidth,
                    lHeight: expandedHeight,
                  },
                },
              }))
            }, 50)
          },
        )
      }
    }
  }

  renderNAInfo = ({ text = "", vertical = false } = {}) => {
    return (
      <p className={`value error-value ${vertical ? "vertical" : ""}`}>
        <span className="chart-icon">
          <FontAwesomeIcon icon={["far", "chart-line-down"]} />
        </span>
        <span>
          {text ||
            "Likely there is not enough data to render this graph or cache is not refreshed. It can be solved by an administrator."}
        </span>
      </p>
    )
  }

  renderAbbreviateNumberWithTooltip = value => {
    if (value > 999) {
      return (
        <Tippy content={getUserFriendlyValueFormat(value, "int")}>
          <span>{abbreviateNumber(value)}</span>
        </Tippy>
      )
    } else return value
  }

  render() {
    const {
      hasAccess,
      attributesMapById,
      attributesCount,
      areAttributesFulfilled,
      eventsMap,
      eventsMapBySourceIdEventType,
      areEventsFulfilled,
      sources,
      hiddenSourceIds,
      areSourcesFulfilled,
      areGlobalSettingsFulfilled,
      customerEventsCount,
      customersCount,
      cacheStatus,
      customerEventsPerEventType,
      licenceUsage,
      customerEventsPerDate,
      customersPerDate,
      customersLastUpdate,
      customersPerSourcesCount,
      customersPerSource,
      eventTypesLastEventTimes,
      customersPerAttribute,
      suspiciousCustomerEntities,
      isLoadingCustomerEventsCount,
      isLoadingCustomersCount,
      isLoadingCacheStatus,
      isLoadingCustomerEventsPerEventType,
      isLoadingLicenceUsage,
      isLoadingCustomerEventsPerDate,
      isLoadingCustomersPerDate,
      isLoadingCustomersLastUpdate,
      isLoadingCustomersPerSourcesCount,
      isLoadingCustomersPerSource,
      isLoadingEventTypesLastEventTimes,
      isLoadingCustomersPerAttribute,
      isLoadingSuspiciousCustomerEntities,
    } = this.props
    const { orderDirs, chartsExpanded, chartsProportions } = this.state

    const isSomeChartExpanded = Object.values(chartsExpanded).some(val => val === "expanded")
    let sourcesSize = 0
    if (areSourcesFulfilled && areGlobalSettingsFulfilled) {
      if (Array.isArray(hiddenSourceIds) && hiddenSourceIds.length > 0) {
        sources.forEach(source => {
          if (!hiddenSourceIds.includes(source.id)) {
            sourcesSize++
          }
        })
      } else {
        sourcesSize = sources.length
      }
    }

    return (
      <Page
        title="Diagnostic dashboard"
        className="analytics-dashboard"
        description={
          <>
            <p>
              The Diagnostic Dashboard is a{" "}
              <strong>tool to get you to know your customer data</strong>. It shows how many
              profiles you have, which channels they are active on and what is the overall quality
              of the data you have collected on every one of your profiles.{" "}
              <strong>The dashboard does not provide insights into customer behaviour.</strong>
            </p>
            <p>
              To learn more about the Diagnostic Dashboard, please refer to{" "}
              <a
                href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-analytics#bkmrk-diagnostic-dashboard-0"
                target="_blank"
                rel="noreferrer noopener"
              >
                this article
              </a>
              .
            </p>
          </>
        }
      >
        <div className="dashboard-header">
          <Paper className="diagnostic-values">
            <div className={`box customers-count ${isLoadingCustomersCount ? "loading" : ""}`}>
              <div className="box-chart-title small">
                <h3>
                  No. of profiles{" "}
                  <InfoTooltip placement="right" interactive className="no-of-customers-tooltip">
                    Number of profile entities before and after{" "}
                    <a
                      href="https://docs.meiro.io/books/meiro-knowledge-base/page/identity-stitching-and-how-customer-identity-appears"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      profile stitching
                    </a>
                    .
                  </InfoTooltip>
                </h3>
              </div>
              {!isLoadingCustomersCount && (
                <p className="value relative">
                  {customersCount?.customer_entities_count
                    ? this.renderAbbreviateNumberWithTooltip(customersCount.customer_entities_count)
                    : "N/A"}
                  {typeof customersCount?.customers_before_stitching_count === "number" && (
                    <span className="before-stitching">
                      <span className="val">
                        {this.renderAbbreviateNumberWithTooltip(
                          customersCount.customers_before_stitching_count,
                        )}
                      </span>{" "}
                      before profile stitching
                    </span>
                  )}
                </p>
              )}
            </div>
            <div className="delimiter" />
            <div
              className={`box sources-count ${
                !areSourcesFulfilled || !areGlobalSettingsFulfilled ? "loading" : ""
              }`}
            >
              <div className="box-chart-title small">
                <h3>Connected Sources</h3>
              </div>
              {areSourcesFulfilled && areGlobalSettingsFulfilled && (
                <p className="value">{sourcesSize}</p>
              )}
            </div>
            <div className="delimiter" />
            <div className={`box attributes-count ${!areAttributesFulfilled ? "loading" : ""}`}>
              <div className="box-chart-title small">
                <h3>Profile Attributes</h3>
              </div>
              {areAttributesFulfilled && <p className="value">{attributesCount}</p>}
            </div>
            <div className="delimiter" />
            <div className={`box events-count ${isLoadingCustomerEventsCount ? "loading" : ""}`}>
              <div className="box-chart-title small">
                <h3>Total events</h3>
              </div>
              {!isLoadingCustomerEventsCount && (
                <p className="value">
                  {customerEventsCount?.customer_events_count
                    ? this.renderAbbreviateNumberWithTooltip(
                        customerEventsCount.customer_events_count,
                      )
                    : "N/A"}
                </p>
              )}
            </div>
          </Paper>
          <PaperHeader className="last-cache-update" size="small">
            <h4>Data updated</h4>
            <div className="cache-init-time">
              {isLoadingCacheStatus && <LoadingIndicator size="sm" />}
              {!isLoadingCacheStatus && cacheStatus && (
                <>
                  {!cacheStatus.is_refresh_running && (
                    <TimeAgo
                      date={DBtimestampToDate(cacheStatus.init_time)}
                      formatter={timeAgoFormatter({ seconds: "min" })}
                    />
                  )}
                  {cacheStatus.is_refresh_running && cacheStatus.refresh_init_time && (
                    <span className="refresh-in-progress">
                      Refresh is in progress{" "}
                      <strong>
                        <Duration
                          datetime={DBtimestampToDate(cacheStatus.refresh_init_time)}
                          units={["d", "h", "m"]}
                        />
                      </strong>
                    </span>
                  )}
                </>
              )}
            </div>
          </PaperHeader>
        </div>
        {hasAccess.data.licenceUsage && (
          <Paper className="licence-usage">
            <div className="title">Licence usage:</div>
            <div className="licence-usage-item">
              Monthly processed events&nbsp;&nbsp;
              <InfoTooltip>Total events processed in the last 30 days</InfoTooltip>
              <span className="value">
                {isLoadingLicenceUsage && <LoadingIndicator size="sm" />}

                {!isNil(licenceUsage?.customer_events_hidden_included_last_30_days_count) &&
                  abbreviateNumber(licenceUsage.customer_events_hidden_included_last_30_days_count)}

                {isNil(licenceUsage?.customer_events_hidden_included_last_30_days_count) &&
                  !isLoadingLicenceUsage &&
                  "N/A"}
              </span>
            </div>
            <div className="delimiter" />
            <div className="licence-usage-item">
              Total events stored (as of now)&nbsp;&nbsp;
              <InfoTooltip>Total events in storage, including hidden events</InfoTooltip>
              <span className="value">
                {isLoadingLicenceUsage && <LoadingIndicator size="sm" />}

                {!isNil(licenceUsage?.customer_events_hidden_included_count) &&
                  abbreviateNumber(licenceUsage.customer_events_hidden_included_count)}

                {isNil(licenceUsage?.customer_events_hidden_included_count) &&
                  !isLoadingLicenceUsage &&
                  "N/A"}
              </span>
            </div>
            <div className="delimiter" />
            <div className="licence-usage-item">
              Total number of unified profiles&nbsp;&nbsp;
              <InfoTooltip>Total number of unified profiles after profile stitching</InfoTooltip>
              <span className="value">
                {isLoadingCustomersCount ? (
                  <LoadingIndicator size="sm" />
                ) : customersCount?.customer_entities_count ? (
                  abbreviateNumber(customersCount.customer_entities_count)
                ) : (
                  "N/A"
                )}
              </span>
            </div>
          </Paper>
        )}
        <div className="grid">
          <Paper
            ref={el => {
              this.chartRefs["customerEventsPerDate"] = el
            }}
            style={{
              left:
                chartsExpanded["customerEventsPerDate"] === "expanded"
                  ? chartsProportions["customerEventsPerDate"].lLeft
                  : chartsProportions["customerEventsPerDate"]?.xLeft ?? "auto",
              top:
                chartsExpanded["customerEventsPerDate"] === "expanded"
                  ? chartsProportions["customerEventsPerDate"].lTop
                  : chartsProportions["customerEventsPerDate"]?.xTop ?? "auto",
              width:
                chartsExpanded["customerEventsPerDate"] === "expanded"
                  ? chartsProportions["customerEventsPerDate"].lWidth
                  : chartsProportions["customerEventsPerDate"]?.xWidth ?? "100%",
              height:
                chartsExpanded["customerEventsPerDate"] === "expanded"
                  ? chartsProportions["customerEventsPerDate"].lHeight
                  : chartsProportions["customerEventsPerDate"]?.xHeight ?? "100%",
            }}
            className={`box events-over-time ${isLoadingCustomerEventsPerDate ? "loading" : ""} ${
              chartsExpanded["customerEventsPerDate"] ?? "collapsed-end"
            }`}
          >
            <div className="box-chart-title expand-functionality">
              <div className="union-elements">
                <Tippy
                  content="This chart shows the number of events collected by your project over the last #
                    days. Watch out for extreme dips or spikes as they might indicate irregularity
                    in the data."
                >
                  <span className="dashboard-tooltip-icon">
                    <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                  </span>
                </Tippy>
                <h3>New events over time</h3>
              </div>
              <IconButton
                className="expand-button"
                icon={[
                  "far",
                  chartsExpanded["customerEventsPerDate"] !== "expanded" ? "expand-alt" : "times",
                ]}
                tooltip={
                  chartsExpanded["customerEventsPerDate"] !== "expanded" ? "Expand" : "Close"
                }
                onClick={this.toggleChartsExpanded("customerEventsPerDate")}
                disabled={!customerEventsPerDate || isLoadingCustomerEventsPerDate}
                variant="outlined"
              />
            </div>
            {!isNil(customerEventsPerDate?.data) &&
              !isEmpty(customerEventsPerDate?.data) &&
              areSourcesFulfilled && (
                <EventsOverTimeChart
                  rawData={customerEventsPerDate.data}
                  color="#68B3A1"
                  dataResolution={customerEventsPerDate.resolution}
                  className="horizontal-chart"
                  startDate={customerEventsPerDate.startDate}
                />
              )}
            {!customerEventsPerDate && !isLoadingCustomerEventsPerDate && this.renderNAInfo()}
          </Paper>

          <Paper
            ref={el => {
              this.chartRefs["customersPerDate"] = el
            }}
            style={{
              left:
                chartsExpanded["customersPerDate"] === "expanded"
                  ? chartsProportions["customersPerDate"].lLeft
                  : chartsProportions["customersPerDate"]?.xLeft ?? "auto",
              top:
                chartsExpanded["customersPerDate"] === "expanded"
                  ? chartsProportions["customersPerDate"].lTop
                  : chartsProportions["customersPerDate"]?.xTop ?? "auto",
              width:
                chartsExpanded["customersPerDate"] === "expanded"
                  ? chartsProportions["customersPerDate"].lWidth
                  : chartsProportions["customersPerDate"]?.xWidth ?? "100%",
              height:
                chartsExpanded["customersPerDate"] === "expanded"
                  ? chartsProportions["customersPerDate"].lHeight
                  : chartsProportions["customersPerDate"]?.xHeight ?? "100%",
            }}
            className={`box identified-customers-over-time ${
              isLoadingCustomersPerDate ? "loading" : ""
            } ${chartsExpanded["customersPerDate"] ?? "collapsed-end"}`}
          >
            <div className="box-chart-title expand-functionality">
              <div className="union-elements">
                <Tippy
                  content="This chart shows the number of identified profiles for your brand for the last
                    # days. Watch out for extreme dips or spikes as they might indicate irregularity
                    in the data and/or profile identification process."
                >
                  <span className="dashboard-tooltip-icon">
                    <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                  </span>
                </Tippy>

                <h3>No. of identified profiles over time</h3>
              </div>
              <IconButton
                className="expand-button"
                icon={[
                  "far",
                  chartsExpanded["customersPerDate"] !== "expanded" ? "expand-alt" : "times",
                ]}
                tooltip={chartsExpanded["customersPerDate"] !== "expanded" ? "Expand" : "Close"}
                onClick={this.toggleChartsExpanded("customersPerDate")}
                disabled={!customersPerDate || isLoadingCustomersPerDate}
                variant="outlined"
              />
            </div>
            {!isNil(customersPerDate?.data) && !isEmpty(customersPerDate?.data) && (
              <CustomersOverTimeChart
                rawData={customersPerDate.data}
                color="#19309F"
                dataResolution={customersPerDate.resolution}
                className="horizontal-chart"
              />
            )}
            {!customersPerDate && !isLoadingCustomersPerDate && this.renderNAInfo()}
          </Paper>

          <Paper
            ref={el => {
              this.chartRefs["customersUpdated"] = el
            }}
            style={{
              left:
                chartsExpanded["customersUpdated"] === "expanded"
                  ? chartsProportions["customersUpdated"].lLeft
                  : chartsProportions["customersUpdated"]?.xLeft ?? "auto",
              top:
                chartsExpanded["customersUpdated"] === "expanded"
                  ? chartsProportions["customersUpdated"].lTop
                  : chartsProportions["customersUpdated"]?.xTop ?? "auto",
              width:
                chartsExpanded["customersUpdated"] === "expanded"
                  ? chartsProportions["customersUpdated"].lWidth
                  : chartsProportions["customersUpdated"]?.xWidth ?? "100%",
              height:
                chartsExpanded["customersUpdated"] === "expanded"
                  ? chartsProportions["customersUpdated"].lHeight
                  : chartsProportions["customersUpdated"]?.xHeight ?? "100%",
            }}
            className={`box customers-updated ${isLoadingCustomersLastUpdate ? "loading" : ""} ${
              chartsExpanded["customersUpdated"] ?? "collapsed-end"
            }`}
          >
            <div className="box-chart-title expand-functionality">
              <div className="union-elements">
                <Tippy
                  content={
                    <span>
                      This chart shows if profile attributes are kept up to date. Contact Meiro
                      support if you see a significant amount of profiles in the &gt;96h section.
                    </span>
                  }
                >
                  <span className="dashboard-tooltip-icon">
                    <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                  </span>
                </Tippy>

                <h3>Profile attributes updated</h3>
              </div>
              <IconButton
                className="expand-button"
                icon={[
                  "far",
                  chartsExpanded["customersUpdated"] !== "expanded" ? "expand-alt" : "times",
                ]}
                tooltip={chartsExpanded["customersUpdated"] !== "expanded" ? "Expand" : "Close"}
                onClick={this.toggleChartsExpanded("customersUpdated")}
                disabled={!customersLastUpdate || isLoadingCustomersLastUpdate}
                variant="outlined"
              />
            </div>
            {!isNil(customersLastUpdate) && !isEmpty(customersLastUpdate) && (
              <CustomersUpdatedChart
                rawData={customersLastUpdate}
                color="#19309F"
                className="horizontal-chart"
              />
            )}
            {!customersLastUpdate && !isLoadingCustomersLastUpdate && this.renderNAInfo()}
          </Paper>

          <Paper
            ref={el => {
              this.chartRefs["customersPerSourcesCount"] = el
            }}
            style={{
              left:
                chartsExpanded["customersPerSourcesCount"] === "expanded"
                  ? chartsProportions["customersPerSourcesCount"].lLeft
                  : chartsProportions["customersPerSourcesCount"]?.xLeft ?? "auto",
              top:
                chartsExpanded["customersPerSourcesCount"] === "expanded"
                  ? chartsProportions["customersPerSourcesCount"].lTop
                  : chartsProportions["customersPerSourcesCount"]?.xTop ?? "auto",
              width:
                chartsExpanded["customersPerSourcesCount"] === "expanded"
                  ? chartsProportions["customersPerSourcesCount"].lWidth
                  : chartsProportions["customersPerSourcesCount"]?.xWidth ?? "100%",
              height:
                chartsExpanded["customersPerSourcesCount"] === "expanded"
                  ? chartsProportions["customersPerSourcesCount"].lHeight
                  : chartsProportions["customersPerSourcesCount"]?.xHeight ?? "100%",
            }}
            className={`box customers-per-sources-count ${
              isLoadingCustomersPerSourcesCount || !areSourcesFulfilled ? "loading" : ""
            } ${
              chartsExpanded["customersPerSourcesCount"]
                ? chartsExpanded["customersPerSourcesCount"]
                : "collapsed-end"
            }`}
          >
            <div className="box-chart-title expand-functionality">
              <div className="union-elements">
                <Tippy
                  content="This chart shows the number of profiles that are identified on 1 through x
                    sources. The maximum of x is the total number of data sources. The higher the
                    number of identified profiles in the higher cross-data sources, the better the
                    performance of the profiling process, and the better the quality of the
                    profile output."
                >
                  <span className="dashboard-tooltip-icon">
                    <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                  </span>
                </Tippy>

                <h3>No. of unique profiles identified across X sources</h3>
              </div>
              <IconButton
                className="expand-button"
                icon={[
                  "far",
                  chartsExpanded["customersPerSourcesCount"] !== "expanded"
                    ? "expand-alt"
                    : "times",
                ]}
                tooltip={
                  chartsExpanded["customersPerSourcesCount"] !== "expanded" ? "Expand" : "Close"
                }
                onClick={this.toggleChartsExpanded("customersPerSourcesCount")}
                disabled={
                  !customersPerSourcesCount ||
                  isLoadingCustomersPerSourcesCount ||
                  !areSourcesFulfilled
                }
                variant="outlined"
              />
            </div>
            {customersPerSourcesCount && areSourcesFulfilled && (
              <CustomersAcrossXSourcesChart
                rawData={customersPerSourcesCount}
                className="horizontal-chart"
              />
            )}
            {!customersPerSourcesCount && !isLoadingCustomersPerSourcesCount && this.renderNAInfo()}
          </Paper>
          <Paper
            ref={el => {
              this.chartRefs["customersPerSource"] = el
            }}
            style={{
              left:
                chartsExpanded["customersPerSource"] === "expanded"
                  ? chartsProportions["customersPerSource"].lLeft
                  : chartsProportions["customersPerSource"]?.xLeft ?? "auto",
              top:
                chartsExpanded["customersPerSource"] === "expanded"
                  ? chartsProportions["customersPerSource"].lTop
                  : chartsProportions["customersPerSource"]?.xTop ?? "auto",
              width:
                chartsExpanded["customersPerSource"] === "expanded"
                  ? chartsProportions["customersPerSource"].lWidth
                  : chartsProportions["customersPerSource"]?.xWidth ?? "100%",
              height:
                chartsExpanded["customersPerSource"] === "expanded"
                  ? chartsProportions["customersPerSource"].lHeight
                  : chartsProportions["customersPerSource"]?.xHeight ?? "100%",
            }}
            className={`box customers-per-source ${
              isLoadingCustomersPerSource || !areSourcesFulfilled ? "loading" : ""
            } ${chartsExpanded["customersPerSource"] ?? "collapsed-end"}`}
          >
            <div className="box-chart-title expand-functionality">
              <div className="union-elements">
                <Tippy content="This chart shows the number of identified profiles per data source.">
                  <span className="dashboard-tooltip-icon">
                    <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                  </span>
                </Tippy>

                <h3>No. of profiles identified per source</h3>
              </div>
              <IconButton
                className="expand-button"
                icon={[
                  "far",
                  chartsExpanded["customersPerSource"] !== "expanded" ? "expand-alt" : "times",
                ]}
                tooltip={chartsExpanded["customersPerSource"] !== "expanded" ? "Expand" : "Close"}
                onClick={this.toggleChartsExpanded("customersPerSource")}
                disabled={
                  !customersPerSource || isLoadingCustomersPerSource || !areSourcesFulfilled
                }
                variant="outlined"
              />
            </div>
            {customersPerSource && areSourcesFulfilled && (
              <CustomersPerSourceChart rawData={customersPerSource} className="horizontal-chart" />
            )}
            {!customersPerSource && !isLoadingCustomersPerSource && this.renderNAInfo()}
          </Paper>

          <Paper
            ref={el => {
              this.chartRefs["customerEventsPerEventType"] = el
            }}
            style={{
              left:
                chartsExpanded["customerEventsPerEventType"] === "expanded"
                  ? chartsProportions["customerEventsPerEventType"].lLeft
                  : chartsProportions["customerEventsPerEventType"]?.xLeft ?? "auto",
              top:
                chartsExpanded["customerEventsPerEventType"] === "expanded"
                  ? chartsProportions["customerEventsPerEventType"].lTop
                  : chartsProportions["customerEventsPerEventType"]?.xTop ?? "auto",
              width:
                chartsExpanded["customerEventsPerEventType"] === "expanded"
                  ? chartsProportions["customerEventsPerEventType"].lWidth
                  : chartsProportions["customerEventsPerEventType"]?.xWidth ?? "100%",
              height:
                chartsExpanded["customerEventsPerEventType"] === "expanded"
                  ? chartsProportions["customerEventsPerEventType"].lHeight
                  : chartsProportions["customerEventsPerEventType"]?.xHeight ?? "100%",
            }}
            className={`box events-per-event-type ${
              isLoadingCustomerEventsPerEventType || !areSourcesFulfilled || !areEventsFulfilled
                ? "loading"
                : ""
            } ${chartsExpanded["customerEventsPerEventType"] ?? "collapsed-end"}`}
          >
            <div className="box-chart-title sort-functionality expand-functionality">
              <div className="union-elements">
                <Tippy
                  content="This chart shows customer engagements across different types of events captured
                    in your project. Check out which channels and/or activities your customer
                    engages in the most with your brand."
                >
                  <span className="dashboard-tooltip-icon">
                    <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                  </span>
                </Tippy>

                <h3>No. of events per event type</h3>
              </div>
              <div className="control-buttons">
                <IconButton
                  className="sort-button"
                  icon={[
                    "far",
                    orderDirs.customerEventsPerEventType === "desc"
                      ? "sort-amount-down"
                      : "sort-amount-up",
                  ]}
                  tooltip={
                    orderDirs.customerEventsPerEventType === "desc"
                      ? "Sort ascending"
                      : "Sort descending"
                  }
                  onClick={this.toggleDataOrder("customerEventsPerEventType")}
                  variant="outlined"
                />
                <IconButton
                  className="expand-button"
                  icon={[
                    "far",
                    chartsExpanded["customerEventsPerEventType"] !== "expanded"
                      ? "expand-alt"
                      : "times",
                  ]}
                  tooltip={
                    chartsExpanded["customerEventsPerEventType"] !== "expanded" ? "Expand" : "Close"
                  }
                  onClick={this.toggleChartsExpanded("customerEventsPerEventType")}
                  disabled={
                    !customerEventsPerEventType ||
                    isLoadingCustomerEventsPerEventType ||
                    !areSourcesFulfilled ||
                    !areEventsFulfilled
                  }
                  variant="outlined"
                />
              </div>
            </div>
            {customerEventsPerEventType && areSourcesFulfilled && areEventsFulfilled && (
              <VerticalChart
                sourcesLegend={sources}
                data={customerEventsPerEventType}
                order={orderDirs.customerEventsPerEventType}
                labels={eventsMapBySourceIdEventType}
                type="integer"
                uniqueTooltipIdPrefix="events-per-event-type"
                expanded={chartsExpanded["customerEventsPerEventType"] === "expanded"}
              />
            )}
            {!customerEventsPerEventType &&
              !isLoadingCustomerEventsPerEventType &&
              this.renderNAInfo({ vertical: true })}
          </Paper>
          <Paper
            ref={el => {
              this.chartRefs["eventTypesLastEventTimes"] = el
            }}
            style={{
              left:
                chartsExpanded["eventTypesLastEventTimes"] === "expanded"
                  ? chartsProportions["eventTypesLastEventTimes"].lLeft
                  : chartsProportions["eventTypesLastEventTimes"]?.xLeft ?? "auto",
              top:
                chartsExpanded["eventTypesLastEventTimes"] === "expanded"
                  ? chartsProportions["eventTypesLastEventTimes"].lTop
                  : chartsProportions["eventTypesLastEventTimes"]?.xTop ?? "auto",
              width:
                chartsExpanded["eventTypesLastEventTimes"] === "expanded"
                  ? chartsProportions["eventTypesLastEventTimes"].lWidth
                  : chartsProportions["eventTypesLastEventTimes"]?.xWidth ?? "100%",
              height:
                chartsExpanded["eventTypesLastEventTimes"] === "expanded"
                  ? chartsProportions["eventTypesLastEventTimes"].lHeight
                  : chartsProportions["eventTypesLastEventTimes"]?.xHeight ?? "100%",
            }}
            className={`box time-since-last-received-event ${
              isLoadingEventTypesLastEventTimes || !areEventsFulfilled || !areSourcesFulfilled
                ? "loading"
                : ""
            } ${chartsExpanded["eventTypesLastEventTimes"] ?? "collapsed-end"}`}
          >
            <div className="box-chart-title sort-functionality expand-functionality">
              <div className="union-elements">
                <Tippy
                  content="This chart shows the elapsed time since the last event of each event type.
                    Events are usually collected daily. If the event data reception time is
                    exceeded, it may indicate that your data is not collected frequently enough. In
                    some cases, this may indicate that there are no new events available (for
                    example there have not been transactions for that particular day made)."
                >
                  <span className="dashboard-tooltip-icon">
                    <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                  </span>
                </Tippy>

                <h3>Time since the last event received</h3>
              </div>
              <div className="control-buttons">
                <IconButton
                  className="sort-button"
                  icon={[
                    "far",
                    orderDirs.eventTypesLastEventTimes === "desc"
                      ? "sort-amount-down"
                      : "sort-amount-up",
                  ]}
                  tooltip={
                    orderDirs.eventTypesLastEventTimes === "desc"
                      ? "Sort ascending"
                      : "Sort descending"
                  }
                  onClick={this.toggleDataOrder("eventTypesLastEventTimes")}
                  variant="outlined"
                />
                <IconButton
                  className="expand-button"
                  icon={[
                    "far",
                    chartsExpanded["eventTypesLastEventTimes"] !== "expanded"
                      ? "expand-alt"
                      : "times",
                  ]}
                  tooltip={
                    chartsExpanded["eventTypesLastEventTimes"] !== "expanded" ? "Expand" : "Close"
                  }
                  onClick={this.toggleChartsExpanded("eventTypesLastEventTimes")}
                  disabled={
                    !eventTypesLastEventTimes ||
                    isLoadingEventTypesLastEventTimes ||
                    !areEventsFulfilled ||
                    !areSourcesFulfilled
                  }
                  variant="outlined"
                />
              </div>
            </div>
            {eventTypesLastEventTimes && areEventsFulfilled && areSourcesFulfilled && (
              <VerticalChart
                sourcesLegend={sources}
                data={eventTypesLastEventTimes}
                order={orderDirs.eventTypesLastEventTimes}
                labels={eventsMap}
                type="time"
                uniqueTooltipIdPrefix="events-last-received"
                expanded={chartsExpanded["eventTypesLastEventTimes"] === "expanded"}
              />
            )}
            {!eventTypesLastEventTimes &&
              !isLoadingEventTypesLastEventTimes &&
              this.renderNAInfo({ vertical: true })}
          </Paper>

          <Paper
            ref={el => {
              this.chartRefs["customersPerAttributeCounts"] = el
            }}
            style={{
              left:
                chartsExpanded["customersPerAttributeCounts"] === "expanded"
                  ? chartsProportions["customersPerAttributeCounts"].lLeft
                  : chartsProportions["customersPerAttributeCounts"]?.xLeft ?? "auto",
              top:
                chartsExpanded["customersPerAttributeCounts"] === "expanded"
                  ? chartsProportions["customersPerAttributeCounts"].lTop
                  : chartsProportions["customersPerAttributeCounts"]?.xTop ?? "auto",
              width:
                chartsExpanded["customersPerAttributeCounts"] === "expanded"
                  ? chartsProportions["customersPerAttributeCounts"].lWidth
                  : chartsProportions["customersPerAttributeCounts"]?.xWidth ?? "100%",
              height:
                chartsExpanded["customersPerAttributeCounts"] === "expanded"
                  ? chartsProportions["customersPerAttributeCounts"].lHeight
                  : chartsProportions["customersPerAttributeCounts"]?.xHeight ?? "100%",
            }}
            className={`box customers-per-attribute ${
              isLoadingCustomersPerAttribute || !areAttributesFulfilled || !areSourcesFulfilled
                ? "loading"
                : ""
            } ${chartsExpanded["customersPerAttributeCounts"] ?? "collapsed-end"}`}
          >
            <div className="box-chart-title sort-functionality expand-functionality">
              <div className="union-elements">
                <Tippy
                  content="This chart shows the number of identified profiles by each attribute in your
                    project."
                >
                  <span className="dashboard-tooltip-icon">
                    <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                  </span>
                </Tippy>

                <h3>No. of profiles per attribute</h3>
              </div>
              <div className="control-buttons">
                <IconButton
                  className="sort-button"
                  onClick={this.toggleDataOrder("customersPerAttributeCounts")}
                  icon={[
                    "far",
                    orderDirs.customersPerAttributeCounts === "desc"
                      ? "sort-amount-down"
                      : "sort-amount-up",
                  ]}
                  tooltip={
                    orderDirs.customersPerAttributeCounts === "desc"
                      ? "Sort ascending"
                      : "Sort descending"
                  }
                  variant="outlined"
                />
                <IconButton
                  className="expand-button"
                  icon={[
                    "far",
                    chartsExpanded["customersPerAttributeCounts"] !== "expanded"
                      ? "expand-alt"
                      : "times",
                  ]}
                  tooltip={
                    chartsExpanded["customersPerAttributeCounts"] !== "expanded"
                      ? "Expand"
                      : "Close"
                  }
                  onClick={this.toggleChartsExpanded("customersPerAttributeCounts")}
                  disabled={
                    !customersPerAttribute ||
                    isLoadingCustomersPerAttribute ||
                    !areAttributesFulfilled ||
                    !areSourcesFulfilled
                  }
                  variant="outlined"
                />
              </div>
            </div>
            {customersPerAttribute && areAttributesFulfilled && areSourcesFulfilled && (
              <VerticalChart
                sourcesLegend={sources}
                data={customersPerAttribute}
                order={orderDirs.customersPerAttributeCounts}
                labels={attributesMapById}
                type="integer"
                uniqueTooltipIdPrefix="customer-per-attribute"
                expanded={chartsExpanded["customersPerAttributeCounts"] === "expanded"}
              />
            )}
            {!customersPerAttribute &&
              !isLoadingCustomersPerAttribute &&
              this.renderNAInfo({ vertical: true })}
          </Paper>
          <Paper
            ref={el => {
              this.chartRefs["suspiciousCustomerEntities"] = el
            }}
            style={{
              left:
                chartsExpanded["suspiciousCustomerEntities"] === "expanded"
                  ? chartsProportions["suspiciousCustomerEntities"].lLeft
                  : chartsProportions["suspiciousCustomerEntities"]?.xLeft ?? "auto",
              top:
                chartsExpanded["suspiciousCustomerEntities"] === "expanded"
                  ? chartsProportions["suspiciousCustomerEntities"].lTop
                  : chartsProportions["suspiciousCustomerEntities"]?.xTop ?? "auto",
              width:
                chartsExpanded["suspiciousCustomerEntities"] === "expanded"
                  ? chartsProportions["suspiciousCustomerEntities"].lWidth
                  : chartsProportions["suspiciousCustomerEntities"]?.xWidth ?? "100%",
              height:
                chartsExpanded["suspiciousCustomerEntities"] === "expanded"
                  ? chartsProportions["suspiciousCustomerEntities"].lHeight
                  : chartsProportions["suspiciousCustomerEntities"]?.xHeight ?? "100%",
            }}
            className={`box suspicious-customer-entities ${
              isLoadingSuspiciousCustomerEntities ? "loading" : ""
            } ${chartsExpanded["suspiciousCustomerEntities"] ?? "collapsed-end"}`}
          >
            <div className="box-chart-title expand-functionality">
              <div className="union-elements">
                <Tippy content="This chart shows the list of top 10 profiles with a suspiciously large number of events (>= 10000). By clicking on profile ID, the user will be redirected to the profile page.">
                  <span className="dashboard-tooltip-icon">
                    <FontAwesomeIcon className="info-icon" icon={["fas", "info-circle"]} />
                  </span>
                </Tippy>

                <h3>Suspicious profiles</h3>
              </div>
              <div className="control-buttons">
                <IconButton
                  className="sort-button"
                  disabled={
                    isNil(suspiciousCustomerEntities?.data) ||
                    isEmpty(suspiciousCustomerEntities.data) ||
                    isLoadingSuspiciousCustomerEntities
                  }
                  onClick={this.toggleDataOrder("suspiciousCustomerEntities")}
                  icon={[
                    "far",
                    orderDirs.suspiciousCustomerEntities === "desc"
                      ? "sort-amount-down"
                      : "sort-amount-up",
                  ]}
                  tooltip={
                    orderDirs.suspiciousCustomerEntities === "desc"
                      ? "Sort ascending"
                      : "Sort descending"
                  }
                  variant="outlined"
                />
                <IconButton
                  className="expand-button"
                  icon={[
                    "far",
                    chartsExpanded["suspiciousCustomerEntities"] !== "expanded"
                      ? "expand-alt"
                      : "times",
                  ]}
                  tooltip={
                    chartsExpanded["suspiciousCustomerEntities"] !== "expanded" ? "Expand" : "Close"
                  }
                  onClick={this.toggleChartsExpanded("suspiciousCustomerEntities")}
                  disabled={
                    isNil(suspiciousCustomerEntities?.data) ||
                    isEmpty(suspiciousCustomerEntities.data) ||
                    isLoadingSuspiciousCustomerEntities
                  }
                  variant="outlined"
                />
              </div>
            </div>
            {!isNil(suspiciousCustomerEntities?.data) &&
              !isEmpty(suspiciousCustomerEntities.data) && (
                <SuspiciousCustomerEntities
                  expanded={chartsExpanded["suspiciousCustomerEntities"] === "expanded"}
                  data={suspiciousCustomerEntities.data}
                  order={orderDirs.suspiciousCustomerEntities}
                  total={suspiciousCustomerEntities.totalCount}
                />
              )}
            {(isNil(suspiciousCustomerEntities?.data) ||
              isEmpty(suspiciousCustomerEntities.data)) &&
              !isLoadingSuspiciousCustomerEntities &&
              this.renderNAInfo({
                text: "There are no suspicious customer entities to render this graph.",
              })}
          </Paper>
        </div>
        <CSSTransition in={isSomeChartExpanded} timeout={200} classNames="fade" unmountOnExit>
          <div className="expand-overlay" onClick={this.toggleChartsExpanded()} />
        </CSSTransition>
      </Page>
    )
  }
}

export default props => {
  const { data: attributesMap = {}, isSuccess } = useFetchAttributesMap()
  const { data: attributesCount } = useFetchAttributesCount()
  const { data: events = [], isSuccess: areEventsFulfilled } = useFetchAllEvents()
  const { data: eventsMap = {} } = useFetchEventsMap()
  const { data: sources = [], isSuccess: areSourcesFulfilled } = useFetchAllDataSources()
  const hasAccess = useHasAccess()

  const eventsMapBySourceIdEventType = Object.fromEntries(
    events.map(event => [`${event.source.id}-${event.type}`, event]),
  )

  const { data: globalSettings, isSuccess: areGlobalSettingsFulfilled } = useFetchGlobalSettings()
  const hiddenSourceIds = globalSettings?.["hidden_data_sources_from_diagnostic_dashboard"]?.value

  const { data: customersCount, isLoading: isLoadingCustomersCount } = useFetchCustomersCount()
  const { data: customerEventsCount, isLoading: isLoadingCustomerEventsCount } =
    useFetchCustomerEventsCount()
  const { data: cacheStatus, isLoading: isLoadingCacheStatus } = useFetchCacheStatus({
    refetchInterval: (_data, query) => (query.state.error ? false : 5 * 1000),
  })
  const { data: customerEventsPerEventType, isLoading: isLoadingCustomerEventsPerEventType } =
    useFetchCustomerEventsPerEventType()
  const { data: customerEventsPerDate, isLoading: isLoadingCustomerEventsPerDate } =
    useFetchCustomerEventsPerDate()
  const { data: customersLastUpdate, isLoading: isLoadingCustomersLastUpdate } =
    useFetchCustomersLastUpdate()
  const { data: customersPerDate, isLoading: isLoadingCustomersPerDate } =
    useFetchCustomersPerDate()
  const { data: customersPerAttribute, isLoading: isLoadingCustomersPerAttribute } =
    useFetchCustomersPerAttribute()
  const { data: eventTypesLastEventTimes, isLoading: isLoadingEventTypesLastEventTimes } =
    useFetchEventTypesLastEventTimes()
  const { data: customersPerSource, isLoading: isLoadingCustomersPerSource } =
    useFetchCustomersPerSource()
  const { data: customersPerSourcesCount, isLoading: isLoadingCustomersPerSourcesCount } =
    useFetchCustomersPerSourcesCount()
  const {
    data: licenceUsage,
    isLoading: isLoadingLicenceUsage,
    isFetching: isFetchingLicenceUsage,
  } = useFetchLicenceUsage()
  const { data: suspiciousCustomerEntities, isLoading: isLoadingSuspiciousCustomerEntities } =
    useFetchSuspiciousCustomerEntities()

  return (
    <Dashboard
      {...props}
      attributesMapById={attributesMap}
      areAttributesFulfilled={isSuccess}
      attributesCount={attributesCount}
      hasAccess={hasAccess}
      areEventsFulfilled={areEventsFulfilled}
      eventsMapBySourceIdEventType={eventsMapBySourceIdEventType}
      eventsMap={eventsMap}
      sources={sources}
      areSourcesFulfilled={areSourcesFulfilled}
      hiddenSourceIds={hiddenSourceIds}
      areGlobalSettingsFulfilled={areGlobalSettingsFulfilled}
      customerEventsCount={customerEventsCount}
      customersCount={customersCount}
      cacheStatus={cacheStatus}
      customerEventsPerEventType={customerEventsPerEventType}
      customerEventsPerDate={customerEventsPerDate}
      customersLastUpdate={customersLastUpdate}
      customersPerDate={customersPerDate}
      customersPerAttribute={customersPerAttribute?.map(({ attribute_id, count }) => ({
        item_id: attribute_id,
        value: count,
      }))}
      eventTypesLastEventTimes={eventTypesLastEventTimes}
      customersPerSource={customersPerSource}
      customersPerSourcesCount={customersPerSourcesCount}
      licenceUsage={licenceUsage}
      suspiciousCustomerEntities={suspiciousCustomerEntities}
      isLoadingCustomerEventsCount={isLoadingCustomerEventsCount}
      isLoadingCustomersCount={isLoadingCustomersCount}
      isLoadingCacheStatus={isLoadingCacheStatus}
      isLoadingCustomerEventsPerEventType={isLoadingCustomerEventsPerEventType}
      isLoadingCustomerEventsPerDate={isLoadingCustomerEventsPerDate}
      isLoadingCustomersLastUpdate={isLoadingCustomersLastUpdate}
      isLoadingCustomersPerDate={isLoadingCustomersPerDate}
      isLoadingCustomersPerAttribute={isLoadingCustomersPerAttribute}
      isLoadingEventTypesLastEventTimes={isLoadingEventTypesLastEventTimes}
      isLoadingCustomersPerSource={isLoadingCustomersPerSource}
      isLoadingCustomersPerSourcesCount={isLoadingCustomersPerSourcesCount}
      isLoadingLicenceUsage={isLoadingLicenceUsage && isFetchingLicenceUsage}
      isLoadingSuspiciousCustomerEntities={isLoadingSuspiciousCustomerEntities}
    />
  )
}

import React, {
  ComponentPropsWithRef,
  ForwardedRef,
  forwardRef,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react"
import classNames from "classnames"
import styles from "./PathField.module.scss"
import { dropLast, update } from "ramda"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ErrorTippy from "../ErrorTippy/ErrorTippy"
import { hasTrailingWhitespaces } from "helpers/validators.helper"
import WhitespaceWarning from "../WhitespaceWarning/WhitespaceWarning"

type PathFieldProps = {
  label?: string
  error?: string
  className?: string
  value?: string[]
  disabled?: boolean
  onChange: (value: string[]) => void
} & Omit<ComponentPropsWithRef<"input">, "value" | "onChange">

const PathField = forwardRef(function PathField(
  { label, error, className, value, onChange, onFocus, onBlur, disabled }: PathFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const inputsRef = useRef<HTMLInputElement[]>([])

  // We use inner value and onChange because we don't want to send up empty strings; this makes
  // validation easier
  const [innerValue, setInnerValue] = useState(value ?? [""])
  const innerOnChange = (val: string[]) => {
    setInnerValue(val)
    onChange(val.filter(item => item !== ""))
  }

  useEffect(() => {
    inputsRef.current = inputsRef.current.slice(0, innerValue.length)
  }, [innerValue.length])

  const focusLastInput = () => {
    inputsRef.current[inputsRef.current.length - 1].focus()
  }

  const [focused, setFocused] = useState(false)
  const [whitespaceWarningVisible, setWhitespaceWarningVisible] = useState(false)
  const [hasWhitespace, setHasWhitespace] = useState(false)

  useEffect(() => {
    setHasWhitespace(value?.some(v => hasTrailingWhitespaces(v)) ?? false)
  }, [value])

  useEffect(() => {
    if (error) return

    if (hasWhitespace) setWhitespaceWarningVisible(focused)
  }, [error, focused, hasWhitespace])

  return (
    <div
      className={classNames(styles.container, className, {
        [styles.hasError]: error,
        [styles.disabled]: disabled,
        [styles.hasWarning]: whitespaceWarningVisible,
      })}
    >
      <div className={styles.labelWrapper}>
        {label && <label className={styles.label}>{label}</label>}

        <div className={styles.hint}>Press Enter to separate path parts</div>
      </div>
      <ErrorTippy disabled={!error} content={error}>
        <div className={styles.warningWrapper}>
          <div className={styles.inputs}>
            {innerValue.map((item, index) => (
              <Fragment key={index}>
                {index !== 0 && (
                  <FontAwesomeIcon className={styles.separator} icon={["fas", "chevron-right"]} />
                )}
                <div className={styles.inputWrapper}>
                  <div className={styles.ghostText}>{item}</div>
                  <input
                    ref={el => {
                      inputsRef.current[index] = el!
                      // Pass ref to last input; react-hook-form uses this to focus on error
                      if (index === innerValue.length - 1 && ref) {
                        if ("current" in ref) {
                          ref.current = el
                        }
                        if (typeof ref === "function") {
                          ref(el)
                        }
                      }
                    }}
                    value={item}
                    onChange={e => innerOnChange(update(index, e.target.value, innerValue))}
                    onFocus={event => {
                      setFocused(true)
                      onFocus?.(event)
                    }}
                    onBlur={event => {
                      setFocused(false)
                      onBlur?.(event)
                    }}
                    onKeyDown={e => {
                      if (e.code === "Enter") {
                        e.preventDefault()
                        if (item !== "") {
                          innerOnChange([...innerValue, ""])
                          setTimeout(focusLastInput, 0)
                        }
                      }

                      if (e.code === "Backspace" && item.length === 0 && index !== 0) {
                        e.preventDefault()
                        innerOnChange(dropLast(1, innerValue))
                        setTimeout(focusLastInput, 0)
                      }
                    }}
                    disabled={disabled}
                  />
                </div>
              </Fragment>
            ))}
          </div>
          {hasWhitespace && <WhitespaceWarning visible={whitespaceWarningVisible} />}
        </div>
      </ErrorTippy>
    </div>
  )
})

export default PathField

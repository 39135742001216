import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import Tag from "../../elements/Tag"
import Button from "../../elements/Button/Button"
import "./TagPicker.scss"
import { isNil } from "ramda"

class TagPicker extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      isHovering: null,
    }

    this.wrapperRef = React.createRef()

    window.addEventListener("keyup", this.handleKeyUp, false)
    setTimeout(() => document.addEventListener("click", this.handleOutsideClick, false), 0)
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
    document.removeEventListener("click", this.handleOutsideClick, false)
  }

  handleMouseHover = tagId => () => {
    this.setState({
      isHovering: tagId,
    })
  }

  toggleOpen = () => {
    this.setState(prevState => ({
      open: !prevState.open,
      isHovering: null,
    }))
  }

  handleKeyUp = evt => {
    const keys = {
      27: () => {
        if (this.state.open) {
          this.toggleOpen()
        }
      },
    }
    if (keys[evt.keyCode]) {
      keys[evt.keyCode]()
    }
  }

  handleOutsideClick = evt => {
    if (this.state.open && !isNil(this.wrapperRef.current)) {
      if (!this.wrapperRef.current.contains(evt.target)) {
        this.toggleOpen()
      }
    }
  }

  onTagClick = tag => () => {
    this.props.onTagSelect(tag.id)
    this.toggleOpen()
  }

  render() {
    const {
      className,
      selectedTagIds,
      allTags,
      disabled,
      type,
      dropdownAlign = "left",
    } = this.props
    const { open, isHovering } = this.state

    const displayTags = allTags.filter(tag => !selectedTagIds.includes(tag.id))

    return (
      <div
        data-testid="tag-picker"
        className={`tag-picker ${className ? className : ""}`}
        ref={this.wrapperRef}
      >
        <Button
          color="grey"
          size="xs"
          variant="outlined"
          className="add-button"
          onClick={this.toggleOpen}
          disabled={disabled}
        >
          <span className="plus">+</span>
          {Array.isArray(selectedTagIds) && selectedTagIds.length === 0 && (
            <span className="add-label"> add {type ? type : "tag"}</span>
          )}
        </Button>
        {open && (
          <div className={`pick-area ${dropdownAlign} ${isHovering ? "active" : ""}`}>
            {displayTags.length > 0 &&
              displayTags.map(tag => (
                <Tag
                  color={tag.color ? tag.color : "primary"}
                  size="small"
                  key={tag.id}
                  onClick={this.onTagClick(tag)}
                  onMouseEnter={this.handleMouseHover(tag.id)}
                  onMouseLeave={this.handleMouseHover(null)}
                  className={isHovering === tag.id ? "is-hovering" : ""}
                >
                  {tag.name}
                </Tag>
              ))}
            {displayTags.length === 0 && <p className="warning-red">No options to select</p>}
          </div>
        )}
      </div>
    )
  }
}

TagPicker.propTypes = {
  selectedTagIds: PropTypes.array.isRequired,
  allTags: PropTypes.array.isRequired,
  onTagSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
}

export default TagPicker

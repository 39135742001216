import React from "react"
import JSPath from "jspath"
import { getUserFriendlyValueFormat } from "./attributeValue.helper"
import { path as get } from "ramda"

export const errorMessage = value => (
  <span className="error">
    Error: The value has a different type than defined in the event schema. Received value:{" "}
    {JSON.stringify(value)}
  </span>
)

export const isCorrectType = (value, type) => {
  switch (type) {
    case "number": {
      return typeof value === "number"
    }
    case "datetime": {
      return !isNaN(new Date(value))
    }
    case "string": {
      return typeof value === "string"
    }
    default: {
      return true
    }
  }
}

export const getValue = (customerEvent, type, path, isArray = false) => {
  let values = []
  try {
    values = JSPath.apply(path, customerEvent.payload)
  } catch {
    if (isArray) {
      return []
    } else {
      return "—"
    }
  }

  if (isArray) {
    switch (type) {
      case "number": {
        return values.map(value => getUserFriendlyValueFormat(value, "int"))
      }
      case "datetime": {
        return values.map(value => getUserFriendlyValueFormat(value, "datetime"))
      }
      case "string": {
        return values
      }
      default: {
        return values
      }
    }
  } else {
    if (values.length === 0) {
      return "—"
    } else {
      switch (type) {
        case "number": {
          return getUserFriendlyValueFormat(values[0], "int")
        }
        case "datetime": {
          return getUserFriendlyValueFormat(values[0], "datetime")
        }
        case "string": {
          return values[0]
        }
        default: {
          return values[0]
        }
      }
    }
  }
}

export const getCustomerEventTitle = (customerEvent, schema, defaultName) => {
  if (!schema.title) {
    return defaultName
  }

  try {
    return schema.title.replaceAll(/\$\{(.+?)\}/g, (_, pathStr) => {
      const path = pathStr.split(".")
      const value = get(path, customerEvent.payload)
      if (!value) {
        throw new Error()
      }
      return value
    })
  } catch {
    return defaultName
  }
}

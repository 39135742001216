import { reject, whereEq } from "ramda"
import { SEGMENT_ANALYTICS_FUNCTIONS } from "sharedConstants"

export const getFunctionName = (functionValue, valueType) => {
  if (valueType && ["date", "datetime"].includes(valueType)) {
    if (functionValue === SEGMENT_ANALYTICS_FUNCTIONS.EARLIEST.value) {
      return SEGMENT_ANALYTICS_FUNCTIONS.EARLIEST.label
    } else if (functionValue === SEGMENT_ANALYTICS_FUNCTIONS.LATEST.value) {
      return SEGMENT_ANALYTICS_FUNCTIONS.LATEST.label
    }
  }
  return SEGMENT_ANALYTICS_FUNCTIONS[functionValue.toUpperCase()].label
}

export const getPercentageText = (percentage, round = 0) => {
  let low, high
  switch (round) {
    case 1: {
      low = 1
      high = 99.9
      break
    }
    case 2: {
      low = 1
      high = 99.99
      break
    }
    default: {
      low = 1
      high = 99
    }
  }
  if (percentage < low && percentage !== 0) {
    return `<${low}%`
  } else if (percentage > high && percentage !== 100) {
    return `>${high}%`
  } else {
    return `${Math.round(percentage * 10 ** round) / 10 ** round}%`
  }
}

export const reorderStickyInsights = (insights, stickyInsightsIds) => {
  if (stickyInsightsIds.length !== 0 && Array.isArray(insights)) {
    const stickyOnes = []
    stickyInsightsIds.forEach(id => {
      const insight = insights.find(whereEq({ id }))
      if (insight) {
        stickyOnes.push(insight)
      }
    })
    const filteredOut = reject(({ id }) => stickyInsightsIds.includes(id), insights)
    return stickyOnes.concat(filteredOut)
  } else {
    return insights
  }
}

import requestFactory from "api/request"
import activationEndpoints from "./activation/emailActivationEndpoints"
import template from "./emailTemplate/emailTemplateEndpoints"
import {
  CreateEmailPayload,
  Email,
  EmailListResponse,
  ModifyEmailPayload,
  EmailsListDeletedParams,
  EmailsListDeletedResponse,
  BlockEmailPayload,
  UnblockEmailPayload,
  EmailResponse,
} from "./emailTypes"

const email = {
  list(): Promise<EmailListResponse> {
    return requestFactory("get", "/emails")
  },
  retrieve(id: Email["id"]): Promise<EmailResponse> {
    return requestFactory("get", `/emails/${id}`)
  },
  create(data: CreateEmailPayload): Promise<EmailResponse> {
    return requestFactory("post", "/emails", data)
  },
  modify(id: Email["id"], data: ModifyEmailPayload): Promise<EmailResponse> {
    return requestFactory("patch", `/emails/${id}`, data)
  },
  delete(id: Email["id"]): Promise<void> {
    return requestFactory("delete", `/emails/${id}`)
  },
  listDeleted({
    offset = 0,
    limit = 20,
    searched_text = "",
  }: EmailsListDeletedParams = {}): Promise<EmailsListDeletedResponse> {
    return requestFactory("get", "/emails/trash", { offset, limit, searched_text })
  },
  restoreDeleted(id: Email["id"]): Promise<EmailResponse> {
    return requestFactory("patch", `/emails/trash/${id}`)
  },
  block(data: BlockEmailPayload): Promise<void> {
    return requestFactory("post", "/emails/block", data)
  },
  unblock(data: UnblockEmailPayload): Promise<void> {
    return requestFactory("post", "/emails/unblock", data)
  },
  activation: activationEndpoints,
  template,
}

export default email

import React from "react"
import PropTypes from "prop-types"
import { abbreviateNumber } from "helpers/number.helper"
import "./SegmentationNumbers.scss"
import Tippy from "@tippyjs/react"
import { isNil } from "ramda"

const SegmentationNumbers = ({
  isLoading,
  totalNumber,
  segmentedNumber,
  error,
  plusUpTo,
  errorMessage,
  showBar,
  showLabel,
  showTotal,
  uniqueDataTipId,
  className,
}) => {
  let percentage = isLoading ? "" : "N/A"

  if (
    !isLoading &&
    typeof totalNumber === "number" &&
    typeof segmentedNumber === "number" &&
    totalNumber > 0 &&
    segmentedNumber !== -1
  ) {
    percentage = Math.round(segmentedNumber / (totalNumber / 100))
    if (percentage === 0 && segmentedNumber > 0) {
      percentage = "<1"
    }
    if (percentage === 100 && segmentedNumber < totalNumber) {
      percentage = ">99"
    }
  }

  if (totalNumber === 0 && segmentedNumber === 0) {
    percentage = "0"
  }

  const isSegmentedLoading =
    isLoading && !(typeof segmentedNumber === "number" && segmentedNumber !== -1)
  const isTotalLoading = isLoading && typeof totalNumber !== "number"

  const isPercentageLoading = isSegmentedLoading || isTotalLoading
  const isError = error || Boolean(errorMessage)

  if (!isNil(totalNumber) && !isNil(segmentedNumber) && !isNil(plusUpTo)) {
    plusUpTo = Math.min(plusUpTo, totalNumber - segmentedNumber)
  }

  return (
    <Tippy
      disabled={isNil(segmentedNumber) || isNil(plusUpTo)}
      content={`The range of segmented profiles is ${abbreviateNumber(
        segmentedNumber,
      )} to ${abbreviateNumber(segmentedNumber + plusUpTo)}`}
    >
      <div className={`segmentation-numbers-wrapper ${className ?? ""} ${isError ? "error" : ""}`}>
        {showLabel && <div className="segmentation-label">Segmented:</div>}
        <div className="box">
          <div className="inner-box">
            {!isError && (
              <React.Fragment>
                <span className={`segmented ${isSegmentedLoading ? "loading" : ""}`}>
                  {typeof segmentedNumber === "number" && segmentedNumber !== -1 ? (
                    <span>{Number(segmentedNumber).toLocaleString()}</span>
                  ) : (
                    <React.Fragment>
                      <Tippy
                        content="To see the number, please, save conditions."
                        disabled={isNil(uniqueDataTipId)}
                      >
                        <span>N/A</span>
                      </Tippy>
                    </React.Fragment>
                  )}
                </span>
                {!isNil(plusUpTo) && (
                  <span className="plus-up-to">(+ up to {plusUpTo.toLocaleString()})</span>
                )}
                {showTotal && (
                  <React.Fragment>
                    <span className="out-of"> out&nbsp;of </span>
                    <span className={`segmentation-total ${isTotalLoading ? "loading" : ""}`}>
                      {!isTotalLoading ? abbreviateNumber(totalNumber) : <span>N/A</span>}
                    </span>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {isError && (
              <span className="error-message">
                {errorMessage ? errorMessage : "Error loading numbers"}
              </span>
            )}
            {percentage !== "" && !isError && (
              <div className={`percents ${isPercentageLoading ? "loading" : ""}`}>
                ({percentage} %)
              </div>
            )}
          </div>
          {!isNil(segmentedNumber) && !isNil(totalNumber) && showBar && (
            <div className="progress-bar">
              <div
                className="progress-segmented"
                style={{
                  width: totalNumber === 0 ? 0 : `${(segmentedNumber / totalNumber) * 100}%`,
                }}
              />
              {!isNil(plusUpTo) && (
                <div
                  className="progress-plus-up-to"
                  style={{
                    width:
                      totalNumber === 0
                        ? 0
                        : `${((segmentedNumber + plusUpTo) / totalNumber) * 100}%`,
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Tippy>
  )
}

SegmentationNumbers.defaultProps = {
  showLabel: true,
  showTotal: true,
  error: false,
}

SegmentationNumbers.propTypes = {
  totalNumber: PropTypes.number,
  segmentedNumber: PropTypes.number,
  plusUpTo: PropTypes.number,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  uniqueDataTipId: PropTypes.string,
  showLabel: PropTypes.bool,
  showTotal: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  showBar: PropTypes.bool,
}

export default SegmentationNumbers

import React, { lazy, Suspense, useRef } from "react"
import styles from "./ActivateWebhookNodeForm.module.scss"
import {
  ActivateWebhookNodeType,
  ActivateWebhookNodeSettings,
} from "resources/journey/journeyTypes"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import NodeFormLayout from "../../components/NodeFormLayout/NodeFormLayout"
import SelectField from "components/UI/elements/SelectField"
import { onlyValidAttributesUsed, required } from "helpers/validators.helper"
import { SelectOption } from "types/util"
import TextInput from "components/UI/elements/TextInput/TextInput"
import AttributePickerButton from "components/UI/components/AttributePickerButton/AttributePickerButton"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import classNames from "classnames"
import ReactAce from "react-ace/lib/ace"
import {
  ActivateWebhookNodeFormValues,
  formValuesToSettings,
  settingsToFormValues,
} from "./formValues"
import ActivateWebhookNodeCard from "./ActivateWebhookNodeCard"
import { getDescription } from "./getDescription"

const AceEditor = lazy(() => import("components/AceEditor/AceEditor"))

type ActivateWebhookNodeFormProps = {
  node?: ActivateWebhookNodeType
  onSubmit: (data: ActivateWebhookNodeSettings) => void
  onClose: () => void
  isSubmitting: boolean
  isEditable: boolean
}

export default function ActivateWebhookNodeForm({
  node,
  onClose,
  onSubmit,
  isSubmitting,
  isEditable,
}: ActivateWebhookNodeFormProps) {
  const defaultValues = node?.settings
    ? settingsToFormValues(node.settings)
    : // @ts-ignore
      ({ method: "", url: "", body: "", headers: [] } as ActivateWebhookNodeFormValues)

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    register,
    setValue,
  } = useForm<ActivateWebhookNodeFormValues>({ defaultValues })
  const { remove, append, fields } = useFieldArray({ control, name: "headers" })

  function submit(data: ActivateWebhookNodeFormValues) {
    onSubmit(formValuesToSettings(data))
  }

  const { data: attributesMap = {} } = useFetchAttributesMap()

  const values = watch()
  const urlInputRef = useRef<HTMLInputElement>(null)
  const { ref: urlRef, ...urlRegister } = register("url", {
    validate: {
      required,
      onlyValidAttributesUsed: value => onlyValidAttributesUsed(value, attributesMap),
    },
  })
  const insertAttributeIdToURL = (attributeId: string | null) => {
    if (!urlInputRef.current || !attributeId) {
      return
    }
    const { selectionStart, selectionEnd } = urlInputRef.current
    setValue(
      "url",
      `${values.url.slice(0, selectionStart ?? 0)}{{${attributeId}}}${values.url.slice(
        selectionEnd ?? 0,
      )}`,
      { shouldDirty: true },
    )
  }

  const bodyInputRef = useRef<ReactAce>(null)
  const insertAttributeIdToBody = (attributeId: string | null) => {
    if (!bodyInputRef.current || !attributeId) {
      return
    }

    const { editor } = bodyInputRef.current

    editor.session.insert(editor.getCursorPosition(), `{{${attributeId}}}`)
  }

  return (
    <NodeFormLayout
      previewCard={
        <ActivateWebhookNodeCard description={getDescription(formValuesToSettings(values))} />
      }
      onClose={onClose}
      onSubmit={handleSubmit(submit)}
      isSubmitting={isSubmitting}
      isEditable={isEditable}
    >
      <div className={styles.content}>
        <Controller
          name="method"
          control={control}
          rules={{ validate: { required } }}
          render={({ field }) => (
            <SelectField
              label="Method"
              options={methodOptions}
              input={field}
              className={styles.methodSelect}
              disabled={!isEditable}
              error={errors.method?.message}
              isSearchable={false}
              isSimpleValue
            />
          )}
        />

        <div className={styles.urlWrapper}>
          <TextInput
            {...urlRegister}
            label="URL"
            error={errors.url?.message}
            disabled={!isEditable}
            className={styles.urlInput}
            ref={(el: HTMLInputElement) => {
              // @ts-ignore
              urlInputRef.current = el
              urlRef(el)
            }}
          />
          {isEditable && (
            <AttributePickerButton onChange={insertAttributeIdToURL} isError={!!errors?.url} />
          )}
        </div>

        <div className={styles.headersWrapper}>
          <div className={styles.label}>Headers</div>
          {fields.map((field, index) => (
            <div key={field.id} className={styles.header}>
              <TextInput
                {...register(`headers.${index}.key`, { validate: { required } })}
                error={errors.headers?.[index]?.key?.message}
                label="Header"
                disabled={!isEditable}
              />
              <TextInput
                {...register(`headers.${index}.value`, { validate: { required } })}
                error={errors.headers?.[index]?.value?.message}
                label="Value"
                disabled={!isEditable}
              />
              {isEditable && (
                <IconButton
                  variant="outlined"
                  color="red"
                  icon={["fas", "trash-alt"]}
                  onClick={() => remove(index)}
                  size="xs"
                />
              )}
            </div>
          ))}
          {isEditable && (
            <Button
              variant="outlined"
              color="grey"
              icon={["far", "plus"]}
              onClick={() => append({ key: "", value: "" })}
              className={styles.addHeaderButton}
            >
              Add header
            </Button>
          )}
        </div>

        <div className={styles.bodyWrapper}>
          {isEditable && (
            <AttributePickerButton
              onChange={insertAttributeIdToBody}
              className={styles.attributePicker}
              isInTextArea
            />
          )}
          <Controller
            control={control}
            name="body"
            rules={{
              validate: {
                onlyValidAttributesUsed: value => onlyValidAttributesUsed(value, attributesMap),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <Suspense fallback={<LoadingIndicator />}>
                <div className={styles.label}>Body</div>

                <AceEditor
                  value={field.value}
                  onChange={field.onChange}
                  mode="json"
                  theme="github"
                  editorProps={{ $blockScrolling: true }}
                  width="100%"
                  height="400px"
                  wrapEnabled={true}
                  className={classNames("ace-editor", styles.aceEditor)}
                  errorMessage={error?.message}
                  ref={el => {
                    // @ts-ignore
                    bodyInputRef.current = el
                    field.ref(el)
                  }}
                />
              </Suspense>
            )}
          />
        </div>
      </div>
    </NodeFormLayout>
  )
}

const methodOptions: SelectOption<ActivateWebhookNodeSettings["method"]>[] = [
  { value: "GET", label: "GET" },
  { value: "POST", label: "POST" },
  { value: "PUT", label: "PUT" },
  { value: "PATCH", label: "PATCH" },
  { value: "DELETE", label: "DELETE" },
]

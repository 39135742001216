import React, { useEffect, useState } from "react"
import styles from "./UsersActivity.module.scss"
import Paper from "components/UI/elements/Paper"
import { prop } from "ramda"
import ActivityChart from "./components/ActivityChart/ActivityChart"
import { FilterType, UsersActivityFilters } from "resources/usersActivity/usersActivityTypes"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SelectField from "components/UI/elements/SelectField"
import { DateString, SelectOption } from "types/util"
import Button from "components/UI/elements/Button/Button"
import ActivityDatePicker from "./components/ActivityDatePicker/ActivityDatePicker"
import { makeDataLabels } from "./makeDataLabels"
import UserCounts from "./components/UserCounts/UserCounts"
import { useFetchEmailDomainOptions, useFetchUserOptions } from "resources/user/userQueries"
import Page from "components/UI/Page/Page"
import create from "zustand"
import { getISODateDaysAgo, getISODateToday } from "helpers/dateFormat.helper"
import { useFetchUserRoleOptions } from "resources/userRole/userRoleQueries"
import { useFetchUsersActivity } from "resources/usersActivity/usersActivityQueries"
import { useQueryClient } from "@tanstack/react-query"

const filterTypeOptions: SelectOption<FilterType>[] = [
  { label: "Users", value: "user_ids" },
  { label: "Roles", value: "user_role_ids" },
  { label: "Email domains", value: "domains" },
]

type UsersActivitySetters = {
  setStartDate: (date: DateString) => void
  setEndDate: (date: DateString) => void
  setFilterType: (filterType: FilterType) => void
  setFilterValue: (filterValue: (string | number)[]) => void
}

const useFiltersStore = create<UsersActivityFilters & UsersActivitySetters>(set => ({
  since: getISODateDaysAgo(6),
  until: getISODateToday(),
  filterType: "all_users",
  filterValue: [],

  setStartDate(date) {
    set({ since: date })
  },
  setEndDate(date) {
    set({ until: date })
  },
  setFilterType(filterType) {
    set({ filterType })
  },
  setFilterValue(filterValue) {
    set({ filterValue })
  },
}))

export default function UsersActivity() {
  const {
    since,
    until,
    filterType,
    filterValue,
    setStartDate,
    setEndDate,
    setFilterType,
    setFilterValue,
  } = useFiltersStore()

  const { data: userOptions = [] } = useFetchUserOptions()
  const { data: domainOptions = [] } = useFetchEmailDomainOptions()
  const { data: roleOptions = [] } = useFetchUserRoleOptions()

  const selectedFilterType =
    filterType === "all_users" ? null : filterTypeOptions.find(({ value }) => value === filterType)

  const filterValueOptions: SelectOption<number | string>[] =
    filterType === "all_users"
      ? []
      : filterType === "user_ids"
      ? userOptions
      : filterType === "user_role_ids"
      ? roleOptions
      : domainOptions

  const selectedFilterValue = filterValue.map(
    value => filterValueOptions.find(option => option.value === value)!,
  )

  const changeFilterType = (option: SelectOption<FilterType> | null) => {
    setFilterType(option?.value ?? "all_users")
    setFilterValue([])
    setIsValidationError(false)
  }

  const changeFilterValue = (options: SelectOption<string | number>[]) => {
    setFilterValue(options.map(prop("value")))
    setIsValidationError(false)
  }

  const [isValidationError, setIsValidationError] = useState(false)

  const { data, isFetching, refetch } = useFetchUsersActivity({
    since,
    until,
    filterType,
    filterValue,
  })

  const queryClient = useQueryClient()

  async function refetchData() {
    if (filterType !== "all_users" && filterValue.length === 0) {
      setIsValidationError(true)
      return
    }

    await refetch()
    // It's weird when the user goes back to previous filters and the charts load immediately from
    // the rq cache without the user pressing "apply filters"
    queryClient.removeQueries(["usersActivity"])
  }

  useEffect(() => {
    refetch()
  }, [refetch])

  const { user_ids, user_role_ids, domains } = data?.selection_settings ?? {}
  const responseFilterType: FilterType = user_ids
    ? "user_ids"
    : user_role_ids
    ? "user_role_ids"
    : domains
    ? "domains"
    : "all_users"

  const labels = data ? makeDataLabels(data.activities.labels, data.activities.resolution) : []

  return (
    <Page contentClassName={styles.container} title="Users activity">
      <UserCounts />

      <Paper className={styles.filters}>
        <ActivityDatePicker
          startDate={since}
          endDate={until}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <SelectField
          input={{
            value: selectedFilterType,
            onChange: changeFilterType,
          }}
          options={filterTypeOptions}
          className={styles.filterTypeSelect}
          label="Filter by (optional)"
          isClearable
        />
        {filterType !== "all_users" && (
          <SelectField
            input={{
              value: selectedFilterValue,
              onChange: changeFilterValue,
            }}
            options={filterValueOptions}
            className={styles.filterValueSelect}
            label={filterTypeOptions.find(({ value }) => value === filterType)!.label}
            isMulti
            meta={{
              touched: true,
              error: isValidationError && "Please fill in the field",
            }}
          />
        )}
        <Button
          size="md"
          onClick={refetchData}
          className={styles.applyButton}
          loading={isFetching}
          disabled={isFetching}
        >
          Apply filters
        </Button>
      </Paper>

      {isFetching && <LoadingIndicator className={styles.pageSpinner} />}

      {!isFetching && data && (
        <div className={styles.chartsGrid}>
          {data.activities.category_data.map(category => (
            <ActivityChart
              key={category.id}
              categoryData={category}
              xAxisLabels={labels}
              userOptions={userOptions}
              roleOptions={roleOptions}
              filterType={responseFilterType}
            />
          ))}
        </div>
      )}
    </Page>
  )
}

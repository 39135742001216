import { MutableRefObject, useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"

import styles from "./WhitespaceWarning.module.scss"

type WhitespaceWarningProps = {
  visible: boolean
  className?: string
}

const useDimensions = (myRef: MutableRefObject<any>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return dimensions
}

export default function WhitespaceWarning({ className, visible }: WhitespaceWarningProps) {
  const [withoutDelay, setWithoutDelay] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (withoutDelay) setIsVisible(visible)
    else {
      if (visible) {
        const timer = setTimeout(() => {
          setWithoutDelay(true)
          setIsVisible(true)
        }, 1500)
        return () => clearTimeout(timer)
      } else setIsVisible(false)
    }
  }, [withoutDelay, visible])

  const ref = useRef<HTMLDivElement | null>(null)
  const { width } = useDimensions(ref)

  return (
    <div
      ref={ref}
      className={classNames(styles.root, className, {
        [styles.noWrap]: width > 100,
        [styles.visible]: isVisible,
      })}
    >
      <FontAwesomeIcon icon={["far", "eyes"]} className={styles.icon} />
      <span>Watch out for trailing whitespace</span>
    </div>
  )
}

import { isNil } from "ramda"
import { WaitDelayNodeSettings } from "resources/journey/journeyTypes"
import { secondsToDuration, Unit } from "../../utils/duration"

const labels: Record<Unit, string> = {
  days: "day",
  hours: "hour",
  minutes: "minute",
}

export function getDescription(settings?: WaitDelayNodeSettings | null): string {
  if (isNil(settings)) {
    return ""
  }

  const { count, unit } = secondsToDuration(settings.delay)

  if (isNaN(count)) {
    return ""
  }

  return `${count} ${labels[unit]}${count !== 1 ? "s" : ""}`
}

import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import { api } from "api"
import { assoc, prop, sort, update } from "ramda"
import { SelectOption } from "types/util"
import { ascend } from "utilities/comparators"
import { Sender, SenderCreatePayload, SendersListResponse } from "./emailSendersTypes"

const EMAIL_SENDER = "emailSender" as const
const EMAIL_SENDER_ALL_QK: QueryKey = [EMAIL_SENDER, "all"]

function useEmailSendersQuery<T>(
  config?: UseQueryOptions<SendersListResponse, unknown, T, QueryKey>,
) {
  return useQuery(EMAIL_SENDER_ALL_QK, api.channel.emails.senders.list, config)
}

export function useFetchEmailSenders() {
  return useEmailSendersQuery({
    select: ({ email_addresses }) => sort(ascend(prop("email")), email_addresses),
  })
}

export function useFetchDefaultSenderAddress() {
  return useEmailSendersQuery({
    select: ({ email_addresses }) =>
      email_addresses.find(({ verified, default: _default }) => verified && _default)?.email,
  })
}

export function useFetchVerifiedSendersOptions() {
  return useEmailSendersQuery<SelectOption<string>[]>({
    select: ({ email_addresses }) =>
      sort(
        ascend(prop("value")),
        email_addresses
          .filter(({ verified }) => verified)
          .map(({ email, default: _default }) => ({
            label: email,
            value: email,
            default: _default,
          })),
      ),
  })
}

export function useCreateEmailSender() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: SenderCreatePayload }) => api.channel.emails.senders.create(data),
    {
      onSuccess({ email_address }) {
        queryClient.setQueryData<SendersListResponse>(EMAIL_SENDER_ALL_QK, data => {
          if (!data) {
            return
          }

          return { ...data, email_addresses: data.email_addresses.concat(email_address) }
        })
        showToast("Email sender created.")
      },
    },
  )
}

export function useSetDefaultEmailSender() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ email }: { email: string }) => api.channel.emails.senders.modify(email, { default: true }),
    {
      onSuccess({ email_address }) {
        queryClient.setQueryData<SendersListResponse>(EMAIL_SENDER_ALL_QK, data => {
          if (!data) {
            return
          }

          // If a sender is set as default, the previous default sender is unset as default
          const senders = data.email_addresses.map<Sender>(assoc("default", false))

          const index = senders.findIndex(({ email }) => email === email_address.email)

          return {
            ...data,
            email_addresses:
              index === -1 ? senders.concat(email_address) : update(index, email_address, senders),
          }
        })
        showToast("Email sender set as default.")
      },
    },
  )
}

export function useDeleteEmailSender() {
  const queryClient = useQueryClient()

  return useMutation(({ email }: { email: string }) => api.channel.emails.senders.delete(email), {
    onSuccess(_, { email }) {
      queryClient.setQueryData<SendersListResponse>(EMAIL_SENDER_ALL_QK, data => {
        if (!data) {
          return
        }

        return {
          ...data,
          email_addresses: data.email_addresses.filter(sender => sender.email !== email),
        }
      })
      showToast("Email sender deleted.")
    },
  })
}

export function useResendEmailSenderVerification() {
  return useMutation(
    ({ email }: { email: string }) => api.channel.emails.senders.resendVerification(email),
    {
      onSuccess() {
        showToast("Verification email resent.")
      },
    },
  )
}

import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { append, uniq, update, whereEq } from "ramda"
import SimpleBar from "simplebar-react"

import Button from "components/UI/elements/Button/Button"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import Paper from "components/UI/elements/Paper"
import SearchField from "components/UI/elements/SearchField"
import { Source } from "resources/dataSource/dataSourceTypes"
import { useFetchEventsMapBySourceId } from "resources/event/eventQueries"
import { Event, EventFull } from "resources/event/eventTypes"
import { ActiveEventIds, ActiveSourceIds } from "../types"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"

import styles from "./TimelineFilter.module.scss"

type TimelineFilterProps = {
  activeEventIds: ActiveEventIds
  activeSourceIds: ActiveSourceIds
  searchTerm: string
  sources: Array<Source>
  events: Array<EventFull>
  setActiveEventIds: (eventIds: ActiveEventIds) => void
  setActiveSourceIds: (sourceIds: ActiveSourceIds) => void
  setSearchTerm: (newSearchTerm: string) => void
}

export default function TimelineFilter({
  activeEventIds,
  activeSourceIds,
  searchTerm,
  sources,
  events,
  setActiveEventIds,
  setActiveSourceIds,
  setSearchTerm,
}: TimelineFilterProps) {
  const [expandedSources, setExpandedSources] = useState<
    Array<{ id: Source["id"]; expanded: boolean }>
  >([])

  const { data: eventsMapBySourceId = {} } = useFetchEventsMapBySourceId()

  const onReset = () => {
    setActiveSourceIds(sources.map(({ id }) => id))
    setActiveEventIds(events.map(({ id }) => id))
  }

  const onSourceExpand = (sourceId: Source["id"]) => {
    const sourceIndex = expandedSources.findIndex(whereEq({ id: sourceId }))

    if (sourceIndex === -1)
      setExpandedSources(append({ id: sourceId, expanded: true }, expandedSources))
    else {
      const source = expandedSources[sourceIndex]
      const newExpandedSources = update(
        sourceIndex,
        { ...source, expanded: !source.expanded },
        expandedSources,
      )

      setExpandedSources(newExpandedSources)
    }
  }

  const onSourceToggle = (sourceId: Source["id"]) => {
    const isActive = activeSourceIds.includes(sourceId)

    const newFilter = isActive
      ? activeSourceIds.filter(id => id !== sourceId)
      : activeSourceIds.concat(sourceId)

    setActiveSourceIds(newFilter)

    const sourceEvents = events.filter(({ source }) => source.id === sourceId)
    const sourceEventIds = sourceEvents.map(({ id }) => id)

    if (isActive) setActiveEventIds(activeEventIds.filter(id => !sourceEventIds.includes(id)))
    else setActiveEventIds(uniq(activeEventIds.concat(sourceEventIds)))
  }

  const onEventToggle = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const [, sourceId, eventId] = evt.currentTarget.name.split(".")

    const isActive = activeEventIds.includes(eventId)

    setActiveEventIds(
      isActive ? activeEventIds.filter(id => id !== eventId) : activeEventIds.concat(eventId),
    )

    if (evt.currentTarget.checked) {
      setActiveSourceIds(uniq(activeSourceIds.concat(sourceId)))
    } else {
      const sourceEvents = activeEventIds.filter(whereEq({ source_id: sourceId }))
      const checkedSourceEvents = sourceEvents.filter(whereEq({ active: true }))

      if (checkedSourceEvents.length === 1)
        setActiveSourceIds(activeSourceIds.filter(id => id !== sourceId))
    }
  }

  const selectOnly = (eventId: Event["id"], sourceId: Source["id"]) => {
    setActiveEventIds([eventId])
    setActiveSourceIds([sourceId])
  }

  return (
    <div className={styles.container}>
      <Paper hasHeader>
        <SearchField
          fullWidth
          label="Search"
          input={{
            value: searchTerm,
            onChange: (value: string) => setSearchTerm(value),
          }}
          placeholder="Search for payload"
          type="text"
          onClear={() => setSearchTerm("")}
        />
      </Paper>

      <Paper hasHeader={true} className={styles.timelineOptionsPaper}>
        <SimpleBar className={styles.scrollable}>
          <div className={styles.timelineOptionsHeader}>
            <h4 className={styles.title}>Filter by event:</h4>
            <Button color="grey" variant="link" onClick={_ => onReset()}>
              Reset
            </Button>
          </div>
          {sources.map(source => {
            const isActive = activeSourceIds.includes(source.id)

            const sourceEvents = eventsMapBySourceId[source.id]
            let color = source.frontend_settings?.color
            const isExpanded = expandedSources.find(whereEq({ id: source.id }))?.expanded

            return (
              <div
                key={source.id}
                data-testid={`source-name-${source.id}`}
                className={styles.formRow}
              >
                <span
                  className={classNames(
                    styles.sourcename,
                    { [styles.turnedOff]: !isActive },
                    styles.clickable,
                  )}
                  onClick={() => onSourceExpand(source.id)}
                >
                  {source.name}
                  <span className={styles.caretButton}>
                    {isExpanded && <FontAwesomeIcon icon={["fas", "caret-up"]} />}
                    {!isExpanded && <FontAwesomeIcon icon={["fas", "caret-down"]} />}
                  </span>
                </span>

                <div
                  className={classNames(styles.sourceIcon, styles[color], {
                    [styles.disabled]: !isActive,
                  })}
                  onClick={() => {
                    onSourceToggle(source.id)
                  }}
                >
                  <SrcDstIcon source={source} white />
                </div>

                {Array.isArray(sourceEvents) && (
                  <div
                    data-testid="source-events"
                    className={classNames(styles.eventTypes, {
                      [styles.expanded]: isExpanded,
                      [styles.collapsed]: !isExpanded,
                    })}
                  >
                    {sourceEvents.map(event => {
                      const isActive = activeEventIds.includes(event.id)

                      return (
                        <div key={event.id} className={styles.eventTypeCheckboxWrapper}>
                          <button
                            className={styles.selectOnlyButton}
                            onClick={e => {
                              e.stopPropagation()
                              selectOnly(event.id, source.id)
                            }}
                            type="button"
                          >
                            Select only
                          </button>
                          <Checkbox
                            key={event.id}
                            checked={isActive}
                            id={`eventTypes.${source.id}.${event.id}`}
                            label={
                              <>
                                <FontAwesomeIcon icon={["fas", "check"]} />
                                {event.name}
                              </>
                            }
                            name={`eventTypes.${source.id}.${event.id}`}
                            onChange={onEventToggle}
                            variant="secondary"
                            className={styles.sub}
                          />
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
        </SimpleBar>
      </Paper>
    </div>
  )
}

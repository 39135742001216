import {
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query"
import { api } from "api"
import {
  CustomerEventGroupListRequestPayload,
  CustomerEventListRequestPayload,
  CustomerEventListResponse,
} from "./customerEventTypes"
import { showToast } from "app/toast"
import { TOAST } from "sharedConstants"
import { Flag } from "types/util"

export function useFetchCustomerEventsCounts() {
  return useQuery(["customerEvents", "counts"], () => api.customerEvent.counts(), {
    staleTime: 60 * 1000,
    select: response => response.event_counts,
  })
}

export const useFetchCustomerEventGroups = (data: CustomerEventGroupListRequestPayload) => {
  return useQuery(
    ["customer", data.customer_entity_id, "eventGroups"],
    () => api.customerEvent.group.list(data),
    {
      onSuccess: data => {
        if (data?.customer_event_groups.length === 0) {
          showToast("No events found for the customer.", TOAST.TYPE.SUCCESS, "", true)
        }
      },
    },
  )
}

export const useFetchCustomerEvents = <T extends Flag>(
  payload: Omit<CustomerEventListRequestPayload, "offset">,
  config?: UseInfiniteQueryOptions<
    CustomerEventListResponse<T>,
    unknown,
    CustomerEventListResponse<T>,
    CustomerEventListResponse<T>,
    QueryKey
  >,
) => {
  const { data, ...rest } = useInfiniteQuery(
    [
      "customer",
      payload.customer_entity_id,
      "events",
      "startDate",
      payload.start_date,
      "endDate",
      payload.end_date,
      "searchTerm",
      payload.search_text,
      "sourceIds",
      payload.source_ids,
      "eventIds",
      payload.event_ids,
    ] as QueryKey,
    ({ pageParam }) => api.customerEvent.list({ ...payload, offset: pageParam ?? 0 }),
    {
      ...config,
      refetchOnWindowFocus: false,
      retry: 0,
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.customer_events.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(p => p.customer_events) : [] }
}

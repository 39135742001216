import { useQuery } from "@tanstack/react-query"
import requestFactory from "api/request"

type SuspiciousCustomerEntitiesResponse = {
  customers_with_events_excess: Array<{
    customer_entity_id: string
    events_count: number
  }>
  total_count: number
}

const suspiciousCustomerEntities = (): Promise<SuspiciousCustomerEntitiesResponse> =>
  requestFactory("get", "/customers_with_events_excess")

export function useFetchSuspiciousCustomerEntities() {
  return useQuery(["suspiciousCustomerEntities"], suspiciousCustomerEntities, {
    staleTime: 60 * 1000,
    select({ customers_with_events_excess, total_count }) {
      return {
        data: customers_with_events_excess.map(({ customer_entity_id, events_count }) => ({
          label: customer_entity_id,
          value: events_count,
        })),
        totalCount: total_count,
      }
    },
  })
}

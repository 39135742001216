import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import styles from "./FunnelGroupCreate.module.scss"
import Page from "components/UI/Page/Page"
import { useHistory } from "react-router-dom"
import { useCreateFunnelGroup } from "resources/funnelGroup/funnelGroupQueries"
import FunnelGroupForm, {
  FunnelGroupFormValues,
} from "../components/FunnelGroupForm/FunnelGroupForm"
import { showToast } from "app/toast"
import { useModifyFunnelGroupPermissions } from "resources/funnelGroupPermission/funnelGroupPermissionQueries"
import { diffPermissionsLists } from "resources/funnelGroupPermission/diffPermissionsLists"

export default function FunnelGroupCreate() {
  const history = useHistory()
  const createMutation = useCreateFunnelGroup()
  const modifyPermissionsMutation = useModifyFunnelGroupPermissions()

  async function createFunnelGroup({ funnelGroup, acl }: FunnelGroupFormValues) {
    const {
      funnel_group: { id },
    } = await createMutation.mutateAsync({
      data: { disabled: funnelGroup.disabled, name: funnelGroup.name },
    })

    const permissionsList = Object.entries(acl).map(([user_role_id, permission]) => ({
      user_role_id: +user_role_id,
      permission,
    }))

    await modifyPermissionsMutation.mutateAsync({
      id,
      data: diffPermissionsLists([], permissionsList),
    })

    showToast("Funnel group created.")
    history.push(getRoutePath("administration.funnel-groups"))
  }

  return (
    <Page
      title="Create funnel group"
      headerContent={
        <div className={styles.buttons}>
          <Button
            color="grey"
            variant="outlined"
            onClick={() => history.push(getRoutePath("administration.funnel-groups"))}
          >
            Cancel
          </Button>
          <Button loading={createMutation.isLoading} form="funnelGroupForm" type="submit">
            Save
          </Button>
        </div>
      }
    >
      <FunnelGroupForm onSubmit={createFunnelGroup} />
    </Page>
  )
}

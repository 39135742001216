import {
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { api } from "api"
import {
  BlockEmailPayload,
  CreateEmailPayload,
  Email,
  EmailListResponse,
  EmailSelectionOptions,
  EmailsListDeletedResponse,
  ModifyEmailPayload,
  UnblockEmailPayload,
} from "./emailTypes"
import { showToast } from "app/toast"
import { ascend, descend } from "utilities/comparators"
import { isNil, prop, sort, update } from "ramda"
import { useCallback } from "react"
import { sortByDateProp } from "resources/utils"
import { getRoutePath } from "routes"
import { TrashItem } from "types/trash"
import { SelectOption } from "types/util"

export const EMAIL = "email" as const
export const EMAIL_ALL_QK: QueryKey = [EMAIL, "all"]
const EMAIL_TRASH_QK: QueryKey = [EMAIL, "trash"]

function useEmailsQuery<T = EmailListResponse>(
  config?: UseQueryOptions<EmailListResponse, unknown, T, QueryKey>,
) {
  return useQuery(EMAIL_ALL_QK, api.email.list, config)
}

export const useFetchAllEmails = (
  config?: UseQueryOptions<EmailListResponse, unknown, EmailListResponse, QueryKey>,
  options?: EmailSelectionOptions,
) =>
  useEmailsQuery({
    ...config,
    select: data => {
      if (!options) return data
      const { emails } = data

      const filteredEmails = options.searchTerm
        ? emails.filter(({ name }) => name.toLowerCase().includes(options.searchTerm.toLowerCase()))
        : emails

      let sortedEmails: Array<Email> = []
      if (options.orderBy === "last_activation")
        sortedEmails = sortByDateProp(filteredEmails, options.orderDir, "last_activation")
      else {
        const comparator = options.orderDir === "ASC" ? ascend : descend
        sortedEmails = sort(comparator(prop(options.orderBy)), filteredEmails)
      }

      return { ...data, emails: sortedEmails }
    },
  })

export function useFetchEmailById(id?: Email["id"] | null) {
  const select = useCallback(
    ({ emails }: EmailListResponse) => emails.find(email => email.id === id) ?? null,
    [id],
  )

  return useEmailsQuery({
    enabled: !isNil(id),
    select,
  })
}

export function useFetchEmailOptions() {
  return useEmailsQuery<SelectOption<Email["id"]>[]>({
    select: data => data.emails.map(({ id, name }) => ({ value: id, label: name })),
  })
}

export function useCreateEmail() {
  const queryClient = useQueryClient()

  return useMutation(({ data }: { data: CreateEmailPayload }) => api.email.create(data), {
    onSuccess: ({ email }) => {
      queryClient.setQueryData<EmailListResponse>(EMAIL_ALL_QK, data => {
        if (!data) {
          return
        }

        // TODO: Do we want to sort here? The new email will jump into correct position after refetch
        return { ...data, emails: data.emails.concat(email) }
      })
      showToast("Email created.")
    },
  })
}

export function useModifyEmail() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: Email["id"]; data: ModifyEmailPayload }) => api.email.modify(id, data),
    {
      onSuccess: ({ email }) => {
        queryClient.setQueryData<EmailListResponse>(EMAIL_ALL_QK, data => {
          if (!data) {
            return
          }

          const index = data.emails.findIndex(({ id }) => id === email.id)

          const emails =
            index === -1 ? data.emails.concat(email) : update(index, email, data.emails)

          return { ...data, emails }
        })
      },
    },
  )
}

export const useDeleteEmail = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Email["id"] }) => api.email.delete(id), {
    onSuccess: (_, { id }) => {
      queryClient.setQueryData<EmailListResponse>(EMAIL_ALL_QK, data => {
        if (!data) return

        const emails = data.emails.filter(item => item.id !== id)

        return { ...data, emails }
      })
      showToast("Email deleted.")
    },
  })
}

export const useBlockEmail = () =>
  useMutation((data: BlockEmailPayload) => api.email.block(data), {
    onSuccess() {
      showToast("Triggered email blocking.")
    },
  })
export const useUnblockEmail = () =>
  useMutation((data: UnblockEmailPayload) => api.email.unblock(data), {
    onSuccess() {
      showToast("Triggered email unblocking.")
    },
  })

export const useFetchEmailTrashItems = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<
    EmailsListDeletedResponse,
    string,
    Omit<EmailsListDeletedResponse, "trashed_emails"> & { trashed_emails: Array<TrashItem> },
    QueryKey
  >(
    [...EMAIL_TRASH_QK, searchTerm],
    ({ pageParam }) =>
      api.email.listDeleted({
        offset: pageParam,
        limit: 20,
        searched_text: searchTerm,
      }),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.trashed_emails.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      select: data => {
        return {
          ...data,
          pages: data.pages.map(p => ({
            ...p,
            trashed_emails: p.trashed_emails.map(({ id, name, modified, modified_by }) => {
              const trashItem: TrashItem = {
                id,
                name,
                deleted_at: modified,
                deleted_by: modified_by,
                type: "emails",
              }

              return trashItem
            }),
          })),
        }
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.trashed_emails) : [] }
}

export const useRestoreEmail = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Email["id"] }) => api.email.restoreDeleted(id), {
    onSuccess: ({ email }) => {
      queryClient.invalidateQueries(EMAIL_TRASH_QK)
      queryClient.setQueryData<EmailListResponse>(EMAIL_ALL_QK, data => {
        if (!data) return

        return { ...data, emails: data.emails.concat(email) }
      })
      showToast(
        "Email restored.",
        undefined,
        getRoutePath("channels.emails.detail", { id: email.id }),
      )
    },
  })
}

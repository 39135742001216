import requestFactory from "api/request"
import { LookalikeModifyPayload, LookalikeResponse } from "./lookalikeTypes"

const lookalike = {
  retrieve(): Promise<LookalikeResponse> {
    return requestFactory("GET", "/segments/settings/lookalike")
  },
  modify(data: LookalikeModifyPayload): Promise<LookalikeResponse> {
    return requestFactory("PATCH", "/segments/settings/lookalike", data)
  },
}

export default lookalike

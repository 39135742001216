import requestFactory from "api/request"
import {
  GlobalSetting,
  GlobalSettingModifyPayload,
  GlobalSettingsListResponse,
} from "./globalSettingsTypes"

const globalSettings = {
  list(): Promise<GlobalSettingsListResponse> {
    return requestFactory("get", "/global_settings")
  },
  modify(
    globalSettingsId: GlobalSetting["id"],
    data: GlobalSettingModifyPayload,
  ): Promise<{ global_setting: GlobalSetting }> {
    return requestFactory("patch", `/global_settings/${globalSettingsId}`, data)
  },
}

export default globalSettings

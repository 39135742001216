import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SimpleBar from "simplebar-react"

// helpers
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper.js"
import { abbreviateNumber } from "helpers/number.helper"
import { durationStringFromSeconds } from "helpers/date.helper"

import "./VerticalChart.scss"
import Tippy from "@tippyjs/react"
import classNames from "classnames"
import { useFetchAllDataSources } from "resources/dataSource/dataSourceQueries"
import { isNil } from "ramda"

class VerticalChart extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      legendExpanded: false,
      hiddenSources: {},
    }
    this.canvasObj = {}
  }

  toggleSource = id => () => {
    this.setState(prevState => ({
      hiddenSources: {
        ...prevState.hiddenSources,
        [id]: prevState.hiddenSources[id] === true ? false : true,
      },
    }))
  }

  toggleLegend = () => {
    this.setState(prevState => ({
      legendExpanded: !prevState.legendExpanded,
    }))
  }

  getTextWidth = text => {
    // re-use canvas object for better performance
    const canvas =
      this.canvasObj.canvas || (this.canvasObj.canvas = document.createElement("canvas"))
    const context = canvas.getContext("2d")
    context.font = "bold 11px arial"
    var metrics = context.measureText(text)
    return metrics.width
  }

  renderValue = (value, barWidth) => {
    if (value !== null && value >= 0) {
      const { type } = this.props
      if (type === "time") {
        const resultValue = durationStringFromSeconds(value, "hours", true)
        const textWidth = this.getTextWidth(resultValue) + 4
        return (
          <span
            className={textWidth > barWidth ? "next-to" : ""}
            style={{ right: `-${textWidth}px` }}
          >
            {resultValue}
          </span>
        )
      } else if (type === "integer") {
        const resultValue = abbreviateNumber(value)
        const textWidth = this.getTextWidth(resultValue) + 4
        return (
          <span
            className={textWidth > barWidth ? "next-to" : ""}
            style={{ right: `-${textWidth}px` }}
          >
            {resultValue}
          </span>
        )
      } else {
        const textWidth = this.getTextWidth(value) + 4
        return (
          <span
            className={textWidth > barWidth ? "next-to" : ""}
            style={{ right: `-${textWidth}px` }}
          >
            {value}
          </span>
        )
      }
    } else if (value === null) {
      return "Never used"
    } else if (value < 0) {
      return "Invalid data"
    }
  }

  render() {
    const { sources, data, labels, order, type, expanded } = this.props
    const { hiddenSources, legendExpanded } = this.state

    const occuringSourceIds = []
    let filteredData = data.filter(record => {
      const sourceId = record.source_id ? record.source_id : labels[record.item_id]?.source.id
      if (!occuringSourceIds.includes(sourceId)) {
        occuringSourceIds.push(sourceId)
      }
      return hiddenSources[sourceId] !== true
    })

    const sourcesFiltered = sources.filter(source => occuringSourceIds.includes(source.id))

    let maxValue = 0
    if (filteredData.length > 0) {
      filteredData.forEach(record => {
        if (record.value > maxValue) {
          maxValue = record.value
        }
      })
    }

    filteredData = filteredData
      .map(record => ({
        ...record,
        name: labels[record.item_id]?.name ?? "Undefined name",
      }))
      .sort((a, b) => {
        if (order === "asc") {
          if (a.value < b.value || (isNil(b.value) && !isNil(a.value))) {
            return -1
          }
          if (a.value > b.value || (isNil(a.value) && !isNil(b.value))) {
            return 1
          }
        } else {
          if (a.value < b.value || (isNil(b.value) && !isNil(a.value))) {
            return 1
          }
          if (a.value > b.value || (isNil(a.value) && !isNil(b.value))) {
            return -1
          }
        }
        return a.name.localeCompare(b.name)
      })

    return (
      <SimpleBar className={`vertical-chart ${expanded ? "expanded" : ""}`}>
        <div className="legend">
          {sourcesFiltered.map((source, index) => {
            if (!legendExpanded && index > 3) {
              return null
            } else {
              return (
                <button
                  className={classNames("legend-button", source.frontend_settings?.color, {
                    hidden: hiddenSources[source.id],
                  })}
                  onClick={this.toggleSource(source.id)}
                  key={source.id}
                >
                  {source.name}
                </button>
              )
            }
          })}
          {sourcesFiltered.length > 4 && (
            <button className="legend-toggle-button" onClick={this.toggleLegend}>
              {legendExpanded ? (
                <span>
                  Show less <FontAwesomeIcon icon={["fas", "caret-up"]} />
                </span>
              ) : (
                <span>
                  Show more <FontAwesomeIcon icon={["fas", "caret-down"]} />
                </span>
              )}
            </button>
          )}
        </div>
        <div className="data">
          {filteredData.map(record => {
            // in px
            const barWidth =
              record.value > 0 ? Math.round((record.value / maxValue) * 154 * 100) / 100 : 154
            const source =
              sourcesFiltered.find(({ id }) => id === record.source_id) ??
              sourcesFiltered.find(({ id }) => id === labels[record.item_id]?.source?.id)
            let color = source?.frontend_settings?.color

            let preciseValue = record.value
            if (!isNil(preciseValue)) {
              if (type === "time") {
                preciseValue = durationStringFromSeconds(record.value)
              } else if (type === "integer") {
                preciseValue = getUserFriendlyValueFormat(record.value, "int")
              }
            }

            return (
              <div className="row" key={record.item_id}>
                <div className="row-title">{record.name}</div>
                <Tippy
                  disabled={isNil(preciseValue)}
                  content={<span>Exact value: {preciseValue}</span>}
                >
                  <div
                    className={classNames("bar", color, {
                      "has-value": record.value > 0,
                      "no-value": record.value === 0,
                    })}
                    style={{
                      width: ((expanded ? 80 : 50) / 154) * barWidth + "%",
                    }}
                  >
                    {this.renderValue(record.value, barWidth)}
                  </div>
                </Tippy>
              </div>
            )
          })}
        </div>
      </SimpleBar>
    )
  }
}

VerticalChart.propTypes = {
  labels: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  type: PropTypes.string,
  order: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
}

export default props => {
  const { data = {} } = useFetchAllDataSources()

  return <VerticalChart {...props} sources={data} />
}

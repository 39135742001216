import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { api } from "api"
import { showToast } from "app/toast"
import { always, prop } from "ramda"
import { useHasAccess } from "resources/user/currentUserQueries"
import { LookalikeModifyPayload } from "./lookalikeTypes"

const LOOKALIKE = "lookalike" as const

export function useFetchLookalikeSettings() {
  const hasAccess = useHasAccess()

  return useQuery([LOOKALIKE], api.lookalike.retrieve, {
    enabled: hasAccess.setup.lookalikeSettings,
    select: prop("attribute_ids"),
  })
}

export function useModifyLookalikeSettings() {
  const queryClient = useQueryClient()

  return useMutation(({ data }: { data: LookalikeModifyPayload }) => api.lookalike.modify(data), {
    onSuccess(data) {
      queryClient.setQueryData([LOOKALIKE], always(data))

      showToast("Lookalike settings modified.")
    },
  })
}

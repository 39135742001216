import React from "react"
import { ActivateEmailNodeType, ActivateEmailNodeSettings } from "resources/journey/journeyTypes"
import { Controller, useForm } from "react-hook-form"
import { useFetchEmailOptions } from "resources/email/emailQueries"
import NodeFormLayout from "../../components/NodeFormLayout/NodeFormLayout"
import SelectField from "components/UI/elements/SelectField"
import { required } from "helpers/validators.helper"
import { useGetDescription } from "./useGetDescription"
import ActivateEmailNodeCard from "./ActivateEmailNodeCard"

type ActivateEmailNodeFormProps = {
  node?: ActivateEmailNodeType
  onSubmit: (data: ActivateEmailNodeSettings) => void
  onClose: () => void
  isSubmitting: boolean
  isEditable: boolean
}

export default function ActivateEmailNodeForm({
  node,
  onClose,
  onSubmit,
  isSubmitting,
  isEditable,
}: ActivateEmailNodeFormProps) {
  const defaultValues = node?.settings ?? { campaign_id: "" }

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<ActivateEmailNodeSettings>({ defaultValues })

  const values = watch()
  const { data: emailOptions = [] } = useFetchEmailOptions()

  return (
    <NodeFormLayout
      previewCard={<ActivateEmailNodeCard description={useGetDescription(values)} />}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      isEditable={isEditable}
    >
      <Controller
        control={control}
        rules={{ validate: { required } }}
        name="campaign_id"
        render={({ field }) => (
          <SelectField
            input={field}
            options={emailOptions}
            label="Email campaign"
            error={errors.campaign_id?.message}
            disabled={!isEditable}
            isSimpleValue
            noOptionsMessage="No campaigns available. Please create a campaign first."
          />
        )}
      />
    </NodeFormLayout>
  )
}

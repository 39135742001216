import React, { lazy, Suspense } from "react"
import classNames from "classnames"
import { pick } from "ramda"
import { Controller, useForm } from "react-hook-form"
import { Prompt } from "react-router-dom"

import { maxLength, pythonVariable, required } from "helpers/validators.helper"
import { MetaAttribute } from "resources/metaAttributes/metaAttributesTypes"
import Paper from "components/UI/elements/Paper"
import TextInput from "components/UI/elements/TextInput/TextInput"

import styles from "./MetaAttributesForm.module.scss"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import TextArea from "components/UI/elements/TextArea/TextArea"
const AceEditor = lazy(() => import("components/AceEditor/AceEditor"))

type FormValues = Pick<MetaAttribute, "id" | "definition" | "description">

type Props = {
  onSubmit: (data: FormValues) => Promise<{ meta_attribute: MetaAttribute }>
  metaAttribute?: MetaAttribute
}

export default function MetaAttributesForm({ metaAttribute, onSubmit }: Props) {
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty, isSubmitting, isSubmitted },
  } = useForm<FormValues>({
    defaultValues: metaAttribute && pick(["id", "definition", "description"], metaAttribute),
  })

  return (
    <form
      id="metaAttributesForm"
      onSubmit={handleSubmit(async formValues => {
        await onSubmit(formValues)
        reset(formValues)
      })}
    >
      <Prompt
        when={isDirty && !isSubmitting && !isSubmitted}
        message="Changes you made will not be saved."
      />

      <Paper hasHeader className={styles.paper}>
        <div className={styles.row}>
          <div className={styles.left}>
            <h2>General</h2>
          </div>
          <div className={styles.id}>
            <TextInput
              {...register("id", {
                validate: { pythonVariable, required, maxLength: maxLength(255) },
              })}
              disabled={!!metaAttribute}
              error={errors.id?.message}
              label="Meta Attribute ID"
              placeholder="Meta Attribute ID"
            />
          </div>
        </div>
        <div className={classNames(styles.row, styles.grey)}>
          <div className={styles.left}>
            <h2>Description</h2>
          </div>
          <div className={styles.right}>
            <TextArea
              {...register("description")}
              label="Description"
              placeholder="Description"
              rows={10}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.left}>
            <h2>Definition</h2>
          </div>
          <div className={styles.right}>
            <Controller
              control={control}
              name="definition"
              rules={{ validate: { required } }}
              render={({ field: { onChange, value } }) => (
                <div className={styles.aceEditorWrapper}>
                  <label>SQL query</label>
                  <Suspense fallback={<LoadingIndicator />}>
                    <AceEditor
                      wrapEnabled
                      showPrintMargin={false}
                      className={classNames(styles.aceEditor, {
                        [styles.error]: !!errors.definition?.message,
                      })}
                      editorProps={{ $blockScrolling: true }}
                      mode="pgsql"
                      height="400px"
                      theme="github"
                      width="100%"
                      value={value}
                      onChange={onChange}
                      errorMessage={errors.definition?.message}
                    />
                  </Suspense>
                </div>
              )}
            />
          </div>
        </div>
      </Paper>
    </form>
  )
}

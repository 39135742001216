import React from "react"
import { Link, useParams } from "react-router-dom"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import IconButton from "components/UI/elements/IconButton/IconButton"
import PromotionBar from "./PromotionBar/PromotionBar"
import Username from "components/Username/Username"
import { getRoutePath } from "routes"
import "./SegmentExports.scss"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import { BASE_API_URL } from "api/request"
import classNames from "classnames"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import { whereEq } from "ramda"
import { useFetchSegmentExports } from "resources/segment/segmentExport/segmentExportQueries"
import Table, { Name } from "components/Table/Table"
import Datetime from "components/UI/elements/Datetime/Datetime"
import { SegmentExportRow } from "./SegmentExportRow/SegmentExportRow"
import { Segment } from "resources/segment/segment/segmentTypes"

type SegmentExportsProps = {
  isEditable: boolean
  isFeaturedSegment: boolean
  isSmartSegment: boolean
  segment: Segment
  refetchSegmentNumbers: () => void
}

export default function SegmentExports({
  isEditable,
  isFeaturedSegment,
  isSmartSegment,
  segment,
  refetchSegmentNumbers,
}: SegmentExportsProps) {
  const { id } = useParams<{ id: string }>()

  const { data: destinations = [] } = useFetchAllDestinations()
  const {
    data: segmentExports = [],
    fetchNextPage: fetchExportsNextPage,
    hasNextPage: hasExportsNextPage,
    refetch: refetchExports,
    isFetchingNextPage: isFetchingExportsNextPage,
  } = useFetchSegmentExports(+id)

  return (
    <div className="segment-export">
      {destinations && destinations.length > 0 && (
        <div>
          <PaperHeader size="small" titleText="export destinations" />
          <Paper className="segment-export-settings" hasHeader={true}>
            <table className="table segment-export-table">
              <thead>
                <tr>
                  <th>Destination</th>
                  <th>
                    Exportable{" "}
                    <InfoTooltip placement="right" interactive>
                      Segmented customers available for each export destinations.{" "}
                      <a
                        href="https://docs.meiro.io/books/meiro-business-explorer/page/export-out-of-available-for-the-destination--what-does-it-mean"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Learn more
                      </a>
                      .
                    </InfoTooltip>
                  </th>
                  <th>Schedule</th>
                  <th>Parameters</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {destinations.map(destination => {
                  const segmentExportInProgress = segmentExports.find(
                    segmentExport => segmentExport.segment_export_destination_id === destination.id,
                  )

                  return (
                    <SegmentExportRow
                      key={destination.id}
                      isEditable={isEditable}
                      destination={destination}
                      segment={segment}
                      segmentExport={segmentExportInProgress}
                      refetchExports={refetchExports}
                      refetchSegmentNumbers={refetchSegmentNumbers}
                    />
                  )
                })}
              </tbody>
            </table>
          </Paper>
          <PromotionBar />

          <PaperHeader
            className="segment-export-history-header"
            titleText="exports history"
            size="small"
          />
          <Paper noPaddingTop className="segment-export-history" hasHeader={true}>
            <Table
              data={segmentExports}
              columns={[
                {
                  id: "name",
                  label: "Export name",
                  gridTemplate: "1fr",
                  renderCell: ({ name }) => <Name name={name} />,
                },
                {
                  id: "exportedBy",
                  label: "User",
                  gridTemplate: "max-content",
                  renderCell: ({ user_id }) => <Username userId={user_id} />,
                },
                {
                  id: "destination",
                  label: "Destination",
                  gridTemplate: "max-content",
                  renderCell: ({ segment_export_destination_id }) =>
                    destinations.find(whereEq({ id: segment_export_destination_id }))?.name,
                },
                {
                  id: "exportedAt",
                  label: "Exported",
                  gridTemplate: "max-content",
                  renderCell: ({ created }) => <Datetime precision="minute" datetime={created} />,
                },
                {
                  id: "status",
                  label: "Status",
                  gridTemplate: "max-content",
                  renderCell: ({ status }) => (
                    <div className={classNames("status", status)}>{status}</div>
                  ),
                },
                {
                  id: "log",
                  label: "Log",
                  gridTemplate: "max-content",
                  renderCell: ({ id }) => (
                    <Link
                      to={{
                        pathname: getRoutePath(
                          isSmartSegment
                            ? "segments.smart.export.detail"
                            : isFeaturedSegment
                            ? "segments.featured.export.detail"
                            : "segments.custom.export.detail",
                          {
                            id: segment.id,
                            eid: id,
                          },
                        ),
                        state: { goBack: true },
                      }}
                    >
                      <IconButton
                        icon={["far", "file-alt"]}
                        size="xs"
                        tooltip="View log"
                        variant="outlined"
                        className="log-icon"
                      />
                    </Link>
                  ),
                },
                {
                  id: "csv",
                  label: "CSV",
                  gridTemplate: "max-content",
                  renderCell: ({
                    segment_export_destination_id,
                    id,
                    status,
                    file_download_token,
                  }) =>
                    segment_export_destination_id === 2 &&
                    status === "finished" && (
                      // download csv file, id = 2 is guaranteed by instalator
                      <a
                        data-testid="download-button"
                        href={`${BASE_API_URL}/segments/${segment.id}/exports/${id}/download?file_download_token=${file_download_token}`}
                        download
                      >
                        <IconButton
                          icon={["far", "download"]}
                          size="xs"
                          tooltip="Download export"
                          variant="outlined"
                          className="download-icon"
                        />
                      </a>
                    ),
                },
              ]}
              emptyMessage="There are no exports yet."
              fetchNextPage={fetchExportsNextPage}
              hasNextPage={hasExportsNextPage}
              isFetchingNextPage={isFetchingExportsNextPage}
            />
          </Paper>
        </div>
      )}
    </div>
  )
}

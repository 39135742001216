import React, { forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import classNames from "classnames"
import styles from "./AttributePicker.module.scss"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import { Attribute, AttributeDataType, AttributeFull } from "resources/attribute/attributeTypes"
import {
  getCompoundAttributeSubAttribute,
  getCompoundAttributeSubAttributes,
  isAttributeCompound,
} from "resources/attribute/compoundAttributeUtils"
import useClickOutHandler from "hooks/useClickOutHandler"
import { Source } from "resources/dataSource/dataSourceTypes"
import { Label } from "resources/attributeLabel/attributeLabelTypes"
import { useFetchDataSourcesMap } from "resources/dataSource/dataSourceQueries"
import { useFetchLabelsMap } from "resources/attributeLabel/attributeLabelQueries"
import { filter, groupBy, isEmpty, isNil, mapObjIndexed, sort, whereEq } from "ramda"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import NewBadge from "components/UI/elements/NewBadge/NewBadge"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Badge from "components/UI/elements/AttributeBadge"
import Tippy from "@tippyjs/react"
import ErrorTippy from "components/UI/elements/ErrorTippy/ErrorTippy"
import IconButton from "components/UI/elements/IconButton/IconButton"
import useKeyListener from "hooks/useKeyListener"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { ascend } from "utilities/comparators"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"

type AttributePickerProps = {
  value?: string | null // attribute_id OR attribute_id.dimension_id
  error?: string
  readOnly?: boolean
  isClearable?: boolean
  autoFocus?: boolean
  size?: "sm" | "md" | "lg"
  className?: string
  inputWrapperClassname?: string
  placeholder?: string
  withoutSource?: boolean
  onClose?: () => void
  onChange: (value: string | null) => void
  label?: string
} & Omit<AttributeDropdownProps, "isOpen" | "onChange">

export default function AttributePicker({
  value = null,
  onChange,
  error,
  readOnly = false,
  isClearable = false,
  autoFocus = false,
  size = "md",
  className,
  inputWrapperClassname,
  placeholder: propsPlaceholder,
  withoutSource = false,
  onClose,
  label,
  ...dropdownProps
}: AttributePickerProps) {
  const { isLoading } = useFetchAttributesMap()
  const parsedValue = useParsedValue(value)
  const { isOpen, open, close, triggerRef, ref, toggle } = useClickOutHandler<HTMLDivElement>({
    closeCallback() {
      setSearchTerm("")
      onClose?.()
    },
    initState: autoFocus,
  })
  const [searchTerm, setSearchTerm] = useState("")

  let placeholder = propsPlaceholder ?? "Search for attribute…"

  if (parsedValue) {
    placeholder = parsedValue.attribute.name

    if (parsedValue.type === "dimension") {
      placeholder += `: ${parsedValue.dimension.name}`
    }

    if (!withoutSource) {
      placeholder = `${parsedValue.attribute.source.name}: ${placeholder}`
    }
  }

  const ghostRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [needsTooltip, setNeedsTooltip] = useState(false)
  useLayoutEffect(() => {
    setNeedsTooltip(
      Boolean(
        parsedValue &&
          ghostRef.current &&
          inputRef.current &&
          ghostRef.current.getBoundingClientRect().width >
            inputRef.current.getBoundingClientRect().width,
      ),
    )
  }, [parsedValue])

  useKeyListener("Escape", _ => {
    if (isOpen) {
      close()
    }
  })

  // The backspace that deletes the last remaining character of the search term shouldn't remove the
  // attribute; the next backspace should
  const [shouldClear, setShouldClear] = useState(false)
  useKeyListener("Backspace", _ => {
    if (inputRef.current?.matches(":focus") && !searchTerm) {
      if (shouldClear) {
        onChange(null)
        setShouldClear(false)
      } else {
        setShouldClear(true)
      }
    }
  })

  // Dirty hack to be able to reset dropdown state
  const [isDropdownMounted, setIsDropdownMounted] = useState(true)
  function resetDropdown() {
    setIsDropdownMounted(false)
    setTimeout(() => setIsDropdownMounted(true), 0)
  }

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus()
    } else {
      inputRef.current?.blur()
    }
  }, [isOpen])

  return (
    <div data-testid="attribute-picker" className={classNames(styles.container, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <ErrorTippy disabled={!error} content={error}>
        <div
          ref={triggerRef}
          className={classNames(
            styles.inputWrapper,
            styles[size],
            {
              [styles.error]: error,
              [styles.readOnly]: readOnly,
              [styles.open]: isOpen,
            },
            inputWrapperClassname,
          )}
          onClick={_ => {
            if (!isLoading && !readOnly) {
              open()
            }
          }}
        >
          {isLoading && <LoadingIndicator size="sm" />}

          {!isLoading && (
            <>
              {!withoutSource && (
                <div
                  className={classNames(
                    styles.sourceLogoWrapper,
                    styles[parsedValue?.attribute.source.frontend_settings?.color],
                    {
                      [styles.shrunk]: searchTerm || !parsedValue,
                    },
                  )}
                >
                  {parsedValue && (
                    <SrcDstIcon
                      className={styles.sourceLogo}
                      source={parsedValue.attribute.source}
                      white
                    />
                  )}
                </div>
              )}
              {(parsedValue?.attribute?.is_hidden === 1 ||
                parsedValue?.attribute?.source.is_hidden === 1) && (
                <FontAwesomeIcon
                  icon={["far", "eye-slash"]}
                  className={classNames(styles.hiddenIcon, {
                    [styles.shrunk]: searchTerm || !parsedValue,
                  })}
                />
              )}
              <Tippy content={placeholder} disabled={!needsTooltip}>
                <input
                  placeholder={placeholder}
                  value={searchTerm}
                  onChange={e => {
                    open()
                    setSearchTerm(e.target.value)
                  }}
                  ref={inputRef}
                  className={classNames(styles.input, { [styles.hasValue]: parsedValue })}
                  autoComplete="off"
                  type="text"
                  autoFocus={autoFocus}
                  disabled={readOnly}
                />
              </Tippy>
              {isClearable && parsedValue && (
                <IconButton
                  icon={["fas", "times"]}
                  color="grey"
                  variant="transparent"
                  onClick={_ => {
                    onChange(null)
                    resetDropdown()
                  }}
                  className={styles.closeButton}
                />
              )}
              {size !== "sm" && (
                <div
                  data-testid="attribute-caret"
                  className={styles.caretWrapper}
                  onClick={e => {
                    if (!readOnly) {
                      e.stopPropagation()
                      toggle()
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={["fas", "caret-down"]}
                    flip={isOpen ? "vertical" : undefined}
                  />
                </div>
              )}
              <div ref={ghostRef} className={styles.ghost}>
                {placeholder}
              </div>
            </>
          )}
        </div>
      </ErrorTippy>

      {isDropdownMounted && (
        <AttributeDropdown
          isOpen={isOpen && !isLoading}
          ref={ref}
          onChange={id => {
            onChange(id)
            if (searchTerm) {
              resetDropdown()
            }
            close()
          }}
          searchTerm={searchTerm}
          {...dropdownProps}
        />
      )}
    </div>
  )
}

type AttributeDropdownProps = {
  onChange: (value: string) => void
  withDimensions?: boolean
  uniqueOnly?: boolean
  includeHidden?: boolean
  searchTerm?: string
  singleColumn?: boolean
  usedIds?: string[]
  isOpen?: boolean
  embedded?: boolean
  rightAlign?: boolean
  omitPII?: boolean
  allowedTypes?: AttributeDataType[]
}

export const AttributeDropdown = forwardRef<HTMLDivElement, AttributeDropdownProps>(
  function AttributeDropdown(
    {
      onChange,
      withDimensions = false,
      uniqueOnly = false,
      includeHidden = false,
      searchTerm,
      singleColumn = false,
      usedIds,
      isOpen = true,
      embedded = false,
      rightAlign = false,
      omitPII = false,
      allowedTypes,
    },
    ref,
  ) {
    const [filterBy, setFilterBy] = useState<"source" | "label">("source")

    const { data: currentUser } = useFetchCurrentUser()
    const { data: sourcesMap, isLoading: isLoadingSources } = useFetchDataSourcesMap()
    const { data: labelsMap, isLoading: isLoadingLabels } = useFetchLabelsMap()
    const { data: attributesMap, isLoading: isLoadingAttributes } = useFetchAttributesMap({
      includeHidden,
    })
    const isLoading = isLoadingAttributes || isLoadingLabels || isLoadingSources

    type ViewedCategory = { type: "source"; id: Source["id"] } | { type: "label"; id: Label["id"] }
    const [viewedCategory, setViewedCategory] = useState<ViewedCategory | null>(null)
    const [viewedAttributeId, setViewedAttributeId] = useState<Attribute["id"] | null>(null)

    // prettier-ignore
    const viewedSource = (viewedCategory?.type === "source" && sourcesMap?.[viewedCategory.id]) || null
    const viewedLabel = (viewedCategory?.type === "label" && labelsMap?.[viewedCategory.id]) || null
    const viewedAttribute = (viewedAttributeId && attributesMap?.[viewedAttributeId]) || null

    // TS is crapping out here a bit so explicit typing
    const viewedEntity: AttributeFull | Source | Label | null =
      viewedAttribute ?? viewedSource ?? viewedLabel

    function goBack() {
      if (viewedAttributeId) {
        // Timeouts because otherwise the component re-renders before the event reaches the window
        // listener, which removes the event target from the dropdown, and the listener thinks the
        // click was outside the dropdown
        setTimeout(() => setViewedAttributeId(null), 0)
      } else {
        setTimeout(() => setViewedCategory(null), 0)
      }
    }

    function selectAttribute(id: Attribute["id"]) {
      const attribute = attributesMap?.[id]
      if (!attribute) {
        return
      }
      if (withDimensions && isAttributeCompound(attribute.data_type)) {
        setTimeout(() => setViewedAttributeId(id), 0)
      } else {
        onChange(id)
      }
    }

    const groupedBySource = useMemo(() => {
      if (!attributesMap || !sourcesMap) {
        return null
      }

      const sourceGroups = groupBy(attribute => attribute.source.id, Object.values(attributesMap))

      return includeHidden
        ? sourceGroups
        : Object.fromEntries(
            Object.entries(sourceGroups).filter(([id]) => !sourcesMap[id]!.is_hidden),
          )
    }, [attributesMap, includeHidden, sourcesMap])

    const sortedSourceIds = useMemo(
      () =>
        groupedBySource
          ? sort(
              ascend(id => sourcesMap?.[id].name),
              Object.keys(groupedBySource),
            )
          : null,
      [groupedBySource, sourcesMap],
    )

    const groupedByLabel = useMemo(() => {
      if (!attributesMap || !labelsMap) {
        return null
      }

      const labelGroups = mapObjIndexed<Label, AttributeFull[]>(_ => [], labelsMap)

      Object.values(attributesMap).forEach(attribute =>
        attribute.tags?.forEach(({ id }) => {
          labelGroups[id].push(attribute)
        }),
      )

      return filter(a => !isEmpty(a), labelGroups)
    }, [attributesMap, labelsMap])

    const sortedLabelIds = useMemo(
      () =>
        groupedByLabel
          ? sort(
              ascend(id => labelsMap?.[id].name),
              Object.keys(groupedByLabel),
            )
          : null,
      [groupedByLabel, labelsMap],
    )

    const searchResults = useMemo(() => {
      if (!searchTerm || !groupedBySource || !groupedByLabel) {
        return null
      }

      const grouped = filterBy === "source" ? groupedBySource : groupedByLabel

      const filteredGrouped = mapObjIndexed(
        group =>
          group.filter(attribute =>
            attribute.name.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()),
          ),
        grouped,
      )

      return Object.entries(filteredGrouped)
        .filter(([_, attributes]) => attributes.length > 0)
        .map(([categoryId, attributes]) => ({ categoryId, attributes }))
    }, [filterBy, groupedByLabel, groupedBySource, searchTerm])

    useEffect(() => {
      setViewedAttributeId(null)
      setViewedCategory(null)
    }, [searchTerm])

    function renderAttributeRow(attribute: AttributeFull) {
      const isAlreadyUsed = Boolean(usedIds?.includes(attribute.id))
      const isWrongType =
        allowedTypes &&
        !allowedTypes.includes(attribute.data_type) &&
        !(allowedTypes.includes("compound") && isAttributeCompound(attribute.data_type)) &&
        !(withDimensions && isAttributeCompound(attribute.data_type))
      const isNotUnique = uniqueOnly && !attribute.is_unique
      const isPIIProtected =
        omitPII &&
        currentUser?.role.invisible_attributes_tag_ids.some(id =>
          attribute.tags?.some(whereEq({ id })),
        )
      const disabled = isAlreadyUsed || isWrongType || isNotUnique || isPIIProtected

      const disabledTooltip = isAlreadyUsed
        ? "Attribute already used."
        : isWrongType
        ? `Only ${allowedTypes!.join(", ")} attributes are allowed.`
        : isNotUnique
        ? "Multiple-value attributes are not supported."
        : isPIIProtected
        ? "You don't have permission to view the values for this protected attribute."
        : ""

      let examplesTooltip = <p>No examples</p>

      if (attribute.examples && !isEmpty(attribute.examples) && !disabled) {
        if (Array.isArray(attribute.examples)) {
          examplesTooltip = (
            <>
              <p>Example values:</p>
              <p>
                {attribute.examples
                  .map(ex => getUserFriendlyValueFormat(ex, attribute.data_type))
                  .join(", ")}
              </p>
            </>
          )
        } else {
          examplesTooltip = (
            <>
              <p>Example values:</p>
              <ul className={styles.dimensionExamplesList}>
                {Object.entries(attribute.examples).map(([dimensionId, examples]) => {
                  const dimension = getCompoundAttributeSubAttribute(
                    dimensionId,
                    attribute.data_type,
                  )

                  return (
                    <li key={dimensionId}>
                      <strong>{dimension?.name ?? dimensionId}:</strong>{" "}
                      {examples
                        .map(ex => getUserFriendlyValueFormat(ex, attribute.data_type))
                        .join(", ") || "No examples"}
                    </li>
                  )
                })}
              </ul>
            </>
          )
        }
      }

      return (
        <Tippy
          key={attribute.id}
          placement={disabled ? "top-start" : "bottom-start"}
          content={disabled ? disabledTooltip : examplesTooltip}
          delay={[400, 0]}
        >
          <div
            className={classNames(styles.row, { [styles.disabled]: disabled })}
            onClick={_ => (disabled ? undefined : selectAttribute(attribute.id))}
          >
            {(attribute.is_hidden === 1 || attribute.source.is_hidden === 1) && (
              <FontAwesomeIcon icon={["far", "eye-slash"]} className={styles.hiddenIcon} />
            )}{" "}
            {attribute.name} {isAttributeCompound(attribute.data_type) && <Badge text="Compound" />}{" "}
            <NewBadge created={attribute.created} size="sm" />
          </div>
        </Tippy>
      )
    }

    function renderDimensionRow({ id, name, data_type }: Dimension) {
      const isAlreadyUsed = Boolean(usedIds?.includes(`${viewedAttribute!.id}.${id}`))
      const isWrongType = Boolean(
        allowedTypes && !allowedTypes.includes(data_type as AttributeDataType),
      )
      const disabled = isAlreadyUsed || isWrongType

      const disabledTooltip = isAlreadyUsed
        ? "Dimension already used."
        : isWrongType
        ? `Only ${allowedTypes!.join(", ")} dimensions are allowed.`
        : ""

      let examplesTooltip = <p>No examples</p>

      const examples = (viewedAttribute?.examples as Record<string, string[]> | undefined)?.[id]

      if (examples && !isEmpty(examples) && !disabled) {
        examplesTooltip = (
          <>
            <p>Example values:</p>
            <p>{examples.map(ex => getUserFriendlyValueFormat(ex, data_type)).join(", ")}</p>
          </>
        )
      }

      return (
        <Tippy
          placement={disabled ? "top-start" : "bottom-start"}
          content={disabled ? disabledTooltip : examplesTooltip}
        >
          <div
            key={id}
            data-testid="attribute-picker-row"
            className={classNames(styles.row, { [styles.disabled]: disabled })}
            onClick={_ => {
              if (!disabled) {
                if (searchTerm) {
                  setViewedAttributeId(null)
                  setViewedCategory(null)
                }
                onChange(`${viewedAttribute!.id}.${id}`)
              }
            }}
          >
            {name}
          </div>
        </Tippy>
      )
    }

    function AttributesList({ attributes }: { attributes?: AttributeFull[] }) {
      if (!attributes) {
        return null
      }

      if (singleColumn || attributes.length === 1) {
        return <div className={styles.list}>{attributes.map(renderAttributeRow)}</div>
      }

      const midIndex = Math.ceil(attributes.length / 2)

      return (
        <div data-testid="attribute-dropdown" className={styles.columns}>
          <div data-testid="attribute-list-column-1" className={styles.list}>
            {attributes.slice(0, midIndex).map(renderAttributeRow)}
          </div>
          <div className={styles.separator} />
          <div className={styles.list}>{attributes.slice(midIndex).map(renderAttributeRow)}</div>
        </div>
      )
    }

    if (!isOpen) {
      return null
    }

    if (isLoading) {
      return (
        <div
          className={classNames(styles.dropdown, {
            [styles.embedded]: embedded,
            [styles.rightAlign]: rightAlign,
          })}
          ref={ref}
        >
          <div className={styles.content}>
            <LoadingIndicator />
          </div>
        </div>
      )
    }

    return (
      <div
        className={classNames(styles.dropdown, {
          [styles.embedded]: embedded,
          [styles.rightAlign]: rightAlign,
          [styles.wide]: searchTerm || (viewedCategory && !viewedAttribute && !singleColumn),
        })}
        ref={ref}
      >
        <div className={styles.header}>
          {viewedEntity ? (
            <div className={styles.categoryHeader} onClick={_ => goBack()}>
              <FontAwesomeIcon icon={["fas", "chevron-left"]} className={styles.backIcon} />
              {viewedEntity?.name}
            </div>
          ) : (
            <>
              <div className={styles.filterBy}>Filter by:</div>
              <Button
                variant="text"
                color={filterBy === "source" ? "primary" : "grey"}
                onClick={_ => setFilterBy("source")}
              >
                Source
              </Button>
              <Button
                variant="text"
                color={filterBy === "label" ? "primary" : "grey"}
                onClick={_ => setFilterBy("label")}
              >
                Label
              </Button>
            </>
          )}
        </div>

        <div className={styles.content}>
          {/* Search results */}
          {searchTerm && !viewedAttribute && (
            <div className={styles.groups}>
              {searchResults?.map(({ categoryId, attributes }) => (
                <div className={styles.group} key={categoryId}>
                  <div className={styles.title}>
                    {filterBy === "source"
                      ? sourcesMap?.[categoryId]?.name
                      : labelsMap?.[categoryId]?.name}
                  </div>
                  <div className={styles.groupedListWrapper}>
                    <div data-testid="attribute-list" className={styles.list}>
                      {attributes.map(renderAttributeRow)}
                    </div>
                  </div>
                </div>
              ))}

              {searchResults?.length === 0 && (
                <div className={styles.emptyMessage}>No attributes found.</div>
              )}
            </div>
          )}

          {/* List of sources or labels */}
          {!searchTerm && isNil(viewedEntity) && (
            <div data-testid="attribute-list" className={styles.list}>
              {(filterBy === "source" ? sortedSourceIds : sortedLabelIds)?.map(id => (
                <div
                  key={id}
                  className={styles.categoryRow}
                  data-testid="attribute-picker-row"
                  onClick={_ => setTimeout(() => setViewedCategory({ type: filterBy, id }), 0)}
                >
                  {filterBy === "source" ? sourcesMap?.[id]?.name : labelsMap?.[id]?.name}{" "}
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                </div>
              ))}
            </div>
          )}

          {/* List of attributes for selected source */}
          {!searchTerm && viewedCategory?.type === "source" && !viewedAttribute && (
            <AttributesList attributes={groupedBySource?.[viewedCategory.id]} />
          )}

          {/* List of attributes for selected label */}
          {!searchTerm && viewedCategory?.type === "label" && !viewedAttribute && (
            <AttributesList attributes={groupedByLabel?.[viewedCategory.id]} />
          )}

          {/* List of dimensions for selected attribute */}
          {viewedAttribute && (
            <div data-testid="attribute-list" className={styles.list}>
              {getCompoundAttributeSubAttributes(viewedAttribute.data_type).map(renderDimensionRow)}
            </div>
          )}
        </div>
      </div>
    )
  },
)

type Dimension = { id: string; name: string; data_type: string }
type Value =
  | { type: "attribute"; attribute: AttributeFull }
  | { type: "dimension"; attribute: AttributeFull; dimension: Dimension }

function useParsedValue(value: string | null): Value | null {
  const { data: attributesMap } = useFetchAttributesMap({ includeHidden: true })

  return useMemo(() => {
    if (isNil(value)) {
      return null
    }

    if (value.includes(".")) {
      const [attributeId, dimensionId] = value.split(".")
      const attribute = attributesMap?.[attributeId] ?? null
      return (
        attribute && {
          type: "dimension",
          attribute,
          dimension: getCompoundAttributeSubAttribute(dimensionId, attribute.data_type),
        }
      )
    }

    const attribute = attributesMap?.[value] ?? null
    return attribute && { type: "attribute", attribute }
  }, [value, attributesMap])
}

import React from "react"
import { useParams } from "react-router-dom"
import Waypoint from "react-waypoint"

import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import CustomerEvent from "../CustomerEvent/CustomerEvent"
import { ActiveEventIds, ActiveSourceIds } from "../types"
import useDebounce from "hooks/useDebounce"
import { useFetchCustomerEvents } from "resources/customer/event/customerEventQueries"
import { Source } from "resources/dataSource/dataSourceTypes"
import { useFetchEventsMap } from "resources/event/eventQueries"
import { ITEMS_PER_PAGE } from "sharedConstants"
import { EventFull } from "resources/event/eventTypes"

import styles from "./TimelineEvents.module.scss"

type TimelineEventsProps = {
  activeEventIds: ActiveEventIds
  activeSourceIds: ActiveSourceIds
  sources: Array<Source>
  events: Array<EventFull>
  searchTerm?: string
}

export default function TimelineEvents({
  activeEventIds,
  activeSourceIds,
  searchTerm,
  sources,
  events,
}: TimelineEventsProps) {
  const { id: profileId } = useParams<{ id: string }>()

  const { data: eventsMap = {} } = useFetchEventsMap({
    includeHidden: true,
  })

  const debouncedSearchTerm = useDebounce(searchTerm)

  const activeSources = sources.filter(({ id }) => activeSourceIds.includes(id))
  const areAllSourcesActive = sources.every(({ id }) => activeSourceIds.includes(id))
  const areAllSourcesDeactivated = sources.every(({ id }) => !activeSourceIds.includes(id))
  const source_ids = areAllSourcesActive ? [] : activeSources.map(({ id }) => id)

  const activeEvents = events.filter(({ id }) => activeEventIds.includes(id))
  const areAllEventsActive = events.every(({ id }) => activeEventIds.includes(id))
  const areAllEventsDeactivated = events.every(({ id }) => !activeEventIds.includes(id))
  const event_ids = areAllEventsActive ? [] : activeEvents.map(({ id }) => id)

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isSuccess } =
    useFetchCustomerEvents<0>({
      source_ids,
      event_ids,
      customer_entity_id: profileId,
      limit: ITEMS_PER_PAGE,
      load_full_structure: 0,
      order_by: "event_time",
      order_dir: "DESC",
      search_text: debouncedSearchTerm?.trim(),
    })

  const renderWaypoint = () => {
    if (!isFetchingNextPage && hasNextPage) {
      return <Waypoint onEnter={() => fetchNextPage()} bottomOffset={-300} />
    } else if (isFetchingNextPage && hasNextPage) {
      return <LoadingIndicator />
    }
  }

  const noResultsFound = (
    <Paper className={styles.noResults}>
      <p>No results found, consider to modify filter settings.</p>
    </Paper>
  )

  if (areAllEventsDeactivated && areAllSourcesDeactivated) return noResultsFound

  if (isLoading) return <LoadingIndicator />

  if (isSuccess && data.length === 0) return noResultsFound

  return (
    <>
      {data.map(customerEvent => {
        const source = sources.find(({ id }) => id === customerEvent.source_id)
        if (!source) return null

        return (
          <CustomerEvent
            key={customerEvent.id}
            hasTimeline={false}
            dataSource={source}
            customerEvent={customerEvent}
            event={eventsMap[customerEvent.event_id]}
          />
        )
      })}
      {renderWaypoint()}
    </>
  )
}

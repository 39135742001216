import Page from "components/UI/Page/Page"
import React, { useState } from "react"
import { Redirect, useHistory, useParams } from "react-router-dom"
import {
  useCloneFunnelChart,
  useDeleteFunnelChart,
  useFetchFunnelChart,
  useModifyFunnelChart,
} from "resources/funnelChart/funnelChartQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { getRoutePath } from "routes"
import styles from "./ChartDetail.module.scss"
import { FunnelGroup } from "resources/funnelGroup/funnelGroupTypes"
import ConfirmModal from "components/UI/components/ConfirmModal"
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button/Button"
import {
  useFetchAllFunnelGroups,
  useFetchAllFunnelGroupsByUser,
} from "resources/funnelGroup/funnelGroupQueries"
import SelectField from "components/UI/elements/SelectField"
import { ChartModifyPayload } from "resources/funnelChart/funnelChartTypes"
import FunnelChartForm from "../components/FunnelChartForm/FunnelChartForm"
import LineChartForm from "../components/LineChartForm/LineChartForm"
import { pick } from "ramda"
import { getFunnelChartInitialValues, getLineChartInitialValues } from "../getInitialValues"
import { useHasFunnelGroupEditPermission } from "resources/user/currentUserQueries"
import { useCurrentUserId } from "auth/auth"

export default function ChartDetail() {
  const { groupId, id: chartId } = useParams<{ groupId: string; id: string }>()
  const history = useHistory()
  const chartQuery = useFetchFunnelChart(groupId, chartId)
  const modifyMutation = useModifyFunnelChart()
  const cloneMutation = useCloneFunnelChart()
  const deleteMutation = useDeleteFunnelChart()
  const currentUserId = useCurrentUserId()
  const groupsQueryByUser = useFetchAllFunnelGroupsByUser(currentUserId)
  const groupsQuery = useFetchAllFunnelGroups({ includeDisabled: false })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false)
  const [groupIdToClone, setGroupIdToClone] = useState<FunnelGroup["id"] | null>(null)
  const isEditable = useHasFunnelGroupEditPermission(groupId)

  if (chartQuery.isLoading) {
    return (
      <Page
        title="Edit chart"
        backRouteFallback={getRoutePath("analytics.funnels.group", { groupId })}
      >
        <LoadingIndicator />
      </Page>
    )
  }

  if (chartQuery.isError) {
    return (
      <Page
        title="Edit chart"
        backRouteFallback={getRoutePath("analytics.funnels.group", { groupId })}
      >
        {" "}
      </Page>
    )
  }

  if (chartQuery.data === null) {
    return <Redirect to={getRoutePath("not-found")} />
  }

  function modifyChart(data: ChartModifyPayload) {
    return modifyMutation.mutate(
      {
        funnelGroupId: groupId,
        chartId,
        data: pick(["name", "description", "frontend_settings", "data", "function"], data),
      },
      {
        onSuccess: () => {
          history.push(getRoutePath("analytics.funnels.group", { groupId }))
        },
      },
    )
  }

  function cloneChart() {
    cloneMutation.mutate(
      {
        funnelGroupId: groupId,
        chartId,
        data: { funnel_group_id: groupIdToClone! },
      },
      {
        onSuccess({ chart }) {
          closeCloneModal()
          history.push(
            getRoutePath("analytics.funnels.group.chart-detail", {
              groupId: chart.funnel_group_id,
              id: chart.id,
            }),
          )
        },
      },
    )
  }

  function deleteChart() {
    deleteMutation.mutate(
      { funnelGroupId: groupId, chartId },
      {
        onSuccess() {
          history.push(getRoutePath("analytics.funnels.group", { groupId }))
        },
      },
    )
  }

  function closeCloneModal() {
    setIsCloneModalOpen(false)
    setTimeout(() => setGroupIdToClone(null), 200)
  }

  const chart = chartQuery.data

  return (
    <>
      <ConfirmModal
        title="Are you sure?"
        open={isDeleteModalOpen}
        type="delete"
        text={`Do you really want to delete funnel group?`}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={deleteChart}
        isLoading={deleteMutation.isLoading}
      />
      <Modal
        open={isCloneModalOpen}
        handleClose={closeCloneModal}
        title="Copy chart"
        contentClassName={styles.modalContent}
      >
        <div>Select the chart group where the copy will be created:</div>
        <SelectField
          input={{ value: groupIdToClone, onChange: setGroupIdToClone }}
          options={
            groupsQueryByUser.data?.map(byUser => {
              const group = groupsQuery.data?.find(({ id }) => id === byUser.funnel_group_id)
              return group ? { value: group.id, label: group.name } : null
            }) ?? []
          }
          isSimpleValue
        />
        <div className={styles.modalButtons}>
          <Button variant="text" color="grey" onClick={closeCloneModal}>
            Cancel
          </Button>
          <Button disabled={!groupIdToClone} onClick={cloneChart} loading={cloneMutation.isLoading}>
            Create
          </Button>
        </div>
      </Modal>

      <Page
        title="Edit chart"
        backRouteFallback={getRoutePath("analytics.funnels.group", { groupId })}
        headerContent={
          <div className={styles.buttons}>
            <Button variant="outlined" color="red" onClick={() => setIsDeleteModalOpen(true)}>
              Delete
            </Button>
            <Button variant="outlined" color="grey" onClick={() => setIsCloneModalOpen(true)}>
              Copy
            </Button>
            <Button
              variant="outlined"
              color="grey"
              onClick={() => history.push(getRoutePath("analytics.funnels.group", { groupId }))}
            >
              Cancel
            </Button>
            <Button type="submit" form="chartForm" loading={modifyMutation.isLoading}>
              Save
            </Button>
          </div>
        }
      >
        {chart.type === "FUNNEL" ? (
          <FunnelChartForm
            onSubmit={modifyChart}
            initialValues={getFunnelChartInitialValues(chart)}
            isEditable={isEditable}
          />
        ) : (
          <LineChartForm
            onSubmit={modifyChart}
            initialValues={getLineChartInitialValues(chart)}
            isEditable={isEditable}
          />
        )}
      </Page>
    </>
  )
}

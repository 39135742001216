import React, { useState } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SimpleBar from "simplebar-react"
import { isNil } from "ramda"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { SegmentOption } from "../types"
import styles from "./SegmentPickerBar.module.scss"
import Tippy from "@tippyjs/react"
import { SegmentType } from "resources/segment/segment/segmentTypes"
import lookalikesIcon from "images/lookalike.svg"

type Props = {
  onSelect: (newValue: SegmentOption) => void
  options: Array<SegmentOption>
  selectedOption: SegmentOption | null
  loading?: boolean
  searchMode?: boolean
  disableActivated?: boolean
  enabledTypes?: SegmentType[]
}

const SegmentPickerBar = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      onSelect,
      options,
      selectedOption,
      loading = false,
      searchMode = false,
      disableActivated,
      enabledTypes,
    },
    ref,
  ) => {
    const [selectedCategory, setSelectedCategory] = useState<SegmentType | undefined>(
      selectedOption?.type,
    )

    const categories: SegmentType[] = (
      ["custom", "featured", "smart", "lookalike"] as SegmentType[]
    )
      .filter(c => isNil(enabledTypes) || enabledTypes.includes(c))
      .filter(c => options.some(({ type }) => type === c))

    const selectedCategoryOptions = selectedCategory
      ? options.filter(({ type }) => type === selectedCategory)
      : []

    return (
      <div ref={ref} className={styles.wrapper} data-testid="segment-picker-bar">
        <div className={styles.header}>
          {!searchMode && selectedCategory && (
            <span className={styles.clickableTitle} onClick={() => setSelectedCategory(undefined)}>
              <FontAwesomeIcon
                icon={["fas", "chevron-left"]}
                className={classNames(styles.icon, styles.left)}
              />
              {selectedCategory}
            </span>
          )}
          {(searchMode || !selectedCategory) && <span className={styles.title}>Segment</span>}
        </div>
        <SimpleBar className={styles.bar}>
          {loading && <LoadingIndicator />}
          {!loading && options.length === 0 && (
            <ul className={styles.list}>
              <li>No results found</li>
            </ul>
          )}
          {!loading && searchMode && (
            <ul className={styles.list}>
              {categories.map(category => (
                <li key={category} className={styles.categoryListItem}>
                  <div className={styles.categoryName}>
                    {/* {category === "lookalike" && (
                      <div className={styles.lookalikeIconWrapper}>
                        <img src={lookalikesIcon} className={styles.icon} alt="" />
                      </div>
                    )} */}
                    {category}
                  </div>
                  <ul key={category} className={styles.list}>
                    {options
                      .filter(({ type }) => category === type)
                      .map(option => (
                        <li
                          key={option.value}
                          className={classNames(styles.clickableListItem, styles.subItem, {
                            [styles.disabled]: option.isActivated && disableActivated,
                          })}
                          onClick={
                            option.isActivated && disableActivated
                              ? undefined
                              : () => onSelect(option)
                          }
                        >
                          {option.isActivated && disableActivated ? (
                            <Tippy content="Segment is already activated.">
                              <span>{option.label}</span>
                            </Tippy>
                          ) : (
                            option.label
                          )}
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
            </ul>
          )}
          {!loading && !searchMode && selectedCategory && (
            <>
              <ul className={styles.list}>
                {selectedCategoryOptions.map(option => (
                  <li
                    key={option.value}
                    className={classNames(styles.clickableListItem, {
                      [styles.active]: option.value === selectedOption?.value,
                      [styles.disabled]: option.isActivated && disableActivated,
                    })}
                    onClick={
                      option.isActivated && disableActivated ? undefined : () => onSelect(option)
                    }
                  >
                    {option.isActivated && disableActivated ? (
                      <Tippy content="Segment is already activated.">
                        <span>{option.label}</span>
                      </Tippy>
                    ) : (
                      option.label
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
          {!loading && !searchMode && !selectedCategory && (
            <ul className={styles.list}>
              {categories.map(type => (
                <li
                  className={classNames(styles.clickableListItem, styles.category, {
                    [styles.lookalike]: type === "lookalike",
                  })}
                  key={type}
                  onClick={() => setSelectedCategory(type)}
                >
                  <div className={styles.categoryName}>
                    {type === "lookalike" && (
                      <div className={styles.lookalikeIconWrapper}>
                        <img src={lookalikesIcon} className={styles.icon} alt="" />
                      </div>
                    )}
                    {type}
                  </div>
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} className={styles.icon} />
                </li>
              ))}
            </ul>
          )}
        </SimpleBar>
      </div>
    )
  },
)

export default SegmentPickerBar

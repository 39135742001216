import React, { ReactNode } from "react"
import classNames from "classnames"

import styles from "./MarketingContent.module.scss"

type Img = { src: string; alt?: string; className?: string }
type ImgProps = ReactNode | Img

type Props = {
  children: ReactNode
  img: ReactNode | Img
}

const isImg = (img: ImgProps): img is Img => {
  return (img as Img).src !== undefined
}

const MarketingContent = ({ children, img }: Props) => {
  if (isImg(img))
    return (
      <div className={styles.container}>
        <img
          src={img.src}
          alt={img.alt ?? "Marketing content"}
          className={classNames(styles.image, img.className)}
        />
        <div className={styles.content}>
          <div className={styles.items}>{children}</div>
        </div>
      </div>
    )
  else
    return (
      <div className={styles.container}>
        <div className={styles.image}>{img}</div>
        <div className={styles.content}>
          <div className={styles.items}>{children}</div>
        </div>
      </div>
    )
}

export default MarketingContent

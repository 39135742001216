/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import styles from "./SegmentDetail.module.scss"
import { ToggleSwitchMultiple } from "components/UI/elements/ToggleSwitch"
import { Prompt, useHistory, useParams } from "react-router-dom"
import { getRoutePath } from "routes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/UI/elements/Button/Button"
import { Segment, SegmentType } from "resources/segment/segment/segmentTypes"
import Paper from "components/UI/elements/Paper"
import { View } from "./types"
import { getNewPathName } from "./utils"
import classNames from "classnames"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import ConditionBuilder from "components/ConditionBuilder/ConditionBuilder"
import SegmentConditionComponent, {
  getNewCondition,
} from "./components/SegmentCondition/SegmentCondition"
import PaperHeader from "components/UI/elements/PaperHeader"
import ConditionTreeOverview from "components/ConditionBuilder/components/ConditionTreeOverview/ConditionTreeOverview"
import { dec, equals, inc, isNil, without } from "ramda"
import { showToast } from "app/toast"
import TagPicker from "components/UI/components/TagPicker"
import TagComponent from "components/UI/elements/Tag"
import SegmentUsersModal from "./components/SegmentUsersModal/SegmentUsersModal"
import SegmentInsights from "./components/SegmentInsights/SegmentInsights"
import SegmentExports from "./components/SegmentExports/SegmentExports"
import { PERMISSION } from "sharedConstants"
import SegmentNotes from "./components/SegmentNotes/SegmentNotes"
import SmartSegmentSettings from "./components/SmartSegmentSettings/SmartSegmentSettings"
import ConfirmModal from "components/UI/components/ConfirmModal"
import CopyModal from "./components/CopyModal/CopyModal"
import SegmentSummary from "./components/SegmentSummary/SegmentSummary"
import { ConditionNumbersContext } from "./conditionNumbersContext"
import {
  SegmentCondition,
  SegmentConditionError,
} from "resources/segment/segment/segmentConditionsTypes"
import { makeConditionTreeValidator } from "components/ConditionBuilder/validation"
import { validateSegmentCondition } from "./components/SegmentCondition/validation"
import { ConditionPath, ConditionTree } from "types/conditionTree"
import { CSSTransition } from "react-transition-group"
import SegmentGuide from "./components/SegmentGuide/SegmentGuide"
import SegmentCustomersPreview from "./components/SegmentCustomersPreview/SegmentCustomersPreview"
import SegmentNumbers from "./components/SegmentNumbers/SegmentNumbers"
import SegmentUsers from "./components/SegmentUsers/SegmentUsers"
import { useFetchAllAttributes } from "resources/attribute/attributeQueries"
import { useFetchAllUsersMap, useModifyUser } from "resources/user/userQueries"
import {
  useFetchCurrentUser,
  useHasAccess,
  useHasSegmentPermission,
} from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import {
  refetchSegmentPermissionsForCurrentUser,
  useFetchSegmentPermissionsBySegmentId,
} from "resources/segmentPermission/segmentPermissionQueries"
import { TAG_ALL_QK, useFetchAllTags } from "resources/tag/tagQueries"
import { queryClient } from "app/queryClient"
import SegmentChannelNumbers from "./components/SegmentChannelNumbers/SegmentChannelNumbers"
import {
  CUSTOMERS,
  SEGMENT,
  useCopySegmentToCustomSegments,
  useCopySegmentToFeaturedSegments,
  useDeleteSegment,
  useFetchSegmentById,
  useModifySegment,
} from "resources/segment/segment/segmentQueries"
import {
  emitSegmentCounts,
  useListenSegmentCounts,
  useSegmentCountsStore,
} from "resources/segment/segment/segmentCounts"
import { Source } from "resources/dataSource/dataSourceTypes"
import create from "zustand"
import SearchWithSourceSelect from "components/UI/components/SearchWithSourceSelect/SearchWithSourceSelect"
import CreateLookalikeSegmentModal from "../CreateLookalikeSegmentModal/CreateLookalikeSegmentModal"
import Tippy from "@tippyjs/react"
import { Controller, useForm } from "react-hook-form"
import LookalikeSizeSlider from "../LookalikeSizeSlider/LookalikeSizeSlider"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { max, min, required } from "helpers/validators.helper"
import EditableValue from "components/UI/components/EditableValue/EditableValue"
import {
  emitSegmentChannelCounts,
  useEmailsChannelCountStore,
  useListenSegmentChannelCounts,
  usePushNotificationsChannelCountStore,
} from "resources/channel/channelCounts"
import { useFetchSystemInfo } from "resources/systemInfo/systemInfoQueries"
import { useFetchSegmentAggregations } from "resources/segment/segmentAggregation/segmentAggregationQueries"

const validateConditionTree = makeConditionTreeValidator(validateSegmentCondition)

// invalidate or refetch won't work - previously fetched pages will be still present in result
const refetchSegmentCustomers = (id: Segment["id"]) =>
  queryClient.removeQueries([SEGMENT, id, CUSTOMERS])

type InsightsFiltersState = {
  searchTerm: string
  sourceId: Source["id"] | null
  reset: () => void
  setSearchTerm: (searchTerm: string) => void
  setSourceId: (id: Source["id"] | null) => void
}

const insightsFiltersInitialState = {
  searchTerm: "",
  sourceId: null,
}

export const useInsightsFiltersStore = create<InsightsFiltersState>(set => ({
  ...insightsFiltersInitialState,

  reset: () => set(insightsFiltersInitialState),

  setSearchTerm: searchTerm => set({ searchTerm }),
  setSourceId: sourceId => set({ sourceId }),
}))

type SegmentDetailProps = {
  type?: SegmentType
  view?: View
  isSmartSetup?: boolean
}

export default function SegmentDetail({
  type = "custom",
  view = "builder",
  isSmartSetup,
}: SegmentDetailProps) {
  const { id } = useParams<{ id: string }>()
  const segmentId = useMemo(() => {
    return +id as Segment["id"]
  }, [id])
  const history = useHistory()
  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()
  const hasAccess = useHasAccess()
  const hasSegmentPermission = useHasSegmentPermission()
  const { data: systemInfo } = useFetchSystemInfo()
  const { reset, searchTerm, setSearchTerm, setSourceId, sourceId } = useInsightsFiltersStore()
  useEffect(() => reset, [reset])

  // SEGMENT
  const { data: segment } = useFetchSegmentById(+id, {
    refetchOnWindowFocus: false,
    onSuccess: ({ segment_type }) => {
      if (segment_type === "smart" && type !== "smart") {
        history.push(getRoutePath("segments.smart.detail", { id }))
      } else if (segment_type === "featured" && type !== "featured") {
        history.push(getRoutePath("segments.featured.detail", { id }))
      } else if (segment_type === "lookalike" && type !== "lookalike") {
        history.push(getRoutePath("segments.lookalike.detail", { id }))
      }
    },
  })
  const { mutateAsync: modifySegment, isLoading: isModifying } = useModifySegment()

  // SEGMENT COUNTS
  useListenSegmentCounts(segmentId)

  const {
    conditionsResultsCount,
    customersTotalCount,
    baseSegmentSize,
    conditionsNumbersFlat: conditionsNumbers,
    clear: clearSegmentNumbers,
  } = useSegmentCountsStore()

  const refetchSegmentNumbers = useCallback(
    (refreshCache?: boolean) => {
      clearSegmentNumbers()
      emitSegmentCounts({
        segmentId,
        refreshCache,
      })
    },
    [segmentId, clearSegmentNumbers],
  )

  // CHANNEL COUNTS
  const [isRefetchingEmailsCounts, setIsRefetchingEmailsCounts] = useState(false)
  const [isRefetchingPushNotificationsCounts, setIsRefetchingPushNotificationsCounts] =
    useState(false)
  useListenSegmentChannelCounts([segmentId])
  const { reset: resetEmailsChannelCount } = useEmailsChannelCountStore()
  const { reset: resetPushNotitifactionsChannelCount } = usePushNotificationsChannelCountStore()

  const refetchChannelsSegmentNumbers = useCallback(() => {
    setIsRefetchingEmailsCounts(true)
    setIsRefetchingPushNotificationsCounts(true)
    resetEmailsChannelCount()
    resetPushNotitifactionsChannelCount()

    emitSegmentChannelCounts({
      channelType: "emails",
      segmentIds: [segmentId],
      onAck: () => setIsRefetchingEmailsCounts(false),
    })
    emitSegmentChannelCounts({
      channelType: "push_notifications",
      segmentIds: [segmentId],
      onAck: () => setIsRefetchingPushNotificationsCounts(false),
    })
  }, [resetEmailsChannelCount, resetPushNotitifactionsChannelCount, segmentId])

  const {
    allAggregationValues,
    segmentAggregationValues,
    refetch: refetchAggregationValues,
  } = useFetchSegmentAggregations(segment?.id)

  // CONDITIONS

  const conditionsFromStore = segment?.settings?.conditions_operation ?? null
  const [conditions, setConditions] = useState<ConditionTree<SegmentCondition> | null>(null)

  // CONDITIONS (CONT.)

  useEffect(() => {
    if (segment?.settings?.conditions_operation)
      setConditions(segment.settings.conditions_operation)
  }, [segment])

  const [shouldValidate, setShouldValidate] = useState(false)
  const [error, setError] = useState<ConditionTree<SegmentConditionError | null>>(null)

  const setConditionsAndValidate = useCallback(
    conditions => {
      setConditions(conditions)
      if (shouldValidate) {
        setError(validateConditionTree(conditions))
      }
    },
    [shouldValidate],
  )

  const [hoveredPath, setHoveredPath] = useState<ConditionPath>()
  const [isSavingConditions, setIsSavingConditions] = useState(false)

  const saveConditions = useCallback(async () => {
    setShouldValidate(true)
    const error = validateConditionTree(conditions)
    setError(error)
    if (error) {
      return
    } else {
      setShouldValidate(false)
    }

    setIsSavingConditions(true)
    const settings = segment?.settings ?? {}
    try {
      await modifySegment(
        {
          id: +id,
          data: {
            settings: { ...settings, conditions_operation: conditions ?? undefined },
          },
        },
        {
          onSuccess: () => {
            showToast("Conditions saved.")
            refetchSegmentNumbers()
            refetchSegmentCustomers(+id)
            refetchChannelsSegmentNumbers()
            refetchAggregationValues()
          },
        },
      )
    } catch {
    } finally {
      setIsSavingConditions(false)
    }
  }, [
    conditions,
    segment?.settings,
    modifySegment,
    id,
    refetchSegmentNumbers,
    refetchChannelsSegmentNumbers,
    refetchAggregationValues,
  ])

  const hasUnsavedConditions = !equals(conditionsFromStore, conditions)

  // NAME

  const editName = useCallback(
    async newName => {
      try {
        await modifySegment(
          { id: +id, data: { name: newName } },
          {
            onSuccess: () => {
              showToast("Segment name edited.")
            },
          },
        )
      } catch {}
    },
    [id, modifySegment],
  )

  // SEGMENT PERMISSIONS

  const { data: segmentPermissions, isSuccess: areSegmentPermissionsFulfilled } =
    useFetchSegmentPermissionsBySegmentId(+id)
  const { data: usersMap, isSuccess: areUsersFulfilled } = useFetchAllUsersMap()

  // TAGS

  const { data: allTags = [], isSuccess: areTagsFulfilled } = useFetchAllTags()

  const assignTag = useCallback(
    async tagId => {
      try {
        await modifySegment(
          { id: +id, data: { tag_ids: segment!.tag_ids.concat(tagId) } },
          {
            onSuccess: () => {
              showToast("Tag assigned.")
              queryClient.refetchQueries(TAG_ALL_QK)
            },
          },
        )
      } catch {}
    },
    [id, segment, modifySegment],
  )

  const unassignTag = useCallback(
    async tagId => {
      try {
        await modifySegment(
          { id: +id, data: { tag_ids: without([tagId], segment!.tag_ids) } },
          {
            onSuccess: () => {
              showToast("Tag unassigned.")
              queryClient.refetchQueries(TAG_ALL_QK)
            },
          },
        )
      } catch {}
    },
    [id, segment, modifySegment],
  )

  // IS EDITABLE

  const [isEditable, setIsEditable] = useState(true)

  useEffect(() => {
    setIsEditable(
      isSmartSetup ||
        (type !== "smart" &&
          hasSegmentPermission(+id, {
            segmentType: type === "featured" ? "featured" : "custom",
          }) === PERMISSION.WRITE),
    )
  }, [segmentPermissions, id, isSmartSetup, hasSegmentPermission, type])

  // UI STATE

  const [isUsersModalOpen, setIsUsersModalOpen] = useState(false)
  const [isSummaryOpen, setIsSummaryOpen] = useState(true)
  const [isNotesOpen, setIsNotesOpen] = useState(false)
  const [isCustomersOpen, setIsCustomersOpen] = useState(false)

  // Reset UI state when id changes
  useEffect(() => {
    setIsUsersModalOpen(false)
    setIsSummaryOpen(true)
    setIsNotesOpen(false)
    setIsCustomersOpen(false)
    clearSegmentNumbers()
    resetEmailsChannelCount()
    resetPushNotitifactionsChannelCount()
  }, [id, clearSegmentNumbers, resetEmailsChannelCount, resetPushNotitifactionsChannelCount])

  // ROUTING
  useEffect(() => {
    if (view !== "builder") {
      if (hasUnsavedConditions) {
        undoChanges()
      }
    }
  }, [view]) // eslint-disable-line react-hooks/exhaustive-deps

  const goToView = (newView: View) => {
    history.push(getRoutePath(getNewPathName(newView, type, isSmartSetup), { id }))
  }

  const backLink = getRoutePath(
    type === "featured"
      ? "segments.featured"
      : type === "smart"
      ? "segments.smart"
      : type === "lookalike"
      ? "segments.lookalike"
      : "segments.custom",
  )

  const toggleViewOptions = isSmartSetup
    ? [{ value: "settings" }, { value: "builder" }, { value: "insights" }, { value: "exports" }]
    : [{ value: "builder" }, { value: "insights" }, { value: "exports" }]

  // DELETE

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const { mutate: deleteSegment, isLoading: isDeleting } = useDeleteSegment(type)

  // COPY

  const { mutate: copySegmentToCustom, isLoading: isCopyingToCustom } =
    useCopySegmentToCustomSegments()
  const { mutate: copySegmentToFeatured, isLoading: isCopyingToFeatured } =
    useCopySegmentToFeaturedSegments()
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)

  const copyToCustomSegments = () =>
    copySegmentToCustom(
      { id: +id },
      {
        onSuccess: ({ segment: copiedSegment }) => {
          refetchSegmentPermissionsForCurrentUser()
          setIsCopyModalOpen(false)
          history.push(getRoutePath("segments.custom.detail", { id: copiedSegment.id }))
        },
      },
    )

  const copyToFeaturedSegments = () =>
    copySegmentToFeatured(
      { id: +id },
      {
        onSuccess: ({ segment: copiedSegment }) => {
          setIsCopyModalOpen(false)
          history.push(getRoutePath("segments.featured.detail", { id: copiedSegment.id }))
        },
      },
    )

  // SMART SEGMENT FE SETTINGS

  const changeSmartSegmentFESettings = useCallback(
    async newFESettings => {
      // TODO: indication of loading?
      try {
        await modifySegment(
          { id: +id, data: { frontend_settings: newFESettings } },
          {
            onSuccess: () => {
              showToast("Segment's visual attributes were updated.")
            },
          },
        )
      } catch {}
    },
    [id, modifySegment],
  )

  // ONBOARDING GUIDE

  const { frontend_settings, id: userId } = currentUser!

  const [isGuideOpen, setIsGuideOpen] = useState(!frontend_settings?.hasSeenSegmentGuide)
  const [guideStep, setGuideStep] = useState(1)

  const guideStepBack = useCallback(() => setGuideStep(dec), [])
  const guideStepForward = useCallback(() => setGuideStep(inc), [])

  const closeGuide = useCallback(() => {
    setIsGuideOpen(false) // Hide immediately, don't wait for the BE response
    modifyUser({
      id: userId,
      data: {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          hasSeenSegmentGuide: true,
        },
      },
    })
  }, [frontend_settings, modifyUser, userId])

  const { isSuccess: areAttributesFulfilled } = useFetchAllAttributes()

  const isReady =
    segment &&
    areSegmentPermissionsFulfilled &&
    areUsersFulfilled &&
    areTagsFulfilled &&
    areAttributesFulfilled

  // LOOKALIKE MODAL

  const [isLookalikeModalOpen, setIsLookalikeModalOpen] = useState(false)

  // LOOKALIKE SETTINGS

  type LookalikeSettingsValues = {
    size: number
    includeBaseSegment: boolean
  }

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors: lookalikeSettingsErrors, isDirty: lookalikeSettingsIsDirty },
    setValue,
    getValues,
    reset: resetLookalikeSettings,
  } = useForm<LookalikeSettingsValues>({
    defaultValues: { includeBaseSegment: false, size: 0 },
  })

  // This has to be here because of the order in which vars are declared and used, sorry
  const undoChanges = useCallback(() => {
    const settings = segment?.settings?.lookalike

    setConditions(conditionsFromStore)
    setShouldValidate(false)
    setError(null)
    refetchSegmentNumbers()
    refetchSegmentCustomers(+id)
    refetchChannelsSegmentNumbers()
    resetLookalikeSettings({
      size: settings?.size ?? 1,
      includeBaseSegment: settings?.include_base_segment ?? false,
    })
  }, [
    id,
    segment?.settings?.lookalike,
    conditionsFromStore,
    refetchSegmentNumbers,
    refetchChannelsSegmentNumbers,
    resetLookalikeSettings,
  ])

  function modifyLookalikeSettings({ size, includeBaseSegment }: LookalikeSettingsValues) {
    modifySegment(
      {
        id: +id,
        data: {
          settings: {
            ...segment!.settings,
            lookalike: { size, include_base_segment: includeBaseSegment },
          },
        },
      },
      {
        onSuccess() {
          showToast("Lookalike segment modified.")
          refetchSegmentNumbers(true)
          refetchSegmentCustomers(+id)
          refetchChannelsSegmentNumbers()
          resetLookalikeSettings({ size, includeBaseSegment }, { keepDirty: false })
          refetchAggregationValues()
        },
      },
    )
  }

  const onSliderChange = useCallback(
    (value: number) => {
      setValue("size", value, { shouldDirty: true })
    },
    [setValue],
  )

  const includeBaseSegment = watch("includeBaseSegment")
  const size = watch("size")

  // So that we can display the lookalike settings while the numbers are reloading
  const [storedBaseSegmentSize, setStoredBaseSegmentSize] = useState<number | null>(null)
  const [storedCustomersTotal, setStoredCustomersTotal] = useState<number | null>(null)

  useEffect(() => {
    if (!isNil(baseSegmentSize)) {
      setStoredBaseSegmentSize(baseSegmentSize)
    }
  }, [baseSegmentSize])

  useEffect(() => {
    if (!isNil(customersTotalCount)) {
      setStoredCustomersTotal(customersTotalCount)
    }
  }, [customersTotalCount])

  const maxSize = useMemo(
    () => (storedCustomersTotal ? Math.floor(storedCustomersTotal * 0.2) : 1),
    [storedCustomersTotal],
  )
  const minSize = useMemo(
    () =>
      includeBaseSegment && !isNil(storedBaseSegmentSize)
        ? Math.min(storedBaseSegmentSize + 1, maxSize)
        : 1,
    [storedBaseSegmentSize, includeBaseSegment, maxSize],
  )

  useEffect(() => {
    const settings = segment?.settings?.lookalike
    if (!isNil(settings)) {
      setValue("size", settings.size)
      setValue("includeBaseSegment", settings.include_base_segment)
    }
  }, [segment?.settings?.lookalike, setValue])

  let warning = ""
  let disabled = false

  if (storedBaseSegmentSize === 0) {
    disabled = true
    warning =
      "The size of the base segment is 0, so the resulting size of the lookalike segment will also be 0."
  } else if (includeBaseSegment) {
    if (!isNil(storedBaseSegmentSize) && storedBaseSegmentSize >= maxSize) {
      disabled = true
      warning =
        "The size of the base segment is greater than or equal to 20 % of the entire profile database, so it's not possible to find lookalike profiles that don't belong to this segment."
    }
  } else if (storedBaseSegmentSize === storedCustomersTotal) {
    disabled = true
    warning =
      "The base segment includes the whole profiles database, so the size of the lookalike segment will be 0."
  } else if (
    !isNil(storedBaseSegmentSize) &&
    !isNil(storedCustomersTotal) &&
    storedBaseSegmentSize + size > storedCustomersTotal
  ) {
    warning =
      "The base segment is too large, so it won't be possible to find the specified number of profiles."
  } else if (
    !isNil(conditionsResultsCount) &&
    conditionsResultsCount < size &&
    !lookalikeSettingsIsDirty
  ) {
    warning =
      "It was not possible to find the desired number of lookalike profiles for the given segment that meet the similarity criteria."
  }

  if (!isReady) {
    return (
      <Page
        contentClassName={styles.container}
        title={`Edit ${type} segment`}
        backRouteFallback={backLink}
      >
        <LoadingIndicator />
      </Page>
    )
  }

  if (!usersMap) {
    return null
  }

  const { name, tag_ids, author_id } = segment

  const reducedOpacity = { opacity: 0.3 }

  return (
    <Page
      contentClassName={styles.container}
      title={`Edit ${type} segment`}
      headerContent={
        <div
          className={styles.buttonsWrapper}
          style={
            isGuideOpen && (guideStep !== 3 || view !== "builder") ? reducedOpacity : undefined
          }
        >
          {isEditable && view === "builder" && (
            <Button
              className={styles.button}
              color="grey"
              variant="outlined"
              disabled={(!hasUnsavedConditions || isSavingConditions) && !lookalikeSettingsIsDirty}
              onClick={undoChanges}
            >
              undo changes
            </Button>
          )}
          {isEditable && (
            <Button
              className={styles.button}
              color="red"
              variant="outlined"
              onClick={() => setIsDeleteModalOpen(true)}
              disabled={!isEditable}
            >
              delete
            </Button>
          )}
          <Button
            className={styles.button}
            color="grey"
            variant="outlined"
            onClick={() => {
              if (segment.segment_type === "lookalike") {
                copyToCustomSegments()
              } else {
                setIsCopyModalOpen(true)
              }
            }}
            disabled={!hasAccess.segments.create && !hasAccess.segments.featured.edit}
            loading={segment.segment_type === "lookalike" && isCopyingToCustom}
          >
            copy
          </Button>
          {isEditable &&
            (segment.segment_type === "lookalike" ? (
              <Button
                className={styles.button}
                color="green"
                loading={isModifying}
                type="submit"
                form="lookalikeSettingsForm"
                disabled={!lookalikeSettingsIsDirty || disabled}
              >
                save
              </Button>
            ) : (
              view === "builder" && (
                <Button
                  className={styles.button}
                  color="green"
                  loading={isSavingConditions}
                  onClick={saveConditions}
                  disabled={!hasUnsavedConditions}
                >
                  save conditions
                </Button>
              )
            ))}
        </div>
      }
      backRouteFallback={backLink}
    >
      <Prompt
        when={hasUnsavedConditions && !isDeleting}
        message="Changes to conditions you made will not be saved."
      />
      {(segment.segment_type === "custom" || segment.segment_type === "featured") && (
        <CreateLookalikeSegmentModal
          isOpen={isLookalikeModalOpen}
          onClose={() => setIsLookalikeModalOpen(false)}
          segmentId={segment.id}
        />
      )}

      <CSSTransition
        in={isGuideOpen && guideStep === 1}
        timeout={200}
        classNames="fade"
        unmountOnExit
      >
        <SegmentGuide
          onClose={closeGuide}
          onPrev={guideStepBack}
          onNext={guideStepForward}
          step={1}
          style={{ top: "50%", left: "50%", transform: "translate(-50%)", position: "fixed" }}
        />
      </CSSTransition>

      {type !== "smart" && (
        <SegmentUsersModal
          isOpen={isUsersModalOpen}
          onClose={() => setIsUsersModalOpen(false)}
          segmentId={+id}
          authorId={author_id}
          acl={segmentPermissions!}
          allUsers={usersMap}
          isEditable={isEditable}
          segmentType={type}
        />
      )}

      <CopyModal
        open={isCopyModalOpen}
        handleClose={() => setIsCopyModalOpen(false)}
        type={type}
        onCopyClick={copyToCustomSegments}
        onCopyToFeaturedClick={copyToFeaturedSegments}
        copying={isCopyingToCustom}
        copyingToFeatured={isCopyingToFeatured}
      />

      <ConfirmModal
        open={isDeleteModalOpen}
        type="delete"
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={() =>
          deleteSegment(+id, {
            onSuccess: () => {
              history.push(backLink)
              queryClient.removeQueries([SEGMENT, id])
            },
          })
        }
        title="delete segment"
        isLoading={isDeleting}
        text="Do you really want to delete the current segment?"
      />

      <div
        className={styles.viewSwitchWrapper}
        style={isGuideOpen && guideStep !== 4 ? reducedOpacity : undefined}
      >
        <CSSTransition
          in={isGuideOpen && guideStep === 4}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <SegmentGuide
            onClose={closeGuide}
            onPrev={guideStepBack}
            onNext={guideStepForward}
            step={4}
            style={{ top: "calc(100% + 10px)", left: "50%", transform: "translateX(-50%)" }}
          />
        </CSSTransition>

        <div className={styles.line}></div>
        <ToggleSwitchMultiple
          width={isSmartSetup ? "380px" : "280px"}
          name="view-switch"
          buttons={toggleViewOptions}
          checked={view}
          handleToggle={goToView}
          className={styles.viewSwitch}
        />
        <div className={styles.line}></div>
      </div>

      <div className={styles.mainContent}>
        <div
          className={styles.leftPanel}
          style={isGuideOpen && guideStep !== 2 ? reducedOpacity : undefined}
        >
          <CSSTransition
            in={isGuideOpen && guideStep === 2}
            timeout={200}
            classNames="fade"
            unmountOnExit
          >
            <SegmentGuide
              onClose={closeGuide}
              onPrev={guideStepBack}
              onNext={guideStepForward}
              step={2}
              style={{ top: "0", left: "calc(100% + 10px)" }}
            />
          </CSSTransition>
          <Paper className={styles.card}>
            <div data-testid="segment-name" className={styles.nameWrapper}>
              <div className={styles.title}>name</div>
              {isEditable ? (
                <EditableValue
                  initValue={name}
                  onChange={editName}
                  validate={required}
                  className={styles.name}
                />
              ) : (
                <div className={styles.name}>{name}</div>
              )}
              <div className={styles.stretch}></div>
              <div data-testid="segment-id" className={styles.segmentId}>
                ID: {id}
              </div>
            </div>
            {type !== "smart" && (
              <div className={styles.usersWrapper}>
                <div className={styles.title}>users</div>
                <SegmentUsers
                  acl={segmentPermissions!}
                  allUsers={usersMap}
                  authorId={author_id}
                  openUsersModal={() => setIsUsersModalOpen(true)}
                />
              </div>
            )}
          </Paper>
          <Paper className={styles.tagsCard}>
            <div className={styles.title}>tags</div>
            <div className={styles.tagPickerWrapper}>
              {tag_ids.map(tagId => {
                const tag = allTags.find(({ id }) => id === tagId)

                return tag ? (
                  <TagComponent
                    key={tag.id}
                    clickable={isEditable}
                    color={tag.color ? tag.color : "primary"}
                    onClick={isEditable ? () => unassignTag(tagId) : undefined}
                  >
                    {tag.name}
                  </TagComponent>
                ) : null
              })}
              <TagPicker
                className={styles.tagPicker}
                selectedTagIds={tag_ids}
                allTags={allTags}
                onTagSelect={assignTag}
                disabled={!isEditable}
              />
            </div>
          </Paper>

          {(segment.segment_type === "custom" || segment.segment_type === "featured") && (
            <Paper className={styles.card}>
              <Tippy
                content={
                  !hasAccess.segments.create
                    ? "You don't have the permission to create segments."
                    : !systemInfo?.lookalike_segment_configured
                    ? "To create lookalike segments, first configure lookalike settings under the Administration tab."
                    : "To create a lookalike segment, the base segment must have defined conditions."
                }
                disabled={
                  hasAccess.segments.create &&
                  systemInfo?.lookalike_segment_configured &&
                  !isNil(conditionsFromStore)
                }
              >
                <div>
                  <Button
                    disabled={
                      !hasAccess.segments.create ||
                      !systemInfo?.lookalike_segment_configured ||
                      isNil(conditionsFromStore)
                    }
                    fullWidth
                    onClick={() => setIsLookalikeModalOpen(true)}
                  >
                    <FontAwesomeIcon icon={["fas", "user-plus"]} /> Create lookalike segment
                  </Button>
                </div>
              </Tippy>
            </Paper>
          )}

          {segment.segment_type === "lookalike" && (
            <Paper className={styles.card}>
              <div className={styles.title}>lookalike segment size</div>
              <form
                className={styles.lookalikeSettings}
                onSubmit={handleSubmit(modifyLookalikeSettings)}
                id="lookalikeSettingsForm"
              >
                {isNil(storedCustomersTotal) || isNil(storedBaseSegmentSize) ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <LookalikeSizeSlider
                      maxSize={maxSize}
                      baseSegmentSize={
                        includeBaseSegment && storedBaseSegmentSize
                          ? storedBaseSegmentSize
                          : undefined
                      }
                      value={size}
                      onChange={onSliderChange}
                      disabled={!isEditable}
                    />

                    <div className={styles.inputs}>
                      <TextInput
                        value={storedBaseSegmentSize ?? ""}
                        label="Base segment size"
                        disabled
                      />
                      <Controller
                        control={control}
                        rules={{
                          validate: {
                            required: required,
                            max: max(maxSize),
                            min: min(minSize),
                          },
                        }}
                        name="size"
                        render={({ field: { value, onChange } }) => (
                          <TextInput
                            data-testid="ls-size-input"
                            type="number"
                            label="Lookalike size"
                            min={1}
                            max={maxSize}
                            error={lookalikeSettingsErrors.size?.message}
                            value={value}
                            onChange={e => {
                              onChange(parseInt(e.target.value))
                            }}
                            disabled={!isEditable}
                          />
                        )}
                      />
                    </div>
                    <Controller
                      control={control}
                      name="includeBaseSegment"
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          checked={value}
                          onChange={e => {
                            onChange(e)
                            const prevSize = getValues().size
                            if (e.target.checked) {
                              setValue("size", Math.min(prevSize + storedBaseSegmentSize!, maxSize))
                            } else {
                              setValue("size", Math.max(prevSize - storedBaseSegmentSize!, minSize))
                            }
                          }}
                          label="Include base segment"
                          className={styles.checkbox}
                          disabled={!isEditable}
                        />
                      )}
                    />

                    {warning && (
                      <div data-testid="warning-message" className={styles.warning}>
                        <FontAwesomeIcon icon={["far", "info-circle"]} /> {warning}
                      </div>
                    )}
                  </>
                )}
              </form>
            </Paper>
          )}
          <Paper className={classNames(styles.card, styles.segmented)}>
            <div className={styles.title}>segmented</div>
            <SegmentNumbers
              hasUnsavedConditions={hasUnsavedConditions}
              segmentId={segmentId}
              refetch={() => {
                refetchSegmentNumbers(true)
                refetchChannelsSegmentNumbers()
              }}
            />
          </Paper>
          <Paper className={classNames(styles.card, styles.availableInChannels)}>
            <div className={styles.title}>Available in channels</div>
            <div className={styles.channels}>
              <SegmentChannelNumbers
                hasUnsavedConditions={hasUnsavedConditions}
                isRefetching={isRefetchingEmailsCounts}
                channelType="emails"
                segmentId={segmentId}
              />
              <SegmentChannelNumbers
                hasUnsavedConditions={hasUnsavedConditions}
                isRefetching={isRefetchingPushNotificationsCounts}
                channelType="push_notifications"
                segmentId={segmentId}
              />
            </div>
          </Paper>
          {segment.segment_type !== "lookalike" && (
            <Paper className={styles.card}>
              <div className={styles.flexWrapper}>
                <div className={classNames(styles.title, styles.stretch)}>summary</div>
                <button className={styles.openButton} onClick={() => setIsSummaryOpen(s => !s)}>
                  <FontAwesomeIcon
                    icon={["fas", "chevron-down"]}
                    flip={isSummaryOpen ? "vertical" : undefined}
                  />
                </button>
              </div>
              <SegmentSummary
                conditionTree={conditions}
                segmentedCustomers={conditionsResultsCount ?? null}
                totalCustomers={customersTotalCount ?? null}
                hasUnsavedConditions={hasUnsavedConditions}
                isOpen={isSummaryOpen}
              />
            </Paper>
          )}
          <Paper className={styles.notesCard}>
            <div className={styles.notesCardHeader}>
              <div className={classNames(styles.title, styles.stretch)}>notes</div>
              <button className={styles.openButton} onClick={() => setIsNotesOpen(s => !s)}>
                <FontAwesomeIcon
                  icon={["fas", "chevron-down"]}
                  flip={isNotesOpen ? "vertical" : undefined}
                />
              </button>
            </div>
            <SegmentNotes
              segmentId={+id}
              isEditable={isEditable}
              toggleNotes={() => {}}
              isOpen={isNotesOpen}
            />
          </Paper>
          <Paper className={styles.card}>
            <div data-testid="preview-profiles" className={styles.flexWrapper}>
              <div className={classNames(styles.title, styles.stretch)}>preview profiles</div>
              <button className={styles.openButton} onClick={() => setIsCustomersOpen(s => !s)}>
                <FontAwesomeIcon
                  icon={["fas", "chevron-down"]}
                  flip={isCustomersOpen ? "vertical" : undefined}
                />
              </button>
            </div>
            <SegmentCustomersPreview
              hasUnsavedConditions={hasUnsavedConditions}
              isOpen={isCustomersOpen}
            />
          </Paper>
        </div>

        <div
          className={styles.viewWrapper}
          style={isGuideOpen && guideStep !== 3 ? reducedOpacity : undefined}
        >
          <CSSTransition
            in={isGuideOpen && guideStep === 3}
            timeout={200}
            classNames="fade"
            unmountOnExit
          >
            <SegmentGuide
              onClose={closeGuide}
              onPrev={guideStepBack}
              onNext={guideStepForward}
              step={3}
              style={{ top: "0", right: "calc(100% + 10px)" }}
            />
          </CSSTransition>

          {view === "settings" && (
            <div style={isGuideOpen ? reducedOpacity : undefined}>
              <SmartSegmentSettings
                id={id}
                segment={segment}
                handleSmartSegmentFeSettingsChange={changeSmartSegmentFESettings}
              />
            </div>
          )}
          {view === "builder" && (
            <>
              <PaperHeader
                titleText="conditions"
                size="small"
                className={styles.conditionsOverviewWrapper}
              >
                <ConditionTreeOverview
                  conditionTree={conditions}
                  onChange={setConditionsAndValidate}
                  highlightedPath={hoveredPath}
                  onHover={setHoveredPath}
                  readOnly={!isEditable || segment.segment_type === "lookalike"}
                />
              </PaperHeader>
              <Paper className={styles.conditionBuilderWrapper}>
                <ConditionNumbersContext.Provider
                  value={{
                    totalCustomers: customersTotalCount ?? null,
                    conditionNumbers: conditionsNumbers ?? [],
                    isInvalidNumbers: hasUnsavedConditions,
                  }}
                >
                  <ConditionBuilder<SegmentCondition, SegmentConditionError | null>
                    conditionTree={conditions}
                    onChange={setConditionsAndValidate}
                    conditionComponent={SegmentConditionComponent}
                    getNewCondition={getNewCondition}
                    isEditable={isEditable && segment.segment_type !== "lookalike"}
                    error={error}
                    highlightedPath={hoveredPath}
                    onHover={setHoveredPath}
                  />
                </ConditionNumbersContext.Provider>
              </Paper>
            </>
          )}
          {view === "insights" && (
            <div style={isGuideOpen ? reducedOpacity : undefined}>
              <PaperHeader titleText="insights" size="small" className={styles.insightsHeader}>
                <div className={styles.insightsSearchWrapper}>
                  <SearchWithSourceSelect
                    searchValue={searchTerm}
                    setSearchValue={setSearchTerm}
                    selectValue={sourceId}
                    setSelectValue={setSourceId}
                    placeholder="Search for insights"
                  />
                </div>
              </PaperHeader>
              <Paper>
                {hasAccess.segments.insights ? (
                  <SegmentInsights
                    id={id}
                    segmentAggregationValues={segmentAggregationValues}
                    allAggregationValues={allAggregationValues}
                  />
                ) : (
                  <div className={styles.noAccessMessage}>
                    You don't have permission to view segment insights.
                  </div>
                )}
              </Paper>
            </div>
          )}
          {view === "exports" && (
            <div style={isGuideOpen ? reducedOpacity : undefined}>
              {hasAccess.segments.export ? (
                <SegmentExports
                  segment={segment}
                  isEditable={isEditable}
                  isSmartSegment={type === "smart"}
                  isFeaturedSegment={type === "featured"}
                  refetchSegmentNumbers={() => refetchSegmentNumbers(true)}
                />
              ) : (
                <>
                  <PaperHeader titleText="exports" size="small" />
                  <Paper className={styles.noAccessMessage}>
                    You don't have permission to view segment exports.
                  </Paper>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Page>
  )
}

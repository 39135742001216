import React from "react"
import PropTypes from "prop-types"
import ReactSlider from "react-slider"

import "./NumberSlider.scss"

const NumberSliderField = ({ input, label, marks = [], min, max }) => {
  return (
    <div className="number-slider-field">
      {label && <label>{label}</label>}
      <ReactSlider
        className="horizontal-slider"
        marks={marks}
        markClassName={`slider-mark marked-${input.value}`}
        thumbClassName="slider-thumb"
        trackClassName="slider-track"
        value={input.value}
        onChange={input.onChange}
        min={min}
        max={max}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
        renderMark={props => {
          const { className, ...otherProps } = props
          const match = /marked-(\d+)/g.exec(className)
          const selectedValue = parseInt(match[1])
          return (
            <span
              className={`${className} ${props.key > selectedValue ? "grey" : ""}`}
              {...otherProps}
            >
              {props.key}
            </span>
          )
        }}
      />
    </div>
  )
}

NumberSliderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  marks: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
}

export default NumberSliderField

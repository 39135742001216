import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import Table, {
  RowMessage,
  TbodySortable,
  Td,
  Th,
  Thead,
  TrSortable,
} from "components/UI/elements/Table"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import { useCallback, useState } from "react"
import IconButton from "components/UI/elements/IconButton/IconButton"
import styles from "./FunnelGroupsList.module.scss"
import { getRoutePath } from "routes"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { DragEndEvent } from "@dnd-kit/core"
import Page from "components/UI/Page/Page"
import {
  useDeleteFunnelGroup,
  useFetchAllFunnelGroups,
  useModifyFunnelGroup,
  useMoveFunnelGroup,
} from "resources/funnelGroup/funnelGroupQueries"
import { FunnelGroup } from "resources/funnelGroup/funnelGroupTypes"
import { Link } from "react-router-dom"
import { showToast } from "app/toast"

export default function FunnelGroupsList() {
  const funnelGroupsQuery = useFetchAllFunnelGroups()
  const modifyMutation = useModifyFunnelGroup()
  const deleteMutation = useDeleteFunnelGroup()
  const moveMutation = useMoveFunnelGroup()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [funnelGroupToDelete, setFunnelGroupToDelete] = useState<FunnelGroup | null>(null)

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      if (event.over?.data.current) {
        const toIndex = event.over.data.current.sortable.index
        const fromIndex = event.active.data.current?.sortable.index
        if (!isNaN(fromIndex) && toIndex !== fromIndex) {
          moveMutation.mutate({
            id: event.active.id as FunnelGroup["id"],
            // Note: `order_index` is 1-indexed
            data: { from_index: fromIndex + 1, to_index: toIndex + 1 },
          })
        }
      }
    },
    [moveMutation],
  )

  function toggleDisabled(funnelGroup: FunnelGroup) {
    modifyMutation.mutate(
      {
        id: funnelGroup.id,
        data: { disabled: !funnelGroup.disabled },
      },
      {
        onSuccess({ funnel_group: { disabled } }) {
          showToast(`Funnel group ${disabled ? "hidden" : "visible"}.`)
        },
      },
    )
  }

  return (
    <Page
      title="Funnel groups"
      description="Funnel groups enhance organizational clarity and ensure user role access control, allowing you to efficiently organize and oversee funnels and line charts."
      headerContent={
        <Link to={getRoutePath("administration.funnel-groups.create")}>
          <Button icon={["far", "plus"]}>Create funnel group</Button>
        </Link>
      }
    >
      {funnelGroupsQuery.isLoading && <LoadingIndicator />}
      {funnelGroupsQuery.isSuccess && (
        <Paper>
          <div className={styles.trashButtonWrapper}>
            <Link to={getRoutePath("administration.funnel-groups.trash")}>
              <Button color="grey" variant="outlined" icon={["far", "trash-alt"]}>
                Trash
              </Button>
            </Link>
          </div>
          {funnelGroupsQuery.data.length === 0 ? (
            <RowMessage>No funnel groups found.</RowMessage>
          ) : (
            <Table>
              <Thead>
                <Th className={styles.dragHandleCol}></Th>
                <Th>Name</Th>
                <Th className={styles.tableEnabledCol}>Show in Analytics tab</Th>
                <Th className={styles.tableActionsCol}>&nbsp;</Th>
              </Thead>
              <TbodySortable items={funnelGroupsQuery.data} onDragEnd={onDragEnd}>
                {funnelGroupsQuery.data.map(funnelGroup => (
                  <TrSortable key={funnelGroup.id} id={funnelGroup.id}>
                    <Td textBlack textBold textBigger>
                      {funnelGroup.name}
                    </Td>
                    <Td className={styles.tableEnabledCol}>
                      <ToggleButton
                        value={!funnelGroup.disabled}
                        handleToggle={() => toggleDisabled(funnelGroup)}
                        isLoading={
                          modifyMutation.isLoading &&
                          modifyMutation.variables?.id === funnelGroup.id
                        }
                      />
                    </Td>
                    <Td textAlignRight className={styles.tableActionsCol}>
                      <div className={styles.actions}>
                        <Link
                          to={getRoutePath("administration.funnel-groups.detail", {
                            id: funnelGroup.id,
                          })}
                        >
                          <IconButton
                            color="black"
                            size="xs"
                            icon="pencil-alt"
                            tooltip="Edit"
                            variant="outlined"
                          />
                        </Link>
                        <IconButton
                          color="red"
                          size="xs"
                          onClick={() => {
                            setFunnelGroupToDelete(funnelGroup)
                            setIsDeleteModalOpen(true)
                          }}
                          icon="trash-alt"
                          tooltip="Delete"
                          variant="outlined"
                        />
                      </div>
                    </Td>
                  </TrSortable>
                ))}
              </TbodySortable>
            </Table>
          )}
        </Paper>
      )}
      <ConfirmModal
        title="Are you sure?"
        open={isDeleteModalOpen}
        type="delete"
        text={`Do you really want to delete funnel group ${funnelGroupToDelete?.name}?`}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={() => {
          deleteMutation.mutate(
            { id: funnelGroupToDelete!.id },
            {
              onSuccess() {
                setIsDeleteModalOpen(false)
              },
            },
          )
        }}
        isLoading={deleteMutation.isLoading}
      />
    </Page>
  )
}

import { ChartData, ChartsDataResponse } from "resources/funnelChart/funnelChartTypes"
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  AreaChart,
  Area,
  TooltipProps,
} from "recharts"
import { format } from "date-fns"
import { DATE_FMT } from "sharedConstants"
import styles from "./LineChart.module.scss"

const getMiddleIndex = (data: Array<unknown>) => Math.ceil((data.length - 1) / 2)
const getMiddleItem = <T,>(data: Array<T>) => data[getMiddleIndex(data)]
const getTicks = (data: ChartData[], expanded?: boolean) => {
  if (data.length === 0) return []

  let ticks: ChartsDataResponse["data"] = data
  const numberOfTicks = expanded ? 5 : 3

  if (data.length > numberOfTicks) {
    const firstTick = data[0]
    const lastIndex = data.length - 1
    const lastTick = data[lastIndex]
    const middleIndex = getMiddleIndex(data)
    const middleTick = getMiddleItem(data)

    if (expanded) {
      const firstHalf = data.slice(0, middleIndex)
      const secondHalf = data.slice(middleIndex, lastIndex)

      ticks = [firstTick, getMiddleItem(firstHalf), middleTick, getMiddleItem(secondHalf), lastTick]
    } else {
      ticks = [firstTick, middleTick, lastTick]
    }
  }

  return ticks.map(({ start_date }) => start_date)
}

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<NonNullable<ChartData["value"]>, ChartData["start_date"]>) => {
  if (!active || !payload || payload.length === 0 || !payload[0].value) return null

  return (
    <div className={styles.customTooltip}>
      <p>{format(new Date(label), DATE_FMT.MONTH_DAY)}</p>
      <p>
        <strong>Value:&nbsp;{payload[0].value.toLocaleString()}</strong>
      </p>
    </div>
  )
}

type LineChartProps = {
  data: ChartData[]
  interval: ChartsDataResponse["interval"]
  color?: string
  expanded?: boolean
  id: string
}

export default function LineChart({ data, color, expanded, id }: LineChartProps) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data}>
        <defs>
          <linearGradient id={`areaFill_${id}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.5} />
            <stop offset="95%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <YAxis
          allowDecimals={false}
          width={20}
          tick={{ fontSize: expanded ? 15 : 12 }}
          axisLine={false}
          tickLine={false}
        />
        <XAxis
          dataKey="start_date"
          interval="preserveStartEnd"
          tickFormatter={date => format(new Date(date), DATE_FMT.MONTH_DAY)}
          height={expanded ? 20 : 15}
          padding={expanded ? { left: 20, right: 20 } : { left: 10, right: 10 }}
          tick={{ fontSize: expanded ? 15 : 12 }}
          ticks={getTicks(data, expanded)}
          axisLine={false}
          tickLine={false}
        />
        <Area dot dataKey="value" stroke={color} strokeWidth={2} fill={`url(#areaFill_${id})`} />
        <Tooltip content={<CustomTooltip />} />
        <CartesianGrid vertical={false} strokeOpacity={0.5} />
      </AreaChart>
    </ResponsiveContainer>
  )
}

import Modal from "components/UI/elements/Modal"
import { useCallback, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { PromoCodesList } from "resources/promoCodesList/promoCodesListTypes"
import styles from "./UpdatePromoCodesListModal.module.scss"
import { maxFileSize, required, requiredFile } from "helpers/validators.helper"
import FileField from "components/UI/elements/FileField/FileField"
import Button from "components/UI/elements/Button/Button"
import EditableValue from "components/UI/components/EditableValue/EditableValue"
import { showToast } from "app/toast"
import { TOAST } from "sharedConstants"
import FileUploadDnD from "components/UI/components/FileUploadDnD/FileUploadDnD"
import { MAX_UPLOADED_FILE_SIZE } from "pages/Channels/PromoCodes/PromoCodesLists/PromoCodesLists"

type UpdatePromoCodesListFormValues = {
  file: FileList | null
}

type UpdatePromoCodesListModalProps = {
  open: boolean
  handleClose: () => void
  onFileUpload: (id: PromoCodesList["id"], file: FileList) => void
  onNameChange: (id: PromoCodesList["id"], name: PromoCodesList["name"]) => void
  promoCodesList: PromoCodesList | null
}

export default function UpdatePromoCodesListModal({
  open,
  handleClose,
  onFileUpload,
  promoCodesList,
  onNameChange,
}: UpdatePromoCodesListModalProps) {
  const { control, handleSubmit, reset, setValue } = useForm<UpdatePromoCodesListFormValues>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const closeModal = useCallback(() => {
    handleClose()
    reset()
  }, [handleClose, reset])

  const submitForm: SubmitHandler<UpdatePromoCodesListFormValues> = useCallback(
    async ({ file }) => {
      if (!isSubmitting && promoCodesList && file) {
        setIsSubmitting(true)
        try {
          await onFileUpload(promoCodesList.id, file)
          reset()
          setIsSubmitting(false)
        } catch (err: any) {
          setIsSubmitting(false)
        }
      }
    },
    [isSubmitting, promoCodesList, reset, onFileUpload],
  )

  const submitName = useCallback(
    async (name: string) => {
      if (!isSubmitting && promoCodesList && name) {
        setIsSubmitting(true)
        try {
          await onNameChange(promoCodesList.id, name)
          setIsSubmitting(false)
        } catch {
          setIsSubmitting(false)
        }
      }
    },
    [onNameChange, promoCodesList, isSubmitting],
  )

  const onFileDrop = (files: FileList) => {
    if (files && files[0]) {
      const file = files[0]
      if (file.type !== "text/csv") {
        showToast("Only CSV files are supported.", TOAST.TYPE.ERROR)
      } else {
        setValue("file", files)
      }
    }
  }

  return (
    <Modal open={open} handleClose={closeModal} title="Edit promo codes list" size="large">
      <div className={styles.nameFieldRow}>
        <EditableValue
          initValue={promoCodesList?.name ?? ""}
          onChange={submitName}
          inputClassName={styles.editableNameValueInput}
          validate={required}
        />
      </div>
      <form onSubmit={handleSubmit(submitForm)}>
        <div className={styles.fileFieldRow}>
          <FileUploadDnD onFileDrop={onFileDrop} label="Add more promo codes">
            <div className={styles.fileFieldFlexWrapper}>
              <div>
                <Controller
                  control={control}
                  name="file"
                  rules={{
                    validate: {
                      requiredFile,
                      maxFileSize: maxFileSize(MAX_UPLOADED_FILE_SIZE),
                    },
                  }}
                  render={({ field: { value, ref, onBlur, onChange }, fieldState: { error } }) => (
                    <FileField
                      ref={ref}
                      error={error?.message}
                      accept=".csv"
                      value={value?.[0]?.name}
                      onBlur={onBlur}
                      onChange={event => {
                        const { files } = event.target
                        if (files && files[0]) onChange(files)
                      }}
                      className={styles.fileField}
                    />
                  )}
                />
                <p className={styles.fileFieldDescription}>
                  CSV with 1 column with codes. Max size: 2MB
                </p>
              </div>
              <Button loading={isSubmitting} type="submit" className={styles.uploadButton}>
                Upload
              </Button>
            </div>
          </FileUploadDnD>
        </div>
      </form>
      <p className={styles.availablePromoCodeInfo}>
        <strong>Available promo codes:</strong> {promoCodesList?.promo_codes_count}
      </p>
    </Modal>
  )
}

import { AttributeDataType } from "resources/attribute/attributeTypes"
import { ChartFunction } from "resources/funnelChart/funnelChartTypes"
import { SelectOption } from "types/util"

const allFunctionOptions: Array<SelectOption<ChartFunction>> = [
  { value: "SUM", label: "Sum" },
  { value: "COUNT", label: "Count" },
  { value: "AVERAGE", label: "Average" },
  { value: "CARDINALITY", label: "Unique counts" },
]

const isNumberFunction = (func: ChartFunction) => ["SUM", "AVERAGE", "COUNT"].includes(func)
const isStringFunction = (func: ChartFunction) => ["CARDINALITY", "COUNT"].includes(func)

export const getFunctionOptionsByDataType = (dataType?: AttributeDataType) =>
  dataType === "string"
    ? allFunctionOptions.filter(({ value }) => isStringFunction(value))
    : dataType === "int" || dataType === "float"
    ? allFunctionOptions.filter(({ value }) => isNumberFunction(value))
    : allFunctionOptions

export const isIncorrectFunctionForDataType = (
  func: ChartFunction,
  dataType: AttributeDataType,
) => {
  const isNotStringFunction = dataType === "string" && !isStringFunction(func)
  const isNotNumberFunction = ["int", "float"].includes(dataType) && !isNumberFunction(func)

  return isNotStringFunction || isNotNumberFunction
}

import { useCallback, useEffect, useRef, useState } from "react"
import styles from "./AuthCarousel.module.scss"

type Props = {
  data: { image: string; description: { text: string; title: string; learnMoreHref: string } }[]
}

const isInRange = (min: number, max: number, num: number): boolean => {
  return num >= min && num <= max
}

export const CarouselContent = ({ data }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number | null>(null)
  const navigationDots = Array.from<number, number>({ length: data.length }, (_, i) => i)
  const [touched, setTouched] = useState(false)
  const [indexSelected, setIndexSelected] = useState(0)
  const [descriptionTransition, setdescriptionTransition] = useState(true)
  const [description, setDiscription] = useState<Props["data"][0]["description"]>(
    data[0].description,
  )

  // necessary for the fade in/out animation
  const setDescriptionWithDelay = useCallback(
    (index: number) => {
      setdescriptionTransition(false)
      const timeout = setTimeout(() => {
        setdescriptionTransition(true)
        setDiscription(data[index].description)
      }, 200)

      return () => {
        clearTimeout(timeout)
      }
    },
    [data],
  )

  const pushToIndex = useCallback(
    (index: number) => {
      if (!contentRef.current || !width) {
        return
      }

      contentRef.current?.scrollTo({ left: width * index, behavior: "smooth" })
      setDescriptionWithDelay(index)
      setIndexSelected(index)
    },
    [setDescriptionWithDelay, width],
  )

  const onItemMouseDown: React.MouseEventHandler<HTMLDivElement> = e => {
    setTouched(true)
    const prevScrollLeft = contentRef.current?.scrollLeft || 0
    let scrollSnapRight: boolean | null = null

    const onMouseMove = (event: Event) => {
      const snapTolarence = 50
      const currentEle = contentRef.current
      if (!currentEle) return

      currentEle.scrollLeft -= (event as MouseEvent).movementX

      // sets boolean flag to snap right (true)/left (false) with a tolarence to stay on the same index
      // if user drags just by (snapTolarence) pixels
      if (
        isInRange(
          currentEle.scrollLeft - snapTolarence,
          currentEle.scrollLeft + snapTolarence,
          prevScrollLeft,
        )
      ) {
        scrollSnapRight = null
      } else {
        scrollSnapRight = currentEle.scrollLeft > prevScrollLeft
      }
    }

    const onMouseUp = () => {
      if (!width || !contentRef.current) return
      const { scrollLeft } = contentRef.current

      // calculates index based on the snap direction  and scrolls the element to the center
      if (scrollSnapRight === true) {
        const index = Math.ceil(scrollLeft / width)
        pushToIndex(index)
      } else if (scrollSnapRight !== null) {
        const index = Math.floor(scrollLeft / width)
        pushToIndex(index)
      } else {
        contentRef.current?.scrollTo({ left: width * indexSelected, behavior: "smooth" })
      }

      e.target.removeEventListener("mousemove", onMouseMove)
      document.removeEventListener("mouseup", onMouseUp)
    }

    e.target.addEventListener("mousemove", onMouseMove)
    document.addEventListener("mouseup", onMouseUp)
  }

  const updateWidth = () => {
    if (!contentRef.current) return

    setWidth(contentRef.current.offsetWidth)
  }

  // checks on resize to update the width of the element
  useEffect(() => {
    if (!contentRef.current) return

    updateWidth()

    window.addEventListener("resize", updateWidth)

    return () => {
      window.removeEventListener("resize", updateWidth)
    }
  }, [])

  useEffect(updateWidth, [contentRef.current?.offsetLeft])

  // auto scroll if the user did not touch the carousel
  useEffect(() => {
    if (!width || !contentRef.current) return

    let index = 0

    const interval = window.setInterval(() => {
      index += 1

      if (index === data.length - 1) {
        window.clearInterval(interval)
      }

      pushToIndex(index)
    }, 5000)

    if (touched) {
      window.clearInterval(interval)
    }

    return () => {
      window.clearInterval(interval)
    }
  }, [data.length, pushToIndex, touched, width])

  useEffect(() => {
    if (!contentRef.current) return

    // resets the state of carousel
    contentRef.current.scrollLeft = 0
    setIndexSelected(0)
  }, [])

  return (
    <div className={styles.contentWrapper}>
      <div ref={contentRef} className={styles.imageContent}>
        {data.map(({ image }, i) => (
          <div
            onMouseDown={onItemMouseDown}
            key={i}
            style={{ minWidth: `${width}px` }}
            className={styles.imageContainer}
          >
            <img draggable={false} className={styles.image} src={image} alt="" />
          </div>
        ))}
      </div>
      <div className={styles.descriptionContent}>
        <div
          className={`${styles.description} ${
            descriptionTransition ? "fade-enter-active" : "fade-exit-active"
          }`}
          style={{ minWidth: `${width}px` }}
        >
          <h1>{description.title}</h1>
          <p>{description.text}</p>
          <a href={description.learnMoreHref}>Learn more</a>
        </div>
      </div>
      <div className={styles.dotNavigation}>
        {navigationDots.map(index => (
          <div
            key={index}
            className={`${styles.dotNavigationItem} ${
              index === indexSelected ? styles.dotNavigationItemSelected : ""
            }`}
            onClick={() => {
              pushToIndex(index)
              setTouched(true)
            }}
          ></div>
        ))}
      </div>
    </div>
  )
}

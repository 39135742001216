import Modal from "components/UI/elements/Modal"
import { useCallback, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { PromoCodesList } from "resources/promoCodesList/promoCodesListTypes"
import TextInput from "components/UI/elements/TextInput/TextInput"
import styles from "./CreatePromoCodesListModal.module.scss"
import { maxFileSize, required, requiredFile } from "helpers/validators.helper"
import FileField from "components/UI/elements/FileField/FileField"
import Button from "components/UI/elements/Button/Button"
import FileUploadDnD from "components/UI/components/FileUploadDnD/FileUploadDnD"
import { showToast } from "app/toast"
import { TOAST } from "sharedConstants"
import { MAX_UPLOADED_FILE_SIZE } from "pages/Channels/PromoCodes/PromoCodesLists/PromoCodesLists"

type CreatePromoCodesListFormValues = {
  name: PromoCodesList["name"]
  file: FileList
}

type CreatePromoCodesListModalProps = {
  open: boolean
  handleClose: () => void
  onSubmit: (data: CreatePromoCodesListFormValues) => void
}

export default function CreatePromoCodesListModal({
  open,
  handleClose,
  onSubmit,
}: CreatePromoCodesListModalProps) {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm<CreatePromoCodesListFormValues>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const closeModal = useCallback(() => {
    handleClose()
    reset()
  }, [handleClose, reset])

  const submitForm: SubmitHandler<CreatePromoCodesListFormValues> = useCallback(
    async ({ name, file }) => {
      if (!isSubmitting) {
        setIsSubmitting(true)
        try {
          await onSubmit({ name, file })
          setIsSubmitting(false)
          closeModal()
        } catch (err: any) {
          setIsSubmitting(false)
        }
      }
    },
    [isSubmitting, onSubmit, closeModal],
  )

  const onFileDrop = (files: FileList) => {
    if (files && files[0]) {
      const file = files[0]
      if (file.type !== "text/csv") {
        showToast("Only CSV files are supported.", TOAST.TYPE.ERROR)
      } else {
        setValue("file", files)
      }
    }
  }

  return (
    <Modal open={open} handleClose={closeModal} title="Create promo codes list" size="large">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className={styles.nameFieldRow}>
          <TextInput
            label="Name"
            placeholder="Promo codes list name"
            error={errors.name?.message}
            {...register("name", { validate: required })}
          />
        </div>
        <div className={styles.fileFieldRow}>
          <FileUploadDnD onFileDrop={onFileDrop} label="Add promo codes">
            <div>
              <Controller
                control={control}
                name="file"
                rules={{
                  validate: {
                    requiredFile,
                    maxFileSize: maxFileSize(MAX_UPLOADED_FILE_SIZE),
                  },
                }}
                render={({ field: { value, ref, onBlur, onChange } }) => (
                  <FileField
                    ref={ref}
                    error={errors.file?.message}
                    accept=".csv"
                    value={value?.[0]?.name}
                    onBlur={onBlur}
                    onChange={event => {
                      const { files } = event.target
                      if (files && files[0]) onChange(files)
                    }}
                    className={styles.fileField}
                  />
                )}
              />
              <p className={styles.fileFieldDescription}>
                CSV with 1 column with codes. Max size: 2MB
              </p>
            </div>
          </FileUploadDnD>
        </div>
        <div className={styles.modalButtons}>
          <Button color="grey" size="md" variant="text" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" size="md" loading={isSubmitting}>
            Create
          </Button>
        </div>
      </form>
    </Modal>
  )
}

import { useQuery } from "@tanstack/react-query"
import requestFactory from "api/request"
import { Attribute } from "resources/attribute/attributeTypes"

type CustomersPerAttributeResponse = {
  customers_per_attribute_counts: {
    attribute_id: Attribute["id"]
    count: number
  }[]
}

export function customersPerAttribute(): Promise<CustomersPerAttributeResponse> {
  return requestFactory("get", "/customers_per_attribute_counts")
}

export function useFetchCustomersPerAttribute() {
  return useQuery(["customersPerAttribute"], customersPerAttribute, {
    staleTime: 60 * 1000,
    select: ({ customers_per_attribute_counts }) => customers_per_attribute_counts,
  })
}

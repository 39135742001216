import { getSymbolStyle, getSymbolTree } from "components/ConditionBuilder/treeSymbols"
import { AndOrCondition, ConditionPath, ConditionSymbol, ConditionTree } from "types/conditionTree"
import { isAndOrCondition } from "components/ConditionBuilder/utils"
import React, { Fragment } from "react"
import styles from "./ConditionTreeOverview.module.scss"
import { duplicateCondition, isWithinPath } from "components/ConditionBuilder/treeMethods"
import Tippy from "@tippyjs/react"
import classNames from "classnames"

const Tooltip = ({
  children,
  onClick,
  onTrigger,
  onUntrigger,
  group = false,
  readOnly = false,
}: {
  children: React.ReactElement
  onClick: () => void
  onTrigger: () => void
  onUntrigger: () => void
  group?: boolean
  readOnly?: boolean
}) => (
  <Tippy
    interactive
    interactiveBorder={0}
    placement="bottom"
    content={
      <span className={styles.duplicateButton} onClick={onClick}>
        {group ? "Duplicate group" : "Duplicate"}
      </span>
    }
    onTrigger={onTrigger}
    onUntrigger={onUntrigger}
    disabled={readOnly}
  >
    {children}
  </Tippy>
)

type LeafRendererProps = {
  hoverSelf: (hovered: boolean) => void
  onDuplicate: (path: ConditionPath) => void
  path: ConditionPath
  symbolObject: ConditionSymbol
  highlightedPath?: ConditionPath
  readOnly?: boolean
}

function LeafRenderer({
  highlightedPath,
  hoverSelf,
  onDuplicate,
  path,
  symbolObject: { text, color, negation },
  readOnly = false,
}: LeafRendererProps) {
  return (
    <Tooltip
      onClick={() => onDuplicate(path)}
      onTrigger={() => hoverSelf(true)}
      onUntrigger={() => hoverSelf(false)}
      readOnly={readOnly}
    >
      <div
        className={classNames(styles.symbol, {
          [styles.greyedOut]: highlightedPath ? !isWithinPath(highlightedPath, path) : false,
        })}
        style={getSymbolStyle(color)}
      >
        {negation && <div className={styles.negation}>NOT</div>}
        {text}
      </div>
    </Tooltip>
  )
}

type BranchRendererProps = {
  branch: AndOrCondition<ConditionSymbol>
  onDuplicate: (path: ConditionPath) => void
  onHover: (conditionPath?: ConditionPath) => void
  path: ConditionPath
  highlightedPath?: ConditionPath
  isTopLevel?: boolean
  readOnly?: boolean
}

function BranchRenderer({
  branch: { operator, operands },
  highlightedPath,
  onHover,
  onDuplicate,
  path,
  isTopLevel = false,
  readOnly = false,
}: BranchRendererProps) {
  const itemPaths = operands.map<ConditionPath>((_, i) => [...path, "operands", i])
  const branchHighlighted = isWithinPath(highlightedPath, path)
  const greyedOut = highlightedPath ? !branchHighlighted : false

  return (
    <>
      {!isTopLevel && (
        <Tooltip
          group
          onClick={() => onDuplicate(path)}
          onTrigger={() => onHover(path)}
          onUntrigger={() => onHover()}
          readOnly={readOnly}
        >
          <div className={classNames(styles.parens, { [styles.greyedOut]: greyedOut })}>(</div>
        </Tooltip>
      )}
      {operands.map((operand, index) => (
        <Fragment key={index}>
          {isAndOrCondition(operand) ? (
            <BranchRenderer
              branch={operand}
              highlightedPath={highlightedPath}
              path={itemPaths[index]}
              onDuplicate={onDuplicate}
              onHover={onHover}
              readOnly={readOnly}
            />
          ) : (
            <LeafRenderer
              symbolObject={operand}
              highlightedPath={highlightedPath}
              path={itemPaths[index]}
              hoverSelf={hover => (hover ? onHover(itemPaths[index]) : onHover())}
              onDuplicate={onDuplicate}
              readOnly={readOnly}
            />
          )}
          {index < operands.length - 1 && (
            <Tooltip
              group
              onClick={() => onDuplicate(path)}
              onTrigger={() => onHover(path)}
              onUntrigger={() => onHover()}
              readOnly={readOnly}
            >
              <div className={classNames(styles.operator, { [styles.greyedOut]: greyedOut })}>
                {operator}
              </div>
            </Tooltip>
          )}{" "}
        </Fragment>
      ))}
      {!isTopLevel && (
        <Tooltip
          group
          onClick={() => onDuplicate(path)}
          onTrigger={() => onHover(path)}
          onUntrigger={() => onHover()}
          readOnly={readOnly}
        >
          <div className={classNames(styles.parens, { [styles.greyedOut]: greyedOut })}>)</div>
        </Tooltip>
      )}
    </>
  )
}

type ConditionTreeOverviewProps<T> = {
  conditionTree: ConditionTree<T> | null
  onChange: (conditionTree: ConditionTree<T> | null) => void
  onHover: (conditionPath?: ConditionPath) => void
  highlightedPath?: ConditionPath
  readOnly?: boolean
}

export default function ConditionTreeOverview<T>({
  conditionTree,
  highlightedPath,
  onChange,
  onHover,
  readOnly = false,
}: ConditionTreeOverviewProps<T>) {
  if (!conditionTree) {
    return null
  }

  const onDuplicate = (path: ConditionPath) => onChange(duplicateCondition(path, conditionTree))

  const symbolTree = getSymbolTree(conditionTree)

  return (
    <div className={styles.container}>
      {isAndOrCondition(symbolTree) ? (
        <BranchRenderer
          isTopLevel
          branch={symbolTree}
          highlightedPath={highlightedPath}
          path={[]}
          onDuplicate={onDuplicate}
          onHover={onHover}
          readOnly={readOnly}
        />
      ) : (
        <LeafRenderer
          highlightedPath={highlightedPath}
          path={[]}
          symbolObject={symbolTree}
          hoverSelf={hovered => (hovered ? onHover([]) : onHover())}
          onDuplicate={onDuplicate}
          readOnly={readOnly}
        />
      )}
    </div>
  )
}

import React from "react"
import classNames from "classnames"
import styles from "./Switch.module.scss"

export type SwitchProps = {
  leftValue: string
  rightValue: string
  checked: string
  onToggle: () => void
  className?: string
  disabled?: boolean
  name?: string
  leftLabel?: string
  rightLabel?: string
  size?: "xs" | "sm" | "md"
  width?: number
}

export default function Switch({
  checked,
  disabled,
  className,
  leftLabel,
  leftValue,
  name,
  rightValue,
  rightLabel,
  onToggle,
  size = "md",
  width = 120,
}: SwitchProps) {
  return (
    <div
      className={classNames(styles.root, { [styles.disabled]: disabled }, styles[size], className)}
      style={{ width: `${width}px` }}
      data-testid={`toggle-switch-${name}`}
    >
      <p
        onClick={disabled ? undefined : onToggle}
        className={styles.fieldset}
        data-testid="fieldset"
      >
        <input
          type="radio"
          name={name}
          value={leftValue}
          id={`${leftValue}-${name}`}
          checked={checked === leftValue}
          readOnly
          disabled
          data-testid="left-input"
        />
        <label
          htmlFor={`${leftValue}-${name}`}
          className={classNames(styles.label, styles.bottom, styles.left, {
            [styles.hidden]: checked === leftValue,
          })}
        >
          {leftLabel ? leftLabel : leftValue}
        </label>
        <input
          type="radio"
          name={name}
          value={rightValue}
          id={`${rightValue}-${name}`}
          checked={checked === rightValue}
          readOnly
          disabled
          data-testid="right-input"
        />
        <label
          htmlFor={`${rightValue}-${name}`}
          className={classNames(styles.label, styles.bottom, styles.right, {
            [styles.hidden]: checked === rightValue,
          })}
        >
          {rightLabel ? rightLabel : rightValue}
        </label>
        <span className={styles.switch} />
        <label
          htmlFor={`${leftValue}-${name}`}
          className={classNames(styles.label, styles.front, styles.left, {
            [styles.hidden]: checked === rightValue,
          })}
        >
          {leftLabel ? leftLabel : leftValue}
        </label>
        <label
          htmlFor={`${rightValue}-${name}`}
          className={classNames(styles.label, styles.front, styles.right, {
            [styles.hidden]: checked === leftValue,
          })}
        >
          {rightLabel ? rightLabel : rightValue}
        </label>
      </p>
    </div>
  )
}

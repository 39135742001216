import React, { PureComponent } from "react"
import { Link, Redirect } from "react-router-dom"

import { showToast } from "app/toast"
import { getRoutePath } from "routes"

import { REDIRECT_AFTER_OKTA_LOGIN_LS_KEY, TOAST } from "sharedConstants"

import WholePageLoading from "./WholePageLoading/WholePageLoading"
import { oidcLogin, useAuthTokenStore } from "auth/auth"
import useLocalStorage from "hooks/useLocalStorage"

class SsoCallback extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
    }
  }

  componentDidMount() {
    const { location, history, redirectAfterOktaLogin } = this.props

    const urlParams = new URLSearchParams(location.search)
    const code = urlParams.get("code")
    const state = urlParams.get("state")

    if (redirectAfterOktaLogin) {
      history.replace({
        search: `${location.search}&${new URLSearchParams({
          redirect: redirectAfterOktaLogin,
        })}`,
      })
    }
    if (code && state) {
      oidcLogin({ code, state })
        .then(() => {
          try {
            if (window.localStorage.getItem("cdpGeneralTermsAccepted") !== "true") {
              window.localStorage.setItem("cdpGeneralTermsAccepted", "true")
            }
          } catch (err) {}
        })
        .catch(error => {
          this.setState({
            error: true,
          })
        })
    } else {
      const error_description = urlParams.get("error_description")
      this.setState({
        error: true,
      })
      showToast(error_description, TOAST.TYPE.ERROR)
    }
  }

  render() {
    if (this.props.authToken) {
      return (
        <Redirect
          to={new URLSearchParams(window.location.search).get("redirect") ?? getRoutePath("home")}
        />
      )
    }

    return (
      <WholePageLoading
        customMessage="Logging in..."
        loadingError={this.state.error}
        customErrorMessage="Error while logging in."
        fullHeight
      >
        {this.state.error && (
          <Link to={getRoutePath("login")} className="back-to-login">
            Back to login
          </Link>
        )}
      </WholePageLoading>
    )
  }
}

export default props => {
  const { authToken } = useAuthTokenStore()
  const [redirectAfterOktaLogin] = useLocalStorage(REDIRECT_AFTER_OKTA_LOGIN_LS_KEY, null)

  return (
    <SsoCallback {...props} authToken={authToken} redirectAfterOktaLogin={redirectAfterOktaLogin} />
  )
}

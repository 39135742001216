import React, { ComponentPropsWithRef, forwardRef } from "react"
import classNames from "classnames"

import ErrorTippy from "../ErrorTippy/ErrorTippy"

import styles from "./TextArea.module.scss"

export type TextAreaProps = {
  error?: string
  label?: string
  rows?: number
} & ComponentPropsWithRef<"textarea">

const TextArea = forwardRef(function TextArea(
  { className, error, id, label, name, rows, ...props }: TextAreaProps,
  ref: React.Ref<HTMLTextAreaElement>,
) {
  const textAreaId = id ?? name

  return (
    <div
      className={classNames(styles.wrapper, className, {
        [styles.hasError]: !!error,
      })}
      data-testid={name ? `text-area-${name}` : "text-area"}
    >
      {label && (
        <label htmlFor={textAreaId} className={styles.label}>
          {label}
        </label>
      )}
      <ErrorTippy disabled={!error} content={error}>
        <textarea id={textAreaId} name={name} ref={ref} rows={rows} {...props} />
      </ErrorTippy>
    </div>
  )
})

export default TextArea

import React, { useState } from "react"
import { getRoutePath } from "routes"
import Button from "components/UI/elements/Button/Button"
import Paper from "components/UI/elements/Paper"
import DestinationForm from "../components/DestinationForm/DestinationForm"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"
import "./DestinationDetail.scss"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import {
  useDeleteDestination,
  useFetchDestinationById,
  useModifyDestination,
} from "resources/exportDestination/exportDestinationQueries"
import { useHistory, useParams } from "react-router-dom"
import { useFetchWorkspaceOptions } from "resources/workspace/workspaceQueries"
import { SegmentExportDestinationModifyPayload } from "resources/exportDestination/exportDestinationTypes"

export default function DestinationDetail() {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { data: destination, isSuccess: areDestinationsFulfilled } = useFetchDestinationById(+id)
  const modifyMutation = useModifyDestination()
  const deleteMutation = useDeleteDestination()
  const { data: workspaces = [] } = useFetchWorkspaceOptions()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const modifyDestination = (data: SegmentExportDestinationModifyPayload) =>
    modifyMutation.mutate(
      { id: +id, data },
      {
        onSuccess() {
          history.push(getRoutePath("administration.destinations"))
        },
      },
    )
  const deleteDestination = () =>
    deleteMutation.mutate(
      { id: +id },
      {
        onSuccess() {
          history.push(getRoutePath("administration.destinations"))
        },
      },
    )

  const formInitValues: any = {}
  if (destination) {
    formInitValues.id = destination.id
    formInitValues.name = destination.name
    formInitValues.description = destination.description
    formInitValues.attribute_ids = destination.attribute_ids ?? null
    formInitValues.mandatory_attribute_ids = destination.mandatory_attribute_ids ?? null
    formInitValues.icon = destination.icon ? destination.icon.replace(/\.[^/.]+$/, "") : null
    formInitValues.settings = {
      mandatory_attributes_operator: destination.settings?.mandatory_attributes_operator ?? "or",
    }
    formInitValues.registration_token_attribute_id =
      destination.settings?.registration_token_attribute_id ?? null
    formInitValues.color = destination.frontend_settings?.color ?? null

    if (destination.mi_workspace_id) {
      formInitValues.mi_workspace = workspaces.find(
        item => item.value === destination.mi_workspace_id,
      )
      if (!formInitValues.mi_workspace) {
        formInitValues.mi_workspace = {
          value: destination.mi_workspace_id,
          label: `${destination.mi_workspace_id}`,
        }
      }
    }

    const parameters = destination.settings?.mi_workspace_variables
    if (parameters) {
      formInitValues.settings.mi_workspace_variables = parameters.map((param: any) => ({
        ...param,
        input_mode: {
          user_input: param.input_mode.includes("user_input"),
          value_from_list: param.input_mode.includes("value_from_list"),
        },
      }))
    }
  }

  const isReady = areDestinationsFulfilled

  return (
    <Page
      className="detail-destination"
      title="Edit destination"
      headerContent={
        <div className="header-buttons">
          <Button
            color="red"
            icon="trash-alt"
            variant="outlined"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            Delete
          </Button>
          <Button
            color="grey"
            variant="outlined"
            onClick={() => {
              history.push(getRoutePath("administration.destinations"))
            }}
          >
            Cancel
          </Button>
          <Button loading={modifyMutation.isLoading} form="destinationForm" type="submit">
            Save
          </Button>
        </div>
      }
    >
      {!isReady && <LoadingIndicator />}
      {isReady && (
        <Paper className="detail-destination-content">
          <DestinationForm
            onSubmit={modifyDestination}
            initialValues={formInitValues}
            disabledFields={["id"]}
          />
        </Paper>
      )}
      <ConfirmModal
        open={isDeleteModalOpen}
        type={MODAL.TYPE.DELETE}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={deleteDestination}
        title="Are you sure?"
        action="delete"
        what="destination"
        item={destination?.name ?? ""}
        isLoading={deleteMutation.isLoading}
      />
    </Page>
  )
}

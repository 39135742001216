import styles from "./AttributePickerButton.module.scss"
import { AttributeDropdown } from "components/AttributePicker/AttributePicker"
import React, { useRef, useState } from "react"
import classNames from "classnames"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import useClickOutHandler from "hooks/useClickOutHandler"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useKeyListener from "hooks/useKeyListener"

type AttributePickerButtonProps = {
  onChange: (value: string) => void
  isError?: boolean
  className?: string
  isInTextArea?: boolean
  autoFocus?: boolean
}

export default function AttributePickerButton({
  onChange,
  isError,
  className,
  isInTextArea,
  autoFocus = true,
}: AttributePickerButtonProps) {
  const { isLoading } = useFetchAttributesMap()
  const { isOpen, open, close, triggerRef, ref, toggle } = useClickOutHandler<HTMLDivElement>({
    closeCallback() {
      setSearchTerm("")
    },
  })
  const [searchTerm, setSearchTerm] = useState("")
  const placeholder = "Search attributes…"

  const inputRef = useRef<HTMLInputElement>(null)

  useKeyListener("Escape", _ => {
    if (inputRef.current?.matches(":focus")) {
      close()
      inputRef.current?.blur()
    }
  })

  return (
    <div data-testid="attribute-picker" className={classNames(styles.container, className)}>
      <div
        ref={triggerRef}
        className={classNames(styles.inputWrapper, {
          [styles.error]: isError,
          [styles.open]: isOpen,
          [styles.inTextArea]: isInTextArea,
        })}
        onClick={_ => {
          if (!isLoading) {
            inputRef.current?.focus()
            open()
          }
        }}
      >
        {isLoading && <LoadingIndicator size="sm" />}

        {!isLoading && (
          <>
            <div className={styles.icons}>
              <FontAwesomeIcon className={styles.bracketsIcon} icon={["fas", "brackets-curly"]} />
              <FontAwesomeIcon className={styles.ellipsisIcon} icon={["far", "ellipsis-h"]} />
            </div>
            <input
              placeholder={placeholder}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              ref={inputRef}
              className={styles.input}
              autoComplete="off"
              type="text"
              autoFocus={autoFocus}
            />

            {
              <div
                className={styles.caretWrapper}
                onClick={e => {
                  e.stopPropagation()
                  toggle()
                }}
              >
                <FontAwesomeIcon
                  icon={["fas", "caret-down"]}
                  flip={isOpen ? "vertical" : undefined}
                />
              </div>
            }
          </>
        )}
      </div>

      {isOpen && !isLoading && (
        <AttributeDropdown
          ref={ref}
          onChange={id => {
            onChange(id)
            close()
          }}
          searchTerm={searchTerm}
          withDimensions
          uniqueOnly
          rightAlign
        />
      )}
    </div>
  )
}

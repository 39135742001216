import React from "react"
import { Route, Switch } from "react-router-dom"

import { getRoutePath } from "routes"
import MetaAttributesCreate from "./MetaAttributesCreate/MetaAttributesCreate"
import MetaAttributesDetail from "./MetaAttributesDetail/MetaAttributesDetail"
import MetaAttributesList from "./MetaAttributesList/MetaAttributesList"

export default function MetaAttributesSetup() {
  return (
    <Switch>
      <Route
        path={getRoutePath("administration.metaAttributes")}
        exact
        component={MetaAttributesList}
      />
      <Route
        path={getRoutePath("administration.metaAttributes.create")}
        component={MetaAttributesCreate}
      />
      <Route
        path={getRoutePath("administration.metaAttributes.detail")}
        exact
        component={MetaAttributesDetail}
      />
    </Switch>
  )
}

import React, { useState } from "react"
import styles from "./RoleForm.module.scss"
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { maxLength, required } from "helpers/validators.helper"
import { Feature, UserRoleCreatePayload } from "resources/userRole/userRoleTypes"
import Paper from "components/UI/elements/Paper"
import { prop, whereEq } from "ramda"
import SystemBadge from "components/UI/elements/SystemBadge/SystemBadge"
import { FeatureItem, featuresSections, RoleFormSection } from "resources/userRole/features"
import Button from "components/UI/elements/Button/Button"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useFetchChannels } from "resources/channel/channelQueries"
import { useFetchAllLabels } from "resources/attributeLabel/attributeLabelQueries"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import TagPicker from "components/UI/components/TagPicker"
import { Label } from "resources/attributeLabel/attributeLabelTypes"
import Tag from "components/UI/elements/Tag"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { EMAILS_CHANNEL_NAME, PUSH_NOTIFICATIONS_CHANNEL_NAME } from "sharedConstants"

const dependencies: Feature[][] = [
  // For the first element to be on, the second element must be on

  // WATCH OUT: if you want to put in "segments/export", that might not work because there is a
  // special case relationship between "segments/export" and its subfeatures

  // segments
  ["foreign_segments/view", "foreign_segments/list"],
  ["foreign_segments/edit", "foreign_segments/view"],

  ["featured_segments/view", "featured_segments/list"],
  ["featured_segments/edit", "featured_segments/view"],

  // tags
  ["settings/tags", "data/tags"],

  // reports
  ["reports/edit", "reports/view"],

  // web banners
  ["web_banners/view", "featured_segments/list"],
  ["web_banners/view", "featured_segments/view"],
  ["web_banners/view", "foreign_segments/list"],
  ["web_banners/view", "foreign_segments/view"],

  ["web_banners/edit", "web_banners/view"],

  // push notifications
  ["push_notifications/view", "featured_segments/list"],
  ["push_notifications/view", "featured_segments/view"],
  ["push_notifications/view", "foreign_segments/list"],
  ["push_notifications/view", "foreign_segments/view"],

  ["push_notifications/edit", "push_notifications/view"],

  // emails
  ["emails/view", "featured_segments/list"],
  ["emails/view", "featured_segments/view"],
  ["emails/view", "foreign_segments/list"],
  ["emails/view", "foreign_segments/view"],

  ["emails/edit", "emails/view"],

  // funnels
  ["funnels/*/edit", "funnels/*/view"],
]

function payloadFromFormValues(values: RoleFormValues): UserRoleCreatePayload {
  return {
    name: values.name,
    features: Object.entries(values.features)
      .filter(([_, value]) => value)
      .map(([feature]) => feature) as Feature[],
    invisible_attributes_tag_ids: values.labelIds.map(prop("labelId")),
  }
}

type RoleFormValues = {
  name: string
  features: Record<Feature, boolean>
  labelIds: Array<{ labelId: Label["id"] }>
}

type RoleFormProps = {
  initialValues?: RoleFormValues
  onSubmit: (data: UserRoleCreatePayload) => void
  isAdmin?: boolean
  isOwn?: boolean
}

export default function RoleForm({
  initialValues,
  onSubmit,
  isAdmin = false,
  isOwn = false,
}: RoleFormProps) {
  const { isLoading: isLoadingChannels } = useFetchChannels()
  const { isLoading: isLoadingDestinations } = useFetchAllDestinations()
  const { data: labels = [], isLoading: isLoadingLabels } = useFetchAllLabels()
  const methods = useForm<RoleFormValues>({
    defaultValues: initialValues,
  })
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = methods
  const { append, remove } = useFieldArray({ control, name: "labelIds" })

  const [confirmModal, setConfirmModal] = useState<{
    isOpen: boolean
    values: RoleFormValues | null
  }>({ isOpen: false, values: null })

  if (isLoadingDestinations || isLoadingChannels || isLoadingLabels) {
    return <LoadingIndicator />
  }

  return (
    <form
      id="roleForm"
      onSubmit={handleSubmit(values => {
        if (isOwn) {
          setConfirmModal({ isOpen: true, values })
        } else {
          onSubmit(payloadFromFormValues(values))
        }
      })}
    >
      <ConfirmModal
        open={confirmModal.isOpen}
        type="success"
        handleClose={() => setConfirmModal({ isOpen: false, values: null })}
        handleConfirm={() => {
          setConfirmModal({ isOpen: false, values: null })
          onSubmit(payloadFromFormValues(confirmModal.values!))
        }}
        title="Are you sure?"
        text="Warning. You are about to edit your own user role."
      />
      <Paper noPadding>
        <div className={styles.generalSection}>
          <div className={styles.description}>
            <h2>General {isAdmin && <SystemBadge />}</h2>
          </div>
          <div className={styles.content}>
            <TextInput
              label="Name"
              {...register("name", { validate: { required, maxLength: maxLength(50) } })}
              error={errors.name?.message}
              disabled={isAdmin}
            />
          </div>
        </div>
        <div className={styles.permissionsSection}>
          <div className={styles.description}>
            <h2>Permissions</h2>
          </div>
          <div className={styles.content}>
            <FormProvider {...methods}>
              <div>
                {featuresSections.slice(0, 3).map(section => (
                  <FeaturesSection key={section.title} section={section} isAdmin={isAdmin} />
                ))}
              </div>
              <div>
                {featuresSections.slice(3).map(section => (
                  <FeaturesSection key={section.title} section={section} isAdmin={isAdmin} />
                ))}
              </div>
            </FormProvider>
          </div>
        </div>
        <div className={styles.attributesSection}>
          <div className={styles.description}>
            <h2>Attribute visibility protection</h2>
            <p>Specify attribute labels for which this user role won't see values.</p>
          </div>
          <div className={styles.content}>
            <Controller
              control={control}
              name="labelIds"
              render={({ field: { value } }) => (
                <>
                  {value?.map(({ labelId }, index) => {
                    const label = labels.find(whereEq({ id: labelId }))

                    return (
                      <Tag key={labelId} color="primary" clickable onClick={() => remove(index)}>
                        {label?.name ?? labelId}
                      </Tag>
                    )
                  })}

                  <TagPicker
                    selectedTagIds={value?.map(prop("labelId")) ?? []}
                    allTags={labels}
                    onTagSelect={(labelId: Label["id"]) => append({ labelId })}
                    dropdownAlign="left"
                    type="label"
                    disabled={isAdmin}
                  />
                </>
              )}
            />
          </div>
        </div>
      </Paper>
    </form>
  )
}

function FeaturesSection({ section, isAdmin }: { section: RoleFormSection; isAdmin: boolean }) {
  const { setValue, getValues } = useFormContext<RoleFormValues>()
  const { data: destinations = [] } = useFetchAllDestinations()

  function setFeature(feature: Feature, _value?: boolean) {
    const formValues = getValues()
    const value = _value ?? !formValues.features[feature]

    setValue(`features.${feature}`, value)

    if (value) {
      dependencies
        .filter(([dependent]) => dependent === feature)
        .forEach(([_, dependency]) => {
          setFeature(dependency, true)
        })
    }

    if (!value) {
      dependencies
        .filter(([_, dependency]) => dependency === feature)
        .forEach(([dependent]) => {
          setFeature(dependent, false)
        })
    }

    if (feature === "segments/export") {
      destinations.forEach(({ id }) => {
        setFeature(`segments/export/${id}`, value)
      })
    }

    if (feature.startsWith("segments/export/")) {
      if (!value) {
        setValue("features.segments/export", false)
      }

      if (value) {
        const areAllOtherExportsOn = Object.entries(formValues.features)
          .filter(
            ([otherFeature]) =>
              otherFeature.startsWith("segments/export/") && otherFeature !== feature,
          )
          .every(([_, value]) => value)

        if (areAllOtherExportsOn) {
          setValue("features.segments/export", true)
        }
      }
    }
  }

  function setAll(value: boolean) {
    section.items.forEach(({ feature }) => {
      setFeature(feature, value)
    })
  }

  return (
    <div className={styles.featuresSection}>
      <div className={styles.header}>
        <h3>{section.title}</h3>
        {!isAdmin && (
          <div className={styles.sectionButtons}>
            <Button data-testid="disable-all" variant="link" onClick={_ => setAll(false)}>
              Disable all
            </Button>
            |
            <Button data-testid="enable-all" variant="link" onClick={_ => setAll(true)}>
              Enable all
            </Button>
          </div>
        )}
      </div>
      <div className={styles.features}>
        {section.items.map(item => (
          <FeatureRow key={item.feature} item={item} setFeature={setFeature} isAdmin={isAdmin} />
        ))}
      </div>
    </div>
  )
}

function FeatureRow({
  item: { feature, label, tooltip },
  setFeature,
  isAdmin = false,
}: {
  item: FeatureItem
  setFeature: (feature: Feature, value?: boolean) => void
  isAdmin?: boolean
}) {
  const { control } = useFormContext<RoleFormValues>()
  const { data: destinations = [] } = useFetchAllDestinations()
  const { data: channels = [] } = useFetchChannels()

  const areEmailsActive = channels?.find(whereEq({ name: EMAILS_CHANNEL_NAME }))?.is_active
  const areMobilePushesActive = channels?.find(
    whereEq({ name: PUSH_NOTIFICATIONS_CHANNEL_NAME }),
  )?.is_active

  if (
    (feature.startsWith("emails/") && !areEmailsActive) ||
    (feature.startsWith("push_notifications/") && !areMobilePushesActive)
  ) {
    return null
  }

  return (
    <div data-testid="role-row" className={styles.row}>
      <div className={styles.main}>
        <label>
          {label} <InfoTooltip className={styles.info}>{tooltip}</InfoTooltip>
        </label>
        <Controller
          control={control}
          name={`features.${feature}`}
          render={({ field }) => (
            <ToggleButton
              value={isAdmin || field.value}
              handleToggle={() => setFeature(feature)}
              size="sm"
              disabled={isAdmin}
            />
          )}
        />
      </div>

      {feature === "segments/export" && destinations.length > 0 && (
        <div className={styles.exports}>
          {destinations.map(destination => {
            return (
              <div className={styles.export} key={destination.id}>
                <label>– {destination.name}</label>
                <Controller
                  control={control}
                  name={`features.${feature}/${destination.id}`}
                  render={({ field }) => (
                    <ToggleButton
                      value={isAdmin || field.value}
                      handleToggle={() => setFeature(`${feature}/${destination.id}`)}
                      size="sm"
                      disabled={isAdmin}
                    />
                  )}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

import React, { useMemo, useRef, useState } from "react"
import classNames from "classnames"
import SimpleBar from "simplebar-react"

import Button from "components/UI/elements/Button/Button"
import Modal from "components/UI/elements/Modal"
import { MobilePushNotification } from "resources/mobilePushNotification/mobilePushNotificationTypes"
import { MODAL_SIMPLE_BAR_HEIGHT } from "sharedConstants"

import styles from "./CustomDataSchemaModal.module.scss"

type CustomDataSchemaModalProps = {
  affectedPushNotifications: Array<Pick<MobilePushNotification, "id" | "name">>
  isLoading: boolean
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

export default function CustomDataSchemaModal({
  affectedPushNotifications,
  isLoading,
  open,
  onClose,
  onSubmit,
}: CustomDataSchemaModalProps) {
  const [isBottom, setIsBottom] = useState(false)

  const scrollableRef = useRef<HTMLElement>(null)
  const { current } = scrollableRef

  const isScrollVisible = useMemo(() => {
    if (!current) return false
    return current.clientHeight === MODAL_SIMPLE_BAR_HEIGHT
  }, [current])

  return (
    <Modal open={open} title="Custom data schema" handleClose={onClose}>
      <p className={styles.text}>
        You've setup custom data schema that makes following mobile push notifications invalid:
      </p>
      <SimpleBar
        scrollableNodeProps={{ ref: scrollableRef }}
        onScrollCapture={() => {
          if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current
            if (Math.round(scrollTop + clientHeight) === scrollHeight) setIsBottom(true)
            else setIsBottom(false)
          }
        }}
        className={classNames(styles.bar, { [styles.notBottom]: !isBottom && isScrollVisible })}
        style={{ maxHeight: `${MODAL_SIMPLE_BAR_HEIGHT}px` }}
      >
        <div className={styles.items}>
          {affectedPushNotifications.map(campaign => (
            <span key={campaign.id} className={styles.name}>
              {campaign.name}
            </span>
          ))}
        </div>
      </SimpleBar>
      <div className={styles.buttons}>
        <Button color="grey" size="md" variant="text" onClick={_ => onClose()}>
          Cancel
        </Button>
        <Button loading={isLoading} color="green" size="md" onClick={_ => onSubmit()}>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

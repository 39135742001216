import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./IconsRadioGroup.scss"
import Tippy from "@tippyjs/react"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import classNames from "classnames"

class IconsRadioGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isHovering: null,
    }
  }

  handleMouseHover = icon => () => {
    if (this.props.disabled) {
      return
    }
    this.setState({
      isHovering: icon,
    })
  }

  render() {
    const {
      input,
      meta: { touched, error },
      icons,
      disabled,
    } = this.props
    const { isHovering } = this.state
    const errorMessage = touched ? error : ""
    return (
      <div className="icons-wrapper">
        <label>Icon</label>
        <div
          className={classNames("icons-options-group", {
            active: isHovering || input.value,
            disabled,
          })}
        >
          {icons.map(icon => (
            <React.Fragment key={icon.icon}>
              <input
                {...input}
                type="radio"
                id={icon.icon}
                value={icon.icon}
                className="icon-opt"
                checked={icon.icon === input.value}
                disabled={disabled}
              />
              <label
                htmlFor={icon.icon}
                onMouseEnter={this.handleMouseHover(icon.icon)}
                onMouseLeave={this.handleMouseHover()}
                className={isHovering === icon.icon || input.value === icon.icon ? "marked" : ""}
              >
                <Tippy content={icon.name} delay={[200, null]} disabled={icon.name === undefined}>
                  <div className="icon-container">
                    <SrcDstIcon iconName={icon.icon} />
                  </div>
                </Tippy>

                {input.value === icon.icon && (
                  <span>
                    <FontAwesomeIcon icon={["far", "check"]} className="check-icon" />
                  </span>
                )}
              </label>
            </React.Fragment>
          ))}
        </div>
        {errorMessage && <p className="error-message">Pick one of the icons please.</p>}
      </div>
    )
  }
}

IconsRadioGroup.propTypes = {
  icons: PropTypes.array.isRequired,
}

export default IconsRadioGroup

import React, { PureComponent } from "react"
import PropTypes from "prop-types"

// ui components
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"

// constants, helpers
import { DATE_FMT } from "sharedConstants"
import { getCustomerEventTitle } from "helpers/event.helper"

import "./CustomerEvent.scss"
import classNames from "classnames"
import StructuredEventPayload from "components/UI/elements/StructuredEventPayload/StructuredEventPayload"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import { format } from "date-fns"

const TOGGLE_HEIGHT_TRESHOLD = 100

class CustomerEvent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      expand: false,
      height: null,
      headingHeight: null,
      showRawPayload: false,
    }
  }

  componentDidMount() {
    const height = this.customerEventHeight.clientHeight
    const headingHeight = this.headingHeight.clientHeight
    const expand = height <= TOGGLE_HEIGHT_TRESHOLD
    this.setState({ height, headingHeight, expand })
  }

  toggleShowMore = () => () => {
    let { expand } = this.state

    expand = !expand
    this.setState({ expand })
  }

  render() {
    const { customerEvent, event, dataSource, hasTimeline = true } = this.props
    const { expand, height, headingHeight, showRawPayload } = this.state

    const title = getCustomerEventTitle(customerEvent, event.schema, event.name)
    const color = dataSource.frontend_settings?.color

    return (
      <Paper
        className={classNames("event-box", color, {
          "bottom-pad": height <= TOGGLE_HEIGHT_TRESHOLD,
          "has-timeline": hasTimeline,
        })}
      >
        <div className="event-source-icon">
          <SrcDstIcon source={dataSource} white />
        </div>
        <div
          className={`event-content ${expand ? "" : "collapse"}`}
          ref={customerEventHeight => (this.customerEventHeight = customerEventHeight)}
        >
          <div className="header" ref={headingHeight => (this.headingHeight = headingHeight)}>
            <h4>{title}</h4>
            <Button
              onClick={() =>
                this.setState(({ showRawPayload }) => ({
                  showRawPayload: !showRawPayload,
                  expand: true,
                }))
              }
              // className="payload-toggle-button"
              variant="outlined"
              color="grey"
              size="xs"
            >
              {showRawPayload ? "Show structured data" : "Show raw payload"}
            </Button>
            <p className="datetime">
              {format(new Date(customerEvent.event_time), DATE_FMT.DATETIME_PRECISE)}
            </p>
          </div>
          <div className="payload">
            {showRawPayload ? (
              <div className="raw-payload">
                <pre>{JSON.stringify(customerEvent.payload, null, 2)}</pre>
              </div>
            ) : (
              <StructuredEventPayload customerEvent={customerEvent} schema={event.schema} />
            )}
            <div
              className={`payload-overlay ${expand === true ? "" : "collapse"} ${
                headingHeight < 45 ? "big" : "small"
              }`}
            />
          </div>
        </div>
        {height > TOGGLE_HEIGHT_TRESHOLD && (
          <div className={`show-more-less-btn ${expand ? "expanded" : ""}`}>
            <Button
              color="grey"
              variant="link"
              onClick={this.toggleShowMore()}
              className={`show ${expand ? "" : "collapse"}`}
            >
              {expand ? "Show Less" : "Show More"}
            </Button>
          </div>
        )}
      </Paper>
    )
  }
}

CustomerEvent.propTypes = {
  customerEvent: PropTypes.object.isRequired,
  dataSource: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  hasTimeline: PropTypes.bool,
}

export default CustomerEvent

import { TrashItemType } from "types/trash"

const trashItemLabels: Record<TrashItemType, string> = {
  embedded_web_banners: "Native banner",
  popup_web_banners: "Pop-up banner",
  custom_segments: "Custom segment",
  featured_segments: "Featured segment",
  smart_segments: "Smart segment",
  lookalike_segments: "Lookalike segment",
  segment_export_destinations: "Export destination",
  users: "User",
  user_roles: "User role",
  push_notifications: "Mobile Push",
  emails: "Email",
  funnel_groups: "Funnel group",
  journeys: "Journey",
  charts: "Chart",
}

export default function getReadableItemType(
  type: TrashItemType,
  { capitalize = true, plural = false } = {},
) {
  let result = trashItemLabels[type]
  if (!capitalize) result = result.toLowerCase()
  if (plural) result = type === "push_notifications" ? `${result}es` : `${result}s`
  return result
}

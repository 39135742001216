import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "resources/attribute/compoundAttributeUtils"
import { clone, isEmpty } from "ramda"

const definitionDefaults = {
  sources: [],
  types: [],
  versions: [],
  weight: "",
  value: "",
  outer_value: "",
  filter: "",
  outer_filter: "",
}

export const getInitialAttributeFormValues = attribute => {
  if (!attribute) {
    return {
      is_unique: "1",
      tag_ids: [],
      definition: definitionDefaults,

      _isDefinitionShown: true,
      _definitionType: "predefined",
      _dimensions: [],
    }
  }

  // In some cases attribute definition can come from the BE as `{}`; in that case migrate to null
  if (isEmpty(attribute.definition)) attribute.definition = null

  const formInitValues = {
    id: attribute.id,
    name: attribute.name,
    source_id: attribute.source.id,
    description: attribute.description,
    is_unique: attribute.is_unique,
    definition: attribute.definition ? clone(attribute.definition) : definitionDefaults,

    _isDefinitionShown: attribute.definition !== null,
  }

  if (isAttributeCompound(attribute.data_type)) {
    formInitValues._dimensions = getCompoundAttributeSubAttributes(attribute.data_type)
    formInitValues.data_type = "compound"
  } else {
    formInitValues._dimensions = []
    formInitValues.data_type = attribute.data_type
  }

  if (Array.isArray(attribute.tags)) {
    formInitValues.tag_ids = attribute.tags.map(({ id }) => ({ id }))
  } else {
    formInitValues.tag_ids = []
  }

  formInitValues._definitionType = attribute.definition?.type === "custom" ? "custom" : "predefined"

  return formInitValues
}

import requestFactory from "api/request"
import { Flag, OrderDir } from "types/util"
import {
  CustomerAttributeRetrieveResponse,
  CustomerAttributesListPayload,
  CustomerAttributeListResponse,
  CustomerAttributeRetrievePaginatedPayload,
} from "./customerAttributeTypes"
import { Attribute } from "resources/attribute/attributeTypes"
import {
  CustomerAttributeValueCount,
  CustomerAttributeValueCountResponse,
} from "types/customerAttributes"

const customerAttribute = {
  list<T extends Flag = 0>({
    customer_entity_id,
    attribute_values_max_count = 10,
    load_full_structure = 0 as T,
  }: CustomerAttributesListPayload): Promise<CustomerAttributeListResponse<T>> {
    return requestFactory(
      "get",
      `/customers/${customer_entity_id}/attributes`,
      {
        load_full_structure,
        attribute_values_max_count,
      },
      false,
      true,
      "",
      false,
      true,
    )
  },
  retrieve({
    attribute_id,
    customer_entity_id,
    limit = 10,
    offset = 0,
  }: CustomerAttributeRetrievePaginatedPayload): Promise<CustomerAttributeRetrieveResponse> {
    return requestFactory("get", `/customers/${customer_entity_id}/attributes/${attribute_id}`, {
      offset,
      limit,
    })
  },
  listValues({
    attribute_id,
    offset = 0,
    limit = 100,
    order_by = "count",
    order_dir = "DESC",
  }: {
    attribute_id: Attribute["id"]
    offset?: number
    limit?: number
    order_by?: keyof CustomerAttributeValueCount
    order_dir?: OrderDir
  }): Promise<CustomerAttributeValueCountResponse> {
    return requestFactory(
      "get",
      `/customers/attributes/${attribute_id}/values/counts`,
      {
        offset,
        limit,
        order_by,
        order_dir,
        value_contains: "",
      },
      true,
      true,
      "",
      false,
      true,
    )
  },
}

export default customerAttribute

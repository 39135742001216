import React from "react"
import styles from "./NewReleaseModal.module.scss"
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button/Button"
import { useModifyUser } from "resources/user/userQueries"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"
import IconButton from "components/UI/elements/IconButton/IconButton"
import mobilePush from "./mobile-push.jpg"

const CURRENT_NEW_FEATURE = "mobile_push_multi_firebase_projects"

export default function NewReleaseModal() {
  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()

  const { id, frontend_settings } = currentUser!

  const handleClose = () => {
    modifyUser({
      id,
      data: {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          lastReleaseModalShown: CURRENT_NEW_FEATURE,
        },
      },
    })
  }

  const { lastReleaseModalShown } = frontend_settings ?? {}

  // @ts-ignore
  if (window.Cypress) return null

  return (
    <Modal
      open={lastReleaseModalShown !== CURRENT_NEW_FEATURE}
      customHeader={
        <header>
          <h3>🎉 Check out the updates:</h3>
          <IconButton
            color="grey"
            icon={["fas", "times"]}
            tooltip="Close"
            variant="transparent"
            onClick={handleClose}
            data-testid="close-button"
          />
        </header>
      }
      handleClose={handleClose}
      className={styles.modal}
      contentClassName={styles.content}
    >
      <h3>Greet the better version of Mobile Push!</h3>

      <img src={mobilePush} alt="" />

      <p>
        We are excited to introduce the recent improvements to the Mobile Push that will help you
        take your customer experiences to the next level. Here’s what’s new:
      </p>

      <p>
        <b>Manage campaigns across all apps from one place:</b> Save your precious time and effort —
        configure and effortlessly run campaigns for multiple projects with different apps and
        operating systems from one place with the Mobile Push interface.
      </p>

      <p>
        <b>Configure the notifications actions:</b> Optimize push campaign engagement by choosing
        how recipients interact with your messages, whether by opening the app, browser, or a deep
        link.
      </p>

      <p>
        <b>Add images to your messages:</b> Create visually compelling push notifications to improve
        audience engagement.
      </p>

      <p>
        <b>Quality control your messages:</b> Send previews to test campaigns and ensure everything
        looks and functions exactly as intended before reaching your audience.
      </p>

      <p>
        Explore our{" "}
        <a
          href="https://docs.meiro.io/books/meiro-business-explorer/chapter/mobile-push-tutorials-tips"
          target="_blank"
          rel="noreferrer"
        >
          documentation
        </a>{" "}
        to take advantage of these exciting improvements and start with the upgraded version of the
        Mobile Push today!
      </p>

      <div className={styles.buttonWrapper}>
        <Button size="md" onClick={handleClose}>
          Got it
        </Button>
      </div>
    </Modal>
  )
}

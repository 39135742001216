import React, { useEffect } from "react"
import { Route, Switch } from "react-router-dom"

import { getRoutePath } from "routes"
import Trash from "pages/Trash/Trash"
import FunnelsList, { useFiltersStore } from "./FunnelsList/FunnelsList"
import ChartCreate from "./ChartCreate/ChartCreate"
import ChartDetail from "./ChartDetail/ChartDetail"
import {
  useFetchFunnelChartTrashItems,
  useRestoreFunnelChart,
} from "resources/funnelChart/funnelChartQueries"

export default function FunnelsRoutes() {
  const { reset } = useFiltersStore()
  useEffect(() => reset, [reset])

  return (
    <Switch>
      <Route
        path={getRoutePath("analytics.funnels.trash")}
        render={() => (
          <Trash
            backRoute={getRoutePath("analytics.funnels")}
            itemType="charts"
            fetchQuery={useFetchFunnelChartTrashItems}
            // @ts-ignore
            restoreMutation={useRestoreFunnelChart}
          />
        )}
      />
      <Route path={getRoutePath("analytics.funnels.group.chart-create")} component={ChartCreate} />
      <Route path={getRoutePath("analytics.funnels.group.chart-detail")} component={ChartDetail} />
      <Route path={getRoutePath("analytics.funnels.group")} component={FunnelsList} />
      <Route path={getRoutePath("analytics.funnels")} component={FunnelsList} />
    </Switch>
  )
}

import { useEffect, useMemo, useState } from "react"
import { values, whereEq } from "ramda"
import { useParams } from "react-router-dom"

import { ActiveEventIds, ActiveSourceIds } from "../types"
import SourceFilter from "../SourceFilter/SourceFilter"
import TimelineFilter from "../TimelineFilter/TimelineFilter"
import TimelineEvents from "../TimelineEvents/TimelineEvents"
import TimelineEventGroups from "../TimelineEventGroups/TimelineEventGroups"
import { useFetchAllDataSources } from "resources/dataSource/dataSourceQueries"
import { useFetchAttributesMapBySourceId } from "resources/attribute/attributeQueries"
import { useFetchEventSourceIds, useFetchEventsMap } from "resources/event/eventQueries"
import { useFetchCustomerAttributes } from "resources/customer/attribute/customerAttributeQueries"
import { ATTRIBUTE_VALUES_LIMIT } from "sharedConstants"

import styles from "./TimelineView.module.scss"

export default function TimelineView() {
  const [activeEventIds, setActiveEventIds] = useState<ActiveEventIds>([])
  const [activeSourceIds, setActiveSourceIds] = useState<ActiveSourceIds>([])
  const [searchTerm, setSearchTerm] = useState("")

  const { id: profileId } = useParams<{ id: string }>()

  const { data: sources = [] } = useFetchAllDataSources({ showHidden: true })
  const { data: attributesMapBySourceId = {} } = useFetchAttributesMapBySourceId()
  const { data: eventsMap = {} } = useFetchEventsMap({
    includeHidden: true,
  })
  const { data: timelineAvailableSourceIds = [] } = useFetchEventSourceIds()
  const { data: customerAttributesResponse } = useFetchCustomerAttributes({
    attribute_values_max_count: ATTRIBUTE_VALUES_LIMIT,
    customer_entity_id: profileId,
    load_full_structure: 0,
  })

  const customerSources = useMemo(
    () =>
      sources.filter(source => {
        const sourceAttributes = attributesMapBySourceId[source.id]
        if (Array.isArray(sourceAttributes) && customerAttributesResponse)
          return sourceAttributes.some(attr =>
            customerAttributesResponse.customer_attributes.find(whereEq({ attribute_id: attr.id })),
          )

        return false
      }),
    [attributesMapBySourceId, customerAttributesResponse, sources],
  )

  useEffect(() => {
    if (customerSources.length > 0)
      setActiveSourceIds(
        customerSources
          .filter(({ id }) => timelineAvailableSourceIds.includes(id))
          .map(({ id }) => id),
      )
  }, [customerSources, timelineAvailableSourceIds])

  useEffect(() => {
    if (values(eventsMap).length > 0 && customerSources.length > 0)
      setActiveEventIds(
        values(eventsMap)
          .filter(({ source }) => customerSources.some(whereEq({ id: source.id })))
          .filter(({ source }) => timelineAvailableSourceIds.includes(source.id))
          .map(({ id }) => id),
      )
  }, [eventsMap, customerSources, timelineAvailableSourceIds])

  const isSearchTermEmpty = searchTerm ? /^\s+$/g.test(searchTerm) : true
  const timelineSources = customerSources.filter(s => timelineAvailableSourceIds.includes(s.id))
  const timelineEvents = values(eventsMap)
    .filter(({ source }) => customerSources.some(whereEq({ id: source.id })))
    .filter(({ source }) => timelineAvailableSourceIds.includes(source.id))
  const showOnlyTimelineEvents =
    timelineEvents.some(({ id }) => !activeEventIds.includes(id)) ||
    timelineSources.some(({ id }) => !activeSourceIds.includes(id)) ||
    !isSearchTermEmpty

  return (
    <>
      <SourceFilter
        activeEventIds={activeEventIds}
        activeSourceIds={activeSourceIds}
        sources={timelineSources}
        events={timelineEvents}
        setActiveEventIds={setActiveEventIds}
        setActiveSourceIds={setActiveSourceIds}
      />
      <div data-testid="timeline-content" className={styles.timelineContent}>
        <div className={styles.leftPanel}>
          <TimelineFilter
            activeEventIds={activeEventIds}
            activeSourceIds={activeSourceIds}
            searchTerm={searchTerm}
            setActiveEventIds={setActiveEventIds}
            setActiveSourceIds={setActiveSourceIds}
            setSearchTerm={setSearchTerm}
            sources={timelineSources}
            events={timelineEvents}
          />
        </div>
        <div className={styles.rightPanel}>
          {showOnlyTimelineEvents ? (
            <TimelineEvents
              activeEventIds={activeEventIds}
              activeSourceIds={activeSourceIds}
              sources={timelineSources}
              events={timelineEvents}
              searchTerm={searchTerm}
            />
          ) : (
            <TimelineEventGroups sources={timelineSources} />
          )}
        </div>
      </div>
    </>
  )
}

import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import PopupWebBannersCreate from "./PopupWebBannersCreate/PopupWebBannersCreate"
import PopupWebBannersDetail from "./PopupWebBannersDetail/PopupWebBannersDetail"
import PopupWebBannersList from "./PopupWebBannersList/PopupWebBannersList"
import { useHasAccess } from "resources/user/currentUserQueries"
import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import Trash from "pages/Trash/Trash"
import {
  useFetchPopupWebBannerTrashItems,
  useRestorePopupWebBanner,
} from "resources/webBanner/popupWebBanner/popupWBQueries"

export default function PopupWebBanners() {
  const hasAccess = useHasAccess()

  return (
    <Switch>
      <Route path={getRoutePath("channels.popup-banners")} exact component={PopupWebBannersList} />
      <AuthorizedRoute
        hasAccess={hasAccess.webBanners.view}
        path={getRoutePath("channels.popup-banners.trash")}
        exact
        render={() => (
          <Trash
            backRoute={getRoutePath("channels.popup-banners")}
            itemType="popup_web_banners"
            fetchQuery={useFetchPopupWebBannerTrashItems}
            restoreMutation={useRestorePopupWebBanner}
          />
        )}
      />
      <Route
        path={getRoutePath("channels.popup-banners.create")}
        component={PopupWebBannersCreate}
      />
      <Route
        path={getRoutePath("channels.popup-banners.detail")}
        component={PopupWebBannersDetail}
      />
    </Switch>
  )
}

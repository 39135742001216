import React from "react"
import { useForm } from "react-hook-form"

import Button from "components/UI/elements/Button/Button"
import Modal from "components/UI/elements/Modal"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { required, snakeCase, maxLength } from "helpers/validators.helper"
import { useCreateStitchingCategory } from "resources/stitchingCategory/stitchingCategoryQueries"
import { StitchingCategory } from "resources/stitchingCategory/stitchingCategoryTypes"

import styles from "./CreateStitchingCategoryModal.module.scss"

type CreateStitchingCategoryFormValues = Omit<StitchingCategory, "stitching_rules_count">

type CreateStitchingCategoryModalProps = {
  open: boolean
  onClose: () => void
  onConfirm: (stitchingCategory: StitchingCategory) => void
}

export default function CreateStitchingCategoryModal({
  open,
  onClose,
  onConfirm,
}: CreateStitchingCategoryModalProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateStitchingCategoryFormValues>({
    defaultValues: {
      id: "",
      name: "",
    },
  })

  const createMutation = useCreateStitchingCategory()
  const createStitchingCategory = (data: CreateStitchingCategoryFormValues) =>
    createMutation.mutate(
      { data },
      {
        onSuccess: ({ stitching_category }) => {
          onConfirm(stitching_category)
        },
      },
    )

  return (
    <Modal open={open} title="Create stitching category" handleClose={onClose}>
      <form onSubmit={handleSubmit(createStitchingCategory)}>
        <div className={styles.textInputs}>
          <TextInput
            error={errors.id?.message}
            label="ID"
            maxLength={255}
            placeholder="ID"
            className={styles.textInput}
            {...register("id", {
              validate: {
                required,
                snakeCase,
                maxLength: maxLength(255),
              },
            })}
          />
          <TextInput
            error={errors.name?.message}
            label="Name"
            placeholder="Name"
            className={styles.textInput}
            {...register("name", { validate: { required } })}
          />
        </div>
        <div className={styles.buttons}>
          <Button color="grey" size="md" variant="text" onClick={_ => onClose()}>
            Cancel
          </Button>
          <Button loading={createMutation.isLoading} color="green" size="md" type="submit">
            Create
          </Button>
        </div>
      </form>
    </Modal>
  )
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import NotificationCard from "components/UI/components/Notification/Notification"
import Avatar from "components/UI/elements/Avatar"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import { abbreviateNumber } from "helpers/number.helper"
import InfiniteScroll from "react-infinite-scroll-component"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import TimeAgo from "react-timeago"
import timeAgoFormatter from "./utilities/timeAgoFormatter"
import Duration from "components/UI/elements/Duration"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import styles from "./HomePage.module.scss"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import { useFetchAttributesCount } from "resources/attribute/attributeQueries"
import { useFetchCurrentUser, useHasAccess } from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import { useFetchDataSourceCount } from "resources/dataSource/dataSourceQueries"
import { useFetchNotifications, useMarkAllAsRead } from "resources/notification/notificationQueries"
import { partition } from "ramda"
import { DBtimestampToDate } from "utilities/date"
import { useFetchCustomersCount } from "resources/stats/customersCount"
import { useFetchCustomerEventsCount } from "resources/stats/customerEventsCount"
import { useFetchCacheStatus } from "resources/stats/cacheStatus"

export default function HomePage() {
  const { data: currentUser } = useFetchCurrentUser()
  const { data: sourcesCount, isLoading: areSourcesLoading } = useFetchDataSourceCount()
  const { data: attributesCount, isLoading: isLoadingAttributesCount } = useFetchAttributesCount()

  const hasAccess = useHasAccess()

  const { data: customersCount, isLoading: isLoadingCustomersCount } = useFetchCustomersCount({
    enabled: hasAccess.data.dashboard,
  })
  const { data: customerEventsCount, isLoading: isLoadingCustomerEventsCount } =
    useFetchCustomerEventsCount({
      enabled: hasAccess.data.dashboard,
    })
  const { data: cacheStatus, isLoading: isLoadingCacheStatus } = useFetchCacheStatus({
    enabled: hasAccess.data.dashboard,
  })

  const {
    data: notifications = [],
    hasNextPage = false,
    fetchNextPage,
    isLoading: isLoadingNotifications,
  } = useFetchNotifications()
  const { mutate: markAllAsRead, isLoading: isMarkingAsRead } = useMarkAllAsRead()
  const [readNotifs, unreadNotifs] = partition(notif => notif.read, notifications)
  const noOfScrolledElements =
    +(unreadNotifs.length > 0) + +(readNotifs.length > 0) + notifications.length

  return (
    <Page title="Home" contentClassName={styles.content}>
      {hasAccess.data.dashboard && (
        <div className={styles.dashboardSection}>
          <Paper className={styles.dashboardHeaderCard}>
            <div data-testid="welcome-user" className={styles.left}>
              <Avatar
                name={currentUser!.name}
                email={currentUser!.email}
                className={styles.avatar}
              />
              <div>
                <div>Welcome</div>
                <div className={styles.name}>{currentUser!.name}!</div>
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.label}>latest data update</div>

              {isLoadingCacheStatus ? (
                <LoadingIndicator size="sm" fixedWidth />
              ) : cacheStatus?.refresh_init_time ? (
                <>
                  <div className={styles.refreshLabel}>Refresh is in progress</div>
                  <div className={styles.refreshValue}>
                    <Duration
                      datetime={DBtimestampToDate(cacheStatus.refresh_init_time)}
                      units={["d", "h", "m"]}
                    />
                  </div>
                </>
              ) : cacheStatus?.init_time ? (
                <TimeAgo
                  date={DBtimestampToDate(cacheStatus.init_time)}
                  formatter={timeAgoFormatter}
                />
              ) : (
                <div className={styles.mainValue}>N/A</div>
              )}
            </div>
          </Paper>
          <Paper className={styles.dashboardCard}>
            <div className={styles.left}>
              <div className={styles.label}>
                total number of profiles{" "}
                <InfoTooltip placement="right" interactive>
                  Number of profiles before and after{" "}
                  <a
                    href="https://docs.meiro.io/books/meiro-knowledge-base/page/identity-stitching-and-how-customer-identity-appears"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    profile stitching
                  </a>
                  .
                </InfoTooltip>
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.mainValue}>
                {isLoadingCustomersCount ? (
                  <LoadingIndicator size="sm" fixedWidth />
                ) : customersCount?.customer_entities_count ? (
                  abbreviateNumber(customersCount.customer_entities_count)
                ) : (
                  "N/A"
                )}
              </div>
              {!isLoadingCustomersCount && (
                <div className={styles.beforeStitching}>
                  <span className={styles.uppercase}>
                    {customersCount?.customers_before_stitching_count
                      ? abbreviateNumber(customersCount.customers_before_stitching_count)
                      : "N/A"}
                  </span>{" "}
                  before stitching
                </div>
              )}
            </div>
          </Paper>
          <div className={styles.row}>
            <Paper className={styles.dashboardCard}>
              <div className={styles.left}>
                <div className={styles.label}>all connected sources</div>
              </div>
              <div className={styles.right}>
                <div className={styles.mainValue}>
                  {areSourcesLoading ? (
                    <LoadingIndicator size="sm" fixedWidth />
                  ) : (
                    sourcesCount ?? "N/A"
                  )}
                </div>
              </div>
            </Paper>
            <Paper className={styles.dashboardCard}>
              <div className={styles.left}>
                <div className={styles.label}>total number of profile attributes</div>
              </div>
              <div className={styles.right}>
                <div className={styles.mainValue}>
                  {isLoadingAttributesCount ? (
                    <LoadingIndicator size="sm" fixedWidth />
                  ) : (
                    attributesCount ?? "N/A"
                  )}
                </div>
              </div>
            </Paper>
          </div>
          <Paper className={styles.dashboardCard}>
            <div className={styles.left}>
              <div className={styles.label}>total number of events</div>
            </div>
            <div className={styles.right}>
              <div className={styles.mainValue}>
                {isLoadingCustomerEventsCount ? (
                  <LoadingIndicator size="sm" fixedWidth />
                ) : customerEventsCount?.customer_events_count ? (
                  abbreviateNumber(customerEventsCount.customer_events_count)
                ) : (
                  "N/A"
                )}
              </div>
            </div>
          </Paper>
          <Link className={styles.linkToAnalytics} to={getRoutePath("analytics.dashboard")}>
            <FontAwesomeIcon icon={["fas", "tachometer"]} className={styles.icon} />
            go to diagnostic dashboard
          </Link>
        </div>
      )}
      <div className={styles.notificationsSection}>
        <PaperHeader titleText="Notifications" size="small" className={styles.notificationsHeader}>
          <InfoTooltip className={styles.notificationsTooltip} placement="right">
            <p>
              You will get notifications whenever your colleagues make changes to{" "}
              <strong>attributes</strong>, <strong>events</strong>, or{" "}
              <strong>segments you collaborate on</strong>.
            </p>
            <p>Notifications for other features will be coming soon.</p>
          </InfoTooltip>
        </PaperHeader>
        <Paper hasHeader>
          {isLoadingNotifications && notifications.length === 0 && <LoadingIndicator />}

          {!isLoadingNotifications && notifications.length === 0 ? (
            <div className={styles.emptyMessage}>There are no notifications.</div>
          ) : (
            <InfiniteScroll
              dataLength={noOfScrolledElements}
              next={fetchNextPage}
              hasMore={hasNextPage}
              scrollThreshold="100px"
              hasChildren={noOfScrolledElements > 0}
              loader={
                <div className={styles.loadingMore}>
                  <LoadingIndicator size="sm" fixedWidth />
                </div>
              }
            >
              {unreadNotifs.length > 0 && (
                <>
                  <div className={styles.header}>
                    <div className={styles.sectionHeader}>
                      {unreadNotifs.length}
                      {readNotifs.length === 0 && hasNextPage && "+"} unread
                    </div>
                    <button
                      onClick={_ => markAllAsRead()}
                      className={classNames(styles.markAsReadButton, {
                        [styles.isLoading]: isMarkingAsRead,
                      })}
                    >
                      <div className={styles.text}>mark all as read</div>
                      <div className={styles.spinnerWrapper}>
                        <LoadingIndicator size="xs" />
                      </div>
                    </button>
                  </div>
                  <div>
                    {unreadNotifs.map(notif => (
                      <NotificationCard notification={notif} key={notif.id} />
                    ))}
                  </div>
                </>
              )}
              {readNotifs.length > 0 && (
                <>
                  <div className={styles.header}>
                    <div className={styles.sectionHeader}>read</div>
                  </div>
                  <div>
                    {readNotifs.map(notif => (
                      <NotificationCard notification={notif} key={notif.id} />
                    ))}
                  </div>
                </>
              )}
            </InfiniteScroll>
          )}
        </Paper>
      </div>
    </Page>
  )
}

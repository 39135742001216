import { QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { showToast } from "app/toast"
import { api } from "api"
import { sort } from "ramda"
import { OrderDir } from "types/util"
import { ascend, descend } from "utilities/comparators"
import { Tag, TagPayload, TagSort } from "./tagTypes"

export const TAG_ALL_QK: QueryKey = ["tag", "all"]

type FetchAllTagsOpts = {
  orderBy?: TagSort
  orderDir?: OrderDir
  searchTerm?: string
}

export function useFetchAllTags({
  orderBy = "name",
  orderDir = "ASC",
  searchTerm,
}: FetchAllTagsOpts = {}) {
  return useQuery(TAG_ALL_QK, api.tag.listAll, {
    staleTime: 60 * 1000,
    select(tags) {
      const filteredTags = searchTerm
        ? tags.filter(tag => tag.name.toLowerCase().includes(searchTerm.trim().toLowerCase()))
        : tags
      const comparator = orderDir === "ASC" ? ascend : descend
      return sort(
        comparator(tag => tag[orderBy]),
        filteredTags,
      )
    },
  })
}

export function useCreateTag() {
  const queryClient = useQueryClient()

  return useMutation(({ data }: { data: TagPayload }) => api.tag.create(data), {
    onSuccess() {
      // We have to refetch because the BE doesn't include the whole entity in the create response
      queryClient.refetchQueries(TAG_ALL_QK)
      showToast("Tag created.")
    },
  })
}

export function useModifyTag() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: Tag["id"]; data: TagPayload }) => api.tag.modify(id, data),
    {
      onSuccess() {
        // We have to refetch because the BE doesn't include the whole entity in the modify response
        queryClient.refetchQueries(TAG_ALL_QK)
        showToast("Tag modified.")
      },
    },
  )
}

export function useDeleteTag() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Tag["id"] }) => api.tag.delete(id), {
    onSuccess(_, { id }) {
      queryClient.setQueryData<Tag[]>(TAG_ALL_QK, data => {
        if (!data) {
          return
        }

        return data.filter(tag => tag.id !== id)
      })
      showToast("Tag deleted.")
    },
  })
}

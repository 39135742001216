import React, { useState } from "react"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import IdentityStitchingForm, {
  IdentityStitchingFormValues,
} from "../components/IdentityStitchingForm/IdentityStitchingForm"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"
import "./IdentityStitchingDetail.scss"
import { useFetchAllEvents } from "resources/event/eventQueries"
import { equals, prop, sort } from "ramda"
import { ascend } from "utilities/comparators"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { Redirect, useHistory, useParams } from "react-router-dom"
import {
  useFetchStitchingCategoryById,
  useModifyStitchingCategoryRules,
  useDeleteStitchingCategory,
  useModifyStitchingCategory,
} from "resources/stitchingCategory/stitchingCategoryQueries"
import Tippy from "@tippyjs/react"

const emptyRules: IdentityStitchingFormValues["rules"] = [
  { event_id: "", value_expression: "", is_system: 0 },
]

export default function IdentityStitchingDetail() {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [formDirty, setFormDirty] = useState(false)

  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const { data: events = [], isSuccess: areEventsFulfilled } = useFetchAllEvents({
    includeHidden: true,
  })
  const eventOptions = sort(
    ascend(prop("label")),
    events.map(event => ({
      label: `${event.source.name}: ${event.name}`,
      value: event.id,
      hidden: event.is_hidden === 1,
      source_id: event.source.id,
    })),
  )

  const { data: stitchingCategory, isSuccess: isStitchingCategoryFulfilled } =
    useFetchStitchingCategoryById(id)
  const modifyCategory = useModifyStitchingCategory()
  const modifyMutation = useModifyStitchingCategoryRules()
  const deleteMutation = useDeleteStitchingCategory()

  const modifyStitchingCategory = async ({
    rules,
    regex_filter_pattern,
  }: IdentityStitchingFormValues & { regex_filter_pattern?: string }) => {
    if (
      stitchingCategory?.id &&
      stitchingCategory.regex_filter_pattern !== (regex_filter_pattern ?? null)
    ) {
      await modifyCategory.mutateAsync({
        id: stitchingCategory.id,
        data: {
          regex_filter_pattern: regex_filter_pattern === "" ? null : regex_filter_pattern,
        },
      })
    }

    if (
      !equals(
        rules,
        (stitchingCategory?.stitching_rules?.length ?? 0) > 0
          ? stitchingCategory?.stitching_rules
          : emptyRules,
      )
    ) {
      await modifyMutation.mutateAsync({
        id,
        rules,
      })
    }
  }

  const deleteStitchingCategory = () =>
    deleteMutation.mutate(
      { id },
      {
        onSuccess() {
          history.push(getRoutePath("administration.identity-stitching"))
        },
      },
    )

  if (isStitchingCategoryFulfilled && !stitchingCategory)
    return <Redirect to={getRoutePath("not-found")} />

  const isSystem = stitchingCategory?.stitching_rules.some(({ is_system }) => is_system)

  return (
    <Page
      className="identity-stitching-detail"
      title="Edit stitching category"
      headerContent={
        isStitchingCategoryFulfilled && (
          <div className="header-buttons">
            <Button
              disabled={isSystem}
              color="red"
              icon="trash-alt"
              tooltip={
                isSystem
                  ? "Cannot delete stitching category containing system stitching rules"
                  : undefined
              }
              variant="outlined"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              Delete
            </Button>
            <Button
              color="grey"
              variant="outlined"
              onClick={() => {
                history.push(getRoutePath("administration.identity-stitching"))
              }}
            >
              Cancel
            </Button>
            <Tippy content="There are no changes." placement="bottom" disabled={formDirty}>
              <div>
                <Button
                  color="green"
                  loading={modifyMutation.isLoading || modifyCategory.isLoading}
                  type="submit"
                  form="identity-stitching-form"
                  disabled={!formDirty}
                >
                  Save
                </Button>
              </div>
            </Tippy>
          </div>
        )
      }
    >
      {!isStitchingCategoryFulfilled && <LoadingIndicator />}
      {isStitchingCategoryFulfilled && stitchingCategory && (
        <IdentityStitchingForm
          areEventsFulfilled={areEventsFulfilled}
          category={stitchingCategory}
          rules={
            stitchingCategory.stitching_rules.length > 0
              ? stitchingCategory.stitching_rules
              : emptyRules
          }
          eventOptions={eventOptions}
          onSubmit={modifyStitchingCategory}
          onFormDirty={setFormDirty}
        />
      )}
      <ConfirmModal
        open={isDeleteModalOpen}
        type={MODAL.TYPE.DELETE}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={deleteStitchingCategory}
        title="Are you sure?"
        action="delete"
        what="stitching category"
        item={stitchingCategory?.name}
        isLoading={deleteMutation.isLoading}
      />
    </Page>
  )
}

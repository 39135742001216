import React from "react"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import SourceForm from "../components/SourceForm/SourceForm"
import "./SourceCreate.scss"
import Page from "components/UI/Page/Page"
import { useCreateDataSource } from "resources/dataSource/dataSourceQueries"
import { useHistory, useLocation } from "react-router-dom"
import { Source, SourceCreatePayload } from "resources/dataSource/dataSourceTypes"
import { pick } from "ramda"

export default function SourceCreate() {
  const createMutation = useCreateDataSource()
  const history = useHistory()
  const source = useLocation<{ source?: Source }>().state?.source

  function createSource(data: SourceCreatePayload) {
    createMutation.mutate(
      { data },
      {
        onSuccess() {
          history.push(getRoutePath("administration.sources"))
        },
      },
    )
  }

  return (
    <Page
      title="Create source"
      className="create-source"
      headerContent={
        <div className="header-buttons">
          <Button
            color="grey"
            variant="outlined"
            onClick={() => {
              history.push(getRoutePath("administration.sources"))
            }}
          >
            Cancel
          </Button>
          <Button type="submit" form="sourceForm" loading={createMutation.isLoading}>
            Save
          </Button>
        </div>
      }
    >
      <Paper className="create-source-content">
        <SourceForm
          onSubmit={createSource}
          initialValues={
            source && pick(["id", "name", "type", "description", "frontend_settings"], source)
          }
          isCreate
        />
      </Paper>
    </Page>
  )
}

import { format } from "date-fns"
import { sanitizeDatetime } from "utilities/date"
import { DATE_FMT } from "sharedConstants"

type DatetimeProps = {
  datetime?: Date | string | null
  precision?: "day" | "minute" | "second"
}

export default function Datetime({ datetime: date, precision = "day" }: DatetimeProps) {
  if (!date) {
    return null
  }

  return (
    <>
      {format(
        sanitizeDatetime(date),
        precision === "day"
          ? DATE_FMT.DATE
          : precision === "minute"
          ? DATE_FMT.DATETIME
          : DATE_FMT.DATETIME_PRECISE,
      )}
    </>
  )
}

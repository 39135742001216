import { getRoutePath } from "routes"
import logo from "images/Meiro-white.svg"
import { AuthCarousel } from "components/Login/AuthCarousel/AuthCarousel"
import { useHistory } from "react-router-dom"
import styles from "./Logout.module.scss"

const LogoutPage = () => {
  const history = useHistory()

  return (
    <section className={styles.logoutPage}>
      <div className={styles.leftPart}>
        <div>
          <img src={logo} className={styles.logo} alt="meiro cdp logo" />
          <h1>
            You’ve successfully
            <br /> logged out.
          </h1>
          <p>
            To learn more about cool things you can do <br /> with Meiro, visit our{" "}
            <a href="https://meiro.io/use-cases/" target="_blank" rel="noreferrer noopener">
              resources
            </a>
            .
          </p>
          <div className={styles.loginButton} onClick={() => history.push(getRoutePath("login"))}>
            Back to login
          </div>
        </div>
      </div>
      <div className={styles.rightPart}>
        <AuthCarousel />
      </div>
    </section>
  )
}

export default LogoutPage

import React, { useState } from "react"
import SelectField from "components/UI/elements/SelectField"
import DummyInsight from "./DummyInsight"
import CustomDatePicker from "components/UI/components/CustomDatePicker"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import ColorRadioGroup from "components/UI/components/ColorRadioGroup"
import ToggleIconSwitchInput from "components/UI/elements/ToggleSwitch/ToggleIconSwitchInput"
import NumberSliderField from "components/UI/elements/NumberSliderField"
import { SEGMENT, SEGMENT_ANALYTICS_FUNCTIONS, OPTION_GROUP_COLORS } from "sharedConstants"
import { required, min, max } from "helpers/validators.helper"
import Whisperer from "components/UI/components/Whisperer/Whisperer"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "resources/attribute/compoundAttributeUtils"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import { Controller, useForm } from "react-hook-form"
import TextInput from "components/UI/elements/TextInput/TextInput"
import classNames from "classnames"
import AttributePicker from "components/AttributePicker/AttributePicker"
import { AttributeAggregation } from "resources/attributeAggregation/attributeAggregationTypes"
import { Attribute, AttributeFull } from "resources/attribute/attributeTypes"
import { useFetchCustomerAttributeValues } from "resources/customer/attribute/customerAttributeQueries"

type DataType = keyof typeof SEGMENT.ANALYTICS.FUNCTIONS
type InsightType = keyof typeof SEGMENT_ANALYTICS_FUNCTIONS
type InsightLabelValue = typeof SEGMENT_ANALYTICS_FUNCTIONS[InsightType]
type InsightValue = InsightLabelValue["value"]

type SubAttribute = Pick<Attribute, "data_type"> & {
  label: Attribute["name"]
  value: Attribute["id"]
}

type FormValues = {
  tile_type: string
  count: number
  name: AttributeAggregation["name"]
  attribute_id: AttributeAggregation["attribute_id"]
  description: AttributeAggregation["description"]
  color: string
  function: InsightValue | null
  subattribute: SubAttribute | null
  value: string
  value_from: string
  value_to: string
}

type InsightFormProps = {
  initialValues: FormValues
  onSubmit: (data: FormValues) => void
}

const commonFunctions: Array<InsightValue> = [
  SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
  SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
]

const functionsWithListValue: Array<InsightValue> = [
  ...commonFunctions,
  SEGMENT_ANALYTICS_FUNCTIONS.UNIQUE_VALUES.value,
]

const functionsWithValue: Array<InsightValue> = [
  ...functionsWithListValue,
  SEGMENT_ANALYTICS_FUNCTIONS.COUNT.value,
  SEGMENT_ANALYTICS_FUNCTIONS.LOWER_THAN.value,
  SEGMENT_ANALYTICS_FUNCTIONS.GREATER_THAN.value,
  SEGMENT_ANALYTICS_FUNCTIONS.CONTAINS.value,
]

const getFunctionsByDataType = (dataType: DataType): Array<InsightLabelValue> => {
  if (!dataType) return []
  return SEGMENT.ANALYTICS.FUNCTIONS[dataType]?.slice() ?? [] // little trick to change readonly array to mutable array
}

const InsightForm = ({ initialValues, onSubmit }: InsightFormProps) => {
  const [datePickerOpen, setDatePickerOpen] = useState<number>(0)
  const [valueFieldError, setValueFieldError] = useState<number>(0)

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: initialValues ?? {
      tile_type: "chart",
      count: 1,
      name: "",
      attribute_id: "",
      description: "",
      color: "",
      function: null,
      subattribute: null,
      value: "",
      value_from: "",
      value_to: "",
    },
  })

  watch() // watch and subscribe to entire form

  const {
    attribute_id,
    subattribute,
    tile_type,
    value,
    value_from,
    value_to,
    count,
    color,
    description,
    name,
    function: funcType,
  } = getValues()

  const { data: attributesMap = {} } = useFetchAttributesMap()
  const attribute = attributesMap[attribute_id] as AttributeFull | undefined

  const {
    error,
    isError,
    data: attributeValuesResponse,
    isLoading: isLoadingAttributeValues,
  } = useFetchCustomerAttributeValues(
    subattribute ? `${attribute_id}.${subattribute.value}` : attribute_id,
    { enabled: !!attribute_id },
  )

  const suggestions: Array<{
    id: string
    label: any
    value: any
    disabled?: boolean
    highest?: boolean
    lowest?: boolean
  }> =
    isError && error?.response?.data.error_type === "pii_protected_attribute"
      ? [
          {
            label: "* Protected Information *",
            value: "",
            disabled: true,
            id: "protected_information",
          },
        ]
      : attributeValuesResponse
      ? attributeValuesResponse.attribute_values_counts.map((item, index) => {
          const additionalProps: { highest?: boolean; lowest?: boolean } = {}
          if (index === 0 && attributeValuesResponse.attribute_values_counts.length > 2)
            additionalProps.highest = true
          else if (
            index === attributeValuesResponse.attribute_values_counts.length - 1 &&
            attributeValuesResponse.attribute_values_counts.length > 2
          )
            additionalProps.lowest = true

          return {
            id: `${item.attribute_id}.${item.value}`,
            label: item.value,
            value: item.value,
            ...additionalProps,
          }
        })
      : []

  const toggleDatePicker = (id: number) => () => {
    if (datePickerOpen === id) setDatePickerOpen(0)
    else setDatePickerOpen(id)
  }

  const onAttributeChange = (newAttributeId: Attribute["id"] | null) => {
    const { attribute_id: oldAttributeId, function: funcType } = getValues()
    const clearForm = () => {
      setValue("function", null)
      setValue("value", "")
      setValue("value_from", "")
      setValue("value_to", "")
      setValue("subattribute", null)
    }

    if (oldAttributeId && newAttributeId && newAttributeId !== oldAttributeId) {
      const newAttrDataType = attributesMap[oldAttributeId]?.data_type
      const oldAttrDataType = attributesMap[newAttributeId]?.data_type
      if (newAttrDataType !== oldAttrDataType) {
        // attribute types changed, check if new attribute supports functType or if change
        // was from/to compound
        if (isAttributeCompound(newAttrDataType) || isAttributeCompound(oldAttrDataType)) {
          clearForm()
        } else {
          const newAttribute = attributesMap[newAttributeId]
          if (
            !newAttribute ||
            (funcType &&
              !getFunctionsByDataType(newAttribute.data_type.toUpperCase() as DataType).find(
                item => item.value === funcType,
              )) ||
            funcType === "between"
          ) {
            clearForm()
          }
        }
      }
    }
  }

  const onDimensionChange = (newSubAttribute: SubAttribute) => {
    const { subattribute, function: funcType } = getValues()
    if (subattribute && subattribute.data_type !== newSubAttribute.data_type) {
      if (
        funcType &&
        (!getFunctionsByDataType(newSubAttribute.data_type.toUpperCase() as DataType).find(
          item => item.value === funcType,
        ) ||
          funcType === "between")
      ) {
        setValue("function", null)
        setValue("value", "")
        setValue("value_from", "")
        setValue("value_to", "")
      }
    }
  }

  const _onSubmit = (data: FormValues) => {
    const { value } = getValues()

    if (data.attribute_id) {
      const attribute = attributesMap[data.attribute_id]
      const dataType = data.subattribute ? data.subattribute.data_type : attribute.data_type
      if (["date", "string"].includes(dataType)) {
        if (data.function === "count") {
          // validate date existence, because the component is not redux form field
          if (!value) {
            setValueFieldError(1)
            return
          }
        }
        if (data.function === "contains") {
          if (!value || (value && value.length < 3)) {
            setValueFieldError(1)
            return
          }
        }
      }
    }

    onSubmit(data)
  }

  const renderCompoundAttributeFields = (
    attribute: AttributeFull,
    functionOptions: Array<InsightLabelValue>,
  ) => {
    const subAttributes = getCompoundAttributeSubAttributes(attribute.data_type)

    return (
      <div className="compound-attr-form-row">
        <div className="left-field">
          <Controller
            control={control}
            name="subattribute"
            rules={{ validate: required }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectField
                input={{
                  value,
                  onChange: (newSubAttribute: SubAttribute) => {
                    onDimensionChange(newSubAttribute)
                    onChange(newSubAttribute)
                  },
                }}
                meta={{ touched: true, error: error?.message }}
                label="Dimension *"
                isSearchable={false}
                options={subAttributes.map(sattr => ({
                  label: sattr.name,
                  value: sattr.id,
                  data_type: sattr.data_type,
                }))}
                placeholder="Select dimension"
                inputId="dimension"
              />
            )}
          />
        </div>
        <div className="right-field">
          <Controller
            control={control}
            name="function"
            rules={{ validate: required }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectField
                input={{ value, onChange }}
                meta={{ touched: true, error: error?.message }}
                label="Condition *"
                isSearchable={false}
                options={functionOptions}
                placeholder="Select condition"
                noOptionsMessage="First select a dimension"
                inputId="condition"
                isSimpleValue
              />
            )}
          />
        </div>
      </div>
    )
  }

  let conditionDataType = ""
  if (attribute_id)
    conditionDataType = subattribute ? subattribute.data_type : attribute ? attribute.data_type : ""
  const functionOptions = getFunctionsByDataType(conditionDataType.toUpperCase() as DataType)

  const hasValueField = funcType && functionsWithValue.includes(funcType)
  const hasTwoValueFields = funcType && funcType === SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN.value
  const hasToggle = funcType && commonFunctions.includes(funcType)

  return (
    <>
      <Paper hasHeader className="admin-segment-analytics-content form-content">
        <div className="analytics-form-section">
          <form
            id="insightForm"
            className="analytics-form"
            autoComplete="off"
            onSubmit={handleSubmit(_onSubmit)}
          >
            <div className="form-row">
              <TextInput
                {...register("name", { validate: required })}
                placeholder="Name"
                label="Name *"
                maxLength={50}
                error={errors.name?.message}
              />
            </div>
            <div className="form-row">
              <TextInput
                {...register("description")}
                placeholder="Description"
                label="Description"
                disableWhitespaceWarning
              />
            </div>
            <div className="two-fields">
              <Controller
                control={control}
                name="attribute_id"
                rules={{ validate: required }}
                render={({ field, fieldState: { error } }) => (
                  <div
                    className={classNames("form-row", "attribute-row", {
                      error: error?.message,
                    })}
                  >
                    <label>Attribute *</label>
                    <AttributePicker
                      value={field.value}
                      onChange={id => {
                        onAttributeChange(id)
                        field.onChange(id)
                      }}
                      error={error?.message}
                      size="lg"
                    />
                  </div>
                )}
              />
              <div className="form-row">
                {attribute && !isAttributeCompound(attribute.data_type) && (
                  <Controller
                    control={control}
                    name="function"
                    rules={{ validate: required }}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <SelectField
                        input={{ value, onChange }}
                        label="Condition *"
                        isSearchable={false}
                        options={functionOptions}
                        placeholder="Select condition"
                        noOptionsMessage="First select an attribute"
                        error={error?.message}
                        isSimpleValue
                      />
                    )}
                  />
                )}
              </div>
            </div>
            {attribute &&
              isAttributeCompound(attribute.data_type) &&
              renderCompoundAttributeFields(attribute, functionOptions)}
            {attribute && hasValueField && (
              <div className={hasToggle ? "two-fields" : ""}>
                <div
                  className={`form-row date-value last-row ${hasToggle ? "two-fields-count" : ""}`}
                >
                  {conditionDataType === "date" && !commonFunctions.includes(funcType) && (
                    <div className={`text-field ${valueFieldError === 1 ? "error" : ""}`}>
                      <label>Value *</label>
                      <CustomDatePicker
                        value={value}
                        open={datePickerOpen === 1}
                        isEditable={true}
                        onChange={(newValue: string) => {
                          setValueFieldError(0)
                          setValue("value", newValue)
                        }}
                        toggle={toggleDatePicker(1)}
                        dataType={conditionDataType}
                      />
                      {valueFieldError === 1 && (
                        <p className="error-message">Please fill in the field</p>
                      )}
                    </div>
                  )}
                  {conditionDataType === "string" && !functionsWithListValue.includes(funcType) && (
                    <div className={`whisperer text-field ${valueFieldError === 1 ? "error" : ""}`}>
                      <label>Value *</label>
                      <Whisperer
                        loading={isLoadingAttributeValues}
                        options={suggestions}
                        value={value}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          { newValue }: { newValue: string },
                        ) => {
                          if (!event) return
                          setValueFieldError(0)
                          setValue("value", newValue)
                        }}
                        placeholder="Search..."
                      />
                      {valueFieldError === 1 && (
                        <p className="error-message">Please fill in at least 3 characters</p>
                      )}
                    </div>
                  )}
                  {!["date", "string"].includes(conditionDataType) &&
                    !commonFunctions.includes(funcType) && (
                      <TextInput
                        {...register("value", {
                          validate(value, values) {
                            if (!values.function) return
                            return ["lower_than", "greater_than", "contains", "count"].includes(
                              values.function,
                            )
                              ? required(value)
                              : undefined
                          },
                        })}
                        type={["int", "float"].includes(conditionDataType) ? "number" : "text"}
                        placeholder="Value fulfilling selected condition"
                        label="Value *"
                        error={errors.value?.message}
                      />
                    )}
                  {functionsWithListValue.includes(funcType) && (
                    <Controller
                      control={control}
                      name="count"
                      rules={{ validate: { required, min: min(1), max: max(5) } }}
                      render={({ field: { value, onChange } }) => (
                        <NumberSliderField
                          input={{ value, onChange }}
                          key={hasToggle ? "1" : "2"}
                          min={1}
                          max={5}
                          label="Show"
                          marks={[1, 2, 3, 4, 5]}
                        />
                      )}
                    />
                  )}
                </div>
                {hasToggle && (
                  <div className="form-row tile-display last-row">
                    <Controller
                      control={control}
                      name="tile_type"
                      render={({ field: { value, onChange } }) => (
                        <ToggleIconSwitchInput
                          name="tile_type"
                          leftValue="chart"
                          rightValue="list"
                          leftIcon={["far", "chart-line"]}
                          rightIcon={["far", "bars"]}
                          width="100px"
                          className="tile-display-toggle"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
            )}
            {attribute && hasTwoValueFields && (
              <div className="two-values-fields">
                <div className="form-row date-value last-row two-fields">
                  {["date", "datetime"].includes(conditionDataType) && (
                    <>
                      <div className={`text-field ${valueFieldError === 1 ? "error" : ""}`}>
                        <label>Since *</label>
                        <CustomDatePicker
                          value={value_from}
                          open={datePickerOpen === 1}
                          isEditable={true}
                          onChange={(newValue: string) => {
                            setValueFieldError(0)
                            setValue("value_from", newValue)
                          }}
                          toggle={toggleDatePicker(1)}
                          dataType={conditionDataType}
                        />
                        {valueFieldError === 1 && (
                          <p className="error-message">Please fill in the field</p>
                        )}
                      </div>
                      <div className={`text-field ${valueFieldError === 2 ? "error" : ""}`}>
                        <label>Until *</label>
                        <CustomDatePicker
                          value={value_to}
                          open={datePickerOpen === 2}
                          isEditable={true}
                          onChange={(newValue: string) => {
                            setValueFieldError(0)
                            setValue("value_to", newValue)
                          }}
                          toggle={toggleDatePicker(2)}
                          dataType={conditionDataType}
                        />
                        {valueFieldError === 2 && (
                          <p className="error-message">Please fill in the field</p>
                        )}
                      </div>
                    </>
                  )}
                  {["int"].includes(conditionDataType) && (
                    <div className="int-values-fields">
                      <TextInput
                        {...register("value_from", { validate: required })}
                        type="number"
                        placeholder="From"
                        label="From *"
                        error={errors.value_from?.message}
                      />
                      <TextInput
                        {...register("value_to", { validate: required })}
                        type="number"
                        placeholder="To"
                        label="To *"
                        error={errors.value_to?.message}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="form-row"></div>
          </form>
          <div className="preview">
            <span className="label">Preview example:</span>
            <DummyInsight
              id={1}
              name={name}
              funcType={funcType}
              compareValue={
                SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN.value === funcType
                  ? [value_from, value_to]
                  : value
              }
              description={description}
              attribute={attribute}
              subAttribute={
                subattribute
                  ? {
                      id: subattribute.value,
                      name: subattribute.label,
                      data_type: subattribute.data_type,
                    }
                  : null
              }
              color={color}
              displayType={tile_type}
              count={count ? Math.round(+count) : null}
              hideDescription={false}
            />
          </div>
        </div>
      </Paper>
      <div className="insight-form-color-picker">
        <div className="colors-box">
          <p className="label-like">Pick color</p>
          <Controller
            control={control}
            name="color"
            render={({ field: { value, onChange } }) => (
              <ColorRadioGroup input={{ value, onChange }} colors={OPTION_GROUP_COLORS} />
            )}
          />
        </div>
        <div className="buttons">
          <Button color="grey" size="md" variant="outlined" onClick={() => setValue("color", "")}>
            Clear
          </Button>
        </div>
      </div>
    </>
  )
}

export default InsightForm

import { ActivateWebhookNodeSettings } from "resources/journey/journeyTypes"

export type ActivateWebhookNodeFormValues = Omit<ActivateWebhookNodeSettings, "headers"> & {
  headers: { key: string; value: string }[]
}

export function settingsToFormValues(
  settings: ActivateWebhookNodeSettings,
): ActivateWebhookNodeFormValues {
  return {
    ...settings,
    headers: Object.entries(settings.headers).map(([key, value]) => ({ key, value })),
  }
}

export function formValuesToSettings(
  values: ActivateWebhookNodeFormValues,
): ActivateWebhookNodeSettings {
  return {
    ...values,
    headers: Object.fromEntries(values.headers.map(({ key, value }) => [key, value])),
  }
}

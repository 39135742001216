import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ComponentPropsWithoutRef } from "react"
import NodeCard from "../../components/NodeCard/NodeCard"

const icon = <FontAwesomeIcon icon={["fas", "star-of-life"]} color="#fe7f66" />

export default function EntryNodeCard(
  props: Omit<ComponentPropsWithoutRef<typeof NodeCard>, "title" | "icon">,
) {
  return <NodeCard title="Entry" icon={icon} {...props} />
}

import React, { useCallback, useContext, useRef, useState } from "react"
import classNames from "classnames"

import ConfirmModal from "components/UI/components/ConfirmModal"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Paper from "components/UI/elements/Paper"
import { MODAL } from "sharedConstants"
import { EmailChannelSection, EmailsChannelItemContext } from "../../EmailsChannel"

import styles from "./EmailChannelPaper.module.scss"

type EmailChannelPaperProps = {
  children: React.ReactNode
  title: string
  description?: string | React.ReactNode
  expandable?: boolean
  hasUnsavedChanges?: boolean
  headerContent?: React.ReactNode
  id?: EmailChannelSection
  goBack?: () => void
}

export default function EmailChannelPaper({
  children,
  id,
  title,
  description,
  headerContent,
  goBack,
  expandable = false,
  hasUnsavedChanges = false,
}: EmailChannelPaperProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { expandedSection, setExpandedSection } = useContext(EmailsChannelItemContext)

  const scrollToRef = useRef<HTMLDivElement>(null)

  const scrollTo = useCallback(() => {
    if (scrollToRef && scrollToRef.current)
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
      })
  }, [scrollToRef])

  const isExpanded = id && expandedSection ? expandedSection === id : false

  const closeModal = () => setModalOpen(false)

  return (
    <>
      <Paper className={styles.paper} ref={scrollToRef}>
        <div className={classNames(styles.paperHeader, { [styles.expanded]: isExpanded })}>
          <div className={styles.wrapper}>
            {goBack && (
              <IconButton
                onClick={() => {
                  if (hasUnsavedChanges) setModalOpen(true)
                  else goBack()
                }}
                icon="chevron-left"
                variant="transparent"
                className={styles.backButton}
              />
            )}
            <div className={styles.titleWrapper}>
              <h3>{title}</h3>
              {description && <p>{description}</p>}
            </div>
          </div>
          {expandable ? (
            <div>
              {isExpanded && headerContent}
              <IconButton
                data-testid="expand-button"
                color="grey"
                icon={isExpanded ? "chevron-up" : "chevron-down"}
                onClick={() => {
                  if (isExpanded) setExpandedSection(null)
                  else if (id) {
                    setExpandedSection(id)
                    setTimeout(scrollTo, 1e3 / 60) // wait 16ms (single frame) to use scrollIntoView - little trick from MUI
                  }
                }}
                variant="transparent"
                className={styles.expandButton}
              />
            </div>
          ) : (
            headerContent
          )}
        </div>
        {expandable && !isExpanded ? null : children}
      </Paper>
      <ConfirmModal
        open={modalOpen}
        title="Are you sure?"
        text="Changes you made will not be saved."
        type={MODAL.TYPE.CANCEL}
        handleClose={closeModal}
        handleConfirm={() => {
          closeModal()
          goBack?.()
        }}
      />
    </>
  )
}

import React, { useState } from "react"
import classNames from "classnames"
import styles from "./JourneysList.module.scss"
import Page from "components/UI/Page/Page"
import { useHasAccess } from "resources/user/currentUserQueries"
import Button from "components/UI/elements/Button/Button"
import {
  useCreateJourney,
  useDeleteJourney,
  useFetchAllJourneys,
} from "resources/journey/journeyQueries"
import { Link, useHistory } from "react-router-dom"
import { getRoutePath } from "routes"
import Tippy from "@tippyjs/react"
import Table, { Column, Modified, Name } from "components/Table/Table"
import { Journey, JourneySort } from "resources/journey/journeyTypes"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import Datetime from "components/UI/elements/Datetime/Datetime"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { OrderDir } from "types/util"
import create from "zustand"
import SearchField from "components/UI/elements/SearchField"

type FilterState = {
  orderBy: JourneySort
  orderDir: OrderDir
  searchTerm: string
  reset: () => void
  setSearchTerm: (searchTerm: string) => void
  setSort: (orderBy: JourneySort) => void
}

const filtersInitialState = {
  orderBy: "name" as JourneySort,
  orderDir: "ASC" as OrderDir,
  searchTerm: "",
}

export const useFiltersStore = create<FilterState>(set => ({
  ...filtersInitialState,
  reset: () => set(filtersInitialState),
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
}))

const formatter = new Intl.NumberFormat("en-US")

export default function JourneysList() {
  const filters = useFiltersStore()
  const { orderBy, orderDir, searchTerm, setSearchTerm, setSort } = filters
  const hasAccess = useHasAccess()
  const history = useHistory()
  const journeysQuery = useFetchAllJourneys(filters)
  const createMutation = useCreateJourney()
  const deleteMutation = useDeleteJourney()
  const [journeyToDelete, setJourneyToDelete] = useState<Journey | null>(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  function createJourney() {
    createMutation.mutate(
      { data: { name: `Untitled journey` } },
      {
        onSuccess({ journey: { id } }) {
          history.push(getRoutePath("journeys.detail", { id }))
        },
      },
    )
  }

  if (!hasAccess.journeys.view) {
    return <Page title="Journey canvas">TODO: marketing content</Page>
  }

  if (journeysQuery.isLoading) {
    return (
      <Page title="Journey canvas">
        <LoadingIndicator />
      </Page>
    )
  }

  if (!journeysQuery.data) {
    return <Page title="Journey canvas">{null}</Page>
  }

  const columns: Column<Journey>[] = [
    {
      id: "name",
      gridTemplate: "1fr",
      label: "Name",
      renderCell: journey => <Name name={journey.name} />,
      onSort: () => setSort("name"),
    },
    {
      id: "entered",
      gridTemplate: "min-content",
      label: "Arrived",
      renderCell: journey =>
        (journey.status === "active" || journey.status === "passive") &&
        formatter.format(journey.lifetime_statistics.customer_entities_entered),
      onSort: () => setSort("entered"),
    },
    {
      id: "dropped",
      gridTemplate: "min-content",
      label: "Dropped",
      renderCell: journey =>
        (journey.status === "active" || journey.status === "passive") &&
        formatter.format(journey.lifetime_statistics.customer_entities_dropped),
      onSort: () => setSort("dropped"),
    },
    {
      id: "finished",
      gridTemplate: "min-content",
      label: "Completed",
      renderCell: journey =>
        (journey.status === "active" || journey.status === "passive") &&
        formatter.format(journey.lifetime_statistics.customer_entities_finished),
      onSort: () => setSort("finished"),
    },
    {
      id: "status",
      gridTemplate: "min-content",
      label: "Status",
      renderCell: ({ status }) => (
        <div className={classNames(styles.status, styles[status])}>{status}</div>
      ),
      onSort: () => setSort("status"),
    },
    {
      id: "created",
      gridTemplate: "max-content",
      label: "Created",
      renderCell: ({ created }) => <Datetime datetime={created} precision="minute" />,
      onSort: () => setSort("created"),
    },
    {
      id: "modified",
      gridTemplate: "max-content",
      label: "Modified",
      renderCell: ({ modified, modified_by }) => (
        <Modified modifiedAt={modified} modifiedBy={modified_by} />
      ),
      onSort: () => setSort("modified"),
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: journey => (
        <div className={styles.actions}>
          <Link to={getRoutePath("journeys.detail", { id: journey.id })}>
            <IconButton
              color="black"
              size="xs"
              variant="outlined"
              icon="pencil-alt"
              tooltip="Edit"
            />
          </Link>
          <IconButton
            disabled={!hasAccess.journeys.edit}
            color="red"
            icon="trash-alt"
            size="xs"
            tooltip="Delete"
            variant="outlined"
            onClick={() => {
              setJourneyToDelete(journey)
              setIsDeleteModalOpen(true)
            }}
          />
        </div>
      ),
    },
  ]

  return (
    <>
      <ConfirmModal
        isLoading={deleteMutation.isLoading}
        open={isDeleteModalOpen}
        action="delete"
        item={journeyToDelete?.name}
        title="Are you sure?"
        type="delete"
        what="journey"
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={() =>
          deleteMutation.mutate(
            { id: journeyToDelete!.id },
            { onSuccess: () => setIsDeleteModalOpen(false) },
          )
        }
      />

      <Page
        title="Journey canvas"
        headerContent={
          <>
            <SearchField
              input={{
                value: searchTerm,
                onChange: setSearchTerm,
              }}
              placeholder="Search for name"
              onClear={() => setSearchTerm("")}
              wrapperClassName={styles.searchField}
            />
            <Tippy
              content="You don't have the permission to create journeys"
              disabled={hasAccess.journeys.edit}
            >
              <Button
                onClick={createJourney}
                loading={createMutation.isLoading}
                disabled={createMutation.isLoading || !hasAccess.journeys.edit}
              >
                + Create journey
              </Button>
            </Tippy>
          </>
        }
      >
        <Paper>
          <Table
            columns={columns}
            emptyMessage="No journeys found."
            data={journeysQuery.data}
            sortBy={orderBy}
            sortDir={orderDir}
          />
        </Paper>
      </Page>
    </>
  )
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ComponentPropsWithoutRef } from "react"
import NodeCard from "../../components/NodeCard/NodeCard"

const icon = <FontAwesomeIcon icon={["fas", "code-merge"]} color="#8855db" />

export default function ConditionNodeCard(
  props: Omit<ComponentPropsWithoutRef<typeof NodeCard>, "title" | "icon">,
) {
  return <NodeCard title="Condition" icon={icon} {...props} />
}

import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import { queryClient } from "app/queryClient"
import { equals, omit, prop, sort, update, whereEq } from "ramda"
import api from "resources/endpoints"
import {
  Source,
  SourceCreatePayload,
  SourceFilterState,
  SourceModifyPayload,
} from "./dataSourceTypes"
import {
  GLOBAL_SETTING_QK,
  useFetchGlobalSettings,
} from "resources/globalSettings/globalSettingsQueries"
import { useCallback } from "react"
import { ascend, descend } from "utilities/comparators"
import { GlobalSettingsListResponse } from "resources/globalSettings/globalSettingsTypes"

const DATA_SOURCE_ALL_QK: QueryKey = ["dataSource", "all"]

export const refetchDataSources = () =>
  queryClient.invalidateQueries(
    { queryKey: DATA_SOURCE_ALL_QK, refetchType: "all" },
    { throwOnError: false },
  )

function useSourcesQuery<T>(config?: UseQueryOptions<Source[], unknown, T, QueryKey>) {
  return useQuery(DATA_SOURCE_ALL_QK, api.dataSource.listAll, {
    staleTime: 60 * 1000,
    ...config,
  })
}

type FetchAllSourcesOptions = Partial<Pick<SourceFilterState, "orderBy" | "orderDir">> & {
  showHidden?: boolean
}

export function useFetchAllDataSources({
  orderBy,
  orderDir,
  showHidden,
}: FetchAllSourcesOptions = {}) {
  const queryClient = useQueryClient()
  const globalSettingsResponse =
    queryClient.getQueryData<GlobalSettingsListResponse>(GLOBAL_SETTING_QK)

  const select = useCallback(
    (sources: Array<Source>) => {
      let selectedSources = sources
      if (!showHidden) {
        selectedSources = selectedSources.filter(({ is_hidden }) => is_hidden === 0)
      }

      if (orderBy && orderDir) {
        const comparator = orderDir === "ASC" ? ascend : descend

        switch (orderBy) {
          case "in_dashboard":
            if (!globalSettingsResponse) return selectedSources

            const { global_settings } = globalSettingsResponse
            const hiddenSourcesSetting = global_settings.find(
              whereEq({ key: "hidden_data_sources_from_diagnostic_dashboard" }),
            )

            const temp: Array<Source & { in_dashboard: boolean }> = sources.map(s => ({
              ...s,
              in_dashboard: !hiddenSourcesSetting?.value.includes(s.id),
            }))

            selectedSources = sort(comparator(prop("in_dashboard")), temp).map(
              omit(["in_dashboard"]),
            )
            break
          default:
            selectedSources = sort(comparator(prop(orderBy)), selectedSources)
        }
      }

      return selectedSources
    },
    [orderBy, orderDir, showHidden, globalSettingsResponse],
  )

  return useSourcesQuery({ select })
}

export function useFetchDataSourcesMap() {
  return useSourcesQuery({
    select: sources => Object.fromEntries(sources.map(source => [source.id, source])),
  })
}

export function useFetchDataSourceOptions({ showHidden }: { showHidden?: boolean } = {}) {
  return useSourcesQuery({
    select: sources => {
      const filteredSources = showHidden
        ? sources
        : sources.filter(source => source.is_hidden === 0)
      return filteredSources.map(source => ({
        value: source.id,
        label: source.name,
        hidden: source.is_hidden === 1,
      }))
    },
  })
}

export function useFetchDataSourceCount() {
  const { data: globalSettings } = useFetchGlobalSettings()

  return useSourcesQuery({
    select: sources => {
      if (!globalSettings) {
        return undefined
      }
      sources = sources.filter(source => source.is_hidden === 0)
      const hiddenSources = globalSettings["hidden_data_sources_from_diagnostic_dashboard"]?.value
      if (hiddenSources && Array.isArray(hiddenSources) && hiddenSources.length > 0) {
        return sources.filter(({ id }) => !hiddenSources.includes(id)).length
      }
      return sources.length
    },
  })
}

export function useCreateDataSource() {
  const queryClient = useQueryClient()

  return useMutation(({ data }: { data: SourceCreatePayload }) => api.dataSource.create(data), {
    onSuccess: ({ source }) => {
      queryClient.setQueryData<Source[]>(DATA_SOURCE_ALL_QK, data => {
        if (!data) {
          return
        }
        return data.concat(source)
      })
      showToast("Source created.")
    },
  })
}

export function useModifyDataSource() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: Source["id"]; data: SourceModifyPayload }) =>
      api.dataSource.modify(id, data),
    {
      onSuccess: ({ source }, { data }) => {
        queryClient.setQueryData<Source[]>(DATA_SOURCE_ALL_QK, data => {
          if (!data) return

          const index = data.findIndex(({ id }) => source.id === id)

          return index === -1 ? data.concat(source) : update(index, source, data)
        })
        showToast(
          equals(data, { is_hidden: 1 })
            ? "Data source hidden."
            : equals(data, { is_hidden: 0 })
            ? "Data source visible."
            : "Data source modified.",
        )
      },
    },
  )
}

import React from "react"
import styles from "./RoleCreate.module.scss"
import Page from "components/UI/Page/Page"
import { Link, useHistory } from "react-router-dom"
import { getRoutePath } from "routes"
import Button from "components/UI/elements/Button/Button"
import RoleForm from "../components/RoleForm/RoleForm"
import { useCreateUserRole } from "resources/userRole/userRoleQueries"

export default function RoleCreate() {
  const createMutation = useCreateUserRole()
  const history = useHistory()

  return (
    <Page
      title="Create user role"
      headerContent={
        <div className={styles.buttons}>
          <Link to={getRoutePath("administration.roles")}>
            <Button variant="outlined" color="grey">
              Cancel
            </Button>
          </Link>
          <Button type="submit" form="roleForm" loading={createMutation.isLoading}>
            Save
          </Button>
        </div>
      }
    >
      <RoleForm
        onSubmit={data =>
          createMutation.mutate(
            { data },
            {
              onSuccess() {
                history.push(getRoutePath("administration.roles"))
              },
            },
          )
        }
      />
    </Page>
  )
}

import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"

import Button from "components/UI/elements/Button/Button"
import CreateStitchingCategoryModal from "components/CreateStitchingCategoryModal/CreateStitchingCategoryModal"
import EmailChannelPaper from "../components/EmailChannelPaper/EmailChannelPaper"
import SelectField from "components/UI/elements/SelectField"
import { required } from "helpers/validators.helper"
import { useModifyEmailsChannelStitchingCategory } from "resources/channel/channelQueries"
import {
  EmailsChannel,
  ModifyEmailsChannelStitchingCategoryPayload,
} from "resources/channel/channelTypes"
import { useFetchAllStitchingCategories } from "resources/stitchingCategory/stitchingCategoryQueries"
import { StitchingCategory as StitchingCategoryType } from "resources/stitchingCategory/stitchingCategoryTypes"

import styles from "./StitchingCategory.module.scss"

type StitchingCategoryProps = {
  stitchingCategoryId: EmailsChannel["emails_stitching_category_id"]
  isWizard?: boolean
  goBack?: () => void
  goForward?: () => void
}

export default function StitchingCategory({
  stitchingCategoryId,
  goBack,
  goForward,
  isWizard = false,
}: StitchingCategoryProps) {
  const [modalOpen, setModalOpen] = useState(false)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ModifyEmailsChannelStitchingCategoryPayload>({
    defaultValues: { stitching_category_id: stitchingCategoryId },
  })

  const { mutateAsync, isLoading } = useModifyEmailsChannelStitchingCategory()

  const onSubmit = async (formValues: ModifyEmailsChannelStitchingCategoryPayload) => {
    await mutateAsync({ data: formValues })
    goForward?.()
  }

  const { data: stitchingCategories = [], isLoading: isLoadingStitchingCategories } =
    useFetchAllStitchingCategories()

  const stitchingCategoryOptions = stitchingCategories.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  const getCategory = (id: StitchingCategoryType["id"]) =>
    stitchingCategoryOptions.find(option => option.value === id)

  const stitchingCategoryAlreadySet = !!stitchingCategoryId
  const skipSubmit = isWizard && stitchingCategoryAlreadySet

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EmailChannelPaper
          title="Email stitching category"
          headerContent={
            <Button
              disabled={!isWizard && stitchingCategoryAlreadySet}
              icon={isWizard ? "arrow-right" : undefined}
              iconPosition="end"
              loading={isLoading}
              type={skipSubmit ? "button" : "submit"}
              onClick={() => {
                if (skipSubmit) goForward?.()
              }}
            >
              {isWizard ? (
                <>
                  2<span className={styles.steps}>/5</span> Continue
                </>
              ) : (
                "Save"
              )}
            </Button>
          }
          goBack={goBack}
        >
          <div className={styles.root}>
            <Controller
              control={control}
              name="stitching_category_id"
              rules={{ validate: { required } }}
              render={({ field: { value, onChange } }) => (
                <div className={styles.selectFieldWrapper}>
                  <SelectField
                    disabled={stitchingCategoryAlreadySet}
                    isLoading={isLoadingStitchingCategories}
                    input={{
                      value: value ? getCategory(value) : undefined,
                      onChange: ({ value }: { value: StitchingCategoryType["id"] }) =>
                        onChange(value),
                    }}
                    label="Email stitching category"
                    meta={{
                      touched: true,
                      error: errors?.stitching_category_id?.message,
                    }}
                    noOptionsMessage="No attribute matches the given criteria."
                    options={stitchingCategoryOptions}
                    placeholder="Type to search..."
                  />
                </div>
              )}
            />
            <Button
              disabled={stitchingCategoryAlreadySet}
              icon="circle-plus"
              size="md"
              onClick={() => setModalOpen(true)}
            >
              Add stitching category
            </Button>
          </div>
        </EmailChannelPaper>
      </form>

      <CreateStitchingCategoryModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={({ id }) => {
          setValue("stitching_category_id", id, { shouldDirty: true })
          setModalOpen(false)
        }}
      />
    </>
  )
}

import React, { Fragment } from "react"
import classNames from "classnames"
import styles from "./FunnelChart.module.scss"
import { ChartsDataResponse } from "resources/funnelChart/funnelChartTypes"
import { abbreviateNumber } from "helpers/number.helper"
import { hexToRgba } from "helpers/chartjs.helper"
import Tippy from "@tippyjs/react"

type FunnelChartProps = {
  data: { id: string; value: number | null; label: string }[]
  interval: ChartsDataResponse["interval"]
  color?: string
  expanded?: boolean
}

export default function FunnelChart({ data, color, expanded }: FunnelChartProps) {
  const maxValue = Math.max(...data.map(({ value }) => value ?? 0))

  return (
    <div className={classNames(styles.container, { [styles.expanded]: expanded })}>
      {data.map(({ id, value, label }, index, data) => {
        value ??= 0
        const prevValue = data[index - 1]?.value ?? 0

        let diffPercent = (value / prevValue - 1) * 100
        if (diffPercent === 0) {
          diffPercent = -0
        }
        if (value === 0 && prevValue === 0) {
          diffPercent = 0
        }
        const diffPercentString = diffPercent.toLocaleString(undefined, {
          maximumFractionDigits: 1,
        })

        let absPercent = (value / maxValue) * 100
        if (value === 0 && maxValue === 0) {
          absPercent = 0
        }
        const absPercentString = absPercent.toLocaleString(undefined, {
          maximumFractionDigits: 1,
        })

        const numSteps = data.length
        const colorStepSize = 0.65 / (numSteps - 1)
        const stepOpacity = 1 - colorStepSize * index
        const stepColor = hexToRgba(color, stepOpacity)
        const hasManyItems = data.length > 8

        return (
          <Fragment key={`${id}-${index}`}>
            <div className={styles.step} key={id}>
              <div className={styles.stepInner}>
                <div className={styles.header}>
                  <Tippy content={label} placement="top">
                    <div
                      className={classNames(styles.label, {
                        [styles.fontLarge]: expanded,
                        [styles.fontSmall]: hasManyItems && !expanded,
                      })}
                    >
                      {label}
                    </div>
                  </Tippy>
                </div>
                <div className={styles.barWrapper}>
                  {index > 0 && (
                    <div className={styles.diffPercent}>
                      {`${diffPercent > 0 ? "+" : ""}${diffPercentString}%`}
                      <svg
                        className={styles.arrow}
                        viewBox="0 0 75 50"
                        xmlns="http://www.w3.org/2000/svg"
                        width={expanded ? 66 : 45}
                        height={expanded ? 44 : 30}
                        strokeWidth={10}
                      >
                        <path
                          stroke="#222"
                          fill="#222"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M 10,10 L 52.5,10 L 65,25 L 52.5,40 L 10,40 Z"
                        ></path>
                      </svg>
                    </div>
                  )}
                  <div
                    className={styles.bar}
                    style={{
                      height: `${absPercent}%`,
                      backgroundColor: stepColor,
                    }}
                  />
                </div>
              </div>
              <div className={styles.valueWrapper}>
                <div
                  className={classNames(styles.value, {
                    [styles.fontLarge]: expanded,
                    [styles.fontSmall]: hasManyItems && !expanded,
                  })}
                >
                  {abbreviateNumber(value)}
                </div>
                {expanded && <div className={styles.absPercent}>({absPercentString}%)</div>}
              </div>
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

const TimeComponent = ({ value, unit }) => (
  <div className="timeago">
    <strong>{value}</strong> {unit} ago
  </div>
)

export const timeAgoFormatter =
  ({ seconds = "min" } = {}) =>
  (value, unit) => {
    const unitMapping = {
      second: "s",
      minute: "min",
      hour: "hour",
      day: "day",
      week: "week",
      month: "month",
      year: "year",
    }
    if (unit === "second") {
      if (seconds === "moment") {
        return (
          <div className="timeago">
            <strong>seconds</strong> ago
          </div>
        )
      } else if (seconds === "min") {
        return <TimeComponent value="1" unit="min" />
      } else {
        return <TimeComponent value={value} unit="s" />
      }
    } else {
      if (value <= 1) {
        return <TimeComponent value="1" unit={unitMapping[unit]} />
      } else {
        return <TimeComponent value={value} unit={`${unitMapping[unit]}s`} />
      }
    }
  }

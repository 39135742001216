import { showToast } from "app/toast"
import { AuthCarousel } from "components/Login/AuthCarousel/AuthCarousel"
import Button from "components/UI/elements/Button/Button"
import logo from "images/Meiro-white.svg"
import { useEffect } from "react"
import { TOAST } from "sharedConstants"
import styles from "./LinkExpired.module.scss"

type Props = {
  title: string
  text: string
  toastText?: string
  onResendEmail?: () => void
}

export const LinkExpired = ({ title, text, onResendEmail, toastText }: Props) => {
  useEffect(() => {
    if (toastText) {
      showToast(toastText, TOAST.TYPE.ERROR)
    }
  }, [toastText])

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.leftInner}>
          <img src={logo} className={styles.logo} alt="meiro cdp logo" />
          <h2>{title}</h2>
          <p>{text}</p>
          {onResendEmail && <Button onClick={onResendEmail}>Re-send email</Button>}
        </div>
      </div>
      <div className={styles.right}>
        <AuthCarousel />
      </div>
    </div>
  )
}

import { useState } from "react"

import ApplicationModal from "./ApplicationModal/ApplicationModal"
import Badge from "components/UI/elements/Badge/Badge"
import Button from "components/UI/elements/Button/Button"
import ConfirmModal from "components/UI/components/ConfirmModal"
import IconButton from "components/UI/elements/IconButton/IconButton"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import {
  useCreatePushNotificationApp,
  useDeletePushNotificationApp,
  useFetchPushNotificationAppsByFirebaseProjectId,
  useModifyPushNotificationApp,
} from "resources/channel/channelQueries"
import {
  FirebaseProject,
  PushNotificationApp,
  PushNotificationAppPayload,
} from "resources/channel/channelTypes"
import { hasAndroidApp, hasIosApp } from "resources/channel/channelUtils"

import styles from "./ApplicationSettings.module.scss"

type ApplicationSettingsProps = {
  firebaseProjectId: FirebaseProject["id"]
}

export default function ApplicationSettings({ firebaseProjectId }: ApplicationSettingsProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedApp, setSelectedApp] = useState<PushNotificationApp>()

  const { data: applications = [], isLoading } =
    useFetchPushNotificationAppsByFirebaseProjectId(firebaseProjectId)

  const { mutate: createApp, isLoading: isCreating } = useCreatePushNotificationApp()
  const { mutate: modifyApp, isLoading: isModifying } = useModifyPushNotificationApp()
  const { mutate: deleteApp, isLoading: isDeleting } = useDeletePushNotificationApp()

  const closeModal = () => {
    setModalOpen(false)
    setSelectedApp(undefined)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
    setSelectedApp(undefined)
  }

  const onModalSubmit = (data: PushNotificationAppPayload) => {
    if (selectedApp)
      modifyApp(
        {
          data,
          firebaseProjectId,
          appId: selectedApp.id,
        },
        {
          onSuccess: closeModal,
        },
      )
    else
      createApp(
        { data, firebaseProjectId },
        {
          onSuccess: closeModal,
        },
      )
  }

  const addApplicationButton = (
    <Button variant="link" onClick={() => setModalOpen(true)} className={styles.addApplication}>
      + Add application
    </Button>
  )

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!isLoading && applications.length > 0 ? (
        <div className={styles.root}>
          {applications.map(application => {
            const hasAnyAndroidApp = hasAndroidApp(application.app_ids)
            const hasAnyIosApp = hasIosApp(application.app_ids)

            return (
              <div key={application.id} className={styles.application}>
                <div className={styles.name}>
                  <span className={styles.text}>{application.name}</span>
                  {hasAnyAndroidApp && (
                    <Badge background="white" icon={["fab", "android"]} text="Android" />
                  )}
                  {hasAnyIosApp && <Badge background="white" icon={["fab", "apple"]} text="iOS" />}
                </div>
                <div className={styles.buttons}>
                  <IconButton
                    color="grey"
                    icon="pencil-alt"
                    variant="transparent"
                    onClick={() => {
                      setSelectedApp(application)
                      setModalOpen(true)
                    }}
                  />
                  <IconButton
                    color="red"
                    icon="trash-alt"
                    variant="transparent"
                    onClick={() => {
                      setSelectedApp(application)
                      setDeleteModalOpen(true)
                    }}
                  />
                </div>
              </div>
            )
          })}
          {addApplicationButton}
        </div>
      ) : (
        addApplicationButton
      )}

      <ApplicationModal
        isLoading={isCreating || isModifying}
        open={modalOpen}
        application={selectedApp}
        firebaseProjectId={firebaseProjectId}
        handleClose={closeModal}
        onSubmit={onModalSubmit}
      />
      <ConfirmModal
        isLoading={isDeleting}
        open={deleteModalOpen}
        action="delete"
        item={selectedApp?.name}
        title="Are you sure?"
        type="delete"
        what="application"
        handleClose={closeDeleteModal}
        handleConfirm={() =>
          deleteApp(
            { firebaseProjectId, appId: selectedApp!.id },
            { onSuccess: () => closeDeleteModal() },
          )
        }
      />
    </>
  )
}

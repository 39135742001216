import { api } from "api"
import { useLocation } from "react-router-dom"
import { LinkExpired } from "./components/LinkExpired"

export const ResetPasswordExpired = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get("email")

  const onResendEmail = () => {
    if (!email) return

    void api.user.resetPassword({ email })
  }
  return (
    <LinkExpired
      onResendEmail={onResendEmail}
      title="Your reset password link has expired"
      text="Reset password link lasts 5 days for security reasons."
      toastText="Reset password link has expired"
    />
  )
}
